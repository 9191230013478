<template>
<div>
  <el-card class="box-card">
    <div slot="header" class="clearfix">
      <el-row :gutter="10">
        <el-col :span="18">
          <span>
            <span style="font-size: 1.5em">Inventory</span>
            &nbsp;
            <span class="text-secondary"></span>
          </span>
        </el-col>
        <el-col :span="2">
          <el-button
              v-if="true"
              type="default"
              style="  padding: 5px; margin-right: 0.5em;"
              @click="reset()"
          >
            <i class="fas fa-layer-plus"></i>
            New
          </el-button>
        </el-col>
        <el-col :span="2">
          <el-button
              :disabled="!can_print_labels() || form.label_loading"
              type="default"
              style="  padding: 5px; margin-right: 0.5em;"
              @click="print_lot_labels()"
          >
            <span v-if="form.label_loading">
              <i class="fas fa-circle-notch fa-spin"></i> Loading...
            </span>
            <span v-else>
              <i class="fal fa-print"></i>
              Print
            </span>
          </el-button>
        </el-col>
        <el-col :span="2">
          <el-button
              type="primary"
              style="padding: 5px; ; margin-right: .5em"
              @click="record_entry()"
          >
            <i class="fal fa-save"></i> Save
          </el-button>
        </el-col>
      </el-row>
    </div>

    <el-row :gutter="10">
      <el-col :span="12">
        <div class="sub-title">
          <i class="fal fa-barcode-scan"></i> Enter Product Code:
        </div>
        <el-autocomplete
            class="inline-input"
            style="width: 50em;"
            v-model="form.product_code"
            :fetch-suggestions="search_product_identifiers"
            placeholder="Search..."
            :trigger-on-focus="false"
            @select="select_product"
            autocapitalize="none"
            clearable
            tabindex="0"
        >
          <template slot="prepend">
            <i class="fal fa-search"></i>
          </template>

          <template slot-scope="{ item }">
            <div class="value">{{ item.manufacturer_name }} - {{ item.description }}</div>
          </template>
          <!-- <template slot="append">
            <i @click="select_product" class="fal fa-plus-circle"></i>
          </template>-->
        </el-autocomplete>&nbsp;
      </el-col>
      <el-col :span="8">
        <router-link :to="{ name: 'ingredient-manage' }">Add/Manage Ingredients</router-link>
      </el-col>
    </el-row>
    <section class="selected_item_area" v-if="product.product_identifier">
      <el-row :gutter="10">
        <el-col :span="7">
          <div v-if="product.product_identifier">
            Code:
            <span class="product-code">{{ product.product_identifier }}</span>
          </div>
          <div v-if="product.ingredient.description">
            <span class="product-description">{{ product.ingredient.description }}</span>
            &nbsp;
            <span
                class="product-manufacturer"
            >[{{ product.manufacturer.name }}]</span>
          </div>
        </el-col>
        <el-col :span="1">&nbsp;</el-col>
        <el-col :span="7">
          <div>
            Quantity<span v-if="product.ingredient.unit_of_measure"> [<BaseUnitOfMeasure
              :unit="(product.ingredient.unit_of_measure)"/>]</span>:
            <el-input
                placeholder="Quantity"
                v-model="product.inventory.quantity"
                tabindex="3"
                clearable
                type="number"
                inputmode="decimal"
            ></el-input>
          </div>
        </el-col>
        <el-col :span="1">&nbsp;</el-col>
        <el-col :span="7">
          <div>
            PO#:
            <el-input
                v-model="product.inventory.purchase_order"
                placeholder="Purchase Order #"
                tabindex="18"
            ></el-input>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="7">
          Lot #:
          <el-input
              placeholder="ABC123"
              v-model="product.lot_number"
              ref="inputLotNumber"
              tabindex="1"
              clearable
          ></el-input>
        </el-col>
        <el-col :span="1">&nbsp;</el-col>
        <el-col :span="7">
          <div>
            # of Containers:
            <el-input
                placeholder="1"
                v-model="product.inventory.containers"
                tabindex="4"
                clearable
                type="number"
                inputmode="decimal"
            ></el-input>
          </div>
        </el-col>
        <el-col :span="1">&nbsp;</el-col>
        <el-col :span="7">
          <div>
            Purchase Price Per Unit:
            <el-input
                v-model="product.inventory.purchase_price_per_unit"
                placeholder="Purchase Price #"
                tabindex="19"
                @keypress.native="isNumber"
                type="number"
                inputmode="decimal"
            ></el-input>
          </div>
        </el-col>
        <el-col :span="1">&nbsp;</el-col>
        <el-col :span="7">
          <div>
            <BaseUploadDocument label="Packing Slip / Invoice"
                                :document_information="{ category: 'invoice', reference_identifier: product.identifier}"></BaseUploadDocument>
          </div>
        </el-col>

        <!-- <el-col :span="7">
          <i class="fal fa-calendar-alt"></i> &nbsp;
          Beyond Use Date:
          <el-date-picker v-model="form.beyond_use_date" type="date" placeholder="Beyond Use"></el-date-picker>
        </el-col>-->
      </el-row>
      <el-row class="ingredient-suppliers header-area">
        <el-col :span="7">
          <span style="margin-right: 2em">
            <i class="fal fa-calendar-alt"></i> &nbsp;
            Beyond Use Date:
          </span>
          <el-date-picker
              v-model="product.inventory.beyond_use_date"
              type="date"
              placeholder="Beyond Use"
              tabindex="2"
          ></el-date-picker>
        </el-col>
        <el-col :span="1">&nbsp;</el-col>
        <el-col :span="7">
          <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4}"
              placeholder="Notes"
              v-model="product.inventory.note"
              tabindex="14"
          ></el-input>
        </el-col>
        <el-col :span="1">&nbsp;</el-col>
        <el-col :span="7">
          <div>
            <div>
              <BaseUploadDocument label="CoA"
                                  :document_information="{ category: 'certificate_of_analysis', reference_identifier: product.identifier}"></BaseUploadDocument>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="7">
          <div>
            CoA Adjustment %:
            <el-input v-model="product.inventory.coa_adjustment" placeholder="100.5" tabindex="9"
              type="number"
              inputmode="decimal"></el-input>
          </div>
        </el-col>
        <el-col :span="1">&nbsp;</el-col>
        <el-col :span="7">
          <div>
            Capsule Weight:
            <el-input v-model="product.inventory.capsule_weight_average" placeholder="Avg Weight"
              tabindex="11"
              type="number"
              inputmode="decimal">
            </el-input>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <documentsList :documents_s="documents_s" @deleteDoc="deleteDoc" />
          <BaseDocumentUpload
              :documents.sync="form.documents"
              :value="form.documents"
              :categories="document_categories"
              @update_documents="updateDocuments"
          ></BaseDocumentUpload>
          <takePhoto @update_documents="updateDocuments" />
        </el-col>
      </el-row>
    </section>
  </el-card>
</div>
</template>
<script>
import NProgress from "nprogress";
import Common from "@/common.js";
import takePhoto from '@/components/TakePhoto.vue'
import documentsList from '@/components/DocumentList.vue'
import url_for from "@/services/pmk_url_for";

const uuidv4 = require("uuid/v4");
export default {
  components: {
    takePhoto,
    documentsList
  },
  data() {
    return {
      documents_s: [],
      document_categories: [
        {label: 'C of A', value: 'C of A'},
        {label: 'Purchase Order', value: 'Purchase Order'},
      ],
      categories: this.$store.state.categories,
      form: this.createFreshFormObject(),
      product: this.createFreshInventoryObject()
    };
  },
  mounted() {
    this.documents_s = [];
  },
  methods: {
    arrayContainsCategory(tabb, categ) {
      let res = false;
      let i = 0;
      let pos = i;
      tabb.forEach(function (params) {
        console.log(params);
        if (params.category == categ) {
          res = true;
          pos = i;
        }
        i++;
      });
      return {contains: res, index: pos};
    },
    get_document_url(identifier, args = {}) {
      return Common.get_document_url(identifier, args);
    },
    updateDocuments(_document) {
      // update documents in form
      let to_append = {};
      to_append.name = _document.name;
      to_append.note = _document.note;
      to_append.type = _document.type;
      to_append.identifier = _document.identifier;
      let to_append_list = [];
      to_append_list.push(to_append);
      let this_document_category_list = {};
      let new_documents_list;
      this_document_category_list["category"] = _document.category;
      this_document_category_list["documents"] = to_append_list;
      if (this.documents_s.length == 0) {
        new_documents_list = this_document_category_list;
        this.documents_s.push(this_document_category_list);
      } else {
        let sear = this.arrayContainsCategory(this.documents_s, _document.category);
        if (sear.contains) {
          this.documents_s[sear.index]["documents"].push(to_append);
        } else {
          let to_add_in_list = {};
          to_add_in_list["category"] = _document.category;
          to_add_in_list["documents"] = to_append_list;
          this.documents_s.push(to_add_in_list);
        }
      }
    },
    deleteDoc(item, category) {
      this.documents_s.forEach(doc_category => {
        if (doc_category.category === category) {
          let index = doc_category.documents.indexOf(item)
          if (index >= 0) {
            doc_category.documents.splice(index, 1)
          }
        }
      })
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
          charCode > 31 &&
          (charCode < 48 || charCode > 57) &&
          charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    reset() {
      this.form = this.createFreshFormObject();
      this.product = this.createFreshInventoryObject();
    },
    can_print_labels() {
      return this.product.saved;
    },
    get_manufacturers(queryString, cb) {
      let options = Object.values(this.manufacturers);
      // .map(function(manufacturer) {
      //   return { value: manufacturer.value, id: manufacturer, name: manufacturer }
      // })
      let results = queryString
          ? options.filter(this.createFilter(queryString))
          : options;
      cb(results);
    },
    //    read_product_code() {},
    select_product(selected_product) {
      // console.log(selected_product)
      let scan = {structured_code: {}};
      if (this.form.scan) {
        scan = this.form.scan.structured_code;
      }

      this.form.selected_product = selected_product;
      let product_identifiers =
          selected_product.manufacturers[selected_product.manufacturer.identifier]
              .product_identifiers[selected_product.product_identifier];

      this.product = {
        ...this.createFreshInventoryObject(),
        ...{
          product_identifier: selected_product.product_identifier,
          // product_identifiers: selected_product.product_identifiers,
          product_identifiers: product_identifiers,
          manufacturer_identifier: selected_product.manufacturer.identifier,
          ingredient_identifier: selected_product.identifier,
          ingredient: {
            description: selected_product.description,
            location: selected_product.location,
            unit_of_measure: selected_product.unit_of_measure
          },
          manufacturer: selected_product.manufacturer,

          description: selected_product.description,
          date_created: new Date().toJSON(),
          inventory: {
            beyond_use_date: scan.beyond_use_date,
            lot_number: scan.lot_number,
            quantity: scan.quantity,
            container_id: scan.container_id
          },
          lot_number: scan.lot_number
        }
      };
      this.form = this.createFreshFormObject();
      // focus the lot#: TODO

      this.$nextTick(() => this.$refs.inputLotNumber.focus());
    },
    createFreshFormObject() {
      return {
        identifier: uuidv4(),
        label_loading: false,
        selected_product: {},
        identifiers: {formula_id: ""},
        category: "",
        ingredient: {},
        new_ingredient_description: "",
        manufacturers: [],
        manufacturer: {},
        name: "",
        description: "",
        location: "",
        date_created: "",
        documents: {},
        options: {
          auxillary_labels: [],
          is_lab_stock: false,
          location: "",
          stability_span: ""
        },
        instructions: "",
        tasks: [],
        scanned_code: {},
        ingredients: [],
        new_ingredient: {
          description: "",
          id: "",
          quantity: "",
          note: "",
          identifiers: [
            {
              code: "",
              system: ""
            }
          ]
        }
      };
    },
    createFreshInventoryObject() {
      const user = this.$store.getters["account/profile"];

      return {
        identifier: uuidv4(),
        product_identifier: "",
        manufacturer_identifier: "",
        ingredient_identifier: "",
        selected_product: {},
        ingredient: {
          description: ""
        },
        documents: {},
        author: user.name,
        manufacturer: {},
        name: "",
        description: "",
        date_created: "",
        instructions: "",
        saved: false,
        lot_type: 'ingredient',
        date_entered_inventory: new Date().toJSON(),
        capsule_weight_average: ""
      };
    },

    search_product_identifiers(queryString, cb) {
      // first parse the barcode:
      let product_identifier = queryString;
      let scan = Common.parse_scan(product_identifier);
      console.log({scan})
      if (!scan.structured_code.empty) {
        this.form.scan = scan;
      }

      // here we need to query the ingredient list API for manufacturer identifiers
      let query_object = {
        explicit: 0,
        method: "LIKE",
        product_identifier:
            scan.structured_code.product_code ||
            scan.structured_code.ndc ||
            product_identifier,
        description: queryString
      };
      NProgress.start();
      this.$store
          .dispatch("ingredient/listIngredients", query_object)
          .then(() => {
            cb(
                this.$store.getters["ingredient/ingredient_search_list"].map(
                    item => item.data
                )
            );
          })
          .then(() => NProgress.done())
          .catch(() => {
            NProgress.done();
          });
    },

    print_lot_labels() {
      if (this.product.identifier) {
        this.form.label_loading = true
        Common.get_lot_label_url(
            this.product,
            this.product.ingredient,
            this.$store.getters['formula/get_lab_config']
        )
        .then((label_url) => {
          this.form.label_loading = false
        })

      }
    },

    record_entry() {
      // see how createEvent in event.js works:
      // an action gets dispatched, which performs a POST, inside of which a commit is made
      NProgress.start();
      // attach documents
      this.product.documents = this.documents_s;
      this.$store
          .dispatch("ingredient/inventoryLog", this.product)
          .then(() => {
            // this.product = this.createFreshEventObject()
            // this.form = this.createFreshEventObject()
            this.product.saved = true;
          })
          .then(() => NProgress.done())
          .catch(() => {
            NProgress.done();
          });
    }
  }
};
</script>
<style scoped>
.location {
  margin-bottom: 0;
}

.location > .icon {
  margin-left: 10px;
}

.event-header > .title {
  margin: 0;
}

.list-group {
  margin: 0;
  padding: 0;
  list-style: none;
}

.list-group > .list-item {
  padding: 1em 0;
  border-bottom: solid 1px #e5e5e5;
}

.formula-options .el-col {
  margin-top: 4px;
}

.auxiallary_button {
  margin-right: 1.5em;
}

.formula-instructions {
  margin: 1.5em 0;
}

.button.bottom {
  margin-top: 13px;
  line-height: 12px;
  padding: 0;
  float: right;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}

.ingredient-suppliers.entry {
  margin-top: 1em;
  padding-bottom: 0.5em;
  border-bottom: 1px solid silver;
}

.ingredient-suppliers h4 {
  font-weight: bold;
}

.manufacturers-area {
  margin: 0.5em 0;
}

.ingredient-options .ingredient-description {
  font-weight: bold;
  margin: 0.75em 0;
}

.selected_item_area .el-row {
  margin-bottom: 1.5em;
}

.clickable {
  cursor: pointer;
}
</style>
