import EventService from "@/services/EventService.js";
import global_store from "@/store/store";
const uuid_base64 = require("uuid-base64");
const uuidv4 = require("uuid/v4");
import { BroadcastChannel } from 'broadcast-channel'
let bc = new BroadcastChannel('ion_user_channel')
export const namespaced = true;

export const state = {
    profile: {},

};

export const mutations = {
    SET_ACCOUNT_PROFILE(state, data) {
        state.profile = data;
        if (data && data.username) {
          localStorage.setItem('username', data.username)
          console.log('1555 localstorage username', localStorage.getItem('username'))
          bc.postMessage('user_set')
        } else {
          localStorage.removeItem('username')
        }
    }
}



export const actions = {
    fetchAccountProfile({ state, commit, getters, dispatch }, id) {
        return EventService.fetchAccountProfile(id)
            .then(response => {
                commit('SET_ACCOUNT_PROFILE', response.data.data)
                return response.data
            })
            .catch(error => {
                const notification = {
                    type: 'error',
                    message:
                        'There was a problem fetching data: ' + error.message
                }
                dispatch('notification/add', notification, { root: true })
            })
    },
    set_user({ commit, dispatch }, data) {
        commit("SET_ACCOUNT_PROFILE", data);
    },

    updateAccountProfile({ state, commit, getters, dispatch }, data) {
        return EventService.updateAccountProfile(response)
            .then(response => {
                commit('SET_ACCOUNT_PROFILE', data)
                return data
            })
            .catch(error => {
                const notification = {
                    type: 'error',
                    message:
                        'There was a problem fetching data: ' + error.message
                }
                dispatch('notification/add', notification, { root: true })
            })
    },

}
export const getters = {
    profile: state => {
        return state.profile
    },
    get_user: state => {
        return state.profile
    },
    get_user_name: state => {
        return state.profile.name
    }

}
