<template>
  <div>
    <el-row style="margin-bottom: 1.5em;">
      <el-col>
        <el-input
          v-model="form.filter_query"
          placeholder="Filter..."
          size="small"/>
      </el-col>
      <el-col>
        <div style="margin-bottom: 1.5em;">
          <el-button
            type="default"
            class="status-button"
            style="float: right; padding: 5px"
            @click="add_manufacturer()"
          >
            <i class="fal fa-plus"></i> Add New
          </el-button>
        </div>
      </el-col>
    </el-row>
    <div>
      <type
        v-for="[manufacturer_identifier, manufacturer] of Object.entries(manufacturers).filter(obj => obj[1].name.toLowerCase().includes(form.filter_query.toLowerCase())) "
        :key="manufacturer_identifier"
        :manufacturer="manufacturer" />
    </div>
  </div>
</template>
<script>
import Common from '@/common.js'
import type from './type.vue'
export default {
  // props: {
  //   manufacturers: {
  //     type: Object,
  //     required: true
  //     // default: function() {
  //     //   let tmp_manufacturers = {
  //     //     Medisca: {
  //     //       account: 'account',
  //     //       phone: '1234567890',
  //     //       name: 'Medisca'
  //     //     }
  //     //   }
  //     //   return tmp_manufacturers
  //     // }
  //   }
  // },
  components: {
    type
  },
  data() {
    return {
      //      manufacturer: {},
      form: {
        filter_query: ''
      }
    }
  },
  computed: {
    manufacturers: {
      get() {
        return this.$store.getters['manufacturer/get_manufacturers']
      },
      set() {
        return this.$store.dispatch('manufacturer/set_manufacturers')
      }
    }
  },
  methods: {
    add_manufacturer() {
      console.log('add manufacturer')
      console.log(this.$store)
      this.$store.dispatch('manufacturer/addManufacturer')
    },
    saveManufacturer(manufacturer) {
      this.$store
        .dispatch('manufacturer/saveManufacturer', manufacturer)
        .then(data => {
          console.log(data)
        })
    }
  }
}
</script>

<style scoped>
.property-row {
  margin-bottom: 1em;
}
</style>
