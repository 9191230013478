<template>
  <div v-if='$store.getters["app/pmk_config"].modules.ion_enabled'>

    <b>Anticipatory Compounding Limit:</b> {{parseInt(anticipatory_compound_limit) ? parseInt(anticipatory_compound_limit) : 'N/A'}}
    - {{limit_date_range}}

    <el-button type="text" @click="download_report" style="margin-left: 15px;">
      <i class="fad fa-file-download"></i> Download
    </el-button>

    <div id="ac_chart" style="margin-top: 15px;">
    </div>

    <el-dialog
      title="Enter NDC"
      :visible.sync="display_prompt_dialog"
      width="30%">
      <el-input placeholder="Please enter NDC" v-model="ndc_local"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="display_prompt_dialog = false">Cancel</el-button>
        <el-button type="primary" @click="load_report">Load Report</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import bb, { line } from 'billboard.js'
import service from '@/services/EventService.js'
import moment from 'moment'
export default {
  props: {
    ndc: {
      default: null,
      required: false
    }
  },
  data() {
    return {
      ndc_local: '',
      report_data_raw: [],
      report_data: {
        data: {
          x: 'x',
          columns: [
            ['x'],
            ['Quantity Prescribed'],
          ],
          type: line(),
        },
        axis: {
          x: {
            type: 'category',
            tick: {
              values: [
                ''
              ]
            }
          },
        },
        colors: {
          'Quantity Prescribed': '#ff00ff',
        },
        bindto: '#ac_chart'
      },
      anticipatory_compound_limit: 0,
      display_prompt_dialog: false,
    }
  },
  computed: {
    limit_date_range() {
      if (this.report_data_raw.length) {
        let date_start = this.report_data_raw.reverse()
          .filter((obj) => obj.quantity_30_day_total === this.anticipatory_compound_limit)[0].date_of_record
        let date_end = moment(date_start).add(30, 'days').format('YYYY-MM-DD')
        date_start = moment(date_start).format('YYYY-MM-DD')
        return `${date_start} - ${date_end}`
      }
      return ''
    }
  },
  mounted() {
    if(!this.ndc && this.$store.getters["app/pmk_config"].modules.ion_enabled) {
      this.display_prompt_dialog = true
    } else if (this.$store.getters["app/pmk_config"].modules.ion_enabled) {
      this.ndc_local = this.ndc
      this.load_report()
    }
  },
  methods: {
    load_report() {
      this.display_prompt_dialog = false
      service.pmk_fetch(
        'electric_lab_report_formula_anticipatory_compounding',
        'GET',
        {ndc: this.ndc_local},
        {ndc: this.ndc_local}
      ).then((response) => {
        this.report_data_raw = response.data.data.data
        response.data.data.data.forEach(row => {
          this.report_data.data.columns[1].push(parseInt(row.quantity_30_day_total) || 0)
          let date_range = `${moment(row.date_of_record).format('YYYY-MM-DD')} - ${moment(row.date_of_record).add(30, 'days').format('YYYY-MM-DD')}`
          this.report_data.data.columns[0].push(date_range)
        })
        this.anticipatory_compound_limit = response.data.data.anticipatory_compounding_limit
        bb.generate(this.report_data)
      })
    },
    download_report() {
      let csvContent = "data:text/csv;charset=utf-8,"
      let columns = ['Date', 'Prescriptions entered in last 30 days']
      let data = []

      this.report_data_raw.forEach((row) => {
        data.push([row.date_of_record, row.quantity_30_day_total || 0])
      })

      csvContent += columns.join(',') + '\n'
      csvContent += data.map(item => item.join(',')).join('\n')

      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "anticipatory_compounding_report.csv");
      document.body.appendChild(link); // Required for FF
      link.click();
    }
  }
}
</script>

<style lang="css">
#ac_chart path.bb-shape.bb-shape.bb-line.bb-line-Quantity-Prescribed {
    fill: white !important;
}
</style>
