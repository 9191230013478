<template>
  <div>
    <el-button type="primary" icon="el-icon-printer" @click="prepare_print" :disabled="print_view" style="margin-bottom: 0.5em; float: right;" plain>Print</el-button>

    <worksheet :task="emulated_task" :printView="print_view" @readyPrint="print_worksheet" ref="worksheet_area" style="clear: both;"></worksheet>
  </div>
</template>

<script>
import worksheet from "@/views/print/lotWorksheet.vue";
export default {
  props: ["lot"],
  components: {
    worksheet
  },
  computed: {
    emulated_task: {
      get() {
        return {
          lot: this.lot,
          formula: { data: this.lot.formula },
          task: {}
        };
      }
    }
  },
  data() {
    return {
      print_view: false,
    }
  },
  methods: {
    prepare_print() {
      this.print_view = true
    },
    print_worksheet() {
      console.log(this.$refs);

      let windowObjectReference;
      let strWindowFeatures =
        "menubar=no,location=no,resizable=yes,scrollbars=yes,status=yes";

      let worksheet_html = this.$refs.worksheet_area.$refs.worksheet_area_inner.innerHTML;
      let head_html = document.querySelector("head").innerHTML;
      let print_html = `
          <html>
          <head>
          ${head_html}
          <style>

            html, body {
              overflow: unset;
              position: unset;
              background: none !important;
              margin: 0.25in;

            }
            body.print {
              height: fit-content !important;
              width: fit-content !important;
              overflow: scroll !important;
              position: unset !important;
              width: 8in !important;
              margin: 0.25in;
              }
              body.print .el-card.box-card.is-always-shadow {
                  overflow: scroll !important;
              }
              body.print .el-card {
                  overflow: scroll !important;
              }

              .formula-tasks .el-form-item {
                  margin-bottom: 0px !important;
              }
              table, table *:not(i) {
                  width: 100% !important;
              }
          </style>
          </head>
            <body class="print">
              ${worksheet_html}
            </body>
          </html>
            `;
      let print_url = "data:text/html;charset=utf-8," + encodeURI(print_html);
      windowObjectReference = window.open(
        "",
        "Compounding Worksheet",
        strWindowFeatures
      );
      windowObjectReference.document.write(print_html);
      this.print_view = false

      // windowObjectReference.document.write(
      //   '<iframe src="' +
      //     print_url +
      //     '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>'
      // );
      console.log(print_url);
    }
  }
};
</script>

<style>
</style>
