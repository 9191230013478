<template>
  <div>
    <div class="btn-container">
      <el-button type="primary" icon="el-icon-printer" @click="print_worksheet()" s plain>Print</el-button>
    </div>

    <el-backtop target=".main" right="22"><i class="el-icon-caret-top"></i></el-backtop>
    <el-card class="box-card formula_worksheet_container">

      <div slot="header" class="clearfix">
        <span>
          <span style="font-size: 1.5em">{{ formula.name }}</span>
          &nbsp;
          <span class="text-secondary">[{{formula.identifiers.formula_id}}]</span>
        </span>
        <span class="formula-status">{{ formula.formula_status }}</span>
      </div>
     <!-- options -->
         <div class="formula-options">
        <el-row :gutter="10" :span='24'>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <span class="indicators">
              <span class="indicator" style="color: Tomato;" v-if="formula.formula_information && formula.formula_information.is_hazardous">
                <i style="font-size: 1.0rem;" class="fad fa-flask-poison"></i>&nbsp;Hazardous
              </span>
              <span class="indicator" v-if="formula.formula_information && formula.formula_information.dea_schedule">
                <i style="font-size: 1.0rem;" class="fad fa-exclamation-triangle"></i>&nbsp;{{ formula.formula_information.dea_schedule.description }}
              </span>

            </span>
            <div style="float: right; padding: 5px; margin-right: 1.5em">

            </div>
          </el-col>
        </el-row>

        <el-row :gutter="10">
          <el-col :xs="16" :sm="16" :md="16" :lg="16" :xl="12">
            <div class="input-label top">Formula Name </div>
            <div class="text-secondary"> {{ formula.name}}</div>
          </el-col>
          <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4">
            <div class="input-label top">Formula ID</div>
             <div class="text-secondary"> {{ formula.identifiers.formula_id}}</div>
          </el-col>
          <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4">
            <div class="input-label top">NDC</div>
            <div class="text-secondary"> {{ formula.identifiers.ndc}}</div>
          </el-col>
        </el-row>
        <el-row>
          <div style="margin-top: 15px;">
            <!-- <div class="bottom clearfix">
              <el-button type="primary">
                <i class="fal fa-save"></i> Save
              </el-button>
            </div>-->
          </div>
      </el-row>
        <el-row :gutter="10">
          <el-col :xs="6" :sm="6" :md="6" :lg="4" :xl="2">
            <div style="">
                   <span><i :class=" formula.options.is_lab_stock ? 'fal fa-check-square' : 'fal fa-square'"></i> Add as
                  ingredient?</span>
            </div>
          </el-col>
          <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4">
            <div class="input-label top">Formula Type</div>
            <div class="text-secondary"> {{ formula_types[formula.formula_type] ? formula_types[formula.formula_type].name : formula.formula_type}}</div>
          </el-col>
          <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4">
            <div class="input-label top">Dosage Form</div>
            <div >
              <BaseDosageForm v-model="formula.dosage_form" :readonly="true"/>
            </div>

          </el-col>

          <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4">
            <div class="input-label top">Route of Administration</div>
            <div>
              <BaseRouteOfAdministration
                :route="formula.route_of_administration"
              />
            </div>
          </el-col>

          <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4">
            <div style="margin: 0.75em">
              <span><i :class=" formula.options.sterile_indicator ? 'fal fa-check-square' : 'fal fa-square'"></i> Sterile</span>
            </div>
          </el-col>

          <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4">
            <div class="input-label top">Therapy Class</div>
            <div v-if="formula.locked">
              <span v-if="formula.compound_class">{{ compound_class_description() }}</span>
            </div>
            <div v-else>
              <div class="text-secondary"> {{compound_class_description()}}</div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <div style="margin-top: 15px;">
            <!-- <div class="bottom clearfix">
              <el-button type="primary">
                <i class="fal fa-save"></i> Save
              </el-button>
            </div>-->
          </div>
         </el-row>
        <el-row :gutter="10">
            <el-col :xs="4" :sm="4" :md="4" :lg="3" :xl="2">
            <div class="input-label top">Task Points</div>
            <div class="text-secondary"> {{ formula.task_points}}</div>
          </el-col>
          <el-col :xs="4" :sm="4" :md="4" :lg="3" :xl="2">
            <div class="input-label top">Required Time</div>
            <div class="text-secondary"> {{ formula.task_time_required}}</div>
          </el-col>
          <el-col :xs="4" :sm="4" :md="4" :lg="3" :xl="2">
            <div class="input-label top">Location</div>
            <div class="text-secondary"> {{ formula.location}}</div>
          </el-col>

          <el-col :xs="5" :sm="5" :md="5" :lg="3" :xl="2">
            <div class="input-label top">Stability Span</div>
            <div class="text-secondary"> {{ formula.options.stability_span}}</div>
          </el-col>

          <el-col
            :xs="6"
            :sm="6"
            :md="6"
            :lg="5"
            :xl="2"
            v-if="compare_array( formula.dosage_form, ['C25158'])"
          >
            <div class="input-label top">Capsule Weight</div>
            <div class="text-secondary"> {{ formula.options.capsule_weight}}</div>
          </el-col>

          <el-col
            :xs="5"
            :sm="5"
            :md="5"
            :lg="4"
            :xl="1"
            v-if="compare_array( formula.dosage_form, ['C69068', 'C68996', 'C29012', 'C29269', 'C42893', 'C42894', 'C42912', 'C42941', 'C42953', 'C42955', 'C42996', 'C64880', 'C43001', 'C43002'])"
          >
            <div class="input-label top">Flavor</div>
            <div class="text-secondary"> {{ formula.options.flavor}}</div>
          </el-col>

          <el-col :xs="10" :sm="10" :md="10" :lg="8" :xl="4">
            <div v-if="formula.formula_status == 'active' || formula.formula_status == 'retired'">
              <div>
                Status:
                <el-button
                  size="mini"
                  class="status-button"
                  :type="formula.formula_status == 'active' ? 'success' : 'default'"
                >{{formula.formula_status}}</el-button>&nbsp;
              </div>

              <div v-if="formula.date_verified">Verified: {{formula.date_verified | moment('YYYY-MM-DD')}} by {{formula.verified_by}}</div>
              <div>Last Modified: {{formula.date_modified | moment('YYYY-MM-DD')}}  by {{formula.modified_by}}</div>
            </div>
          </el-col>
        </el-row>

        <el-row>
          <div style="margin-top: 15px;">
            <el-col :span="12">
              <div class="sub-title">Auxiliary Labels</div>
            </el-col>
          </div>
        </el-row>

        <el-row style="margin-top: 15px;">
          <span
            class="auxiallary_button"
            v-for="label in formula.options.auxiliary_labels"
            :key="label.id"
            :xs="6"
            :sm="6"
            :md="6"
            :lg="4"
            :xl="1"
          >
            <el-button size="small" :type="label.type" :round="true">{{label.value}}</el-button>

          </span>
        </el-row>

      </div>
      <!-- ingredients component-->
      <printableingredients :ingredients="formula.ingredients" :form="form" :terminology="terminology"
        :locked="formula.locked"
        :formula_quantity="formula.formula_quantity"
        :unit_of_measure="formula.unit_of_measure"
       >
      </printableingredients>
    <!-- instructions component -->
    <printableinstructions :instructions="formula.instructions" :formula_type_instructions="formula_types[formula.formula_type] ? formula_types[formula.formula_type].instructions : null" ></printableinstructions>

     <!-- tasks compnent -->
     <printabletasks :tasks="formula.tasks" :form="form" :formula_type_tasks="formula_types[formula.formula_type] ? formula_types[formula.formula_type].tasks : null"></printabletasks>
      <!-- details here  -->
      <el-row>
        <div style="margin-top: 20px;">
          <h4>
            <i style="margin-right: .5em" class="fal fa-angle-right"></i>
            <span class="sub-title">Details</span>
          </h4>

          <el-col :span="24">
           <printabledetails :details="formula.details" :form="form" @updateDetails="updateDetails"></printabledetails>
          </el-col>
          <el-col :span="24">

            <el-row>
              <el-col :span="24">
                <documentsList :documents_s="documents_s" @deleteDoc="deleteDoc" :hideDelete="true" />
              </el-col>
            </el-row>
          </el-col>
        </div>
      </el-row>

      <anticipatoryCompoundReport :ndc="formula.identifiers.ndc" />

      <!-- history component  -->
     <printablehistory :history="formula.change_log"></printablehistory>

      <el-row>
        <div style="margin-top: 15px;">
          <!-- <div class="bottom clearfix">
            <el-button type="primary">
              <i class="fal fa-save"></i> Save
            </el-button>
          </div>-->
        </div>
      </el-row>
    </el-card>

  </div>
</template>
<script>
const uuidv4 = require("uuid/v4");
import NProgress from "nprogress";
const moment = require("vue-moment");
import TaskService from "@/services/task.js";
import url_for from "@/services/pmk_url_for";
import Common from "@/common.js";
import printableingredients from "@/views/formula/printable/ingredients.vue"
import printableinstructions from "@/views/formula/printable/instructions.vue"
import printabletasks from "@/views/formula/printable/tasks.vue"
import printabledetails from "@/views/formula/printable/details.vue"
import printablehistory from "@/views/formula/printable/history.vue"
import subsequentTasks from '@/components/formula/subsequentTasks'
import * as validators from '@/utils/validators.js'
import documentsList from '@/components/DocumentList.vue'
import anticipatoryCompoundReport from '@/components/formula/anticipatory_compound_report.vue'

const datetime_picker_options = {
  default: {
    // double check the set times for each short cut
    shortcuts: [
      {
        text: "2 hours",
        onClick(picker) {
          const end = new Date();
          const start = new Date();
          end.setTime(start.getTime() + 3600 * 1000 * 2);
          picker.$emit("pick", [start, end]);
        }
      },
      {
        text: "4 hours",
        onClick(picker) {
          const end = new Date();
          const start = new Date();
          end.setTime(end.getTime() + 3600 * 1000 * 4);
          picker.$emit("pick", [start, end]);
        }
      },
      {
        text: "24 hours",
        onClick(picker) {
          const end = new Date();
          const start = new Date();
          end.setTime(end.getTime() + 3600 * 1000 * 24);
          picker.$emit("pick", [start, end]);
        }
      },
      {
        text: "3 days",
        onClick(picker) {
          const end = new Date();
          const start = new Date();
          end.setTime(end.getTime() + 3600 * 1000 * 24 * 3);
          picker.$emit("pick", [start, end]);
        }
      },
      {
        text: "Next week",
        onClick(picker) {
          const end = new Date();
          const start = new Date();
          end.setTime(end.getTime() + 3600 * 1000 * 24 * 7);
          picker.$emit("pick", [start, end]);
        }
      }
    ]
  }
};

export default {
  props: {
    task_id: {
      type: [Number, String],
      required: false
    }
  },
  components: {
    printableingredients,
    printableinstructions,
    printabletasks,
    printabledetails,
    printablehistory,
    subsequentTasks,
    documentsList,
    anticipatoryCompoundReport,

  },
  beforeRouteUpdate (to, from, next) {
    let self = this
    let formulaFromStore = JSON.parse(JSON.stringify(this.$store.getters["formula/get_formula"]))
    // if the formula in the store is the same requested we fetch that
    if(formulaFromStore.identifier == to.params.formula_identifier){
      this.formula = formulaFromStore;
    }else{
      // otherwise we get the formula from server( in case we are going back from copy to original )
      this.$store
          .dispatch("formula/fetchFormula", to.params.formula_identifier)
          .then(data => {
            self.formula = self.formulaLocal;
            next();
          });
    }

    next()
  },
  data() {
    return {
      lotReqRules: {
        quantity : [
          { trigger:'blur', validator:validators.validateNumbers}
        ],
        date: [
          { validator: validators.validateDatesArray, trigger: 'blur'}
        ]
      },

      zeb: null,
      lot_request_dialogFormVisible: false,
      lot_request_post_approval_dialogFormVisible: false,
      lot_request: {},
      formula_instructions_form: '',
      activeDetailSections: ["formula_type_instructions","formula_type_tasks"],
      document_category_titles: {
        method_of_suitability: "Method of suitability",
        patient_handout: "Patient Handouts",
        sterility: "Sterility",
        stability: "Stability",
        testing: "Testing",
        other: "Other"
      },

      options: {
        // formula_types: [
        //   "capsule",
        //   "capsule-oil",
        //   "tablet",
        //   "cream",
        //   "liquid",
        //   "injectable",
        //   "pellet",
        //   "troche",
        //   "drops",
        //   "lozenge",
        //   "suspension",
        //   "other"
        // ],
        dosage_forms: [
          "capsule",
          "capsule-oil",
          "tablet",
          "cream",
          "liquid",
          "injectable",
          "pellet",
          "troche",
          "drops",
          "lozenge",
          "suspension",
          "other"
        ],
        units: [
          "mg",
          "gm",
          "ml",
          "mcg",
          "qs",
          "tab",
          "cap",
          "cc",
          "each",
          "unit",
          "vial",
          "bottle",
          "lozenge",
          "troche",
          "tube",
          "drop",
          "suppository",
          "IU"
        ]
      },
      categories: this.$store.getters["formula/get_document_categories"],
      form: this.createFreshFormObject(),
      dialogReworkVisible: false,
      dialogDescribeChangesVisible: false,
      documents_s: {},
      formula : null,
      local_vars: {}

      //formula: this.createFreshFormulaObject()
    };
  },
  computed: {

    formulaLocal: {
      get() {
        if (this.$store.getters["formula/get_formula"]) {
          return this.$store.getters["formula/get_formula"]
        } else return {};
      },
      set (value) {
        this.$store.commit('SET_WORKING_FORMULA', value)
      }
    },
    user: {
      get() {
        return this.$store.getters["account/profile"];
      }
    },
    terminology: {
      get() {
        return this.$store.getters["terminology/terminology"];
      }
    },

    formula_types: {
      get() {
        if (!this.$store.getters["formula/get_lab_config"]) {
          this.$store.dispatch("formula/fetchLabConfig");
        }
        if (this.$store.getters["formula/get_lab_config"]) {
          return this.$store.getters["formula/get_lab_config"]["config"][
            "formula_types"
          ];
        } else return {};
      }
    },

    formula_task: {
      get() {
        return this.$store.getters["task/queryTasks"];
      }
    },
    datetime_picker_options: {
      get() {
        let picker =
          datetime_picker_options.default ||
          this.$store.getters["task/datetime_picker_options"];
        return picker;
      }
    }
  },
  methods: {
    createCopy(){
      let newId = uuidv4();
      this.$store
          .dispatch("formula/copyFormula", newId).then((resp) => {
            console.log(resp)
            this.$router.push({
              name: "formula-manage",
              params: { formula_identifier: resp.identifier }
            });
          })
    },
    updateFormulaInstructions(e){
       this.local_vars.instructions = e
    },
    updateIngredients(e){
       this.local_vars.ingredients = e
    },
    updateTasks(e){
     this.local_vars.tasks = e
    },
    updateDetails(e){
      this.local_vars.details = e
    },
    updateFormulaQty(e){
      this.local_vars.formula_quantity = e
    },
    updateFormulaUnit(e){
      this.local_vars.unit_of_measure = e
    },
    deleteDoc(tab, item) {
      var index = tab.indexOf(item);
      if (index > -1) {
        tab.splice(index, 1);
      }
    },
    updateDocuments(_document) {
      let to_append = {};
      to_append.name = _document.name;
      to_append.note = _document.note;
      to_append.type = _document.type;
      to_append.identifier = _document.identifier;
      let to_append_list = [];
      to_append_list.push(to_append);
      let this_document_category_list = {};
      let new_documents_list;
      this_document_category_list["category"] = _document.category;
      this_document_category_list["documents"] = to_append_list;
      if (this.documents_s.length == 0) {
        new_documents_list = this_document_category_list;
        this.documents_s.push(this_document_category_list);
      } else {
        let sear = this.arrayContainsCategory(this.documents_s, _document.category);
        if (sear.contains) {
          this.documents_s[sear.index]["documents"].push(to_append);
        } else {
          let to_add_in_list= {};
          to_add_in_list["category"] = _document.category;
          to_add_in_list["documents"] = to_append_list;
          this.documents_s.push(to_add_in_list);
        }
      }
    },
    arrayContainsCategory(tabb, categ) {
      let res = false;
      let i = 0;
      let pos = i;
      tabb.forEach(function(params) {
        if (params.category == categ) {
          res = true;
          pos = i;
        }
        i++;
      });
      return { contains: res, index: pos };
    },
    get_document_url(identifier, args={}) {
      return Common.get_document_url(identifier, args);
    },
    compound_class_description() {
      let classes = this.$store.getters["terminology/compound_class"];
      if (this.formula.compound_class && classes[this.formula.compound_class]) {
        return classes[this.formula.compound_class].description;
      }
    },
    go_to_task(task_id) {
      console.log("goto", task_id);
      this.$router.push({ name: "task-view", params: { id: task_id } });
      console.log("goto-end", task_id);
    },
    record_auxiliary_label(label) {
      // console.log(this.formula.options);
      this.formula.options.auxiliary_labels.push(label);
      this.form.new_auxiliary_label = "";
      this.form.new_auxiliary_label_type = "default";
    },
    remove_auxiliary_label(label_index) {
      console.log(label_index);
      this.formula.options.auxiliary_labels.splice(label_index, 1);
    },
    auxiliarySearch(queryString, cb) {
      let labels = this.$store.getters["formula/get_lab_config"]["formula"][
        "auxiliary_labels"
      ];
      console.log({ labels });

      let results = queryString
        ? Object.values(labels)
            .map(function(item) {
              return {
                label_text: item.data.label_text,
                label: item.data.label_text,
                value: item.data.identifier
              };
            })
            .filter(function(queryString) {
              return label => {
                return (
                  label.label_text
                    .toLowerCase()
                    .indexOf(queryString.toLowerCase()) === 0
                );
              };
            })
        : Object.values(labels).map(function(item) {
            return { label: item.data.label_text, value: item.data.identifier };
          });
      // call callback function to return suggestions
      console.log(results);
      cb(results);
    },
    compare_array(item, array) {
      let compare_function = function(array_item) {
        return item == array_item;
      };

      let is_in_array = array.some(compare_function);
      return is_in_array;
    },
    calculate_total() {
      let total_quantity = 0;
      this.formula.ingredients.forEach(function(ingredient) {
        let unit_of_measure = (
          ingredient.formula_unit_of_measure ||
          ingredient.unit_of_measure ||
          ""
        ).toUpperCase();
        if (["C28252", "C28253", "C48155"].includes(unit_of_measure)) {
          total_quantity += parseFloat(ingredient.quantity) || 0;
        }
      });
      return total_quantity;
    },
    createFreshFormObject() {
      const user = this.$store.getters["account/profile"];

      return {
        rework_data: { reason: "" },
        rework_reason: "",
        reason_for_change: "",
        workflow: {},
        identifiers: { formula_id: "" },
        category: "",
        author: user.name,
        verified_by: "",
        date_verified: "",
        formula_status: "draft",
        name: "",
        description: "",
        location: "",
        date_created: "",
        documents: {},
        change_log: [],
        options: {
          auxiliary_labels: [],
          is_lab_stock: false,
          stability_span: ""
        },
        instructions: "",
        tasks: [],
        hazard_level: 0,
        ingredients: [],
        new_ingredient: {
          description: "",
          id: "",
          quantity: "",
          note: "",
          identifiers: [
            {
              code: "",
              system: ""
            }
          ]
        }
      };
    },
    formula_details_table() {
      this.formula.details;
    },
    add_ingredient(selected_ingredient) {
      // this.form.new_ingredient = selected_ingredient
      this.formula.ingredients.push(selected_ingredient);
      //selected_ingredient = {}
      this.form.new_ingredient.description = "";
      return;
    },
    remove_ingredient(ingredient_index, ingredient) {
      console.log(ingredient);
      this.formula.ingredients.splice(ingredient_index, 1);
    },
    createFilter(queryString) {
      return result => {
        return (
          result.description
            .toLowerCase()
            .indexOf(queryString.toLowerCase()) === 0
        );
      };
    },
    /* tasks */
    remove_formula_task(task_index, task) {
      // TODO: this mutates the store and might not be reactive
      this.formula.tasks.splice(task_index, 1);
    },
    attachComponentsData(){
      if(this.local_vars.details){
        this.formula.details = this.local_vars.details
      }
      if(this.local_vars.instructions){
        this.formula.instructions = this.local_vars.instructions
      }
      if(this.local_vars.ingredients){
        this.formula.ingredients = this.local_vars.ingredients
      }
      if(this.local_vars.unit_of_measure){
        this.formula.unit_of_measure = this.local_vars.unit_of_measure
      }
      if(this.local_vars.tasks){
        this.formula.tasks = this.local_vars.tasks
      }
      if(this.local_vars.formula_quantity){
        this.formula.formula_quantity = this.local_vars.formula_quantity
      }
    },
    save_formula(args) {
      let user = this.$store.getters["account/profile"];
      this.attachComponentsData()
      let formula_mutable = { ...this.formula };
      formula_mutable.formula_status = args.formula_status;
      // save formula
      // saving formula should know what to do with it, i.e. put it in a task queue for edit or review, etc
      if (args.workflow) {
        formula_mutable.workflow = args.workflow;
      } else {
        delete formula_mutable.workflow;
      }
      if (args.task) {
        // this is now taken care of by backend
        // console.log('args.task', args.task)
        if (args.task.task_id && args.task.status == "complete") {
          this.complete_task(args.task.task_id, args.task);
        }
      }
      if (args.formula_status == "proposed") {
        if (args.change_log_entry) {
          args.change_log_entry.modified_by = formula_mutable.modified_by =
            user.name;
          args.change_log_entry.date_modified = formula_mutable.date_modified = new Date().toJSON();
          // set change_log entry:
          if ("undefined" != typeof formula_mutable.change_log) {
            formula_mutable.change_log.push(args.change_log_entry);
          } else {
            formula_mutable = {
              ...formula_mutable,
              ...{ change_log: [args.change_log_entry] }
            };
          }
        }
      }

      if (args.formula_status == "active") {
        formula_mutable.verified_by = user.name;
        formula_mutable.date_verified = new Date().toJSON();
      } // active
      // setting documents
      formula_mutable.documents = this.documents_s;
      this.record_entry(formula_mutable);
    },
    record_entry(formula_mutable) {
      // hack to be compatible as an ingredient:
      if (!formula_mutable.code) {
        formula_mutable.code = formula_mutable.identifiers.formula_id;
      }

      let tmp_subsequent_tasks = [...(formula_mutable.subsequent_tasks || [])]

      NProgress.start();
      this.$store
        .dispatch("formula/saveFormula", formula_mutable)
        .then(response => {
          console.log({ response });
          console.log({
            success: response.success,
            status: response.data.data.formula_status,
            ttype: typeof tmp_subsequent_tasks,
            tmp_subsequent_tasks: tmp_subsequent_tasks
          });
          if (
            response.data.success &&
            response.data.data.formula_status == "active"
          ) {
            // deal with subsequent tasks, at this point they have been cleared.  This is done
            // because we do not know if the formula has had enough reviewers yet:
            if (
              "undefined" != typeof tmp_subsequent_tasks &&
              tmp_subsequent_tasks.length
            ) {
              let tasks_to_remove = [];
              tmp_subsequent_tasks.forEach(
                (new_task_object, task_index, tasks) => {
                  console.log({ new_task_object });
                  TaskService.schedule_task(new_task_object)
                    .then(() => NProgress.done())
                    .then(function() {
                      tasks_to_remove.push(task_index);
                      const notification = {
                        type: "success",
                        message: "Task scheduled"
                      };

                      self.$store.dispatch("notification/add", notification, {
                        root: true
                      });
                    });
                }
              ); // each task
              // remove in reverse so the size does not shrink
              tasks_to_remove.reverse().forEach(remove_index => {
                formula_mutable.subsequent_tasks.splice(remove_index, 1);
              });
            } // subsequent tasks
          }

          // this.product = this.createFreshEventObject()
          // this.form = this.createFreshEventObject()

          // EL-29: do we need to set formula_mutable?
          // $store.dispatch("formula/saveFormula", formula_mutable) sets/commits SET_WORKING_FORMULA with data returned by the server.
          // with the recent changes do we need to need to also set formula_mutable and anything else here?
          // BACKGROUND: the formula.formula_status we send to the server might not be the status the server returns back.
          this.formula = this.formulaLocal
          this.formula.saved = true;
        })
        .then(() => NProgress.done())
        .catch(() => {
          NProgress.done();
        });
    },
    search_ingredients(queryString, cb) {
      // here we need to query the ingredient list API for manufacturer identifiers
      let query_object = {
        explicit: 0,
        method: "LIKE",
        description: queryString,
        include_formulas: 1
      };
      NProgress.start();
      this.$store
        .dispatch("ingredient/listIngredients", query_object)
        .then(() => {
          cb(
            this.$store.getters["ingredient/ingredient_search_list"].map(
              item => item.data
            )
          );
        })
        .then(() => NProgress.done())
        .catch(() => {
          NProgress.done();
        });
    },
    complete_task(parameter_task_id, task_data) {
      console.log(parameter_task_id);
      NProgress.start();
      this.$store
        .dispatch("task/complete_task", {
          task_id: parameter_task_id,
          task_data: task_data
        })
        .then(() => NProgress.done())
        .catch(() => {
          NProgress.done();
        });
    },
    toggleReworkVisibility() {
      return;
    },
    create_formula_lot_task(args = {}) {
      // somewhere, display a modal to request the parameters for this task type and save to lot_request
      //      let lot_request = {}
      let self = this;
      let create_as_subsequent_task = args.create_as_subsequent_task;
      this.$refs['lotReqForm'].validate((valid) => {
        if(valid){
          if(!create_as_subsequent_task ){
            self.lot_request_dialogFormVisible = false;
          }else{
            self.lot_request_post_approval_dialogFormVisible = false;
          }


          console.log("this.lot_request", this.lot_request);
          console.log("store.formula", this.$store.getters["formula/get_formula"]);
          console.log("this.formula", this.formula);
          let task_sub_title = `Formula ID: ${this.formula.formula_id ||
            this.formula.identifiers.formula_id} | ${
            this.formula_types[this.formula.formula_type]
              ? (this.formula_types[this.formula.formula_type].name || '')
              : ""
          }`;

          let task_details_indicators = []
          if (this.formula.formula_information) {

            if (this.formula.formula_information.is_hazardous) {

              task_details_indicators.push(
                `<span class="indicator" style="color: Tomato;">
                  <i style="font-size: 1.0rem;" class="fad fa-flask-poison"></i>&nbsp;Hazardous
                </span>`
              )
            }
            if (this.formula.options.sterile_indicator) {

              task_details_indicators.push(
                `<span class="indicator">
                  <i style="font-size: 1.0rem;" class="fad fa-exclamation-triangle"></i>&nbsp;Sterile
                </span>`
              )
            }
          }
          let title_details = `QTY: ${this.lot_request.quantity}` + ( this.lot_request.task_note ?  ` Note: ${this.lot_request.task_note.substr(0, 40)}...` : '')

          if (task_details_indicators.length > 0) {
            title_details = title_details.concat(task_details_indicators.join("\n"))
          }
          let task_data = {
            formula: { identifier: this.formula.identifier },
            lot: { quantity: this.lot_request.quantity },
            title: this.formula.name || this.formula.description,
            sub_title: task_sub_title,
            title_details: title_details,
            task_note: this.lot_request.task_note

          };

          let task_utc_dates = [
            this.$moment(this.lot_request.date[0])
              .utc()
              .format(),
            this.$moment(this.lot_request.date[1])
              .utc()
              .format()
          ];
          let new_task_object = {
            task_type: "lot_create",
            task_status: "new",
            task_data: task_data,
            title: this.formula.name || this.formula.description,
            sub_title: task_sub_title,
            // title_details: `QTY: ${this.lot_request.quantity}` + ( this.lot_request.task_note ?  ` Note: ${this.lot_request.task_note.substr(0, 32)}...` : '') ,
            // task_note: this.lot_request.task_note,

            data_id: this.formula.id,
            order_id: this.lot_request.order_id,
            patient_id: this.lot_request.patient_id,
            prescriber_id: this.lot_request.prescriber_id,
            date_scheduled: task_utc_dates[0],
            date_due: task_utc_dates[1],
            task_priority: this.lot_request.priority
          };
          if (create_as_subsequent_task) {
            self.formula.subsequent_tasks = [
              ...(self.formula.subsequent_tasks || []),
              ...[new_task_object]
            ];
            NProgress.start();
            TaskService.schedule_subsequent_formula_task({
              formula_identifier: self.formula.identifier,
              subsequent_tasks: [new_task_object]
            }) // patch_preserve is used so that other users creating tasks at the same time will not destroy them
              .then(() => NProgress.done())
              .then(function() {
                const notification = {
                  type: "success",
                  message:
                    "Task will be scheduled once formula changes are approved"
                };

                self.$store.dispatch("notification/add", notification, {
                  root: true
                });
                self.lot_request = {}
              })
              .catch(() => {
                NProgress.done();
              });
          } else {
            NProgress.start();
            TaskService.schedule_task(new_task_object)
              .then(() => NProgress.done())
              .then(function() {
                const notification = {
                  type: "success",
                  message: "Task scheduled"
                };

                self.$store.dispatch("notification/add", notification, {
                  root: true
                });
                self.lot_request = {}
              })
              .catch(() => {
                NProgress.done();
              });
      }

        }
      })


    },
    print_worksheet(){
      let windowObjectReference;
      let strWindowFeatures =
        "menubar=no,location=no,resizable=yes,scrollbars=yes,status=yes";

      let worksheet_html = document.getElementsByClassName("formula_worksheet_container")[0].innerHTML;
      let head_html = document.querySelector("head").innerHTML;
      let print_html = `
          <html>
          <head>
          ${head_html}
          <style>

            html, body {
              overflow: unset;
              position: unset;
              background: none !important;
              margin: 0.25in;

            }
            body.print {
              height: fit-content !important;
              overflow: scroll !important;
              position: unset !important;
              width: fit-content !important;
              margin: 0.25in;
              }
              body.print .el-card.box-card.is-always-shadow {
                  overflow: scroll !important;
              }
              body.print .el-card {
                  overflow: scroll !important;
              }

              .formula-tasks .el-form-item {
                  margin-bottom: 0px !important;
              }
              .el-table__body-wrapper{
                overflow:hidden;
              }
          </style>
          </head>
            <body class="print">
              ${worksheet_html}
            </body>
          </html>
            `;
      let print_url = "data:text/html;charset=utf-8," + encodeURI(print_html);
      windowObjectReference = window.open(
        "",
        "Compounding Worksheet",
        strWindowFeatures
      );
      windowObjectReference.document.write(print_html);

      // windowObjectReference.document.write(
      //   '<iframe src="' +
      //     print_url +
      //     '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>'
      // );
      console.log(print_url);
    }
  },
  mounted() {
    let f = JSON.parse(JSON.stringify(this.$store.getters["formula/get_formula"]))
    this.formula = f;
    // this does not yet exist?
    if (this.task_id) {
      this.$store.dispatch("task/setQueryFilters", { task_id: this.task_id });
    }

    this.documents_s = f.documents.length>0?f.documents: [];
  }
};
</script>




<style scoped>
.location {
  margin-bottom: 0;
}
.location > .icon {
  margin-left: 10px;
}
.event-header > .title {
  margin: 0;
}
.list-group {
  margin: 0;
  padding: 0;
  list-style: none;
}
.list-group > .list-item {
  padding: 1em 0;
  border-bottom: solid 1px #e5e5e5;
}

.formula-options .el-col {
  margin-top: 4px;
}

.auxiallary_button {
  margin-right: 1.5em;
}

.formula-instructions {
  margin: 1.5em 0;
}

.button.bottom {
  margin-top: 13px;
  line-height: 12px;
  padding: 0;
  float: right;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}

.status-button {
  margin-right: 1.25em;
}

.formula-status {
  font-size: 0.8em;
  color: #8a8a8a;
  margin: 0 0.5em;
}
.sub_task-instructions {
  display: inline-block;
  min-width: 25em;
  max-width: 80%;
}
.sub_task-points {
  max-width: 8em;
  margin: 0 1em;
}
.ingredient-remove:hover {
  color: rgb(207, 13, 13);
}

.task-remove:hover {
  color: rgb(207, 13, 13);
}

.ingredient-header {
  font-weight: bold;
  font-size: 1em;
}

.formula-task-options {
  margin-top: 0.35em;
}
</style>
<style>
/* should be global: */
.clickable {
  cursor: pointer;
}
.clickable:hover {
  color: #409eff;
}
.clickable.remove:hover {
  color: rgb(207, 13, 13);
}

.long-placeholder ::placeholder {
  font-size: 0.7em;
}
.long-placeholder ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size: 0.7em;
}
.long-placeholder ::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 0.7em;
}
.long-placeholder :-ms-input-placeholder {
  /* IE 10+ */
  font-size: 0.75em;
}
.long-placeholder :-moz-placeholder {
  /* Firefox 18- */
  font-size: 0.75em;
}
.helper-instructions {
  font-size: 0.8em;
  color: #8a8a8a;
  hyphens: auto;
  overflow-wrap: break-word;
  word-wrap: break-word;
  display: none;
}
.pull-right {
  float: right!important;
  margin-left: 1em!important;
}
.grey-button {
    color: rgb(144, 147, 153) !important;
    background: rgb(244, 244, 245) !important;
    border-color: rgb(211, 212, 214)!important;
}

.grey-button:hover {
    background: #fff !important;
    border-color: #50bfff !important;
    color: #50bfff !important;
}

.auxiallary_button {
    display: inline-block;
    padding-bottom: 0.25em;
}

.indicators {
  font-size: 0.8em;
}

.indicator {
  margin-right: 1em;
}
.btn-container{
    margin-bottom: 0.5em;
    width: 100%;
    display: block;
    text-align: right;
}
</style>
