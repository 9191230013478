<!-- NOTE: come back to this when v-model with compnents is understood better , it should get used in formula/manage.vue -->
<template>
  <span>
        <el-row>
          <div style="margin-top: 15px;">
            <el-col :span="12">
              <div class="sub-title">Auxiliary Labels</div>
              <el-input
                style="width: 50em;"
                placeholder="Add auxiliary Label"
                v-model="form.new_auxiliary_label"
                value-key="label"
              >
                <el-select
                  v-model="form.new_auxiliary_label_type"
                  slot="prepend"
                  placeholder="Type"
                >
                  <el-option label="Yellow" value="warning"></el-option>
                  <el-option label="Red" value="danger"></el-option>
                  <el-option label="Default" value="default"></el-option>
                </el-select>
                <el-button
                  @click="record_auxiliary_label(
                    { label_text: form.new_auxiliary_label,
                      label_type: form.new_auxiliary_label_type

                    }
                  )"
                  slot="append"
                  icon="fal fa-plus-circle"
                ></el-button>
              </el-input>
            </el-col>
          </div>
        </el-row>
        <el-row style="margin-top: 15px;">
          <div
            style="margin: 0.5em;"
            class="auxiallary_button"
            v-for="label in auxiliary_labels_local"
            :key="label.identifier"
            :xs="16"
            :sm="8"
            :md="6"
            :lg="4"
            :xl="1"
          >
          <!-- <pre>{{label}}</pre> -->
            <span v-if="label.label_status == 1">
            <el-button size="small" :type="label.data.label_type || 'default'" :round="true">{{label.data.label_text}}</el-button>
            &nbsp; <i class="fal fa-minus-circle clickable remove"  @click="remove_label(label)"></i>
            </span>
          </div>
          <!-- <pre>{{auxiliary_labels_local}}</pre> -->
        </el-row>

  </span>
</template>

<script>
const uuidv4 = require("uuid/v4");
import Common from "@/common.js";

export default {
  props: {
    value: Object,
    readonly: Boolean
  },

  data() {
    return {
      auxiliary_labels_local: this.value ,
      form: {
        new_auxiliary_label: '',
        new_auxiliary_label_type: ''
      }
    };
  },
  watch: {
  auxiliary_labels_local: {
    deep: true,
    handler(newArray) {
        console.log('changes')
    }
  }
},
  methods: {
    remove_label(label) {
      Common.deleteObjectProperty(this.auxiliary_labels_local, [label.identifier])
      delete this.auxiliary_labels_local[label.identifier]
      // Vue.delete(this.auxiliary_labels_local, label.identifier)
      this.handleInput(this.auxiliary_labels_local)
    },
    record_auxiliary_label(label) {

      let self = this;
        label.identifier = uuidv4()
        let new_label = {}
        // i added label_status field to fix the bug because in the dom you display obly the labes w status 1
        new_label[label.identifier] = { identifier: label.identifier, data: label,label_status:1 }

        this.$set(self.auxiliary_labels_local, label.identifier, new_label[label.identifier])
        this.handleInput(this.auxiliary_labels_local)
        this.form = {
          new_auxiliary_label: '',
          new_auxiliary_label_type: ''
        }
    },
    handleInput(e) {
      console.log("d", e);
      this.$emit("input", e);
    },
    display() {
      let unit_list = this.form;
      let map = new Map(
        unit_list.map(i => [i.code, i.display || i.description])
      );
      return map.get(this.value) || this.value;
    }
  }
};
</script>




<style scoped>
</style>
