<template>
  <el-menu
    :default-active.sync="activeLink"
    :mode="mode"
    @select="goto"
    :collapse="isCollapse"
    :unique-opened="true"
    background-color="transparent"
    class="main-navigation-menu"
    :class="{'nav-collapsed':isCollapse}"
  >
    <div class="el-menu-item-group__title" style="padding-top: 4px;">
      <span>Apps</span>
    </div>
    <el-menu-item index="/dashboard">
      <i class="mdi mdi-gauge"></i>
      <span slot="title">Dashboard</span>
    </el-menu-item>
    <el-menu-item index="/tasks/list" name="task-list">
      <i class="fal fa-tasks"></i>&nbsp; Task Queue
    </el-menu-item>
    <!-- <el-menu-item index="/tasks/create" name="task-list">
      <i class="fas fa-layer-plus"></i>&nbsp; Create Task
    </el-menu-item> -->

    <div class="el-menu-item-group__title">
      <span>Formulas &amp; Ingredients</span>
    </div>
    <el-submenu index="layout-formulas" popper-class="main-navigation-submenu">
      <template slot="title">
        <i class="fad fa-mortar-pestle fa-lg"></i>
        <span> Formulas</span>
      </template>
      <el-menu-item index="/formula/create" name="formula-create">
        <span slot="title">Create New</span>
      </el-menu-item>
      <el-menu-item index="/formula/search" name="formula-search">
        <span slot="title">Formula Search</span>
      </el-menu-item>
    </el-submenu>
    <el-submenu index="layout-ingredients" popper-class="main-navigation-submenu">
      <template slot="title">
        <i class="fad fa-jug fa-lg"></i>
        <span> Ingredients</span>
      </template>
      <el-menu-item index="/ingredient/manage">
        <span slot="title">Manage Ingredients</span>
      </el-menu-item>
      <el-menu-item :index="!!pmk_config.modules.electriclab['inventory_receipt_2_step'] ? '/ingredient/inventory/receive' : '/ingredient/inventory' ">
        <span slot="title">Add Inventory</span>
      </el-menu-item>
      <el-menu-item index="/ingredient/inventory-search">
        <span slot="title">Ingredient Inventory Search</span>
      </el-menu-item>
    </el-submenu>
    <div class="el-menu-item-group__title">
      <span>Administration</span>
    </div>
    <el-submenu index="icons" popper-class="main-navigation-submenu">
      <template slot="title">
        <i class="fad fa-sliders-h fa-lg"></i>
        <span> Configuration</span>
      </template>

      <el-menu-item index="/equipment/manage" name="equipment-manage">
        <span slot="title">Configure Equipment</span>
      </el-menu-item>
      <el-menu-item index="/manufacturer/manage" name="manufacturers-manage">
        <span slot="title">Manage Manufacturers</span>
      </el-menu-item>
      <el-menu-item index="/formula/manage_types" name="formula-manage-types">
        <span slot="title">Formula Types</span>
      </el-menu-item>
      <el-menu-item index="/configuration/auxiliary_labels" name="configuration-auxiliary-labels">
        <span slot="title">Auxiliary Labels</span>
      </el-menu-item>
      <el-menu-item index="/configuration/system_settings" name="configuration-system-settings">
        <span slot="title">System Settings</span>
      </el-menu-item>
      <el-menu-item v-if="pmk_config.modules.electriclab.user_management && profile.authorization_level >= 4" index="/configuration/create_user" name="configuration-create-user">
        <span slot="title">Create Users</span>
      </el-menu-item>
      <el-menu-item v-if="pmk_config.modules.electriclab.formula_upload" index="/formula/bulk_import" name="formula-bulk-import">
        <span slot="title">Bulk Formula Import</span>
      </el-menu-item>

    </el-submenu>
    <div class="el-menu-item-group__title">
      <span>Analytics and reports</span>
    </div>
    <el-submenu index="reports" popper-class="main-navigation-submenu">
      <template slot="title">
        <i class="fad fa-analytics"></i>
        <span> Reports</span>
      </template

      <el-menu-item-group>
        <el-menu-item index="/reports/report_lots_production_by_date">
         <span slot="title">Production Volume</span>
       </el-menu-item>
       <el-menu-item index="/reports/electric_lab_report_lots_by_date_with_productivity_metrics">
         <span slot="title">Productivity Report</span>
       </el-menu-item>
       <el-menu-item index="/reports/task_report">
         <span slot="title">Task Report</span>
       </el-menu-item>
      </el-menu-item-group>

      <el-menu-item-group>
        <el-menu-item index="/reports/expiring_product_lots">
          <span slot="title">Expiring product lots</span>
        </el-menu-item>
        <el-menu-item index="/reports/lots_entered_by_date">
          <span slot="title">Lots entered by date</span>
        </el-menu-item>
        <el-menu-item index="/reports/lots_using_lot_as_ingredient">
          <span slot="title">Lots using lot as ingredient</span>
        </el-menu-item>
        <el-menu-item index="/reports/lots_containing_controlled_substances">
          <span slot="title">Controlled Substance QOH</span>
        </el-menu-item>
        <el-menu-item index="/reports/controlled_substance_ingredient_inventory">
          <span slot="title">Controlled Raw QOH</span>
        </el-menu-item>
        <el-menu-item index="/reports/inventory_failed_lots">
          <span slot="title">Scrapped Lots</span>
        </el-menu-item>
        <el-menu-item index="/reports/inventory_lot_usage">
          <span slot="title">Lot Usage</span>
        </el-menu-item>
        <el-menu-item index="/reports/report_ingredient_usage_by_date">
          <span slot="title">Raw Ingredient Usage</span>
        </el-menu-item>
      </el-menu-item-group>


      <el-menu-item-group>
        <el-menu-item index="ingredient_list">
          <a href="/api/pharmetika/v5/electric_lab/report/ingredient/list" target="_blank">
            <span slot="title">Ingredient List</span>
          </a>
        </el-menu-item>
        <el-menu-item index="ingredient_list">
          <a href="/api/pharmetika/v5/electric_lab/report/ingredient/list/manufacturer_entries" target="_blank">
            <span slot="title">Ingredient List W/ Entries</span>
          </a>
        </el-menu-item>
        <el-menu-item index="formula_list">
          <a href="/api/pharmetika/v5/electric_lab/report/formula/list" target="_blank">
            <span slot="title">Formula List</span>
          </a>
        </el-menu-item>
        <el-menu-item index="/reports/inventory_ingredient_current">
          <span slot="title">Ingredient Inventory</span>
        </el-menu-item>
        <el-menu-item index="/reports/inventory_formula_current">
          <span slot="title">Formula Inventory</span>
        </el-menu-item>
      </el-menu-item-group>





    </el-submenu>
    <el-menu-item index="/logout">
      <i class="mdi mdi-lock"></i>
      <span>Log Out</span>
    </el-menu-item>
  </el-menu>
</template>


<script>
import { detect } from "detect-browser";
const browser = detect();
import NProgress from "nprogress";
export default {
  name: "Nav",
  props: ["mode", "isCollapse"],
  data() {
    return {
      isIe: true,
      isEdge: true,
      activeLink: null
    };
  },
  computed: {
    pmk_config() {
      return this.$store.getters['app/pmk_config']
    },
    profile() {
      return this.$store.getters['account/profile']
    }
  },
  methods: {
    goto(index, indexPath) {
      if (index.charAt(0) === "/") {
        if(this.$router.currentRoute.path == index){
          this.$router.replace({ path: index, query: { ref: '1' } }).catch((e) => {
            NProgress.done();
            throw e;
          })
        }
        this.$router.push(index);
        this.$emit("push-page", { page: index });
      }
    },
    setLink(path) {
      this.activeLink = path;
    }
  },
  created() {
    if (browser.name !== "ie") this.isIe = false;
    if (browser.name !== "edge") this.isEdge = false;

    this.setLink(this.$router.currentRoute.path);
    this.$router.afterEach((to, from) => {
      this.setLink(this.$router.currentRoute.path);
      //console.log('afterEach', to, from)
    });
    //console.log('this.$router.currentRoute.path', this.$router.currentRoute.path)
  },
  mounted() {
    //console.log('nav component mounted')
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/_variables";

.el-menu {
  border: none;
}
.el-menu::before,
.el-menu::after {
  display: none;
}
.el-submenu,
.el-menu-item {
  .mdi {
    vertical-align: middle;
    margin-right: 5px;
    display: inline-block;
    width: 24px;
    text-align: center;
    font-size: 18px;
  }
}
</style>

<style lang="scss">
@import "../assets/scss/_variables";

.main-navigation-menu {
  transition: width 0.5s;

  &:not(.el-menu--collapse) {
    .el-submenu__title,
    .el-menu-item {
      height: 40px;
      line-height: 40px;
      background-color: transparent !important;
    }

    &:not(.el-menu--horizontal) {
      .el-menu-item,
      .el-submenu {
        position: relative;

        &::before {
          content: "";
          display: block;
          width: 0px;
          height: 1px;
          position: absolute;
          bottom: 10px;
          left: 30px;
          background: $text-color;
          z-index: 1;
          opacity: 0;
          transition: all 0.7s cubic-bezier(0.55, 0, 0.1, 1);
        }
        &:hover {
          &::before {
            width: 100px;
            opacity: 1;
            //left: 50px;
            transform: translate(20px, 0);
          }
        }

        &.is-active {
          &::before {
            background: $text-color-accent;
          }
        }
      }
    }

    .el-submenu.is-opened {
      //background: #edf1f6 !important;
      //background: rgba(223, 228, 234, 0.38) !important;
      position: relative;

      &::after {
        content: "";
        display: block;
        width: 2px;
        position: absolute;
        top: 40px;
        bottom: 10px;
        left: 31px;
        background: $text-color;
        z-index: 1;
      }

      &::before {
        display: none;
      }

      .el-menu-item,
      .el-submenu {
        &::before,
        &::after {
          display: none;
        }
      }
    }

    .el-menu-item-group__title {
      padding: 15px 0 0px 20px;
      color: transparentize($text-color, 0.65);
    }
  }

  .el-submenu__title,
  .el-menu-item:not(.is-active) {
    color: $text-color !important;
    a {
      color: $text-color;
    }
    i {
      color: $text-color;
    }
  }

  &.el-menu--collapse {
    .el-menu-item-group__title {
      padding: 15px 0 0px 0px;
      width: 100%;
      text-align: center;
    }

    .el-submenu__title:hover,
    .el-menu-item:hover {
      background-color: rgba(0, 0, 0, 0.05) !important;
    }
  }

  &.el-menu--horizontal {
    white-space: nowrap;
    /*width: fit-content;
		width: max-content;*/
    overflow: hidden;
    display: table;

    & > * {
      float: inherit !important;
      display: inline-block;
    }
  }

  &.nav-collapsed {
    .el-menu-item,
    .el-submenu__title {
      & > span {
        display: none;
      }
    }
  }
}

.main-navigation-submenu {
  .el-menu {
    background: #fff !important;

    .el-menu-item:not(.is-active) {
      color: $text-color;
      a {
        color: $text-color;
      }
    }
    .el-menu-item:hover {
      background-color: transparentize($background-color, 0.3) !important;
    }
  }
}
.main-navigation-menu .el-menu-item a {
  color: $text-color;
}
</style>
