<template>
    <div>
      <el-card
        class="box-card"
        style="margin-bottom: 1.5em;"
      >
        <div slot="header" class="clearfix">
          <span>
            <span style="font-size: 1.5em;margin-right: 1em;">Type: {{item.name || item.description}}</span>
            &nbsp;
            <!-- <span class="text-secondary">{{item_identifier}}</span> -->
          </span>
          <el-button
            type="text"
            @click="collapse_type = !collapse_type">
            <span v-show="collapse_type"><i class="far fa-chevron-double-down"></i></span>
            <span v-show="!collapse_type"><i class="far fa-chevron-double-up"></i></span>
          </el-button>
          <el-popover
            title=" "
            width="200"
            trigger="click">
            Are you sure you want to delete this?
            <el-button @click="removeEntry(item)" type="danger" size="mini" style="float:right; margin: 5px 5px 0px;">Yes</el-button>
            <el-button size="mini" style="float:right;margin: 5px 5px 0px;">No</el-button>
            <el-button slot="reference" class="status-button" style="float: right; padding: 5px; margin: 1em;"><i class="fas fa-minus-circle"></i> Remove</el-button>
          </el-popover>&nbsp;
          <el-button
            type="primary"
            class="status-button"
            style="float: right; padding: 5px; margin: 1em;"
            @click="saveEntry(item, item_identifier)"
          >
            <i class="fal fa-save"></i> Save
          </el-button>&nbsp;
          <el-button
            class="status-button"
            style="float: right; padding: 5px; margin: 1em;"
            @click="copyEntry(item)"
          >
            <i class="far fa-copy"></i> Copy
          </el-button>
        </div>

        <div v-if="!collapse_type">
          <el-row :gutter="10" class="property-row">
            <el-col :span="7">
              <span class="form-label">
                <span style="font-size: 1.5em" class="form-label">Description:</span>
                &nbsp;
                <span class="text-secondary"></span>
              </span>
            </el-col>
            <el-col :span="8">
              <el-input v-model="item.name"></el-input>
            </el-col>
            <el-col :span="2"></el-col>
            <el-col :span="2"></el-col>
          </el-row>

          <el-row :gutter="10" class="property-row">
            <el-col :span="7">
              <span class="form-label">
                <span style="font-size: 1.5em" class="form-label">Requires Station Type:</span>
                &nbsp;
                <span class="text-secondary"></span>
              </span>
            </el-col>
            <el-col :span="12">
                <el-select v-model="item.station_type" placeholder="Station Type">
                    <el-option
                      v-for="item in station_type_options"
                      :key="item.value"
                      :label="item.description"
                      :value="item.value">
                    </el-option>
                </el-select>
            </el-col>
          </el-row>

          <el-row :gutter="10" class="property-row">
            <el-col :span="5">
              <span class="form-label">
                <span style="font-size: 1.5em">Instructions:</span>
                &nbsp;
                <span class="text-secondary"></span>
              </span>
            </el-col>
            <el-col :span="19">
              <el-input
              v-model="item.instructions"
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4}"
              ></el-input>
            </el-col>
          </el-row>

          <el-row :gutter="10" class="property-row">
            <el-col :span="4">
              <span>
                <span style="font-size: 1.5em">
                  <i class="fal fa-tasks"></i> Tasks:
                </span>
                &nbsp;
                <span class="text-secondary"></span>
              </span>
            </el-col>
            <el-col :span="20"></el-col>
            <el-col :span="24" v-if="item.tasks">
              <tasks :tasks="item.tasks" @updateTasks="updateTasks" :entry="item"></tasks>
            </el-col>
          </el-row>
        </div>
      </el-card>
    </div>
</template>
<script>
import tasks from '@/views/formula/parts/tasks';
export default {
  components: { tasks },
  props: {
    item: {
      type: Object,
      required: true,
    },
    item_identifier: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      //      item: {},when
      form: {
        new_task: {}
      },
      formula_types_mutable: this.formula_types,
      collapse_type: true
    };
  },
  computed: {
    station_type_options() {
      const station_types = this.$store.getters['formula/get_station_types']
      return Object.values(station_types)
    }
  },
  methods: {
    echo() {
      console.log('hello')
    },
    saveEntry(item, item_identifier) {
      console.log(item, item_identifier)
      // if (item.name != item_identifier) {
        let current_config = this.$store.getters["formula/get_lab_config"]
        delete current_config.config.formula_types[item_identifier]
        current_config.config.formula_types[item_identifier]=item
        this.$store.dispatch("formula/updateLabConfig", current_config)
        this.formula_types_mutable = {...this.formula_types_mutable, ...current_config.config.formula_types}
    },
    copyEntry(item) {
      this.$emit('copyEntry', item)
    },

    removeEntry(item) {
      console.log('Removed', item)
      item.archived = true;
      this.saveEntry(item)
      this.$emit('update_entry', item)
    },

    updateTasks(new_data) {
      console.log({item: this.item, new_data})
      this.item.tasks = new_data
    },

    add_task(item) {
      console.log(this.formula_types_mutable[item.identifier].tasks);
      console.log('this.form.new_task', this.form.new_task[item.identifier])
      let new_config = {}
      new_config[item.identifier] = this.formula_types_mutable[item.identifier];
      if (typeof this.formula_types_mutable[item.identifier].tasks == "undefined") {
        new_config[item.identifier].tasks = [];
      }
      new_config[item.identifier].tasks.push({
        description: this.form.new_task[item.identifier],
        points: 1,
        weights: [],
        measurements: [],
        image: ""
      });
      this.formula_types_mutable = { ...this.formula_types_mutable, new_config };
      this.form.new_task[item.identifier] = "";
    },
    remove_task(item, task_index) {
        item.tasks.splice(task_index, 1)
    },

    add_item() { // not currently used
      this.$store.dispatch("formula/addFormulaType");
    }

  }
};
</script>

<style scoped>
.property-row {
  margin-bottom: 1em;
}
.sub_task-instructions {
  display: inline-block;
  min-width: 25em;
  max-width: 80%;
}
.sub_task-points {
  max-width: 8em;
  margin: 0 1em;
}

.form-label {
  margin-right: 1.25em;
}
.formula-task-options-label {
  vertical-align: middle;
}
.pull-right {
  margin-left: 1em;
  font-size: 0.5em
}
</style>
