<template>
  <el-dialog
    title="Session has Expired"
    :visible="!authorized"
    width="30%"
    center
  >
    <span class="no-break">Your session has expired. Please click the button below to open a new window to log in without losing your work.</span>
    <span slot="footer" class="dialog-footer">
      <el-button type="warning" @click="closeWindow">Close Window (May Lose Progress)</el-button>
      <el-button type="primary" @click="openReauthWindow">Log In (Opens New Window)</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import pmk_url_for from '@/services/pmk_url_for'

const el_key_name = 'electric_lab_reauthed'

const delay = function(ms) {
  return new Promise(function(resolve) {
    setTimeout(resolve, ms)
  })
}

export default {
  name: "ReauthDialog",
  computed: {
    ...mapGetters('reauth', ['authorized']),
  },
  mounted() {
    this.$store.subscribe((mutation) => {
      if (mutation.type === 'reauth/unset_authorized') {
        localStorage.setItem(el_key_name, 'false')
      } else if (mutation.type === 'reauth/set_authorized') {
        localStorage.setItem(el_key_name, 'true')
      }
    })
  },
  methods: {
    closeWindow() {
      window.close()
    },
    async openReauthWindow() {
      const reauthWindow = window.open(pmk_url_for.url_for('lab_login'), '_blank')
      while (localStorage.getItem(el_key_name) !== 'true') {
        await delay(250)
      }
      await this.$store.dispatch('reauth/set_authorized')
      reauthWindow.close()
    }
  }
}
</script>

<style scoped>
/* why should this even be needed in the modal? dumb. */
.no-break {
  word-break: normal;
}
</style>