<template>
 <div class="formula-instructions">
        <div>Instructions</div>
        <el-input
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 10}"
          placeholder="Instructions"
          v-model.lazy="localInstr"
          @change="updateEmit"
        ></el-input>
      </div>
</template>

<script>
export default {
    name:"instr",
    props: ["instructions"],
    data(){
        return{
            localInstr :this.instructions
        }
    },
    methods: {
        updateEmit(e){
            this.$emit('updateInstructions',e)
        } 
    }
};
</script>

<style>
.formula-instructions {
  margin: 1.5em 0;
}

</style>
