<template>
  <div class="label">
    <div class="label_line_rotated">
      <svg id="barcode-product"></svg>
    </div>

    <section class="label_body">
      <div class="line_1 label_line">Location: G22-T45</div>
      <div class="line_3 label_line lot_details">
        <span class="unbreakable">Date Compounded: 2019-01-23</span> |
        <span class="unbreakable">Beyond Use: 2019-07-22</span> |
        <span class="unbreakable">Verified: MMW</span> |
        <!-- <span class="unbreakable">Lot ID: 10664</span> -->
      </div>

      <div
        class="line_2 label_line product_description"
      >E2 + E3 + P + DHEA - E2 + E3 + P + DHEA - E2 + E3 + P + DHEA / 1 + 5 + 40 + 25 mg/GM Cream - Versa Pro</div>
      <div class="label_line barcode_lot_line">
        <svg id="barcode-lot"></svg>
      </div>
    </section>
  </div>
</template>
<script>
// var JsBarcode = require('jsbarcode')
let lot_b64 = `gbA0qhbEQayDPgAA/6sMNg`
let product = `812340178921`
let lot = { id: 18101 }

export default {
  mounted() {
    console.log('this.$el.textContent') // I'm text inside the component.
    JsBarcode('#barcode-lot', lot_b64, {
      format: 'CODE128',
      width: 1,
      height: 20,
      displayValue: true,
      fontSize: 14,
      text: `Lot ID: ${lot.id.toString().padStart(5, 0)}`
    })

    JsBarcode('#barcode-product', product, {
      format: 'CODE128',
      width: 1,
      height: 15,
      displayValue: true,
      fontSize: 10
    })
  }
}
</script>

<style scoped>
body {
  width: 90mm;
  height: 38mm;
}
.label {
  width: 90mm;
  height: 38mm;
}
.label_body {
  font-size: 0.8em;
  max-width: 90mm;
  max-height: 38mm;
  min-width: 90mm;
  min-height: 38mm;
  border: solid 2px red;
  padding: 0.5em;
  font-family: Futura;
}
.label_line {
  max-width: 80mm;
}
.product_description {
  font-size: 1.2em;
  font-weight: bold;
  display: inline-block;
  float: left;
}
.lot_details {
  font-size: 0.5rem;
}
.label_line_rotated {
  margin-left: -100%;
  margin-top: 2em;
  float: right;
}
.barcode_lot_line {
  max-height: 3em;
}
#barcode-lot {
  margin-right: 3em;
  width: 30em;
}

.label_line_rotated {
  transform-origin: 50% 50%;
  transform: translate(50%, 50%) rotate(90deg);
  max-width: 38mm;

  float: right;
  position: relative;
  /* right: 2em; */
  right: 0.5em;
}
#barcode-product rect {
  max-height: 3em;
}

#barcode-product text {
  max-height: 3em;
}

.unbreakable {
  white-space: nowrap;
}
</style>
