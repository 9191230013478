import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import _ from 'lodash'
//import * as user from '@/store/modules/user.js'
import * as user from "@/store/modules/account.js";
import * as account from "@/store/modules/account.js";
import * as app from  "@/store/modules/app.js";
import reauth from "@/store/modules/reauth";
import * as event from "@/store/modules/event.js";
import * as task from "@/store/modules/task.js";
import * as ingredient from "@/store/modules/ingredient.js";
import * as formula from "@/store/modules/formula.js";
import * as equipment from "@/store/modules/equipment.js";
import * as lot from "@/store/modules/lot.js";
import * as manufacturer from "@/store/modules/manufacturer.js";
import * as terminology from "@/store/modules/terminology.js";
import * as reports from  "@/store/modules/reports.js";

import * as notification from "@/store/modules/notification.js";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

import createPlugin from "logrocket-vuex";

import LogRocket from "logrocket";
const logrocketPlugin = createPlugin(LogRocket);
console.log(reports)
const storeParameters = {
  modules: {
    account,
    user,
    app,
    event,
    task,
    ingredient,
    formula,
    equipment,
    lot,
    notification,
    manufacturer,
    terminology,
    reports,
    reauth
  },
  state: {
    layout: {
      navPos: "left", //top, bottom, left, right, false
      toolbar: "top", //top, bottom, false
      footer: true, //above, below, false
      boxed: false, //true, false
      roundedCorners: false, //true, false
      viewAnimation: "fade-top" // fade-left, fade-right, fade-top, fade-top-in-out, fade-bottom, fade-bottom-in-out, fade, false
    },
    splashScreen: true,
    logged: true,

    /* ElectricLab */

    picker_options: {
      // double check the set times for each short cut
      shortcuts: [
        {
          text: "2 hours",
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            end.setTime(start.getTime() + 3600 * 1000 * 2);
            picker.$emit("pick", [start, end]);
          }
        },
        {
          text: "4 hours",
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            end.setTime(end.getTime() + 3600 * 1000 * 4);
            picker.$emit("pick", [start, end]);
          }
        },
        {
          text: "24 hours",
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            end.setTime(end.getTime() + 3600 * 1000 * 24);
            picker.$emit("pick", [start, end]);
          }
        },
        {
          text: "3 days",
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            end.setTime(end.getTime() + 3600 * 1000 * 24 * 3);
            picker.$emit("pick", [start, end]);
          }
        },
        {
          text: "Next week",
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            end.setTime(end.getTime() + 3600 * 1000 * 24 * 7);
            picker.$emit("pick", [start, end]);
          }
        }
      ]
    },

    socket: {
      isConnected: false,
      message: '',
      reconnectError: false,
      heartBeatInterval: 50000,
      // Heartbeat timer
      heartBeatTimer: 0
    }
  },
  mutations: {
    setLayout(state, payload) {
      if (payload && payload.navPos !== undefined)
        state.layout.navPos = payload.navPos;

      if (payload && payload.toolbar !== undefined)
        state.layout.toolbar = payload.toolbar;

      if (payload && payload.footer !== undefined)
        state.layout.footer = payload.footer;

      if (payload && payload.boxed !== undefined)
        state.layout.boxed = payload.boxed;

      if (payload && payload.roundedCorners !== undefined)
        state.layout.roundedCorners = payload.roundedCorners;

      if (payload && payload.viewAnimation !== undefined)
        state.layout.viewAnimation = payload.viewAnimation;
    },
    setLogin(state, payload) {
      state.logged = true;
    },
    setLogout(state, payload) {
      state.layout.navPos = null;
      state.layout.toolbar = null;
      state.logged = false;
    },
    setSplashScreen(state, payload) {
      state.splashScreen = payload;
    },
    SOCKET_ONOPEN(state, event) {
      Vue.prototype.$socket = event.currentTarget
      state.socket.isConnected = true

      state.socket.heartBeatTimer = setInterval(() => {
        const message = 'PING ' + Math.floor(Math.random()*10000000)
        state.socket.isConnected &&
          Vue.prototype.$socket.sendObj({
            code: 200,
            msg: message
          });
      }, state.socket.heartBeatInterval)
    },
    SOCKET_ONCLOSE(state, event) {
      state.socket.isConnected = false
      clearInterval(state.socket.heartBeatTimer);
      state.socket.heartBeatTimer = 0;
      console.log("The line is disconnected: " + new Date());
      console.log(event);
    },
    SOCKET_ONERROR(state, event) {
      console.error(state, event)
    },
    // default handler called for all methods
    SOCKET_ONMESSAGE(state, message) {
      console.log('SOCKET_ONMESSAGE', JSON.parse(JSON.stringify(_.cloneDeep(message))))
      try {
        if (message.channel === 'tasks') {
          store.dispatch('task/update_task_entry', message.task).then(d => {
            console.log(`task ${message.task.id} updated, data_id ${message.task.data_id}`)
          })
        }
      } catch (error) {
        console.log('SOCKET_ONMESSAGE', error)
      }
      state.socket.message = message
    },
    // mutations for reconnect methods
    SOCKET_RECONNECT(state, count) {
      console.info(state, count)
    },
    SOCKET_RECONNECT_ERROR(state) {
      state.socket.reconnectError = true
    }
  },
  actions: {},
  getters: {
    layout(state, getters) {
      return state.layout;
    },
    navPos(state, getters) {
      return state.layout.navPos;
    },
    toolbar(state, getters) {
      return state.layout.toolbar;
    },
    footer(state, getters) {
      return state.layout.footer;
    },
    boxed(state, getters) {
      return state.layout.boxed;
    },
    roundedCorners(state, getters) {
      return state.layout.roundedCorners;
    },
    viewAnimation(state, getters) {
      return state.layout.viewAnimation;
    },
    isLogged(state, getters) {
      return state.logged;
    },
    splashScreen(state, getters) {
      return state.splashScreen;
    }
  },
  plugins: [createPersistedState({ paths: ["layout"] }), logrocketPlugin],
  strict: debug
}

const store = new Vuex.Store(storeParameters)
export default store
