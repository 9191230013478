<template>
  <div>

    <el-card class="box-card inventory-item-card">
      <div slot="header" class="clearfix">
        <el-row :gutter="20">
          <el-col :xs="24" :sm="18" :md="10">
            <span>
              <span style="font-size: 1.5em">{{lot.data.formula ? lot.data.formula.description : lot.data.description || lot.description}} [<router-link
              :to="{ name: (lot.lot_type == 'formula' ? 'formula-manage' : 'ingredient-manage-item' ), params: { formula_identifier: lot.ingredient_identifier, ingredient_identifier: lot.ingredient_identifier } }"
              target="_blank"
            >{{lot.code || (lot.data && lot.data.formula ? lot.data.formula.identifiers.ndc : 'formula')}}</router-link>]
               </span>
              &nbsp;
              <span class="text-secondary"></span>
            </span>
          </el-col>
          <el-col :span="6">

            <el-button
              type="default"
              class="status-button"
              style="padding: 5px; margin-right: 0.5em;"
              @click="open_label()"
              :disabled="form.label_loading"

            >
              <span v-if="form.label_loading">
                <i class="fas fa-circle-notch fa-spin"></i> Loading...
              </span>
              <span v-else>
                <i class="fal fa-tag"></i> Label
              </span>
            </el-button>

            <el-dropdown split-button style="padding: 5px; margin-right: 0.5em;" @click="unit_dose_label('pdf')" v-if="lab_config.config && lab_config.config.formula && lab_config.config.formula.enable_unit_dose_labels">
              Unit Dose Label (PDF)
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item >
                  <el-button type="text" @click="unit_dose_label('png')">
                    PNG
                  </el-button>
                </el-dropdown-item>
                <el-dropdown-item>
                  <el-button type="text" @click="unit_dose_label('zpl')">
                    ZPL
                  </el-button>
                </el-dropdown-item>
                <el-dropdown-item>
                  <el-popover
                    placement="right"
                    title="300dpi Quantity"
                    width="175"
                    trigger="hover">
                    <el-button slot="reference" type="text" style="width:100%;text-align:left">
                      Send to Printer (300dpi)
                    </el-button>
                    <el-row>
                      <el-input v-model="label_count" style="width: 50%;margin-right:5px;"
                        type="number"
                        inputmode="decimal"></el-input>
                      <el-button type="primary" @click="unit_dose_label('print300')">
                        Print
                      </el-button>
                    </el-row>
                  </el-popover>
                </el-dropdown-item>
                <el-dropdown-item>
                  <el-popover
                    placement="right"
                    title="203dpi Quantity"
                    width="175"
                    trigger="hover">
                    <el-button slot="reference" type="text" style="width:100%;text-align:left">
                      Send to Printer (203dpi)
                    </el-button>
                    <el-row>
                      <el-input v-model="label_count" style="width: 50%;margin-right:5px"
                        type="number"
                        inputmode="decimal"></el-input>
                      <el-button type="primary" @click="unit_dose_label('print203')">
                        Print
                      </el-button>
                    </el-row>
                  </el-popover>
                </el-dropdown-item>
                <el-dropdown-item v-if="is_connected_printer">
                  <el-popover
                    placement="right"
                    title="Label Quantity"
                    width="175"
                    trigger="hover">
                    <el-button slot="reference" type="text" style="width:100%;text-align:left">
                      Send to Printer (Equipment)
                    </el-button>
                    <el-row>
                      <el-input v-model="label_count" style="width: 50%;margin-right:5px"
                        type="number"
                        inputmode="decimal"></el-input>
                      <el-button type="primary" @click="unit_dose_label('print_equipment')">
                        Print
                      </el-button>
                    </el-row>
                  </el-popover>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>

          </el-col>
          <el-col :span="4">
            <el-link  @click="copy_identifier_to_clipboard(lot.identifier, 'identifier')" :type="copied.identifier ? 'success' : 'primary'" :underline="false" >
              <span v-if="!copied.identifier">
                <i class="fal fa-paste" style="font-size: 1.5em;"></i> Copy Lot ID
              </span>
              <span v-else>
                <i class="fal fa-clipboard-check" style="font-size: 1.5em;"></i> Copied!
              </span>
            </el-link>
          </el-col>
          <el-col :span="4" v-if="config.realm_type === 'training'">
            <el-link  @click="copy_identifier_to_clipboard(lot_barcode, 'barcode')" :type="copied.barcode ? 'success' : 'primary'" :underline="false" >
              <span v-if="!copied.barcode">
                <i class="fal fa-barcode-read" style="font-size: 1.5em;"></i> Copy Lot Barcode
              </span>
              <span v-else>
                <i class="fal fa-clipboard-check" style="font-size: 1.5em;"></i> Copied!
              </span>
            </el-link>

          </el-col>
        </el-row>
      </div>

      <el-row :gutter="10">
        <!-- <el-col :span="12">
          <pre>{{lot}}</pre>
        </el-col>-->
        <!-- <el-col :span="12">
          <pre>{{lot.ingredient}}</pre>
        </el-col>-->
        <el-col :xs="24" :sm="18" :md="14" :lg="12" :xl="8">
          <el-table :data="ingredient_table_data()" style="width: 100%" :show-header="false">
            <el-table-column prop="title" label width="180">
              <template slot-scope="scope">
                <span class="break-word">{{ scope.row.title }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="value" label>
              <template slot-scope="scope">
                <span v-html="scope.row.value" v-if="scope.row.value_type=='html'"></span>
                <span v-else class="no-break-word">{{ scope.row.value }}</span>
              </template>
            </el-table-column>
          </el-table>

          <div  style="margin-top: 10px;">
            <el-button @click="display_add_activity_factor = true" v-if="lot.ingredient.has_activity_factor || lot.ingredient.has_pack_stat">
              <span v-if="lot.ingredient.has_activity_factor">
                <span v-if="lot.ingredient.has_pack_stat">
                  Add Activity Factor/Pack Stats
                </span>
                <span v-else>
                  Add Activity Factor
                </span>
              </span>
              <span v-else-if="lot.ingredient.has_pack_stat">
                Add Pack Stats
              </span>
            </el-button>
            <el-button @click="display_adjust_BUD = true"
              v-if="allow_beyond_use_date_modifications && lot.lot_type === 'ingredient'"
            >
              Modify Beyond Use Date
            </el-button>
          </div>

        </el-col>

        <el-col :xs="24" :sm="18" :md="14" :lg="12" :xl="8">
          <div>

<!--             <pre>{{lot.current_inventory}}</pre> -->

<!--
            <el-button type="text"  @click="open_log_inventory_reduction">
              <i class="fal fa-minus-circle clickable remove" ></i> Log Inventory Loss
            </el-button>
            <LogInventoryPrompt @input="dataEntered" @closing="closeModal" :dialogVisible="log_inventory_reduction_dialog_visible" :identifier="lot.identifier"></LogInventoryPrompt>
          </div>
          <div>
            <pre>{{lot.current_inventory}}</pre>
-->

          </div>
          <div>
            <el-table
            class="lossTable"
              :data="lot.lots_used_in"
              height="250"
              style="width: 100%"
              show-summary
              >
              <el-table-column
                prop="date_logged"
                label="Date Recorded"
                width="180">
                <template slot-scope="scope">
                  <span>{{ formatted_date(scope.row.date_logged) }}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="quantity"
                label="QTY"
                width="120">
                  <template slot-scope="scope">
                    {{parseFloat(scope.row.quantity)}}
                    <BaseUnitOfMeasure
                        :unit="(scope.row.formula_unit_of_measure || scope.row.quantity_unit)"
                    />
                </template>

              </el-table-column>
              <el-table-column
                prop="lot_identifier"
                label="Lot"
                min-width="200">
                <template slot-scope="scope">
                  <span v-if="scope.row.details.reason_type === 'lot_scrapped'">
                    <el-tooltip class="item" effect="dark" placement="top-end">
                      <template slot="content">
                        {{ scope.row.details.reason }}
                        {{ scope.row.details.author ? ` Author: ${scope.row.details.author}` : '' }}
                        {{ scope.row.reason_details && scope.row.reason_details.description ? `${scope.row.reason_details.description}` : '' }}
                      </template>
                      <router-link :to="{ name: 'ingredient-inventory-item', params: { identifier: scope.row.lot_identifier } }">
                        <el-button>Scrapped Lot</el-button>
                      </router-link>
                    </el-tooltip>
                  </span>
                  <span v-else-if="scope.row.lot_identifier">
                    <router-link  :to="{ name: 'ingredient-inventory-item', params: { identifier: scope.row.lot_identifier }}"  target="_blank">
                      {{ scope.row.lot_number || 'Lot' }}
                    </router-link>
                  </span>
                  <span v-else-if="scope.row.details.reason_type === 'dispense'">
                    <el-tooltip class="item" effect="dark" placement="top-end">
                      <template slot="content">
                        {{ scope.row.details.reason }}
                        {{ scope.row.details.author ? ` Author: ${scope.row.details.author}` : '' }}
                        {{ scope.row.reason_details && scope.row.reason_details.description ? `${scope.row.reason_details.description}` : '' }}
                      </template>
                      <el-button>Dispense</el-button>
                    </el-tooltip>
                  </span>
                  <span v-else>
                    <el-tooltip class="item" effect="dark" placement="top-end">
                      <template slot="content">
                        <span style="white-space: pre-wrap">
                          {{ scope.row.details.reason }}
                          {{ scope.row.details.author ? ` Author: ${scope.row.details.author}` : '' }}
                          {{ scope.row.reason_details && scope.row.reason_details.description ? `${scope.row.reason_details.description}` : ( scope.row.details.reason_details ? `\n${JSON.stringify(scope.row.details.reason_details, null, 2)}` : '') }}
                        </span>
                      </template>
                      <el-button v-if="scope.row.details.reason_type==='loss-manual'">Loss</el-button>
                      <!-- 'remove_from_inventory' & 'removed_from_inventory' both seem to appear in the data, so we handle both  -->
                      <el-button v-if="scope.row.details.reason_type==='remove_from_inventory'">Removed</el-button>
                      <el-button v-if="scope.row.details.reason_type==='removed_from_inventory'">Removed</el-button>
                      <el-button v-if="scope.row.details.reason_type==='other'">Other</el-button>
                      <el-button v-if="scope.row.details.reason_type==='expired'">Expired</el-button>
                      <el-button v-if="scope.row.details.reason_type==='recalled'">Recalled</el-button>

                    </el-tooltip>
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                label="Recorded by"
                width="180">
                <template slot-scope="scope">
                  <span v-if="scope.row.details.reason_details">
                    {{ scope.row.details.reason_details.recorded_by }}
                  </span>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div style="margin: 15px;">
            <div v-if="lot.data.inventory_type === 'removed'">
              <b>Removed from inventory <i class="fad fa-trash-alt" style="color: tomato; font-size: 1.4em;"></i></b>
              <br>
              <el-button size="small" @click="open_label({reverse_distribution: true})" :disabled="form.label_loading">
                <span v-if="form.label_loading">
                  <i class="fas fa-circle-notch fa-spin"></i> Loading...
                </span>
                <span v-else>
                  <i class="fal fa-tag"></i> Reverse Distribution Label
                </span>
              </el-button>
            </div>
            <div v-else>
              <el-button type="text"  @click="open_log_inventory_reduction">
                <i class="fal fa-minus-circle clickable remove" ></i> Log Inventory Loss
              </el-button>
              <LogInventoryPrompt @closing="closeModal" @input="updateAfterLogLoss()"
                :dialogVisible="log_inventory_reduction_dialog_visible" :identifier="lot.identifier"
                :quantity_unit="lot.unit_of_measure ? lot.unit_of_measure :
                lot.ingredient.unit_of_measure">
              </LogInventoryPrompt>
            </div>
          </div>

        </el-col>

        <el-col class="mt-20" :xs="24" :sm="24" :md="24" :lg="24" :xl="24" >
          <h4>
            <i style="margin-right: .5em" class="fal fa-sticky-note"></i>
            <span>Notes</span>
          </h4>
          <el-table
            class="notesTable"
              :data="notesTableData"
              height="250"
              style="width: 100%"
              >
              <el-table-column
                prop="date_created"
                label="Date"
                width="130"
                >
                <template slot-scope="scope">
                  <span>{{ scope.row.date_created | moment('YYYY-MM-DD') }}</span>
                </template>
              </el-table-column>

              <el-table-column
                prop="note_type"
                label="Type"
                width="150"
                >
              </el-table-column>

              <el-table-column
                prop="note_text"
                label="Text"
                >
              </el-table-column>
              <el-table-column
                prop="author.name"
                label="Author"
                width="180"
                >
                <template slot-scope="scope">
                  <span v-if="scope.row.author.name">
                    {{ scope.row.author.name }}
                  </span>
                  <span v-if="scope.row.author.user_name">
                    {{ scope.row.author.user_name }}
                  </span>
                  <span v-if="scope.row.author.display_name">
                    {{ scope.row.author.display_name }}
                  </span>
                </template>
              </el-table-column>
            </el-table>



            <div style="margin: 15px;">
            <el-button type="text"  @click="open_add_note">
              <i class="fa fa-sticky-note clickable" ></i> Add a note
            </el-button>
            <AddNotePrompt @closing="closeAddNoteModal" @input="updateNotes"
              :dialogVisible="add_note_dialog_visible"
              :identifier="lot.identifier" >
            </AddNotePrompt>
          </div>
        </el-col>
      </el-row>

      <el-row v-if="documents_s ">
        <el-col :span="24">
          <documentsList :documents_s="documents_s" @deleteDoc="deleteDoc" />
          <BaseDocumentUpload
            label="Upload Document"
            :documents.sync="form.documents"
            :value="form.documents"
            :categories="document_categories"
            @update_documents="updateDocuments"
          ></BaseDocumentUpload>
          <takePhoto @update_documents="updateDocuments" />
        </el-col>
      </el-row>

      <el-row v-if="this.lot.data.session_data && config.external_accounts.mouseflow && config.external_accounts.mouseflow.website_id">
        <el-col class="mt-20" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <h4>
            <i style="margin-right: .5em" class="fal fa-users"></i>
            <span>Sessions</span>
          </h4>
          <el-table
            :data="this.lot.data.session_data"
            style="width:100%;"
            height="250">
            <el-table-column
              label="User"
              prop="user.name"
              width="200">
            </el-table-column>
            <el-table-column
              label="Session">
              <template slot-scope="scope">
                <el-button type="text" @click="download_recording(scope.row.sessionid)">
                  {{ formatted_date(scope.row.date_session_started) }} - {{ formatted_date(scope.row.date_session_ended) }}
                </el-button>
              </template>
            </el-table-column>
            <el-table-column
              label="Duration">
              <template slot-scope="scope">
                  {{ date_diff(scope.row.date_session_started, scope.row.date_session_ended) }}
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>

      <el-row>
        <el-col class="mt-20" :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
          <el-collapse @change="get_lot_label_list(lot.identifier)">
            <el-collapse-item name="1">
              <template slot="title">
                <h4>
                  <i style="margin-right: .5em" class="fal fa-tags"></i>
                  <span>Labels</span>
                </h4>
              </template>
              <el-table
                v-if="label_list && label_list.length"
                :data="label_list"
                height="250"
                style="width: 100%"
                >
                <el-table-column
                  prop="requested_by"
                  label="User"
                  width="150"
                  >
                </el-table-column>
                <el-table-column
                  prop="date_requested"
                  label="Date Printed"
                  width="250"
                  >
                  <template slot-scope="scope">
                    <span>{{ scope.row.date_requested | moment('YYYY-MM-DD h:mm:ss a') }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="label_count"
                  label="Label Count"
                  width="150"
                  >
                </el-table-column>
              </el-table>
            </el-collapse-item>
          </el-collapse>
        </el-col>
      </el-row>

      <el-row v-if="lot.data.modifications">
        <el-col :span="24" v-for="mod_type in Object.keys(lot.data.modifications)" :key="mod_type" style="margin-top: 15px;">
          <h4>{{modification_types[mod_type]}} Modifications</h4>
          <el-table
            :data="lot.data.modifications[mod_type]"
            style="width: 100%">
            <el-table-column
              prop="date_modified"
              label="Date Modified"
              width="180">
              <template slot-scope="scope">
                {{scope.row.date_modified | moment('YYYY-MM-DD hh:mm a')}}
              </template>
            </el-table-column>
            <el-table-column
              prop="original_value"
              label="Original Value">
              <template slot-scope="scope">
                <span v-if="typeof scope.row.original_value === 'object'">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    placement="top"
                  >
                    <div slot="content">
                      <pre>{{scope.row.original_value}}</pre>
                    </div>

                    <el-button type="text">Data Modification</el-button>
                  </el-tooltip>
                </span>
                <span v-else>
                  {{scope.row.original_value}}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="new_value"
              label="Updated Value">
              <template slot-scope="scope">
                <span v-if="typeof scope.row.new_value === 'Object'">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    placement="top"
                  >
                    <div slot="content">
                      <pre>{{scope.row.new_value}}</pre>
                    </div>
                    <el-button type="text">Data Modification</el-button>
                  </el-tooltip>
                </span>
                <span v-else>
                  {{scope.row.new_value}}
                </span>
              </template>
            </el-table-column>
            <el-table-column
              prop="reason"
              label="Reason">
            </el-table-column>
            <el-table-column
              prop="user"
              label="User">
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>

      <!-- <pre>{{ lot.data.removal_details }}</pre> -->

    </el-card>

    <el-dialog
      id="activity_factor_dialog"
      title="Add Activity Factor/Pack Stats"
      :visible.sync="display_add_activity_factor"
      width="50%">
      <table v-if="lot.ingredient.has_activity_factor">
        <tr>
          <td><b>Activity Factor [%]:</b></td>
          <td>
            <el-input-number
              v-if="!this.lot.data.activity_factor"
              placeholder="100.5"
              :precision="3"
              v-model="form.activity_factor"
              :controls="false"
              type="number"
              inputmode="decimal">
            </el-input-number>
            <span v-else>
              {{this.lot.data.activity_factor}}%
            </span>
          </td>
        </tr>
      </table>

      <div v-if="lot.ingredient.has_pack_stat">
        <b>Pack Stat</b>
        <div style="font-size:.8em;color:#6e6e6e;">All measurements are in grams</div>
        <el-tabs v-model="form.tab">
          <el-tab-pane label="Capsules" name="first">
            <table>
              <tr>
                <td>Size 000</td>
                <td>
                  <el-input-number
                    v-if="!this.lot.data.pack_stat || !this.lot.data.pack_stat['000']"
                    placeholder="0.00"
                    :precision="3"
                    v-model="form.pack_stat['000']"
                    :controls="false"
                    type="number"
                    inputmode="decimal"
                    @change="calculate_pack_stats('000')">
                  </el-input-number>
                  <span v-else>
                    {{this.lot.data.pack_stat['000']}}
                  </span>
                </td>
              </tr>
              <tr>
                <td>Size 00</td>
                <td>
                  <el-input-number
                    v-if="!this.lot.data.pack_stat || !this.lot.data.pack_stat['00']"
                    placeholder="0.00"
                    :precision="3"
                    v-model="form.pack_stat['00']"
                    :controls="false"
                    type="number"
                    inputmode="decimal"
                    @change="calculate_pack_stats('00')">
                  </el-input-number>
                  <span v-else>
                    {{this.lot.data.pack_stat['00']}}
                  </span>
                </td>
              </tr>
              <tr>
                <td>Size 0</td>
                <td>
                  <el-input-number
                    v-if="!this.lot.data.pack_stat || !this.lot.data.pack_stat['0']"
                    placeholder="0.00"
                    :precision="3"
                    v-model="form.pack_stat['0']"
                    :controls="false"
                    type="number"
                    inputmode="decimal"
                    @change="calculate_pack_stats('0')">
                  </el-input-number>
                  <span v-else>
                    {{this.lot.data.pack_stat['0']}}
                  </span>
                </td>
              </tr>
              <tr>
                <td>Size 1</td>
                <td>
                  <el-input-number
                    v-if="!this.lot.data.pack_stat || !this.lot.data.pack_stat['1']"
                    placeholder="0.00"
                    :precision="3"
                    v-model="form.pack_stat['1']"
                    :controls="false"
                    type="number"
                    inputmode="decimal"
                    @change="calculate_pack_stats('1')">
                  </el-input-number>
                  <span v-else>
                    {{this.lot.data.pack_stat['1']}}
                  </span>
                </td>
              </tr>
              <tr>
                <td>Size 2</td>
                <td>
                  <el-input-number
                    v-if="!this.lot.data.pack_stat || !this.lot.data.pack_stat['2']"
                    placeholder="0.00"
                    :precision="3"
                    v-model="form.pack_stat['2']"
                    :controls="false"
                    type="number"
                    inputmode="decimal"
                    @change="calculate_pack_stats('2')">
                  </el-input-number>
                  <span v-else>
                    {{this.lot.data.pack_stat['2']}}
                  </span>
                </td>
              </tr>
              <tr>
                <td>Size 3</td>
                <td>
                  <el-input-number
                    v-if="!this.lot.data.pack_stat || !this.lot.data.pack_stat['3']"
                    placeholder="0.00"
                    :precision="3"
                    v-model="form.pack_stat['3']"
                    :controls="false"
                    type="number"
                    inputmode="decimal"
                    @change="calculate_pack_stats('3')">
                  </el-input-number>
                  <span v-else>
                    {{this.lot.data.pack_stat['3']}}
                  </span>
                </td>
              </tr>
              <tr>
                <td>Size 4</td>
                <td>
                  <el-input-number
                    v-if="!this.lot.data.pack_stat || !this.lot.data.pack_stat['4']"
                    placeholder="0.00"
                    :precision="3"
                    v-model="form.pack_stat['4']"
                    :controls="false"
                    type="number"
                    inputmode="decimal"
                    @change="calculate_pack_stats('4')">
                  </el-input-number>
                  <span v-else>
                    {{this.lot.data.pack_stat['4']}}
                  </span>
                </td>
              </tr>
              <tr>
                <td>Size 5</td>
                <td>
                  <el-input-number
                    v-if="!this.lot.data.pack_stat || !this.lot.data.pack_stat['5']"
                    placeholder="0.00"
                    :precision="3"
                    v-model="form.pack_stat['5']"
                    :controls="false"
                    type="number"
                    inputmode="decimal"
                    @change="calculate_pack_stats('5')">
                  </el-input-number>
                  <span v-else>
                    {{this.lot.data.pack_stat['5']}}
                  </span>
                </td>
              </tr>
            </table>
            <el-checkbox v-model="calculate_pack_stats_check">Calculate Pack Stats?</el-checkbox>
          </el-tab-pane>
          <el-tab-pane label="Scoops" name="second">
            <table>
              <tr>
                <td>1mL Scoop</td>
                <td>
                  <el-input-number
                    v-if="!this.lot.data.pack_stat || !this.lot.data.pack_stat['1mL']"
                    placeholder="0.00"
                    :precision="3"
                    v-model="form.pack_stat['1mL']"
                    type="number"
                    inputmode="decimal"
                    :controls="false">
                  </el-input-number>
                  <span v-else>
                    {{this.lot.data.pack_stat['1mL']}}
                  </span>
                </td>
              </tr>
              <tr>
                <td>2.5mL Scoop</td>
                <td>
                  <el-input-number
                    v-if="!this.lot.data.pack_stat || !this.lot.data.pack_stat['2.5mL']"
                    placeholder="0.00"
                    :precision="3"
                    v-model="form.pack_stat['2.5mL']"
                    type="number"
                    inputmode="decimal"
                    :controls="false">
                  </el-input-number>
                  <span v-else>
                    {{this.lot.data.pack_stat['2.5mL']}}
                  </span>
                </td>
              </tr>
              <tr>
                <td>5mL Scoop</td>
                <td>
                  <el-input-number
                    v-if="!this.lot.data.pack_stat || !this.lot.data.pack_stat['5mL']"
                    placeholder="0.00"
                    :precision="3"
                    v-model="form.pack_stat['5mL']"
                    type="number"
                    inputmode="decimal"
                    :controls="false">
                  </el-input-number>
                  <span v-else>
                    {{this.lot.data.pack_stat['5mL']}}
                  </span>
                </td>
              </tr>
              <tr>
                <td>15mL Scoop</td>
                <td>
                  <el-input-number
                    v-if="!this.lot.data.pack_stat || !this.lot.data.pack_stat['15mL']"
                    placeholder="0.00"
                    :precision="3"
                    v-model="form.pack_stat['15mL']"
                    type="number"
                    inputmode="decimal"
                    :controls="false">
                  </el-input-number>
                  <span v-else>
                    {{this.lot.data.pack_stat['15mL']}}
                  </span>
                </td>
              </tr>
              <tr>
                <td>29.6mL Scoop</td>
                <td>
                  <el-input-number
                    v-if="!this.lot.data.pack_stat || !this.lot.data.pack_stat['29.6mL']"
                    placeholder="0.00"
                    :precision="3"
                    v-model="form.pack_stat['29.6mL']"
                    type="number"
                    inputmode="decimal"
                    :controls="false">
                  </el-input-number>
                  <span v-else>
                    {{this.lot.data.pack_stat['29.6mL']}}
                  </span>
                </td>
              </tr>
              <tr>
                <td>60mL Scoop</td>
                <td>
                  <el-input-number
                    v-if="!this.lot.data.pack_stat || !this.lot.data.pack_stat['60mL']"
                    placeholder="0.00"
                    :precision="3"
                    v-model="form.pack_stat['60mL']"
                    type="number"
                    inputmode="decimal"
                    :controls="false">
                  </el-input-number>
                  <span v-else>
                    {{this.lot.data.pack_stat['60mL']}}
                  </span>
                </td>
              </tr>
            </table>
          </el-tab-pane>
        </el-tabs>
      </div>


      <el-row>
        <el-col :span="24">
              <documentsList :documents_s="documents_s" @deleteDoc="deleteDoc" />
              <BaseDocumentUpload
                label="Upload Other Documents"
                :documents.sync="form.documents"
                :value="form.documents"
                :categories="document_categories"
                @update_documents="updateDocuments"
              ></BaseDocumentUpload>
              <takePhoto @update_documents="updateDocuments" />
        </el-col>
        <el-col :span="24">
          <div style="margin-top: 1.5em;">
            <BaseDocumentUpload
              label="Activity Factor"
              :document_information="{ category: 'activity_factor', reference_identifier: lot.identifier}"
              :categories="[document_categories[0]]"
              @update_documents="updateDocuments"
              ></BaseDocumentUpload>
              <takePhoto @update_documents="updateDocuments" />
          </div>
        </el-col>
        <el-col :span="24">
          <div style="margin-top: 1.5em;">
            <BaseDocumentUpload
              label="Pack Stat"
              :document_information="{ category: 'pack_stat', reference_identifier: lot.identifier}"
              :categories="[document_categories[1]]"
              @update_documents="updateDocuments"></BaseDocumentUpload>
              <takePhoto @update_documents="updateDocuments" />
          </div>
        </el-col>
      </el-row>

      <span slot="footer" class="dialog-footer">
        <el-button @click="display_add_activity_factor = false">Cancel</el-button>
        <el-button type="primary" @click="save_activity_factor">Submit</el-button>
      </span>
    </el-dialog>



    <el-dialog
      id="adjust_BUD_dialog"
      title="Modify Beyond Use Date"
      :visible.sync="display_adjust_BUD"
      width="40%">

      <el-row :gutter="20">
        <el-col :span="9">
          <b>Original Beyond Use Date:</b> <br>
          {{lot.beyond_use_date}}<br>
        </el-col>
        <el-col :span="15">
          <b>Reason:</b>
          <el-input
            type="textarea"
            autosize
            placeholder="Reason for modification"
            v-model="adjust_bud_form.adjust_reason">
          </el-input>
        </el-col>
        <el-col :span="9">
          <b>New Beyond Use Date:</b> <br>
          <el-date-picker
            v-model="adjust_bud_form.new_bud"
            type="date"
            placeholder="Beyond Use Date">
          </el-date-picker>
        </el-col>
        <el-col :span="15" style="margin-top: 20px;">
          <el-checkbox
            v-model="adjust_bud_form.update_lots_utilizing_lot">
            <b>Update lots utilizing this lot</b>
          </el-checkbox>
          <div style="font-size: 0.85em;word-break: break-word;padding-top: 5px;">
            <i class="far fa-question-circle"></i>
            This will re-calculate the Beyond Use Date of all lots containing this lot. If this lots Beyond Use Date is sooner than the existing Beyond Use Date of that lot, it will be updated to the new Beyond Use Date.
          </div>
        </el-col>
      </el-row>

      <span slot="footer" class="dialog-footer">
        <el-button @click="display_adjust_BUD = false">Cancel</el-button>
        <el-button type="primary" @click="adjust_bud" :disabled="!adjust_bud_form.adjust_reason || !adjust_bud_form.new_bud">Save</el-button>
      </span>
    </el-dialog>

  </div>
</template>
<script>
// TODO - where would we upload a sterility document after the lot has been created?
// or is it not fully created until sterility testing is complete qq
// if the later, the lot should remain pending and have the abiity to upload on the creation task
// otherwise, should we include the ability here qq

import _ from 'lodash'
import NProgress from "nprogress";
import EventService from "@/services/EventService.js";
import EquipmentService from '@/services/equipment.js'
import Common from "@/common.js";
import store from "@/store/store";
import moment from 'moment'
const uuid_base64 = require("uuid-base64");
import url_for from "@/services/pmk_url_for";
import LogInventoryPrompt from "@/components/ingredient/log_inventory_prompt.vue";
import AddNotePrompt from "@/components/ingredient/add_note_prompt.vue";
import BaseDocumentUpload from '@/components/BaseDocumentUpload'
import takePhoto from '@/components/TakePhoto.vue'
import documentsList from '@/components/DocumentList.vue'

export default {
  props: {
    lot: {
      type: Object,
      required: true
    }
  },
  components: {
    LogInventoryPrompt,
    AddNotePrompt,
    takePhoto,
    BaseDocumentUpload,
    documentsList
  },
  data() {
    return {
      label_list: {},
      label_count: 1,
      selected_device: {},
      devices: [],
      copied: {
        identifier: false,
        barcode: false
      },
      categories: this.$store.state.categories,
      log_inventory_reduction_dialog_visible: false,
      add_note_dialog_visible: false,
      documents_s: (this.lot.data ? (this.lot.data.documents || []) : []),
      form: {
        documents: [],
        tab: 'first',
        activity_factor: this.lot.data.activity_factor ? this.lot.data.activity_factor : '',
        label_loading: false,
        log_inventory_reduction: {
          quantity: '',
          reason: ''
        },
        pack_stat: this.lot.data.pack_stat ? _.cloneDeep(this.lot.data.pack_stat) : {
          '000': 0,
          '00': 0,
          '0': 0,
          '1': 0,
          '2': 0,
          '3': 0,
          '4': 0,
          '5': 0,
          '1mL': 0,
          '2.5mL': 0,
          '5mL': 0,
          '15mL': 0,
          '29.6mL': 0,
          '60mL': 0,
        },
        pack_stat_multipliers: {
          "000": 2.95,
          "00": 1.95,
          "1": 1,
          "0": 1.37,
          "2": 0.74,
          "3": 0.61,
          "4": 0.43,
          "5": 0.27
        }
      },
      results: [],
      ingredient: {},
      display_add_activity_factor: false,
      display_adjust_BUD: false,
      adjust_bud_form: {
        new_bud: '',
        adjust_reason: '',
        update_lots_utilizing_lot: false,
      },
      calculate_pack_stats_check: true,
      document_categories: [
        {label:'Other', value:'other'},
        {label:'C of A',value:'certificate_of_analysis'},
        {label:'Purchase Order', value:'Purchase Order'},
        {label:'Activity Factor',value:'activity_factor'},
        {label:'Pack Stat', value:'pack_stat'},
      ],
      modification_types: {
        'beyond_use_date': 'Beyond Use Date',
        'pack_stat_AF': 'Pack Stat/Activity Factor'
      }
    };
  },
  beforeRouteUpdate(to, from, next) {
    let self = this;
    store
          .dispatch("lot/getInventory", {identifier: to.params.identifier, options: {
            include_inventory_lots_used_in: true,
            search_all_inventory: true
             }})
          .then(data => {
            self.lot = data;

          });
    next()
  },
  watch: {
    'lot.data.documents': {
      deep: true,
      handler() {
        this.documents_s = this.lot.data.documents
      }
    }
  },
  mounted() {
    const self = this
    this.get_lot_label_list(this.lot.identifier)
    try {
      BrowserPrint.getDefaultDevice("printer", function(device)
    {
      self.selected_device = device
    }, undefined)
    } catch (e) {
      console.log('error:', e)
    }
  },
  computed: {
    unit_of_measure: {
      get() {
        let unit_list = this.$store.getters["terminology/terminology"][
          "unit_of_measure"
        ];
        if (!unit_list) {
          this.$store.dispatch("terminology/fetchMedicationTerminologies");
          unit_list = this.$store.getters["terminology/terminology"][
            "unit_of_measure"
          ];
        }
        let map = new Map(
          unit_list.map(i => [i.code, i.display || i.description])
        );
        return map;
      }
    },
    allow_beyond_use_date_modifications() {
      return _.get(this.lab_config, 'config.lot.allow_beyond_use_date_modifications', false)
    },
    preparation_environment_type() {
      let environment_types = this.$store.getters["terminology/compound"][
        "preparation_environment_type"
      ];
      if (!environment_types) {
        this.$store.dispatch("terminology/fetchCompoundTerminologies");
        environment_types = this.$store.getters["terminology/compound"][
          "preparation_environment_type"
        ];
      }
      return environment_types;
    },
    decimal_places() {
      return _.get(this.$store.getters['formula/get_lab_config'].config, 'lot.decimal_places', 3)
    },
    notesTableData(){
      let self = this;
      let data = [];
      if (self.lot.note || self.lot.inventory.note) {
        let creation_note = {
          note_text : self.lot.lot_type == 'formula' ? self.lot.note : self.lot.inventory.note,
          author: {
            name : self.lot.data.author
          },
          date_created : self.lot.date_created,
          note_type: "Entry Note"
        }
        data.push(creation_note);
      }


      let result = data.concat(self.lot.data.lot_notes);

      return result;
    },
    lot_barcode() {
      return uuid_base64.encode(this.lot.identifier)
    },
    config() {
      return this.$store.getters['app/pmk_config']
    },
    lab_config: {
      get() {
        return this.$store.getters['formula/get_lab_config']
      }
    },
    is_connected_printer: function() {
      let checked_out_printer = this.$store.getters["equipment/get_equipment_list_checked_out"].filter(obj => obj.equipment_type === 'printer')
      if (checked_out_printer[0]) {
        let printer_config = this.$store.getters["equipment/get_equipment_list"][checked_out_printer[0].identifier]
        return printer_config
      } else return false
    }
  },
  methods: {
    adjust_bud() {
      EventService.pmk_fetch(
        'electric_lab_update_lot_with_recalculated_beyond_use_date',
        'POST',
        {
          identifier: this.lot.identifier,
        },
        {
          beyond_use_date: moment(this.adjust_bud_form.new_bud).format(),
          reason: this.adjust_bud_form.adjust_reason
        }
      ).then((http_response) => {
        console.log('update lot bud res', http_response)
        if (http_response.data.messages) {
          http_response.data.messages.forEach(message => this.$message(message))
        }
        if (http_response.data.success) {
          if (this.adjust_bud_form.update_lots_utilizing_lot) {
            EventService.pmk_fetch(
              'electric_lab_modify_beyond_use_date_for_lots_utilizing',
              'POST',
              {
                identifier: this.lot.identifier,
              },
              {
                reason: this.adjust_bud_form.adjust_reason
              }
            ).then((http_response) => {
              console.log('adjust lots containing lot bud res', http_response)
              if (http_response.data.messages) {
                http_response.data.messages.forEach(message => this.$message(message))
              }
              if (http_response.data.success) {
                this.display_adjust_BUD = false
                this.adjust_bud_form = {
                  new_bud: '',
                  adjust_reason: '',
                  update_lots_utilizing_lot: true
                }
                store
                      .dispatch("lot/getInventory", {identifier: this.lot.identifier, options: {
                        include_inventory_lots_used_in: true,
                        search_all_inventory: true
                         }})
                      .then(data => {
                        this.lot = data;

                      });
              }
            }).catch((e) => {
              this.$message({
                message: 'Updating Lots Utilizing Lot Failed: ' + e,
                type: "error"
              })
            })
          } else {
            if (http_response.data.success) {
              this.display_adjust_BUD = false
              this.adjust_bud_form = {
                new_bud: '',
                adjust_reason: '',
                update_lots_utilizing_lot: true
              }
              store
                    .dispatch("lot/getInventory", {identifier: this.lot.identifier, options: {
                      include_inventory_lots_used_in: true,
                      search_all_inventory: true
                       }})
                    .then(data => {
                      this.lot = data;

                    });
            }
          }
        }
      }).catch((e) => {
        this.$message({
          message: 'Updating Beyond Use Date Failed: ' + e,
          type: "error"
        })
      })
    },
    get_lot_label_list(identifier) {
      if (!this.label_list || !this.label_list.length) {
        EventService.pmk_fetch('electric_lab_get_lot_label_list', 'GET', {
          identifier,
          version: 'v5'
        }).then(http_response => {
          this.label_list = http_response.data.data
          console.log('label_list', this.label_list)
        })
      }
    },
    unit_dose_label(type) {
      let label_url = `/api/pharmetika/v5/electric_lab/inventory/id/${this.lot.identifier}/unit_dose_label.`
      if (type === 'print300') {
        label_url += 'zpl?dpi=300&quantity=' + this.label_count
        try {
          this.selected_device.sendFile(label_url, undefined, undefined)
        } catch (e) {
          console.log('error:', e)
        }
        return
      }
      if (type === 'print203') {
        label_url += 'zpl?dpi=203&quantity=' + this.label_count
        try {
          this.selected_device.sendFile(label_url, undefined, undefined)
        } catch (e) {
          console.log('error:', e)
        }
        return
      }
      if (type === 'print_equipment') {
        let printer_dpi = this.is_connected_printer.printer_dpi
        label_url += `zpl?dpi=${printer_dpi}&quantity=` + this.label_count
        try {
          EquipmentService.send_to_equipment_printer({url: label_url})
        } catch (e) {
          console.log('error:', e)
        }
        return
      }

      label_url += type

      let label_win = window.open(label_url, 'label')
      label_win.focus()
      this.label_count = 1
    },
    deleteDoc(item, category) {
      this.lot.data.documents.forEach(doc_category => {
        if (doc_category.category === category) {
          let index = doc_category.documents.indexOf(item)
          if (index >= 0) {
            doc_category.documents.splice(index, 1)
          }
        }
      })
      this.updateDocuments(false)
    },
    calculate_pack_stats(from_size) {
      if (this.calculate_pack_stats_check) {
        Object.entries(this.form.pack_stat).forEach(size => {
          if (size[0] === from_size) {
            Object.entries(this.form.pack_stat).forEach(set_size => {
              if (Object.keys(this.form.pack_stat_multipliers).includes(set_size[0])) { // && set_size[1] === 0
                let new_size = parseFloat((size[1] / this.form.pack_stat_multipliers[size[0]]) * this.form.pack_stat_multipliers[set_size[0]]).toFixed(this.decimal_places)
                this.form.pack_stat[set_size[0]] = new_size
                //console.log('set size', set_size)
              }
            })
          }
        })
      }
    },
    updateDocuments(_document){
      // update documents in form
      // console.log({ _document })
      if (_document) {
        let to_append = {};
        to_append.name = _document.name;
        to_append.note = _document.note;
        to_append.type = _document.type;
        to_append.identifier = _document.identifier;
        let to_append_list = [];
        to_append_list.push(to_append);
        let this_document_category_list = {};
      //   let new_documents_list;
        this_document_category_list["category"] = _document.category;
        this_document_category_list["documents"] = to_append_list;
        // console.log({ document_s: this.documents_s })
        if (!Array.isArray(this.documents_s)) {
          this.documents_s = Object.values(this.documents_s) || []
        }
        this.form.documents.push(this_document_category_list)
        this.documents_s.push(this_document_category_list)
      }
      if (!this.lot.data.documents) {
        this.lot.data.documents = this.form.documents
      }
      this.lot.data.documents = this.lot.data.documents.filter(category => {
        return category.documents.length
      })

      let new_data = {}
      if (this.form.documents) {
        new_data.documents = this.lot.data.documents
        // This line was adding the new document twice
        //this.lot.data.documents && Array.isArray(this.lot.data.documents) ? [...this.lot.data.documents, ...this.form.documents] : this.form.documents
      }
      EventService.pmk_fetch(
        'electric_lab_patch_ingredient_inventory',
        'PATCH',
        {
          identifier: this.lot.identifier
        },
        new_data
      ).then(http_response => {
        if (http_response && http_response.data && http_response.data.messages) {
          http_response.data.messages.forEach(message => {
            this.$message(message)
          })
        }
        console.log('http_response', http_response)
      })
    },
    download_recording(sessionid) {
      if (sessionid === null) {
        this.$message({
          message: 'This recording could not be loaded',
          type: 'error'
        })
        return
      }
      EventService.pmk_fetch(
        'administration_session_recording_get_token',
        'GET',
        {
          recording_id: sessionid
        },
        {}
      ).then(http_response => {
        if (http_response.data.succcess) {
          window.open(http_response.data.data.url, '_blank')
        } else if (http_response.data.messages) {
          http_response.data.messages.forEach(message => this.$message(message))
        }
        else {
          this.$message({
            message: 'This recording could not be loaded',
            type: 'error'
          })
        }
      })
    },
    save_activity_factor() {
      const user = this.$store.getters['account/profile']
      if (user.user_type !== 'lab-pharmacist') {
        this.$message({
          message: 'Only RPh May add activity factor',
          type: 'warning'
        })
        this.display_add_activity_factor = false
        return
      }

      let pack_stat_contains_zero = false

      Object.values(this.form.pack_stat).forEach(size => {
        if (parseFloat(size) === 0) {
          if (Object.keys(this.form.pack_stat_multipliers).includes(size[0])) {
            pack_stat_contains_zero = true
            console.log('size', size)
          }
        }
      })
      if (pack_stat_contains_zero) {
        Object.entries(this.form.pack_stat).forEach(size => {
          if (parseFloat(size[1]) !== 0) {
            if (Object.keys(this.form.pack_stat_multipliers).includes(size[0])) {
              this.calculate_pack_stats(size[0])
              console.log('sizeset', size)
            }
          }
        })
      }


      let new_data = {}
      if (this.form.documents) {
        new_data.documents = this.lot.data.documents
        // This line was adding the new document twice
        //new_data.documents = this.lot.data.documents ? [...this.lot.data.documents, ...this.form.documents] : this.form.documents
      }
      if (!this.lot.data.activity_factor) {
        new_data.activity_factor = this.form.activity_factor ? this.form.activity_factor : null
        this.lot.data.activity_factor = this.form.activity_factor
      }

      new_data.pack_stat = this.form.pack_stat
      this.lot.data.pack_stat = new_data.pack_stat

      console.log('new_data', new_data)
      EventService.pmk_fetch(
        'electric_lab_patch_ingredient_inventory',
        'PATCH',
        {
          identifier: this.lot.identifier
        },
        new_data
      ).then(http_response => {
        if (http_response && http_response.data && http_response.data.messages) {
          http_response.data.messages.forEach(message => {
            this.$message(message)
          })
        }
        console.log('http_response', http_response)
        this.display_add_activity_factor = false
      })

    },
    date_diff(date_start, date_end) {
      let start = moment.utc(date_start)
      let end = moment.utc(date_end)
      let time = moment.duration(end.diff(start))
      return (time.hours() ? time.hours() + "hr ":'') + (time.minutes() ? time.minutes() + "min ":'') + time.seconds() + "sec"
    },
    formatted_date(date) {
      return moment.utc(date).local().format('YYYY-MM-DD HH:mma')
    },
    updateNotes(e){
      let self = this;
      EventService.inventoryGetNotes(self.lot.identifier).then ( (response) => {
          // hide modal
          self.add_note_dialog_visible = e.dialogVisible
          self.lot.data.lot_notes = response.data.data
          self.$forceUpdate()
          // scroll table to buttom
          self.$nextTick(()=>{
              let notesTable = document.getElementsByClassName('notesTable')[0]
                 .getElementsByClassName('el-table__body-wrapper')[0];
              notesTable.scrollTop = notesTable.scrollHeight;
          })

      })
      .catch((error) => {
            const notification = {
            type: "error",
            message:
                "There was a problem fetshing notes: " + error.message
            };
            self.$store.dispatch("notification/add", notification, { root: true });
        })
    },
    updateAfterLogLoss(){
      let self= this;
      store
          .dispatch("lot/refreshLogLoss",
            {
              identifier: self.lot.identifier,
              options: {
                include_inventory_lots_used_in: true,
                search_all_inventory: true
              }
            })
          .then(data => {
            console.log('updateAfterLogLoss data', data)
            self.lot.lots_used_in = data.data.lots_used_in;
            self.lot.current_inventory.quantity_remaining = data.data.current_inventory.quantity_remaining;
            self.$nextTick(()=>{
              // scroll table to buttom
              let lossTable = document.getElementsByClassName('lossTable')[0]
                .getElementsByClassName('el-table__body-wrapper')[0];
              lossTable.scrollTop = lossTable.scrollHeight;
            })
          });
    },
    dataEntered(){
    let self = this;
    store
          .dispatch("lot/getInventory", self.lot.identifier)
          .then(data => {
            self.lot = data;
          });
    this.log_inventory_reduction_dialog_visible = false
    },
    closeModal(entry){
      this.log_inventory_reduction_dialog_visible = entry.dialogVisible
    },
    closeAddNoteModal(entry){
      this.add_note_dialog_visible = entry.dialogVisible
    },
    reset() {
      this.form = {};
    },
    copy_identifier_to_clipboard(payload, indicator) {
      navigator.clipboard.writeText(payload).then(() => {
        /* clipboard successfully set */
        this.copied[indicator] = true
        setTimeout(() => this.copied[indicator] = false, 5000)
      }).catch( () => {
        /* clipboard write failed */
      })
    },

    open_label(args = {}) {
      let lab_config = this.$store.getters['formula/get_lab_config']
      if (args.reverse_distribution) {
        lab_config.config.label.reverse_distribution = true
      }
      if (
        this.lot.lot_type === 'ingredient'
        && lab_config.config.label
        && lab_config.config.label.include_GS1_QRCODE
      ) {
        this.form.label_loading = true
        if (this.lot.data.product_identifiers && this.lot.data.product_identifiers.system === "NDC")
        {

          const ingredient_data = this.lot.data
          // for ingredients - an NDC should be used
          const product_code = ingredient_data.product_identifiers.code
          const ndc10_p = Common.pmk_get_ndc_properties(product_code)

          ndc10_p.then(ndc10 => {
            Common.get_lot_label_url(
              this.lot.data,
              {...this.lot.data[this.lot.lot_type], ...{code: this.lot.code, ndc10: ndc10} },
              lab_config
            )
            .then(() =>  this.form.label_loading = false);
          })
        } else if (this.lot.data.product_identifiers && this.lot.data.product_identifiers.system === "UPC" ) {
          const ingredient_data = this.lot.data
          // for ingredients - a UPC _could_ be used
          const product_code = ingredient_data.product_identifiers.code
          // example: 074752606018 -> 7475260601

          const ndc10 = product_code.toString().length > 11 ? product_code.toString().slice(0, -1).slice(1,11) : product_code.slice(1,11)

          Common.get_lot_label_url(
            this.lot.data,
            {...this.lot.data[this.lot.lot_type], ...{code: this.lot.code, ndc10: ndc10} },
            lab_config
          )
          .then(() =>  this.form.label_loading = false);


        } else {
          Common.get_lot_label_url(
            this.lot.data,
            {...this.lot.data[this.lot.lot_type], ...{code: this.lot.code} },
            lab_config
          )
          .then(() =>  this.form.label_loading = false);
        }
      } else {

        this.form.label_loading = true

        if (this.lot.inventory && this.lot.inventory.label_list && this.lot.inventory.label_list.length > 1) {
          const tz_offset = new Date().getTimezoneOffset()
          const tz_name = Intl.DateTimeFormat().resolvedOptions().timeZone
          const args = {
            return_result: 1,
            render_and_delete: 0,
            identifier: this.lot.identifier,
            timezone_offset: tz_offset,
            timezone_name: tz_name,

          }
          if (_.get(this.lab_config, 'config.label.dimensions', false)) {
            args.dimensions = 'dimensions_' + _.get(this.lab_config, 'config.label.dimensions')
          }
          Common.open_label_set(args).then( () => this.form.label_loading = false)
        } else {
          Common.get_lot_label_url(
            this.lot.data,
            {...this.lot.data[this.lot.lot_type], ...{code: this.lot.code} },
            lab_config
          )
          .then(() =>  this.form.label_loading = false);
        }
      }
    },
    get_ingredient_cofa_url() {
      // only if lot_type is ingredient
      let product_code = this.lot.ingredient.manufacturers[
                    this.lot.data.manufacturer.identifier
                  ].product_identifiers[this.lot.data.product_identifier].code
                  || '' //protect against missing codes

      if ( product_code.match(/^49452/) ) {
        // Spectrum
        let regex = /49452([A-Z0-9][A-Z0-9][A-Z0-9][A-Z0-9][A-Z0-9])/i
        let spectrum_product_code = ''
        try {
          spectrum_product_code = this.lot.data.product_identifiers.code.match(regex)
        } catch {

        }
        console.log('spectrum_product_code', spectrum_product_code)
        if (!!spectrum_product_code) {
          return `https://www.spectrumchemical.com/OA_HTML/xxsc_COA.jsp?ic=${spectrum_product_code[1]}&lot=${this.lot.lot_number}&pf=true`
        }
      } else if ( product_code.match(/^0?38779/) ) {
        // Medisca
        return `https://www.medisca.com/Pages/ViewCofa.aspx?Name=Cofa&Lot=${this.lot.lot_number}`
      } else if (product_code.match(/^\d?62991/) ) {
          // Letco
          return `https://www.letcomedical.com/certificate-of-analysis?lotNumber=${this.lot.lot_number}`
      } else if (product_code.match(/^0?63275/)) {
        // B & B
        let regex = /PR(BB\d\d([A-Z][A-Z][A-Z0-9][A-Z0-9][A-Z0-9][A-Z0-9][A-Z0-9]))/i
        let bb_lot_number = this.lot.lot_number.match(regex)
        let post_url = `https://www.bbpharm.net/CofA.asp?srchLotNumber=${bb_lot_number}&hdnCofASearch=y&honeypot=`
        // API has been updated to include this currently
        // TODO: this should be re-done via JS or saved during initial lot entry
        return this.lot.lot_c_of_a_url || post_url
      } else if (product_code.match(/^0?51552/)) {
        // Fagron
        let regex1 = /([A-Z0-9]{5}-[A-Z0-9]{3}-[A-Z0-9]{6})/i
        let regex2 = /([A-Z0-9]{6}-[A-Z0-9]{6})/i
        let fagron_lot_number = this.lot.lot_number.match(regex1)
        if (!fagron_lot_number) {
          fagron_lot_number = this.lot.lot_number.match(regex2)
        }
        console.log('fagron_lot_number', fagron_lot_number)
        let get_url = `https://fagron.us/search?q=${fagron_lot_number && fagron_lot_number[0]}`
        // API has been updated to include this currently
        return this.lot.lot_c_of_a_url || get_url
      }

    },
    get_document_url(identifier, args={}) {
      return Common.get_document_url(identifier, args);
    },
    unit_display(unit) {
      let display_unit = this.unit_of_measure.get(unit) || unit;
      return display_unit;
    },
    ingredient_table_data() {
      // console.log("table data lot", this.lot);
      let data = {
        beyond_use_date: {
          title: "Beyond Use Date",
          value: moment.utc(this.lot.data.inventory.beyond_use_date).local().format(
            "YYYY-MM-DD"
          )
        },
        containers: {
          title: "Containers",
          value: this.lot.data.inventory.containers
        },

        quantity: {
          title: "Quantity",
          value: `${parseFloat(this.lot.current_inventory.quantity_remaining).toFixed(this.decimal_places) || 0} / ${this.lot.data
            .inventory.quantity || "n/a"} ${this.unit_display(this.lot.current_inventory.unit_of_measure)}`
        },
        lot_number: {
          title: "Lot#:",
          value: `${this.lot.lot_number}`
        },
        date_created: {
          title: "Date Checked-In",
          value: moment.utc(this.lot.date_created).local().format("YYYY-MM-DD  h:mm a")
        },
        location: {
          title: "Location",
          value: `${this.lot.data.location || this.lot.ingredient.location || ''}`
        }
      };
      try {
        data.manufacturer = {
                  title: ( this.lot.lot_type == "ingredient" ? "Manufacturer" : "Verified By"),
                  value:
                    this.lot.lot_type == "ingredient"
                      ? `${decodeURIComponent(escape(this.lot.data.manufacturer.name))}`
                      : decodeURIComponent(escape(this.lot.data.verified_by.name || ''))
                }
      } catch (e) {
        console.log('error loading ingredient manufacturer,', e)
      }
      try {
        data.product_identifier = {
          title: "Product Code",
          value:
            this.lot.lot_type == "ingredient"
              ? `${
                  this.lot.ingredient.manufacturers[
                    this.lot.data.manufacturer.identifier
                  ].product_identifiers[this.lot.data.product_identifier].system
                  || this.lot.data.product_identifiers.system
                }: ${
                  this.lot.ingredient.manufacturers[
                    this.lot.data.manufacturer.identifier
                  ].product_identifiers[this.lot.data.product_identifier].code
                } [${decodeURIComponent(escape(this.lot.data.product_identifier))}]`
              : `${this.lot.ingredient.identifiers.formula_id || ''} [${
                  this.lot.ingredient.identifiers.ndc
                }]`
        }
      } catch (e) {
        console.log('error loading ingredient product identifier,', e)
      }

      if(this.lot.data.inventory.capsule_weight_average){
        data.capsule_weight_average = {
          title : "Average Capsule Weight",
          value : this.lot.data.inventory.capsule_weight_average
        }
      }
      if (this.lot.data.inventory.purchase_price_total) {
        data.purchase_price_total = {
          title : "Total purchase price",
          value : `$${parseFloat(this.lot.data.inventory.purchase_price_total).toFixed(2)}`
        }
      }
      if (this.lot.data.inventory.purchase_order) {
        data.purchase_order_number = {
          title : "Purchase Order #",
          value : `${this.lot.data.inventory.purchase_order}`
        }
      }
      if(this.lot.data.preparation_environment_type){
        let preparationEnvTypeObj = this.lot.data.preparation_environment;
        let valueToDisplay = ""
        valueToDisplay += preparationEnvTypeObj.hazardous == 0 ? "Non-hazardous" : "Hazardous";
        valueToDisplay += preparationEnvTypeObj.sterile == 0 ? " / Non-sterile" : " / Sterile";
        if (preparationEnvTypeObj.environment_not_set && this.lot.data.preparation_environment_type === '01') {
          valueToDisplay = this.preparation_environment_type && this.preparation_environment_type[this.lot.data.preparation_environment_type].description
        }
        data.preparation_environment_type = {
          title : "Preparation Environment",
          value : valueToDisplay
        }
      }
      if(this.lot.data.inventory_type === 'removed'){
        if (this.lot.data.removal_details) {
          data.removal_reason = {
            title : "Removed",
            value : `${this.lot.data.removal_details.reason || this.lot.data.removal_details.reason_type}`
          }
          data.date_removed = {
            title : "Date Removed",
            value : moment.utc(this.lot.data.removal_details.date_removed).local().format("YYYY-MM-DD  h:mm a") + ' by ' + this.lot.data.removal_details.removed_by
          }
        } else {
          data.date_removed = {
            title : "Date Removed",
            value : moment.utc(this.lot.data.date_removed).local().format("YYYY-MM-DD  h:mm a") + ' by ' + this.lot.data.removed_by
          }
        }

      }
      if (this.lot.data.activity_factor) {
        data.activity_factor = {
          title : "Activity Factor",
          value : `${this.lot.data.activity_factor}`
        }
      }
      if (this.lot.lot_type === 'ingredient') {
        data.accepted_by = {
          title : "Accepted by",
          value : `${this.lot.data.accepted_by}`
        }
      }
      // add coa in the end
      data.CoA= {
        title: ( this.lot.lot_type == "ingredient" ? "CoA Document:" : "Worksheet"),
        // TODO: this should support multiple documents
        value: ( this.lot.lot_type == "ingredient" ?
            ( this.lot.data.documents && (this.lot.data.documents.certificate_of_analysis || _.findIndex(this.lot.data.documents, {"category": "certificate_of_analysis"}) !== -1) ?
              `<a href="${this.get_cofa_document_url()}" target="_blank">Document</a>`
              :
              (this.get_ingredient_cofa_url() ?
              `<a href="${this.get_ingredient_cofa_url()}" target="_blank"><i class="fal fa-file-download"></i> &nbsp; ${
                  this.lot.lot_number
                }</a>`
                :
                'No CoA found'
              )
            )
          :
            `<a href="/app/${ this.lot.data.inventory_type === "removed" ? 'inventory-removed/inventory-item' : 'ingredient/inventory-item' }/${this.lot.identifier}/worksheet" target="_blank"><i class="fal fa-file-download"></i> &nbsp; ${
            this.lot.lot_number
            }</a>`
          ),
        value_type: "html"
      }

      console.log(Object.values(data));
      return Object.values(data);
    },
    get_cofa_document_url() {
      if (this.lot.data.documents.certificate_of_analysis && this.lot.data.documents.certificate_of_analysis.identifier) {
        return url_for.url_for('electric_lab_document_get', {identifier: this.lot.data.documents.certificate_of_analysis.identifier})
      }
      if (_.findIndex(this.lot.data.documents, {"category": "certificate_of_analysis"}) !== -1) {
        const item = this.lot.data.documents[_.findIndex(this.lot.data.documents, {"category": "certificate_of_analysis"})].documents[0]
        if (item) {
          const url = this.get_document_url(item.identifier, {file_name: item.name, mime_type: item.mime_type || item.type})
          return url
        }
      }
    },
      // open_log_inventory_reduction() {
      //     let self = this
      //   this.$confirm(log_inventory_prompt, 'Log inventory reduction', {
      //       confirmButtonText: 'Submit',
      //       cancelButtonText: 'Cancel',
      //     callback: action => {
      //         let query_object = { ...{ identifier: lot.identifier},
      //                              ...self.form.log_inventory_reduction
      //                             }
      //         NProgress.start();
      //         return EventService.inventoryLogReduction(query_object)
      //     }
      //     })
      //     .then(results => {
      //             self.form.log_inventory_reduction.quantity = ''
      //             self.form.log_inventory_reduction.reason = ''
      //             })
      //     .then(() => NProgress.done())
      //     .catch(() => {
      //             NProgress.done();
      //     })
      //   }
      // },


    open_log_inventory_reduction() {
      this.log_inventory_reduction_dialog_visible = true
    },
    open_add_note(){
      this.add_note_dialog_visible = true
    },
    handleSearchResultSelection(row) {
      this.$router.push({
        name: "ingredient-inventory-item",
        params: { identifier: row.identifier }
      });
    }
  }
};




        //     let self=this
        //     this.$alert('Quantity', 'Log inventory reduction', {
        //       dangerouslyUseHTMLString: true

        // },
      // open_log_inventory_reduction_nice_but_broken() {
      //   let self=this
      //   this.$prompt(log_inventory_prompt, 'Log inventory reduction', {
      //     confirmButtonText: 'Next',
      //     cancelButtonText: 'Cancel',
      //     inputPattern: /[\d\.]+/,
      //     inputErrorMessage: 'Invalid quantity'
      //   }).then(({ value }) => {
      //     self.form.log_inventory_reduction.quantity=value
      //     return self.$prompt('Reason', 'Log inventory reduction', {
      //             confirmButtonText: 'Log Entry',
      //             cancelButtonText: 'Cancel',
      //     })
      //     }).then(({ reason_value }) => {
      //         console.log(reason_value)

      //         self.form.log_inventory_reduction.reason=reason_value
      //         let query_object = { ...{ identifier: lot.identifier},
      //                              ...self.form.log_inventory_reduction
      //                             }
      //         console.log(query_object)
      //         NProgress.start();
      //         return EventService.inventoryLogReduction(query_object)
      //           .then(results => {
      //             self.form.log_inventory_reduction.quantity = ''
      //             self.form.log_inventory_reduction.reason = ''
      //             })
      //           .then(() => NProgress.done())
      //           .catch(() => {
      //             NProgress.done();
      //           });
      //     }).then( () => {


      //         return self.$message({
      //               type: 'success',
      //               message: 'Inventory reduced by ' + self.form.log_inventory_reduction.quantity
      //             })

      //   }).catch(() => {
      //     this.$message({
      //       type: 'info',
      //       message: 'Input canceled'
      //     });
      //   });
      // },


</script>
<style scoped>
.clickable {
  cursor: pointer;
}
.no-break-word {
  word-break: keep-all;
}
.mt-20{
  margin-top: 1vh;
}
#activity_factor_dialog td {
  padding: 5px 5px 5px 0px;
}
.box-card.inventory-item-card h4 {
    font-size: 16px;
    color: black;
    margin-top: 16px;
}
.el-table .cell .unit-of-measure {
    word-break: break-word;
}
</style>
<style media="screen">
#adjust_BUD_dialog .el-date-editor .el-input__icon {
  right: -30px;
}
</style>
