<template>
  <div class="">
    <b>{{ingredient.data.description}}</b><br>

    <span v-if="ingredient.data.activity_factor">
      Activity Factor<br>
      <div style="margin-left: 10px">
        {{ parseFloat(formula.ingredients
          .filter(ing => ing.identifier === ingredient.ingredient_identifier)[0].quantity * quantity_muliplier)
          .toFixed(decimal_places)
        }}
        <BaseUnitOfMeasure
          :unit="(formula.ingredients.filter(ing =>
            ing.identifier === ingredient.ingredient_identifier)[0].formula_unit_of_measure
            || formula.ingredients.filter(ing => ing.identifier === ingredient.ingredient_identifier)[0].unit_of_measure)"
        />
        / {{ ingredient.data.activity_factor }}% Activity Factor
        <br><i class="far fa-arrow-right"></i>
        {{ parseFloat(formula.ingredients.filter(ing =>
          ing.identifier === ingredient.ingredient_identifier)[0].quantity * quantity_muliplier / (ingredient.data.activity_factor / 100))
          .toFixed(decimal_places)
        }}
        <BaseUnitOfMeasure
          :unit="(formula.ingredients
            .filter(ing => ing.identifier === ingredient.ingredient_identifier)[0].formula_unit_of_measure
            || formula.ingredients.filter(ing => ing.identifier === ingredient.ingredient_identifier)[0].unit_of_measure)"
        />
      </div>
    </span>

    <span
      v-else-if="ingredient.ingredient_identifier === lot.formula.activity_factor_modifying_ingredient
        || ingredient.quantity_sufficient_indicator && !lot.formula.pack_stat_required"
    >
      Activity Factor Modifying Ingredient<br>
      {{ parseFloat(formula.ingredients
          .filter(ing => ing.identifier === ingredient.ingredient_identifier)[0].quantity * quantity_muliplier)
          .toFixed(decimal_places)
      }}
      <BaseUnitOfMeasure
        :unit="(formula.ingredients
          .filter(ing => ing.identifier === ingredient.ingredient_identifier)[0].formula_unit_of_measure
          || formula.ingredients.filter(ing => ing.identifier === ingredient.ingredient_identifier)[0].unit_of_measure)"
      />

      <span v-for="diff in lot.activity_factor_difference" :key="diff">
        - {{diff}}
        <BaseUnitOfMeasure
          :unit="(formula.ingredients
            .filter(ing => ing.identifier === ingredient.ingredient_identifier)[0].formula_unit_of_measure
            || formula.ingredients.filter(ing => ing.identifier === ingredient.ingredient_identifier)[0].unit_of_measure)"
        />&nbsp;
      </span>

      <br><i class="far fa-arrow-right"></i>

      <span v-if="lot.activity_factor_difference">
        {{ parseFloat(
            parseFloat(formula.ingredients.filter(ing => ing.identifier === ingredient.ingredient_identifier)[0].quantity * quantity_muliplier)
            - parseFloat(Object.values(lot.activity_factor_difference).reduce((total, num) => total + num))
          ).toFixed(decimal_places)
        }}
      </span>

      <BaseUnitOfMeasure
        :unit="(formula.ingredients
          .filter(ing => ing.identifier === ingredient.ingredient_identifier)[0].formula_unit_of_measure
          || formula.ingredients.filter(ing => ing.identifier === ingredient.ingredient_identifier)[0].unit_of_measure)"
      />
    </span>

    <span
      v-if="ingredient.data.pack_stat
        && formula.pack_stat_excipient_ingredient !== ingredient.ingredient_identifier
        && lot.formula.pack_stat_required"
    >
      Pack Stat<br>
      <div style="margin-left: 10px;">

        <span v-if="ingredient.data.activity_factor">
          {{ parseFloat(formula.ingredients
            .filter(ing => ing.identifier === ingredient.ingredient_identifier)[0].quantity * quantity_muliplier / (ingredient.data.activity_factor / 100))
            .toFixed(decimal_places)
          }} gm / {{lot.quantity}} {{pack_stat_unit_name}} /
        </span>

        <span v-else>
          {{ parseFloat(formula.ingredients
            .filter(ing => ing.identifier === ingredient.ingredient_identifier)[0].quantity * quantity_muliplier)
            .toFixed(decimal_places)
          }} gm / {{lot.quantity}} {{pack_stat_unit_name}} /
        </span>

        {{ ingredient.data.pack_stat[lot.formula.pack_stat_size] }} gm Pack Stat
        <br><i class="far fa-arrow-right"></i>

        <span v-if="ingredient.data.activity_factor">
          {{ parseFloat(
              parseFloat(formula.ingredients.filter(ing => ing.identifier === ingredient.ingredient_identifier)[0].quantity * quantity_muliplier / lot.quantity) /
              (ingredient.data.activity_factor / 100) / ingredient.data.pack_stat[lot.formula.pack_stat_size]* 100
            ).toFixed(decimal_places)
          }}% Fill Volume
        </span>

        <span v-else>
          {{ parseFloat(
              parseFloat(formula.ingredients.filter(ing => ing.identifier === ingredient.ingredient_identifier)[0].quantity * quantity_muliplier / lot.quantity) /
              ingredient.data.pack_stat[lot.formula.pack_stat_size]* 100
            ).toFixed(decimal_places)
          }}% Fill Volume
        </span>

      </div>
    </span>

    <span v-else-if="formula.pack_stat_excipient_ingredient === ingredient.ingredient_identifier">
      Excipient Ingredient<br>
      <div style="margin-left: 10px">
        100%
        <span
          v-for="item in lot.pack_stat_fill"
          :key="item"
        >
          - {{item}}%
        </span>

        <br><i class="far fa-arrow-right"></i>

        {{ parseFloat(space_remaining(lot.pack_stat_fill)).toFixed(decimal_places) }}% Volume Remaining<br>
        {{ ingredient.data.pack_stat[lot.formula.pack_stat_size] }} gm Pack Stat *
        {{ parseFloat(space_remaining(lot.pack_stat_fill)).toFixed(decimal_places) }}% Volume Remaining

        <br><i class="far fa-arrow-right"></i>

        {{ parseFloat((space_remaining(lot.pack_stat_fill) / 100) * ingredient.data.pack_stat[lot.formula.pack_stat_size] )
          .toFixed(decimal_places)
        }} gm/{{pack_stat_unit_name}}<br>

        {{ parseFloat((space_remaining(lot.pack_stat_fill) / 100) * ingredient.data.pack_stat[lot.formula.pack_stat_size] )
          .toFixed(decimal_places)
        }} gm/{{pack_stat_unit_name}} *
        {{lot.quantity}} {{pack_stat_unit_name}}

        <br><i class="far fa-arrow-right"></i>

        {{ parseFloat((space_remaining(lot.pack_stat_fill) / 100) * ingredient.data.pack_stat[lot.formula.pack_stat_size] * lot.quantity ).toFixed(decimal_places) }} gm
      </div>
    </span>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  props: {
    ingredient: Object,
    formula: Object,
    lot: Object,
    quantity_muliplier: Number,
  },
  computed: {
    decimal_places() {
      return _.get(this.$store.getters['formula/get_lab_config'].config, 'lot.decimal_places', 3)
    },
    pack_stat_unit_name() {
      return ["000", "00", "0", "1", "2", "3", "4", "5"].includes(this.formula.pack_stat_size) ? 'Capsule' : 'Scoop'
    }
  },
  methods: {
    space_remaining(pack_stat_fill) {
      let space = 100
      if (pack_stat_fill) {
        Object.values(pack_stat_fill).forEach(item => {
          space -= item
        })
      }
      return space
    },
  }
}
</script>

<style lang="css" scoped>
</style>
