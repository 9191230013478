<template>
<el-row>
    <div style="margin-top: 15px;">
        <!-- EL-13: Make this a scrollable table -->
        <b>History</b>
        <hr>
        <div class="bottom clearfix">
        <el-table :data="history_local" width="600">
            <el-table-column label="Date" width="180">
                <template slot-scope="scope">
                {{scope.row.date_modified  | moment('YYYY-MM-DD h a')}}
                </template>
            </el-table-column>
            <el-table-column width="170" prop="modified_by" label="User"></el-table-column>
            <el-table-column prop="reason_for_change" label="Note"></el-table-column>
        </el-table>
        </div>
    </div>
</el-row>
</template>

<script>
export default {
    name:"printablehistory",
    props: ["history"],
    data(){
        return {
            history_local : JSON.parse(JSON.stringify(this.history))
        }
      
    },
    methods: {
   
    }
};
</script>

<style>
</style>
