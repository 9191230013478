import store from "@/store/store";
import _ from 'lodash'

//const decimal_places = _.get(store.getters['formula/get_lab_config'].config, 'lot.decimal_places', 3)

const calculate_quantity_required = function (args = {
  ingredient,
  quantity_muliplier,
  activity_factor_difference,
  pack_stat_fill,
  has_activity_factor_ingredient,
  lot,
  task_mutatable,
  formula,
  has_pack_stat_ingredient
}) {
    let messages = []

    let base_quantity = args.ingredient.quantity

    const decimal_places = _.get(store.getters['formula/get_lab_config'].config, 'lot.decimal_places', 3)

    if (
      !args.quantity_muliplier &&
      args.task_mutatable.task.data &&
      args.task_mutatable.task.data.lot
    ) {
      // this.quantity_muliplier = this.task_mutatable.task.data.lot.quantity;
      args.quantity_muliplier =
        args.lot.quantity / (args.formula.formula_quantity || 1);

    }
    if (args.ingredient.has_activity_factor) {
      let current_ingredient
      if (args.lot.lots_allowed) {
        current_ingredient = Object.values(args.lot.lots_allowed).filter(lot => lot.ingredient_identifier === args.ingredient.identifier)[0]
      }        // e.g. if factor is 92.5%, we would need (100/92.5) * the normal quantity
      if (current_ingredient && current_ingredient.data) {
        let new_base_qty = (100/current_ingredient.data.activity_factor) * base_quantity
        args.activity_factor_difference[args.ingredient.identifier] = parseFloat(args.quantity_muliplier * (new_base_qty - base_quantity)).toFixed(decimal_places)
        // console.log('AF diff', this.activity_factor_difference)
        base_quantity = new_base_qty
      }
    }

    if (args.ingredient.has_pack_stat && args.ingredient.identifier !== args.lot.formula.pack_stat_excipient_ingredient && args.has_pack_stat_ingredient) {
      let current_ingredient
      if (args.lot.lots_allowed) {
        current_ingredient = Object.values(args.lot.lots_allowed).filter(lot => lot.ingredient_identifier === args.ingredient.identifier)[0]
      }
      if (current_ingredient && current_ingredient.data) {
        // Determine Capsule fill % by dividing required capsule strength by capsule Packing Statistic for selected Capsule Size.
        // console.log(`(${base_quantity} * ${this.quantity_muliplier} / ${this.lot.quantity}) / ${current_ingredient.data.pack_stat[this.lot.formula.pack_stat_size]}`)
        let capsule_fill_percent = (base_quantity * args.quantity_muliplier / args.lot.quantity) / current_ingredient.data.pack_stat[args.lot.formula.pack_stat_size]
        // console.log('capsule_fill_percent', capsule_fill_percent)
        // Multiply by 100 to convert to %.
        args.pack_stat_fill[args.ingredient.identifier] = parseFloat(capsule_fill_percent * 100).toFixed(decimal_places)
        // console.log('pack_stat_fill', self.pack_stat_fill)
      }
    }

    if (args.has_activity_factor_ingredient && !args.lot.formula.pack_stat_required) {
      if ((args.ingredient.identifier === args.lot.formula.activity_factor_modifying_ingredient)) {
        if (args.activity_factor_difference && Object.entries(args.activity_factor_difference).length >= 1) {
          let total_activity_factor_difference = 0
          Object.values(args.activity_factor_difference).forEach(diff => {
            total_activity_factor_difference += parseFloat(diff)
          })
          // console.log('total_activity_factor_difference', total_activity_factor_difference)
          base_quantity -= (total_activity_factor_difference / args.quantity_muliplier)
          args.lot.formula.activity_factor_modifying_ingredient = args.ingredient.identifier
          // console.log('new base_qty', base_quantity)
        }
      }
    } else if (args.has_pack_stat_ingredient) {
      if (args.ingredient.identifier === args.lot.formula.pack_stat_excipient_ingredient) {
        let current_ingredient = Object.values(args.lot.lots_allowed).filter(lot => lot.ingredient_identifier === args.ingredient.identifier)[0]

        let remaining_space_percent = 100
        // Find space taken by other pack_stat ingredients
        if (Object.entries(args.pack_stat_fill).length >= 1) {
          Object.values(args.pack_stat_fill).forEach(fill => {
            remaining_space_percent -= fill
          })
        }
        // messages.push(`remaining_space_percent, ${remaining_space_percent}`)
        if (current_ingredient && current_ingredient.data) {
          // Find amount of excipient to be packed per capsule - remaining space as percent * ingredient pack_stat
          let excipient_per_capsule = (remaining_space_percent / 100) * current_ingredient.data.pack_stat[args.lot.formula.pack_stat_size]
          // messages.push(`excipient_per_capsule, ${excipient_per_capsule}`)
          // Multiply by number of capsules in formula
          base_quantity = parseFloat(excipient_per_capsule * args.lot.quantity / args.quantity_muliplier).toFixed(decimal_places)
        }

      }
    }
    const quantity = parseFloat((args.quantity_muliplier * parseFloat(base_quantity)).toFixed(decimal_places));

    const return_value = {
      quantity_muliplier: args.quantity_muliplier,
      activity_factor_difference: args.activity_factor_difference,
      pack_stat_fill: args.pack_stat_fill,
      lot: args.lot,
      quantity,
      messages
    }
    return return_value
}

export default {
  calculate_quantity_required
}
