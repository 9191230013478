	<template id="tmpl_pmk_tasks_task_buttons">
  <el-row type="flex" justify="end">
    <slot></slot>
    <el-button @click="log_task_events">
      Task Events
      <span class="badge bg-primary">{{number_of_tasks}}</span>
    </el-button>

    <el-button @click="save_for_later">Save for later</el-button>

    <el-button @click="close_dialog">Cancel</el-button>

    <el-button type="primary" style="margin-left: 10px" @click="mark_complete">
      <span v-html="(label_complete || 'Mark Complete')"></span>
    </el-button>
  </el-row>
</template>

<!-- component -->

<script>
//const moment = require('moment')

import EventService from '@/services/EventService.js'
import handle_task_generic from '@/common.js'
import TaskService from '@/services/task.js'

//require('moment/locale/es')

// Vue.use(require('vue-moment'), {
//   moment
// })
export default {
  props: {
    task: Object,
    label_complete: String,
    task_template: String
  },
  computed: {
    number_of_tasks: {
      get() {
        if (
          typeof this.$store.getters['current_working_task/get_task']['task'][
            'events'
          ] != 'undefined'
        ) {
          return this.$store.getters['current_working_task/get_task']['task'][
            'events'
          ].length
        }

        return 0
      }
    }
  },
  methods: {
    save_for_later() {
      let self = this
      let task_state = this.$store.state[this.task_template]
      let fetch_parameters = {}
      let payload = {}

      if (this.task_template == 'current_working_task') {
        fetch_parameters = {
          id: task_state.current_task.task.id
        }

        payload = {
          date_due: task_state.current_task.task.date_due,
          date_scheduled: task_state.current_task.task.date_scheduled,
          task_state: task_state.current_task.current_state,
          task_status: 'started'
        }
      } else {
        fetch_parameters = {
          id: task_state.task.id
        }

        payload = {
          date_due: task_state.task.date_due,
          date_scheduled: task_state.task.date_scheduled,
          task_state: task_state.form,
          task_status: 'started'
        }
      }

      //            	console.log(payload);

      EventService.pmk_fetch('task_update', 'PUT', fetch_parameters, payload)
        .then(
          self.$store.dispatch('task_event_modal/set_dialog_visibility', true)
        )
        .then(function(data) {
          TaskService.render_messages(data.messages)
        })
        .catch(function() {
          TaskService.render_messages([
            {
              message: 'Could not save task!',
              icon: 'fa fa-exclamation-triangle',
              type: 'error'
            }
          ])
        })
    },
    mark_complete() {
      //console.log("task has been completed, task state is below")

      let task_state = this.$store.state[this.task_template]
      //console.log(task_state);

      // stuff to be sent
      let task_data = task_state.current_task

      if (this.task_template == 'current_working_task') {
        task_data.id = task_state.current_task.task.id
        task_data.task_id = task_state.current_task.task.id
        task_data.task_status = 'complete'

        task_data.task_state = task_state.current_task.current_state
      } else {
        task_data.id = task_state.task.id
        task_data.task_id = task_state.task.id
        task_data.task_status = 'complete'
      }

      handle_task_generic(task_data)

      // close the modal
      this.$store.dispatch('view_task_set_dialog_visibility', false)
    },
    close_dialog() {
      this.$store.dispatch('view_task_set_dialog_visibility', false)
    },
    log_task_events() {
      //console.log(Vue.prototype.pmk.format_to_utc_date_time(new Date()))
      this.$store.dispatch(
        'task_event_history_modal/set_dialog_visibility',
        true
      )
    }
  }
}
</script>
