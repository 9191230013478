<template>
  <div>
    <el-row :gutter="25">
      <el-col :span="12">
        <el-select
          v-model="task_type_filter"
          multiple
          collapse-tags
          filterable
          autocomplete
          no-style="margin-left: 20px;"
          style="width: 100%;"
          placeholder="Task Types"
        >
          <el-option
            v-for="task_type in Object.keys(task_types)"
            :key="task_type"
            :label="task_types[task_type].task_display_name"
            :value="task_type"
          ></el-option>
        </el-select>
      </el-col>
      <el-col :span="12">
        <el-input v-model="form.task_title_filter_input" @input="throttledMethod" placeholder="Search" clearable
                autocomplete="off"
                autocapitalize="none"
                id="task_search_input"
        ></el-input>
      </el-col>
    </el-row>

    <div style="margin-top: 20px">
      <el-checkbox-group v-model="task_due_filter" size="medium">
        <el-checkbox-button label="past_due">Overdue</el-checkbox-button>
        <el-checkbox-button label="currently_due">Due</el-checkbox-button>
        <el-checkbox-button label="upcoming">Upcoming</el-checkbox-button>
        <el-checkbox-button label="completed">
          <span class="hidden-md-and-down">
          Completed
          </span>
          <span class="hidden-lg-and-up">
            <i class="fas fa-check-circle" style="color: lightseagreen;"></i>
          </span>
        </el-checkbox-button>
        <!--
        <el-checkbox-button label="pending">Pending</el-checkbox-button>
        -->
      </el-checkbox-group>
    </div>
    <!-- <div v-for="item of Object.keys(task_types)">{{item}}</div> -->
  </div>
</template>

<script>
import { mapState } from "vuex";
import store from "@/store/store";
import _ from 'lodash'
const uuid_base64 = require("uuid-base64");
// eslint-disable-next-line no-unused-vars
const task_due_statuses = [
  "past_due",
  "upcoming",
  "currently_due",
  "completed",
  "pending"
];
const task_types = {
  formula_verify: {
    filter: 1,
    task_display_name: "Formula Verify",
    schedule_via_ui: 0,
    task_category: "Compounding",
    task_options: { template_type: "legacy-jq" }
  },
  formula_verify_second: {
    filter: 1,
    task_display_name: "Formula Verify (2nd)",
    schedule_via_ui: 0,
    task_category: "Compounding",
    task_options: { template_type: "legacy-jq" }
  },
  formula_rework: {
    filter: 1,
    task_display_name: "Formula Rework",
    schedule_via_ui: 0,
    task_category: "Compounding",
    task_options: { template_type: "legacy-jq" }
  },
  lot_create: {
    filter: 1,
    task_display_name: "Lot Create",
    schedule_via_ui: 0,
    task_category: "Compounding",
    task_options: { template_type: "vuejs" }
  },
  lot_check: {
    filter: 1,
    task_display_name: "Pharmacist Check - Lot",
    schedule_via_ui: 0,
    task_category: "Compounding",
    task_options: { template_type: "vuejs" }
  },
  lot_rework: {
    filter: 1,
    task_display_name: "Lot Rework",
    schedule_via_ui: 0,
    task_category: "Compounding",
    task_options: { template_type: "vuejs" }
  },
  compounding_request: {
    filter: 1,
    task_display_name: "Request to Compound",
    schedule_via_ui: 0,
    task_category: "Compounding",
    task_options: { template_type: "vuejs" }
  },
  inventory_review: {
    filter: 1,
    task_display_name: "Inventory Receipt Review",
    schedule_via_ui: 0,
    task_category: "Compounding",
    task_options: { template_type: "vuejs" }
  },

};

export default {
  data() {
    return {
      task_types: task_types,
      task_filter_types: [],
      form: {
        filter_include_pending: true,
        task_title_filter_input: store.getters["task/getTaskFilters"].task_title || this.task_title_filter || ''
        }
    };
  },
  mounted() {
    document.querySelector('#task_search_input').focus()
  },
  methods: {
    throttledMethod: _.debounce( function(val)  {
      this.task_title_filter = this.form.task_title_filter_input = val
      // console.log('throttled', e)
    }, 300),
    format_utc_datetime(dt, format) {
      if (dt == null) {
        return "";
      } //prevent breaking and prevent showing "undef"
      if (format == null) {
        format = "ddd, MMM Do, h:mm a";
      }

      return this.$moment
        .utc(dt)
        .local()
        .format(format);
    }
  },

  computed: {
    filtered_tasks() {
      return store.getters["task/getTasksFiltered"];
    },
    task_type_filter: {
      get() {
        return store.getters["task/getTaskFilters"].task_types || [];
      },
      set(value) {
        store.dispatch("task/setFilters", {
          task_types: value
        });
      }
    },
    task_due_filter: {
      get() {
        return store.getters["task/getTaskFilters"].task_due_status || [];
      },
      set(value) {
        store.dispatch("task/setFilters", {
          task_due_status: value
        });
      }
    },
    task_status_filter: {
      get() {
        return store.getters["task/getTaskFilters"].task_status_status || [];
      },
      set(value) {
        //defaults:
        let statuses = ["complete", "new", "started"];
        if (value) {
          statuses.push("pending");
        }
        store.dispatch("task/setFilters", {
          task_status: statuses
        });
      }
    },

    task_title_filter: {
      get() {
        // console.log(477, store.getters["task/getTaskFilters"])
        return store.getters["task/getTaskFilters"].task_title || "";
      },
      set(value) {
        if ((value.length == 36 && value.match(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i)) || value.length == 22) {
          let identifier = "";
          // only lots have 22 char compressed barcodes at this time:
          if (value.length == 22) {
            identifier = uuid_base64.decode(value);
          } else {
            identifier = value;
          }

          store.dispatch("task/setFilters", {
            data_id: [identifier],
            task_title: null
          });
        } else {
          store.dispatch("task/setFilters", {
            task_title: value,
            data_id: null
          });
        }
      }
    },

    ...mapState(["task"])
  }
};
</script>

<style >
/* fix: */
.el-tag .el-select__tags-text {
  color: rgb(144, 147, 153);
}
</style>
