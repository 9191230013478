<template>
<div>
    <el-row :gutter="20">
      <el-col :lg="13" :md="collapse_filters ? 23 : 13">
          <TaskList/>
      </el-col>
      <el-col :lg="11" :md="collapse_filters ? 1 : 11" style="border-left: solid #d1dbe5 1px;">
        <span class="hidden-lg-and-up">
          <el-button type="text" @click="collapse_filters = !collapse_filters">
            <i class="fas fa-filter"></i> Filters
            <span v-if="!collapse_filters">
              <i class="fas fa-angle-double-right"></i>
            </span>
          </el-button>
        </span>
        <div v-if="!collapse_filters || windowWidth > 1200">
          <h1>
            Additional Filters
          </h1>
          <div style="margin-top: 20px" v-if="filter_config.enable_priorities">
              <b>Priority:</b>
              <el-checkbox-group v-model="task_priority_filter">
                  <el-checkbox :label="1">Default</el-checkbox>
                  <el-checkbox :label="2">Elevated</el-checkbox>
                  <el-checkbox :label="3">Urgent</el-checkbox>
              </el-checkbox-group>
          </div>

          <div style="margin-top: 20px">
              <b>Status:</b>
              <div style="padding: 0.5em;">
                  <el-checkbox
                      v-model="form.filter_include_pending"
                      @change="toggle_pending_filter(1)"
                  >Include Pending</el-checkbox>
              </div>
          </div>
          <div style="margin-top: 20px" v-if="filter_config.enable_formula_types">
              <b>Formula Types:</b>
              <div style="padding: 0.5em;">
                  <el-select v-model="task_formula_type_filter" multiple filterable placeholder="Formula Types">
                      <el-option
                      v-for="entry in formula_type_options_as_array"
                      :key="entry.identifier"
                      :label="entry.name"
                      :value="entry.identifier">
                      </el-option>
                  </el-select>
                  <ul>
                      <li v-for="entry in task_formula_type_filter" :key="entry">{{ formula_type_options[entry].name }}</li>
                  </ul>
              </div>
          </div>
          <div style="margin-top: 20px" v-if="filter_config.enable_hazardous">
              <b>Hazardous:</b>
              <div style="padding: 0.5em;">
                  <el-radio-group v-model="task_formula_hazardous_filter">
                  <el-radio label="1">Hazardous Only</el-radio>
                  <el-radio label="0">Non-Hazardous Only</el-radio>
                  <el-radio label="both">Both</el-radio>
                  </el-radio-group>
              </div>
          </div>
          <div style="margin-top: 20px" v-if="filter_config.enable_sterile">
              <b>Sterile:</b>
              <div style="padding: 0.5em;">
                  <el-radio-group v-model="task_formula_sterile_filter">
                  <el-radio label="1">Sterile Only</el-radio>
                  <el-radio label="0">Non-Sterile Only</el-radio>
                  <el-radio label="both">Both</el-radio>
                  </el-radio-group>
              </div>
          </div>
          <div style="margin-top: 20px" v-if="filter_config.enable_station_types">
              <b>Station Types:</b>
              <div style="padding: 0.5em;">
                  <el-checkbox-group v-model="task_station_type_filter">
                      <el-checkbox :label="station.value" v-for="station in station_type_options" :key="station.value" style="no-display: block; margin-bottom:  0.65em;"  border>{{ station.description }}</el-checkbox>
                  </el-checkbox-group>
              </div>
          </div>

          <div style="margin-top: 20px" v-if="filter_config.enable_stations">
              <b>Stations:</b>
              <div style="padding: 0.5em;">
                  <el-checkbox-group v-model="form.station_list_selected">
                      <el-checkbox :label="station.description" v-for="station in station_list" :key="station.identifier" style="no-display: block; margin-bottom:  0.65em;"  border></el-checkbox>
                  </el-checkbox-group>
              </div>
          </div>
        </div>
      </el-col>
    </el-row>
</div>
</template>

<script>
import { mapState } from "vuex";
import store from "@/store/store";

import TaskList from "@/components/task/list.vue";

function getPageTasks(routeTo, next) {
  const currentPage = parseInt(routeTo.query.page) || 1;
  console.log('has task list from API:', store.getters['task/has_task_list_from_API'])
  if (!store.getters["task/get_tasks"].length || !store.getters['task/has_task_list_from_API']) {
    store
      .dispatch("task/fetchTasks", {
        page: currentPage
      })
      .then(() => {
        routeTo.params.page = currentPage;
        next();
      })
      .then(() => {
        console.log("store.getters[task/getTasks]:");
        console.log(store.getters["task/get_tasks"]);
      });
  } else {
    routeTo.params.page = currentPage;
    next();
  }
}

export default {
  props: {
    selected_queue: {
      type: String,
      required: false,
      default: "all"
    },
    page: {
      type: Number,
      required: false,
      default: 1
    },
    styleObject: {
      type: Object,
      required: false,
      default: function() {
        return { maxWidth: "30em" };
      }
    }
  },
  components: {
    TaskList
  },
  beforeRouteEnter(routeTo, routeFrom, next) {
    getPageTasks(routeTo, next);
  },
  beforeRouteUpdate(routeTo, routeFrom, next) {
    getPageTasks(routeTo, next);
  },
  data() {
      return {
        collapse_filters: true,
        windowWidth: window.innerWidth,
        filter_config: {
            enable_station_types: true, // pending sponsorship -- READY
            enable_stations: false, // unimplemented
            enable_priorities: true,
            enable_hazardous: true, // pending sponsorship - may need work - filter is on task.hazardous_indicator, which may need to instead be pulled in with an API change and merged into task.data because if the task is created via API, the originator may not know if it is hazardous/sterile/etc
            enable_sterile: true, // pending sponsorship - may need work - filter is on task.sterile_indicator
            enable_formula_types: true // pending sponsorship -- READY
        },
        form: {
            filter_include_pending: true,
            station_list_selected: [],
            task_formula_type_filter: [],
            hazardous_indicator: 'both',
            sterile_indicator: 'both'
        },
        station_list: [
            {
                description: 'HD1',
                identifier: 'HD1'
            },
            {
                description: 'NHD1',
                identifier: 'NHD1'
            },
            {
                description: 'NHD2',
                identifier: 'NHD2'
            },
            {
                description: 'NHD3',
                identifier: 'NHD4'
            },
            {
                description: 'NHD4',
                identifier: 'NHD4'
            },
            {
                description: 'NHD5',
                identifier: 'NHD5'
            },
            {
                description: 'NHD6',
                identifier: 'NHD6'
            },
        ]

      }
  },
  computed: {
    station_type_options() {
      const station_types = this.$store.getters['formula/get_station_types']
      return Object.values(station_types)
    },
    formula_type_options() {
      return this.$store.getters['formula/get_formula_types']
    },
    formula_type_options_as_array() {
      if (typeof this.formula_type_options === "object") {
        return Object.values(this.formula_type_options).filter(entry => !entry.archived);
      } else {
          return []
      }
    },
    task_priority_filter: {
      get() {
        return store.getters["task/getTaskFilters"].task_priority || [];
      },
      set(value) {
        store.dispatch("task/setFilters", {
          task_priority: value
        });
      }
    },
    task_formula_type_filter: {
      get() {
        return store.getters["task/getTaskFilters"].formula_type || [];
      },
      set(value) {
        store.dispatch("task/setFilters", {
          formula_type: value
        });
      }
    },
    task_formula_hazardous_filter: {
      get() {
        return store.getters["task/getTaskFilters"].hazardous_indicator || 'both';
      },
      set(value) {
        store.dispatch("task/setFilters", {
          hazardous_indicator: value
        });
      }
    },
    task_station_type_filter: {
      get() {
        return store.getters["task/getTaskFilters"].station_type || [];
      },
      set(value) {
        store.dispatch("task/setFilters", {
          station_type: value
        });
      }
    },
    task_formula_sterile_filter: {
      get() {
        return store.getters["task/getTaskFilters"].sterile_indicator || 'both';
      },
      set(value) {
        store.dispatch("task/setFilters", {
          sterile_indicator: value
        });
      }
    }
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth
    }
  },
  methods: {
    toggle_pending_filter() {
      this.task_status_filter = this.form.filter_include_pending;
    },

  }
}
</script>

<style>

</style>
