<template>
    <div id="heic-container" style="height: 100%;display: flex;justify-content: center;">
      <div v-if="loading_image" class="loading" style="font-size: 32px">
        <i class="fad fa-spinner fa-spin" /> Loading...
      </div>
    </div>
</template>

<script>
import Common from '@/common'
import heic2any from 'heic2any'
export default {
  props: {
    file: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      filename: '',
      container: null,
      loading_image: true,
    }
  },
  watch: {
    file: {
      deep: true,
      handler() {
        this.container = document.getElementById('heic-container')
        if (this.file.name) {
          this.load_file()
          this.loading_image = true
        } else if (this.file.document_link) {
          this.load_file_from_url(this.file.document_link)
        }
      },
    },
  },
  mounted() {
    this.container = document.getElementById('heic-container')
    if (this.file.name) {
      this.load_file()
      this.loading_image = true
    } else if (this.file.document_link) {
      this.load_file_from_url(this.file.document_link)
    }
  },
  methods: {
    get_document_url(identifier, args = {}) {
      return new Promise(function(resolve, reject) {
        let url = Common.get_document_url(identifier, args)
        resolve(url)
      });
    },
    load_file() {
      this.get_document_url(this.file.identifier).then((file_url) => {
        console.log('file url for new file', file_url)
        fetch(file_url)
          .then((res) => res.blob())
          .then((blob) => heic2any({ blob }))
          .then((conversionResult) => {
            let objectURL = URL.createObjectURL(conversionResult)
            let myImage = new Image()
            myImage.src = objectURL
            myImage.style = 'max-height: 100%;'
            this.container.appendChild(myImage)
            this.loading_image = false
          })
      })
    },
    load_file_from_url(file_url) {
      console.log('file url for new file', file_url)
      fetch(file_url)
        .then((res) => res.blob())
        .then((blob) => heic2any({ blob }))
        .then((conversionResult) => {
          let objectURL = URL.createObjectURL(conversionResult)
          let myImage = new Image()
          myImage.src = objectURL
          myImage.style = 'max-height: 100%;'
          this.container.appendChild(myImage)
          this.loading_image = false
        })
    },
  },
}
</script>

<style lang="css" scoped></style>
