import EventService from "@/services/EventService.js";
import url_for from "@/services/pmk_url_for";
import moment from 'moment'
export const namespaced = true;
export const state = {
    reports: [],
    perPage: 500,
    destroyComp: true,
    needsParams: true,
    category: null,
    excelParams: {
      url: null,
      filename: null
    }
}



export const mutations = {
    SET_REPORTS(state, events) {
      state.reports = events
    },
    SET_DESTROYCOMP(state, data) {
      state.destroyComp = data
    },
    SET_CATEGORY(state, data) {
      state.category = data
    },
    SET_NEEDSPARAMS(state, data){
      state.needsParams = data
    },
    SET_EXCELPARAMS(state, data){
      state.excelParams.url = data.url;
      state.excelParams.filename = data.filename;
    }
}


export const actions = {
    fetchReports({ commit, dispatch, state },data) {
      commit('SET_DESTROYCOMP', true)
        return EventService.getReports(data)
          .then(response => {
            response.data.format.identifier = data.identifier
            commit('SET_REPORTS', response.data)
            commit('SET_DESTROYCOMP', false)
          })
          .catch(error => {
            const notification = {
              type: 'error',
              message: 'There was a problem fetching reports: ' + error.message
            }
            dispatch('notification/add', notification, { root: true })
            })
    },
    setReportCategory({ commit, dispatch, state },data) {
      commit('SET_CATEGORY',data)
      commit('SET_DESTROYCOMP', true)
      if(state.category.require == null){
        commit('SET_NEEDSPARAMS', false)
      }else{
        commit('SET_NEEDSPARAMS', true)
      }
    },
    destroyComponent({ commit, dispatch, state }){
      commit('SET_DESTROYCOMP', true)
    },
    setDestroyComponent({ commit, dispatch, state },data){
      commit('SET_DESTROYCOMP', data)
    },
    setNeedsParams({ commit, dispatch, state },data){
      commit('SET_NEEDSPARAMS', data)
    },
    setExcelUrl({ commit, dispatch, state },category){
      let queryParams = '?';
      let reportName = category.category;

      let excelUrl = new URL(url_for.url_for(category.api, category.params) + '.xlsx', window.location.origin)
      for (var key in category.args) {
        if (!_.isNil(category.args[key])) {
          if (typeof category.args[key] === 'object') {
            Object.values(category.args[key]).forEach((item) => {
              excelUrl.searchParams.append(key, item)
            })
          } else {
            excelUrl.searchParams.append(key, category.args[key])
          }
        }
        if (['date_start', 'date_end'].includes(key)) {
          reportName += `_${moment.utc(category.args[key]).local().format('YYYY-MM-DD')}`;
        }
      }
      // remove the last ?
      /*
      let lengthQuery = queryParams.length;
      if(queryParams.length > 1){
          queryParams= queryParams.substring(0, lengthQuery - 1);
          excelUrl += queryParams;
      }
      */
      let result = {
        url : excelUrl.toString(),
        filename: reportName + '.xlsx'
      };
      commit('SET_EXCELPARAMS', result)
    }
}

export const getters = {
    getReports: state => {
      return state.reports;
    },
    getDestroyComp: state => {
      return state.destroyComp;
    },
    getCategory: state => {
      return state.category;
    },
    getNeedsParams: state => {
      return state.needsParams;
    },
    getExcelParams: state => {
      return state.excelParams;
    }
}
