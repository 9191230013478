<template>
  <div>
    <ol>
      <template v-for="(task, index) in tasks">
        <li :key="index" style="margin-top: 15px;">
          <el-input
            class="sub_task-instructions"
            placeholder="Task Instructions"
            v-model="task.description"
            clearable
          ></el-input>
          <el-input class="sub_task-points" placeholder="5" v-model="task.points">
            <template slot="prepend">Points</template>
          </el-input>
          <div class="formula-task-options">
            <span class="formula-task-options-label">
              <i class="fal fa-balance-scale-right"></i> Record Weight[s]: &nbsp;
            </span>
            <el-switch v-model="task.record_weights" active-text inactive-text></el-switch>&nbsp; | &nbsp;
            <span class="formula-task-options-label">
              <i class="fal fa-keyboard"></i> Record Other Measurements &nbsp;
            </span>
            <el-switch v-model="task.record_manual_measurement" active-text inactive-text></el-switch>&nbsp; | &nbsp;
            <span class="formula-task-options-label">
              <i class="fas fa-camera"></i> Include Photo &nbsp;
            </span>
            <el-switch v-model="task.record_photo" active-text inactive-text></el-switch>
          </div>
        </li>
      </template>
    </ol>
  </div>
</template>

<script>
export default {
  props: {
    tasks: Array
  },
  methods: {},
  computed: {}
};
</script>

<style scoped>
.sub_task-instructions {
  display: inline-block;
  min-width: 25em;
  max-width: 80%;
}
.sub_task-points {
  max-width: 8em;
  margin: 0 1em;
}
</style>