<template>
  <el-row type="flex" justify="end">
    <slot name="buttons"></slot>
    <el-button @click="show_order_events">
      Order Event History
      <span class="badge bg-primary">{{number_of_order_events}}</span>
    </el-button>

    <el-button @click="show_qre_options">Log QRE</el-button>

    <el-button @click="show_pending_exceptions">
      Pending Exceptions
      <span class="badge bg-primary">{{number_of_order_exceptions}}</span>
    </el-button>
  </el-row>
</template>
    
<script>
export default {
  name: 'Icon',
  props: {
    order: {
      type: [Object],
      default: function() {
        return {
          items: [
            {
              description: 'description'
            }
          ]
        }
      }
    }
  },
  computed: {
    number_of_order_events: {
      get() {
        if (this.order.items) {
          return this.order.items.length
        }

        return 0
      }
    },
    number_of_order_exceptions: {
      get() {
        if (this.order.exceptions) {
          return this.order.exceptions.length
        }

        return 0
      }
    }
  },
  methods: {
    show_qre_options() {},
    show_order_events() {},
    show_pending_exceptions() {
      //this.$store.dispatch('view_task_set_dialog_visibility', false)
    }
  }
}
</script>
    
<style scoped>
.icon-wrapper {
  display: inline-flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.4);
  font-size: 1rem;
  font-weight: 600;
}
.icon {
  stroke: currentColor;
  stroke-width: 2;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
  margin-right: 6px;
}
</style>
