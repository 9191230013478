import EventService from "@/services/EventService.js";
import TaskService from "@/services/task.js";
import global_store from "@/store/store";
import _ from 'lodash'
export const namespaced = true;
// should remove:
import Dexie from "dexie";
import db from "@/store/indexdb.js";


// const task_types = {
//   namespaced: true,
//   state: pmk.task_types,
//   mutations: {   },
//   getters: {
//     get_task_type(state,task_type) {
//       return function(task_type) {state[task_type];}
//     },
//     get_task_types(state) {
//       return state;
//     }

//    },
//   actions: {
//       toggle_filter ({ commit, state }, data) {
//         commit('toggle_filter', data);
//       }

//     }
// }

export const state = {
  tasks: [],
  tasks_filtered: [],
  tasksTotal: 0,
  task: {},
  query_filters: {},
  queried_task_results: [],
  perPage: 3,
  has_task_list_from_API: false,

  filters: {
    task_due_status: ["past_due", "currently_due"],
    task_types: [
      "lot_create",
      "lot_check",
      "lot_rework",
      "formula_verify",
      "formula_verify_second",
      "formula_rework",
      "compounding_request",
      "inventory_review"

    ],
    task_priority: [],
    formula_type: [],
    station_type: [],
    task_types_all: [
      "lot_create",
      "lot_check",
      "lot_rework",
      "formula_verify",
      "formula_rework",
      "compounding_request",
      "inventory_review",

      "call_patient",
      "call_patient_for_refill",
      "call_prescriber",
      "call_prescriber_for_refill",
      "contact_prescriber_for_medication_review",
      "documentation_file_billing",
      "documentation_file_clinical",
      "email_received",
      "external_webform_request",
      "failed_fax",
      "fullfilment_order",
      "fullfilment_order_update",
      "incoming_fax",
      "labs_required",
      "new_prescription_enter",
      "new_prescription_enter_and_fill",
      "new_prescription_task",
      "other_patient_task",
      "Patient_Address",
      "Patient_Address_Date_Change",
      "patient_assessment_required",
      "patient_billing_update",
      "Patient_Info",
      "patient_originated_authorization_transfer",
      "patient_portal_payment_update",
      "prior_authorization_call_new_patient",
      "prior_authorization_denial_follow_up",
      "prior_authorization_initiate_prior_authorization",
      "prior_authorization_new_patient",
      "prior_authorization_pharmacist_medication_review",
      "prior_authorization_specialty_therapy",
      "prior_authorization_status_additional_information_required",
      "prior_authorization_status_denial_follow_up",
      "prior_authorization_status_followup",
      "refill_request_from_patient",
      "second_refill_request_fax",
      "SMS-IN",
      "voicemail_patient"
    ]
  }
};

export const mutations = {
  ADD_EVENT(state, event) {
    state.tasks.push(event);
  },
  SET_TASKS_DIRECT(state, data) {
    state.tasks = data;
  },

  SET_TASKS(state, data) {
    let self = this;
    // make it searchable:
    try {
      data.forEach(function(task) {
        let title_string = `${task.id} ${task.data.title} ${task.data.sub_title ||
          ""} ${task.data.title_details || ""}`;
        task.title_string = title_string.toLowerCase();
          // set formula_identifier if available:
          // TODO: remove this when database uses global scheduled_tasks
          if (task.data) {
            if(! task.formula_identifier && task.data.formula && typeof task.data.formula == "object") {
              task.formula_identifier =  task.data.formula.identifier
            }
            if(! task.lot_identifier && task.data.lot && typeof task.data.lot == "object") {
              task.lot_identifier =  task.data.lot.identifier
            }
        }

      });
      console.log(`putting ${data.length} tasks into indexdb`)
      db.tasks
        .bulkPut(data)
        .then(function(lastKey) {
          console.log("Done putting tasks into database");
          console.log("Last task id was: " + lastKey);
          self.commit("task/SET_TASKS_DIRECT", data);
        })
        .then(function() {
          filter_list(state.tasks, state.filters).then(function(list) {
            console.log('146 task list filtered', list)
            self.commit("task/SET_FILTERED_LIST", list);
          });
        })
        .catch(Dexie.BulkError, function(e) {
          // Explicitely catching the bulkAdd() operation makes those successful
          // additions commit despite that there were errors.
          console.error(
            "Some tasks did not succeed. However, " +
              data.length -
              e.failures.length +
              " tasks were added successfully"
          );
        });
    } catch (e) {
      console.log('failed to set tasks: ', e)
    }

  },
  SET_TASK_UPDATE(state, data) {
    let self = this;
    let title_string = `${data.id} ${data.data.title} ${data.data.sub_title ||
      ""} ${data.data.title_details || ""} ${data.data_id || ""}`;
    data.title_string = title_string.toLowerCase();
    // set formula_identifier if available:
    // TODO: remove this when database uses global scheduled_datas
    if (data.data) {
      if(! data.formula_identifier && typeof data.data.formula == "object" && data.data.formula) {
        data.formula_identifier = data.data.formula.identifier
      }
      if(!data.lot_identifier && typeof data.data.lot == "object" && data.data.lot.identifier) {
        data.lot_identifier = data.data.lot.identifier
      }
      if(!data.lot_identifier && typeof data.task_state == "object" && data.task_state.lot_identifier) {
        data.lot_identifier = data.task_state.lot_identifier
      }
    }
    // add or update a task
    // could check it first, but we can simply set it instead:
    //db.tasks.where('id').equals(data.id).toArray()
    state.tasks.push(data)
    db.tasks.put(data).then(() => {
      filter_list(state.tasks, state.filters).then(function(list) {
        console.log('189 task list filtered', list)
        self.commit("task/SET_FILTERED_LIST", list);
      });
    })
    //then update our filtered list:

  },
  // SET_TASK_STATUS(state, data) {
  //   // update will come via WS
  //   let self = this
  // },
  SET_TASKS_TOTAL(state, tasksTotal) {
    state.tasksTotal = tasksTotal;
  },
  SET_TASK_FILTER(state, filter_set) {
    let self = this;

    state.filters = { ...state.filters, ...filter_set };
    // console.log({'tasks-159': state.filters})
    filter_list(state.tasks, state.filters).then(function(list) {
      console.log('209 task list filtered', list)
      self.commit("task/SET_FILTERED_LIST", list);
    });
  },
  SET_TASK_QUERY_FILTER(state, filter_set) {
    let self = this;

    state.query_filters = filter_set;
    filter_list(state.tasks, state.query_filters).then(function(list) {
      console.log('218 task list filtered', list)
      self.commit("task/SET_QUERIED_LIST", list);
    });
  },
  SET_FILTERED_LIST(state, data) {
    state.tasks_filtered = data;
  },
  SET_QUERIED_LIST(state, data) {
    state.queried_task_results = data;
  },
  SET_WORKING_TASK(state, data) {
    state.task = data;
  },
  SET_HAS_TASK_LIST_FROM_API(state) {
    state.has_task_list_from_API = true
  }
};

export const actions = {
  createTask({ commit, dispatch }, event) {
    return EventService.postTask(event)
      .then(() => {
        commit("ADD_EVENT", event);
        commit("SET_EVENT", event);
        const notification = {
          type: "success",
          message: "Your task has been created!"
        };
        dispatch("notification/add", notification, { root: true });
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "There was a problem creating your task: " + error.message
        };
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  },
  // add an action to add a new task via websocket
  // ...we will need to keep a list of tasks by id as we do for the PMK tasking OR alter the API
  fetchTasks({ commit, dispatch, state }, { page }) {
    return EventService.getTasks(state.perPage, page)
      .then(response => {
        //commit('SET_TASKS_TOTAL', parseInt(response.headers['x-total-count']))
        commit("SET_TASKS_TOTAL", response.data.length || 98);
        commit("SET_TASKS", response.data);
        commit('SET_HAS_TASK_LIST_FROM_API')
        if (window.sessionStorage.getItem('task_type_filter_el')) {
          commit("SET_TASK_FILTER", JSON.parse(window.sessionStorage.getItem('task_type_filter_el')));
        } else if (window.localStorage.getItem('task_type_filter_el')) {
          commit("SET_TASK_FILTER", JSON.parse(window.localStorage.getItem('task_type_filter_el')));
        }
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "There was a problem fetching tasks: " + error.message
        };
        commit("SET_TASKS_TOTAL", -1);

        dispatch("notification/add", notification, { root: true });
      });
  },
  fetchTask({ commit, /* getters, */ dispatch, state }, id) {
    if (id == state.task.id) {
      return state.task;
    }
    // no cache
    return EventService.getTask(id)
      .then(response => {
        commit("SET_WORKING_TASK", response.data);
        return response.data;
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "There was a problem fetching task: " + error.message
        };
        dispatch("notification/add", notification, { root: true });
      });
  },
  setFilters({ commit }, filter_list) {
    if (filter_list) {
      window.sessionStorage.setItem('task_type_filter_el', JSON.stringify(filter_list))
      window.localStorage.setItem('task_type_filter_el', JSON.stringify(filter_list))
      commit("SET_TASK_FILTER", filter_list);
      // return getters.getTaskFilters()
    }
  },
  setQueryFilters({ commit }, filter_list) {
    if (filter_list) {
      commit("SET_TASK_QUERY_FILTER", filter_list);
      // return getters.getTaskFilters()
    }
  },

  complete_task({ commit }, task_data) {
    // had { commit }
    console.log("task data", task_data);
    return TaskService.complete_task(task_data.task_id);
  },
  update_task({ dispatch }, task_data) {
    return EventService.updateTask(task_data)
      .then(response => {
        // commit('SET_TASK_STATUS', response.data)
        // TaskService.update_task_status
        if (response.data.messages) {
          dispatch("notification/add", response.data.messages[0], {
            root: true
          });
        }
        return response.data;
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "There was a problem updating task: " + error.message
        };
        dispatch("notification/add", notification, { root: true });
      });
    },
    update_task_entry({ commit }, task_data) {
      return commit('SET_TASK_UPDATE', task_data)
    }
};

export const getters = {
  get_task: state => {
    return state.task;
  },
  get_tasks: state => {
    return state.tasks;
  },
  has_task_list_from_API: state => {
    return state.has_task_list_from_API
  },
  getEventById: state => id => {
    return state.tasks.find(event => event.id === id);
  },
  getTasksFiltered: state => {
    return state.tasks_filtered;
  },
  getTaskFilters: state => {
    if (window.sessionStorage.getItem('task_type_filter_el')) {
      let filter_set = JSON.parse(window.sessionStorage.getItem('task_type_filter_el'))
      return { ...state.filters, ...filter_set }
    } else if (window.localStorage.getItem('task_type_filter_el')) {
      let filter_set = JSON.parse(window.localStorage.getItem('task_type_filter_el'))
      return { ...state.filters, ...filter_set }
    }
    return state.filters;
  },
  queryTasks: state => {
    // filters => {
    //   filter_list(state.tasks, filters).then(function(list) {
    //     self.commit('task/SET_QUERIED_LIST', list)
    //   })
    return state.queried_task_results;
  },
  queryTasksDirect: (state, data) => {
    // console.log({data})
    // let tmp_list = filter_list(state.tasks, data.filters);
    // console.log({tmp_list})
    return filter_list
  },
  datetime_picker_options: picker_type => {
    return datetime_picker_options[picker_type || "default"];
  }

};

// function filter_list_js(list, filters) {
//   // let task_due_status = ['past_due', 'upcoming', 'currently_due', 'completed', 'pending']
//   const result = list.filter(function(task) {
//     let include = true
//     if (include && filters.task_types) {
//       include = filters.task_types.includes(task.task_type)
//     }
//     if (include && filters.task_due_status.length) {
//       include = filters.task_due_status.includes(task.task_due_status)
//     }

//     if (include && filters.patient_id) {
//       include = filters.patient_id.includes(task.patient_id)
//     }

//     if (include && filters.prescriber_id) {
//       include = filters.prescriber_id.includes(task.prescriber_id)
//     }

//     if (include && filters.order_id) {
//       include = filters.order_id.includes(task.order_id)
//     }

//     if (include && filters.task_title) {
//       if (task.data.title) {
//         let title_string =
//           task.id +
//           task.data.title +
//           (task.data.sub_title || '') +
//           (task.data.title_details || '')
//         include = title_string
//           .toLowerCase()
//           .includes(filters.task_title.toLowerCase())
//       } else {
//         include = false
//       }
//     }

//     console.log(task.id + ': ' + include)
//     return include
//   })
//   return
// }

const filter_list = async (list, filters) => {
  // uses the db directly and skips state
  let result = [];
  console.log('FILTERS:')
  console.log(JSON.parse(JSON.stringify(_.cloneDeep(filters))))
  if (! filters ) {
    console.log('no filters)')
    return
  }

    const formula_types = global_store.getters['formula/get_formula_types']

  let db_filter_list=[]

  if (filters.task_priority && !!filters.task_priority.length) {
      console.log('filters.task_priority')
      db_filter_list.push(
        {
          "task_priority": filters.task_priority
        }
      )
    }

  if (filters.task_id) {
    console.log('filters.task_id')
    result = await db.tasks.get(filters.task_id);
    return result;
  } else if (filters.data_id) {
    console.log('filters.data_id')
    result = await db.tasks
      .where("data_id")
      .anyOf(filters.data_id)
      .toArray();
  } else if (filters.patient_id) {
    console.log('filters.patient_id')
    result = await db.tasks
      .where("patient_id")
      .anyOf(filters.patient_id)
      .toArray();
  } else if (filters.formula_identifier) {
    console.log('filters.formula_identifier')
    result = await db.tasks
      .where("formula_identifier")
      .anyOf(filters.formula_identifier)
      .toArray();
      console.log('filters.formula_identifier')
      console.log(result)
  } else if (filters.lot_identifier) {
    // TODO: THIS IS NOT IMPLEMENTED, currently uses task title instead
    console.log('filters.lot_identifier')
    result = await db.tasks
      .where("lot_identifier")
      .anyOf(filters.lot_identifier)
      .toArray();
      console.log('filters.lot_identifier')
      console.log(result)
  } else {
    console.log('other filter')
    let tmp_filter_list = [];
    filters.task_types.forEach(function(task_type) {
      filters.task_due_status.forEach(function(due_status) {
        tmp_filter_list.push([task_type, due_status]);
      });
    });
    result = await db.tasks
      .where("[task_type+task_due_status]")
      .anyOf(tmp_filter_list)
      .sortBy("task_sort_field");
    console.log('496 tmp_filter_list', tmp_filter_list)
    console.log('496 filter result', result)
    console.log('496 filter result length', result.length)
    console.log('496 dexie length', db.tasks.count())
  }
  let final = result.filter(function(task) {
    let include = true;
    if (0 && include && filters.task_types) {
      include = filters.task_types.includes(task.task_type);
    }
    if (0 && include && filters.task_due_status.length) {
      include = filters.task_due_status.includes(task.task_due_status);
    }

    if (include && filters.patient_id) {
      include = filters.patient_id.includes(task.patient_id);
    }
    if (include && filters.prescriber_id) {
      include = filters.prescriber_id.includes(task.prescriber_id);
    }
    if (include && filters.order_id) {
      include = filters.order_id.includes(task.order_id);
    }
    if (include && filters.task_priority && filters.task_priority.length) {
      include = filters.task_priority.includes((parseInt(task.task_priority) || 1));
    }
    // tasks now include formula_type - ready to turn on with sponsorship:
    if (include && filters.formula_type && filters.formula_type.length) {
      include = filters.formula_type.includes(task.formula_type);
    }

    // tasks now include formula_type, with which station_type can be determined - ready to turn on with sponsorship:
    if (include && filters.station_type && filters.station_type.length && task.formula_type && formula_types[task.formula_type]) {
      const task_formula_station_type = formula_types[task.formula_type].station_type
      include = filters.station_type.includes(task_formula_station_type);
    }

    // hazardous_indicator
    if (include && filters.hazardous_indicator ) {
      const task_hazardous_indicator = task.hazardous_indicator
      if (parseInt(filters.hazardous_indicator, 10) === 1 && !!task_hazardous_indicator) {
        include = true
      } else if (parseInt(filters.hazardous_indicator, 10) === 0 && !task.hazardous_indicator) {
        include = true
      }
      else if (filters.hazardous_indicator === 'both') {
        include = true
      } else {
        include = false
      }
    }
    // console.log({filters})
    // sterile_indicator
    if (include && filters.sterile_indicator ) {
      const task_indicator = task.sterile_indicator
      if (parseInt(filters.sterile_indicator, 10) === 1 && !!task_indicator) {
        include = true
      } else if (parseInt(filters.sterile_indicator, 10) === 0 && !task_indicator) {
        include = true
      } else if (filters.sterile_indicator === 'both') {
        include = true
      } else {
        include = false
      }
    }

    if (include && filters.task_title) {
      if (task.title_string) {
        include = task.title_string.includes(filters.task_title.toLowerCase());
      } else {
        include = false;
      }
    }

    if (include && filters.task_status && filters.task_status.length) {
      if (task.task_status) {
        // console.log(task.task_status, filters.task_status)
        include = filters.task_status.includes(task.task_status);
      } else {
        include = false;
      }
    }
    //console.log(task.id + ': ' + include)
    return include;
  });
  console.log('task list filter final:')
  console.log(final)
  return final;
};

const datetime_picker_options = {
  default: {
    // double check the set times for each short cut
    shortcuts: [
      {
        text: "2 hours",
        onClick(picker) {
          const end = new Date();
          const start = new Date();
          end.setTime(start.getTime() + 3600 * 1000 * 2);
          picker.$emit("pick", [start, end]);
        }
      },
      {
        text: "4 hours",
        onClick(picker) {
          const end = new Date();
          const start = new Date();
          end.setTime(end.getTime() + 3600 * 1000 * 4);
          picker.$emit("pick", [start, end]);
        }
      },
      {
        text: "24 hours",
        onClick(picker) {
          const end = new Date();
          const start = new Date();
          end.setTime(end.getTime() + 3600 * 1000 * 24);
          picker.$emit("pick", [start, end]);
        }
      },
      {
        text: "3 days",
        onClick(picker) {
          const end = new Date();
          const start = new Date();
          end.setTime(end.getTime() + 3600 * 1000 * 24 * 3);
          picker.$emit("pick", [start, end]);
        }
      },
      {
        text: "Next week",
        onClick(picker) {
          const end = new Date();
          const start = new Date();
          end.setTime(end.getTime() + 3600 * 1000 * 24 * 7);
          picker.$emit("pick", [start, end]);
        }
      }
    ]
  }
};
