<template>
  <div>
    <div class="input-label top">Formula Type</div>
    <el-select
      v-model="formula_type_selected"
      placeholder="Select"
      filterable
      @change="change_type">
      <el-option
        v-for="type in
                          Object.keys(formula_types)
                            .filter(key => ! formula_types[key].archived)
                            .reduce((obj, key) => {
                              obj[key] = formula_types[key];
                              return obj;
                            }, {})
                "
        :key="type.identifier"
        :label="type.name"
        :value="type.identifier">
      </el-option>
    </el-select>
    <el-button @click="reset" style="margin-left: 10px;">Reset</el-button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      formula_type_selected: ''
    }
  },
  computed: {
    formula_types: {
      get() {
        if (!this.$store.getters["formula/get_lab_config"]) {
          this.$store.dispatch("formula/fetchLabConfig");
        }
        if (this.$store.getters["formula/get_lab_config"]) {
          return this.$store.getters["formula/get_lab_config"]["config"][
            "formula_types"
          ];
        } else return {};
      }
    }
  },
  methods: {
    change_type(selected) {
      this.$emit('selectType', selected)
    },
    reset() {
      this.formula_type_selected = ''
      this.$emit('selectType')
    }
  }
}
</script>

<style lang="css" scoped>
</style>
