<template id="tmpl-pmk-log-support-button">
 <a class="text-primary" @click.prevent="prompt_for_support_case" href="#log-support-request">
	 <span class="h2" v-if="include_icon"><i :class="include_icon"></i></span>
	 <p class="text-gray" >{{button_text}}</p>
</a>
</template>

<script>
// may need to import Log Rocket qq

import LogRocket from 'logrocket';
import EventService from '@/services/EventService.js'
export default {
		props: {
			include_icon: {
				type: String,
				default: '' // e.g. 'far fa-question-circle'
				},
			button_text: {
				type: String,
				default: 'Request Support' // e.g. 'far fa-question-circle'
				},
		},
        name: 'pmk-log-support-button',
		template: '#tmpl-pmk-log-support-button',
		data() {
		      return { }
			},
	    methods: {
	      prompt_for_support_case() {
	        this.$prompt('Please describe the issue you are having', 'Log a Support Case', {
	          confirmButtonText: 'Request Support',
	          inputType: 'textarea',
	          cancelButtonText: 'Cancel',
	          inputPlaceholder: 'Example: When I click XYZ for Patty S I get an error...',
            inputPattern:
              /.+/,
            inputErrorMessage: 'Please describe the issue',
	        }).then(value => {
		        let session_url='';
				// value.user_profile=pmk.user_profile;
				value.user_profile=this.$store.getters["account/profile"]
				delete value.user_profile.password
		        value.userAgent=window.navigator.userAgent;
				value.current_url = window.location.href;
				value.balance = this.$store.getters["equipment/get_balance_state"]
		        if (LogRocket) { LogRocket.getSessionURL(function (sessionURL) {session_url=sessionURL;}); }
		        EventService.pmk_fetch('log_support_case', 'POST', {},
					{'form': value, session_url: session_url})})
				.then(value => {
					this.$message({
						type: 'success',
						message: 'Your support case has been sent'
					});
	        }).catch((error) => {
		        console.log(error);
	          this.$message({
	            type: 'info',
	            message: 'Input canceled'
	          });
        });
      }
    }
  }
</script>
