<template>
  <div>
    <el-card class="box-card">
        <div slot="header" class="clearfix">
            <el-row class="" :gutter="30">
            <el-col :span="12" style="font-size: 1.35em; font-weigh: bold;">
                <div v-if="inventory_lot.product_identifier">
                Code:
                <span class="product-code">{{ inventory_lot.product_identifier }}</span>
                <span v-if="inventory_lot.product_identifiers && inventory_lot.product_identifiers.code" class="product-ndc">
                  ({{ inventory_lot.product_identifiers.code }})
                </span>

                </div>
                <div v-if="inventory_lot.ingredient.description">
                  <span class="product-description">{{ inventory_lot.ingredient.description }}</span>
                  <span class="ingredient-code" style="margin: 0 0.75em;" v-if="inventory_lot.ingredient.code">[{{ inventory_lot.ingredient.code }}]</span>
                  &nbsp;
                  <span
                      class="product-manufacturer"
                  > | {{ inventory_lot.manufacturer.name }}</span>
                </div>
                <!-- <pre>{{ inventory_lot }}</pre> -->
            </el-col>

            <el-col :span="2">
                <div>
                    &nbsp;
                </div>
            </el-col>


            <el-col :span="10">
                <!-- <pre>{{ inventory_entry }}</pre> -->
                <div class="flex-header-container">
                    <div class="item">

                      <el-button
                        type="default"
                        class="status-button"
                        style="padding: 5px; margin-right: 0.5em;"
                        @click="open_label()"
                        :disabled="(! can_print_label()) || form.label_loading"

                      >
                        <span v-if="form.label_loading">
                          <i class="fas fa-circle-notch fa-spin"></i> Loading...
                        </span>
                        <span v-else>
                          <i class="fal fa-tag"></i> Label
                        </span>
                      </el-button>
                    </div>
                    <div class="item" v-if="save_response.accepted || save_response.accepted_response">

                      <el-button
                        type="default"
                        class="status-button"
                        style="padding: 5px; margin-right: 0.5em;"
                        @click="open_item_in_inventory()"
                      >
                        View Item in Inventory
                      </el-button>
                    </div>

                    <div class="item">
                        Status:
                        <el-select v-model="inventory_lot.status" placeholder="Status" @change="gather_rejection_information" :disabled="!form.can_change">
                            <el-option
                            v-for="item in status_options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                            :class="item.value"
                            :disabled="item.disabled()"
                            tabindex="500"
                            >
                            </el-option>
                        </el-select>
                    </div>
                    <div class="item">
                        <el-button
                            :type="form.saved ? 'success' : 'primary'"
                            style="padding: 5px; ; margin-right: .5em; "
                            :style="form.saved && !form.can_change ? 'color: lightseagreen;' : ''"
                            @click="record_entry()"
                            :disabled="!form.can_change || !inventory_lot.status"
                        >
                            <span v-if="form.saved"><i class="fal fa-check-circle"></i> Saved</span>
                            <span v-else><i class="fal fa-save"></i> Save</span>
                        </el-button>
                    </div>
                </div>
            </el-col>

            </el-row>
        </div>

        <el-form no-model="ruleForm" no-rules="rules" ref="ruleForm" class="demo-ruleForm"  :disabled="!is_form_editable">
        <el-row class="margined-row" :gutter="30" v-if="(inventory_lot.status === 'rejected' || inventory_lot.status === 'split_accpect_reject')">

          <el-col :span="5">
            <div>
                <div>Rejection Reason:</div>
                <el-select v-model="inventory_lot.rejection_reason_code" placeholder="Rejection Reason" tabindex="10">
                    <el-option
                    v-for="item in rejection_reason_code_options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                    :class="item.value">
                    </el-option>
                </el-select>
            </div>
          </el-col>

          <el-col :span="9">
            <div>
              Rejection Note:
              <el-input
                placeholder="Reason for rejection..."
                v-model="inventory_lot.rejection_reason_note"
                clearable
                tabindex="20"
              ></el-input>
            </div>
          </el-col>

          <el-col :span="5">
            <div>
              Rejected Quantity:
              <el-input-number
                placeholder="Quantity"
                :controls="false"
                v-model="inventory_lot.quantity_rejected"
                clearable
                @change="calculate_accepted_quantity"
                tabindex="30"
                type="number"
                inputmode="decimal"
              ></el-input-number>
            </div>
          </el-col>

          <el-col :span="5">
            <div>
              Rejected Containers:
              <el-input-number
                placeholder="# Containers"
                :controls="false"
                v-model="inventory_lot.containers_rejected"
                clearable
                @change="calculate_accepted_containers"
                tabindex="40"
                type="number"
                inputmode="decimal"
              ></el-input-number>
            </div>
          </el-col>

        </el-row>

        <el-row class="margined-row" :gutter="30" v-if="(inventory_lot.status === 'split_accpect_reject')">

          <el-col :span="8">
            <div>
                &nbsp;
            </div>
          </el-col>

          <el-col :span="6">
            <div>
                &nbsp;
            </div>
          </el-col>

          <el-col :span="5">
            <div>
              Accepted Quantity:
              <el-input-number
                placeholder="Quantity"
                :controls="false"
                v-model="inventory_lot.quantity_accepted"
                clearable
                tabindex="50"
                type="number"
                inputmode="decimal"
              ></el-input-number>
            </div>
          </el-col>

          <el-col :span="5">
            <div>
              Accepted Containers:
              <el-input-number
                placeholder="# Containers"
                :controls="false"
                v-model="inventory_lot.containers_accepted"
                clearable
                tabindex="60"
                type="number"
                inputmode="decimal"
              ></el-input-number>
            </div>
          </el-col>

        </el-row>

        <el-row class="margined-row" :gutter="30">
          <el-col :span="8">
            Lot #:
            <el-input
              placeholder="ABC123"
              v-model="inventory_lot.lot_number"
              ref="inputLotNumber"
              clearable
              tabindex="110"
            ></el-input>
          </el-col>

          <el-col :span="4">
            <div>
              # of Containers:
              <el-input-number
                placeholder="e.g. 4"
                v-model="inventory_lot.inventory.containers"
                tabindex="130"
                clearable
                :controls="false"
                style="width: 100%"
                type="number"
                inputmode="decimal"
              ></el-input-number>
            </div>
          </el-col>

          <el-col :span="4">
            <div style="margin-top: 20px;">
              <el-button @click="dialogQuantityPromptVisible = true">
                Labels {{ inventory_lot.inventory.label_list ? inventory_lot.inventory.label_list.length : ''}}
              </el-button>
              &nbsp;
              <span style="margin: 0 1.5em;">
                <el-popover
                  placement="right"
                  width="400"
                  trigger="click">
                  <el-table :data="inventory_lot.inventory.label_list">
                    <el-table-column width="150" property="quantity" label="QTY"></el-table-column>
                    <el-table-column width="250" property="note" label="Note"></el-table-column>
                  </el-table>
                  <el-button slot="reference" type="text">View</el-button>
                </el-popover>
              </span>
            </div>
          </el-col>

          <el-col :span="8">
            <el-row :gutter="20">
              <el-col :span="12">
              <div>
                Total Price:
                <el-input
                  v-model="inventory_lot.inventory.purchase_price_total"
                  placeholder="Total Purchase Price"
                  tabindex="150"
                  @keypress.native="isNumber"
                  @change="update_per_unit_cost"
                  type="number"
                  inputmode="decimal"
                ></el-input>
              </div>
              </el-col>

              <el-col :span="12">
              <div>
                Purchase Price Per Unit:
                <span v-if="unit_price > 0" style="text-align:right; margin: 1em 0em 1em 0em;">	&#36;{{unit_price.toFixed(unit_price > 0.01 ? 2 : 5)}}</span>
                <span v-else>n/a</span>
<!--
                <el-input
                  v-model="unit_price"
                  placeholder="Purchase Price"
                  tabindex="150"
                  :disabled="true"
                  @keypress.native="isNumber"
                ></el-input>
 -->
              </div>
              </el-col>
            </el-row>
            <!-- <pre>{{ inventory_lot.inventory }}</pre> -->
          </el-col>


          <!-- <el-col :span="7">
            <i class="fal fa-calendar-alt"></i> &nbsp;
            Beyond Use Date:
            <el-date-picker v-model="form.beyond_use_date" type="date" placeholder="Beyond Use"></el-date-picker>
          </el-col>-->
        </el-row>
        <el-row class="ingredient-suppliers header-area margined-row" :gutter="30">
          <el-col :span="8">
            <span style="no-margin-right: 2em">
               <i class="fal fa-calendar-alt"></i> &nbsp;
            Beyond Use Date:
            </span>
            <el-date-picker
                style="margin-left: 2em;"
              v-model="inventory_lot.inventory.beyond_use_date"
              type="date"
              tabindex="120"
            ></el-date-picker>
          </el-col>

          <el-col :span="8">
            <div>
              Total Quantity<span v-if="inventory_lot.ingredient.unit_of_measure"> [<BaseUnitOfMeasure :unit="(inventory_lot.ingredient.unit_of_measure)"/>]</span>:
              <el-input
                placeholder="Quantity"
                v-model="inventory_lot.inventory.quantity"
                tabindex="140"
                @change="update_per_unit_cost"
                @keypress.native="isNumber"
                type="number"
                inputmode="decimal"
                clearable
              ></el-input>
            </div>
          </el-col>
          <el-col :span="8">
            <div>
              PO#:
              <el-input
                v-model="inventory_lot.inventory.purchase_order"
                placeholder="Purchase Order #"
                tabindex="160"
              ></el-input>
            </div>
          </el-col>
        </el-row>

        <el-row class="margined-row" :gutter="30">
          <el-col :span="8">
            <div>
              CoA Adjustment [%]:
              <el-input v-model="inventory_lot.inventory.coa_adjustment" placeholder="100.5" tabindex="210"
                type="number"
                inputmode="decimal"></el-input>
            </div>
          </el-col>

          <el-col :span="8">
            <div>
              Capsule Weight:
              <el-input v-model="inventory_lot.inventory.capsule_weight_average" placeholder="Avg Weight" tabindex="220"
                type="number"
                inputmode="decimal"></el-input>
            </div>
          </el-col>

          <el-col :span="8">
            <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4}"
              placeholder="Notes"
              v-model="inventory_lot.inventory.note"
              tabindex="300"
            ></el-input>
          </el-col>

          <el-col :span="8" style="clear: both; margin-top: 10px" v-if="inventory_lot.has_activity_factor">
            <div>
              Activity Factor [%]:
              <el-input v-model="inventory_lot.activity_factor" placeholder="100.5" tabindex="222"
                type="number"
                inputmode="decimal"></el-input>
            </div>
          </el-col>

          <el-col :span="8" style="clear: both; margin-top: 10px" v-if="inventory_lot.location !== undefined && inventory_lot.location !== null">
            <div>
              Location:
              <el-input v-model="inventory_lot.location" @input="update_location"></el-input>
            </div>
          </el-col>

        </el-row>

        <el-row>
          <el-col :span="12">
                <documentsList :documents_s="documents_s" @deleteDoc="deleteDoc" />
                <BaseDocumentUpload
                  label="CoA"
                  :document_information="{ category: 'certificate_of_analysis', reference_identifier: inventory_lot.identifier}"
                  :categories="[document_categories[1]]"
                  @update_documents="updateDocuments"
                ></BaseDocumentUpload>
                <BaseDocumentUpload
                  label="Packing Slip / Invoice"
                  :document_information="{ category: 'invoice', reference_identifier: inventory_lot.identifier}"
                  :categories="[document_categories[2]]"
                  @update_documents="updateDocuments"
                ></BaseDocumentUpload>
                <BaseDocumentUpload
                  v-if="pmk_config.modules.electriclab.activity_factor_pack_stats"
                  label="Activity Factor"
                  :document_information="{ category: 'activity_factor', reference_identifier: inventory_lot.identifier}"
                  :categories="[document_categories[3]]"
                  @update_documents="updateDocuments"
                ></BaseDocumentUpload>
                <BaseDocumentUpload
                  v-if="pmk_config.modules.electriclab.activity_factor_pack_stats"
                  label="Pack Stat"
                  :document_information="{ category: 'pack_stat', reference_identifier: inventory_lot.identifier}"
                  :categories="[document_categories[4]]"
                  @update_documents="updateDocuments"
                ></BaseDocumentUpload>
                <BaseDocumentUpload
                  label="Upload Other Documents"
                  :documents.sync="form.documents"
                  :value="form.documents"
                  :categories="document_categories"
                  @update_documents="updateDocuments"
                ></BaseDocumentUpload>
                <takePhoto @update_documents="updateDocuments" />

          </el-col>
        </el-row>
      </el-form>

    </el-card>
    <!-- <pre>{{ inventory_lot }}</pre> -->
    <label-inventory-quantity-prompt
      :dialogVisible="dialogQuantityPromptVisible"
      :labels="inventory_lot.inventory.label_list"
      @input="update_label_entries"
      @close="set_dialogQuantityPromptVisible(false)"
    />
  </div>
</template>

<script>
import NProgress from "nprogress"
import Common from "@/common.js"
import BaseDocumentUpload from '@/components/BaseDocumentUpload'
import url_for from "@/services/pmk_url_for"
import takePhoto from '@/components/TakePhoto.vue'
import documentsList from '@/components/DocumentList.vue'
import label_inventory_quantity_prompt from '@/components/ingredient/label_inventory_quantity_prompt'
import moment from 'moment'
// const uuidv4 = require("uuid/v4")
import _ from 'lodash'
export default {
  components: {
    BaseDocumentUpload,
    takePhoto,
    documentsList,
    'label-inventory-quantity-prompt': label_inventory_quantity_prompt,
  },
  props: {
    inventory_entry: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      save_response: {},
      form: { documents: [], saved: false, can_change: true, label_loading: false },
      status_options: [
        {value: "review", label: "Review", disabled: () => { return !['review', '', null, undefined].includes(this.inventory_entry.status)  } },
        {value: "accepted", label: "Accepted", disabled: () => { return !['review'].includes(this.inventory_entry.status)  } },
        {value: "rejected", label: "Rejected", disabled: () => { return !['review'].includes(this.inventory_entry.status)  } },
        {value: "split_accpect_reject", label: "Split Accept/Reject", disabled: () => { return !['review'].includes(this.inventory_entry.status)  } },
        {value: "void", label: "Void", disabled: () => { return !['review'].includes(this.inventory_entry.status)  } },
      ],
      rejection_reason_code_options: [ // ["damaged", "incorrect_product", "lot_issue", "entry_error", "other"]
        {value: "damaged", label: "Damaged"},
        {value: "incorrect_product", label: "Incorrect Product"},
        {value: "lot_issue", label: "Lot Issue"},
        {value: "entry_error", label: "Entry Error"},
        {value: "other", label: "Other"}
      ],
      documents_s: ( typeof this.inventory_entry.documents === 'object' ?  Object.values(this.inventory_entry.documents) : this.inventory_entry.documents ) || [],
      document_categories: [
        {label:'Other', value:'other'},
        {label:'C of A',value:'certificate_of_analysis'},
        {label:'Purchase Order', value:'Purchase Order'},
        {label:'Activity Factor',value:'activity_factor'},
        {label:'Pack Stat', value:'pack_stat'},
      ],
      categories: this.$store.state.categories,
      inventory_lot: JSON.parse(JSON.stringify(this.inventory_entry)),
      dialogQuantityPromptVisible: false,
    }
  },
  computed: {
    pmk_config: {
      get() {
        return this.$store.getters["app/pmk_config"];
      }
    },
    is_form_editable: {
      get() {
          return !(this.form.saved && !(this.inventory_lot.status == 'review'  || this.inventory_lot.status == 'new'))
      }
    },
    unit_price: {
      get() {
        if (parseFloat(this.inventory_lot.inventory.purchase_price_total) > 0 && parseFloat(this.inventory_lot.inventory.quantity) > 0) {
          // console.log('setting new value' )
          const new_unit_price = parseFloat(this.inventory_lot.inventory.purchase_price_total) / parseFloat(this.inventory_lot.inventory.quantity)
          // console.log('setting new value: ', new_unit_price.toFixed(2) )
          return parseFloat(new_unit_price)
        } else {
          return ''
        }
      }
    },
  },
  watch: {
    inventory_entry() {
      this.save_response = {}
      this.form = { documents: [], saved: false, can_change: true, label_loading: false }
      this.inventory_lot = JSON.parse(JSON.stringify(this.inventory_entry))
      this.inventory_lot.location = ""
      this.inventory_lot.location = JSON.parse(JSON.stringify(this.inventory_entry.ingredient.location))
    }
  },
  created() {
      this.$root.$on('click-save-inventory', this.save_inventory)
  },
  mounted() {
    if (this.inventory_lot && this.inventory_lot.inventory && this.inventory_lot.inventory.purchase_price_per_unit && this.inventory_lot.inventory.quantity && !this.inventory_lot.inventory.purchase_price_total) {
      this.inventory_lot.inventory.purchase_price_total = (this.inventory_lot.inventory.purchase_price_per_unit * this.inventory_lot.inventory.quantity)
    }
    this.inventory_lot.location = ""
    this.inventory_lot.location = JSON.parse(JSON.stringify(this.inventory_entry.ingredient.location))
  },
  methods: {
    update_location(value) {
      this.inventory_lot.location = value
      this.$forceUpdate()
    },
    set_dialogQuantityPromptVisible(value) {
      // console.log('dialogQuantityPromptVisible', this.dialogQuantityPromptVisible, value)
      this.dialogQuantityPromptVisible = value
    },
    update_label_entries(entries) {
      // this.lot.inventory['label_list'] = entries
      Vue.set(this.inventory_lot.inventory,'label_list', entries)

      this.dialogQuantityPromptVisible=false
    },
    open_item_in_inventory(){
      let identifier = this.save_response.accepted ? this.save_response.identifier : this.save_response.accepted_response.data.identifier
      this.$router.push({ name: 'ingredient-inventory-item', params: { identifier }})
    },
    deleteDoc(item, category) {
      this.documents_s.forEach(doc_category => {
        if (doc_category.category === category) {
          let index = doc_category.documents.indexOf(item)
          if (index >= 0) {
            doc_category.documents.splice(index, 1)
          }
        }
      })
    },
    save_inventory(event) {
        console.log('save_inventory',{event})
    },
    arrayContainsCategory(tabb, categ) {
      let res = false;
      let i = 0;
      let pos = i;
      tabb.forEach(function(params) {
        if (params.category == categ) {
          res = true;
          pos = i;
        }
        i++;
      });
      return { contains: res, index: pos };
    },
    updateDocuments(_document){
      // update documents in form
      // console.log({ _document })
      let to_append = {};
      to_append.name = _document.name;
      to_append.note = _document.note;
      to_append.type = _document.type;
      to_append.identifier = _document.identifier;
      let to_append_list = [];
      to_append_list.push(to_append);
      let this_document_category_list = {};
    //   let new_documents_list;
      this_document_category_list["category"] = _document.category;
      this_document_category_list["documents"] = to_append_list;
      // console.log({ document_s: this.documents_s })
      if (this.documents_s.length == 0) {
        // new_documents_list = this_document_category_list;
        this.documents_s.push(this_document_category_list);
      } else {
        let sear = this.arrayContainsCategory(this.documents_s, _document.category);
        if (sear.contains) {
          this.documents_s[sear.index]["documents"].push(to_append);
        } else {
          let to_add_in_list= {};
          to_add_in_list["category"] = _document.category;
          to_add_in_list["documents"] = to_append_list;
          this.documents_s.push(to_add_in_list);

        }
      }
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    calculate_accepted_quantity(current_value, old_value) {
        if (this.inventory_lot.status === 'split_accpect_reject') {
            if (this.inventory_lot.rejection_reason_code === 'entry_error') {
                return
            }
            if (this.inventory_lot.quantity_rejected) {
                this.inventory_lot.quantity_accepted = (this.inventory_lot.inventory.quantity - this.inventory_lot.quantity_rejected)
            }
        }
    },
    calculate_accepted_containers(current_value, old_value) {
        if (this.inventory_lot.status === 'split_accpect_reject') {
            if (this.inventory_lot.rejection_reason_code === 'entry_error') {
                return
            }
            if (this.inventory_lot.containers_rejected) {
                this.inventory_lot.containers_accepted = (this.inventory_lot.inventory.containers - this.inventory_lot.containers_rejected)
            }
        }
    },
    gather_rejection_information() {
        // this is now collected on the form itself
        if (this.inventory_lot.status==='rejected') {
            // record: rejection_reason_code, and rejection_reason_note
        }
        if (this.inventory_lot.status==='split_accpect_reject') {
            // record: rejection_reason_code, rejection_reason_note, and quantity_rejected + containers_rejected
            // quantity_rejected/containers_rejected will be used along with quantity/containers
            // to calculate the DEFAULT quantity_accepted/containers_accepted
            // if there is an entry_error, accepted and rejected can have a value that differs from quantity
        }
    },

    can_print_label() {
      return !!this.inventory_lot.can_print_label;
    },

    open_label() {

      const lab_config = this.$store.getters['formula/get_lab_config']
      const lot_data = this.inventory_lot
      const ingredient_data = (lot_data["formula"] || lot_data["ingredient"])
      // console.log({lot_data})
      // console.log({ingredient_data})
      if (lot_data.lot_type === 'ingredient' && lab_config.config.label && lab_config.config.label.include_GS1_QRCODE) {
        this.form.label_loading = true
        // for ingredients - an NDC should be used
        const product_code = lot_data.product_identifiers.code
        const ndc10_p = Common.pmk_get_ndc_properties(product_code)

        ndc10_p.then(ndc10 => {
          Common.get_lot_label_url(
            lot_data,
            {...ingredient_data, ...{code: ingredient_data.code, ndc10: ndc10} },
            lab_config
          )
          .then(() =>  this.form.label_loading = false);
        })
      } else {

        this.form.label_loading = true

        if (lot_data.inventory && lot_data.inventory.label_list && lot_data.inventory.label_list.length > 1) {
          const tz_offset = new Date().getTimezoneOffset()
          const tz_name = Intl.DateTimeFormat().resolvedOptions().timeZone
          const args = {
            return_result: 1,
            render_and_delete: 0,
            identifier: this.lot.identifier,
            timezone_offset: tz_offset,
            timezone_name: tz_name,

          }
          if (_.get(this.lab_config, 'config.label.dimensions', false)) {
            args.dimensions = 'dimensions_' + _.get(this.lab_config, 'config.label.dimensions')
          }
          Common.open_label_set(args).then( () => this.form.label_loading = false)
        } else {
          Common.get_lot_label_url(
            lot_data,
            {...ingredient_data, ...{code: ingredient_data.code} },
            lab_config
          )
          .then(() =>  this.form.label_loading = false);
        }
      }
    },


    record_entry() {
      // see how createEvent in event.js works:
      // an action gets dispatched, which performs a POST, inside of which a commit is made
      NProgress.start();
      // attach documents
      // NEXT STEPS:

        // 1. need a new API endpoint to save a reviewed inventory item
        // 2. because of a split rejection, labels must come AFTER saving
        // 3. in this fetch below, upon saving accepted inventory, the return should include a URL to print the labels which makes the Print button become available
        // 6. fix up the top part so that saving can work

        // 4. create the task in the task filters
        // 5. route the task to this view





      this.inventory_lot.documents = this.documents_s;
      this.inventory_lot.inventory.beyond_use_date = moment(this.inventory_lot.inventory.beyond_use_date).format()
      this.$store
        .dispatch("ingredient/receive_ingredient_inventory", this.inventory_lot)
        .then((response_data) => {
            console.log('record_entry', {response_data})
          // this.product = this.createFreshEventObject()
          // this.form = this.createFreshEventObject()
          //   this.inventory_lot.saved = true;
          this.form.saved = response_data.data.saved
          this.form.can_change = response_data.data.can_change
          this.save_response = response_data.data
          if (response_data.data.accepted) {
            this.inventory_lot = response_data.data.lot
            this.inventory_lot.can_print_label = response_data.data.can_print_label
            this.inventory_lot.verified_by = response_data.data.verified_by
          }
          else if (response_data.data.accepted_response) {
            this.inventory_lot = response_data.data.accepted_response.data.lot
            this.inventory_lot.can_print_label = response_data.data.accepted_response.data.can_print_label
            this.inventory_lot.identifier = response_data.data.accepted_response.data.identifier
            this.inventory_lot.verified_by = response_data.data.accepted_response.data.verified_by
          } else  {
            this.inventory_lot.can_print_label = response_data.data.can_print_label
            this.inventory_lot.verified_by = response_data.data.verified_by
          }
          // be sure we have a this.inventory_lot.date_entered_inventory:
          this.inventory_lot.date_entered_inventory = this.inventory_lot.date_accepted


        })
        .then(() => NProgress.done())
        .catch(() => {
          NProgress.done();
        });
    },
    update_per_unit_cost() {
      this.inventory_lot.inventory.purchase_price_per_unit = this.unit_price
    }

}
}
</script>

<style scoped>
.accepted { color:seagreen; }
.rejected { color: tomato; }
.split_accpect_reject { color:dodgerblue; }
.void { color:slategray; }
.margined-row { margin: 0 0 1.5em 0;}

.flex-header-container {
  display: flex;
  justify-content: flex-end;
}

.flex-header-container > div.item {
  margin: 10px;
  text-align: center;
}
</style>
