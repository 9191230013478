<template>
<el-row>
    <div style="margin-top: 15px;">
        <!-- EL-13: Make this a scrollable table -->
        <b>History</b>
        <hr>
        <div class="bottom clearfix">
        <el-table :data="history_computed" :height="history_computed && history_computed.length > 0 ? 250 : 0">
            <el-table-column label="Date" class="break-word">
                <template slot-scope="scope">
                  {{moment_format(scope.row.date_modified)}}
                </template>
            </el-table-column>
            <el-table-column prop="modified_by" label="User" class="break-word"></el-table-column>
            <el-table-column prop="reason_for_change" label="Note" class="break-word"></el-table-column>
        </el-table>
        </div>
    </div>
</el-row>
</template>

<script>
import moment from 'moment'
export default {
    name:"historypart",
    props: ["history"],
    computed: {
       history_computed: {
            get() {
                return  JSON.parse(JSON.stringify(this.history))
            }
        }
    },
    methods: {
      moment_format(time) {
        return moment.utc(time).local().format('YYYY-MM-DD h a')
      }
    }
};
</script>

<style>
</style>
