<template>
  <span class="unit-of-measure">{{ unit_display() }}</span>
</template>

<script>
export default {
  props: {
    unit: String
  },
  methods: {
    unit_display() {
      let display_unit = this.unit_of_measure.get(this.unit) || this.unit;
      return display_unit;
    }
  },
  computed: {
    unit_of_measure: {
      get() {
        let unit_list = this.$store.getters["terminology/terminology"][
          "unit_of_measure"
        ];
        if (!unit_list) {
          this.$store.dispatch("terminology/fetchMedicationTerminologies");
          unit_list = this.$store.getters["terminology/terminology"][
            "unit_of_measure"
          ];
        }
        let map = new Map(
          unit_list.map(i => [i.code, i.display || i.description])
        );
        return map;
      }
    }
  }
};
</script>




<style scoped>
</style>