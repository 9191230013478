<template>
  <router-link class="task-link" :to="{ name: 'task-view', params: { id: task.id } }">
    <div
      class="task-card -shadow"
      style="min-height: 100px;"
      :class="`${task.task_type} ${task.task_due_status} task-status-${task.task_status} task-priority-${task.task_priority || 'default'}`"
    >
      <!--
      <span class="eyebrow">@{{ task.id }} by {{ task.date_due }}</span>
      <h4 class="title">{{ task.data.title }}</h4>
      <BaseIcon name="users">{{ task.task_display_name }}</BaseIcon>
      -->
      <!-- task list item.... could be transferred to a different component if desired -->
      <div class="task-list-item">
        <a
          href="#"
          :class="set_task_item_anchor_class(task)"
          :data-taskid="task.id"
          :data-tasktype="task.task_type"
        >
          <div class="media">
            <div
              class_options="busy|offline|invisibled|online"
              :class="set_task_item_prefix_div(task)"
            >
              <i :class="set_task_item_icon_class(task)" v-if="0"></i>
              <img
                class="media-object img-circle pull-left task-icon"
                :src="set_task_item_image_src(task)"
                :onerror="set_task_item_image_onerror(task)"
                width="40"
              >
            </div>
            <div style="display: flex; height: 100px; flex-wrap: wrap;">
              <div style="flex: 0 0 100%;overflow-y: clip; height: 82px" class="media-body">
                <h5 class="media-heading">
                  <span class="task-completed-check" v-if="task.task_status=='complete'">
                    <i class="fal fa-check text-success"></i>
                  </span>
                  <span class="task-pending-check">
                    <div style="font-size:10px;color:gray;">{{task.id}}</div>
                    <el-tooltip
                      class="item"
                      effect="dark"
                      :content="`Assigned to: ${task.checked_out_by} - ${this.$moment.utc(task.date_checked_out).local().format('MM-DD h:mm a')}`"
                      placement="top-end"
                      v-if="task.task_status=='pending'"
                      >
                      <i class="far fa-user-lock" title="Work Pending" style="padding-left: 10px;"></i>
                    </el-tooltip>
                  </span>
                  {{task.task_display_name}}: {{task.data.title}}
                </h5>
                <div class="text-muted task_sub_title" v-html="task.data.sub_title"></div>
                <div class="text-muted task_title_details" v-html="task.data.title_details"></div>
              </div>


              <small class="text-muted task_scheduling_details" style="align-self: flex-end">
                <span v-if="task.date_completed != null">
                  <span class="task_scheduled_date_display task_status_complete">
                    <i class="fa fa-check-square-o"></i>
                    Completed: {{set_task_item_date(task.date_completed)}}
                  </span>
                </span>
                <div v-else>
                  Scheduled:
                  <el-tooltip
                    class="item"
                    effect="dark"
                    todo-fix-qq-content="set_task_schedule_display_date(task.time_since_scheduled)"
                    :content="set_task_item_date(task.date_scheduled)"
                    placement="top-start"
                  >
                    <span
                      class="task_scheduled_date_display"
                      :title="set_task_item_date(task.date_scheduled)"
                      data-toggle="tooltip"
                      data-delay="{'show':  1000, 'hide':  1000}"
                    >
                      <span
                        class="task_schedule_display_due"
                      >{{set_task_schedule_display_due(task.time_since_scheduled)}}</span>

                      <!-- <span
                      class="task_schedule_display_date"
                    >{{set_task_schedule_display_date(task.date_scheduled)}}</span>
                      -->
                    </span>
                  </el-tooltip>, Due:
                  <el-tooltip
                    class="item"
                    effect="dark"
                    todo-fix-qq-content="set_task_schedule_display_date(task.time_until_due)"
                    :content="set_task_item_date(task.date_due)"
                    placement="top-start"
                  >
                    <span
                      class="task_due_date_display"
                      :title="set_task_item_date(task.date_due)"
                      data-toggle="tooltip"
                      data-delay="{'show':  1000, 'hide':  1000}"
                    >
                      <span
                        class="task_schedule_display_due"
                      >{{set_task_schedule_display_due(task.time_until_due)}}</span>
                      <span class="task_schedule_display_date">{{set_task_item_date(task.date_due)}}</span>
                    </span>
                  </el-tooltip>
                </div>
              </small>
            </div>
          </div>
        </a>
      </div>
    </div>
  </router-link>
</template>

<script>
export default {
  props: {
    task: Object
  },
  methods: {
    open_dialog(task) {
      let task_id = task.id;
      // this.$store.dispatch("task_list_set_task",task);
      // // this.set_modal_content(task);
      // // this.$store.dispatch("task_list_set_dialog_visibility",true);
      // vm.$store.commit('view_task_set_modal', task_id);
      return task_id;
    },

    format_utc_datetime(dt, format) {
      if (dt == null) {
        return "";
      } //prevent breaking and prevent showing "undef"
      if (format == null) {
        format = "ddd, MMM Do, h:mm a";
      }

      return this.$moment
        .utc(dt)
        .local()
        .format(format);
    },
    set_task_item_icon_class(task) {
      if (task.task_type == "lab") {
        return "fal fa-tasks";
      } else {
        return "fal fa-tasks";
      }
    },
    set_task_item_anchor_class(task) {
      return `list-group-item in_use func_view_task_details func_mark_task_acknowledged ${
        task.task_type
      } ${task.task_due_status} task-priority-${task.task_priority ||
        "default"}`;
    },
    set_task_item_prefix_div(task) {
      let base_class = "user-status pull-left";
      let class_option = "";

      if (task.date_completed !== null) {
        class_option = "completed";
      } else if (task.past_due > 0) {
        class_option = "offline";
      } else if (task.acknowledged == undefined) {
        class_option = "busy";
      } else {
        class_option = "online";
      }

      return base_class + " " + class_option + " task-item-icon";
    },
    set_task_item_date(date) {
      return this.format_utc_datetime(date);
    },
    set_task_schedule_display_due(task_time_until_due) {
      return this.$moment
        .duration(parseInt(task_time_until_due), "seconds")
        .humanize(true);
    },
    set_task_schedule_display_date(task_schedule_display_date) {
      // seems to not work:
      return (
        this.$moment(task_schedule_display_date)
          .local()
          .format("ddd, MMM Do, h:mm a") +
        "; " +
        task_schedule_display_date
      );
    },
    set_task_item_image_src(task) {
      let base_src =
        "https://pharmetika.com/cdn/internal/assets/images/icons/tasks/";
      let img_type = ".png";
      let task_type = task.task_type;

      return base_src + task_type + img_type;
    },
    set_task_item_image_onerror(task) {
      return (
        "this.onerror=null; this.src='https://pharmetika.com/cdn/internal/assets/images/icons/tasks/" +
        task.task_type +
        ".svg';"
      );
    }
  }
};
</script>

<style scoped>

/* Taken from CoreUI - could be replaced */
.text-success {
  color: #4dbd74 !important;
}

</style>
<style>
.task-card {
  padding: 20px;
  margin-bottom: 24px;
  transition: all 0.2s linear;
  cursor: pointer;
}
h5.media-heading {
  margin-block-end: 0.15em;
}
.task-card {
  /* width: 30em; */
  background: white;
}
.task-card:hover {
  transform: scale(1.01);
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.2), 0 1px 15px 0 rgba(0, 0, 0, 0.19);
}
.task-card > .title {
  margin: 0;
}
.task-link {
  color: black;
  text-decoration: none;
  font-weight: 100;
}

.task_schedule_display_date {
  display: none;
}

.task-item-icon {
  float: left;
  margin-right: 0.5em;
}
.task-icon {
  max-width: 2.5em;
}

.past_due .task-card.-shadow {
  box-shadow: 0 1px 2px 0 #bb4343, 0 1px 5px 0 rgba(0, 0, 0, 0.13);
}

.task-status-pending  .task-card.-shadow {
  background: rgba(110, 110, 110, 0.25);
  box-shadow: 0 1px 2px 0 #bb4343, 0 1px 5px 0 rgba(0, 0, 0, 0.13);
}

.task-priority-2 {
  background: rgba(187, 177, 34, 0.25);
}

.task-priority-3 {
  background: rgba(255, 5, 5, 0.07);
}

.task-completed-check {
  margin-right: 0.5em;
}
.task-pending-check {
    margin-right: 0.5em;
    color: #bb4343;
    font-size: 1.25em;
    float: right;
}
.media-body {
  overflow-y: hidden;
  overflow-y: clip;
}
.media-body:hover {
  overflow-y: auto !important
}
</style>
