// import settings from '../settings.json'
// import _ from 'lodash'
const settings = require('../settings.json')
const _ = require('lodash')

const devEnvs = Object.freeze(['development', 'vpi'])

const getSetting = function(path, fallback) {
  return _.get(settings, `${process.env.NODE_ENV}.${path}`, fallback)
}

const getPMKTarget = function() {
  const defaultPmkTarget = 'https://speedscript.pharmetika.com'
  return getSetting('PMK_TARGET', defaultPmkTarget)
}

const isDevelopment = function() {
  return devEnvs.includes(process.env.NODE_ENV)
}

const isProduction = function() {
  return process.env.NODE_ENV === 'production'
}

module.exports = {
  getPMKTarget,
  isDevelopment,
  isProduction
}
