<template>
  <div style="max-width: 40em;">
    <h1>Tasks for: {{ selected_queue }}</h1>
    <div class="task-filter-bar">
      <TaskFilterBar></TaskFilterBar>

    </div>
    <div style="padding-top: 1.5em;">
      <b>Tasks Displayed:</b> {{ filtered_tasks.length }}/{{ all_tasks.length }}
    </div>

    <RecycleScroller
      class="filtered_tasks"
      :items="filtered_tasks"
      :item-size="160"
      key-field="id"
      v-slot="{ item }"
      page-mode
    >
    <TaskCard
      :task="item"
      :class="`${item.task_due_status} task-priority-${item.task_priority ||
    'default'}`"
    />
    </RecycleScroller>
  </div>
</template>

<script>
import TaskCard from "@/components/TaskCard.vue";
import TaskFilterBar from "@/components/task/filter.vue";

import { mapState } from "vuex";
import store from "@/store/store";

function getPageTasks(routeTo, next) {
  const currentPage = parseInt(routeTo.query.page) || 1;
  if (!store.getters["task/get_tasks"].length) {
    store
      .dispatch("task/fetchTasks", {
        page: currentPage
      })
      .then(() => {
        routeTo.params.page = currentPage;
        next();
      })
      .then(() => {
        console.log("store.getters[task/get_tasks]:");
        console.log(store.getters["task/get_tasks"]);
      });
  } else {
    routeTo.params.page = currentPage;
    next();
  }
}

export default {
  props: {
    selected_queue: {
      type: String,
      required: false,
      default: "all"
    },
    page: {
      type: Number,
      required: false,
      default: 1
    },
    styleObject: {
      type: Object,
      required: false,
      default: function() {
        return { maxWidth: "30em" };
      }
    }
  },
  components: {
    TaskCard,
    TaskFilterBar
  },
  // TODO: Fix tasks API getting called every time task list is loaded [EL-482]
  beforeRouteEnter(routeTo, routeFrom, next) {
    getPageTasks(routeTo, next);
  },
  beforeRouteUpdate(routeTo, routeFrom, next) {
    getPageTasks(routeTo, next);
  },
  computed: {
    filtered_tasks() {
      // console.log(85, store.getters["task/getTasksFiltered"]);
      return store.getters["task/getTasksFiltered"];
    },
    all_tasks() {
      // console.log(85, store.getters["task/getTasksFiltered"]);
      return store.getters["task/get_tasks"];
    },
    hasNextPage() {
      return this.task.tasksTotal > this.page * this.task.perPage;
    },
    pmk_config() {
      return this.$store.getters['app/pmk_config']
    },
    ...mapState(["task", "user"])
  }
};
</script>
<style scoped>
.past_due .task_scheduling_details {
  color: indianred;
}
.scroller {
  height: 100%;
}
</style>
<style>
.task-list-container {
  margin: 1.5em 0;
}

.task-list-container ul {
  padding-left: 0.25em;
}
</style>
