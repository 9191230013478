<template>
  <div class="">
    <el-form ref="form" :model="form" label-width="120px">

      <el-form-item label="Username">
        <el-input v-model="form.user_name"></el-input>
      </el-form-item>

      <el-form-item label="First Name">
        <el-input v-model="form.first_name"></el-input>
      </el-form-item>

      <el-form-item label="Last Name">
        <el-input v-model="form.last_name"></el-input>
      </el-form-item>

      <el-form-item label="Middle Initial">
        <el-input v-model="form.name_MI"></el-input>
      </el-form-item>

      <el-form-item label="Email">
        <el-input v-model="form.email"></el-input>
      </el-form-item>

      <el-form-item label="User Type">
        <el-select v-model="form.user_type" placeholder="please select a user type">
          <el-option v-for="type in user_types" :key="type.value" :label="type.label" :value="type.value"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <el-button type="primary" @click="save_user">
      Save
    </el-button>
  </div>
</template>

<script>
import EventService from "@/services/EventService.js";
export default {
  data() {
    return {
      form: {
        user_name: '',
        first_name: '',
        last_name: '',
        name_MI: '',
        email: '',
        user_type: 'lab',
        extension: 0,
        NPI: null,
        controlled_substance_access: false,
        level: 2,
        name: '',
        status: 'NEW'
      },
      user_types: [
        {
          label: 'Lab Technician',
          value: 'lab'
        },
        {
          label: 'Lab Pharmacist',
          value: 'lab-pharmacist'
        }
      ],
    }
  },
  methods: {
    save_user() {
      this.form.name = `${this.form.first_name} ${this.form.name_MI} ${this.form.last_name}`
      if (!this.form.user_name) {
        this.$message({
          type: 'error',
          message: 'Please enter a Username'
        })
        return
      }
      if (this.form.user_name.length < 3) {
        this.$message({
          type: 'error',
          message: 'Username must be at least 3 characters long'
        })
        return
      }
      if (!this.form.first_name) {
        this.$message({
          type: 'error',
          message: 'Please enter a First Name'
        })
        return
      }
      if (!this.form.last_name) {
        this.$message({
          type: 'error',
          message: 'Please enter a Last Name'
        })
        return
      }
      if (!this.form.email) {
        this.$message({
          type: 'error',
          message: 'Please enter an Email'
        })
        return
      }
      EventService.pmk_fetch(
        'administration_user_create',
        'POST',
        {},
        {user: this.form}).then((response) => {
          if (response.data.messages) {
            response.data.messages.forEach(message => {
              this.$message(message)
            })
          }
          if (response.data.success) {
            this.$message({
              type: 'success',
              message: 'User has been created.'
            })
            this.form = {
              user_name: '',
              first_name: '',
              last_name: '',
              name_MI: '',
              email: '',
              user_type: 'lab',
              extension: 0,
              NPI: null,
              controlled_substance_access: false,
              level: 2,
              name: '',
              status: 'NEW'
            }
            this.$emit('created')
          }
        })
    }
  }
}
</script>

<style lang="css" scoped>
</style>
