<template>
 <el-form label-position="top" label-width="100px" :model="form">
        <el-form-item label="Stability Supporting Documentation">
            <div class="text-secondary pre-wrap">{{localDetails.stability_supporting_documentation}}</div>
        </el-form-item>

        <el-form-item label="Chemical Compatibility">
            <div class="text-secondary pre-wrap">{{localDetails.chemical_compatibility}}</div>
        </el-form-item>

        <el-form-item label="Packaging">
            <div class="text-secondary pre-wrap">{{localDetails.packaging}}</div>
        </el-form-item>

        <el-form-item label="Storage Instructions">
            <div class="text-secondary pre-wrap">{{localDetails.storage_instructions}}</div>
        </el-form-item>

        <el-form-item label="Final Appearance">
            <div class="text-secondary pre-wrap">{{localDetails.final_appearance}}</div>
        </el-form-item>

        <el-form-item label="SOP/Reference Documentation">
            <div class="text-secondary pre-wrap">{{localDetails.reference_documentation}}</div>
        </el-form-item>
    </el-form>
</template>

<script>
export default {
    name:"printabledetails",
    props: ["details",'form'],
    data(){
        return {
            localDetails : JSON.parse(JSON.stringify(this.details))
        }
    },
    watch: {
        localDetails: {
            handler(v){
                this.$emit('updateDetails', v)
             },
          deep: true
        }
    },
    methods: {

    }
};
</script>

<style>
</style>
