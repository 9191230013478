import Vue from "vue";
import Router from "vue-router";
import layouts from "../layout";

/* EL */
import formulaSearch from "@/views/formula/search.vue";

import formulaManage from "@/views/formula/manage.vue";
import formulaManageTypes from "@/views/formula/manage_types.vue";
import configureAuxiliaryLabels from "@/views/configuration/auxiliary_labels.vue";
import configureSystemSettings from "@/views/configuration/system_settings.vue";
import userList from '@/views/configuration/user_list.vue';
import orderCreate from "@/views/order/create.vue";
import ingredientSearch from "@/views/ingredient/search.vue"
import ingredientManage from "@/views/ingredient/manage.vue";
import ingredientInventory from "@/views/ingredient/inventory.vue";
import ingredientInventoryReceive from "@/views/ingredient/inventory_receive.vue";
import ingredientInventoryReview from "@/views/ingredient/inventory_review.vue";
import ingredientInventorySearch from "@/views/ingredient/inventory_search.vue";
import ingredientInventoryItem from "@/views/ingredient/inventory_item.vue";
import lotWorksheet from "@/views/formula/lot_worksheet.vue";
import formulaPrint from "@/views/print/formula.vue";
import reports from "@/views/reports/reports.vue"
import formulaBulkImport from '@/views/configuration/formula_import.vue'

import manufacturersManage from "@/views/manufacturer/manage.vue";
import equipmentManage from "@/views/equipment/manage.vue";

import SOPManage from '@/views/configuration/manage_sops.vue'

import lotLabel from "@/views/print/lotLabel.vue";

import TaskList from "@/components/task/viewList.vue";
import TaskViewer from "@/components/task/viewer.vue";
import taskCreate from "@/components/task/create.vue";
import dashboard from "@/views/apps/electric_lab_dashboard.vue";
//import taskCreate from "@/components/BaseDocumentUpload.vue";

import NProgress from "nprogress";
import store from "@/store/store";
import EventService from "@/services/EventService";
// to create fresh objects
// import formula_store from "@/store/modules/formula";

import Profile from "@/views/pages/Profile.vue";

Vue.use(Router);

const router = new Router({
  mode: "history",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return { x: 0, y: 0 };
      //      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "welcome",
      component: TaskList,
      props: { default: true, styleObject: { width: "40em" } },
      beforeEnter(routeTo, routeFrom, next) {
        let current_filters = store.getters["task/getTaskFilters"];
        current_filters.data_id = null;
        store.dispatch("task/setFilters", current_filters).then(d => {
          next();
        });
      }
    },
    {
      path: "/logout",
      name: "log-out",
      beforeEnter() {
        location.replace(EventService.log_out());
      }
    },
    {
      path: "/dashboard",
      name: "welcome",
      component: dashboard,
      props: { default: true, styleObject: { width: "40em" } },
      beforeEnter(routeTo, routeFrom, next) {
        let current_filters = store.getters["task/getTaskFilters"];
        current_filters.data_id = null;
        store.dispatch("task/setFilters", current_filters).then(d => {
          next();
        });
      }
    },
    {
      path: "/profile",
      name: "profile",
      component: Profile,
      meta: {
        auth: true,
        // layout: layouts.navLeft,
        // searchable: true,
        tags: ["pages"]
      }
    },

    {
      path: "/formula/search",
      name: "formula-search",
      component: formulaSearch,
      props: true,
      beforeEnter(routeTo, routeFrom, next) {
        next();
      }
    },

    {
      path: "/formula/create",
      name: "formula-create",
      component: formulaManage,
      props: true,
      beforeEnter(routeTo, routeFrom, next) {
        store.dispatch("formula/fetchFormula", "create").then(data => {
          routeTo.params.formula = data;
          next();
        });
      }
    },
    // right now, this can only happen from a task:
    // {
    //   path: '/lot/create',
    //   name: 'lot-create',
    //   component: formulaManage,
    //   props: true,
    //   beforeEnter(routeTo, routeFrom, next) {
    //     store.dispatch('dformula/fetchFormula', 'create').then(data => {
    //       routeTo.params.formula = data
    //       next()
    //     })
    //   }
    // },
    {
      path: "/formula/manage/:formula_identifier",
      name: "formula-manage",
      component: formulaManage,
      props: true,
      beforeEnter(routeTo, routeFrom, next) {
        store
          .dispatch("formula/fetchFormula", routeTo.params.formula_identifier)
          .then(data => {
            console.log({ param: data });
            routeTo.params.formula = data;
            next();
          });
      }
    },
    {
      path: "/formula/:formula_identifier/worksheet",
      name: "formula-worksheet",
      component: formulaPrint,
      props: true,
      beforeEnter(routeTo, routeFrom, next) {
        store
          .dispatch("formula/fetchFormula", routeTo.params.formula_identifier)
          .then(data => {
            console.log({ param: data });
            routeTo.params.formula = data;
            next();
          });
      }
    },

    {
      path: "/ingredient/manage",
      name: "ingredient-manage",
      component: ingredientSearch
    },
    {
      path: "/ingredient/create",
      name: "ingredient-create-item",
      component: ingredientManage,
      props: true
    },
    {
      path: "/ingredient/manage/:ingredient_identifier",
      name: "ingredient-manage-item",
      component: ingredientManage,
      props: true,
      beforeRouteUpdate(routeTo, routeFrom, next) {
        if (!routeTo.params.ingredient && routeTo.params.ingredient_identifier) {
          store
          .dispatch("ingredient/fetchIngredient", routeTo.params.ingredient_identifier)
          .then(data => {
            console.log({ line194: data})
            this.ingredient_prop = data.data.data;
            next();
          });
        }
      },
      beforeEnter(routeTo, routeFrom, next) {
        store
          .dispatch("ingredient/fetchIngredient", routeTo.params.ingredient_identifier)
          .then(data => {
            console.log({ line203: data})
            routeTo.params.ingredient_prop = data.data.data;
            next();
          });
      }
    },

    {
      path: "/ingredient/inventory",
      name: "ingredient-inventory",
      component: ingredientInventory
    },
    {
      path: "/ingredient/inventory/receive",
      name: "ingredient-inventory-receive",
      component: ingredientInventoryReceive
    },
    {
      path: "/ingredient/inventory/review",
      name: "ingredient-inventory-review",
      component: ingredientInventoryReview
    },
    {
      path: "/ingredient/inventory-search",
      name: "ingredient-inventory-search",
      component: ingredientInventorySearch,
      props: true,
      beforeEnter(routeTo, routeFrom, next) {
        next();
      }
    },
    {
      path: "/ingredient/inventory-item/:identifier/worksheet",
      name: "ingredient-inventory-item-worksheet",
      component: lotWorksheet,
      props: true,
      beforeEnter(routeTo, routeFrom, next) {
        console.log("routing");
        store
          .dispatch("lot/getInventory", routeTo.params.identifier)
          .then((data) => {
            console.log({ path: "/ingredient/inventory-item/:identifier/worksheet", beforeEnter: true, data });
            routeTo.params.lot = !data ? {} : data.data ;
            next();
          });
      }
    },
    {
      path: "/ingredient/inventory-item/:identifier",
      name: "ingredient-inventory-item",
      component: ingredientInventoryItem,
      props: true,
      beforeEnter(routeTo, routeFrom, next) {
        console.log("routing");
        store
          .dispatch("lot/getInventory", {
            identifier: routeTo.params.identifier,
            options: {
              include_inventory_lots_used_in: true,
              search_all_inventory: true
            }})
          .then(data => {
            console.log(data);
            routeTo.params.lot = data;
            next();
          });
      }
    },

    {
      path: "/inventory-removed/inventory-item/:identifier/worksheet",
      name: "ingredient-inventory-removed-item-worksheet",
      component: lotWorksheet,
      props: true,
      beforeEnter(routeTo, routeFrom, next) {

        const param_options = { status: 'cancelled', inventory: 'removed', identifier: routeTo.params.identifier };
        // console.log("------------------------------routing", "ingredient-inventory-removed-item-worksheet", param_options );
        store
          .dispatch("lot/getInventory", param_options)
          .then((data) => {
            console.log({ path: "/ingredient/inventory-item/:identifier/worksheet", beforeEnter: true, data });
            routeTo.params.lot = !data ? {} : data.data ;
            next();
          });
      }
    },

    {
      path: "/manufacturer/manage",
      name: "manufacturers-manage",
      component: manufacturersManage,
      props: true,
      beforeEnter(routeTo, routeFrom, next) {
        store.dispatch("manufacturer/fetchManufacturers").then(data => {
          console.log("routing");
          console.log(data);

          routeTo.params.manufacturers = data;
          next();
        });
      }
    },

    {
      path: "/equipment/manage",
      name: "equipment-manage",
      component: equipmentManage,
      props: true,
      beforeEnter(routeTo, routeFrom, next) {
        store.dispatch("equipment/fetchEquipmentList").then(data => {
          console.log("routing:" + routeTo);
          console.log(data);

          routeTo.params.equipment = data;
          next();
        });
      }
    },

    {
      path: "/sops/manage",
      name: "sops-manage",
      component: SOPManage,
      props: true,
      beforeEnter(routeTo, routeFrom, next) {
        store.dispatch("equipment/fetchEquipmentList").then(data => {
          console.log("routing:" + routeTo);
          console.log(data);

          routeTo.params.equipment = data;
          next();
        });
      }
    },
    {
      path: "/sops/:identifier/history",
      name: "sop-history",
      component: equipmentManage,
      props: true,
      beforeEnter(routeTo, routeFrom, next) {
        store.dispatch("equipment/fetchEquipmentList").then(data => {
          console.log("routing:" + routeTo);
          console.log(data);

          routeTo.params.equipment = data;
          next();
        });
      }
    },

    {
      path: "/formula/manage_types",
      name: "formula-manage-types",
      component: formulaManageTypes,
      props: true,
      beforeEnter(routeTo, routeFrom, next) {
        store.dispatch("formula/fetchLabConfig").then(data => {
          console.log("FS:" + data);
          console.log(
            "fetchLabConfig.config.formula_types",
            data.data.config.formula_types
          );

          routeTo.params.formula_types = data.data.config.formula_types;
          next();
        });
      }
    },
    {
      path: "/formula/bulk_import",
      name: "formula-bulk-import",
      component: formulaBulkImport,
    },
    {
      path: "/configuration/auxiliary_labels",
      name: "configuration-auxiliary-labels",
      component: configureAuxiliaryLabels,
      props: true,
      beforeEnter(routeTo, routeFrom, next) {
        store.dispatch("formula/fetchLabConfig").then(response => {
          console.log(response.data.formula.auxiliary_labels);
          routeTo.params.auxiliary_labels =
            response.data.formula.auxiliary_labels;
          next();
        });
      }
    },
    {
      path: "/configuration/system_settings",
      name: "configuration-system-settings",
      component: configureSystemSettings,
      props: true,
      beforeEnter(routeTo, routeFrom, next) {
        store.dispatch("formula/fetchLabConfig").then(response => {
          routeTo.params.config = response.data;
          next();
        });
      }
    },
    {
      path: "/configuration/create_user",
      name: "configuration-create-user",
      component: userList,
    },
    {
      path: "/tasks/create_order",
      name: "order-create",
      component: orderCreate
    },
    {
      path: "/tasks/create",
      name: "task-create",
      component: taskCreate
    },
    {
      path: "/tasks/list",
      name: "task-list",
      component: TaskList,
      props: { default: true, styleObject: { width: "40em" } },
      beforeEnter(routeTo, routeFrom, next) {
        let current_filters = store.getters["task/getTaskFilters"];
        current_filters.data_id = null;
        store.dispatch("task/setFilters", current_filters).then(d => {
          next();
        });
      }
    },
    {
      path: "/tasks/view/:id",
      name: "task-view",
      component: TaskViewer,
      props: { default: true, styleObject: { width: "40em" } },
      beforeEnter(routeTo, routeFrom, next) {
        console.log("routing to: ", routeTo);
        store.dispatch("task/fetchTask", routeTo.params.id).then(task => {
          console.log(task);
          routeTo.params.task = task;
          next();
        });
      }
    },

    {
      path: '/knowledge_base',
      component: () => import('@/views/knowledgebase/helpjuice'),
      name: 'knowledge_base',
      meta: { title: 'Knowledgebase' },
      hidden: true,
      children: [
      {
        path: '*',
        component: () => import('@/views/knowledgebase/helpjuice'),
        name: 'knowledge_base',
        meta: { title: 'Knowledgebase' },
        hidden: true
      }
    ]
    },
    {
      path: "/lot/:identifier/print/worksheet",
      name: "lot-worksheet",
      component: lotWorksheet,
      props: true,
      beforeEnter(routeTo, routeFrom, next) {
        // for consideration - we may not WANT to alter the store here?
        // store.dispatch('lot/fetchLot', routeTo.params.identifier).then(data => {
        //   routeTo.params.lot = data
        //   next()
        // })
        EventService.getInventory(routeTo.params.identifier).then(data => {
          routeTo.params.lot = data;
          next();
        });
      }
    },

    {
      path: "/lot/:identifier/label",
      name: "lot-label",
      component: lotLabel,
      props: true,
      beforeEnter(routeTo, routeFrom, next) {
        // for consideration - we may not WANT to alter the store here?
        // store.dispatch('lot/fetchLot', routeTo.params.identifier).then(data => {
        //   routeTo.params.lot = data
        //   next()
        // })
        EventService.getInventory(routeTo.params.identifier).then(data => {
          routeTo.params.lot = data;
          next();
        });
      }
    },
    {
      path: "/reports/:category/:identifier?",
      name: "reports",
      component: reports,
      props: true,
      // beforeEnter(routeTo, routeFrom, next) {
      //   let categories = {
      //    "lots_entered_by_date":{
      //       category: "lots_entered_by_date",
      //       api : "electric_lab_report_lots_entered_by_date",
      //       params: {version: 'v5'},
      //       args: {fields: 'data.yield', date_start: '2019-08-07', date_end: '2019-08-28'},
      //       identifier: "id"
      //     },
      //     "expiring_product_lots": {
      //       category: "expiring_product_lots",
      //       api : "electric_lab_report_expiring_product_lots",
      //       params: {version: 'v5'},
      //       args: { date_start: '2019-08-07', date_end: '2019-08-28'},
      //       identifier: "lot_identifier"
      //     },
      //     "lots_using_lot_as_ingredient": {
      //       category: "lots_using_lot_as_ingredient",
      //       api : "electric_lab_report_lots_using_lot_as_ingredient",
      //       params:  {version: 'v5', lot_identifier: routeTo.params.identifier},
      //       args: {},
      //       identifier: "lot_identifier"
      //     }
      //   }
      //   store.dispatch("reports/fetchReports", categories[routeTo.params.category]).then(d => {
      //     next();
      //   });
     // }
    }

  ]
});

router.beforeEach((routeTo, routeFrom, next) => {
  NProgress.start();
  next();
});

router.afterEach(() => {
  NProgress.done();
});

const l = {
  contenOnly() {
    store.commit("setLayout", layouts.contenOnly);
  },
  navLeft() {
    store.commit("setLayout", layouts.navLeft);
  },
  navRight() {
    store.commit("setLayout", layouts.navRight);
  },
  navTop() {
    store.commit("setLayout", layouts.navTop);
  },
  navBottom() {
    store.commit("setLayout", layouts.navBottom);
  },
  set(layout) {
    store.commit("setLayout", layout);
  }
};

//insert here login logic
const auth = {
  loggedIn() {
    return store.getters.isLogged;
  },
  logout() {
    store.commit("setLogout");
  }
};

const el_key_name = 'electric_lab_reauthed'

router.beforeEach((to, from, next) => {
  let authrequired = false;
  if (to && to.meta && to.meta.auth) authrequired = true;

  //console.log('authrequired', authrequired, to.name)

  if (authrequired) {
    if (auth.loggedIn()) {
      if (to.name === "login") {
        localStorage.setItem(el_key_name, 'true')
        store.dispatch('reauth/set_authorized')
        window.location.href = "/";
        return false;
      } else {
        next();
      }
    } else {
      if (to.name !== "login") {
        localStorage.setItem(el_key_name, 'false')
        store.dispatch('reauth/unset_authorized')
        window.location.href = "/login";
        return false;
      }
      next();
    }
  } else {
    if (auth.loggedIn() && to.name === "login") {
      localStorage.setItem(el_key_name, 'true')
      store.dispatch('reauth/set_authorized')
      window.location.href = "/";
      return false;
    } else {
      next();
    }
  }

  if (to && to.meta && to.meta.layout) {
    l.set(to.meta.layout);
  }
});

router.afterEach((to, from) => {
  setTimeout(() => {
    store.commit("setSplashScreen", false);
    //document.querySelector("#scroll-top").scrollIntoView();
    document.querySelector("#scroll-top").firstElementChild.scrollIntoView();
  }, 500);
});

export default router;
