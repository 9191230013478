<template>
  <div>
<el-card class="box-card">
  <div slot="header" class="clearfix">
    <span>System Settings</span>
    <el-button size="mini" type="primary" style="float: right;" @click="save_config()">Save</el-button>

  </div>
  <div v-if="config_mutable">
    <div v-if="config_mutable.config && config_mutable.config.formula">
      <h3>Formulas</h3>
      <el-row class="mb-5">
          <el-col :span="6">
              Number of reviewers Required:
          </el-col>
          <el-col :span="12">
              <el-input-number size="small" v-model="config_mutable.config.formula.require_secondary_formula_verification" :max="3"></el-input-number>
          </el-col>
      </el-row>
      <el-row class="mb-5">
          <el-col :span="6">
              Enable Unit Dose Labels:
          </el-col>
          <el-col :span="12">
              <el-switch v-model="config_mutable.config.formula.enable_unit_dose_labels"></el-switch>
          </el-col>
      </el-row>
    </div>


    <div style="margin-top:1em;" v-if="config_mutable.config && config_mutable.config.lot">
      <h3>Lots</h3>
      <el-row class="mb-5">
          <el-col :span="6">
              Decimal Rounding Places:
              <span style="font-size: 10px; color: #4e4e4e">
                e.g. 2.62051 -> {{parseFloat(2.62051).toFixed(config_mutable.config.lot.decimal_places)}}
              </span>
          </el-col>
          <el-col :span="12">
              <el-input-number size="small" v-model="config_mutable.config.lot.decimal_places" :min="2" :max="5"></el-input-number>
          </el-col>
      </el-row>
      <el-row class="mb-5">
          <el-col :span="6">
              Allow custom lot number:
          </el-col>
          <el-col :span="12">
              <el-switch v-model="config_mutable.config.lot.allow_custom_lot_number"></el-switch>
          </el-col>
      </el-row>
      <el-row class="mb-5">
          <el-col :span="6">
              Allow Beyond Use Date Modification:
          </el-col>
          <el-col :span="12">
            <el-switch
              v-model="config_mutable.config.lot.allow_beyond_use_date_modifications">
            </el-switch>
          </el-col>
      </el-row>
      <el-row class="mb-5">
          <el-col :span="6">
              Allow technicians to scrap lots:
          </el-col>
          <el-col :span="12">
              <el-switch v-model="config_mutable.config.lot.allow_technicians_to_scrap_lot"></el-switch>
          </el-col>
      </el-row>
      <el-row class="mb-5">
          <el-col :span="6">
              Disallow manual measurements in tasks:
          </el-col>
          <el-col :span="12">
            <el-switch
              v-model="config_mutable.config.lot.disallow_manual_measurement_tasks">
            </el-switch>
          </el-col>
      </el-row>
      <el-row class="mb-5">
          <el-col :span="6">
              Balance timestamp must be within 10 seconds:
          </el-col>
          <el-col :span="12">
            <el-switch
              v-model="config_mutable.config.lot.require_current_balance_time">
            </el-switch>
          </el-col>
      </el-row>
      <el-row class="mb-5">
          <el-col :span="6">
              Liquid measurement variance [%]:
          </el-col>
          <el-col :span="12">
            <el-slider
              v-model="config_mutable.config.lot.liquid_measurement_variance"
              :step="parseFloat(0.5)" :min="parseFloat(0.0)" :max="parseFloat(5.0)"
              show-input>
            </el-slider>
          </el-col>
      </el-row>
    </div>

    <div v-if="config_mutable.config && config_mutable.config.label">
      <h3>Label</h3>
      <el-row class="mb-5">
          <el-col :span="6">
              Include GS1 QRCode on formula:
          </el-col>
          <el-col :span="12">
              <el-switch v-model="config_mutable.config.label.include_GS1_QRCODE_on_formula"></el-switch>
          </el-col>
      </el-row>
      <el-row class="mb-5">
          <el-col :span="6">
              Include GS1 QRCode:
          </el-col>
          <el-col :span="12">
              <el-switch v-model="config_mutable.config.label.include_GS1_QRCODE"></el-switch>
          </el-col>
      </el-row>
      <el-row class="mb-5 ml-5" v-if="config_mutable.config.label.include_GS1_QRCODE">
          <el-col :span="6">
              GS1: Include Quantity
          </el-col>
          <el-col :span="12">
              <el-switch v-model="config_mutable.config.label.GS1_include_quantity"></el-switch>
          </el-col>
      </el-row>
      <el-row class="mb-5 ml-5" v-if="config_mutable.config.label.include_GS1_QRCODE">
          <el-col :span="6">
              GS1: Include Date Compounded
          </el-col>
          <el-col :span="12">
              <el-switch v-model="config_mutable.config.label.GS1_include_date_compounded"></el-switch>
          </el-col>
      </el-row>
      <el-row class="mb-5">
          <el-col :span="6">
              Include 1D Lot Barcode:
          </el-col>
          <el-col :span="12">
              <el-switch v-model="config_mutable.config.label.include_1d_lot_barcode"></el-switch>
          </el-col>
      </el-row>
      <el-row class="mb-5">
          <el-col :span="6">
              Include Formula ID:
          </el-col>
          <el-col :span="12">
              <el-switch v-model="config_mutable.config.label.include_formula_id"></el-switch>
          </el-col>
      </el-row>
      <el-row class="mb-5">
          <el-col :span="6">
              Restrict re-printing of labels to pharmacists:
          </el-col>
          <el-col :span="12">
              <el-switch v-model="config_mutable.config.label.restrict_lot_label_reprinting"></el-switch>
          </el-col>
      </el-row>
    </div>

    <!-- <pre style="white-space: pre-wrap; color: #8e8e8e;">{{config_mutable.config}}</pre> -->
  </div>
</el-card>



  </div>
</template>
<script>
import Common from "@/common.js";
import EventService from "@/services/EventService.js";

const uuidv4 = require("uuid/v4");
import NProgress from "nprogress";
const moment = require("vue-moment");

export default {
   props: {
    config: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
        config_mutable: { ...this.createFreshConfig() } ,
        form: {}
    //   auxiliary_labels_mutable: auxiliary_labels || {}
    }
  },
  computed: {  },
  mounted() {
    this.config_mutable.config =
    {
      ...{
        lot: {
          disallow_manual_measurement_tasks: false,
          decimal_places: 3,
          allow_custom_lot_number: false,
          allow_technicians_to_scrap_lot: true,
          allow_beyond_use_date_modifications: false,
          require_current_balance_time: this.config_mutable.config.lot.require_current_balance_time || true,
        },
        label: {
          include_GS1_QRCODE_on_formula: false,
          include_GS1_QRCODE: false,
          GS1_include_quantity: false,
          GS1_include_date_compounded: false,
          include_1d_lot_barcode: false,
          restrict_lot_label_reprinting: false,
        },
        formula: {
          require_secondary_formula_verification: 1,
          enable_unit_dose_labels: 0,
        }
      },
      ...this.config_mutable.config
    }
  },
  methods: {
    save_config() {
        let self = this
        EventService.updateLabConfig(this.config_mutable.config)
        .then(() => NProgress.done())
        .then(function() {
          const notification = {
            type: "success",
            message: "Settings saved"
          };
          self.$store.dispatch("formula/fetchLabConfig")
          self.$store.dispatch("notification/add", notification, {
            root: true
          });
        })
    },
    createFreshConfig() {
        let new_object =  {
          formula: {
            require_secondary_formula_verification: 1
          },
          lot: {},
          label: {},
        }

        Object.keys(new_object).forEach( key => {
          new_object[key] = { ...new_object[key], ...this.config.config[key] || {} }
        })
        Object.keys(this.config.config).forEach( key => {
          if ("undefined" == typeof new_object[key]) {
            new_object[key] = { ...this.config.config[key] }
          }
        })

        console.log('loaded config', { ...this.config, ...{ config: new_object}})

        return { ...this.config, ...{ config: new_object}}
    }
  }
};
</script>

<style scoped>
.property-row {
  margin-bottom: 1em;
}
</style>
