<template>
  <div class="">
    <el-row style="margin-bottom: 1.5em;">
      <el-col>
        <div style="margin-bottom: 1.5em;">
          <el-input
            class="inline-input"
            style="max-width: calc(100% - 220px)"
            placeholder="Filter"
            v-model="form.filter_query"
            @input="filter_list"
            size="small">
            <template slot="prepend"><i class="fal fa-search" aria-hidden="true"></i></template>
          </el-input>
          <el-checkbox v-model="form.show_archived" style="float: right; padding: 7px 5px">
            Show Archived
          </el-checkbox>
          <el-button
            type="default"
            class="status-button"
            style="float: right; padding: 7px 5px"
            @click="add_sop()"
          >
            <i class="fal fa-plus"></i> Add New
          </el-button>
        </div>
      </el-col>
    </el-row>
    <div class="sop_list">

      <div
        v-for="[sop_identifier, sop] of Object.entries(filtered_sop_list)
        .filter( entry =>
          (!entry[1].archived || form.show_archived)
        )"
        :key="sop_identifier"
      >
      <el-card class="box-card"
        style="margin-bottom: 1.5em;"
      >
        <div slot="header" class="clearfix">
          <span>SOP: {{sop.name}}</span>
          <el-button
            type="text"
            @click="toggle_collapse(sop_identifier)"
            style="margin-left: 1em;"
          >
            <span v-show="sop.collapse"><i class="far fa-chevron-double-down"></i></span>
            <span v-show="!sop.collapse"><i class="far fa-chevron-double-up"></i></span>
          </el-button>
          <el-button
            v-if="!sop.archived"
            plain
            type="danger"
            class="status-button"
            style="float: right; padding: 5px;"
            @click="remove_sop(sop)"
          >
            <i class="fas fa-minus-circle"></i> Archive
          </el-button>
          <span v-else>
            <el-button
              style="float: right; padding: 5px;color:#1f2d3d"
              @click="reactivate_sop(sop)"
            >
              Reactivate
            </el-button>&nbsp;
            <el-button
              plain
              type="text"
              style="float: right; padding: 5px;color:#1f2d3d;"
            >
              <i class="fas fa-archive"></i> Archived
            </el-button>&nbsp;
          </span>
          <el-button
            v-if="!sop.archived"
            type="primary"
            class="status-button"
            style="float: right; padding: 5px;"
            @click="save_sop(sop)"
          >
            <i class="fal fa-save"></i> Save
          </el-button>
        </div>
        <div class="" v-if="!sop.collapse">
          <el-row :gutter="10" class="property-row">
            <el-col :span="4">
              <span>
                <span style="font-size: 1.5em">SOP:</span>
                &nbsp;
                <span class="text-secondary"></span>
              </span>
            </el-col>
            <el-col :span="8">
              <el-input v-model="sop.name" @input="update_sop(sop, 'name', sop.name)"></el-input>
            </el-col>
            <el-col :span="2"></el-col>
            <el-col :span="2"></el-col>
          </el-row>
          <el-row :gutter="10" class="property-row">
            <el-col :span="4">
              <span>
                <span style="font-size: 1.5em">Description:</span>
                &nbsp;
                <span class="text-secondary"></span>
              </span>
            </el-col>
            <el-col :span="8">
              <el-input v-model="sop.description" type="textarea" @input="update_sop(sop, 'description', sop.description)"></el-input>
            </el-col>
            <el-col :span="2"></el-col>
            <el-col :span="2"></el-col>
          </el-row>
          <el-row :gutter="10" class="property-row">
            <el-col :span="4">
              <span>
                <span style="font-size: 1.5em">PCAB Requirement:</span>
                &nbsp;
                <span class="text-secondary"></span>
              </span>
            </el-col>
            <el-col :span="8">
              <el-input v-model="sop.pcab_requirement" @input="update_sop(sop, 'pcab_requirement', sop.pcab_requirement)"></el-input>
            </el-col>
            <el-col :span="2"></el-col>
            <el-col :span="2"></el-col>
          </el-row>

          <div v-for="task in Object.values(sop.tasks)" :key="task.identifier">
            <el-divider></el-divider>

            <el-row :gutter="10" class="property-row">
              <el-col :span="4">
                <span>
                  <span style="font-size: 1.5em">Task Name:</span>
                  &nbsp;
                  <span class="text-secondary"></span>
                </span>
              </el-col>
              <el-col :span="8">
                <el-input v-model="task.name" @input="update_task(task, 'name', task.name)"></el-input>
              </el-col>
              <el-col :span="10">&nbsp;</el-col>
              <el-col :span="2">
                <el-button
                  plain
                  type="danger"
                  class="status-button"
                  style="float: right; padding: 5px;"
                  @click="delete_task(sop, task)"
                >
                  <i class="fas fa-trash-alt"></i> Delete
                </el-button>
              </el-col>
            </el-row>

            <el-row :gutter="10" class="property-row">
              <el-col :span="4">
                <span>
                  <span style="font-size: 1.5em">Frequency:</span>
                  &nbsp;
                  <span class="text-secondary"></span>
                </span>
              </el-col>
              <el-col :span="8">
                <el-select v-model="task.frequency" placeholder="Select frequency" @change="update_task(task, 'frequency', task.frequency)">
                  <el-option
                    v-for="option in form.frequency_options"
                    :key="option"
                    :label="option"
                    :value="option">
                  </el-option>
                </el-select>
              </el-col>
              <el-col :span="2"></el-col>
              <el-col :span="2"></el-col>
            </el-row>

            <el-row :gutter="10" class="property-row">
              <el-col :span="4">
                <span>
                  <span style="font-size: 1.5em">Sub-Tasks:</span>
                  &nbsp;
                  <span class="text-secondary"></span>
                </span>
              </el-col>
              <el-col :span="20">
                <taskspart :tasks="task.tasks" @updateTasks="updateTasks($event, task)"></taskspart>
              </el-col>
            </el-row>

          </div>

          <el-button @click="add_task(sop)" size="small">
            <i class="fal fa-plus"></i>
            Add SOP Task
          </el-button>

        </div>
      </el-card>
      </div>

    </div>
  </div>
</template>

<script>
const uuidv4 = require("uuid/v4");
import taskspart from "@/views/formula/parts/tasks.vue"
export default {
  components: {
    taskspart,
  },
  data() {
    return {
      form: {
        filter_query: '',
        show_archived: false,
        frequency_options: [
          'Daily',
          'Every Other Day',
          'Weekly',
          'Every Other Week',
          'Monthly',
          'Every Other Month'
        ],
        new_task: ''
      },
      sop_list: {},
      filtered_sop_list: [],
    }
  },
  methods: {
    add_sop() {
      let new_sop = {
        identifier: uuidv4(),
        name: 'New SOP',
        description: '',
        pcab_requirement: '',
        tasks: {},
        collapse: true,
        archived: false,
      }
      this.sop_list[new_sop.identifier] = new_sop
      this.filter_list()
    },
    add_task(sop) {
      let new_task = {
        identifier: uuidv4(),
        name: '',
        frequency: 'Weekly',
        sub_tasks: []
      }
      sop.tasks[new_task.identifier] = new_task
      this.$forceUpdate()
    },
    delete_task(sop, task) {
      delete sop.tasks[task.identifier]
      this.$forceUpdate()
    },
    filter_list() {
      if (this.form.filter_query) {
        this.filtered_sop_list = Object.fromEntries(Object.entries(this.sop_list).filter(entry =>
          entry[1].description.toLowerCase().includes(this.form.filter_query.toLowerCase()) ||
          entry[1].frequency.toLowerCase().includes(this.form.filter_query)
        ))
      } else {
        this.filtered_sop_list = this.sop_list
      }
      console.log('this.filtered_sop_list', this.filtered_sop_list)
      this.$forceUpdate()
    },
    save_sop(sop) {
      console.log('TODO: SAVE SOP API', sop)
    },
    remove_sop(sop) {
      sop.archived = true
      this.save_sop(sop)
      this.filter_list()
    },
    reactivate_sop(sop) {
      sop.archived = false
      this.save_sop(sop)
      this.filter_list()
    },
    toggle_collapse(identifier) {
      this.sop_list[identifier].collapse = !this.sop_list[identifier].collapse
      this.$forceUpdate()
    },
    update_sop(sop, property, value) {
      sop[property] = value
      this.$forceUpdate()
    },
    update_task(task, property, value) {
      task[property] = value
      this.$forceUpdate()
    },
    updateTasks(e, task){
     task.sub_tasks = e
    },
  }
}
</script>

<style lang="css" scoped>
.property-row {
  margin-bottom: 1em;
}
</style>
