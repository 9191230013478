<template>
  <div v-if="formula">
    <el-backtop target=".main" :right="22"><i class="el-icon-caret-top"></i></el-backtop>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>
          <span style="font-size: 1.5em" v-if="formula && formula.name">{{ formula.name }}</span>
          &nbsp;
          <span class="text-secondary" v-if="formula && formula.identifiers">[{{formula.identifiers.formula_id}}]</span>
        </span>
        <span class="formula-status">{{ formula.formula_status }}</span>


        <span v-if="formula.formula_status == 'active'">
          <el-button
            type="primary"
            class="status-button"
            style="float: right; padding: 5px"
            v-if="formula.formula_status == 'active'"
            @click="lot_request_dialogFormVisible=true"
          >
            <i class="fal fa-tasks"></i> Create Task
          </el-button>

          <el-dialog
            title="Schedule Task to Create Lot"
            :visible.sync="lot_request_dialogFormVisible"
            width="85%"
          >
            <el-row :gutter="20">
              <el-col span="14">
                <el-form :model="lot_request" :rules="lotReqRules" ref="lotReqForm">
                  <el-form-item label="Quantity" label-width="200" prop="quantity">
                    <el-input v-model="lot_request.quantity" autocomplete="off"
                      type="number"
                      inputmode="decimal"></el-input>
                      <span style="color:tomato;font-size:.85em;"
                        v-if="formula && parseFloat(formula.minimum_batch_quantity) > parseFloat(lot_request.quantity)"
                      >
                        Quantity is less than the formula minimum batch size ({{parseFloat(formula.minimum_batch_quantity)}})
                      </span>
                  </el-form-item>

                  <el-form-item label="Note" label-width="200">
                    <el-input v-model="lot_request.task_note" autocomplete="off"></el-input>
                  </el-form-item>
                  <el-form-item label="Priority" label-width="200">
                    <el-select v-model="lot_request.priority" placeholder="Choose Level">
                      <el-option label="Normal" value="1"></el-option>
                      <el-option label="Elevated" value="2"></el-option>
                      <el-option label="Urgent" value="3"></el-option>
                    </el-select>
                  </el-form-item>

                  <el-row>
                    <el-form-item label="Date Due" label-width="200" prop="date">
                      <el-date-picker
                        style="width: 100%;"
                        type="datetimerange"
                        v-model="lot_request.date"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        format="MM/dd HH:mm"
                        :picker-options="datetime_picker_options"
                        range-separator="To"
                        start-placeholder="Start date"
                        end-placeholder="End date"
                      ></el-date-picker>
                    </el-form-item>
                    <!-- <label class="inputLabel">Date Due</label> -->

                  </el-row>
                  <el-row>
                    <el-col :span="24">
                      <el-checkbox label="Merge if possible" v-model="lot_request.merge"></el-checkbox>
                    </el-col>
                  </el-row>
                  <h5 class="title" style="font-size:16px;">Ingredient Availability</h5>
                  <ingredientAvailablity v-if="formula" :formula="formula" :quantity="parseFloat(lot_request.quantity)" />
                </el-form>
              </el-col>
              <el-col span="10">
                <outstandingTasks :task_type="['lotCreate', 'lot_check']" :filters="{formula_identifier: formula.identifier }" :styleObject="{ fontSize: '0.7em', maxHeight: '28em'}"></outstandingTasks>
              </el-col>
            </el-row>
            <span slot="footer" class="dialog-footer">
              <el-button @click="lot_request_dialogFormVisible = false">Cancel</el-button>
              <el-button
                type="primary"
                @click=" create_formula_lot_task()"
              >Create Task</el-button>
            </span>
          </el-dialog>
        </span>
        <span v-if="formula.formula_status == 'proposed'">
          <el-button
            type="primary"
            class="status-button"
            style="float: right; padding: 5px"
            @click="lot_request_post_approval_dialogFormVisible=true"
          >
            <i class="fal fa-tasks"></i> Schedule Task Pending Approval
          </el-button>

          <el-dialog
            title="Schedule Task to Create Lot Pending Approval"
            :visible.sync="lot_request_post_approval_dialogFormVisible"
          >
            <el-form :model="lot_request" :rules="lotReqRules" ref="lotReqForm">
              <el-form-item label="Quantity" label-width="200" prop="quantity">
                <el-input v-model="lot_request.quantity" autocomplete="off"
                  type="number"
                  inputmode="decimal"></el-input>
                  <span style="color:tomato;font-size:.85em;"
                    v-if="formula && parseFloat(formula.minimum_batch_quantity) > parseFloat(lot_request.quantity)"
                  >
                    Quantity is less than the formula minimum batch size ({{parseFloat(formula.minimum_batch_quantity)}})
                  </span>
              </el-form-item>

              <el-form-item label="Note" label-width="200">
                <el-input v-model="lot_request.task_note" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item label="Priority" label-width="200">
                <el-select v-model="lot_request.priority" placeholder="Choose Level">
                  <el-option label="Normal" value="1"></el-option>
                  <el-option label="Elevated" value="2"></el-option>
                  <el-option label="Urgent" value="3"></el-option>
                </el-select>
              </el-form-item>

              <el-row>
                <el-form-item label="Date Due" label-width="200" prop="date">
                    <el-date-picker
                      style="width: 100%;"
                      type="datetimerange"
                      v-model="lot_request.date"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      format="MM/dd HH:mm"
                      :picker-options="datetime_picker_options"
                      range-separator="To"
                      start-placeholder="Start date"
                      end-placeholder="End date"
                    ></el-date-picker>
                </el-form-item>
                <!-- <label class="inputLabel">Date Due</label> -->

              </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
              <el-button @click="lot_request_post_approval_dialogFormVisible = false">Cancel</el-button>
              <el-button
                type="primary"
                @click=" create_formula_lot_task({ create_as_subsequent_task: true})"
              >Create Task</el-button>
            </span>
          </el-dialog>

          <span
            v-if="( (formula.modified_by == user.name
                  || [(formula.verification_list || [])].some( verified_by_user => { return user.username == verified_by_user} ) )
                  || user.user_type != 'lab-pharmacist') &&
             (formula.verification_tasks.formula_verify || formula.verification_tasks.formula_verify_second)"
          >
            <div style="float: right; padding: 5px; margin-right: 1.5em">
              <el-button
                type="info"
                style="color: #909399; background: #f4f4f5; border-color: #d3d4d6;"
                @click="go_to_task(formula.verification_tasks.formula_verify || formula.verification_tasks.formula_verify_second)"
                plain
              >
                Waiting for review
                <i class="fal fa-external-link"></i>
              </el-button>
            </div>
          </span>
          <span v-else>
            <el-button
              type="success"
              class="status-button"
              style="float: right; padding: 5px"
              v-if="formula.formula_status == 'proposed'"
              @click.once="save_formula({formula_status: 'active', task: { task_id: task_id, status: 'complete'}})"
            >
              <i class="fal fa-save"></i>
              Mark Verified
            </el-button>
            <el-button
              type="warning"
              class="status-button"
              style="float: right; padding: 5px"
              v-if="formula.formula_status == 'proposed'"
              @click="dialogReworkVisible = true "
            >
              <i class="fal fa-save"></i> Needs Changes
            </el-button>
          </span>
        </span>

        <!-- <span v-if="formula.modified_by == user.name && (formula.verification_tasks.formula_verify || formula.verification_tasks.formula_verify_second)">
          <div
            style="float: right; padding: 5px; margin-right: 1.5em"
            v-if="lot.status == 'review'"
          > <el-button type="info"
              style="color: #909399; background: #f4f4f5; border-color: #d3d4d6;"
              @click="go_to_task(formula.verification_tasks.formula_verify || formula.verification_tasks.formula_verify_second)"

              plain
              >Waiting for review <i class="fal fa-external-link"></i></el-button></div>
        </span>-->
        <span v-else>
          <el-button
            type="primary"
            class="status-button"
            style="float: right; padding: 5px"
            v-if="formula.formula_status == 'draft'"
            @click="dialogDescribeChangesVisible = true "
          >
            <i class="fal fa-save"></i> Send to Verification Queue
          </el-button>
          <el-button
            type="primary"
            class="status-button"
            style="float: right; padding: 5px"
            v-else-if="formula.formula_status == 'active' || formula.formula_status == undefined"
            @click="dialogDescribeChangesVisible = true "
            was-click="save_formula({formula_status: 'proposed',  workflow: { task_note: '', task_type: 'formula_verify' } })"
          >
            <i class="fal fa-save"></i> Save Edit/Send to Verification Queue
          </el-button>
        </span>

        <el-button
          type="default"
          class="status-button"
          style="float: right; padding: 5px"
          v-if="formula.formula_status == 'retired'"
          @click="save_formula({formula_status: 'active'})"
        >
          <i class="fal fa-save"></i> Re-Activate
        </el-button>
      </div>
      <div v-if="formula.workflow">
        <el-collapse v-model="form.rework_reason_show" @change="toggleReworkVisibility">
          <el-collapse-item title="Rework Feedback" name="1" v-if="formula.workflow.rework_reason">
            <div>{{formula.workflow.rework_reason}}</div>
          </el-collapse-item>
          <el-collapse-item
            title="Formula Tasks"
            name="2"
            v-if="formula.workflow && formula_task && formula_task.task_status != 'complete'"
          >
            <div v-if="task_id">
              <div
                class="formula-task-title"
              >{{formula_task.task_display_name}}: {{formula_task.title}}</div>
              <div class="formula-task-note">{{formula_task.task_note}}</div>
              <el-button
                type="default"
                class="status-button"
                style="padding: 5px"
                @click="complete_task(task_id)"
              >
                <i class="fal fa-ballot-check"></i> Complete Task
              </el-button>&nbsp;
              <router-link class="task-link" :to="{ name: 'task-view', params: { id: task_id } }">
                {{task_id}}
                <i class="fal fa-external-link"></i>
              </router-link>
            </div>
          </el-collapse-item>
          <el-collapse-item name="pending-approval-tasks" :title="`Tasks Pending Approval [${formula.subsequent_tasks.length}]`" v-if="'undefined' != typeof formula.subsequent_tasks && formula.subsequent_tasks.length">
                 <subsequentTasks :subsequentTasks="formula.subsequent_tasks"></subsequentTasks>
          </el-collapse-item>
        </el-collapse>
      </div>
     <!-- options -->
         <div class="formula-options">
        <el-row :gutter="10">
          <el-col :xs="24" :sm="24" :md="16" :lg="16" :xl="12">
            <div class="input-label top">Formula Name </div>
            <el-input placeholder="Formula Name" v-model="formula.name" maxlength="255" clearable @change="formula.name = formula.name.trim()"></el-input>
          </el-col>


          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <span class="indicators">
              <span class="indicator" style="color: Tomato;" v-if="formula.formula_information && formula.formula_information.is_hazardous">
                <i style="font-size: 1.0rem;" class="fad fa-flask-poison"></i>&nbsp;Hazardous
              </span>
              <span class="indicator" v-if="formula.formula_information && formula.formula_information.dea_schedule && parseInt(formula.formula_information.dea_schedule.level) > 0">
                <i style="font-size: 1.0rem;" class="fad fa-exclamation-triangle"></i>&nbsp;{{ formula.formula_information.dea_schedule.description || 'Controlled'}}
              </span>

            </span>
            <div style="float: right; padding: 5px; margin-right: 1.5em">
              <el-button
                type="primary"
                class=""
                @click="print()"
                size="mini"
                plain
              >
                <i class="fa fa-print"></i>  Print
              </el-button>
              <el-button
                type="info"
                class="grey-button"
                @click="createCopy()"
                size="mini"
                plain
              >
                <i class="fa fa-copy"></i>  Copy Formula
              </el-button>
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="16" :lg="16" :xl="12">
            <div class="input-label top">Label Description </div>
            <el-input placeholder="Label Description" v-model="formula.label_description" maxlength="255" clearable @change="formula.label_description = formula.label_description.trim()"></el-input>

          </el-col>
          <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
            <div class="input-label top">Formula ID</div>
            <el-input
              placeholder="Formula ID"
              v-model="formula.identifiers.formula_id"
              maxlength="36"
              clearable
            ></el-input>
          </el-col>
          <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
            <div class="input-label top">NDC</div>
            <el-input
              placeholder="Formula NDC"
              v-model="formula.identifiers.ndc"
              maxlength="12"
              clearable
            ></el-input>
          </el-col>

          <el-col :span="24" v-if="formula.label_description !== '' && lab_config.config && lab_config.config.formula && lab_config.config.formula.enable_unit_dose_labels">
              <div class="input-label top">Unit Dose Label Details </div>
              <el-input placeholder="Unit Dose Label Details" v-model="formula.unit_dose_label_details" maxlength="220" clearable style="width: 60%"></el-input>
          </el-col>

          <el-col :xs="12" :sm="10" :md="6" :lg="4" :xl="2">

            <div style="margin: 0.75em">
              <el-checkbox v-model="formula.options.is_lab_stock">
                <span style="vertical-align: middle;">
                  Add as
                  <br>ingredient?
                </span>
              </el-checkbox>
            </div>
            <div style="margin: 0.75em">
              <el-checkbox v-model="formula.options.is_formulary_item">
                <span style="vertical-align: middle;">
                  Add to
                  <br>formulary?
                </span>
              </el-checkbox>
            </div>

          </el-col>
          <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
            <div class="input-label top">Formula Type</div>
            <el-select
              v-model="formula.formula_type"
              filterable
              placeholder="Formula Type"
              style="text-transform: capitalize;"
            >
              <el-option
                style="text-transform: capitalize;"
                v-for="(formula_type, type_identifier) in
                          Object.keys(formula_types)
                            .sort((a, b) => {
                              if (formula_types[a].archived && !formula_types[b].archived) {
                                return 1
                              }
                              if (!formula_types[a].archived && formula_types[b].archived) {
                                return -1
                              }
                              return 0
                            })
                            .reduce((obj, key) => {
                              obj[key] = formula_types[key];
                              return obj;
                            }, {})
                "
                :key="type_identifier"
                :value-key="type_identifier"
                :label="formula_type.name"
                :value="type_identifier"
                :disabled="formula_type.archived"
                :style="[formula_type.archived ? {'text-decoration': 'line-through', 'text-decoration-color': 'tomato'} : {}]"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
            <div class="input-label top">Dosage Form</div>
            <div v-if="formula.locked">
              <BaseDosageForm v-model="formula.dosage_form" :readonly=" !formula.locked"/>
            </div>
            <div v-else>
              <el-select
                v-model="formula.dosage_form"
                filterable
                placeholder="Form"
                style="text-transform: capitalize;"
              >
                <el-option
                  style="text-transform: capitalize;"
                  v-for="item in this.$store.getters['terminology/terminology']['dosage_form']"
                  :key="item.code"
                  :label="item.display || item.description"
                  :value="item.code"
                ></el-option>
              </el-select>
            </div>
          </el-col>

          <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
            <div class="input-label top">Route of Administration</div>
            <div v-if="formula.locked">
              <BaseRouteOfAdministration
                :route="formula.route_of_administration"
              />
            </div>
            <div v-else>
              <el-select
                v-model="formula.route_of_administration"
                filterable
                placeholder="Route"
                style="text-transform: capitalize;"
              >
                <el-option
                  style="text-transform: capitalize;"
                  v-for="item in this.$store.getters['terminology/terminology']['route_of_administration']"
                  :key="item.cid"
                  :label="item.display || item.description || item.display_text"
                  :value="item.cid"
                ></el-option>
              </el-select>
            </div>
          </el-col>

          <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
            <div style="padding: 0.75em 0.75em 0.25em; line-height: 2.75em;">
              <el-checkbox
                v-model="formula.options.sterile_indicator"
                :disabled="formula.locked"
              >Sterile</el-checkbox>
            </div>
          </el-col>

          <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
            <div class="input-label top">Therapy Class</div>
            <div v-if="formula.locked">
              <span v-if="formula.compound_class">{{ compound_class_description() }}</span>
            </div>
            <div v-else>
              <el-select
                v-model="formula.compound_class"
                filterable
                placeholder="Class"
                style="text-transform: capitalize;"
              >
                <el-option
                  style="text-transform: capitalize;"
                  v-for="item in this.$store.getters['terminology/compound_class_as_array']"
                  :key="item.code"
                  :label="item.description"
                  :value="item.code"
                ></el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :xs="12" :sm="10" :md="7" :lg="5" :xl="2">
            <div class="input-label top">Level of Complexity
              <el-popover
                placement="right"
                width="850"
                trigger="click">
                <el-table :data="this.$store.getters['terminology/compound_level_of_complexity_as_array']" cell-class-name="break-word">
                  <el-table-column class="helper-instructions" width="270" property="label" label="Level"></el-table-column>
                  <el-table-column width="80" property="value" label="Code"></el-table-column>
                  <el-table-column class="helper-instructions" all-width="400" property="description" label="Desctiption"></el-table-column>
                </el-table>
                <el-button type="text" slot="reference" style="padding-top: 0; padding-bottom: 0;"><a name="compound_level_of_complexity" ><i class="fal fa-question-circle"></i></a></el-button>
              </el-popover>


            </div>
            <div v-if="formula.locked">
              <span v-if="formula.compound_level_of_complexity">{{ compound_level_of_complexity_description() }}</span>
            </div>
            <div v-else>
              <el-select
                v-model="formula.compound_level_of_complexity"
                filterable
                placeholder="Level"
                style="text-transform: capitalize; display: block;"
              >
                <el-option
                  style="text-transform: capitalize;"
                  v-for="item in this.$store.getters['terminology/compound_level_of_complexity_as_array']"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>

          </el-col>

          <el-col :xs="8" :sm="6" :md="4" :lg="3" :xl="2">
            <div class="input-label top">Task Points</div>
            <el-input placeholder="Task Points" v-model="formula.task_points" clearable
              type="number"
              inputmode="decimal">
            </el-input>
          </el-col>
          <el-col :xs="8" :sm="6" :md="4" :lg="3" :xl="2">
            <div class="input-label top">Required Time</div>
            <el-input placeholder="Required Time" v-model="formula.task_time_required" clearable></el-input>
          </el-col>
          <el-col :xs="8" :sm="8" :md="4" :lg="3" :xl="2">
            <div class="input-label top">Location</div>
            <el-input placeholder="Location" v-model="formula.location" clearable></el-input>
          </el-col>


          <el-col :xs="16" :sm="8" :md="5" :lg="3" :xl="2">
            <div class="input-label top">Stability Span</div>
            <el-input
              placeholder="Stability Span [Beyond Use]"
              class="long-placeholder"
              v-model.number="formula.options.stability_span"
              clearable
            ></el-input>
          </el-col>

          <el-col
            :xs="16"
            :sm="8"
            :md="4"
            :lg="3"
            :xl="2"
            v-if="compare_array( formula.dosage_form, ['C25158'])"
          >
            <div class="input-label top">Capsule Weight</div>
            <el-input
              placeholder="Capsule Weight"
              v-model="formula.options.capsule_weight"
              clearable
              type="number"
              inputmode="decimal"
            ></el-input>
          </el-col>
          <el-col
            :xs="16"
            :sm="8"
            :md="4"
            :lg="3"
            :xl="2"
            v-if="compare_array( formula.dosage_form, ['C42751', 'C42893', 'C42897', 'C42905', 'C42910', 'C42927', 'C42930', 'C42931', 'C42992', 'C42998', 'C43243'])"
          >
            <div class="input-label top">Tablet Weight</div>
            <el-input
              placeholder="Capsule Weight"
              v-model="formula.options.capsule_weight"
              clearable
              type="number"
              inputmode="decimal"
            ></el-input>
          </el-col>

          <el-col
            :xs="16"
            :sm="8"
            :md="5"
            :lg="3"
            :xl="1"
            v-if="compare_array( formula.dosage_form, ['C69068', 'C68996', 'C29012', 'C29269', 'C42893', 'C42894', 'C42912', 'C42941', 'C42953', 'C42955', 'C42996', 'C64880', 'C43001', 'C43002'])"
          >
            <div class="input-label top">Flavor</div>
            <el-input placeholder="Flavor" v-model="formula.options.flavor" clearable></el-input>
          </el-col>

          <el-col
            :xs="16"
            :sm="8"
            :md="5"
            :lg="3"
            :xl="1"
          >
            <div class="input-label top">Inventory Re-order Point</div>
            <el-input placeholder="Below X Units" v-model.number="formula.inventory_reorder_point" clearable
              type="number"
              inputmode="decimal">
            </el-input>
          </el-col>

          <el-col
            :xs="16"
            :sm="8"
            :md="5"
            :lg="3"
            :xl="1"
          >
            <div class="input-label top">Minimum Batch Size</div>
            <el-input placeholder="e.g. 300" v-model.number="formula.minimum_batch_quantity" clearable class="hide-controls"
              type="number"
              inputmode="decimal">
            </el-input>
          </el-col>
          <el-col
            :xs="16"
            :sm="8"
            :md="5"
            :lg="3"
            :xl="1"
            v-if="pmk_config.modules.electriclab.activity_factor_pack_stats &&
              !formula.has_pack_stat &&
              compare_array(formula.dosage_form, $store.getters['formula/get_pack_stat_dosage_forms'])"
          >
            <div style="padding: 0.75em 0.75em 0.25em; line-height: 2.75em;">
              <el-checkbox v-model="formula.pack_stat_required" :disabled="formula.locked">
                Pack Stat
                <br>Required
              </el-checkbox>
            </div>
          </el-col>
          <el-col
            :xs="16"
            :sm="8"
            :md="5"
            :lg="3"
            :xl="1"
            v-if="pmk_config.modules.electriclab.activity_factor_pack_stats &&
              formula.options.is_lab_stock &&
              local_vars.unit_of_measure === 'C48155'"
          >
            <div style="padding: 0.75em 0.75em 0.25em; line-height: 2.75em;">
              <el-checkbox v-model="formula.has_pack_stat" :disabled="formula.locked" @change="formula.pack_stat_required = false">
                Has <br>Pack Stat
              </el-checkbox>
            </div>
          </el-col>


          <el-col :xs="16" :sm="8" :md="5" :lg="3" :xl="2"
            v-if="formula.pack_stat_required">
            <div class="input-label top">Capsule/Scoop Size</div>
            <el-select
              v-model="formula.pack_stat_size"
              placeholder="Select Size"
              :disabled="formula.locked">
              <div v-if="compare_array( formula.dosage_form, ['C25158'])">
                <el-option
                  v-for="form in $store.getters['formula/get_pack_stat_dosage_types'].capsules"
                  :key="form"
                  :label="form"
                  :value="form">
                </el-option>
              </div>
              <div v-if="compare_array( formula.dosage_form, $store.getters['formula/get_pack_stat_dosage_forms'])">
                <el-option
                  v-for="form in $store.getters['formula/get_pack_stat_dosage_types'].scoops"
                  :key="form"
                  :label="form"
                  :value="form">
                </el-option>
              </div>

            </el-select>
          </el-col>

          <el-col :xs="16" :sm="12" :md="10" :lg="8" :xl="4">
            <div v-if="formula.formula_status == 'active' || formula.formula_status == 'retired'">
              <div>
                Status:
                <el-button
                  size="mini"
                  class="status-button"
                  :type="formula.formula_status == 'active' ? 'success' : 'default'"
                >{{formula.formula_status}}</el-button>&nbsp;
                <el-button
                  type="danger"
                  size="mini"
                  class="status-button"
                  style="margin-left: 3em; padding: 5px"
                  v-if="formula.formula_status == 'active'"
                  @click="save_formula({formula_status: 'retired'})"
                  plain
                >
                  <i class="fal fa-delete"></i> Deactivate
                </el-button>
              </div>

              <div v-if="formula.date_verified">Verified: {{formula.date_verified | moment('YYYY-MM-DD')}} by {{formula.verified_by}}</div>
              <div>Last Modified: {{formula.date_modified | moment('YYYY-MM-DD')}}  by {{formula.modified_by}}</div>
            </div>
          </el-col>
        </el-row>

        <el-row style="margin-top: 15px;" :gutter="20">
          <span
            class="auxiallary_button"
            v-for="(label, label_index) in formula.options.auxiliary_labels"
            :key="label.id"
            :xs="16"
            :sm="8"
            :md="6"
            :lg="4"
            :xl="1"
          >
            <el-button size="small" :type="label.type" :round="true">{{label.value}}</el-button>
            <!-- This is OK...
              <el-button type="danger" size="mini" @click="remove_auxiliary_label(label_index)" circle
               style="   font-size: 0.8em;
                                padding: 2px;
                                vertical-align: middle;
                                line-height: 0.5em;
                                padding-bottom: 3px;">
                                <span>x</span>
              </el-button>
            -->
            &nbsp;
            <i
              class="fal fa-minus-circle clickable task-remove"
              @click.once="remove_auxiliary_label(label.value)"
            ></i>
          </span>
        </el-row>
        <el-row>
          <div style="margin-top: 15px;">
            <el-col :span="12">
              <div class="sub-title">Auxiliary Labels</div>
              <el-autocomplete
                style="width: 50em;"
                :trigger-on-focus="true"
                placeholder="Add auxiliary Label"
                :fetch-suggestions="auxiliarySearch"
                v-model="form.new_auxiliary_label"
                value-key="label"
                autocapitalize="none"
                >
                <el-select
                  v-model="form.new_auxiliary_label_type"
                  slot="prepend"
                  placeholder="Type"
                >
                  <el-option label="Yellow" value="warning"></el-option>
                  <el-option label="Red" value="danger"></el-option>
                  <el-option label="Default" value="default"></el-option>
                </el-select>
                <el-button
                  @click="record_auxiliary_label(
                    { value: form.new_auxiliary_label,
                      type: form.new_auxiliary_label_type,
                      id: form.new_auxiliary_label
                    }
                  )"
                  slot="append"
                  icon="fal fa-plus-circle"
                ></el-button>
              </el-autocomplete>
            </el-col>
          </div>
        </el-row>
      </div>

      <!-- ingredients component-->
      <ings :ingredients="formula.ingredients" :form="form" :terminology="terminology"
        :locked="formula.locked"
        :formula_quantity="formula.formula_quantity"
        :unit_of_measure="formula.unit_of_measure"
        :needs_activity_factor_modifying_ingredient="needs_activity_factor_modifying_ingredient"
        :needs_excipient_ingredient="needs_excipient_ingredient && formula.pack_stat_required"
        @updateIngredients="updateIngredients"
        @updateFormulaQty="updateFormulaQty"
        @updateFormulaUnit="updateFormulaUnit"
       ></ings>
    <!-- instructions component -->
    <instr :instructions="formula.instructions" @updateInstructions="updateFormulaInstructions"></instr>

     <!-- tasks compnent -->
     <taskspart :tasks="formula.tasks" :form="form" @updateTasks="updateTasks"></taskspart>
      <!-- details here  -->
      <el-row :gutter="20">
        <div style="margin-top: 20px;">
          <h4>
            <i style="margin-right: .5em" class="fal fa-angle-right"></i>
            <span>Details</span>
          </h4>

          <el-col :span="8">
           <detailspart :details="formula.details" :form="form" @updateDetails="updateDetails"></detailspart>
            <!--
            <el-table :data="formula_details_table" style="width: 100%">
              <el-table-column prop="property" label="Property" width="180"></el-table-column>
              <el-table-column prop="value" label="Name" width="380">
                <template slot-scope="scope">
                  <el-input
                    type="textarea"
                    autosize
                    :placeholder="scope.row.title"
                    v-model="scope.row.value"
                  ></el-input>
                </template>
              </el-table-column>
            </el-table>
            -->
          </el-col>
          <el-col :span="1">&nbsp;</el-col>
          <el-col :span="15">
            <el-row>
              <el-col :span="24">
                <el-collapse v-model="activeDetailSections">
                  <el-collapse-item
                    title="Formula Type Instructions"
                    name="formula_type_instructions"
                    v-if="formula.formula_type"
                  >
                    <div
                      v-if="formula_types[formula.formula_type]"
                      style="white-space: pre-wrap;"
                    >{{formula_types[formula.formula_type].instructions}}</div>
                  </el-collapse-item>
                  <el-collapse-item
                    title="Formula Type Tasks"
                    name="formula_type_tasks"
                    v-if="formula.formula_type"
                  >
                    <div v-if="formula_types[formula.formula_type]">
                      <div class="formula-tasks">
                        <h4>
                          <i style="margin-right: .5em" class="fal fa-tasks"></i>
                          <span>Tasks</span>
                        </h4>
                        <ol>
                          <template
                            v-for="(task, index) in formula_types[formula.formula_type].tasks"
                          >
                            <li :key="'r'+index" style="margin-top: 15px;">
                              <el-input
                                class="sub_task-instructions"
                                placeholder="Task Instructions"
                                v-model="task.description"
                                readonly
                              ></el-input>
                              <el-input
                                class="sub_task-points"
                                placeholder="5"
                                v-model="task.points"
                                readonly
                                disabled
                                type="number"
                                inputmode="decimal"
                              >
                                <template slot="prepend">Points</template>
                              </el-input>
                              <div class="formula-task-options">
                                <span class="formula-task-options-label" v-if="task.record_weights">
                                  <i class="fal fa-balance-scale-right"></i> Record Weight[s] &nbsp;
                                </span>
                                <!-- <el-switch readonly disabled v-model="task.record_weights" active-text inactive-text></el-switch>&nbsp; | &nbsp; -->
                                <span
                                  class="formula-task-options-label"
                                  v-if="task.record_manual_measurement"
                                >
                                  <i class="fal fa-keyboard"></i> Record Other Measurements &nbsp;
                                </span>
                                <!-- <el-switch readonly disabled v-model="task.record_manual_measurement" active-text inactive-text></el-switch>&nbsp; | &nbsp; -->
                                <span class="formula-task-options-label" v-if="task.record_photo">
                                  <i class="fas fa-camera"></i> Include Photo &nbsp;
                                </span>
                                <!-- <el-switch readonly disabled v-model="task.record_photo" active-text inactive-text></el-switch> -->
                              </div>
                            </li>
                          </template>
                        </ol>
                      </div>
                    </div>
                  </el-collapse-item>
                </el-collapse>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col :span="24">
                <documentsList :documents_s="documents_s" @deleteDoc="deleteDoc" />
                <BaseDocumentUpload
                  :documents.sync="form.documents"
                  :value="formula.documents"
                  :categories="categories"
                  @update_documents="updateDocuments"
                ></BaseDocumentUpload>
                <takePhoto @update_documents="updateDocuments" />
              </el-col>
            </el-row>
          </el-col>
        </div>
      </el-row>

      <!-- history component  -->
      <historypart :history="formula.change_log"></historypart>
      <!-- inventoryhistory component -->
      <inventoryhistorypart :identifier="formula.identifier"></inventoryhistorypart>
      <!-- current tasks -->
      <outstandingTasks :task_type="['lotCreate', 'lot_check']" :filters="{formula_identifier: formula.identifier }"></outstandingTasks>

      <anticipatoryCompoundReport :ndc="formula.identifiers.ndc || 1" />

      <el-row :gutter="20">
        <div style="margin-top: 15px;">
          <!-- <div class="bottom clearfix">
            <el-button type="primary">
              <i class="fal fa-save"></i> Save
            </el-button>
          </div>-->
        </div>
      </el-row>
    </el-card>

    <el-dialog title="Rework Notes" :visible.sync="dialogReworkVisible">
      <el-form :model="form">
        <el-form-item label="Reason">
          <el-input type="textarea" v-model="form.rework_reason" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogReworkVisible = false">Cancel</el-button>
        <el-button
          type="primary"
          @click="save_formula({formula_status: 'draft', workflow: { task_note: form.rework_reason || 'Needs changes', task_type: 'formula_rework', rework_reason: form.rework_reason } }); dialogReworkVisible = false"
        >Confirm</el-button>
      </span>
    </el-dialog>

    <el-dialog title="Change Notes" :visible.sync="dialogDescribeChangesVisible">
      <el-form :model="form">
        <el-form-item label="Reason">
          <el-input type="textarea" v-model="form.reason_for_change" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogDescribeChangesVisible = false">Cancel</el-button>
        <el-button
          type="primary"
          @click="save_formula({formula_status: 'proposed',
                          workflow: {
                             task_note: form.reason_for_change,
                             task_type: 'formula_verify',
                             rework_reason: formula_task ? formula_task.task_note : ''
                          },
                          change_log_entry: { reason_for_change: form.reason_for_change, date_modified: new Date().toJSON(), modified_by: ''} }); dialogDescribeChangesVisible = false"
        >Confirm</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
const uuidv4 = require("uuid/v4");
import NProgress from "nprogress";
const moment = require("vue-moment");
import TaskService from "@/services/task.js";
import EventService from "@/services/EventService.js";
import url_for from "@/services/pmk_url_for";
import Common from "@/common.js";
import ings from "./parts/ingredients.vue"
import instr from './parts/instructions.vue'
import taskspart from "./parts/tasks.vue"
import detailspart from './parts/details.vue'
import historypart from './parts/history.vue'
import inventoryhistorypart from './parts/inventoryHistory'
import subsequentTasks from '@/components/formula/subsequentTasks'
import outstandingTasks from '@/components/task/outstandingTasks'
import * as validators from '@/utils/validators.js'
import takePhoto from '@/components/TakePhoto.vue'
import documentsList from '@/components/DocumentList.vue'
import anticipatoryCompoundReport from '@/components/formula/anticipatory_compound_report.vue'
import ingredientAvailablity from '@/components/ingredient/ingredient_availability.vue'
import _ from 'lodash'
const datetime_picker_options = {
  default: {
    // double check the set times for each short cut
    shortcuts: [
      {
        text: "2 hours",
        onClick(picker) {
          const end = new Date();
          const start = new Date();
          end.setTime(start.getTime() + 3600 * 1000 * 2);
          picker.$emit("pick", [start, end]);
        }
      },
      {
        text: "4 hours",
        onClick(picker) {
          const end = new Date();
          const start = new Date();
          end.setTime(end.getTime() + 3600 * 1000 * 4);
          picker.$emit("pick", [start, end]);
        }
      },
      {
        text: "24 hours",
        onClick(picker) {
          const end = new Date();
          const start = new Date();
          end.setTime(end.getTime() + 3600 * 1000 * 24);
          picker.$emit("pick", [start, end]);
        }
      },
      {
        text: "3 days",
        onClick(picker) {
          const end = new Date();
          const start = new Date();
          end.setTime(end.getTime() + 3600 * 1000 * 24 * 3);
          picker.$emit("pick", [start, end]);
        }
      },
      {
        text: "Next week",
        onClick(picker) {
          const end = new Date();
          const start = new Date();
          end.setTime(end.getTime() + 3600 * 1000 * 24 * 7);
          picker.$emit("pick", [start, end]);
        }
      }
    ]
  }
};

export default {
  props: {
    task_id: {
      type: [Number, String],
      required: false
    }
  },
  components: {
    ings,
    instr,
    taskspart,
    detailspart,
    historypart,
    subsequentTasks,
    inventoryhistorypart,
    outstandingTasks,
    takePhoto,
    documentsList,
    anticipatoryCompoundReport,
    ingredientAvailablity,
  },
  beforeRouteUpdate (to, from, next) {
    let self = this
    let formulaFromStore = JSON.parse(JSON.stringify(this.$store.getters["formula/get_formula"]))
    // if the formula in the store is the same requested we fetch that
    if(!to.params.formula_identifier || formulaFromStore.identifier == to.params.formula_identifier){
      this.formula = formulaFromStore;
    }else{
      // otherwise we get the formula from server( in case we are going back from copy to original )
      this.$store
          .dispatch("formula/fetchFormula", to.params.formula_identifier)
          .then(data => {
            self.formula = self.formulaLocal;
            next();
          });
    }

    next()
  },
  data() {
    return {
      lotReqRules: {
        quantity : [
          { trigger:'blur', validator:validators.validateNumbers}
        ],
        date: [
          { validator: validators.validateDatesArray, trigger: 'blur'}
        ]
      },

      zeb: null,
      lot_request_dialogFormVisible: false,
      lot_request_post_approval_dialogFormVisible: false,
      lot_request: { merge: false },
      formula_instructions_form: '',
      activeDetailSections: [],

      options: {
        // formula_types: [
        //   "capsule",
        //   "capsule-oil",
        //   "tablet",
        //   "cream",
        //   "liquid",
        //   "injectable",
        //   "pellet",
        //   "troche",
        //   "drops",
        //   "lozenge",
        //   "suspension",
        //   "other"
        // ],
        dosage_forms: [
          "capsule",
          "capsule-oil",
          "tablet",
          "cream",
          "liquid",
          "injectable",
          "pellet",
          "troche",
          "drops",
          "lozenge",
          "suspension",
          "other"
        ],
        units: [
          "mg",
          "gm",
          "ml",
          "mcg",
          "qs",
          "tab",
          "cap",
          "cc",
          "each",
          "unit",
          "vial",
          "bottle",
          "lozenge",
          "troche",
          "tube",
          "drop",
          "suppository",
          "IU"
        ]
      },
      categories: this.$store.getters["formula/get_document_categories"],
      form: this.createFreshFormObject(),
      dialogReworkVisible: false,
      dialogDescribeChangesVisible: false,
      documents_s: {},
      formula : null,
      local_vars: {},
      has_activity_factor_ingredient: false,
      has_pack_stat_ingredient: false,
      has_qs_ingredient: false,
      needs_activity_factor_modifying_ingredient: false,
      needs_excipient_ingredient: false,
      //formula: this.createFreshFormulaObject()
    };
  },
  computed: {
    pmk_config: {
      get() {
        return this.$store.getters["app/pmk_config"];
      }
    },
    lab_config: {
      get() {
        return this.$store.getters['formula/get_lab_config']
      }
    },
    formulaLocal: {
      get() {
        if (this.$store.getters["formula/get_formula"]) {
          return this.$store.getters["formula/get_formula"]
        } else return {};
      },
      set (value) {
        this.$store.commit('SET_WORKING_FORMULA', value)
      }
    },
    user: {
      get() {
        return this.$store.getters["account/profile"];
      }
    },
    terminology: {
      get() {
        return this.$store.getters["terminology/terminology"];
      }
    },

    formula_types: {
      get() {
        if (!this.$store.getters["formula/get_lab_config"]) {
          this.$store.dispatch("formula/fetchLabConfig");
        }
        if (this.$store.getters["formula/get_lab_config"]) {
          return this.$store.getters["formula/get_lab_config"]["config"][
            "formula_types"
          ];
        } else return {};
      }
    },

    formula_task: {
      get() {
        return this.$store.getters["task/queryTasks"];
      }
    },

    datetime_picker_options: {
      get() {
        let picker =
          datetime_picker_options.default ||
          this.$store.getters["task/datetime_picker_options"];
        return picker;
      }
    }
  },
  asyncComputed: {
    async queryTasksDirect() {
          console.log('queryTasksDirect')
          return this.$store.getters["task/queryTasksDirect"]([], { formula_identifier: this.formula.identifier })
           let tmp_list =  await this.$store.getters["task/queryTasksDirect"]([], {formula_identifier:this.formula.identifier})
           console.log({tmp_list})
           return tmp_list
    }
  },
  methods: {
    createCopy(){
      let newId = uuidv4();
      this.$store
          .dispatch("formula/copyFormula", newId).then((resp) => {
            console.log(resp)
            this.$router.push({
              name: "formula-manage",
              params: { formula_identifier: resp.identifier }
            });
          })
    },
    print(){
      let self = this;
      this.$router.push({
              name: "formula-worksheet",
              params: { formula_identifier: self.formula.identifier }
            });
    },
    updateFormulaInstructions(e){
       this.local_vars.instructions = e
    },
    updateIngredients(e){
       this.local_vars.ingredients = e

       this.has_qs_ingredient = false
       this.has_activity_factor_ingredient = false
       this.has_pack_stat_ingredient = false
       this.needs_activity_factor_modifying_ingredient = false
       this.needs_excipient_ingredient = false

       this.local_vars.ingredients.forEach(ingredient => {
         if (ingredient.has_activity_factor) {
           this.has_activity_factor_ingredient = true
         }
         if (ingredient.quantity_sufficient_indicator) {
           this.has_qs_ingredient = true
         }
         if (ingredient.has_pack_stat) {
           this.has_pack_stat_ingredient = true
         }
       })
       if (this.has_activity_factor_ingredient && !this.has_qs_ingredient) {
         this.needs_activity_factor_modifying_ingredient = true
       }
       if (this.has_pack_stat_ingredient) {
         this.needs_excipient_ingredient = true
       }

    },
    updateTasks(e){
     this.local_vars.tasks = e
    },
    updateDetails(e){
      this.local_vars.details = e
    },
    updateFormulaQty(e){
      this.local_vars.formula_quantity = e
    },
    updateFormulaUnit(e){
      this.local_vars.unit_of_measure = e
      this.$forceUpdate()
    },
    // Documents
    deleteDoc(item, category) {
      this.documents_s.forEach(doc_category => {
        if (doc_category.category === category) {
          let index = doc_category.documents.indexOf(item)
          if (index >= 0) {
            doc_category.documents.splice(index, 1)
          }
        }
      })
    },
    updateDocuments(_document) {
      let to_append = {};
      to_append.name = _document.name;
      to_append.note = _document.note;
      to_append.type = _document.type;
      to_append.identifier = _document.identifier;
      let to_append_list = [];
      to_append_list.push(to_append);
      let this_document_category_list = {};
      let new_documents_list;
      this_document_category_list["category"] = _document.category;
      this_document_category_list["documents"] = to_append_list;
      if (this.documents_s.length == 0) {
        new_documents_list = this_document_category_list;
        this.documents_s.push(this_document_category_list);
      } else {
        let sear = this.arrayContainsCategory(this.documents_s, _document.category);
        if (sear.contains) {
          this.documents_s[sear.index]["documents"].push(to_append);
        } else {
          let to_add_in_list= {};
          to_add_in_list["category"] = _document.category;
          to_add_in_list["documents"] = to_append_list;
          this.documents_s.push(to_add_in_list);
        }
      }
    },
    arrayContainsCategory(tabb, categ) {
      let res = false;
      let i = 0;
      let pos = i;
      tabb.forEach(function(params) {
        if (params.category == categ) {
          res = true;
          pos = i;
        }
        i++;
      });
      return { contains: res, index: pos };
    },
    get_document_url(identifier, args={}) {
      return Common.get_document_url(identifier, args);
    },
    // END Documents

    compound_class_description() {
      let classes = this.$store.getters["terminology/compound_class"];
      if (this.formula.compound_class && classes[this.formula.compound_class]) {
        return classes[this.formula.compound_class].description;
      }
    },
    compound_level_of_complexity_description() {
      let classes = this.$store.getters["terminology/compound_level_of_complexity"];
      if (this.formula.compound_level_of_complexity && classes[this.formula.compound_level_of_complexity]) {
        return classes[this.formula.compound_level_of_complexity].label;  // description is long, as instructions
      }
    },

    go_to_task(task_id) {
      console.log("goto", task_id);
      this.$router.push({ name: "task-view", params: { id: task_id } });
      console.log("goto-end", task_id);
    },
    record_auxiliary_label(label) {
      // console.log(this.formula.options);
      this.formula.options.auxiliary_labels.push(label);
      this.form.new_auxiliary_label = "";
      this.form.new_auxiliary_label_type = "default";
    },
    remove_auxiliary_label(label_value) {
      let label_index = _.findIndex(this.formula.options.auxiliary_labels, ['value', label_value])
      console.log('label_index', label_index);
      this.formula.options.auxiliary_labels.splice(label_index, 1);
    },
    auxiliarySearch(queryString, cb) {
      let labels = this.$store.getters["formula/get_lab_config"]["formula"][
        "auxiliary_labels"
      ];
      console.log({ labels });

      let results = queryString
        ? Object.values(labels)
            .map(function(item) {
              return {
                label_text: item.data.label_text,
                label: item.data.label_text,
                value: item.data.identifier
              };
            })
            .filter(function(label) {
                return (
                  label.label_text
                    .toLowerCase()
                    .indexOf(queryString.toLowerCase()) === 0
                );
            })
        : Object.values(labels).map(function(item) {
            return { label: item.data.label_text, value: item.data.identifier };
          });
      // call callback function to return suggestions
      console.log(results);
      cb(results);
    },
    compare_array(item, array) {
      let compare_function = function(array_item) {
        return item == array_item;
      };

      let is_in_array = array.some(compare_function);
      return is_in_array;
    },
    calculate_total() {
      let total_quantity = 0;
      this.formula.ingredients.forEach(function(ingredient) {
        let unit_of_measure = (
          ingredient.formula_unit_of_measure ||
          ingredient.unit_of_measure ||
          ""
        ).toUpperCase();
        if (["C28252", "C28253", "C48155"].includes(unit_of_measure)) {
          total_quantity += parseFloat(ingredient.quantity) || 0;
        }
      });
      return total_quantity;
    },
    createFreshFormObject() {
      const user = this.$store.getters["account/profile"];

      return {
        rework_data: { reason: "" },
        rework_reason: "",
        reason_for_change: "",
        workflow: {},
        identifiers: { formula_id: "" },
        category: "",
        author: user.name,
        verified_by: "",
        date_verified: "",
        formula_status: "draft",
        name: "",
        label_description:"",
        description: "",
        location: "",
        date_created: "",
        documents: {},
        change_log: [],
        options: {
          auxiliary_labels: [],
          is_lab_stock: false,
          stability_span: ""
        },
        instructions: "",
        tasks: [],
        hazard_level: 0,
        ingredients: [],
        minimum_batch_quantity: "",
        inventory_reorder_point: "",
        new_ingredient: {
          description: "",
          id: "",
          quantity: "",
          note: "",
          identifiers: [
            {
              code: "",
              system: ""
            }
          ]
        }
      };
    },
    formula_details_table() {
      this.formula.details;
    },
    add_ingredient(selected_ingredient) {
      // this.form.new_ingredient = selected_ingredient
      this.formula.ingredients.push(selected_ingredient);
      //selected_ingredient = {}
      this.form.new_ingredient.description = "";
      return;
    },
    remove_ingredient(ingredient_index, ingredient) {
      console.log(ingredient);
      this.formula.ingredients.splice(ingredient_index, 1);
    },
    createFilter(queryString) {
      return result => {
        return (
          result.description
            .toLowerCase()
            .indexOf(queryString.toLowerCase()) === 0
        );
      };
    },
    /* tasks */
    remove_formula_task(task_index, task) {
      // TODO: this mutates the store and might not be reactive
      this.formula.tasks.splice(task_index, 1);
    },
    attachComponentsData(){
      if(this.local_vars.details !== null && this.local_vars.details !== undefined){
        this.formula.details = this.local_vars.details
      }
      if(this.local_vars.instructions !== null && this.local_vars.instructions !== undefined){
        this.formula.instructions = this.local_vars.instructions
      }
      if(this.local_vars.ingredients){
        this.formula.ingredients = this.local_vars.ingredients
      }
      if(this.local_vars.unit_of_measure){
        this.formula.unit_of_measure = this.local_vars.unit_of_measure
      }
      if(this.local_vars.tasks){
        this.formula.tasks = this.local_vars.tasks
      }
      if(this.local_vars.formula_quantity){
        this.formula.formula_quantity = this.local_vars.formula_quantity
      }
    },
    save_formula(args) {
      let user = this.$store.getters["account/profile"];
      this.attachComponentsData()
      let formula_mutable = { ...this.formula };
      formula_mutable.formula_status = args.formula_status;

      let ingredients_without_units = formula_mutable.ingredients.filter(ing => {
        return !ing.formula_unit_of_measure
      })
      if (ingredients_without_units.length) {
        this.$message({
          message: 'Not all ingredients have units set',
          type: 'error',
          duration: 6000
        })
        return
      }

      let ingredients_without_qty = formula_mutable.ingredients.filter(ing => {
        return parseFloat(ing.quantity) !== ing.quantity
      })
      if (ingredients_without_qty.length) {
        this.$message({
          message: 'Not all ingredients have quantities set',
          type: 'error',
          duration: 6000
        })
        return
      }

      let activity_factor_modifying_ingredient = ''
      let pack_stat_excipient_ingredient = ''

      if (this.needs_activity_factor_modifying_ingredient) {
        formula_mutable.ingredients.forEach(ingredient => {
          if (ingredient.activity_factor_modifying_ingredient) {
            activity_factor_modifying_ingredient = ingredient.identifier
          }
        })

        formula_mutable.activity_factor_modifying_ingredient = activity_factor_modifying_ingredient
        console.log('activity_factor_modifying_ingredient', activity_factor_modifying_ingredient)
      }

      if (this.needs_excipient_ingredient) {
        formula_mutable.ingredients.forEach(ingredient => {
          if (ingredient.pack_stat_excipient_ingredient) {
            pack_stat_excipient_ingredient = ingredient.identifier
          }
        })

        formula_mutable.pack_stat_excipient_ingredient = pack_stat_excipient_ingredient
        console.log('pack_stat_excipient_ingredient', pack_stat_excipient_ingredient)
      }

      // save formula
      // saving formula should know what to do with it, i.e. put it in a task queue for edit or review, etc
      if (args.workflow) {
        formula_mutable.workflow = args.workflow;
      } else {
        delete formula_mutable.workflow;
      }
      if (args.task) {
        // this is now taken care of by backend
        // console.log('args.task', args.task)
        if (args.task.task_id && args.task.status == "complete") {
          this.complete_task(args.task.task_id, args.task);
        }
      }
      if (args.formula_status == "proposed") {
        if (args.change_log_entry) {
          args.change_log_entry.modified_by = formula_mutable.modified_by =
            user.name;
          args.change_log_entry.date_modified = formula_mutable.date_modified = new Date().toJSON();
          // set change_log entry:
          if ("undefined" != typeof formula_mutable.change_log) {
            formula_mutable.change_log.push(args.change_log_entry);
          } else {
            formula_mutable = {
              ...formula_mutable,
              ...{ change_log: [args.change_log_entry] }
            };
          }
        }
      }

      if (args.formula_status == "active") {
        formula_mutable.verified_by = user.name;
        formula_mutable.date_verified = new Date().toJSON();
      } // active
      // setting documents
      formula_mutable.documents = this.documents_s;
      this.record_entry(formula_mutable);
    },
    record_entry(formula_mutable) {
      // hack to be compatible as an ingredient:
      // if (!formula_mutable.code) {
      //
      // formula_mutable.code = formula_mutable.identifiers.formula_id;
      // }
      formula_mutable.code = formula_mutable.identifiers.formula_id;
      formula_mutable.description = formula_mutable.name

      let tmp_subsequent_tasks = [...(formula_mutable.subsequent_tasks || [])]

      NProgress.start();
      this.$store
        .dispatch("formula/saveFormula", formula_mutable)
        .then(response => {
          console.log({ response });
          console.log({
            success: response.success,
            status: response.data.data.formula_status,
            ttype: typeof tmp_subsequent_tasks,
            tmp_subsequent_tasks: tmp_subsequent_tasks
          });
          if (
            response.data.success &&
            response.data.data.formula_status == "active"
          ) {
            // deal with subsequent tasks, at this point they have been cleared.  This is done
            // because we do not know if the formula has had enough reviewers yet:
            if (
              "undefined" != typeof tmp_subsequent_tasks &&
              tmp_subsequent_tasks.length
            ) {
              let tasks_to_remove = [];
              tmp_subsequent_tasks.forEach(
                (new_task_object, task_index, tasks) => {
                  console.log({ new_task_object });
                  TaskService.schedule_task(new_task_object)
                    .then(() => NProgress.done())
                    .then(function() {
                      tasks_to_remove.push(task_index);
                      const notification = {
                        type: "success",
                        message: "Task scheduled"
                      };

                      self.$store.dispatch("notification/add", notification, {
                        root: true
                      });
                    });
                }
              ); // each task
              // remove in reverse so the size does not shrink
              tasks_to_remove.reverse().forEach(remove_index => {
                formula_mutable.subsequent_tasks.splice(remove_index, 1);
              });
            } // subsequent tasks
          }

          // this.product = this.createFreshEventObject()
          // this.form = this.createFreshEventObject()

          // EL-29: do we need to set formula_mutable?
          // $store.dispatch("formula/saveFormula", formula_mutable) sets/commits SET_WORKING_FORMULA with data returned by the server.
          // with the recent changes do we need to need to also set formula_mutable and anything else here?
          // BACKGROUND: the formula.formula_status we send to the server might not be the status the server returns back.
          this.formula = this.formulaLocal
          this.formula.saved = true;
        })
        .then(() => NProgress.done())
        .catch(() => {
          NProgress.done();
        });
    },
    search_ingredients(queryString, cb) {
      // here we need to query the ingredient list API for manufacturer identifiers
      let query_object = {
        explicit: 0,
        method: "LIKE",
        description: queryString,
        include_formulas: 1
      };
      NProgress.start();
      this.$store
        .dispatch("ingredient/listIngredients", query_object)
        .then(() => {
          cb(
            this.$store.getters["ingredient/ingredient_search_list"].map(
              item => item.data
            )
          );
        })
        .then(() => NProgress.done())
        .catch(() => {
          NProgress.done();
        });
    },
    complete_task(parameter_task_id, task_data) {
      console.log(parameter_task_id);
      NProgress.start();
      this.$store
        .dispatch("task/complete_task", {
          task_id: parameter_task_id,
          task_data: task_data
        })
        .then(() => NProgress.done())
        .catch(() => {
          NProgress.done();
        });
    },
    toggleReworkVisibility() {
      return;
    },
    create_formula_lot_task(args = {}) {
      // somewhere, display a modal to request the parameters for this task type and save to lot_request
      //      let lot_request = {}
      let self = this;
      let create_as_subsequent_task = args.create_as_subsequent_task;
      this.$refs['lotReqForm'].validate((valid) => {
        if(valid){
          if(!create_as_subsequent_task ){
            self.lot_request_dialogFormVisible = false;
          }else{
            self.lot_request_post_approval_dialogFormVisible = false;
          }


          console.log("this.lot_request", this.lot_request);
          console.log("store.formula", this.$store.getters["formula/get_formula"]);
          console.log("this.formula", this.formula);
          let task_sub_title = `Formula ID: ${this.formula.formula_id ||
            this.formula.identifiers.formula_id} | ${
            this.formula_types[this.formula.formula_type]
              ? (this.formula_types[this.formula.formula_type].name || '')
              : ""
          }`;

          let task_details_indicators = []
          if (this.formula.formula_information) {

            if (this.formula.formula_information.is_hazardous) {

              task_details_indicators.push(
                `<span class="indicator" style="color: Tomato;">
                  <i style="font-size: 1.0rem;" class="fad fa-flask-poison"></i>&nbsp;Hazardous
                </span>`
              )
            }
            if (this.formula.options.sterile_indicator) {

              task_details_indicators.push(
                `<span class="indicator">
                  <i style="font-size: 1.0rem;" class="fad fa-exclamation-triangle"></i>&nbsp;Sterile
                </span>`
              )
            }
          }
          let title_details = `QTY: ${this.lot_request.quantity}` + ( this.lot_request.task_note ?  ` Note: ${this.lot_request.task_note.substr(0, 40)}...` : '')

          if (task_details_indicators.length > 0) {
            title_details = title_details.concat(task_details_indicators.join("\n"))
          }
          let task_data = {
            formula: { identifier: this.formula.identifier },
            lot: { quantity: this.lot_request.quantity },
            title: this.formula.name || this.formula.description,
            sub_title: task_sub_title,
            title_details: title_details,
            task_note: this.lot_request.task_note

          };

          let task_utc_dates = [
            this.$moment(this.lot_request.date[0])
              .utc()
              .format(),
            this.$moment(this.lot_request.date[1])
              .utc()
              .format()
          ];
          let new_task_object = {
            formula_identifier: this.formula.identifier,
            task_type: "lot_create",
            task_status: "new",
            merge: this.lot_request.merge,
            task_data: task_data,
            title: this.formula.name || this.formula.description,
            sub_title: task_sub_title,
            // title_details: `QTY: ${this.lot_request.quantity}` + ( this.lot_request.task_note ?  ` Note: ${this.lot_request.task_note.substr(0, 32)}...` : '') ,
            // task_note: this.lot_request.task_note,

            data_id: this.formula.id,
            order_id: this.lot_request.order_id,
            patient_id: this.lot_request.patient_id,
            prescriber_id: this.lot_request.prescriber_id,
            date_scheduled: task_utc_dates[0],
            date_due: task_utc_dates[1],
            task_priority: this.lot_request.priority
          };
          if (create_as_subsequent_task) {
            self.formula.subsequent_tasks = [
              ...(self.formula.subsequent_tasks || []),
              ...[new_task_object]
            ];
            NProgress.start();
            TaskService.schedule_subsequent_formula_task({
              formula_identifier: self.formula.identifier,
              subsequent_tasks: [new_task_object]
            }) // patch_preserve is used so that other users creating tasks at the same time will not destroy them
              .then(() => NProgress.done())
              .then(function() {
                const notification = {
                  type: "success",
                  message:
                    "Task will be scheduled once formula changes are approved"
                };

                self.$store.dispatch("notification/add", notification, {
                  root: true
                });
                self.lot_request = {}
              })
              .catch(() => {
                NProgress.done();
              });
          } else if(this.lot_request.merge) {
            NProgress.start();
            EventService.request_additional_inventory(new_task_object)
              .then(() => NProgress.done())
              .then(function() {
                const notification = {
                  type: "success",
                  message: "Task scheduled"
                };

                self.$store.dispatch("notification/add", notification, {
                  root: true
                });
                self.lot_request = {}
              })
              .catch(() => {
                NProgress.done();
              });

          } else {
            NProgress.start();
            TaskService.schedule_task(new_task_object)
              .then(() => NProgress.done())
              .then(function() {
                const notification = {
                  type: "success",
                  message: "Task scheduled"
                };

                self.$store.dispatch("notification/add", notification, {
                  root: true
                });
                self.lot_request = {}
              })
              .catch(() => {
                NProgress.done();
              });
      }

        }
      })


    }
  },
  mounted() {
    let f = JSON.parse(JSON.stringify(this.$store.getters["formula/get_formula"]))
    this.formula = f;
    // this does not yet exist?
    if (this.task_id) {
      this.$store.dispatch("task/setQueryFilters", { task_id: this.task_id });
    }

    this.documents_s = f.documents.length>0?f.documents: [];

    this.formula.ingredients.forEach(ingredient => {
      if (ingredient.has_activity_factor) {
        this.has_activity_factor_ingredient = true
      }
      if (ingredient.quantity_sufficient_indicator) {
        this.has_qs_ingredient = true
      }
      if (ingredient.has_pack_stat) {
        this.has_pack_stat_ingredient = true
      }
    })
    if (this.has_activity_factor_ingredient && !this.has_qs_ingredient) {
      this.needs_activity_factor_modifying_ingredient = true
    }
    if (this.has_pack_stat_ingredient) {
      this.needs_excipient_ingredient = true
    }

    if(this.formula.details){
      this.local_vars.details = this.formula.details
    }
    if(this.formula.instructions){
      this.local_vars.instructions = this.formula.instructions
    }
    if(this.formula.ingredients){
      this.local_vars.ingredients = this.formula.ingredients
    }
    if(this.formula.unit_of_measure){
      this.local_vars.unit_of_measure = this.formula.unit_of_measure
    }
    if(this.formula.tasks){
      this.local_vars.tasks = this.formula.tasks
    }
    if(this.formula.local_vars_quantity){
      this.local_vars.local_vars_quantity = this.formula.local_vars_quantity
    }
  }
};
</script>




<style scoped>
.location {
  margin-bottom: 0;
}
.location > .icon {
  margin-left: 10px;
}
.event-header > .title {
  margin: 0;
}
.list-group {
  margin: 0;
  padding: 0;
  list-style: none;
}
.list-group > .list-item {
  padding: 1em 0;
  border-bottom: solid 1px #e5e5e5;
}

.formula-options .el-col {
  margin-top: 4px;
}

.auxiallary_button {
  margin-right: 1.5em;
}

.formula-instructions {
  margin: 1.5em 0;
}

.button.bottom {
  margin-top: 13px;
  line-height: 12px;
  padding: 0;
  float: right;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}

.status-button {
  margin-right: 1.25em;
}

.formula-status {
  font-size: 0.8em;
  color: #8a8a8a;
  margin: 0 0.5em;
}

.sub_task-instructions {
  display: inline-block;
  min-width: 25em;
  max-width: 80%;
}
.sub_task-points {
  max-width: 8em;
  margin: 0 1em;
}
.ingredient-remove:hover {
  color: rgb(207, 13, 13);
}

.task-remove:hover {
  color: rgb(207, 13, 13);
}

.ingredient-header {
  font-weight: bold;
  font-size: 1em;
}

.formula-task-options {
  margin-top: 0.35em;
}
</style>
<style>
/* should be global: */
.clickable {
  cursor: pointer;
}
.clickable:hover {
  color: #409eff;
}
.clickable.remove:hover {
  color: rgb(207, 13, 13);
}

.long-placeholder ::placeholder {
  font-size: 0.7em;
}
.long-placeholder ::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size: 0.7em;
}
.long-placeholder ::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 0.7em;
}
.long-placeholder :-ms-input-placeholder {
  /* IE 10+ */
  font-size: 0.75em;
}
.long-placeholder :-moz-placeholder {
  /* Firefox 18- */
  font-size: 0.75em;
}
.helper-instructions {
  font-size: 0.8em;
  color: #8a8a8a;
  hyphens: auto;
  overflow-wrap: break-word;
  word-wrap: break-word;
}
.pull-right {
  float: right!important;
  margin-left: 1em!important;
}
.grey-button {
    color: rgb(144, 147, 153) !important;
    background: rgb(244, 244, 245) !important;
    border-color: rgb(211, 212, 214)!important;
}

.grey-button:hover {
    background: #fff !important;
    border-color: #50bfff !important;
    color: #50bfff !important;
}

.auxiallary_button {
    display: inline-block;
    padding-bottom: 0.25em;
}

.indicators {
  font-size: 0.8em;
}

.indicator {
  margin-right: 1em;
}
</style>
