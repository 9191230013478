<template>
  <div class="begin-taskLotCreate">
    <el-backtop target=".main" :right="22"><i class="el-icon-caret-top"></i></el-backtop>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>
          <span v-if="task.task.task_status === 'cancelled'" style="color: tomato;font-size: 1.5em">
            Task cancelled -
          </span>
          <span style="font-size: 1.5em">{{ formula.name }}</span>
          &nbsp;
          <span class="text-secondary">
            [
            <router-link
              :to="{ name: 'formula-manage', params: { formula_identifier: formula.identifier } }"
              target="_blank"
            >{{formula.identifiers.formula_id || `NDC: ${formula.identifiers.ndc}` }}</router-link>]
           <!-- | <span style="font-size: 7px; color: #909399;">{{lot.version}}</span> -->
          </span>

        </span>
        <!--
        <span class="formula-status">
          <el-tag type="warning">{{ lot.status }}</el-tag>
        </span>
        -->

        <div style="display: block;">
          <el-button
            type="default"
            class="status-button"
            style="float: right; padding: 5px"
            @click="open_label()"
            :disabled="form.label_loading || task.task.task_status === 'cancelled' || !lot.version"
          >
            <span v-if="form.label_loading">
              <i class="fas fa-circle-notch fa-spin"></i> Loading...
            </span>
            <span v-else>
              <i class="fal fa-printer"></i> Label
            </span>
          </el-button>
          <el-dropdown
            split-button
            id="unit_dose_label_button"
            style="padding: 0px; margin-right: 0.5em; float: right;"
            @click="unit_dose_label('pdf')"
            v-if="lab_config.formula && lab_config.formula.enable_unit_dose_labels"
          >
            Unit Dose Label (PDF)
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item >
                <el-button type="text" @click="unit_dose_label('png')">
                  PNG
                </el-button>
              </el-dropdown-item>
              <el-dropdown-item>
                <el-button type="text" @click="unit_dose_label('zpl')">
                  ZPL
                </el-button>
              </el-dropdown-item>
              <el-dropdown-item>
                <el-popover
                  placement="right"
                  title="300dpi Quantity"
                  width="175"
                  trigger="hover">
                  <el-button slot="reference" type="text" style="width:100%;text-align:left">
                    Send to Printer (300dpi)
                  </el-button>
                  <el-row>
                    <el-input v-model="label_count" style="width: 50%;margin-right:5px;"
                      type="number"
                      inputmode="decimal"></el-input>
                    <el-button type="primary" @click="unit_dose_label('print300')">
                      Print
                    </el-button>
                  </el-row>
                </el-popover>
              </el-dropdown-item>
              <el-dropdown-item>
                <el-popover
                  placement="right"
                  title="203dpi Quantity"
                  width="175"
                  trigger="hover">
                  <el-button slot="reference" type="text" style="width:100%;text-align:left">
                    Send to Printer (203dpi)
                  </el-button>
                  <el-row>
                    <el-input v-model="label_count" style="width: 50%;margin-right:5px"
                      type="number"
                      inputmode="decimal"></el-input>
                    <el-button type="primary" @click="unit_dose_label('print203')">
                      Print
                    </el-button>
                  </el-row>
                </el-popover>
              </el-dropdown-item>
              <el-dropdown-item v-if="is_connected_printer">
                <el-popover
                  placement="right"
                  title="Label Quantity"
                  width="175"
                  trigger="hover">
                  <el-button slot="reference" type="text" style="width:100%;text-align:left">
                    Send to Printer (Equipment)
                  </el-button>
                  <el-row>
                    <el-input v-model="label_count" style="width: 50%;margin-right:5px"
                      type="number"
                      inputmode="decimal"></el-input>
                    <el-button type="primary" @click="unit_dose_label('print_equipment')">
                      Print
                    </el-button>
                  </el-row>
                </el-popover>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <span v-if="task.task.task_type == 'lot_create' || task.task.task_type == 'lot_rework'">
            <el-button
              type="default"
              class="status-button"
              style="float: right; padding: 5px"
              v-if="lot.status == 'pending' || lot.status == 'rework'"
              @click="save_lot_confirmation({status: 'cancelled'})"
              :disabled="task.task.task_status === 'cancelled' ||
                (lab_config.lot && lab_config.lot.allow_technicians_to_scrap_lot !== undefined &&
                !lab_config.lot.allow_technicians_to_scrap_lot &&
                $store.getters['account/profile'].user_type !== 'lab-pharmacist')"
            >
              <i class="fal fa-save"></i>
              {{ lot.saved || lot.version ? 'Scrap Lot' : 'Cancel Task' }}
            </el-button>
            <el-button
              type="default"
              class="status-button"
              style="float: right; padding: 5px"
              v-if="(lot.status == 'pending' || lot.status == 'rework') && (!has_activity_factor_ingredient && !(has_pack_stat_ingredient && lot.formula.pack_stat_required) || ((has_activity_factor_ingredient || (has_pack_stat_ingredient && lot.formula.pack_stat_required)) && lot.version))"
              @click="save_lot({status: 'pending'})"
              :disabled="task.task.task_status === 'cancelled' || saving_lot"
            >
              <i class="fal fa-save"></i>
              {{ lot.saved || lot.version ? 'Save' : 'Begin' }}
            </el-button>
            <el-tooltip
              v-if="(lot.status == 'pending' || lot.status == 'rework')  && required_tasks_incomplete"
              style="float: right; padding: 5px 0px;margin-left: 0px;margin-right: 15px;"
              class="item"
              effect="dark"
              content="Required tasks are incomplete"
              placement="top"
            >
              <el-button type="text"><i class="fas fa-exclamation-circle" style="color: tomato; font-size: 18px;"></i></el-button>
            </el-tooltip>
            <el-button
              type="primary"
              class="status-button"
              style="float: right; padding: 5px"
              v-if="(lot.saved || lot.version) && (lot.status == 'pending' || lot.status == 'rework')"
              @click="save_lot({status: 'review'})"
              :disabled="task.task.task_status === 'cancelled' || saving_lot"
            >
              <i class="fal fa-glasses"></i> Ready for Review
            </el-button>

            <el-button
              type="primary"
              class="status-button"
              style="float: right; padding: 5px"
              v-if="(lot.saved || lot.version) && (lot.status == 'pending' || lot.status == 'rework')"
              @click="request_pharmacist({status: 'pending'})"
              :disabled="task.task.task_status === 'cancelled'"
            >
              <i class="fal fa-bells"></i> Request RPh
            </el-button>
          </span>
          <span v-if="task.task.task_type == 'lot_check'">

            <span v-if="view_mode_permissions()">

              <el-button
                type="plain"
                class="status-button"
                style="float: right; padding: 5px"
                v-if="lot.status == 'review' && (task.task.data.icloud_account)"
                @click="beginVideoCall(task.task.data.icloud_account)"
                :disabled="task.task.task_status === 'cancelled'"
              >
                <i class="fad fa-video"></i> Video Call
              </el-button>

              <el-button
                type="warning"
                class="status-button"
                style="float: right; padding: 5px"
                v-if="lot.status == 'review'"
                @click="dialogReworkVisible = true "
                :disabled="task.task.task_status === 'cancelled'"
              >
                <i class="far fa-undo-alt"></i> Needs Changes
              </el-button>

              <el-button
                type="default"
                class="status-button"
                style="float: right; padding: 5px"
                v-if="lot.status == 'pending' || lot.status == 'review'"
                @click="save_lot_confirmation({status: 'cancelled'})"
                :disabled="task.task.task_status === 'cancelled'"
              >
                <i class="fal fa-save"></i>
                {{ lot.saved || lot.version ? 'Lot fails/Scrap' : 'Cancel Task' }}
              </el-button>

              <el-tooltip
                v-if="(lot.status == 'pending' || lot.status == 'review') && required_tasks_incomplete"
                style="float: right; padding: 5px 0px;margin-left: 0px;"
                class="item"
                effect="dark"
                content="Required tasks are incomplete"
                placement="top"
              >
                <el-button type="text"><i class="fas fa-exclamation-circle" style="color: tomato; font-size: 18px;"></i></el-button>
              </el-tooltip>


              <el-button
                type="success"
                class="status-button"
                style="float: right; padding: 5px"
                v-if="lot.status == 'review'"
                @click="save_lot({status: 'approved'})"
                :disabled="task.task.task_status === 'cancelled' || saving_lot || required_tasks_incomplete"
              >
                <i class="fal fa-clipboard-check"></i> Approve
              </el-button>
            </span>
            <div
              style="float: right; padding: 5px; margin-right: 1.5em"
              v-if="lot.status == 'rework'"
            >
              <el-button
                type="info"
                style="color: #909399; background: #f4f4f5; border-color: #d3d4d6;"
                @click="go_to_task(lot.task_id_create_lot)"
                :disabled="task.task.task_status === 'cancelled'"
                plain
              >
                Rework
                <i class="fal fa-external-link"></i>
              </el-button>
            </div>

            <div
              style="float: right; padding: 5px; margin-right: 1.5em"
              v-if="lot.status == 'approved'"
            >
              <el-button type="success" plain disabled>Approved</el-button>
            </div>
          </span>
          <span v-else>
            <div
              style="float: right; padding: 5px; margin-right: 1.5em"
              v-if="lot.status == 'review'"
            >
              <el-button
                type="info"
                style="color: #909399; background: #f4f4f5; border-color: #d3d4d6;"
                @click="go_to_task(lot.task_id_check_lot)"
                :disabled="task.task.task_status === 'cancelled'"
                plain
              >
                Waiting for review
                <i class="fal fa-external-link"></i>
              </el-button>
            </div>
          </span>
          <span>
            <el-button
              type="default"
              class="status-button"
              style="float: right; padding: 5px"
              v-if="lot.status == 'approved'"
              @click="go_to_worksheet"
              :disabled="task.task.task_status === 'cancelled'"
              plain>
              <i class="fal fa-clipboard-check"></i> Worksheet
            </el-button>
          </span>
        </div>
      </div>
      <div v-if="lot.workflow" style="margin: 1.25em;">
        <el-collapse @change="toggleReworkVisibility">
          <el-collapse-item title="Rework Feedback" name="1" v-if="lot.workflow.rework_reason">
            <div>{{lot.workflow.rework_reason}}</div>
          </el-collapse-item>
          <el-collapse-item
            title="Tasks"
            name="2"
            v-if=" false && lot.workflow && related_task && related_task.task_status != 'complete'"
          >
            <div>
              <div
                class="formula-task-title"
              >{{related_task.task_display_name}}: {{related_task.title}}</div>
              <div class="formula-task-note">{{related_task.task_note}}</div>
              <div class="formula-task-note">{{related_task}}</div>
              <el-button
                type="default"
                class="status-button"
                style="padding: 5px"
                @click="complete_task(task_id)"
              >
                <i class="fal fa-ballot-check"></i> Complete Task
              </el-button>&nbsp;
              <router-link class="task-link" :to="{ name: 'task-view', params: { id: task_id } }">
                {{task_id}}
                <i class="fal fa-external-link"></i>
              </router-link>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
      <el-row :gutter="10" style="display: flex">
        <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="4">
          <div class="formula-options">
            <div>
              Formula Type:
              <span style="text-transform: capitalize;">{{ formula_type.name }}</span>
            </div>
            <div v-if="formula_type && formula_type.station_type && station_types && station_types[formula_type.station_type]">
              Station Type:
              <span style="text-transform: capitalize;">{{ station_types[formula_type.station_type].description }}</span>
            </div>

            <div>
            <span class="indicators">
              <span class="indicator" style="color: Tomato;" v-if="formula.formula_information && formula.formula_information.is_hazardous">
                <i style="font-size: 1.0rem;" class="fad fa-flask-poison"></i>&nbsp;Hazardous
              </span>
              <span v-if="formula.formula_information && formula.formula_information.dea_schedule && parseInt(formula.formula_information.dea_schedule.level) > 0">
                <i style="font-size: 1.0rem;" class="fad fa-exclamation-triangle"></i>&nbsp;{{ formula.formula_information.dea_schedule.description || 'Controlled' }}
              </span>
            </span>
            </div>

            <div style="margin: 0.75em">
              <span v-if="formula.options.is_lab_stock">Lab Stock</span>
            </div>
            <div>Task Points: {{ formula.task_points }}</div>
            <div>Estimated Time: {{formula.task_time_required}}</div>
            <div>Location: {{formula.location || 'Not Set'}}</div>
            <div>
              <div v-if="form.quantity_edit_mode">
                Quantity:&nbsp;<el-input-number :controls="false" v-model="form.lot.quantity"
                  type="number"
                  inputmode="decimal" /> &nbsp;
                  <el-button @click="update_task_quantity" :disabled="!(parseFloat(lot.quantity))" round><i class="fal fa-pen" /> Update</el-button>
              </div>
              <div v-else>
                <span v-if="lot.saved || lot.version">Quantity</span>
                <span v-else @click="form.quantity_edit_mode=true" class="clickable">Quantity</span>
                : {{lot.quantity}}
              </div>
            </div>
            <div>
              Beyond Use:
              <el-popover placement="top-start" title="Beyond Use" width="200" trigger="hover">
                <div slot>
                  <div>Formula stability span: {{formula.options.stability_span}} days</div>
                  <div>
                    Beyond Use Date:
                    <el-popover placement="right" trigger="click" v-model="budChangeVisible">
                      <div>
                        <div>Change BUD</div>
                        <el-date-picker
                          v-model="BudToOverride"
                          type="date"
                          placeholder="Choose a BUD"
                          :default-value="calculated_beyond_use_date()"
                        ></el-date-picker>
                      </div>
                      <el-row class="mt-5" :gutter="30">
                        <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8">
                          <el-button size="mini" type="primary" @click="ChangeBud()">Submit</el-button>
                        </el-col>
                        <el-col :xs="16" :sm="16" :md="16" :lg="16" :xl="16">
                          <el-button size="mini" @click="cancelBud">Use Calculated date</el-button>
                        </el-col>
                      </el-row>
                      <a slot="reference">
                        {{calculated_beyond_use_date() | moment('YYYY-MM-DD')}}
                      </a>
                    </el-popover>
                  </div>
                  <div
                    class="helper-instructions"
                  >Note: Beyond Use Date is calculated considering the beyond use dates of ingredients used</div>
                </div>
                <el-button
                  slot="reference"
                  type="info"
                  plain
                  size="small"
                >
                {{calculated_beyond_use_date() | moment('YYYY-MM-DD')}}
                <!-- {{calculated_beyond_use_date().toJSON() }} lt; {{new Date(date_beyond_use_default).toJSON()}} -->
                <el-tooltip class="item" effect="dark" :content="`BUD Shorter than stability span: ${new Date(date_beyond_use_default).toLocaleDateString()}`" placement="right" v-if="!!(calculated_beyond_use_date() < date_beyond_use_default)">
                  <i class="fad fa-exclamation-triangle" style="color: steelblue; font-size: 1.1em;" ></i>
                </el-tooltip>

                </el-button>
              </el-popover>
              <el-tooltip class="item" effect="dark" :content="`BUD Manually changed`" placement="right" v-if="lot.beyond_use_date_override">
                <i class="fas fa-calendar-exclamation" style="color: #eab945b3; margin-left: 10px;"></i>
              </el-tooltip>
            </div>
            <div v-if="lot.date_created">
              Date Compounded:
              {{lot.date_created | moment('YYYY-MM-DD h:mm a')}}
            </div>
            <div
              v-if="formula.options.capsule_weight"
            >
              <span v-if="['C42751', 'C42893', 'C42897', 'C42905', 'C42910', 'C42927', 'C42930', 'C42931', 'C42992', 'C42998', 'C43243'].includes(formula.dosage_form)">
                Tablet
              </span>
              <span v-else>
                Capsule
              </span>
               Weight: {{formula.options.capsule_weight}}
            </div>
          </div>
          <div v-if="view_mode() == 'readonly'">Task Performed By: {{lot.author}}</div>
          <div
            v-if="view_mode() == 'readonly' && lot.verified_by"
          >Verified By: {{lot.verified_by.name}}</div>
        </el-col>
        <el-col :xs="24" :sm="12" :md="14" :lg="10" :xl="6" v-if="view_mode() == 'pending'">
          <div class="balance-area">
            <div class="el-badge item">
              <i class="far fa-balance-scale-right"></i>&nbsp;
              <sup v-if="balance.description" class="el-badge__content el-badge__content--primary" style="" @click="disconnectEquipment()">x</sup>
            </div>

            <span v-if="balance.description">
              {{balance.description}}:
              <span
                style="font-size: 1em; margin: 2em; vertical-align: text-bottom;"
              >
                <div style="margin-top: 5px;" v-show="balance.message">
                  <!-- <transition name="el-fade-in-linear"> -->
                    <div v-show="balance.description && !balance.allow_manual_measurement" class="equipment-message-box">
                      <i class="fad fa-exclamation-circle" style="--fa-secondary-color: tomato;"></i>&nbsp;
                      <span v-html="balance.message"></span></div>
                  <!-- </transition> -->
                </div>
                <span v-if="balance.allow_manual_measurement">
                  <div>
                    <span style="font-size: 14px;">Manual Entry: &nbsp; </span>
                    <span><el-input style="max-width: 8em;" v-model="balance.weight" placeholder="Record Manual Measurement" clearable autocomplete="off" @input="format_manual_weight"
                      type="number"
                      inputmode="decimal"></el-input>
                    </span>
                  </div>
                </span>
                <span v-else>
                  <el-tag
                    v-if="!balance_disallow_recording || parseFloat(balance.weight)"
                    no-color="#8a8a8a"
                    style="transform: scale(1.75);"
                  >{{parseFloat(balance.weight)}} {{balance.unit}}</el-tag>
                  <el-tag
                    v-else
                    no-color="#8a8a8a"
                    style="transform: scale(1.75);"
                  >Waiting for Data...</el-tag>
                </span>
              </span>
            </span>
            <span v-else class="helper-instructions">
              <i class="fal fa-barcode-scan"></i>&nbsp; Scan to select equipment
            </span>
          </div>
        </el-col>
        <el-col :xs="24" :sm="12" :md="14" :lg="10" :xl="8">
          <div class="lots_allowed" v-if="!lot.version">
            <el-card class="box-card" v-if="has_activity_factor_ingredient || (has_pack_stat_ingredient && lot.formula.pack_stat_required)">
              <div slot="header" class="clearfix">
                <span style="font-size: 1.5em;margin: 0">Pre-Scan Ingredients</span>
                <el-button
                  v-if="all_ingredients_scanned_activity_factor"
                  style="float: right;"
                  type="primary"
                  @click="save_lot({status: 'pending'})"
                  :disabled="saving_lot"
                >
                  Begin
                </el-button>
              </div>
              <div v-for="ingredient in formula.ingredients" :key="ingredient.identifier" style="margin-bottom: 10px; font-size: 1.1em;">
                <span :style="[ingredient.checked ? {'color': 'forestgreen'} : {} ]">
                  <span v-if="ingredient.checked"><i class="far fa-check-square"></i></span>
                  <span v-else><i class="far fa-square"></i></span>
                  {{ ingredient.description }}
                </span>
                <div
                  v-for="lot in Object.values(lot.lots_allowed).filter((lot) => lot.ingredient_identifier === ingredient.identifier)"
                  :key="lot.identifier"
                  style="color:#888; margin-left: 10px;"
                >
                  {{lot.lot_number}}
                </div>
              </div>
              <el-input
                ref="activity_factor_scan_code"
                placeholder="Please scan"
                v-model.lazy="form.activity_factor_scan_code"
                @input="activity_factor_utilize_scan_code()"
                clearable
                type="text"
                name="ingredient_lot_identifier"
                autocomplete="off"
                autocapitalize="none"
              >
                <template slot="prepend">
                  <i class="fal fa-barcode-scan"></i>
                </template>
              </el-input>
            </el-card>
          </div>
          <div v-else-if="has_activity_factor_ingredient || (has_pack_stat_ingredient && lot.formula.pack_stat_required)">
            <el-card class="box-card">
              <div slot="header" class="clearfix">
                <span style="font-size: 1.5em;margin: 0">Allowed Lots</span>
              </div>
              <el-collapse v-model="lot.lots_allowed_collapse" class="calculations">
                <el-collapse-item
                  v-for="ingredient in lot.lots_allowed"
                  :key="ingredient.identifier"
                  :title="`${ingredient.data.description || ingredient.description} - Lot# ${ingredient.data.lot_number}`"
                  :name="ingredient.data.lot_number">
                    <b>Original amount:</b>
                    {{parseFloat(formula.ingredients.filter(ing => ing.identifier === ingredient.ingredient_identifier)[0].quantity * quantity_muliplier).toFixed(decimal_places)}}
                    <BaseUnitOfMeasure
                      :unit="(ingredient.formula_unit_of_measure || ingredient.unit_of_measure)"
                    /><br>
                    <span v-if="ingredient.data && ingredient.data.activity_factor">
                      <b>Activity Factor:</b> {{ ingredient.data.activity_factor }}%<br>
                    </span>
                    <span v-if="ingredient.data && ingredient.data.pack_stat && lot.formula.pack_stat_required">
                      <b>Pack Stats:</b>
                      <div
                        v-for="pack_stat in Object.entries(ingredient.data.pack_stat).filter(entry => entry[1] > 0)"
                        :key="pack_stat[0]">
                        {{pack_stat[0]}}: {{pack_stat[1]}} gm
                      </div>
                    </span>
                </el-collapse-item>
                <el-collapse-item
                  v-if="lot.lots_allowed"
                  title="Calculations"
                  name="calculations"
                >
                <div
                  v-for="ingredient in uniqueby(Object.values(lot.lots_allowed).filter(ing => ing.ingredient.has_pack_stat || ing.ingredient.has_activity_factor || ing.ingredient.identifier === lot.formula.activity_factor_modifying_ingredient), 'ingredient_identifier')"
                  :key="ingredient.identifier">
                    <packstatCalculations :ingredient="ingredient" :formula="formula" :lot="lot" :quantity_muliplier="quantity_muliplier" />
                </div>
                </el-collapse-item>
              </el-collapse>
            </el-card>
          </div>
        </el-col>
      </el-row>

      <el-row :gutter="10">

        <div class="formula-ingredients">
          <div style="margin-top: 15px;">
            <el-col :span="11">
              <div class="sub-title section-header">
                Ingredients
                <span class="ingredient_count">[<span style="color: green;">{{in_range_ingredient_count}}</span> in range / {{formula.ingredients.length}} total]</span>
              </div>
            </el-col>
            <el-col :span="4" v-if="view_mode() == 'pending' && (!has_activity_factor_ingredient && !(has_pack_stat_ingredient && lot.formula.pack_stat_required) || ((has_activity_factor_ingredient || (has_pack_stat_ingredient && lot.formula.pack_stat_required)) && lot.version))">
              <span class="section-header">Ingredient Scan Mode &nbsp;</span>
              <el-switch v-model="form.ingredient_scan_mode" @change="switch_ingredient_mode()" :disabled="!lot.version"></el-switch>
            </el-col>
            <el-col :span="6" v-if="view_mode() == 'pending'">
              <form autocomplete="off" action="javascript:void(0);">
                <div v-if="isChrome()">
                  <el-input
                    ref="form_scan_code"
                    placeholder="Please scan"
                    v-model.lazy="form.scan_code"
                    @input="utilize_scan_code()"
                    v-if="form.ingredient_scan_mode"
                    clearable
                    type="text"
                    name="ingredient_lot_identifier"
                    autocomplete="off"
                    autocapitalize="none"
                  >
                    <template slot="prepend">
                      <i class="fal fa-barcode-scan"></i>
                    </template>
                  </el-input>
                </div>
                <div v-else>
                  <el-input
                    ref="form_scan_code"
                    placeholder="Please scan"
                    v-model.lazy="form.scan_code"
                    @input="utilize_scan_code()"
                    v-if="form.ingredient_scan_mode"
                    clearable
                    type="password"
                    name="ingredient_lot_identifier"
                    autocomplete="off"
                    autocapitalize="none"
                  >
                    <template slot="prepend">
                      <i class="fal fa-barcode-scan"></i>
                    </template>
                  </el-input>
                </div>
              </form>

            </el-col>
          </div>
        </div>
      </el-row>
      <el-row style="margin-top: 15px;">
        <div
          class="ingredient-list scrollbar-prominent"
          :class="{ 'scrollable': (view_mode() == 'pending' && form.ingredient_scan_mode)}"
        >
          <el-row
            class="item-wrapper"
            style="margin-top: 15px;"
            v-for="(ingredient, index) in formula.ingredients"
            :key="index"
          >
            <el-col :span="11">
              <el-row>
                <el-col :span="17">
                  <span
                    :class="calculate_ingredient_in_range(ingredient) ? 'ingredient-quantity-required-met' : '' "
                  >
                    <i
                      class="fas fa-check-circle"
                      v-if="calculate_ingredient_in_range(ingredient)"
                    >&nbsp;</i>
                    <span
                      :class="ingredient.identifier == current_ingredient.ingredient_identifier ? 'current-ingredient-lot' : ''"
                    >{{ ingredient.ingredient_type == 'formula' ? ingredient.name : ingredient.description || ingredient.name }}</span>
                  </span>
                  &nbsp;
                  <span class="ingredient-code">
                    <el-tooltip
                      class="item"
                      effect="dark"
                      :content="`Location: ${ingredient.location || 'Not Set'}`"
                      placement="top-start"
                    >
                      <el-button size="mini" round>{{ ingredient.code }}</el-button>
                    </el-tooltip>
                  </span>
                  <span style="margin-left: 10px;" v-if="ingredient.has_activity_factor">
                    <el-tooltip class="item" content="Ingredient has Activity Factor" placement="top">
                      <el-button type="text"><i class="fad fa-chart-network"></i></el-button>
                    </el-tooltip>
                  </span>
                  <span style="margin-left: 10px;" v-if="ingredient.has_pack_stat && $store.getters['formula/get_pack_stat_dosage_forms'].includes(lot.formula.dosage_form)">
                    <el-tooltip class="item" content="Ingredient has Pack Stat" placement="top">
                      <el-button type="text"><i class="fal fa-capsules"></i></el-button>
                    </el-tooltip>
                  </span>
                  <span style="margin-left: 10px;" v-if="ingredient.is_consumable">
                    <el-tooltip class="item" content="Ingredient is Consumable" placement="top">
                      <el-button type="text"><i class="fad fa-space-station-moon"></i></el-button>
                    </el-tooltip>
                  </span>
                </el-col>
                <el-col :span="1">&nbsp;</el-col>
                <el-col
                  :span=" (ingredient.quantity_sufficient_indicator|| ingredient.requires_measurement_check) ? 23 : 6"
                >
                  <div
                    v-if="view_mode() == 'pending' && (! (ingredient.quantity_sufficient_indicator || ingredient.requires_measurement_check) && ingredient.formula_unit_of_measure === 'C48155' && ingredient.identifier == current_ingredient.ingredient_identifier)"
                    class="record-balance-weight-for-ingredient-quantity"
                    style="    max-height: 2.5em;"
                  >
                    <el-button
                      :disabled="balance_disallow_recording"
                      class="clickable"
                      style="    padding-right: 26px;
                             font-size: 1.5em;
                        "
                      type="text"
                      @click.native="record_ingredient_weight()"
                      icon="fal fa-sign-in"
                      circle
                    ></el-button>
                    <!-- <i  class="fal fa-sign-in clickable"></i> -->
                    <!-- <span style="padding-right: 50px;">&nbsp;</span> -->
                  </div>

                  <!-- REQUIRES MEASUREMENT CHECK: -->
                  <div
                    v-if="view_mode() == 'pending' && ((ingredient.requires_measurement_check || ingredient.quantity_sufficient_indicator || ingredient.formula_unit_of_measure !== 'C48155') && ingredient.identifier == current_ingredient.ingredient_identifier)"
                    class="record-balance-weight-for-ingredient-quantity"
                    style="off-max-height: 2.5em; margin-bottom: 1em;"
                  >
                    <span v-if="ingredient.formula_unit_of_measure !== 'C48155'">
                      <div style="display: inline-block;">Quantity:&nbsp;</div>
                      <el-input-number
                        style="width: 15em;margin-left:10px;"
                        v-model="form.manual_measurement"
                        :placeholder="`Quantity`"
                        clearable
                        :controls="false"
                        type="number"
                        inputmode="decimal"
                      >
                        <template slot="append">
                          <BaseUnitOfMeasure
                            :unit="(ingredient.formula_unit_of_measure || ingredient.unit_of_measure)"
                          />
                        </template>
                      </el-input-number>
                    </span>


                    <el-button
                      :disabled="(balance_disallow_recording && ingredient.formula_unit_of_measure === 'C48155') || (!(parseFloat(form.manual_measurement) == form.manual_measurement) && ingredient.formula_unit_of_measure !== 'C48155')"
                      class="clickable"
                      style="padding-right: 26px;font-size: 1.5em;"
                      ref="record_ingredient_weight_button"
                      type="text"
                      @click.native="record_ingredient_weight({manual_measurement: form.manual_measurement})"
                      icon="fal fa-sign-in"
                      circle
                    ></el-button>
                    <!-- <i  class="fal fa-sign-in clickable"></i> -->
                    <!-- <span style="padding-right: 50px;">&nbsp;</span> -->
                  </div>
                </el-col>
                <!-- <el-col :span="1">
                <span style="padding-right: 55px;">&nbsp;</span>
                </el-col>-->
              </el-row>
              <el-row>
                <el-col :span="24">
                  <div v-if="! ingredient.requires_measurement_check">
                    <el-progress
                      :status="calculate_ingredient_is_in_range_as_text(ingredient)"
                      :percentage="calculate_ingredient_percent_complete(ingredient) > 100 ? 100 : calculate_ingredient_percent_complete(ingredient)"
                      class="balance"
                      :class="ingredient.quantity_sufficient_indicator ? 'quantity_sufficient' : '' "
                    ></el-progress>
                    <div class="quantity_current">
                      {{ parseFloat(calculate_ingredient_current_quantity(ingredient)).toFixed(decimal_places) }}
                      <!--
                  | {{ingredient.is_in_range}} | {{calculate_ingredient_in_range(ingredient) }}
                  | {{ calculate_quantity_required(ingredient.quantity) }}
                  | {{ calculate_ingredient_used_quantity({identifier: ingredient.identifier}) }}
                  | {{}}
                      -->
                    </div>
                    <div
                      class="quantity_needed"
                      :class="ingredient.quantity_sufficient_indicator ? 'quantity_sufficient' : '' "
                    >{{ parseFloat(parseFloat(calculate_quantity_required(ingredient)) - parseFloat(calculate_ingredient_current_quantity(ingredient))).toFixed(decimal_places) }}</div>
                  </div>
                  <div v-else>
                    <div>
                      <el-progress
                        :status="calculate_ingredient_is_in_range_as_text(ingredient)"
                        :percentage="calculate_ingredient_percent_complete(ingredient) > 100 ? 100 : calculate_ingredient_percent_complete(ingredient)"
                        class="balance"
                      ></el-progress>
                      <div class="quantity_current">
                        {{ parseFloat(calculate_ingredient_current_quantity(ingredient)).toFixed(decimal_places) }}
                        <!--
	                  | {{ingredient.is_in_range}} | {{calculate_ingredient_in_range(ingredient) }}
	                  | {{ calculate_quantity_required(ingredient.quantity) }}
	                  | {{ calculate_ingredient_used_quantity({identifier: ingredient.identifier}) }}
	                  | {{}}
                        -->
                      </div>
                      <div
                        class="quantity_needed"
                      >{{ parseFloat(parseFloat(calculate_quantity_required(ingredient)) - parseFloat(calculate_ingredient_current_quantity(ingredient))).toFixed(decimal_places) }}</div>
                    </div>
                  </div>
                </el-col>
                <el-col :span="4">
                  <span style="padding-right: 55px;">&nbsp;</span>
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="1">&nbsp;</el-col>
            <el-col :span="6">
              <span class="ingredient-measurement-line">
                <span>
                  <span
                    class="ingredient-quantity-used"
                  >{{ parseFloat(calculate_ingredient_used_quantity({identifier: ingredient.identifier })).toFixed(decimal_places) }}</span>
                  <span>&nbsp;/&nbsp;</span>
                </span>
                <span class="ingredient-quantity-required">
                  <span
                    class="ingredient-quantity-sufficient-message"
                    v-if="ingredient.quantity_sufficient_indicator"
                  >QS to</span>
                  {{ calculate_quantity_required(ingredient).toFixed(decimal_places) }}
                </span>
                <span></span>
                <span class="unit-of-measure-area">
                  <BaseUnitOfMeasure
                    :unit="(ingredient.formula_unit_of_measure || ingredient.unit_of_measure)"
                  />
                </span>
              </span>
            </el-col>
            <el-col :span="2" v-if="ingredient.requires_measurement_check">
              <el-checkbox
                :value="ingredient.measurement_checked"
                @change="log_measurement_reviewer(index, ingredient, (! ingredient.measurement_checked))"
              >Measurement Checked?</el-checkbox>

              <!--
            <div v-if="view_mode() == 'pending'">
	            <el-button @click="log_measurement_reviewer(ingredient, (! ingredient.measurement_checked) )" type="info" size="mini" round plain>{{ ! ingredient.measurement_checked ? 'Check' : 'Reset' }}</el-button>
            </div>
              -->
              <div
                v-if="ingredient.measurement_checked && typeof ingredient.measurement_checked_by == 'object'"
              >
                <el-tooltip placement="top">
                  <div
                    slot="content"
                  >{{new Date(ingredient.date_measurement_checked) | moment('YYYY-MM-DD h:mm:ss a') }}</div>Checked By:
                  <span class="clickable">{{ingredient.measurement_checked_by.name}}</span>
                </el-tooltip>
              </div>
            </el-col>
            <el-col :span="12">
              <div v-if="lot.ingredients_used[ingredient.identifier] && lot.ingredients_used[ingredient.identifier].lots">
                <b class="lot-header">Lots:</b>
                <div
                  v-for="ingredient_lot of Object.values(lot.ingredients_used[ingredient.identifier].lots).sort((a, b) => new Date(a.date_scanned) - new Date(b.date_scanned))"
                  :key="ingredient_lot.identifier"
                  class="lot-line"
                >
                  <!-- <pre>{{ ingredient_lot }}</pre> -->
                  <!-- {{ parseFloat(ingredient_lot.quantity || 0 ).toFixed(decimal_places) }} -->
                  <div
                    :class="ingredient_lot.identifier == current_ingredient.identifier ? 'current-ingredient' : ''"
                  >
                    <span v-if="ingredient_lot.identifier == current_ingredient.identifier">
                      <i class="fas fa-fill-drip" style="color: rgb(244, 116, 66);"></i>
                    </span>
                    <span class="lot-info">
                      <span :class="(lot._inventory_availability && lot._inventory_availability.data && lot._inventory_availability.data.lots[ingredient_lot.identifier] && lot._inventory_availability.data.lots[ingredient_lot.identifier].quantity_deficient) ? 'needs_adjust' : ''">
                        {{ingredient_lot.lot_number}}
                      </span>
                      <span class="manufacturer_name" v-if="ingredient_lot.lot_type==='ingredient' && ingredient_lot.data.manufacturer && ingredient_lot.data.manufacturer.name">[{{ingredient_lot.data.manufacturer.name}}]</span>

                      <span class="ingredient-lot-quantity-area">
                        <i
                          v-if="isNaN(parseFloat(ingredient_lot.quantity)) || parseFloat(ingredient_lot.quantity) == 0"
                          class="fal fa-minus-circle clickable ingredient-remove"
                          @click="remove_ingredient_lot(ingredient_lot)"
                        ></i>
                        <span
                          v-else
                          class="ingredient-lot-quantity"
                          :class="(lot._inventory_availability && lot._inventory_availability.data && lot._inventory_availability.data.lots[ingredient_lot.identifier] && lot._inventory_availability.data.lots[ingredient_lot.identifier].quantity_deficient) ? 'needs_adjust' : ''"
                        >{{parseFloat(ingredient_lot.quantity|| 0).toFixed(decimal_places)}}</span>
                      </span>

                      <span
                        class="lot-beyond-use-date"
                        :class="view_mode()"
                      >
                        Beyond Use:
                        <span
                          class="date no-break"
                        >
                          {{moment_utc(ingredient_lot.data.inventory.beyond_use_date)}}
                        </span>
                      </span>
                      <div class="lot-capsule-weight-average" v-if="ingredient_lot.data.inventory.capsule_weight_average">Empty Capsule Weight: {{ingredient_lot.data.inventory.capsule_weight_average}}</div>
                    </span>
                    <div class="lot-negative-inventory" v-if="lot._inventory_availability && lot._inventory_availability.data && lot._inventory_availability.data.lots[ingredient_lot.identifier]">
                      <span v-if="lot._inventory_availability.data.lots[ingredient_lot.identifier].quantity_deficient">
                        <span v-if="lot._inventory_availability.data.lots[ingredient_lot.identifier].adjusted">
                          <i class="fas fa-check-circle" style="color: forestgreen"></i>
                        </span>

                        <span
                          :style="lot._inventory_availability.data.lots[ingredient_lot.identifier].adjusted ? {'text-decoration': 'line-through', 'text-decoration-color': 'red'} : {}">
                          Inventory over committed: -{{lot._inventory_availability.data.lots[ingredient_lot.identifier].quantity_deficient}}
                          <BaseUnitOfMeasure
                            :unit="(lot._inventory_availability.data.lots[ingredient_lot.identifier].unit_of_measure || ingredient.unit_of_measure)"
                          />
                        </span>



                        <el-popover
                          placement="bottom"
                          title="Adjust Inventory"
                          :width="200"
                          trigger="click"
                          @show="lot_adjust_amount = 0 - lot._inventory_availability.data.lots[ingredient_lot.identifier].quantity_deficient"
                        >
                          <template #reference>
                            <el-button type="text" size="small" style="margin-left: 5px;font-size: x-small;" :disabled="lot._inventory_availability.data.lots[ingredient_lot.identifier].adjusted">
                              <i class="far fa-edit"> Adjust</i>
                            </el-button>
                          </template>
                          <el-input v-model="lot_adjust_amount" style="width: 55%;" size="small"
                            type="number"
                            inputmode="decimal"/>
                          <el-button type="primary" style="width: 40%; margin-left: 5%" size="small"
                            @click="perform_lot_adjustment(lot._inventory_availability.data.lots[ingredient_lot.identifier], ingredient_lot.identifier)">
                            Submit
                          </el-button>
                        </el-popover>
                      </span>

                    </div>

                  </div>
                </div>
              </div>
            </el-col>
            <el-col :span="24" v-if="lot.ingredients_used[ingredient.identifier]">

              <div class="measurements-log-area">
                <div>
                  <b class="log-header">Measurements Log</b>
                </div>
                <div
                  v-for="(item, log_index) in lot.ingredients_used[ingredient.identifier].log_measurements"
                  :key="item.identifier"
                >
                  <span class="logged-weight" :class="item.archived ? 'strike' : ''">{{item.weight}}</span> [
                  <el-tooltip placement="top">
                    <div
                      slot="content"
                    >{{new Date(item.date_logged) | moment('YYYY-MM-DD h:mm:ss a') }}</div>
                    <span class="clickable">{{item.user || item.name}}</span>
                  </el-tooltip>]
                  &nbsp;
                  <i
                    class="fal fa-times-circle clickable remove"
                    @click="remove_log_measurement({log: item, index: log_index, ingredient_identifier: ingredient.identifier})"
                  ></i>
                </div>
              </div>
            </el-col>
          </el-row>
          <!-- /v-for="ingredient" -->
            <div id="end_ingredients_area" v-if="form.ingredient_scan_mode"><hr/>END Ingredients</div>

        </div>
        <!-- #ingredient-list -->
      </el-row>
      <el-row>
        <div style="margin-top: 15px;">
          <el-col :span="16">&nbsp;</el-col>
          <el-col :span="4"></el-col>
          <span class="total_weight_area">
            Total Weight: {{calculate_total()}} / {{calculate_total_required()}}
          </span>
        </div>
      </el-row>
      <el-row>
        <div style="margin-top: 15px;">
          <el-col :span="24" :xs="24">
            <b class="section-header">Equipment Used:</b>
            <el-table :data="equipment_list" style="width: 100%" height="250">
              <el-table-column prop="description" label="Equipment" min-width="180"></el-table-column>
              <el-table-column prop="user" label="User" min-width="180"></el-table-column>
              <el-table-column prop="date_checked_out" label="Time" min-width="200">
                <template slot-scope="props">
                  {{props.row.date_checked_out | moment('YYYY-MM-DD h:mm:ss a') }}
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </div>
      </el-row>

      <div class="formula-instructions-area">
        <div class="section-header">
          Instructions
          <span
            class="toggle-instructions clickable"
            style="color: rgb(100, 100, 100); margin-left: 2em;  text-align:right;"
          >
            <i
              class="far fa-eye"
              v-if=" ! show_instructions"
              @click="toggleInstructionVisibility(true)"
            ></i>
            <i class="far fa-eye-slash" @click="toggleInstructionVisibility(false)" v-else></i>
          </span>
        </div>
        <div class="formula-instructions" v-if="show_instructions">{{formula.instructions}}</div>
        <div
          class="formula-type formula-instructions"
          v-if="show_instructions"
        >{{formula_type.instructions}}</div>
      </div>

      <div class="formula-tasks">
        <h4>
          <i style="margin-right: .5em" class="fal fa-tasks"></i>
          <span>Tasks</span>
        </h4>
        <el-form>
          <el-form-item
            label
            v-for="(task, index) in task_mutatable.lot.formula.tasks"
            :key="index"
          >
            <el-checkbox
              :label="task.description"
              :name="`task_${index}`"
              @change="set_subtask_status(task, index)"
              v-model="task.complete"
              :checked="task.complete"
              :readonly="view_mode() == 'readonly'"
            ></el-checkbox>
            <span v-if="task.required">
              &nbsp;
              <el-tooltip
                class="item"
                effect="dark"
                content="This task is required"
                placement="top"
              >
                <el-button type="text">
                  <span v-if="task.complete">
                    <i class="fas fa-check-circle" style="color:forestgreen"></i>
                  </span>
                  <span v-else>
                    <i class="fas fa-exclamation" style="color: tomato"></i>
                  </span>
                </el-button>
              </el-tooltip>
            </span>
            <span v-if="view_mode() == 'readonly' || task.complete ">
              [
              <el-tooltip placement="top">
                <div
                  slot="content"
                >{{new Date(task.date_completed) | moment('YYYY-MM-DD h:mm:ss a') }}</div>
                <span class="clickable" v-if="task.user">{{task.user.name}}</span>
                <span v-else>
                  <i class="fal fa-exclamation-triangle"></i>
                  <span v-if="! task.complete">Incomplete</span>
                  <span v-else>n/a</span>
                </span>
              </el-tooltip>]
            </span>



            <div v-if="task.record_weights">
              <div class="list-wrap">

                <div class="styled-list">
                  <div v-for="(weight, weight_index) in task.weights" :key="weight_index">
                    {{weight}}
                    <i
                      class="fal fa-minus-circle clickable item-remove"
                      @click="remove_task_weight(index, weight_index)"
                      v-if="view_mode() == 'pending'"
                    ></i>
                  </div>
                </div>
              </div>

              <span v-if="view_mode() == 'pending' && subtasks[index]">

                <span v-if="!config_disallow_manual_measurement_tasks">
                  <el-input v-model="subtasks[index].new_weight" style="max-width: 5em; " size="mini" type="number" inputmode="decimal"></el-input>&nbsp;
                  <el-button type="primary" @click="set_subtask_weights(task, index )" size="mini" style="margin-right: 5px">
                    <i class="fal fa-plus"></i> &nbsp; Add
                  </el-button>
                </span>
                <el-button
                  type="primary"
                  @click="set_subtask_weights(task, index, balance.weight )"
                  data-old="@click='task.weights.push(balance.weight)''"
                  size="mini"
                  :disabled="!balance.description || balance_disallow_recording"
                >
                  <i class="fal fa-balance-scale-right"></i>
                  &nbsp; Record [{{balance.weight}}]
                </el-button>
              </span>
            </div>

            <div v-if="task.record_manual_measurement">
             <ul>
                <li v-for="(measurement, measurement_index) in task.measurements" :key="measurement_index">
                  {{measurement.display_description || measurement.value}}
                  <i
                    class="fal fa-minus-circle clickable item-remove"
                    @click="remove_task_measurement(index, measurement_index)"
                    v-if="view_mode() == 'pending'"
                  ></i>
                </li>
              </ul>
              <span v-if="view_mode() == 'pending'">
                <el-input v-model="subtasks[index].new_measurement.value" style="max-width: 25em; " size="mini"></el-input>
                &nbsp;
                <el-button type="primary" @click="set_subtask_measurement(task, index )" size="mini">
                  <i class="fal fa-plus"></i> &nbsp; Add
                </el-button>
              </span>
            </div>

            <div v-if="task.record_time_measurement">
              <i class="fad fa-stopwatch"></i>
              <span v-if="task.time_measurements && task.time_measurements[0]">
                <span class="time_measurement_begin">{{ task.time_measurements[0] | moment('YYYY-MM-DD hh:mm:ss a') }}</span> -
                <span class="time_measurement_end" v-if="task.time_measurements[1]">{{ task.time_measurements[1] | moment('YYYY-MM-DD  hh:mm:ss a') }}</span>
                <span class="time_measurement_quantity" v-if="task.time_measurements[1]"> | {{ format_time_quantity(task.time_measurements) }} </span>
              </span>
              <span v-if="view_mode() == 'pending'">
                <el-button type="primary" @click="set_subtask_time_measurement(task, index, 0 )" size="mini">
                   &nbsp; Begin
                </el-button>
                &nbsp;
                <el-button type="primary" @click="set_subtask_time_measurement(task, index, 1 )" size="mini">
                   &nbsp; End
                </el-button>

              </span>
            </div>

            <div v-if="task.record_photo">
              <el-row>
                <el-col :span="24">
                  <div style="margin-top: 20px;">
                    <h4>
                      <i style="margin-right: .5em" class="fal fa-file"></i>
                      <span>Documents</span>
                    </h4>
                    <!-- <pre>task.documents:{{task.documents}}</pre> -->
                    <div v-for="(doc, index) in task.documents" :key="index">
                      <div v-if="doc.documents && doc.documents.length > 0">
                        <div class="sub-title">Category: {{ task_document_categories[doc.category] }}</div>
                        <table class="styled striped" width="100%">
                          <thead>
                          <tr>
                            <th width="20%">Name</th>
                            <th width="70%">Note</th>
                            <th width="">Preview</th>
                            <!-- <th width="10%">Remove</th> -->
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="item in doc.documents" :key="item.identifier">
                            <td style="word-break: break-all;">
                              <el-link :href="get_document_url(item.identifier, {file_name: item.name, mime_type: item.mime_type || item.type})" target="_blank">
                                <i class="fa fa-file"></i>
                                {{ item.name }}
                              </el-link>
                            </td>
                            <td>{{ item.note }}</td>
                            <td style="display:flex; flex-direction: row;">
                              <a :href="get_document_url(item.identifier, {file_name: item.name, mime_type: item.mime_type || item.type})" target="_blank">
                                <div v-if="item.type === 'image/heic'" style="width: 200px; height: 200px;">
                                  <RenderHEICImage :file="item" />
                                </div>
                                <el-image
                                    v-if="is_picture(item.mime_type || item.type)"
                                    style="width: 200px; height: 200px"
                                    :src="get_document_url(item.identifier, {file_name: item.name, mime_type: item.mime_type || item.type, width: 200})"
                                    fit="scale-down"
                                >
                                  <div slot="placeholder" class="image-slot">
                                    Loading<span class="dot">...</span>
                                  </div>
                                </el-image>
                              </a>
                              <el-button type="text"
                                @click="remove_document(task, item.identifier)"
                                style="margin-left: 10px;"
                                v-if="view_mode() == 'pending'"
                              >
                                <i class="fas fa-trash-alt" style="color: tomato;"></i>
                              </el-button>
                            </td>
                            <!-- <td>{{ doc.category }}</td> -->
                            <!-- <td>
                              <el-button
                                plain
                                size="mini"
                                type="danger"
                                icon="el-icon-delete"
                                circle
                                @click="deleteDoc(doc.documents, item)"
                              ></el-button>
                            </td> -->
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <BaseDocumentUpload
                      :documents.sync="task.documents"
                      icon_class="fal fa-camera"
                      :value="task.documents"
                      :categories="task_document_categories"
                      label="Upload"
                      @update_documents="updateTaskDocuments(task, ...arguments)"
                    ></BaseDocumentUpload>
                    <takePhoto @update_documents="updateTaskDocuments(task, ...arguments)" />
                  </div>
                </el-col>
              </el-row>
            </div>
          </el-form-item>
        </el-form>
        <!-- formula-type tasks -->
      </div>

      <div
        style="border-top: .5px solid #aaa; border-bottom: .5px solid #aaa; padding: 15px 15px; margin: 10px 0px;"
        v-if="formula.details.final_appearance || formula.details.packaging || formula.details.storage_instructions"
      >
        <div v-if="formula.details.final_appearance">
          <b class="section-header">Final Appearance: {{formula.details.final_appearance}}</b>
        </div>
        <div v-if="formula.details.packaging">
          <b class="section-header">Packaging: {{formula.details.packaging}}</b>
        </div>
        <div v-if="formula.details.storage_instructions">
          <b class="section-header">Storage Instructions: {{formula.details.storage_instructions}}</b>
        </div>
      </div>

      <el-row v-if="view_mode() == 'pending'" :gutter="15">
        <el-col :xs="12" :sm="6" :md="7" :lg="6" :xl="4">
          <div style="margin-top: 15px;">
            Final Yield:
            <span style="font-size: 12px; color: #999">
              [{{lot.yield}}]
            </span>
            <br>
            <el-input
              id="final_yield_input"
              v-model="lot.yield"
              width="10"
              placeholder="100"
              style="min-width: 11em; max-width: 19em;"
              @input="format_float"
              type="number"
              inputmode="decimal"
              pattern="[0-9.-]*"
            >
              <template slot="append">
                <BaseUnitOfMeasure :unit="formula.unit_of_measure" />
              </template>
            </el-input>
          </div>
        </el-col>


        <el-col :xs="12" :sm="6" :md="5" :lg="6" :xl="4">
          <div style="margin-top: 15px;">
            Containers:
            <span style="font-size: 12px; color: #999">
              [{{lot.yield_container_count}}]
            </span>
            <br>
            <el-input
              v-model.number="lot.yield_container_count"
              width="10"
              placeholder="100"
              style="max-width: 13em;"
              type="number"
              inputmode="decimal"
              pattern="[0-9.-]*"
            ></el-input>
          </div>
          <div style="margin-top: 5px;">
            <div>
              <el-button @click="dialogQuantityPromptVisible = true">
                Labels {{ lot.inventory.label_list ? lot.inventory.label_list.length : ''}}
              </el-button>
              &nbsp;
              <span style="margin: 0 1.5em;">
                <el-popover
                  placement="right"
                  width="400"
                  trigger="click">
                  <el-table :data="lot.inventory.label_list">
                    <el-table-column width="150" property="quantity" label="QTY"></el-table-column>
                    <el-table-column width="250" property="note" label="Note"></el-table-column>
                  </el-table>
                  <el-button slot="reference" type="text">View</el-button>
                </el-popover>
              </span>
            </div>
          </div>
          <div style="margin-top: 5px;">

          </div>
        </el-col>
        <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="4">
          <div style="margin-top: 15px;">
            Note: <!-- {{ lot.note }} -->
            <el-input
              type="textarea"
              :autosize="{ minRows: 1, maxRows: 4}"
              placeholder="Lot note..."
              v-model="lot.note"
            ></el-input>
          </div>
        </el-col>

        <el-col :xs="12" :sm="12" :md="6" :lg="3" :xl="1">
          <div style="margin-top: 15px;" v-if="lab_config.lot && lab_config.lot.allow_custom_lot_number">
            Lot #:
            <el-input
              v-model="lot.lot_number"
              width="10"
              placeholder="Lot #"
              style="max-width: 13em;"
            ></el-input>
          </div>
        </el-col>

      </el-row>

      <el-row v-else-if="this.lot.status == 'review'" :gutter="15">
        <el-col :xs="12" :sm="12" :md="8" :lg="6" :xl="4">
          <div style="margin-top: 15px;">
            Final Yield:
            <span style="font-size: 12px; color: #999">
              [{{lot.yield}}]
            </span>
            <br>
            <el-input
              id="final_yield_input"
              v-model="lot.yield"
              width="10"
              placeholder="100"
              style="min-width: 11em; max-width: 19em;"
              :controls="false"
              @input="format_float"
              type="number"
              inputmode="decimal"
              pattern="[0-9.-]*"
            >
              <template slot="append">
                <BaseUnitOfMeasure :unit="formula.unit_of_measure" />
              </template>
            </el-input>
          </div>
          <div style="margin-top: 5px;">
            <el-popover
              placement="right"
              width="400"
              trigger="click">
              <el-table :data="lot.inventory.label_list">
                <el-table-column width="150" property="quantity" label="QTY"></el-table-column>
                <el-table-column width="300" property="note" label="Note"></el-table-column>
              </el-table>
              <el-button slot="reference">Labels {{ lot.inventory.label_list ? lot.inventory.label_list.length : ''}}</el-button>
            </el-popover>
          </div>
        </el-col>

        <el-col :xs="12" :sm="12" :md="8" :lg="6" :xl="4">
          <div style="margin-top: 15px;">
            Containers:
            <span style="font-size: 12px; color: #999">
              [{{lot.yield_container_count}}]
            </span>
            <br>
            <el-input
              v-model.number="lot.yield_container_count"
              width="10"
              placeholder="100"
              style="max-width: 13em;"
              type="number"
              inputmode="decimal"
              pattern="[0-9.-]*"
            ></el-input>
          </div>
        </el-col>

        <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="4">
          <div style="margin-top: 15px;">
            Note: <!-- {{ lot.note }} -->
            <el-input
              type="textarea"
              :autosize="{ minRows: 1, maxRows: 4}"
              placeholder="Lot note..."
              v-model="lot.note"
            ></el-input>
          </div>
        </el-col>

        <el-col :xs="8" :sm="6" :md="4" :lg="3" :xl="1" v-if="lab_config.lot && lab_config.lot.allow_custom_lot_number">
          <div style="margin-top: 15px;">
            Lot #: {{ lot.lot_number }}
          </div>
        </el-col>

      </el-row>

      <el-row v-else :gutter="20">
        <el-col :span="11">
          <div style="margin-top: 15px;">
            Final Yield: {{lot.yield}}
            <BaseUnitOfMeasure :unit="formula.unit_of_measure" />
            &nbsp; Containers: {{lot.yield_container_count}}
          </div>
          <div style="margin-top: 5px;">
            <el-popover
              placement="right"
              width="400"
              trigger="click">
              <el-table :data="lot.inventory.label_list">
                <el-table-column width="150" property="quantity" label="QTY"></el-table-column>
                <el-table-column width="300" property="note" label="Note"></el-table-column>
              </el-table>
              <el-button slot="reference">Labels {{ lot.inventory.label_list ? lot.inventory.label_list.length : ''}}</el-button>
            </el-popover>
          </div>
        </el-col>

        <el-col :span="11">
          <div style="margin-top: 15px;">
            Note:
            <el-input
              type="textarea"
              :autosize="{ minRows: 1, maxRows: 4}"
              placeholder="Lot note..."
              v-model="lot.note"
            ></el-input>
          </div>
        </el-col>

        <el-col :xs="8" :sm="6" :md="4" :lg="3" :xl="1" v-if="lab_config.lot && lab_config.lot.allow_custom_lot_number">
          <div style="margin-top: 15px;">
            Lot #: {{ lot.lot_number }}
          </div>
        </el-col>
      </el-row>

      <el-row>
        <div style="margin-top: 15px;"></div>
      </el-row>
    </el-card>
    <el-dialog title="Rework Notes" :visible.sync="dialogReworkVisible">
      <el-form :model="form">
        <el-form-item label="Reason">
          <el-input type="textarea" v-model="form.rework_reason" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogReworkVisible = false">Cancel</el-button>
        <el-button
          type="primary"
          @click="save_lot({status: 'rework', workflow: { task_note: form.rework_reason || 'Needs changes', task_type: 'lot_rework', rework_reason: form.rework_reason } }); dialogReworkVisible = false"
        >Confirm</el-button>
      </span>
    </el-dialog>
    <label-inventory-quantity-prompt
      :dialogVisible="dialogQuantityPromptVisible"
      :labels="lot.inventory.label_list"
      @input="update_label_entries"
      @close="set_dialogQuantityPromptVisible(false)"
    />
    <div style="display:none;" class="packstat_calculations" v-if="lot.lots_allowed">
      <div
        v-for="ingredient in uniqueby(Object.values(lot.lots_allowed).filter(ing => ing.ingredient.has_pack_stat || ing.ingredient.has_activity_factor || ing.ingredient.identifier === lot.formula.activity_factor_modifying_ingredient), 'ingredient_identifier')"
        :key="ingredient.identifier">
          <packstatCalculations :ingredient="ingredient" :formula="formula" :lot="lot" :quantity_muliplier="quantity_muliplier" />
      </div>
    </div>
  </div>

</template>

<script>
const uuidv4 = require("uuid/v4");
import NProgress from "nprogress";
import Common from "@/common.js";
import moment from 'moment';
import EventService from "@/services/EventService.js";
import EquipmentService from '@/services/equipment.js'
const uuid_base64 = require("uuid-base64");
import lot_store from "@/store/modules/lot.js";
import url_for from "@/services/pmk_url_for";
import LogRocket from 'logrocket';
const jsondiffpatch = require('jsondiffpatch');
import elabmath from '@/utils/calculate_quantity_required.js'
import label_inventory_quantity_prompt from '@/components/ingredient/label_inventory_quantity_prompt'
import packstatCalculations from '@/components/task/task_template/packstat_calculations.vue'
import takePhoto from '@/components/TakePhoto.vue'
import RenderHEICImage from '@/components/RenderHEICImage.vue'
import _ from 'lodash'
let WS_lot = {};
const connect_lot_websocket = function(ws_url) {
  const ws = new WebSocket(ws_url)
  ws.onopen = function() {
    // Web Socket is connected, send data using send()
    ws.send("Opening Socket");
    window.setInterval(function() {
      ws.send("PING Socket"); }, 50000
    );
  };
  return ws;
};
const patch_formula = (self, update_from_WS) => {
  if (!(update_from_WS.patch_data.formula && self.lot_as_saved.formula)) { return }
  const current_server_saved_formula = JSON.parse(JSON.stringify({ ...self.lot_as_saved.formula, ...update_from_WS.patch_data.formula }))
  const jdp = jsondiffpatch.create({cloneDiffValues:true})
  const current_state_diff = jdp.diff(self.lot_as_saved.formula, self.task_mutatable.lot.formula);
  console.log({ current_state_diff })

  const new_version_formula = jdp.patch(current_server_saved_formula, current_state_diff)
  console.log(2530, {current_server_saved_formula, new_version_formula})

  if (new_version_formula && update_from_WS.patch_data.formula) {
    self.task_mutatable.lot.formula = _.merge(
      JSON.parse(JSON.stringify({ ...self.task_mutatable.lot.formula, ...update_from_WS.patch_data.formula })),
      new_version_formula
    )
    // console.log({ 'self.task_mutatable.lot.formula': self.task_mutatable.lot.formula})
    delete update_from_WS.patch_data.formula;
  }
}

const patch_lot = (self, update_from_WS) => {
  self.task_mutatable.lot = _.merge(JSON.parse(JSON.stringify(self.task_mutatable.lot)), JSON.parse(JSON.stringify(update_from_WS.patch_data)))
}

const scanned_code_listener = function(e) {
  // console.log("caught code:", e);
  NProgress.start();
  EventService.getInventory(e.detail.code)
    .then(function(result) {
      if (result.success && result.data.ingredient_identifier) {
        //let lot = self.$store.getters['lot/getLot']
        let filter_result = self.formula.ingredients.filter(
          ingredient =>
            ingredient.identifier.toLowerCase() ==
            result.data.ingredient_identifier.toLowerCase()
        );
        if (filter_result.length) {
          // console.log(
          //   `416:  ${result.data.ingredient_identifier.toLowerCase()}`
          // );
          self.$store.dispatch("lot/ingredient_check_in_and_weigh", {
            ingredient_identifier: result.data.ingredient_identifier.toLowerCase(),
            lot: result.data.data
          });
        } else {
          const notification = {
            type: "warning",
            message: "Incorrect ingredient scanned!"
          };
          self.$store.dispatch("notification/add", notification, {
            root: true
          });
        }
      }
    })
    .then(() => NProgress.done())
    .catch(() => {
      NProgress.done();
    });
};


export default {
  props: {
    task: Object
  },
  components: {
    'label-inventory-quantity-prompt': label_inventory_quantity_prompt,
    packstatCalculations,
    takePhoto,
    RenderHEICImage
  },
  data() {
    return {
      lot_adjust_amount: 0,
      balance_disallow_recording: false,
      budChangeVisible: false,
      BudToOverride: null,
      task_mutatable: this.task,
      options: {},
      show_instructions: false,
      subtasks: [],
      formula_tasks_all: [],
      categories: this.$store.state.categories,
      form: {
        ingredient_scan_mode: false,
        scan_code: "",
        manual_measurement: undefined,
        label_loading: false,
        quantity_edit_mode: false,
        lot: {
          quantity: parseFloat( ( this.task.task.data.lot ? this.task.task.data.lot.quantity : this.task.lot.quantity))
        },
        activity_factor_scan_code: ''
      },
      quantity_muliplier: 0,
      dialogReworkVisible: false,
      dialogQuantityPromptVisible: false,

      task_document_categories: [
        {label:'Photo',value:'photo'}
      ],
      lot_as_saved: _.cloneDeep(this.task.lot || {}),
      ws_active: true,
      date_started: new Date().toJSON(),
      has_activity_factor_ingredient: false,
      has_pack_stat_ingredient: false,
      all_ingredients_scanned_activity_factor: false,
      activity_factor_difference: {},
      pack_stat_fill: {},
      pack_stat_unit_name: '',
      activity_factor_modifying_ingredient: {},
      pack_stat_excipient_ingredient: {},
      saving_lot: false,
      label_count: 1,
      selected_device: {},
      //formula: this.createFreshFormulaObject()

      current_sessions: []
    };
  },
  watch: {
    'formula.ingredients' :{
      handler : function(oldVal, newVal) {
        this.in_range_ingredient_count = this.formula.ingredients.filter(d => d.is_in_range == true).length;
        this.formula.ingredients.forEach(ingredient => {
          if (ingredient.has_pack_stat || ingredient.has_activity_factor) {
            self.has_activity_factor_ingredient = true
          }
        })
      },
      deep: true
    }
  },
  computed: {
    // formula: {
    //   get() {
    //     return this.$store.getters['formula/get_formula']
    //   }
    // },
    decimal_places() {
      return _.get(this.$store.getters['formula/get_lab_config'].config, 'lot.decimal_places', 3)
    },
    station_types() {
      const station_types = this.$store.getters['formula/get_station_types']
      return station_types
    },
    required_tasks_incomplete() {
      let incomplete_required_tasks = this.task_mutatable.lot.formula.tasks.filter(task => {
        if (task.required && !task.complete) {
          return true
        }
        return false
      })
      return !!incomplete_required_tasks.length
    },
    equipment_list: {
      get() {
        return this.$store.getters["equipment/get_equipment_list_checked_out"];
      }
    },
    formula: {
      get() {
        //return { options: {} }
        return this.task_mutatable.lot && this.task_mutatable.lot.formula ? this.task_mutatable.lot.formula : this.task_mutatable.formula.data || { options: {} };
      }
    },
    in_range_ingredient_count:{
      get(){
        let count = this.formula.ingredients.filter(d => d.is_in_range == true).length;
        return count;
      },
      set() {
        return 1
      }
    },
    formula_type: {
      get() {
        if (this.$store.getters["lot/getLot"] && this.$store.getters["lot/getLot"].formula_type_data) {
          console.log('using local formula type data')
          return this.$store.getters["lot/getLot"].formula_type_data
        }
        if (!this.$store.getters["formula/get_lab_config"]) {
          this.$store.dispatch("formula/fetchLabConfig");
        }
        if (this.$store.getters["formula/get_lab_config"]) {
          return (
            this.$store.getters["formula/get_lab_config"]["config"][
              "formula_types"
            ][this.formula.formula_type] || { id: this.formula.formula_type }
          );
        } else return {};
      }
    },
    lab_config: {
      get() {
        if (!this.$store.getters["formula/get_lab_config"]) {
          this.$store.dispatch("formula/fetchLabConfig");
        }
        if (this.$store.getters["formula/get_lab_config"]) {
          return this.$store.getters["formula/get_lab_config"]["config"]
        } else return {};
      }
    },
    config_disallow_manual_measurement_tasks: {
      get() {
        return _.get(this.lab_config, 'lot.disallow_manual_measurement_tasks', false)
      }
    },
    related_task: {
      get() {
        return this.$store.getters["task/queryTasks"];
      }
    },
    balance: {
      get() {
        return this.$store.getters["equipment/get_balance_state"];
      },
      set(new_data) {
        let self = this;
        console.log('set balance', new_data)
        let new_object = { ...new_data };
        self.$store.dispatch("equipment/set_balace_state_weight", new_object).then(data => {
          console.log("balance.weight", self.balance.weight);
        });
      }

    },
    current_ingredient: {
      get() {
        return this.$store.getters["lot/getCurrentIngredientLot"];
      }
    },
    lot: {
      get() {
        let self = this;
        // should be this but lot.js needs work:
        // return this.$store.getters['lot/queryTasks']
        console.warn('this.formula_type.tasks', this.formula_type.tasks)
        const formula_type_tasks_mutable = JSON.parse(JSON.stringify(this.formula_type.tasks || []))
        if (
          !this.task_mutatable.lot ||
          (Object.entries(this.task_mutatable.lot).length === 0 &&
            this.task_mutatable.lot.constructor === Object)
        ) {
         // console.log("getting lot task:", this.task_mutatable);
          let task_lot = createFreshLotObject(this.task_mutatable, this.lab_config);
          console.log("new task lot:", task_lot);

          if (!task_lot.formula.formula_level_tasks) {
            let lot_formula = {
              ...task_lot.formula,
              ...{
                formula_level_tasks: true,
                tasks: [...task_lot.formula.tasks, ...formula_type_tasks_mutable]
              }
            };
            task_lot = { ...task_lot, ...{ formula: lot_formula } };
          }

          this.task_mutatable = {
            ...this.task_mutatable,
            ...{ lot: task_lot }
          };
          self.$store.dispatch("lot/setFormulaLot", task_lot);
        }

        let returned_lot = {
          ...{ formula: { tasks: [] } },
          ...(typeof this.$store.getters["lot/getLot"] == "object"
            ? this.$store.getters["lot/getLot"]
            : {})
        };
        // console.log("returned_lot:", returned_lot);

        if (!returned_lot.formula.formula_level_tasks) {
          let lot_formula = {
            ...returned_lot.formula,
            ...{
              formula_level_tasks: true,
              tasks: [...returned_lot.formula.tasks, ...formula_type_tasks_mutable]
            }
          };
          returned_lot = { ...returned_lot, ...{ formula: lot_formula } };

          //returned_lot = {...returned_lot, ...{formula: {formula_level_tasks: true, tasks: [...returned_lot.formula.tasks, ...formula_type_tasks_mutable]}} } ;
        } else {
          this.task_mutatable = {
            ...this.task_mutatable,
            ...{ lot: returned_lot }
          };
        }

        return returned_lot;
      },
      set(new_data) {
        let self = this;
        let new_object = { ...this.task_mutatable.lot, ...new_data };
        self.$store.dispatch("lot/setFormulaLot", new_object).then(data => {
          // console.log("setFormulaLot");
        });
      }
    },
    lot_tasks: {
      get() {
        let sub_tasks = this.$store.getters["lot/getLot"].formula.data.tasks;
      }
    },
    date_beyond_use_default: {
      get() {
        let return_value;

        let max_days = this.formula.options.stability_span || 180;
        let today = new Date();

        // make sure to use either today or the date compounded to set the correct BUD - especially important around the end/beginning of month:
        let nearest_date = new Date(
          (this.lot.date_compounded ? this.lot.date_compounded : today)
        ).setDate(
          ( this.lot.date_compounded ? new Date(this.lot.date_compounded).getDate() : today.getDate() )
          + parseInt(max_days)
        );
        return_value = nearest_date

        return return_value
      }
    },
    is_connected_printer: function() {
      let checked_out_printer = this.$store.getters["equipment/get_current_printer"]
      if (checked_out_printer && checked_out_printer.identifier) {
        let printer_config = this.$store.getters["equipment/get_equipment_list"][checked_out_printer.identifier]
        return printer_config
      } else return false
    }

    // lot_diff: {
    //   get() {
    //     let self = this
    //     let current_version_delta = jsondiffpatch.diff(self.lot_as_saved, self.task_mutatable.lot);
    //     return current_version_delta
    //   }
    // },
  },
  methods: {
    moment_utc(date) {
      if (date) {
        return moment.utc(date).local().format('YYYY-MM-DD')
      }
      return ''
    },
    uniqueby(arr, prop) {
      return _.uniqBy(arr, prop)
    },
    handle_visibility_change() {
      if (document.visibilityState === "hidden") {
        console.log('1555 user left page')
        try {
          let session = {
            sessionid: '',
            pageviewid: '',
            date_session_ended: new Date().toJSON(),
            date_session_started: this.date_started,
            user: this.$store.getters["account/profile"],
            logrocket: ''
          }
          let session_url = ''
          LogRocket.getSessionURL(function (sessionURL) {
            session_url = sessionURL
          })
          session.logrocket = session_url
          // Stop and start mouseflow, log the session and pageview ids
          try {
            if (mouseflow) {
              // console.log('mf session test', mouseflow)
              window._mfq.push(["stop"]);
              let pageview_id = ''
              try {
                pageview_id = mouseflow.getPageviewId()
              } catch {
                pageview_id = ''
              }

              session.sessionid = mouseflow.getSessionId()
              session.pageviewid = pageview_id
              mouseflow.start()
            }
          } catch (e) {
            console.error(e)
          }
          this.current_sessions.push(session)
        } catch(e) {console.error(e);}
      } else  {
        console.log('1555 user re-entered page')
        this.date_started = new Date().toJSON()
        // Start a new session
      }
    },
    format_manual_weight() {
      this.balance.weight = this.balance.weight.replace(/[^0-9.-]/g, '')
    },
    check_balance_disallow_recording() {
      if (this.balance && !_.isNil(this.balance.allow_recording)) {
        this.balance_disallow_recording = !this.balance.allow_recording
      } else if (this.balance.allow_manual_measurement) {
        this.balance_disallow_recording = false
      }
      window.setTimeout(() => { this.check_balance_disallow_recording() }, 1500)
    },
    perform_lot_adjustment(lot_data, identifier) {
      let username = this.$store.getters['account/profile'].username
      EventService.pmk_fetch(
        'electric_lab_manual_inventory_reduction',
        'POST',
        { identifier },
        {
          identifier,
          quantity: this.lot_adjust_amount,
          quantity_unit: lot_data.unit_of_measure,
          reason: 'Inventory over committed',
          reason_details: {
            username: username,
            recorded_by: username,
          },
          recorded_by: username,
          username: username,
          reason_type: 'remove_from_inventory',
          remove_from_inventory: false,
        }
      ).then(http_response => {
        console.log('http_response', http_response)
        this.lot._inventory_availability.data.lots[identifier].adjusted = true
        this.$forceUpdate()
        if (http_response.data.success) {
          this.$store.dispatch('notification/add', {
            message: 'Lot inventory has been adjusted',
            type: 'success'
          }, { root: true })

        } else {
          this.$store.dispatch('notification/add', {
            message: 'Failed to adjust lot inventory',
            type: 'error'
          }, { root: true })
        }
      })
    },
    moment(time) {
      return moment(time)
    },
    is_picture(mime_type) {
      const picture_types = [
        'image/gif',
        'image/jpeg',
        'image/png'
      ]
      return picture_types.includes(mime_type)
    },
    calculate_total_required() {
      let total_quantity = 0;
      let map_identifier_to_index = new Map(
        this.lot.formula.ingredients.map((val, idx) => [val.identifier, idx])
      );

      Object.entries(this.lot.formula.ingredients).forEach(([key, ingredient]) => {
          let unit_of_measure = (
            ingredient.formula_unit_of_measure ||
            ingredient.unit_of_measure ||
            ""
          ).toUpperCase();

          if (["C28252", "C28253", "C48155"].includes(unit_of_measure)) {
            total_quantity += this.calculate_quantity_required(ingredient) || 0
          }
      });
      return parseFloat(total_quantity).toFixed(this.decimal_places);
    },
    update_task_quantity() {
      this.form.quantity_edit_mode = false
      //console.log(this.task)
      const previous_quantity = this.task.task.data.lot.quantity
      const quantity_regex = new RegExp('QTY: ' + previous_quantity, 'i')
      let task_title_details = this.task.task.data.title_details
      if (task_title_details) {
        task_title_details = task_title_details.replace(quantity_regex, `QTY: ${this.form.lot.quantity}`)
      } else {
        task_title_details = `QTY: ${this.form.lot.quantity}`
      }

      EventService.update_task_quantity(this.task.task.id, { task_data: {lot: this.form.lot, title_details: task_title_details }})
        .then((http_response) => {
          console.log('1332', http_response.data)
          http_response.data.messages.forEach(notification => {
            this.$store.dispatch('notification/add', notification, { root: true })
            this.task_mutatable = this.task = http_response.data.task
            // must manually reset this.quantity_muliplier
            this.quantity_muliplier = 0
            this.quantity_muliplier =
              this.form.lot.quantity / (this.formula.formula_quantity || 1)
            this.$forceUpdate()
          })
        }).catch(err => console.error('update_task_quantity', err))
    },

    set_dialogQuantityPromptVisible(value) {
      // console.log('dialogQuantityPromptVisible', this.dialogQuantityPromptVisible, value)
      this.dialogQuantityPromptVisible = value
    },
    disconnectEquipment(){
      // to do  disconnect logic
      this.$store.dispatch('equipment/disconnectEquipmentWs')
    },
    remove_document(task, item_identifier) {
      let document_index = task.documents[0].documents.findIndex((obj) => { return obj.identifier === item_identifier})
      task.documents[0].documents.splice(document_index, 1)
    },

    beginVideoCall(icloud_account) {
      let video_call_window = window.open("facetime:" + icloud_account, "_facetime");
      video_call_window.close();
          setTimeout(function() {
            console.log('video_call_window')
                  video_call_window.close();
                  console.log('video_call_window closed')
          }, 500);
          return false;
    },
    request_pharmacist(options) {
      // should get a reason, a station from the store, and a device/icloud_account
      console.log('request_pharmacist', {options})
      let self = this
      let current_user = this.$store.getters["account/profile"]
      let messages_object =
          {
            user_type: 'lab-pharmacist',
            message_data: {
              'title': "RPh requested by " + current_user.name,
              // message: value,
              'type': 'warning',
              'alert_duration': 7500,
              'icon': 'fal fa-bells',
              'display_alert': 1,
              'task_id': self.task.task.id,
              'icloud_account': self.$store.getters["equipment/get_current_device"]['icloud_account'],
              'station': self.$store.getters["equipment/get_current_station"]['description']
            }
          }


      this.$prompt("Please enter a message", "Request Pharmacist", {
          confirmButtonText: "Request",
          cancelButtonText: "Cancel",
          inputErrorMessage: "Invalid code",
        })
          .then(({ value }) => {

            messages_object.message_data = {...messages_object.message_data, ...{ message: value }}
            console.log({messages_object})
            EventService.communications_internal_message_send_to_user_group(messages_object)
            .then(
              function(result) {
                if (result.data.success && result.data.messages) {
                  // if logging in manually: const user = this.$store.getters["account/profile"]
                }
              }
            )
          })

    },
    in_range_ingredient_count_method() {
        let count = this.formula.ingredients.filter(d => d.is_in_range == true).length;
        return count;
    },

    cancelBud() {
      let self = this;
      self.budChangeVisible = false;
      self.BudToOverride = null;
      this.lot.beyond_use_date_override = null;

      self.calculated_beyond_use_date();
    },
    ChangeBud() {
      let self = this;
      self.budChangeVisible = false;
      this.lot.beyond_use_date_override = self.BudToOverride;

      self.calculated_beyond_use_date(true);
    },
    go_to_task(task_id) {
      // console.log("goto", task_id);
      if (task_id) {
        this.$router.push({ name: "task-view", params: { id: task_id } });
      }
      // console.log("goto-end", task_id);
    },
    go_to_worksheet() {
      if (true) {
        this.$router.push({
          name: "ingredient-inventory-item-worksheet",
          params: { identifier: this.lot.identifier }
        });
      }
    },
    view_mode() {
      let mode =
        this.lot.status == "pending" || this.lot.status == "rework"
          ? "pending"
          : "readonly";
      return mode;
    },
    view_mode_permissions() {
      const current_user = this.$store.getters["account/profile"];
      // console.log("this.lot.author", this.lot.author);
      // console.log(
      //   "this.task_mutatable.lot.author",
      //   this.task_mutatable.lot.author
      // );
      // console.log("current_user", current_user);
      if (this.lot.author == current_user.name) {
        return false;
      } else if (current_user.user_type !== 'lab-pharmacist') {
        return false
      } else {
        return true;
      }
    },
    switch_ingredient_mode() {
      // console.log(365, this.form.ingredient_scan_mode);
      if (this.form.ingredient_scan_mode) {
        this.$nextTick(() => this.$refs.form_scan_code.focus());
        // document.addEventListener('scanned-code', e => {
        //   console.log('scanned-code', e)
        // })
      }
    },
    format_float(val) {
      if (parseFloat(val)) {
        this.lot.yield = parseFloat(val)
        if (val.slice(-1) === '.') {
          this.lot.yield += '.'
        }
      } else {
        this.lot.yield = ''
      }
    },
    isChrome() {
      var isChromium = window.chrome;
      var winNav = window.navigator;
      var vendorName = winNav.vendor;
      var isOpera = typeof window.opr !== "undefined";
      var isIEedge = winNav.userAgent.indexOf("Edge") > -1;
      var isIOSChrome = winNav.userAgent.match("CriOS");

      if (isIOSChrome) {
         return true
      } else if(
        isChromium !== null &&
        typeof isChromium !== "undefined" &&
        vendorName === "Google Inc." &&
        isOpera === false &&
        isIEedge === false
      ) {
         return true
      } else {
         return false
      }
    },
    activity_factor_utilize_scan_code() {
      // this is for ingredients (or formulas as ingredients)
      let self = this;
      if (this.form.activity_factor_scan_code.length > 21) {
        let decompressed_scan = uuid_base64.decode(this.form.activity_factor_scan_code)

        EventService.pmk_fetch(
          "electric_lab_get_inventory_item",
          "GET",
          { identifier: decompressed_scan },
        ).then(http_response => {
          let data = http_response.data


          let item_selected = self.formula.ingredients.filter(ingredient => {
            return ingredient.identifier === data.data.ingredient_identifier
          })[0]
          //console.log('1657 item_selected', item_selected)
          //console.log('1657 scanned lot', data.data)

          //Check if lot is valid
          if (!data.data.identifier) {
            self.form.activity_factor_scan_code = ""
            self.$refs.activity_factor_scan_code.focus()
            let error_notification = {
              type: "error",
              icon: "fal fa-hand-paper",
              message:
                "The scanned ingredient lot is invalid"
            }

            this.$store.dispatch("notification/add", error_notification, {
              root: true
            })
            // reject("The scanned ingredient does not belong to this formula");
            return; // must return early here
          }

          // Check if formula uses ingredient
          if (
            !self.lot.formula.ingredients.some(function(tmp_ingredient) {
              // console.log('tmp_ingredient.identifier: ', tmp_ingredient.identifier)
              return (
                tmp_ingredient.identifier == data.data.ingredient_identifier
              )
            })
          ) {
            // console.log('invalid ingredient: ', data)
            // console.log('data.data.ingredient_identifier: ', data.data.ingredient_identifier)
            // console.log({ingredients: self.lot.formula.ingredients})

            self.form.activity_factor_scan_code = ""
            self.$refs.activity_factor_scan_code.focus()
            let error_notification = {
              type: "error",
              icon: "fal fa-hand-paper",
              message:
                "The scanned ingredient does not belong to this formula"
            }

            this.$store.dispatch("notification/add", error_notification, {
              root: true
            })
            // reject("The scanned ingredient does not belong to this formula");
            return; // must return early here
          }

          if (Object.values(this.lot.lots_allowed).filter((lot) => lot.ingredient_identifier === data.data.ingredient_identifier).length) {
            console.log('2202')
            self.form.activity_factor_scan_code = ""
            self.$refs.activity_factor_scan_code.focus()
            let other_lot = Object.values(this.lot.lots_allowed).filter((lot) => lot.ingredient_identifier === data.data.ingredient_identifier)[0]
            console.log('other lot', other_lot)
            if (
              (data.data.ingredient.has_activity_factor)
              || (self.has_pack_stat_ingredient)
            ) {
              if (data.data.ingredient.has_pack_stat && data.data.data.pack_stat[self.lot.formula.pack_stat_size] !== other_lot.data.pack_stat[self.lot.formula.pack_stat_size]) {
                let error_notification = {
                  type: "error",
                  icon: "fal fa-hand-paper",
                  message:
                    "Pack stat does not match other ingredient lot"
                }

                this.$store.dispatch("notification/add", error_notification, {
                  root: true
                })
                // reject("The scanned ingredient does not belong to this formula");
                return; // must return early here
              } else if (data.data.data.activity_factor != other_lot.data.activity_factor) {
                let error_notification = {
                  type: "error",
                  icon: "fal fa-hand-paper",
                  message:
                    "Activity factor does not match other ingredient lot"
                }

                this.$store.dispatch("notification/add", error_notification, {
                  root: true
                })
                // reject("The scanned ingredient does not belong to this formula");
                return; // must return early here
              } else if (!data.data.ingredient.has_pack_stat && !data.data.data.activity_factor) {
                let error_notification = {
                  type: "error",
                  icon: "fal fa-hand-paper",
                  message:
                    "Lot already scanned for this ingredient"
                }

                this.$store.dispatch("notification/add", error_notification, {
                  root: true
                })
                // reject("The scanned ingredient does not belong to this formula");
                return; // must return early here
              }
            }
          }

          // check beyond_use_date:
          let ingredient_lot_beyond_use_date = new Date(data.data.inventory.beyond_use_date )
          if (ingredient_lot_beyond_use_date < new Date() ) {
            self.form.activity_factor_scan_code = ""
            self.$refs.activity_factor_scan_code.focus()
            let BUD_message = "The scanned lot is expired and can not be used.  BUD: " + ingredient_lot_beyond_use_date.toLocaleDateString()
            let error_notification = {
              type: "error",
              icon: "fal fa-hand-paper",
              message: BUD_message

            }
            this.$store.dispatch("notification/add", error_notification, {
              root: true
            })
            // reject(BUD_message);
            return; // must return early here
          }

          // Check remaining inventory
          if (data.data.current_inventory) {
            if (item_selected) {
              if (item_selected.quantity_needed > data.data.current_inventory.quantity_remaining ||
                (data.data.ingredient.has_activity_factor && (data.data.current_inventory.quantity_remaining < (item_selected.quantity_needed / (data.data.data.activity_factor / 100))))
              ) {
                self.form.activity_factor_scan_code = "";
                self.$refs.activity_factor_scan_code.focus();
                let error_notification = {
                  type: "error",
                  icon: "fal fa-hand-paper",
                  message:
                    "The scanned lot does not have enough inventory remaining"
                };

                this.$store.dispatch("notification/add", error_notification, {
                  root: true
                });
                if (data.data.ingredient.has_activity_factor || (self.has_pack_stat_ingredient && data.data.ingredient.has_pack_stat)) {
                  return; // must return early here
                }
              }
            }
          }

          if (data.data.ingredient.has_activity_factor && !data.data.data.activity_factor) {
            self.form.activity_factor_scan_code = "";
            self.$refs.activity_factor_scan_code.focus();
            let error_notification = {
              type: "error",
              icon: "fal fa-hand-paper",
              message:
                "The scanned lot does not have an activity factor set"
            };

            this.$store.dispatch("notification/add", error_notification, {
              root: true
            });
            return; // must return early here
          }

          if (self.has_pack_stat_ingredient && data.data.ingredient.has_pack_stat && !(data.data.data.pack_stat && data.data.data.pack_stat[self.lot.formula.pack_stat_size])) {
            self.form.activity_factor_scan_code = "";
            self.$refs.activity_factor_scan_code.focus();
            let error_notification = {
              type: "error",
              icon: "fal fa-hand-paper",
              message:
                "The scanned lot does not have a pack stat set"
            };

            this.$store.dispatch("notification/add", error_notification, {
              root: true
            });
            return; // must return early here
          }

          if (item_selected) {
            item_selected.checked = true
            if (!this.lot.lots_allowed) {
              this.lot.lots_allowed = {}
            }
            self.lot.lots_allowed[data.data.identifier] = data.data
            if (_.uniqBy(Object.values(self.lot.lots_allowed), 'ingredient_identifier').length === self.formula.ingredients.length) {
              self.all_ingredients_scanned_activity_factor = true
            }
          }

          self.form.activity_factor_scan_code = ""
          self.$refs.activity_factor_scan_code.focus()

        })
      }
    },
    utilize_scan_code: _.throttle(function(){
      // this is for ingredients (or formulas as ingredients)
      let self = this
      if (this.form.scan_code.includes('^^-')) {
        if (this.form.scan_code.includes('_$$')) {
          this.form.scan_code = ''
        }
        return
      }
      if (this.form.scan_code.length > 21) {
        let decompressed_scan = uuid_base64.decode(this.form.scan_code);

        self.$store
          .dispatch("lot/getIngredientInventory", decompressed_scan)
          .then(data => {
            if (data == undefined) {
              data = self.$store.getters["lot/getCurrentIngredientLot"];
              // console.log("416 data from getter", data);
            }

            // If this formula has an activity factor ingredient, make sure that the scanned ingredient is in lots_allowed
            if ((self.has_activity_factor_ingredient || self.has_pack_stat_ingredient) && (!Object.keys(self.lot.lots_allowed).includes(data.data.identifier))) {
              self.form.scan_code = "";
              self.$refs.form_scan_code.focus();
              let lot_message = "The scanned lot has not been pre-selected. Formulas with an ingredient that has an activity factor or pack stat must have lots scanned in advance."
              let error_notification = {
                type: "error",
                icon: "fal fa-hand-paper",
                message: lot_message

              };
              if (self.lot.ingredients_used[data.data.ingredient_identifier]) {
                console.error('removing lot due to not in lots_allowed: ', data.data.ingredient_identifier )
                self.lot.ingredients_used[data.data.ingredient_identifier].lots[
                  data.data.identifier
                ] = {}
                delete self.lot.ingredients_used[data.data.ingredient_identifier].lots[
                  data.data.identifier
                ]
                this.$store.dispatch('lot/reset_current_ingredient_lot')
              }
              this.$store.dispatch("notification/add", error_notification, {
                root: true
              });
              // reject(BUD_message);
              return; // must return early here
            }
            // console.log("lot:", self.lot);
            // console.log("self.lot.ingredients_used", self.lot.ingredients_used);

            //Check if lot is valid
            if (!data.data.identifier) {
              self.form.scan_code = ""
              self.$refs.form_scan_code.focus();
              let error_notification = {
                type: "error",
                icon: "fal fa-hand-paper",
                message:
                  "The scanned ingredient lot is invalid"
              }

              this.$store.dispatch("notification/add", error_notification, {
                root: true
              })
              // reject("The scanned ingredient does not belong to this formula");
              return; // must return early here
            }

            //see if ingredient is valid in formula:
            if (
              !self.lot.formula.ingredients.some(function(tmp_ingredient) {
                // console.log('tmp_ingredient.identifier: ', tmp_ingredient.identifier)
                return (
                  tmp_ingredient.identifier == data.data.ingredient_identifier
                );
              })
            ) {
              // console.log('invalid ingredient: ', data)
              // console.log('data.data.ingredient_identifier: ', data.data.ingredient_identifier)
              // console.log({ingredients: self.lot.formula.ingredients})

              self.form.scan_code = "";
              self.$refs.form_scan_code.focus();
              let error_notification = {
                type: "error",
                icon: "fal fa-hand-paper",
                message:
                  "The scanned ingredient does not belong to this formula"
              };

              this.$store.dispatch("notification/add", error_notification, {
                root: true
              });

              // we want to remove the record of this wrong ingredient from the lot record so it does not affect BUD, etc:
              if (self.lot.ingredients_used[data.data.ingredient_identifier]) {
                console.error('removing lot due to not in formula: ', data.data.identifier )
                self.$store.dispatch("lot/reset_current_ingredient_lot");
                delete self.lot.ingredients_used[data.data.ingredient_identifier].lots[
                  data.data.identifier
                ]
              }
              this.form.scan_code = ''
              // reject("The scanned ingredient does not belong to this formula");
              return; // must return early here
            }

            // check beyond_use_date:
            let ingredient_lot_beyond_use_date = new Date(data.data.inventory.beyond_use_date )
            if ( ingredient_lot_beyond_use_date < new Date() ) {
              self.form.scan_code = "";
              self.$refs.form_scan_code.focus();
              let BUD_message = "The scanned lot is expired and can not be used.  BUD: " + ingredient_lot_beyond_use_date.toLocaleDateString()
              let error_notification = {
                type: "error",
                icon: "fal fa-hand-paper",
                message: BUD_message

              };
              if (self.lot.ingredients_used[data.data.ingredient_identifier]) {
                console.error('removing lot due to BUD: ', data.data.identifier )
                self.$store.dispatch("lot/reset_current_ingredient_lot");
                delete self.lot.ingredients_used[data.data.ingredient_identifier].lots[
                  data.data.identifier
                ]
              }
              this.$store.dispatch("notification/add", error_notification, {
                root: true
              });
              // reject(BUD_message);
              this.form.scan_code = ''
              return; // must return early here
            }


            // IMPORTANT NOTE: nearly identical code exists in store/modules/lot.js.  Not sure it should be here too...
            // !!! TODO, this is a bug, it shoudl be data.data.ingredient_identifier:
            // !!! Maybe the other code works just as well
            if (self.lot.ingredients_used[data.ingredient_identifier]) {
              if (
                self.lot.ingredients_used[data.ingredient_identifier].lots[
                  data.identifier
                ]
              ) {
                console.log(`just set active ingredient`);
              } else {
                self.lot.ingredients_used[data.ingredient_identifier].lots[
                  data.identifier
                ] = data;
              }
            } else {
              let lots = {};
              if (data.identifier) {
                // hack to avoid undefined
                lots[data.identifier] = data;
                // TODO ~ could use Object.defineProperty
                let new_data = {};
                new_data[data.ingredient_identifier] = { lots: lots };
                // [TODO?] should probably be:
                self.lot.ingredients_used = {
                  ...self.lot.ingredients_used,
                  ...new_data
                };
                // was:
                // self.lot.ingredients_used[data.ingredient_identifier] = {
                //   lots: lots
                // };
              }
            }
            self.form.scan_code = "";
            self.$refs.form_scan_code.focus();
            //scroll to activated el
            var ingredient_list_container = document.getElementsByClassName('ingredient-list')[0]
            var active_ingredient = document.getElementsByClassName("current-ingredient-lot")[0];
            if(active_ingredient){
              var topPos = active_ingredient.offsetTop
              ingredient_list_container.scrollTop =active_ingredient.closest('.item-wrapper').offsetTop
            }

          });
      }
    }, 1000),
    open_label() {
      this.form.label_loading = true
      if (this.lot.inventory && this.lot.inventory.label_list && this.lot.inventory.label_list.length > 1) {
        const tz_offset = new Date().getTimezoneOffset()
        const tz_name = Intl.DateTimeFormat().resolvedOptions().timeZone
        const args = {
          return_result: 1,
          render_and_delete: 0,
          identifier: this.lot.identifier,
          timezone_offset: tz_offset,
          timezone_name: tz_name,

        }
        if (_.get(this.lab_config, 'config.label.dimensions', false)) {
          args.dimensions = 'dimensions_' + _.get(this.lab_config, 'config.label.dimensions')
        }
        Common.open_label_set(args).then( () => this.form.label_loading = false)
      } else {
        let label_url = Common.get_lot_label_url(this.lot, this.lot["formula"], this.$store.getters['formula/get_lab_config'])
          .then(() =>  this.form.label_loading = false)
        return label_url;
      }
    },
    unit_dose_label(type) {
      let label_url = `/api/pharmetika/v5/electric_lab/inventory/id/${this.lot.identifier}/unit_dose_label.`
      if (type === 'print300') {
        label_url += 'zpl?dpi=300&quantity=' + this.label_count
        try {
          this.selected_device.sendFile(label_url, undefined, undefined)
        } catch (e) {
          console.log('error:', e)
        }
        return
      }
      if (type === 'print203') {
        label_url += 'zpl?dpi=203&quantity=' + this.label_count
        try {
          this.selected_device.sendFile(label_url, undefined, undefined)
        } catch (e) {
          console.log('error:', e)
        }
        return
      }
      if (type === 'print_equipment') {
        let printer_dpi = this.is_connected_printer.printer_dpi
        label_url += `zpl?dpi=${printer_dpi}&quantity=` + this.label_count
        try {
          EquipmentService.send_to_equipment_printer({url: label_url})
        } catch (e) {
          console.log('error:', e)
        }
        return
      }

      label_url += type

      let label_win = window.open(label_url, 'label')
      label_win.focus()
      this.label_count = 1
    },
    calculate_quantity_required(ingredient) {

      const args = {
        ingredient,
        quantity_muliplier: this.quantity_muliplier,
        activity_factor_difference: this.activity_factor_difference,
        pack_stat_fill: this.pack_stat_fill,
        has_activity_factor_ingredient: this.has_activity_factor_ingredient,
        lot: this.lot,
        task_mutatable: this.task_mutatable,
        formula: this.formula,
        has_pack_stat_ingredient: this.has_pack_stat_ingredient
      }

      const results = elabmath.calculate_quantity_required(args)

      this.quantity_muliplier = results.quantity_muliplier
      this.activity_factor_difference = results.activity_factor_difference
      this.pack_stat_fill = results.pack_stat_fill
      this.lot.pack_stat_fill = results.pack_stat_fill
      this.lot.formula.activity_factor_modifying_ingredient = results.lot.formula.activity_factor_modifying_ingredient
      if (results.messages.length) {
        console.log('messages', results.messages)
      }
      return results.quantity
    },
    calculate_total() {
      let total_quantity = 0;
      let map_identifier_to_index = new Map(
        this.lot.formula.ingredients.map((val, idx) => [val.identifier, idx])
      );

      Object.entries(this.lot.ingredients_used).forEach(([key, ingredient]) => {
        // ingredients_used can contain scans for incorrect ingredients so look at
        // look at the formula map instead
        if (map_identifier_to_index.has(key)) {
          let amount = this.calculate_ingredient_used_quantity({
            identifier: key
          });
          // TODO: this map could get moved into this.lot.formula.ingredient_map as it may be useful elsewhere

          let unit_of_measure = (
            this.lot.formula.ingredients[map_identifier_to_index.get(key)]
              .formula_unit_of_measure ||
            this.lot.formula.ingredients[map_identifier_to_index.get(key)]
              .unit_of_measure ||
            ""
          ).toUpperCase();

          if (["C28252", "C28253", "C48155"].includes(unit_of_measure)) {
            total_quantity += parseFloat(amount) || 0;
          }
        }
      });
      return parseFloat(total_quantity).toFixed(this.decimal_places);
    },

    calculated_beyond_use_date() {
      let return_value;
      if (this.lot.beyond_use_date_override) {
        return_value = new Date(this.lot.beyond_use_date_override);
      } else {
        let max_days = this.formula.options.stability_span || 180;
        let today = new Date();

        // make sure to use either today or the date compounded to set the correct BUD - especially important around the end/beginning of month:
        let nearest_date = new Date(
          (this.lot.date_compounded ? this.lot.date_compounded : today)
        ).setDate(
          ( this.lot.date_compounded ? new Date(this.lot.date_compounded).getDate() : today.getDate() )
          + parseInt(max_days)
        );
        // function addDays(date, days) {
        //   let result = new Date(date);
        //   result.setDate(result.getDate() + parseInt(days))
        //   return result;
        // }

      let formula_ingredient_list = Common.mapArrayToObject(this.formula.ingredients, "identifier")


          // console.log('nearest_date', nearest_date)
          // console.log('this.lot.date_compounded', this.lot.date_compounded)
          // console.log('this.lot.date_compounded -> DATE', new Date(this.lot.date_compounded).getDate())
        // for (let ingredient of this.lot.ingredients_used) {
          // console.log(1354, this.formula)
          // console.log(568, this.lot.ingredients_used);
        for (const [ingredient_identifier, ingredient] of Object.entries(
          this.lot.ingredients_used
        )) {
          // skip any ingredients that were scanned, but not part of the formula
          if (! formula_ingredient_list[ingredient_identifier]) { continue }

          if (ingredient.lots) {
            for (const [lot_identifier, ingredient_lot] of Object.entries(
              ingredient.lots
            )) {
               console.log(575, lot_identifier, ingredient_lot);
              // re-enable for debug:
                // console.log(
                //   "ingredient_lot.data.inventory.beyond_use_date, nearest_date"
                // );
                // console.log(
                //   ingredient_lot.data.inventory.beyond_use_date,
                //   nearest_date
                // );

              if (
                ingredient_lot.data.inventory.beyond_use_date && 
                nearest_date >
                new Date(moment.utc(ingredient_lot.data.inventory.beyond_use_date).local())
                && !ingredient_lot.ingredient.is_consumable
              ) {
                 console.log(
                   "changing nearest_date: ",
                   new Date(moment.utc(ingredient_lot.data.inventory.beyond_use_date).local())
                 );
                nearest_date = new Date(
                  moment.utc(ingredient_lot.data.inventory.beyond_use_date).local()
                );
              }
            }
          }
        }
        return_value = new Date(nearest_date);
      }
      // console.log({ l1160: return_value });
      if (
        this.lot.beyond_use_date_override &&
        this.BudToOverride != null &&
        this.BudToOverride != ""
      ) {
        if (this.BudToOverride != return_value) {
          // console.log({ BUD: this.BudToOverride });
          return_value =
            new Date(this.lot.beyond_use_date_override) ||
            new Date(return_value);
          // console.log({ l1165: return_value });
        }
      }
      // console.log({ return_value });
      return moment.utc(return_value).local()
    },
    calculate_ingredient_used_quantity(ingredient_data) {
      let total_quantity = 0;
      if (this.lot.ingredients_used[ingredient_data.identifier] && this.lot.ingredients_used[ingredient_data.identifier].lots) {
        // for (let ingredient_lot of this.lot.ingredients_used[
        //   ingredient_data.identifier
        // ].lots) {

        for (const [lot_identifier, ingredient_lot] of Object.entries(
          this.lot.ingredients_used[ingredient_data.identifier].lots
        )) {
          total_quantity += parseFloat(ingredient_lot.quantity) || 0;
        }
      }
      return total_quantity;
    },
    calculate_ingredient_in_range(ingredient) {
      /*
      if (ingredient.requires_measurement_check) {
        ingredient.is_in_range = ingredient.measurement_checked;
        return ingredient.is_in_range;
      } else
      */
      if (ingredient.quantity_sufficient_indicator) {
        let quantity_current_qs = this.calculate_ingredient_used_quantity({
          identifier: ingredient.identifier
        });
        if (['C48542', 'C28254'].includes(ingredient.formula_unit_of_measure)) {
          let quantity_required = this.calculate_quantity_required(ingredient);
          ingredient.is_in_range = quantity_required >= quantity_current_qs && quantity_current_qs > 0;
        } else {
          ingredient.is_in_range = quantity_current_qs > 0;
        }
        return ingredient.is_in_range;
      } else {
        function inRange(value, min, max) {
          return (value - min) * (value - max) <= 0;
        }
        let quantity_current = this.calculate_ingredient_used_quantity({
          identifier: ingredient.identifier
        });
        let quantity_current_with_weighing = this.calculate_ingredient_current_quantity(
          ingredient
        );
        let quantity_required = this.calculate_quantity_required(ingredient);
        let range_allowance =
          parseFloat(
            "undefined" !=
              typeof this.lot.ingredients_used[ingredient.identifier] &&
              this.lot.ingredients_used[ingredient.identifier]
                .balance_variance_percentage
              ? this.lot.ingredients_used[ingredient.identifier]
                  .balance_variance_percentage
              : this.$store.getters["equipment/get_balance_state"]
                  .balance_variance_percentage || 0
          ) / 100;
        if (ingredient.unit_of_measure === 'C28254' && this.lab_config.lot && this.lab_config.lot.liquid_measurement_variance) {
          range_allowance = this.lab_config.lot.liquid_measurement_variance / 100
        }
        let is_in_range = inRange(
          ingredient.identifier == this.current_ingredient.ingredient_identifier
            ? quantity_current_with_weighing
            : quantity_current,
          quantity_required - quantity_required * range_allowance,
          quantity_required + quantity_required * range_allowance
        );
        ingredient.is_in_range = is_in_range;
        ingredient.quantity_needed =
          parseFloat(quantity_required) - parseFloat(quantity_current);
        return is_in_range;
      }
    },
    calculate_ingredient_is_in_range_as_text(ingredient) {
      if (['C48542', 'C28254'].includes(ingredient.formula_unit_of_measure) && ingredient.requires_measurement_check && !ingredient.measurement_checked) {
        return 'warning'
      }

      if (ingredient.is_in_range) {
        if (ingredient.requires_measurement_check && !ingredient.measurement_checked) {
          return 'warning'
        } else {
          return 'success'
        }
      } else if (parseFloat(this.calculate_ingredient_current_quantity(ingredient)) < parseFloat(this.calculate_quantity_required(ingredient))) {
        return 'text'
      } else {
        return 'exception'
      }
    },
    calculate_ingredient_percent_complete(ingredient) {
      if (parseFloat(ingredient.quantity) > 0) {
        let required_qty = parseFloat(
          this.calculate_quantity_required(ingredient)
        );

        let sum_qty = this.calculate_ingredient_current_quantity(ingredient);

        let percentage_qty = 100 * (sum_qty / required_qty);

        return parseInt(percentage_qty);
      } else {
        return 0;
      }
    },
    calculate_ingredient_current_quantity(ingredient) {
      let current_qty = parseFloat(
        this.calculate_ingredient_used_quantity({
          identifier: ingredient.identifier
        }) || 0
      );

      let balance_qty =
        ingredient.identifier == this.current_ingredient.ingredient_identifier
          ? parseFloat(this.balance.weight || 0)
          : 0;

      let sum_qty = parseFloat(current_qty)

      if (ingredient.formula_unit_of_measure === 'C48155') {
        sum_qty += parseFloat(balance_qty);
      }

      return sum_qty;
    },
    record_ingredient_weight(measurement = {}) {
      // console.log("record_ingredient_weight", measurement);
      this.$store.dispatch("lot/record_ingredient_weight", measurement);
      if (parseFloat(this.form.manual_measurement) == this.form.manual_measurement) {
        this.form.manual_measurement = undefined;
      }
      this.$refs.form_scan_code.focus()
    },
    remove_log_measurement(measurement) {
      // console.log("remove_log_measurement", measurement);
      let self = this;
      this.$confirm(
        "This will remove the weight measured, Continue?",
        "Confirm",
        {
          distinguishCancelAndClose: true,
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel"
        }
      )
        .then(() => {
          if (measurement.log.archived == true) {
            return;
          }
          // console.log("this.lot.ingredients_used", this.lot.ingredients_used);

          let new_current_quantity =
            self.lot.ingredients_used[measurement.ingredient_identifier].lots[
              measurement.log.lot_identifier
            ].quantity - measurement.log.weight;

          // mark archived
          measurement.log.archived = true;
          let new_data = {
            ingredient_identifier: measurement.ingredient_identifier,
            lot_identifier: measurement.log.lot_identifier,
            quantity: new_current_quantity
          };
          // console.log("remove_log: dispatching", `${new Date().getUTCSeconds()} : ${new Date().getUTCMilliseconds()}`);

          self.$store.dispatch("lot/set_ingredient_weight", new_data);
        })
        .catch(action => {
          // add a message
        });

    },
    log_measurement_reviewer(ingredientIndex, ingredient, checked = true) {
      let self = this;
      console.log("log_measurement_reviewer", {
        ingredientIndex: ingredientIndex,
        ingredient: ingredient,
        arg: checked,
        lot: this.task_mutatable.lot
      });
      if (checked) {

        const current_user = this.$store.getters["account/profile"]
        if (current_user.user_type == 'lab-pharmacist' && current_user.name != this.lot.author ) {
          // if logging in manually: const user = this.$store.getters["account/profile"]
          const user = current_user
          ingredient.measurement_checked = true;
          this.task_mutatable.lot.formula.ingredients[ingredientIndex].measurement_checked = true

          ingredient.is_in_range = true;
          this.task_mutatable.lot.formula.ingredients[ingredientIndex].is_in_range = true
          Vue.set(ingredient, "measurement_checked", true);
          Vue.set(ingredient, "is_in_range", true);
          // set ingredients array with VUe.set to fire change event
          Vue.set(self.formula.ingredients, ingredientIndex, ingredient);

          ingredient.measurement_checked_by = user;
          ingredient.date_measurement_checked = new Date().toJSON();
          // this.task_mutatable.lot.formula.ingredients[ingredientIndex].measurement_checked = true
          // this.task_mutatable.lot.formula.ingredients[ingredientIndex].is_in_range = true

          this.task_mutatable.lot.formula.ingredients[ingredientIndex].measurement_checked_by = user;
          this.task_mutatable.lot.formula.ingredients[ingredientIndex].date_measurement_checked = new Date().toJSON();
        } else {

        this.$prompt("Please scan checking pharmacist barcode", "Scan", {
          confirmButtonText: "Check",
          cancelButtonText: "Cancel",
          inputErrorMessage: "Invalid code",
          inputPattern: /^[A-Fa-f0-9]{64}$/
        })
          .then(({ value }) => {
            EventService.fetchAuthenticationForBarcode({ key: value }).then(
              function(result) {
                if (result.data.success && result.data.data.user.user_type == 'lab-pharmacist') {
                  // if logging in manually: const user = this.$store.getters["account/profile"]
                  const user = result.data.data.user;
                  ingredient.measurement_checked = true;
                  ingredient.is_in_range = true;
                  Vue.set(ingredient, "measurement_checked", true);
                  Vue.set(ingredient, "is_in_range", true);
                  // set ingredients array with VUe.set to fire change event
                  Vue.set(self.formula.ingredients, ingredientIndex, ingredient);

                  ingredient.measurement_checked_by = user;
                  ingredient.date_measurement_checked = new Date().toJSON();

                  this.task_mutatable.lot.formula.ingredients[ingredientIndex].measurement_checked = true
                  this.task_mutatable.lot.formula.ingredients[ingredientIndex].is_in_range = true

                  this.task_mutatable.lot.formula.ingredients[ingredientIndex].measurement_checked_by = user;
                  this.task_mutatable.lot.formula.ingredients[ingredientIndex].date_measurement_checked = new Date().toJSON();

                  self.$message({
                    type: "success",
                    message: "Measurement check recorded"
                  });
                } else {
                  self.$message({
                    type: "error",
                    message: "Invalid Code"
                  });
                  Vue.set(ingredient, "measurement_checked", false);
                  Vue.set(ingredient, "is_in_range", false);
                  ingredient.measurement_checked = false;
                  ingredient.is_in_range = false;

                  ingredient.measurement_checked_by = null;
                  ingredient.date_measurement_checked = null;

                  self.task_mutatable.lot.formula.ingredients[ingredientIndex].measurement_checked = false
                  self.task_mutatable.lot.formula.ingredients[ingredientIndex].is_in_range = false

                  self.task_mutatable.lot.formula.ingredients[ingredientIndex].measurement_checked_by = null
                  self.task_mutatable.lot.formula.ingredients[ingredientIndex].date_measurement_checked = null


                  // set ingredients array with VUe.set to fire change event
                  Vue.set(self.formula.ingredients, ingredientIndex, ingredient);

                }
              }
            );
          })
          .catch(() => {
            Vue.set(ingredient, "measurement_checked", false);
            ingredient.measurement_checked = false;
            ingredient.measurement_checked_by = null;
            ingredient.date_measurement_checked = null;
            Vue.set(ingredient, "is_in_range", false);
            ingredient.is_in_range = false;

            this.task_mutatable.lot.formula.ingredients[ingredientIndex].measurement_checked = false
            this.task_mutatable.lot.formula.ingredients[ingredientIndex].is_in_range = false

            this.task_mutatable.lot.formula.ingredients[ingredientIndex].measurement_checked_by = null
            this.task_mutatable.lot.formula.ingredients[ingredientIndex].date_measurement_checked = null

            // set ingredients array with VUe.set to fire change event
            Vue.set(self.formula.ingredients, ingredientIndex, ingredient);
            self.$message({
              type: "info",
              message: "Check canceled"
            });
          });
        } // esle if not a lab-pharmacist and NOT the original performer
      } else {
        Vue.set(ingredient, "measurement_checked", false);
        Vue.set(ingredient, "is_in_range", false);
        ingredient.measurement_checked = false;
        ingredient.is_in_range = false;
        ingredient.measurement_checked_by = null;
        ingredient.date_measurement_checked = null;

        this.task_mutatable.lot.formula.ingredients[ingredientIndex].measurement_checked = false
        this.task_mutatable.lot.formula.ingredients[ingredientIndex].is_in_range = false

        this.task_mutatable.lot.formula.ingredients[ingredientIndex].measurement_checked_by = null
        this.task_mutatable.lot.formula.ingredients[ingredientIndex].date_measurement_checked = null

        // set ingredients array with VUe.set to fire change event
        Vue.set(self.formula.ingredients, ingredientIndex, ingredient);
      }
    },
    set_subtask_status(lot_task, lot_task_index) {
      let completed = lot_task.complete;
      let task = { version: this.task_mutatable.lot.version };
      // console.log("set_subtask_status", lot_task, completed);

      if (completed) {
        const user = this.$store.getters["account/profile"];

        task.date_completed = new Date().toJSON();
        task.user = user;
        task.status = "complete";
        task.complete = true;
      } else {
        task.date_completed = null;
        task.user = null;
        task.status = "new";
        task.complete = false;
      }
      lot_task = { ...lot_task, ...task };
      let new_lot_data = this.lot;
      new_lot_data.formula.tasks[lot_task_index] = lot_task;
      this.lot = new_lot_data;
    },
    set_subtask_weights(lot_task, lot_task_index, weight_to_record) {
      let task = { ...lot_task };
      task.version = this.task_mutatable.lot.version
      if (
        typeof weight_to_record === "undefined" ||
        weight_to_record === null
      ) {
        weight_to_record = this.subtasks[lot_task_index].new_weight;
      }
      // if (! this.subtasks[lot_task_index] ) { this.subtasks[lot_task_index] = {} }
      task.weights.push(weight_to_record);
      this.subtasks[lot_task_index].new_weight = ""; // clear
      lot_task = { ...lot_task, ...task };
      let new_lot_data = { ...this.lot };
      new_lot_data.formula.tasks[lot_task_index] = lot_task;
      this.lot = new_lot_data;
    },
    set_subtask_measurement(lot_task, lot_task_index, measurement_to_record) {
      let task = { ...lot_task };
      task.version = this.task_mutatable.lot.version
      if (
        typeof measurement_to_record === "undefined" ||
        measurement_to_record === null
      ) {
        measurement_to_record = this.subtasks[lot_task_index].new_measurement;
      }
      // if (! this.subtasks[lot_task_index] ) { this.subtasks[lot_task_index] = {} }
      task.measurements.push(measurement_to_record);
      this.subtasks[lot_task_index].new_measurement = {type: "", value: "" }; // clear
      lot_task = { ...lot_task, ...task };
      let new_lot_data = { ...this.lot };
      new_lot_data.formula.tasks[lot_task_index] = lot_task;
      this.lot = new_lot_data;
    },

    set_subtask_time_measurement(lot_task, lot_task_index, time_measurement_index) {
      let task = { ...lot_task };
      task.version = this.task_mutatable.lot.version
      if (
        typeof time_measurement_index === "undefined" ||
        time_measurement_index === null
      ) {
        time_measurement_index = 0
      }
      const measurement_to_record = new Date().toJSON()
      if (! task.time_measurements) {
        task.time_measurements = []
      }
      task.time_measurements[time_measurement_index] = measurement_to_record;

      lot_task = { ...lot_task, ...task };
      let new_lot_data = { ...this.lot };
      new_lot_data.formula.tasks[lot_task_index] = lot_task;
      this.lot = new_lot_data;
    },
    format_time_quantity(times) {
      const begin_time = new moment(times[0])
      const end_time = new moment(times[1])
      const duration = moment.duration(begin_time.diff(end_time))
      return duration.humanize()
    },
    remove_task_weight(task_index,   weight_index) {
      this.task_mutatable.lot.formula.tasks[task_index].weights.splice(weight_index, 1)
      this.$set(this.task_mutatable, 'tasks', this.task_mutatable.lot.formula.tasks)
      this.task_mutatable = {...this.task_mutatable}
    },

    remove_task_measurement(task_index, measurement_index) {
      this.task_mutatable.lot.formula.tasks[task_index].measurements.splice(measurement_index, 1)
      this.task_mutatable = {...this.task_mutatable}
    },

    select_balance(balance) {
      console.log(balance);
    },

    add_ingredient(selected_ingredient) {
      // this.form.new_ingredient = selected_ingredient
      this.formula.ingredients.push(selected_ingredient);
      //selected_ingredient = {}
      this.form.new_ingredient.description = "";
      return;
    },
    remove_ingredient_lot(ingredient_lot) {
      let self = this;
      console.log("remove ing", ingredient_lot);
      console.log("this.lot", this.lot);
      let new_ingredients_used = this.lot.ingredients_used;
      delete new_ingredients_used[ingredient_lot.ingredient_identifier].lots[
        ingredient_lot.identifier
      ];
      // edit object directly because we must delete from it
      let new_data = {
        ...this.lot,
        ...{ ingredients_used: new_ingredients_used }
      };
      // but first edit locally because we are not properly using vuex yet:
      this.lot = new_data;
      self.$store.dispatch("lot/reset_current_ingredient_lot");
      self.$store.dispatch("lot/setFormulaLot", new_data).then(data => {
        // console.log("setFormulaLot");
      });

      // this.formula.ingredients.splice(ingredient_index, 1);
    },

    save_lot(args) {
      this.form.quantity_edit_mode = false
      if (
        this.lab_config.lot &&
        this.lab_config.lot.allow_custom_lot_number &&
        args.status === 'review' &&
        (
          this.lot.lot_number === '' ||
          this.lot.lot_number === null ||
          this.lot.lot_number === undefined
        )
      ) {
        this.$message({
          message: 'You must enter a lot number',
          type: 'error'
        })
        return
      }
      if (args.status === 'pending' && this.task.task.data._custom_data && !(this.lot.saved || this.lot.version)) {
        this.lot._custom_data = this.task.task.data._custom_data
      }
      if (
        (args.status === 'review' || args.status === 'approved') &&
        (
          !parseFloat(this.lot.yield)
        )
      ) {
        this.$message({
          message: 'You must enter a final yield',
          type: 'error'
        })
        document.querySelector('#final_yield_input').focus()
        return
      }
      this.lot.yield = parseFloat(this.lot.yield)
      //      this.lot = args;
      // console.log("store list", this.equipment_list);
      // console.log("lot list", this.lot.equipment_list);
      let current_device_object_clean = {}
      let current_device_object = this.$store.getters["equipment/get_current_device"]
      if (current_device_object) {
        current_device_object_clean = {
          name: current_device_object.name,
          identifier: current_device_object.identifier,
          icloud_account: current_device_object.icloud_account
        }
      }
      let equipment_list_to_save = [...(this.equipment_list || [])];
      let lot_clone = _.cloneDeep(this.lot)
      let lot_data = {
        ...lot_clone,
        ...args,
        ...{
          beyond_use_date: this.$moment(
            this.calculated_beyond_use_date()
          ).format("YYYY-MM-DD"),
          // this would reset the formula each time when it should be _static_:
          //          formula: this.formula,
          current_task_id: this.task_mutatable.task.id,
          //          lot_id: this.task.task.id,
          //          ingredient_identifier: this.formula.identifier,
          //          product_identifier: this.formula.identifiers.formula_id,
          //          lot_type: "formula",
          inventory: {
            ...this.lot.inventory,
            quantity: this.lot.yield,
            containers: this.lot.yield_container_count,
            beyond_use_date: this.$moment(
              this.calculated_beyond_use_date()
            ).format()
          }
        },
        ...{
          product_identifier: this.lot.formula.code,
          lot_type: "formula",
          ingredient_identifier: this.lot.formula.identifier,
          equipment_list: equipment_list_to_save,
          current_device: current_device_object_clean,
          formula_type_data: this.formula_type
        }
      }
      // if not already approved:
      if (lot_data.status == "approved" && this.lot.status != "approved") {
        lot_data.verified_by = this.$store.getters["account/profile"];
        lot_data.inventory.location = this.lot.formula.options.location;
        if (!lot_data.lot_number) { lot_data.lot_number = lot_data.task_id_create_lot }
        // let database do this: lot_data.date_verified=this.$moment().format()
      } else if (
        lot_data.status == "cancelled" &&
        this.lot.status != "approved"
      ) {
        lot_data.cancelled_by = this.$store.getters["account/profile"];
        // save reason
        lot_data.reason_removed_details = args.reason_removed_details;
        // let database do this: lot_data.date_verified=this.$moment().format()
      }
      try {
        let session = {
          sessionid: '',
          pageviewid: '',
          date_session_ended: new Date().toJSON(),
          date_session_started: this.date_started,
          user: this.$store.getters["account/profile"],
          logrocket: ''
        }
        let session_url = ''
        LogRocket.getSessionURL(function (sessionURL) {
          session_url = sessionURL
        })
        session.logrocket = session_url
        // Stop and start mouseflow, log the session and pageview ids
        try {
          if (mouseflow) {
            // console.log('mf session test', mouseflow)
            window._mfq.push(["stop"]);
            let pageview_id = ''
            try {
              pageview_id = mouseflow.getPageviewId()
            } catch {
              pageview_id = ''
            }

            session.sessionid = mouseflow.getSessionId()
            session.pageviewid = pageview_id
            mouseflow.start()
          }
        } catch (e) {
          console.error(e)
        }

        this.date_started = new Date().toJSON()
        this.current_sessions.push(session)

        if (!lot_data.session_data) {
          lot_data.session_data = []
        }
        lot_data.session_data.push(...this.current_sessions)
      } catch(e) {console.error(e);}
      console.log('this.pack_stat_fill', this.pack_stat_fill)
      if (!_.isEmpty(this.pack_stat_fill)) {
        const ps_data = _.cloneDeep(this.pack_stat_fill)
        lot_data.pack_stat_fill = ps_data
        lot_data.activity_factor_calculation = document.querySelector('.packstat_calculations').innerHTML
      }
      console.log('this.activity_factor_difference', this.activity_factor_difference)
      if (!_.isEmpty(this.activity_factor_difference)) {
        const af_data = _.cloneDeep(this.activity_factor_difference)
        lot_data.activity_factor_difference = af_data
        lot_data.activity_factor_calculation = document.querySelector('.packstat_calculations').innerHTML
      }

      console.log("save_lot - 2292", lot_data);
      if (args.status === 'review') {
        this.$emit('complete')
      }
      this.record_entry(lot_data);
    },
    save_lot_confirmation(args) {
      let self = this;
      if(args.status == "cancelled"){
        self.$prompt('Please input the reason for scrapping the lot', 'Save the task with status Cancelled', {
          confirmButtonText: 'Scrap Lot',
          cancelButtonText: 'Cancel',
          inputValidator: (v) => {return v ? true:false},
          inputErrorMessage: 'You should input a reason',
          type: 'warning'
        }).then(({ value }) => {
          //passing reason
          args.reason_removed_details = value
          self.save_lot(args);
        }).catch(() => {
        });

      } else {
        this.$confirm(
          "This will save the task with status " + args.status + ", Continue?",
          "Confirm",
          {
            distinguishCancelAndClose: true,
            confirmButtonText: "Save",
            cancelButtonText: "Cancel"
          }
        )
        .then(() => {
          self.save_lot(args);
        })
        .catch(action => {
          // add a message
        });
      }

    },
    record_entry(lot_data) {
      let self = this;
      // see how createEvent in event.js works:
      // an action gets dispatched, which performs a POST, inside of which a commit is made
      // console.log(lot_data);
      NProgress.start();
      this.saving_lot = true
      this.$store
        .dispatch("lot/saveLot", lot_data)
        .then((response_data) => {
          if (!response_data) {
            throw 'No Response'
          }
          console.log('saveLot response:', response_data)
          this.saving_lot = false
          // this.product = this.createFreshEventObject()
          // this.form = this.createFreshEventObject()
          let current_lot_data = lot_data
          current_lot_data.saved = true
          if (response_data) {
              console.log(2133)
            if (response_data.lot_data) {
              console.log(2135)
              current_lot_data = response_data.lot_data
            }
            const new_lot_version_id = parseInt(response_data.version)
            if (new_lot_version_id) {
              current_lot_data.version = new_lot_version_id
            }
          }
          //self.lot = lot_data;
          console.log("current", current_lot_data);
          self.$store.dispatch("lot/setFormulaLot", current_lot_data);
          self.task_mutatable.lot = current_lot_data
          self.lot_as_saved = _.cloneDeep(self.task_mutatable.lot || {})
          console.log("after", self.lot);
          // self.$store.dispatch("lot/setFormulaLot", new_object)
        })
        .then(() => NProgress.done())
        .then(() => self.$forceUpdate())
        .catch(() => {
          NProgress.done();
          this.saving_lot = false
        });
    },
    reset_balance() {
      this.$store.dispatch("equipment/reset_balance_state");
    },
    complete_task(task_id, task_data) {
      NProgress.start();
      this.$store
        .dispatch("task/complete_task", {
          task_id: task_id,
          task_data: task_data
        })
        .then(() => NProgress.done())
        .catch(() => {
          NProgress.done();
        });
    },
    toggleInstructionVisibility(value) {
      // console.log("toggleInstructionVisibility", this.show_instructions);
      // console.log("VALUE", value);

      this.show_instructions = value;
      // console.log("POST - toggleInstructionVisibility", this.show_instructions);
    },
    toggleReworkVisibility() {
      return;
    },


    updateTaskDocuments( task, _document) {
      // console.log('_document', _document)
      // console.log('task', task)
      // copied from formula/manage.vue:
      let to_append = {};
      to_append.name = _document.name;
      to_append.note = _document.note;
      to_append.type = _document.type;
      to_append.identifier = _document.identifier;
      let to_append_list = [];
      to_append_list.push(to_append);
      let this_document_category_list = {};
      let new_documents_list;
      this_document_category_list["category"] = _document.category;
      this_document_category_list["documents"] = to_append_list;
      // catch if task does not have a documents array yet:
      if (_.isNil(task.documents) || typeof task.documents != 'object') {
        this.$set(task, 'documents', [])
        //task.documents=[]
      }
      if ((task.documents && task.documents.length == 0)) {
        new_documents_list = this_document_category_list;
        task.documents.push(this_document_category_list);
      } else {
        let sear = this.arrayContainsCategory(task.documents, _document.category);
        if (sear.contains) {
          task.documents[sear.index]["documents"].push(to_append);
        } else {
          let to_add_in_list= {};
          to_add_in_list["category"] = _document.category;
          to_add_in_list["documents"] = to_append_list;
          task.documents.push(to_add_in_list);
        }
      }
      this.$forceUpdate()
    },
    arrayContainsCategory(tabb, categ) {
      let res = false;
      let i = 0;
      let pos = i;
      tabb.forEach(function(params) {
        if (params.category == categ) {
          res = true;
          pos = i;
        }
        i++;
      });
      return { contains: res, index: pos };
    },
    get_document_url(identifier, args={}) {
      return Common.get_document_url(identifier, args);
    },
    update_label_entries(entries) {
      // this.lot.inventory['label_list'] = entries
      Vue.set(this.lot.inventory,'label_list', entries)

      this.dialogQuantityPromptVisible=false
    },
    validate_lot_identifier(identifier) {
      if (this.task.task.task_state) {
        return uuid_base64.decode(identifier) === this.task.task.task_state.lot_identifier
      } else { return false }
    },
    verify_lot_barcode() {
      if (this.task.task && this.task.task.task_state) {
        if (['lot_create', 'lot_rework'].includes(this.task.task.task_type) && this.task.task.task_state.lot_identifier) {
          this.$prompt('Please scan lot barcode', 'Lot Verification', {
            confirmButtonText: 'Verify',
            cancelButtonText: 'Cancel',
            inputValidator: this.validate_lot_identifier,
            inputErrorMessage: 'Lot Identifier Does Not Match',
            inputType: this.isChrome() ? 'text' : 'password'
          }).then(({ value }) => {

            }
          ).catch(() => {
            this.task.task.task_status = "cancelled"
            this.$router.go(-1)
            }
          )
        }
      }
    }
  },
  created() {
    let self = this;
    const pmk_config = this.$store.getters['app/pmk_config']

    if (
      pmk_config.modules.electriclab &&
      pmk_config.modules.electriclab.require_technician_label_scan_on_lot_tasks &&
      this.$store.getters['account/profile'].user_type === 'lab') {
      this.verify_lot_barcode()
    }

    // this.lot = this.task.lot;
    // set lot:
    console.log('CREATED')
    const formula_type_tasks_mutable = JSON.parse(JSON.stringify(this.formula_type.tasks || []))

    if (
      !this.task_mutatable.lot ||
      (Object.entries(this.task_mutatable.lot).length === 0 &&
        this.task_mutatable.lot.constructor === Object)
    ) {
      // console.log('this.task.formula.tasks:', this.task.formula.tasks)
      // console.log('this.formula_type.tasks', this.formula_type.tasks)
            // console.log('seeting task_lotC:', { ...{formula: {tasks: [...this.task.formula.tasks, ...this.formula_type.tasks]}} })

      // let task_lot = {...createFreshLotObject(this.task), ...{formula: {tasks: [...this.task.lot.formula.tasks, ...this.formula_type.tasks]}} } ;
      // console.log('task_lotC:', task_lot)
      //      task_lot.formula.tasks=[...task_lot.formula.tasks, ...this.formula_type.tasks]
      //    task_lot = {...task_lot, }
      //      task_lot.formula.tasks.push(this.formula_type.tasks)
      const new_lot = createFreshLotObject(this.task_mutatable, this.lab_config)
      self.$store.dispatch(
        "lot/setFormulaLot",
        new_lot // was: createFreshLotObject(this.task_mutatable, this.lab_config)
      );
      // trigger building of lot:
      const store_lot = self.lot;
      // console.log({store_lot})
      // console.log(JSON.parse(JSON.stringify(this.task_mutatable.lot)))
    } else {
      self.$store.dispatch("lot/setFormulaLot", this.task_mutatable.lot);
    }

    if (
      this.task_mutatable.lot &&
      this.task_mutatable.lot.formula &&
      !this.task_mutatable.lot.formula.formula_level_tasks
    ) {
        let task_lot = {
          ...this.task_mutatable.lot,
          ...{
            formula: {
              formula_level_tasks: true,
              tasks: [
                ...this.task_mutatable.lot.formula.tasks,
                ...formula_type_tasks_mutable
              ]
            }
          }
        };
        let lot_formula = {
          ...this.task_mutatable.lot.formula,
          ...{
            formula_level_tasks: true,
            tasks: [...task_lot.formula.tasks, ...formula_type_tasks_mutable]
          }
        };
        task_lot = { ...task_lot, ...{ formula: lot_formula } };

        self.$store.dispatch("lot/setFormulaLot", task_lot);
    }
    let all_tasks = [...this.task_mutatable.lot.formula.tasks];
    all_tasks.forEach(function(element) {
      self.formula_tasks_all.push(element);
      self.subtasks.push({
        new_weight: "",
        // TODO: support an object that can store a timestamp and other information:
        new_measurement: { value: "" , type: ""},
        // new_measurement: "",
        new_image: {}
      });
    });






},
  mounted() {
    let self = this;
    // set multiplier:
    console.log("lot QTY:", this.lot.quantity);
    console.log("formula makes QTY:", this.formula.formula_quantity);
    this.quantity_muliplier =
      this.lot.quantity / (this.formula.formula_quantity || 1);

    this.formula.ingredients.forEach(ingredient => {
      if (ingredient.has_activity_factor) {
        self.has_activity_factor_ingredient = true
      }
    })
    if (this.formula.pack_stat_required) {
      self.has_pack_stat_ingredient = true
    }
    if (!this.lot.lots_allowed) {
      this.lot.lots_allowed = {}
    }
    if (this.formula.dosage_form === 'C25158') {
      this.pack_stat_unit_name = 'Caps'
    } else if (['C42961', 'C42972', 'C42973', 'C42975', 'C64907', 'C64908', 'C91171'].includes(this.formula.dosage_form)) {
      this.pack_stat_unit_name = 'Scoops'
    }
    this.check_balance_disallow_recording()
    try {
      BrowserPrint.getDefaultDevice("printer", function(device)
    {
      self.selected_device = device
    }, undefined)
    } catch (e) {
      console.log('error:', e)
    }
    document.removeEventListener("visibilitychange", this.handle_visibility_change, false)
    document.addEventListener("visibilitychange", this.handle_visibility_change, false)
    // this is now done upon initial loading
    //let all_tasks = [...this.task.lot.formula.tasks, ...this.formula_type.tasks]



// moved to created:

    // if (
    //   this.task_mutatable.lot &&
    //   this.task_mutatable.lot.formula &&
    //   !this.task_mutatable.lot.formula.formula_level_tasks
    // ) {
    //   let task_lot = {
    //     ...this.task_mutatable.lot,
    //     ...{
    //       formula: {
    //         formula_level_tasks: true,
    //         tasks: [
    //           ...this.task_mutatable.lot.formula.tasks,
    //           ...this.formula_type.tasks
    //         ]
    //       }
    //     }
    //   };
    //   let lot_formula = {
    //     ...this.task_mutatable.lot.formula,
    //     ...{
    //       formula_level_tasks: true,
    //       tasks: [...task_lot.formula.tasks, ...this.formula_type.tasks]
    //     }
    //   };
    //   task_lot = { ...task_lot, ...{ formula: lot_formula } };

    //   self.$store.dispatch("lot/setFormulaLot", task_lot);
    // }
    // let all_tasks = [...this.task_mutatable.lot.formula.tasks];
    // all_tasks.forEach(function(element) {
    //   console.log(2276, {element})
    //   self.formula_tasks_all.push(element);
    //   self.subtasks.push({
    //     new_weight: "",
    //     // TODO: support an object that can store a timestamp and other information:
    //     new_measurement: { value: "" , type: ""},
    //     // new_measurement: "",
    //     new_image: {}
    //   });
    // });
    if (this.task_id) {
      // listen for scanned-code event
      document.addEventListener("scanned-code", scanned_code_listener);
      // EventService.getInventory(decompressed_scan)
      this.$store.dispatch("task/setQueryFilters", { task_id: this.task_id });
    }
    // making the default value for the picker that changes the bud
    self.BudToOverride = self.calculated_beyond_use_date();


    const ws_lot_on_message = function(event) {
      let update_from_WS={};
      let message_successful = false;
      try {
          update_from_WS = JSON.parse(event.data);
          message_successful = true;
      } catch(e) {
          console.error(e);
          return;
      }
      if (message_successful && _.get(update_from_WS.patch_data, 'lot_identifier') === _.get(self.lot, 'lot_identifier')) {
        let current_user = self.$store.getters["account/profile"]
        console.group("PATCH");
        if (current_user && current_user.username && current_user.username !=  update_from_WS.changed_by) {
          console.log('PATCH', { patch_data: update_from_WS })
          // check update version against this version:
          if (self.task_mutatable.lot.version === update_from_WS.patch_data.previous_version) {
            // get the current diff -- TODO - jsondiffpatch needs configuration, currently stores each entity as an array:
            // Explanation:
            // a PATCH between the previous saved version and the latest saved version is sent as update_from_WS.patch_data
            // we do not want to clobber our current changes so a diff should be made between the previous version and the current version
            // the PATCH should be applied to lot_as_saved (our local copy of the last saved version)
            // that should then be patched with our current changes

            let current_version_delta = jsondiffpatch.diff(self.lot_as_saved, self.task_mutatable.lot);
            console.log({ current_version_delta, 'self.lot_as_saved': self.lot_as_saved })
            // TODO - This is the correct code:
            // self.task_mutatable.lot.formula = { ...{ ...self.lot_as_saved.formula, ...update_from_WS.patch_data.formula }, ...current_version_delta}
            if (update_from_WS.patch_data.formula ) {
              patch_formula(self, update_from_WS)
              /*
              if ('debug' && 0) {
                console.log(2522, 'update_from_WS.patch_data.formula', { formula: update_from_WS.patch_data.formula })
                // console.log({ lot_as_saved_formula: self.lot_as_saved.formula })
                // get the diff between lot_as_saved and current state:
                const current_server_saved_formula = JSON.parse(JSON.stringify({ ...self.lot_as_saved.formula, ...update_from_WS.patch_data.formula }))
                const jdp = jsondiffpatch.create({cloneDiffValues:true})
                const current_state_diff = jdp.diff(self.lot_as_saved.formula, self.task_mutatable.lot.formula);
                console.log({ current_state_diff })

                const new_version_formula = jdp.patch(current_server_saved_formula, current_state_diff)
                console.log(2530, {current_server_saved_formula, new_version_formula})
                // TODO: test this and see if new_version_formula is better than the code below:
                self.task_mutatable.lot.formula = _.merge(
                  JSON.parse(JSON.stringify({ ...self.task_mutatable.lot.formula, ...update_from_WS.patch_data.formula })),
                  new_version_formula
                )
                console.log({ 'self.task_mutatable.lot.formula': self.task_mutatable.lot.formula})
                delete update_from_WS.patch_data.formula;
              }
              */
            }
            patch_lot(self, update_from_WS)
            console.log(2536, update_from_WS.patch_data)

            // self.task_mutatable.lot = { ...self.task_mutatable.lot, ...update_from_WS.patch_data }
            self.lot = self.task_mutatable.lot
            console.log(2540, self.lot)
          } else if (self.task_mutatable.lot.version < update_from_WS.patch_data.previous_version) {
            // get the current diff -- TODO - jsondiffpatch needs configuration, currently stores each entity as an array:
            let current_version_delta = jsondiffpatch.diff(self.lot_as_saved, self.task_mutatable.lot);
            console.log({ current_version_delta })
            // TODO - This is the correct code:
            // self.task_mutatable.lot.formula = { ...{ ...self.lot_as_saved.formula, ...update_from_WS.patch_data.formula }, ...current_version_delta}
            if (update_from_WS.patch_data.formula ) {
              patch_formula(self, update_from_WS)
            }
            console.log(2634, update_from_WS.patch_data)

            patch_lot(self, update_from_WS)
            self.lot = self.task_mutatable.lot
            console.log(2637, self.lot)
          } else if (self.task_mutatable.lot.version === update_from_WS.patch_data.previous_version) {

            if (update_from_WS.patch_data.formula ) {
              patch_formula(self, update_from_WS)
            }
            console.log(2644, update_from_WS.patch_data)

            patch_lot(self, update_from_WS)
            self.lot = self.task_mutatable.lot

            console.log(2649, self.lot)


          } else if (self.task_mutatable.lot.version < update_from_WS.patch_data.version) {
            // get the current diff -- TODO - jsondiffpatch needs configuration, currently stores each entity as an array:
            let current_version_delta = jsondiffpatch.diff(self.lot_as_saved, self.task_mutatable.lot);
            console.log({ current_version_delta })
            // TODO - This is the correct code:
            // self.task_mutatable.lot.formula = { ...{ ...self.lot_as_saved.formula, ...update_from_WS.patch_data.formula }, ...current_version_delta}
            if (update_from_WS.patch_data.formula ) {
              patch_formula(self, update_from_WS)
            }
            console.log('2470 - update_from_WS.patch_data:', update_from_WS.patch_data)

            patch_lot(self, update_from_WS)
            self.lot = self.task_mutatable.lot
            console.log(2473, self.lot)
          } else if (self.task_mutatable.lot.version === update_from_WS.patch_data.previous_version) {

            if (update_from_WS.patch_data.formula ) {
              patch_formula(self, update_from_WS)
            }
            console.log(2450, update_from_WS.patch_data)

            patch_lot(self, update_from_WS)
            self.lot = self.task_mutatable.lot

            console.log(2455, self.lot)

          } else {
            console.warn('INVALID VERSIONS?', { tml: self.task_mutatable.lot.version, update_pv: update_from_WS.patch_data.previous_version, update_v: update_from_WS.patch_data.version})
          }

          self.$forceUpdate()
        }
        console.groupEnd();
      } else {
        console.warn('Message unsuccessful or lot identifier mismatch', update_from_WS, self.lot)

      }

    }

    //subscribe to WS for lot channel:
    let url_for_lot_ws =  url_for.get_ws_url('ws_electriclab_lot', { lot_identifier: this.task_mutatable.lot.identifier , version: 'v5'})

    WS_lot = connect_lot_websocket(url_for_lot_ws);
    WS_lot.onmessage = ws_lot_on_message
    WS_lot.onclose = function(event) {
      console.log("lot WebSocket is closed now.", url_for_lot_ws);
      if (self.ws_active) {
        setTimeout(() => {
          WS_lot = connect_lot_websocket(url_for_lot_ws);
          WS_lot.onmessage = ws_lot_on_message
        }, 1000);
      }
    };

  },
  beforeDestroy() {
    // remove listener for barcode-scan event
    this.$store.dispatch("lot/reset_lot");
    document.removeEventListener("scanned-code", scanned_code_listener, false);
    this.$store.dispatch("equipment/clear_checked_out_equipment");
    this.$store.dispatch("equipment/reset_balance_state");
    this.$store.dispatch("equipment/disconnectEquipmentWs");
    // EventService.getInventory(decompressed_scan)

    // unsubscribe from WS channel:
    this.ws_active = false
    WS_lot.close();

  }
};

import global_store from "@/store/store";
const createFreshLotObject = function(lot_task, lab_config) {
  console.log("lot_task", lot_task);
  let current_user =
    global_store.getters["user/get_user"] || global_store.getters["get_user"];

  let formula_type = { tasks: [] };

  let formula_type_identifier = lot_task.formula.data.formula_type;
  if (!global_store.getters["formula/get_lab_config"]) {
    global_store.dispatch("formula/fetchLabConfig");
  }
  if (global_store.getters["formula/get_lab_config"]) {
    formula_type = {
      ...{ tasks: [], instructions: "" },
      ...global_store.getters["formula/get_lab_config"]["config"][
        "formula_types"
      ][formula_type_identifier]
    };
  }

  // console.log("formula_type", formula_type);

  const formula_type_tasks_mutable = JSON.parse(JSON.stringify(formula_type.tasks || []))


  let formula_tasks = [...lot_task.formula.data.tasks, ...formula_type_tasks_mutable];
  let formula = {
    ...lot_task.formula.data,
    ...{ formula_level_tasks: true, tasks: formula_tasks }
  };
  let tmp_lot_identifier = uuidv4()
  let tmp_lot_barcode = uuid_base64.encode(tmp_lot_identifier)
  return {
    identifier: tmp_lot_identifier,
    author: current_user.name,
    verified_by: "",
    date_verified: "",
    status: "pending",
    location: "",
    task_id_create_lot: lot_task.task.id,
    lot_id: (lab_config.lot && lab_config.lot.allow_custom_lot_number ? '' : lot_task.task.id),
    lot_barcode: tmp_lot_barcode,
    lot_number: (lab_config.lot && lab_config.lot.allow_custom_lot_number ? '' : lot_task.task.id),
    date_created: new Date(),
    date_compounded: new Date(),
    options: {},
    lots_allowed: {},
    note: "",
    formula: formula,
    quantity:
      typeof lot_task.task.data.lot == "object"
        ? lot_task.task.data.lot.quantity
        : undefined,
    ingredients_used: {
      // example:
      // estradiol_powder (actually the identifier for it, 'ab4513c-2801ef'):
      //     { lots: {
      //         '135edf-01abc': {quantity: 5.5, identifier: ..., beyond_use_date: '2019-06-01'},
      //         '4635df-23c1d': {quantity: 5.5, identifier: }
      //         ...
    },
    saved: false,
    product_identifier: lot_task.formula.data.code,
    lot_type: "formula",
    ingredient_identifier: lot_task.formula.data.identifier,
    inventory: { label_list: [] }
  };
};
</script>


<style id="task-checkbox-fix">
.formula-tasks label.el-checkbox {
    white-space: break-spaces;
}
.el-icon-warning:before {
    content: "\e60a" !important;
}
</style>

<style scoped>

.location {
  margin-bottom: 0;
}
.location > .icon {
  margin-left: 10px;
}
.indicators {
  font-size: 0.8em;
}

.indicator {
  margin-right: 1em;
}


.event-header > .title {
  margin: 0;
}
.list-group {
  margin: 0;
  padding: 0;
  list-style: none;
}
.list-group > .list-item {
  padding: 1em 0;
  border-bottom: solid 1px #e5e5e5;
}

.formula-options .el-col {
  margin-top: 4px;
}

.auxiallary_button {
  margin-right: 1.5em;
}

.formula-instructions {
  margin: 1.5em 0;
  white-space: pre-wrap;
}

.button.bottom {
  margin-top: 13px;
  line-height: 12px;
  padding: 0;
  float: right;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}

.status-button {
  margin-right: 1.25em;
}

.formula-status {
  font-size: 0.8em;
  color: #8a8a8a;
  margin: 0 0.5em;
}
.sub_task-instructions {
  display: inline-block;
  min-width: 25em;
  max-width: 80%;
}
.sub_task-points {
  max-width: 8em;
  margin: 0 1em;
}
.ingredient-remove:hover {
  color: rgb(207, 13, 13);
}
.clickable {
  cursor: pointer;
}
.clickable:hover {
  color: #409eff !important;
}
.ingredient-lot-quantity {
  margin-left: 1em;
}
.helper-instructions {
  font-size: 0.8em;
  color: #8a8a8a;
  hyphens: auto;
  overflow-wrap: break-word;
  word-wrap: break-word;
}
.balance-area {
  font-size: 1.8em;
}
.current-ingredient {
  /* text-decoration: underline; */
  font-weight: bold;
  font-size: 1.1em;
}
.current-ingredient-lot {
  color: #39b982;
  font-weight: bold;
}
.quantity_current {
  float: left;
}
.quantity_needed {
  /* float: right;
  margin-right: 4em; */
  text-align: right;
  /* 50px to match el-progress-bar css */
  padding-right: 50px;
}

.ingredient-quantity-required-met {
  color: #39b982;
}
.formula-task-options-label {
  vertical-align: bottom;
}
.item-remove:hover {
  color: rgb(207, 13, 13);
}
/* same as sub-title */
.section-header {
  font-weight: bold;
}
.record-balance-weight-for-ingredient-quantity {
  /* float: right; */
  margin-right: 2em;
  text-align: right;
}

.lot-beyond-use-date {
  /* margin-left: 2em; */
  color: #747474;
  /* text-align: end; */
  text-decoration: none !important;
  font-size: x-small;
  display: block;
}

.lot-negative-inventory {
  color: #747474;
  text-decoration: none !important;
  font-size: x-small;
}

.lot-capsule-weight-average {
  /* margin-left: 2em; */
  color: #747474;
  /* text-align: end; */
  text-decoration: none !important;
  font-size: x-small;
  display: block;
}

.lot-line {
  padding-top: 0.15em;
  padding-bottom: 0.15em;
  border-top: 1px solid silver;
}
.ingredient-lot-quantity-area {
  margin-left: 2em;
  text-align: end;
}
.ingredient-measurement-line {
  font-weight: bold;
}
.lot-header {
  font-size: 0.85em;
}
.log-header {
  font-size: 0.85em;
}
.measurements-log-area {
  text-align: right;
  color: #747474;
  padding-right: 2em;
}
.strike {
  text-decoration: line-through;
  text-decoration-color: red !important;
}
.remove:hover {
  color: red;
}
.no-break {
  word-break: keep-all;
  word-wrap: break-word;
}
/* .balance .el-progress-bar {
  padding-right: 0px;
} */
.ingredient_count {
  color: #747474;
  font-size: small;
}
.ingredient-list {
}
.ingredient-list.scrollable {
  box-shadow: -3px 6px 10px 1px #c0c0c045 inset;
  padding: 0.25em 0.25em;
  max-height: 20em;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
</style>
<style>
.balance .el-progress-bar {
  /* padding-right: 0px; */
}
.quantity_sufficient.balance .el-progress-bar .el-progress-bar__outer {
  background-color: #0e8dc599;
}
.quantity_sufficient.quantity_needed {
  color: gray;
}
.quantity_sufficient.quantity_needed:before {
  content: "[QS] ";
}
.mt-5 {
  margin-top: 5px;
}

/* scrollbar */
/* https://codepen.io/anon/pen/VJQgmO */
.scrollbar-prominent::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}
.scrollbar-prominent::-webkit-scrollbar {
  width: 7px;
  border-radius: 4px;
  background-color: #f5f5f5;
}
.scrollbar-prominent::-webkit-scrollbar-thumb {
  background-color: #00668e;

  background-image: -webkit-gradient(
    linear,
    0 0,
    0 100%,
    color-stop(0.5, rgba(255, 255, 255, 0.2)),
    color-stop(0.5, transparent),
    to(transparent)
  );
}
.item .el-badge__content.is-fixed{
  cursor: pointer;
  right: 20px;
  border-radius: 15px;
  font-size: 11px;
  height: 15px;
  line-height: 14px;
  padding: 0px 4px;
  color: #f4f4f5;
  background: #cccecf;
  border-color: #cbcdcf;
}


.list-wrap {
  padding:40px;
  border-radius:5px;
  width:400px;
}

div.styled-list {
  counter-reset: list-number;
}
div.styled-list div:before {
  counter-increment: list-number;
  content: counter(list-number);

  margin-right: 0.75em;
  margin-bottom: 0.5em;
  width: 1.5em;
  height: 1.5em;
  display: inline-flex;
  align-items:center;
  justify-content: center;
  font-size:12px;
  font-weight: bold;
  background-color:#0e8ec5;
  border-radius:50%;
  color:#fff;
}

  .equipment-message-box {
    font-size: 0.65em;
    color: rgb(148, 42, 42);
    white-space: pre-line;
  }
  .manufacturer_name {
    font-size: 0.8em;
    color:#747474;
  }
.calculations div.el-collapse-item__header {
  height: 48px !important;
  line-height: initial !important;
}
#unit_dose_label_button button.el-button {
    padding: 5px;
}
.needs_adjust {
    color: #ff4949;
    font-weight: bold;
}
</style>
