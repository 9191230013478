import EventService from '@/services/EventService.js'
import store from '@/store/store'
const uuidv4 = require('uuid/v4')

export const namespaced = true

export const state = {
  manufacturers: {}
}

export const mutations = {
  SET_MANUFACTURERS(state, data) {
    state.manufacturers = data
  },
  ADD_MANUFACTURER(state, data) {
    let new_object = {}
    new_object[data.identifier] = data
    state.manufacturers =  Object.fromEntries(Object.entries(state.manufacturers).reverse())
    state.manufacturers = { ...state.manufacturers, ...new_object }
    state.manufacturers = Object.fromEntries(Object.entries(state.manufacturers).reverse())
  }
}

export const actions = {
  fetchManufacturers({ commit, dispatch }, data) {
    return EventService.getManufacturerList(data)
      .then(response => {
        console.log(response.data)
        if (response.data.success) {
          commit('SET_MANUFACTURERS', response.data.data)
        }
        return response.data.data
        // const notification = {
        //   type: 'success',
        //   message: 'Your formula has been saved!'
        // }
        // dispatch('notification/add', notification, { root: true })
      })
      .catch(error => {
        const notification = {
          type: 'error',
          message: 'There was a network problem: ' + error.message
        }
        dispatch('notification/add', notification, { root: true })
        throw error
      })
  },
  setManufacturers({ commit, dispatch }, data) {
    commit('SET_MANUFACTURERS', data)
  },
  saveManufacturer({ commit, dispatch }, data) {
    return EventService.saveManufacturer(data)
      .then(response => {
        if (response.data.success) {
          const notification = {
            type: 'success',
            message: 'Manufacturer information saved!'
          }
          dispatch('notification/add', notification, { root: true })
        }
        return response.data.data
        // const notification = {
        //   type: 'success',
        //   message: 'Your formula has been saved!'
        // }
        // dispatch('notification/add', notification, { root: true })
      })
      .catch(error => {
        const notification = {
          type: 'error',
          message: 'There was a network problem: ' + error.message
        }
        dispatch('notification/add', notification, { root: true })
        throw error
      })
  },
  addManufacturer({ commit, dispatch }, data) {
    commit('ADD_MANUFACTURER', common_functions.createFreshManufacturerObject())
  }
}

export const getters = {
  get_manufacturers: state => {
    return state.manufacturers
  }
}

export const common_functions = {
  createFreshManufacturerObject() {
    const user = store.getters["account/profile"]

    return {
      identifier: uuidv4(),
      author: user.name,

      name: '',

      date_created: new Date().toJSON()
    }
  }
}
