<template>
  <div>
    <div slot="header" class="clearfix">
      <el-row :gutter="10">
        <el-col :span="18">
          <span>
            <span style="font-size: 1.5em">Formula Search</span>
            &nbsp;
            <span class="text-secondary"></span>
          </span>
        </el-col>
        <el-col :span="2">
          <el-button
            v-if="true"
            type="default"
            style="  padding: 5px; margin-right: 0.5em;"
            @click="reset()"
          >Clear</el-button>
        </el-col>
        <el-col :span="2">&nbsp;</el-col>
        <el-col :span="2">&nbsp;</el-col>
      </el-row>
    </div>

    <el-row :gutter="10">
      <el-col :span="18">
        <div style="margin-bottom: 2em;">
          <div class="sub-title">Search:</div>
          <el-autocomplete
            id="formula_search_field"
            style="width: 40em;"
            v-model="form.formula_query"
            :fetch-suggestions="search_formulas"
            placeholder="Search..."
            placement="bottom-end"
            popper-class="autocomplete-suggestion-popper-use-table"
            :trigger-on-focus="false"
            no-value-key="name"
            @select="select_product"
            size="400"
            autocapitalize="none"
            :debounce="450"
            clearable
          >
            <template slot="prepend">
              <i class="fal fa-search"></i>
            </template>

            <template slot-scope="{ item }">
              <div class="value">
                <span v-if="item.code">[{{ item.code }}] -</span>
                <span>{{ item.name }}</span>
              </div>
            </template>
            <!-- <template slot="append">
            <i @click="select_product" class="fal fa-plus-circle"></i>
            </template>-->
          </el-autocomplete>&nbsp;
        </div>
      </el-col>
      <el-col :span="8"></el-col>
    </el-row>
    <el-row :gutter="10">
      <el-col :span="24">
        <section class="results_area" v-if="results">
          <el-table
            ref="singleTable"
            :data="results"
            highlight-current-row
            @current-change="handleSearchResultSelection"
            style="width: 100%"
            :row-class-name="formula_status_class"
          >
            <!-- <el-table-column type="index" width="50"></el-table-column> -->
            <el-table-column class-name="clickable" property="name" label="Formula" width="520"></el-table-column>
            <el-table-column property="code" label="Code" width="120"></el-table-column>
            <el-table-column property="Object.values(inventory_data).length" label="Inventory"></el-table-column>
            <el-table-column label="Last Modified">
              <template slot-scope="scope">
                {{ format_date(scope.row.date_updated) }}
              </template>
            </el-table-column>
            <el-table-column label="Last Used">
              <template slot-scope="scope">
                {{ find_last_used(scope.row.inventory_data) }}
              </template>
            </el-table-column>
          </el-table>
        </section>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import NProgress from "nprogress";
import EventService from "@/services/EventService.js";
const uuid_base64 = require("uuid-base64");
import moment from 'moment'

export default {
  props: {
    formulaType: {
      type: String,
      required: false
    }
  },
  watch: {
    formulaType: function() {
      this.search_formulas(this.form.formula_query)
    }
  },
  data() {
    return {
      categories: this.$store.state.categories,
      form: {
        formula_query: ''
      },
      results: []
    };
  },
  mounted() {
    document.querySelector('#formula_search_field').focus()
    if (this.$route.query && this.$route.query.q) {
      this.form.formula_query = this.$route.query.q
      this.search_formulas(this.form.formula_query, ()=> {})
    }
  },
  methods: {
    reset() {
      this.form = {};
      this.results = []
    },
    format_date(date) {
      return moment.utc(date).local().format('MM/DD/YYYY')
    },
    find_last_used(data) {
      const use_dates = Object.values(data)
      if (use_dates.length) {
        use_dates.sort((a, b) => {
          return new Date(b) - new Date(a)
        })
        return this.format_date(use_dates[0])
      }
      return ''
    },
    formula_status_class({row, rowIndex}) {
        if (row.formula_status == 'retired') {
          return 'retired-row';
        } else if (row.formula_status == 'proposed') {
          return 'proposed-row';
        } else if (row.formula_status != 'active') {
          return 'proposed-row';
        }
        return '';
    },
    handleSearchResultSelection(row) {
      this.$router.push({
        name: "formula-manage",
        params: { formula_identifier: row.identifier }
      });
    },
    select_product(selected_product) {
      this.form.selected_product = selected_product;
      this.product = {
        ...this.createFreshInventoryObject(),
        ...{
          product_identifier: selected_product.product_identifier,
          manufacturer_identifier: selected_product.manufacturer.identifier,
          ingredient_identifier: selected_product.identifier,
          ingredient: {
            description: selected_product.description
          },
          manufacturer: selected_product.manufacturer,

          description: selected_product.description,
          date_created: "",
          inventory: {}
        }
      };
      this.form = this.createFreshFormObject();
    },

    search_formulas(queryString, cb) {
      this.$router.replace({ name: 'formula-search', query: { q: queryString }})
      let self = this;
      // here we need to query the formula list
      let arg_string = queryString;
      if (queryString.length == 22) {
        arg_string = uuid_base64.decode(queryString);
      }
      let query_object = {
        explicit: 0,
        method: "LIKE",
        product_identifier: arg_string,
        description: arg_string
      };
      if (this.formulaType) {
        query_object['formula_type'] = this.formulaType
        query_object['explicit'] = 1
        query_object['description'] = '%' + query_object['description']
        delete query_object.product_identifier
      }
      let is_numeric = queryString.match(/^\d+$/)
      if ( is_numeric
          || (queryString.length == 36 && queryString.match(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i))
          || queryString.length == 22
      ) {
        query_object.explicit = 0;
        query_object.method = "=";
        query_object.description = "";
        delete query_object.description;
        if ( is_numeric &&
          (( queryString.length == 11 || queryString.length == 12 )
          // || (queryString.length > 8 && queryString.length < 13)
          )
        ) {
          query_object.ndc = queryString
          query_object.product_identifier = "";
          delete query_object.product_identifier;

        } else if (is_numeric && queryString.length > 3) {
          query_object.ndc = queryString
        }

        if ((queryString.length == 36 && queryString.match(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i))) {
          query_object.formula_identifier = queryString
          query_object.product_identifier = queryString
        }
      }
      // console.log('query_object', query_object)
      NProgress.start();
      EventService.searchFormulas(query_object)
        .then(results => {
          self.results = results.data.data;
          cb(results.data.data);
        })
        .then(() => NProgress.done())
        .catch(() => {
          NProgress.done();
        });
    }
  }
};
</script>
<style scoped>
/* out of scope here */

.clickable {
  cursor: pointer;
  color: #409eff;
}
.clickable:hover * {
  color: #409eff;
}
</style>
<style>
.formula_search_area .el-autocomplete-suggestion.el-popper {
  display: none;
}
.autocomplete-suggestion-popper-use-table {
  display: none !important;
}

  .el-table .retired-row {
    background: #c34c4c33;
    text-decoration-color: #d65a5ae8;
    text-decoration-line: line-through;
  }

  .el-table .proposed-row {
    background: oldlace;
  }

</style>
