import EventService from "@/services/EventService.js";
import TaskService from "@/services/task.js";
import global_store from "@/store/store";
const uuid_base64 = require("uuid-base64");
const uuidv4 = require("uuid/v4");
import _ from 'lodash'
import Common from "@/common.js";
export const namespaced = true;

export const state = {
  lot: {},
  current_ingredient_lot: {}
};

export const mutations = {
  SET_WORKING_LOT(state, data) {
    if (data.equipment_list) {
      global_store.dispatch(
        "equipment/set_equipment_list_checked_out",
        data.equipment_list
      );
    } else {
      global_store.dispatch("equipment/clear_checked_out_equipment");
    }
    state.lot = data;
  },

  SET_INGREDIENT_WEIGHT(state, data) {
    // this is used to directly set a weight.  it requires an ingredient identifier, an ingredient lot identifier, and a new QTY
    // state.lot.ingredients_used[
    //   data.ingredient_identifier
    // ].lots[data.lot_identifier].quantity = data.quantity
    let tmp_state_lot = { ...state.lot };
    tmp_state_lot.ingredients_used[data.ingredient_identifier].lots[
      data.lot_identifier
    ].quantity = data.quantity;
    // maybe this needs to reset the object to be reactive qq
    state.lot = tmp_state_lot;
  },

  RECORD_INGREDIENT_WEIGHT(state, optional_data = {}) {
    // note: this should be "RECORD_INGREDIENT_measurement", weight implies from the balance, but now it is used for any quantity unit
    if (state.current_ingredient_lot.identifier) {
      let current_user =
        global_store.getters["user/get_user"] ||
        global_store.getters["get_user"];
      console.log("CU", current_user);
      console.log({ optional_data });
      let weight_reported =
        (parseFloat(optional_data.manual_measurement) == optional_data.manual_measurement)
        ? optional_data.manual_measurement :
        global_store.getters["equipment/get_balance_weight"] ||
        0
      let current_weight = parseFloat(weight_reported);

      let current_task = global_store.getters['task/get_task']

      let current_formula = current_task && current_task.formula && current_task.formula.data

      console.log('current formula', current_formula)
      console.log('current lot', state.current_ingredient_lot)

      if (current_formula) {
        let selected_ingredient = current_formula.ingredients.filter((ing) => {
          return ing.identifier === state.current_ingredient_lot.ingredient_identifier
        })[0]
        if (selected_ingredient && selected_ingredient.formula_unit_of_measure !== 'C48155'
        && parseFloat(optional_data.manual_measurement) !== current_weight) {
          console.log('not recording weight - ingredient not grams and measurement from balance')
          return
        }
      }
      if (current_weight < 0) {
        console.log('not recording weight - weight is below 0')
        return
      }

      console.log(
        "state.lot.ingredients_used[state.current_ingredient_lot.ingredient_identifier]",
        state.lot.ingredients_used[
          state.current_ingredient_lot.ingredient_identifier
        ]
      );
      let measurement_device = global_store.getters[
        "equipment/get_balance_state"
      ]
      let lab_config = global_store.getters['formula/get_lab_config']
      console.log({ measurement_device })


      // Potential issues we've encountered here:
      /*
        1. QS Ingredients that are marked as mL in the formula, but the ingredient lot is in Gm
          a. We won't check for a valid measurement_device.identifier IF the measurement is a manual_measurement
        2. We don't need to verify the balance will allow_recording, or a valid balance is connected if the ingredient lot is not in Gm
          a. state.current_ingredient_lot.unit_of_measure !== 'C48155' - 'C48155' is the measure code for Grams
      */
      if (
        ((_.isNil(measurement_device.allow_recording) ? true : measurement_device.allow_recording )
          && (measurement_device.identifier || optional_data.manual_measurement))
          ||  state.current_ingredient_lot.unit_of_measure !== 'C48155') {
        if (
          state.lot.ingredients_used[
            state.current_ingredient_lot.ingredient_identifier
          ].lots[state.current_ingredient_lot.identifier].quantity
        ) {
          state.lot.ingredients_used[
            state.current_ingredient_lot.ingredient_identifier
          ].lots[state.current_ingredient_lot.identifier].quantity +=
            current_weight || 0;
        } else {
          state.lot.ingredients_used[
            state.current_ingredient_lot.ingredient_identifier
          ].lots[state.current_ingredient_lot.identifier].quantity =
            current_weight || 0;
        }
        state.lot.ingredients_used[
          state.current_ingredient_lot.ingredient_identifier
        ].log_measurements.push({
          identifier: uuidv4(),
          user: current_user.username,
          name: current_user.name,
          weight: current_weight,
          date_logged: new Date().toJSON(),
          lot_identifier: state.current_ingredient_lot.identifier,
          measurement_device: {
            name: (typeof measurement_device === "object" ? measurement_device.name : '' ),
            identifier: (typeof measurement_device === "object" ? measurement_device.identifier : '' ),
            balance_variance_percentage: (typeof measurement_device === "object" ? measurement_device.balance_variance_percentage : 0)
          },
          balance_variance_percentage: (typeof measurement_device === "object" ? measurement_device.balance_variance_percentage : 0)
        });
      }

      console.log({ measurements: state.lot.ingredients_used[
        state.current_ingredient_lot.ingredient_identifier
      ].log_measurements })
      // find the largest variance allowed: (TODO: this should first determine if there is too much or too little and then choose the largest or smallest amount to decide if it can be considered "in-range")
      let largest_variance = _.orderBy(
        state.lot.ingredients_used[
          state.current_ingredient_lot.ingredient_identifier
        ].log_measurements.filter(val => !isNaN(val.balance_variance_percentage)),
        ['balance_variance_percentage'],
        ['desc']
      )
      if (largest_variance[0]) {
        largest_variance = largest_variance[0].balance_variance_percentage
      } else {
        largest_variance = null
      }
      // set the balance variance:
      state.lot.ingredients_used[
        state.current_ingredient_lot.ingredient_identifier
      ].balance_variance_percentage = largest_variance ||
        global_store.getters[
          "equipment/get_balance_state"
        ].balance_variance_percentage;
    }
  },

  RESET_CURRENT_INGREDIENT_LOT(state) {
    state.current_ingredient_lot = {};
  },

  RESET_LOT(state) {
    state.current_ingredient_lot = {};
    state.lot = {};
  },
  CHECK_IN_INGREDIENT(state, data) {
    // if there is an existing ingredient save the weight data:
    // set this as the active lot we are weighing, for lack of something better at the moment:
    console.log("setting state", data.identifier);
    state.current_ingredient_lot = data;

    if (state.lot.ingredients_used[data.ingredient_identifier]) {
      // exits
      if (
        state.lot.ingredients_used[data.ingredient_identifier].lots[
          data.identifier
        ]
      ) {
        // ingredient lot previously checked-in, just set as active
      } else {
        // add this lot
        let lots = {};
        lots[data.identifier] = {
          ...data,
          date_scanned: new Date().toJSON()
        };

        let new_entry = {};
        // be certain to keep all existing properties
        new_entry[data.ingredient_identifier] = {
          ...state.lot.ingredients_used[data.ingredient_identifier],
          ...{
            lots: {
              ...state.lot.ingredients_used[data.ingredient_identifier].lots,
              ...lots
            }
          }
        };
        state.lot.ingredients_used = {
          ...state.lot.ingredients_used,
          ...new_entry
        };
      }
    } else {
      //new
      let lots = {};
      lots[data.identifier] = {
        ...data,
        date_scanned: new Date().toJSON()
      };
      let new_entry = {};
      new_entry[data.ingredient_identifier] = {
        lots: lots,
        log_measurements: [],
        balance_variance_percentage: 0.0
      };
      state.lot.ingredients_used = {
        ...state.lot.ingredients_used,
        ...new_entry
      };
    }
  }
};

export const actions = {
  refreshLogLoss({ commit, dispatch }, arg){
    console.log('refreshLogLoss arg', arg)
    return EventService.getInventoryNewLoss(arg)
      .then(response => {
        console.log('refreshLogLoss data', response)
        return response.data;
      })
      .catch(error => {
      });
  },
  ingredient_check_in_and_weigh({ commit, dispatch }, data) {
    commit("CHECK_IN_INGREDIENT", data);
  },
  reset_current_ingredient_lot({ commit }) {
    commit("RESET_CURRENT_INGREDIENT_LOT");
  },
  reset_lot({ commit }) {
    commit("RESET_LOT");
  },
  record_ingredient_weight({ commit, dispatch }, optional_data = {}) {
    return new Promise((resolve, reject) => {
      commit("RECORD_INGREDIENT_WEIGHT", optional_data);
      // notify to tare scale:
      const notification = {
        type: "success",
        message: "Weight recorded.  Zero scale before weighing more"
      };
      dispatch("notification/add", notification, { root: true });

      resolve();
    });
  },
  set_ingredient_weight({ commit }, data) {
    commit("SET_INGREDIENT_WEIGHT", data);
  },
  getInventory({ commit, /* getters, */ dispatch }, parameters) {
    // no cache
    console.log('store_getInventory parameters:', parameters)

    let options = {}
    let id
    if (typeof parameters === "object") {
      id = parameters.identifier || parameters.id
      if (typeof parameters.options === "object") {
        options = parameters.options
      }
    } else {
      id = parameters
    }

    console.log('store_getInventory id', id)
    console.log('store_getInventory options', {store_getInventory: options})
    return EventService.getInventory(parameters)
      .then(response => {
        if (response.data.success) {
          commit("SET_WORKING_LOT", response.data.data);
        }
        return response.data.data;
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "There was a problem fetching task: " + error.message
        };
        dispatch("notification/add", notification, { root: true });
      });
  },
  getIngredientInventory({ commit, /* getters, */ dispatch }, id) {
    // NOTED / TODO: this is a copy of getInventory, but sets the ingredient instead of the _lot_
    return EventService.getInventory(id)
      .then(response => {
        console.log("185:", response.data);
        if (response.data.success && _.get(response, 'data.data.identifier')) {
          dispatch("record_ingredient_weight");
          // .then(() => {  })
          commit("CHECK_IN_INGREDIENT", response.data.data);
        } else {
          console.warn("92 FAILED: ", response);
        }
        return response.data;
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "There was a problem fetching data: " + error.message
        };
        dispatch("notification/add", notification, { root: true });
      });
  },

  setFormulaLot({ commit, dispatch }, data) {
    commit("SET_WORKING_LOT", data);
  },

  saveLot({ commit, dispatch }, data) {
    return EventService.recordLot(data)
      .then(response => {
        if (response.data.success) {
          console.log("saved lot");
          data.saved = true;
          commit("SET_WORKING_LOT", data);
          const notification = {
            type: "success",
            message: "Lot saved!"
          };
          dispatch("notification/add", notification, { root: true });
        } else {
          console.warn("92 FAILED:", response);
          if (response.data.messages) {
            dispatch("notification/add", response.data.messages[0], { root: true });
          } else {
            const notification = {
              type: "error",
              message: "The lot could not be saved!"
            };
            dispatch("notification/add", notification, { root: true });
          }
        }
        return response.data.data;
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "There was a problem saving lot: " + error.message
        };
        dispatch("notification/add", notification, { root: true });
      });
  }
};

export const getters = {
  get_task: state => {
    return state.task;
  },
  getCurrentIngredientLot: state => {
    return state.current_ingredient_lot;
  },
  getLot: state => {
    if (Object.entries(state.lot).length) {
      return state.lot;
    } else {
      createFreshLotObject({ task: { data: {} } });
    }
  },
  // generate_lot_label_url: (lot, formula) => {
  //   // let p_demo = {
  //   //   lot_b64: '',
  //   //   product: '21012321011', // NDC
  //   //   product_description: `E2 + E3 + P + DHEA - E2 + E3 + P + DHEA - E2 + E3 + P + DHEA / 1 + 5 + 40 + 25 mg/GM Cream - Versa Pro`,
  //   //   verified_by: 'MMW',
  //   //   beyond_use_date: '2019-07-22',
  //   //   date_compounded: '2019-01-23',
  //   //   location: 'G22-T45',
  //   //   lot_id: 29872
  //   // }
  //   // url = Object.keys(p_demo)
  //   //   .map(function(k) {
  //   //     return encodeURIComponent(k) + '=' + encodeURIComponent(p_demo[k])
  //   //   })
  //   //   .join('&')
  //   // return url
  //   return Common.generate_lot_label_url(lot, formula)
  // },
  getEventById: state => id => {
    return state.tasks.find(event => event.id === id);
  },
  getTasksFiltered: state => {
    return state.tasks_filtered;
  },
  getTaskFilters: state => {
    return state.filters;
  },
  queryTasks: state => {
    // filters => {
    //   filter_list(state.tasks, filters).then(function(list) {
    //     self.commit('task/SET_QUERIED_LIST', list)
    //   })
    return state.queried_task_results;
  }
};

export const createFreshLotObject = function(lot_task = {}) {
  console.log(lot_task);
  let current_user =
    global_store.getters["user/get_user"] || global_store.getters["get_user"];

  let tmp_lot_identifier = uuidv4()
  let tmp_lot_barcode = uuid_base64.encode(tmp_lot_identifier)

  return {
    identifier: tmp_lot_identifier,
    author: current_user.name,
    verified_by: "",
    date_verified: "",
    status: "pending",
    location: "",
    task_id_create_lot: lot_task.task.id,
    lot_id: lot_task.task.id,
    lot_barcode: tmp_lot_barcode,

    date_created: new Date(),
    date_compounded: new Date(),
    options: {},
    note: "",
    beyond_use_date_override: "",

    quantity:
      typeof lot_task.task.data.lot == "object"
        ? lot_task.task.data.lot.quantity
        : undefined,
    ingredients_used: {
      // example:
      // estradiol_powder (actually the identifier for it, 'ab4513c-2801ef'):
      //     { lots: {
      //         '135edf-01abc': {quantity: 5.5, identifier: ..., beyond_use_date: '2019-06-01'},
      //         '4635df-23c1d': {quantity: 5.5, identifier: }
      //         ...
    },
    saved: false
  };
};
