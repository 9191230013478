<template>
  <div>
    <el-card v-if="!uploaded" class="box-card">
      <div slot="header" class="clearfix">
        <span style="font-size: 1.5em">Bulk Formula Import</span>
        <el-button style="float: right; padding: 3px 0" type="text" @click="download_template()"><i class="fad fa-file-spreadsheet" /> Download Template</el-button>
      </div>
      <el-upload
        :action="upload_url"
        drag
        :on-success="process_formulas"
        accept=".csv"
        with-credentials
        name="upload"
        :multiple="false">
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">Upload CSV</div>
      </el-upload>
    </el-card>

    <el-card v-else-if="uploaded" class="box-card mt-10">
      <h1 style="font-size:1.5em;">Results Preview</h1>

      <!-- Failure -->
      <div v-if="!fileData.response.success">
        <div v-for="formula in formulas" :key="formula.formula_id">
          <div v-if="formula.messages" id="messages" class="mt-10 mb-10">
            <span style="font-size: 1.25em;">{{ formula.formula_id }}: {{ formula.label_description || formula.description }} - {{ formula.formula_type_description }}</span>
            <div v-for="(message, m_idx) in formula.messages" :key="m_idx">
              <span :class="message.type"><b>{{ message.type }}:</b> {{ message.message }}</span>
            </div>
            <el-divider></el-divider>
          </div>
        </div>

        <el-button type="primary" @click="reset_import">Try Again</el-button>
      </div>

      <div v-else-if="fileData.response.success">
        <div v-for="(formula, formula_id) in formulas" class="mt-10 mb-10" :key="formula_id">
          <!-- Disabled for now <el-switch v-model="formula.formula_status"/> -->
          &nbsp;
          <span style="font-size: 1.25em;"><b>{{ formula.formula_id }}:</b> {{ formula.label_description }} - {{ formula.formula_quantity }} <BaseUnitOfMeasure :unit="(formula.unit_of_measure)"/> [{{ formula.formula_type_description }}]</span>
          <div class="mt-10 ml-10" v-for="(ingredient, i_indx) in formula.ingredient_list" :key="i_indx">
            <i v-if="ingredient.active_indicator == 1" class="fas fa-scrubber"></i>
            <i v-else class="far fa-scrubber"></i>
            &nbsp;{{ ingredient.code }}: {{ ingredient.quantity }} <BaseUnitOfMeasure :unit="(ingredient.unit_of_measure)"/>
          </div>
          <el-divider></el-divider>
        </div>

        <span style="font-size: 1.2em;">Formula Status:</span>
        <br/>
        <el-switch
          id="badswitch"
          class="mb-10 mt-10"
          v-model="formula_status"
          active-text="Active"
          inactive-text="Review">
        </el-switch>
        <br/>
        <el-button class="mt-10" @click="submit_formula_import" type="primary">Confirm Import</el-button>
        <el-button @click="reset_import">Reset</el-button>


      </div>


    </el-card>

  </div>
</template>

<script>
  import pmk from '@/services/pmk_url_for'
  import EventService from '@/services/EventService'
  import { Notification } from 'element-ui';
  export default {
    data() {
      return {
        fileData: '',
        formulas: [],
        uploaded: false,
        request_id: '',
        formula_status: true
      }
    },
    computed: {
      upload_url() {
        return pmk.url_for('electric_lab_formula_management_upload_csv', { version: 'v5' })
      }
    },
    methods: {
      download_template() {
        const download_url = pmk.url_for('electric_lab_formula_management_template_csv', {version: 'v5'})
        console.log({download_url})
        window.open(download_url,  "_blank")
      },
      process_formulas(res, file) {
        this.fileData = file
        this.request_id = this.fileData.response.original_request_id
        for (const [key, value] of Object.entries(this.fileData.response.formulas)) {
          let new_formula = {
            name: key,
            ...value
          }
          this.formulas.push(new_formula)
        }
        this.uploaded = true
      },
      reset_import() {
        this.fileData = ''
        this.formulas = []
        this.uploaded = false
      },
      submit_formula_import() {
        const self = this
        EventService.pmk_fetch(
          'electric_lab_formula_management_import_accept',
          'PUT',
          { version: 'v5' },
          {
            original_request_id: this.request_id,
            formula_status: (this.formula_status ? "active" : "review")
          }
        ).then( (http_response) => {
          const message = http_response.data.messages[0]
          Notification({
            title: message.type,
            message: message.message,
            duration: 10000,
            type: message.type
          })

        })
      }
    }

  }
</script>

<style>
  #messages .error {
    color: tomato;
  }
  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: #4a596a;
  }
  .el-switch__label {
    position: inherit!important;
  }
  .el-switch__label * {
    color: inherit!important;
  }
</style>
