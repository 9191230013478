
<template>
  <div style="margin-top: 20px;">
    <!-- <div v-for="(task, task_index) in this.queryTasksDirect" v-bind:key="task_index"> -->
      <div>
        <b v-if="related_task_list">Related Tasks ({{ related_task_list.length }})</b>
        <span style="float: right;" class="clickable" @click="update_tasks()">
          <i class="fal fa-sync"></i>
        </span>
        <hr/>
        <div class="related_tasks_area" v-bind:style="styleObject">
          <TaskCard
            v-for="task in related_task_list"
            :key="task.id"
            :task="task"
            :class="`${task.task_due_status} task-priority-${task.task_priority ||
          'default'}`"
          />
        </div>
    </div>
  </div>
</template>

<script>
import url_for from "@/services/pmk_url_for";
import TaskCard from "@/components/TaskCard.vue";

export default {
  props: {
    // tasks: Array,
    filters: Object,
    styleObject: {
      type: Object,
      default: () => {
        return {
          maxHeight: '40em',
          fontSize: '0.8em;'
        }
      }
    }
  },
  components: {
    TaskCard
  },
  data() {
    return {
      tasks: [],
      ticker: 0,
    }
  },
  // asyncComputed: {
  //   async queryTasksDirect() {
  //         console.log('queryTasksDirect')
  //         return this.$store.getters["task/queryTasksDirect"]([], this.filters
  //           // { formula_identifier:this.formula.identifier }
  //         )
  //   }
  // },
  mounted() {
    if (!this.formula_task_list.length) {
      this.update_tasks()
    }
  },
  computed: {
    formula_task_list() {
      return  this.$store.getters["task/getTasksFiltered"]
    },
    related_task_list() {
      return (this.queryTasksDirect || this.formula_task_list).filter(entry => { return !(entry.task_status === 'complete' || entry.task_status === 'completed' || entry.task_status === 'cancelled') }) || []
    }
  },
  asyncComputed: {
    async queryTasksDirect() {
      // returns a promise:
      let val = this.ticker
      return  this.$store.getters["task/queryTasksDirect"]([], this.filters)
          //  let tmp_list =  await this.$store.getters["task/queryTasksDirect"]([], {formula_identifier:this.formula.identifier})
          //  console.log({tmp_list})
          //  return tmp_list
    },
  },
  methods: {
    update_tasks() {
      this.$store
      .dispatch("task/fetchTasks", {}).then( rsp => {
        // update filters to force an update to queryTasksDirect:
        // Do not mutate props, causes console errors
        // this.filters = { ...this.filters }
        this.ticker++
      })
    }
  }
}
</script>

<style scoped>
.task-card {
    position: relative;
    border-radius: 4px;
    padding: 0.65em;
    margin: 5px 0;
    /* height: 114px; */
    box-sizing: border-box;

}
.priority-normal {
    background-color: rgba(144, 147, 153, 0.35);
}
.priority-elevated {
    background-color:rgba(230, 162, 60, 0.35);
}

.priority-urgent {
    background-color:rgba(245, 108, 108, 0.35);
}
.related_tasks_area {
    max-height: 35em;
    overflow-y: scroll;
    font-size: 0.8em;
}
</style>
