import { render, staticRenderFns } from "./VirtualScrollerTable.vue?vue&type=template&id=6198f4c8&scoped=true&"
import script from "./VirtualScrollerTable.vue?vue&type=script&lang=js&"
export * from "./VirtualScrollerTable.vue?vue&type=script&lang=js&"
import style0 from "./VirtualScrollerTable.vue?vue&type=style&index=0&id=6198f4c8&scoped=true&lang=css&"
import style1 from "./VirtualScrollerTable.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6198f4c8",
  null
  
)

export default component.exports