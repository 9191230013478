<template>
  <div>
    <el-card class="box-card formula_search_area">
      <span style="font-size: 1.25em;">Search By:</span>

      <span style="float: right;">
        <searchFormulaTypes @selectType="set_formula_type" />
      </span>

      <el-tabs v-model="active_tab">
        <el-tab-pane label="Name/NDC" name="formulas">
          <searchFormulas :formulaType="formula_type" />
        </el-tab-pane>
        <el-tab-pane label="Ingredients" name="ingredients">
          <searchFormulaIngredients :formulaType="formula_type" />
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
import searchFormulas from './search_formulas'
import searchFormulaIngredients from './search_formula_ingredients'
import searchFormulaTypes from './search_formula_types'

export default {
  components: {
    searchFormulas,
    searchFormulaIngredients,
    searchFormulaTypes
  },
  computed: {
    formula_types: {
      get() {
        if (!this.$store.getters["formula/get_lab_config"]) {
          this.$store.dispatch("formula/fetchLabConfig");
        }
        if (this.$store.getters["formula/get_lab_config"]) {
          return this.$store.getters["formula/get_lab_config"]["config"][
            "formula_types"
          ];
        } else return {};
      }
    }
  },
  data() {
    return {
      active_tab: 'formulas',
      formula_type: null
    }
  },
  methods: {
    set_formula_type(type) {
      this.formula_type = type
    }
  }
}
</script>

<style>
.max_width_is_screen {
    max-width: -webkit-fill-available;
  }
</style>
