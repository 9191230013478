<template>
  <span >{{ route_display() }}</span>
</template>

<script>
export default {
  props: {
    route: String
  },
  methods: {
    route_display() {
      let display_unit = this.route_of_administration.get(this.route) || this.route;
      return display_unit;
    }
  },
  computed: {
    route_of_administration: {
      get() {
        let unit_list = this.$store.getters["terminology/terminology"][
          "route_of_administration"
        ];
        if (!unit_list) {
          this.$store.dispatch("terminology/fetchMedicationTerminologies");
          unit_list = this.$store.getters["terminology/terminology"][
            "route_of_administration"
          ];
        }
        let map = new Map(
          unit_list.map(i => [i.cid, i.display || i.description || i.display_text])
        );
        return map;
      }
    }
  }
};
</script>




<style scoped>
</style>