<template>
  <div class="formula-ingredients">
    <el-row>
      <div style="margin-top: 15px;">
        <el-col :span="12">
          <div class="sub-title">Ingredients</div>
          <el-autocomplete
            class="inline-input"
            v-model="form.new_ingredient.description"
            :fetch-suggestions="search_ingredients"
            placeholder="Search..."
            :trigger-on-focus="false"
            @select="add_ingredient"
            value-key="description"
            style="width: 50em;"
            clearable
            autocapitalize="none"          >
            <template slot="append">
              <i class="fal fa-search"></i>
            </template>
          </el-autocomplete>
        </el-col>
      </div>
    </el-row>
    <el-row class="ingredient-header">
      <div style="margin-top: 15px;">
        <el-col :span="5">
          <div>Description</div>
        </el-col>
        <el-col :span="1">&nbsp;</el-col>

        <el-col :span="5">
          Require Measurement Check
          <el-tooltip
            class="helper-instructions"
            effect="dark"
            content="Check this for items that can not be read by equipment or need a pharmacist review"
          >
            <el-button type="text" size="mini">
              <i class="fal fa-question-circle"></i>
            </el-button>
          </el-tooltip>
          <br>
          Quantity Sufficient
          <el-tooltip
            class="helper-instructions"
            effect="dark"
            content="Check this for items that do not have a specific quantity, e.g. add enough water to be 60mL"
          >
            <el-button type="text" size="mini">
              <i class="fal fa-question-circle"></i>
            </el-button>
          </el-tooltip>
          <br>
          <span v-if="needs_activity_factor_modifying_ingredient">
            Activity Factor Modifying Ingredient
            <el-tooltip
              class="helper-instructions"
              effect="dark"
              content="Check this for items that will have their weight modified to account for other ingredient's activity factor"
            >
              <el-button type="text" size="mini">
                <i class="fal fa-question-circle"></i>
              </el-button>
            </el-tooltip>
          </span>
          <br>
          <span v-if="needs_excipient_ingredient">
            Pack Stat Excipient Ingredient
            <el-tooltip
              class="helper-instructions"
              effect="dark"
              content="Check this for items that will have their weight modified to account for other ingredient's pack stat"
            >
              <el-button type="text" size="mini">
                <i class="fal fa-question-circle"></i>
              </el-button>
            </el-tooltip>
          </span>

        </el-col>
        <el-col :span="9">Quantity</el-col>
        <el-col :span="4">
          <el-dropdown @command="changeCol">
            <span class="el-dropdown-link">
              {{extra_col}}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :disabled="extra_col === 'Details'" command="Details">
                Details
              </el-dropdown-item>
              <el-dropdown-item :disabled="extra_col === 'Unit Cost'" command="Unit Cost">
                Unit Cost
              </el-dropdown-item>
              <el-dropdown-item :disabled="extra_col === 'Control Level'" command="Control Level">
                Control Level
              </el-dropdown-item>
              <el-dropdown-item :disabled="extra_col === 'Type'" command="Type">
                  Type
              </el-dropdown-item>
              <el-dropdown-item :disabled="extra_col === 'Hazardous'" command='Hazardous'>
                Hazardous
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </el-col>
      </div>
    </el-row>
    <draggable v-model="localIngr"  @start="drag=true" @end="drag=false" :disabled="! ingredient_re_order_enabled" handle=".handle">
    <el-row v-for="(ingredient, index) in localIngr" :key="ingredient.code" class="formula-ingredient-row" :gutter="20">
      <div style="margin-top: 15px;">
        <el-col :span="5">
          <div class="hover-only-parent">
            <span class="hover-only"><i class="fad fa-sort handle "></i>&nbsp;</span>
            <span class="ingredient-description">{{ ingredient.ingredient_type == 'formula' ? ingredient.name : ingredient.description || ingredient.name }}</span>
            <span v-if="ingredient.active_indicator">
              <el-tooltip class="item" effect="dark" content="Ingredient is Active" placement="top">
                <el-button size="small" type="text"><i class="fad fa-prescription-bottle-alt" style="margin-left:5px;margin-right:5px;"></i></el-button>
              </el-tooltip>
            </span>
            <span v-if="ingredient.has_activity_factor">
              <el-tooltip class="item" effect="dark" content="Ingredient has Activity Factor" placement="top">
                <el-button size="small" type="text"><i class="fad fa-chart-network" style="margin-left:5px;margin-right:5px;"></i></el-button>
              </el-tooltip>
            </span>
            <span v-if="ingredient.has_pack_stat && needs_excipient_ingredient">
              <el-tooltip class="item" effect="dark" content="Ingredient has Pack Stat" placement="top">
                <el-button size="small" type="text"><i class="fal fa-capsules" style="margin-left:5px;margin-right:5px;"></i></el-button>
              </el-tooltip>
            </span>
            &nbsp;
            <i
              class="fal fa-minus-circle clickable ingredient-remove"
              @click="remove_ingredient(index, ingredient)"
            ></i>
          </div>
          <div>Code: {{ ingredient.code }}</div>
        </el-col>
        <el-col :span="1">&nbsp;</el-col>

        <el-col :span="5">
          <div>
            <el-checkbox v-model="ingredient.requires_measurement_check">Check</el-checkbox>
          </div>
          <div>
            <el-checkbox v-model="ingredient.quantity_sufficient_indicator">QS</el-checkbox>
          </div>
          <div v-if="needs_activity_factor_modifying_ingredient && !ingredient.active_indicator && !ingredient.has_activity_factor && (!has_activity_factor_ingredient() || ingredient.activity_factor_modifying_ingredient)">
            <el-checkbox
              v-model="ingredient.activity_factor_modifying_ingredient">
              Activity Factor Modifying Ingredient
            </el-checkbox>
          </div>
          <div v-if="(needs_excipient_ingredient && !ingredient.active_indicator && !ingredient.has_activity_factor && (!has_pack_stat_excipient_ingredient() || ingredient.pack_stat_excipient_ingredient)) || ingredient.pack_stat_excipient_ingredient">
            <el-checkbox
              v-model="ingredient.pack_stat_excipient_ingredient">
              Pack Stat Excipient Ingredient
            </el-checkbox>
          </div>

        </el-col>
        <el-col :span="9">
          <el-input-number
            style="width: 47.5%; margin-right: 4%; text-align: right !important; max-width: 10em;"
            v-model="ingredient.quantity"
            :precision="decimal_places"
            :step="0.1"
            :max="10000000000"
            :controls="false"
            type="number"
            inputmode="decimal"
          ></el-input-number>
          <span v-if="locked" class="unit-of-measure">
            <BaseUnitOfMeasure
              :unit="(ingredient.formula_unit_of_measure || ingredient.unit_of_measure)"
            />
          </span>
          <span v-else style="width: 47.5%">
            <el-select v-model="ingredient.formula_unit_of_measure" filterable placeholder="Units" style="width: 47.5%">
              <el-option
                v-for="item in terminology.unit_of_measure"
                :key="item.code"
                :label="item.display || item.description"
                :value="item.code"
              >
                <span style="float: left">{{ item.display || item.description }}</span>
                <span style="float: right; color: #8492a6; font-size: 0.85em">{{ item.description }}</span>
              </el-option>
            </el-select>
          </span>
        </el-col>

        <el-col :span="4">
          <div v-if="extra_col === 'Unit Cost'" style="padding: 0px 15px;">
            <el-tooltip
              placement="top-start"
            >
              <el-button type="text" style="color:#303133">
                ${{(((ingredient.primary_billing_unit_cost || 0) * ingredient.quantity) / local_formula_quantity).toFixed(4)}}/unit
              </el-button>
              <div slot="content">${{ingredient.primary_billing_unit_cost || 'N/A'}} Unit Cost</div>
            </el-tooltip>
          </div>
          <div v-if="extra_col === 'Control Level'" style="padding: 0px 15px;">
            {{ingredient.dea_schedule && ingredient.dea_schedule.description}}
          </div>
          <div v-if="extra_col === 'Type'" style="padding: 0px 15px;">
            {{ingredient.ingredient_type[0].toUpperCase() + ingredient.ingredient_type.substring(1)}}
          </div>
          <div v-if="extra_col === 'Hazardous'" style="padding: 0px 15px;">
            {{ingredient.is_hazardous ? "Hazardous" : 'Non-Hazardous'}}
          </div>
        </el-col>

      </div>
    </el-row>
    </draggable>
    <el-row style="border-top: 1px solid silver; margin-top: 1em;" :gutter="20">
      <div style="margin-top: 15px;">
        <el-col :span="10">&nbsp;</el-col>
        <el-col :span="10">
          <div class="total-weight">Total Weight: {{parseFloat(calculate_total()).toFixed(decimal_places)}}</div>
        </el-col>
        <el-col :span="4" v-if="extra_col === 'Unit Cost'">
          <el-tooltip
            placement="top-start"
          >
            <el-button type="text" style="color:#303133">
              ${{parseFloat(unit_formula_total_cost).toFixed(decimal_places)}}/unit
            </el-button>
            <div slot="content">${{parseFloat(unit_formula_total_cost * local_formula_quantity).toFixed(decimal_places)}}/batch</div>
          </el-tooltip>
        </el-col>
      </div>
    </el-row>
    <el-row>
      <div style="margin-top: 15px;">
        <el-col :span="11">&nbsp;</el-col>
        <el-col :span="13">
          <el-row style="display: flex; flex-direction: row;justify-content: space-evenly;" :gutter="20">
            <div class="formuala-quantity">
              <el-tooltip
                class="item"
                effect="dark"
                content="Number of units produced"
                placement="top-start"
              >
                <b>Quantity: &nbsp;</b>
              </el-tooltip>
              <el-input-number
                style="width: 9em;"
                v-model="local_formula_quantity"
                controls-position="right"
                :min="0.1"
                :max="10000000000"
                :controls="false"
                type="number"
                inputmode="decimal"
              ></el-input-number>
            </div>
            <div class="formuala-quantity">
              <!-- nbsp here to force it down, TODO: use CSS -->
              <!-- <div>&nbsp;</div> -->
              <el-select v-model="local_unit_of_measure" filterable clearable placeholder="Units" style="width: 9em;">
                <el-option
                  v-for="item in terminology.unit_of_measure"
                  :key="item.code"
                  :label="item.display || item.description"
                  :value="item.code"
                >
                  <span style="float: left">{{ item.display || item.description }}</span>
                  <span style="float: right; color: #8492a6; font-size: 0.85em">{{ item.description }}</span>
                </el-option>
              </el-select>
            </div>
          </el-row>
        </el-col>
      </div>
    </el-row>
  </div>
</template>

<script>
import NProgress from "nprogress";
import draggable from 'vuedraggable'

export default {
  name: "ings",
  components: {
      draggable
  },
  props: [
    "ingredients",
    "terminology",
    "locked",
    "formula_quantity",
    "unit_of_measure",
    "needs_activity_factor_modifying_ingredient",
    "needs_excipient_ingredient"
  ],
  data() {
    return {
      drag: false,
      localIngr: JSON.parse(JSON.stringify(this.ingredients)),
      local_formula_quantity: this.formula_quantity,
      local_unit_of_measure: this.unit_of_measure,
      ingredient_re_order_enabled: ! this.locked,
      extra_col: 'Details',
      form: {
        new_ingredient: {
          description: ''
        },
      }
    };
  },
  mounted() {},
  watch: {
    localIngr: {
      handler(v) {
        this.$emit("updateIngredients", v);
      },
      deep: true
    },
    local_formula_quantity(v) {
      this.$emit("updateFormulaQty", v);
    },
    local_unit_of_measure(v) {
      this.$emit("updateFormulaUnit", v);
    }
  },
  computed: {
    decimal_places() {
      return _.get(this.$store.getters['formula/get_lab_config'].config, 'lot.decimal_places', 3)
    },
    unit_formula_total_cost() {
      let total_cost = 0
      Object.values(this.localIngr).forEach(ing => {
        total_cost += ing.quantity * (ing.primary_billing_unit_cost || 0)
      })
      return (total_cost/this.local_formula_quantity).toFixed(4)
    }
  },
  methods: {
    changeCol(val) {
      this.extra_col = val
    },
    has_activity_factor_ingredient() {
      let data = this.localIngr.filter(ing => ing.activity_factor_modifying_ingredient)
      return data.length
    },
    has_pack_stat_excipient_ingredient() {
      let data = this.localIngr.filter(ing => ing.pack_stat_excipient_ingredient)
      return data.length
    },
    search_ingredients(queryString, cb) {
      // here we need to query the ingredient list API for manufacturer identifiers
      let query_object = {
        explicit: 0,
        method: "LIKE",
        description: queryString,
        include_formulas: 1
      };
      NProgress.start();
      let formula = this.$store.getters['formula/get_formula']
      this.$store
        .dispatch("ingredient/listIngredients", query_object)
        .then(() => {
          cb(
            this.$store.getters["ingredient/ingredient_search_list"].map(
              item => item.data
            ).filter((obj) => obj.identifier !== formula.identifier && obj.ingredient_type === 'formula' ? obj.formula_status !== 'retired' : (obj.ingredient_type === 'ingredient' ? obj.ingredient_status !== 'retired' : true))
          );
        })
        .then(() => NProgress.done())
        .catch(() => {
          NProgress.done();
        });
    },
    add_ingredient(selected_ingredient) {
      // this.form.new_ingredient = selected_ingredient
      this.localIngr.push(selected_ingredient);
      //selected_ingredient = {}
      this.form.new_ingredient.description = "";
      return;
    },
    remove_ingredient(ingredient_index, ingredient) {

      this.localIngr.splice(ingredient_index, 1);
    },
    calculate_total() {
      let total_quantity = 0;
      this.localIngr.forEach(function(ingredient) {
        let unit_of_measure = (
          ingredient.formula_unit_of_measure ||
          ingredient.unit_of_measure ||
          ""
        ).toUpperCase();
        if (["C28252", "C28253", "C48155"].includes(unit_of_measure)) {
          total_quantity += parseFloat(ingredient.quantity) || 0;
        }
      });
      return total_quantity;
    }
  }
};
</script>

<style>
.ingredient-description { word-break: break-word; }
.formula-ingredient-row .ingredient-description:hover {
  color: #315a8a;
 }
.ingredient-header {
  font-weight: bold;
  font-size: 1em;
}

.ingredient-remove:hover {
  color: rgb(207, 13, 13);
}

.hover-only {
  /* visibility: hidden; */
  display: none;
}
.hover-only-parent:hover .hover-only {
  cursor: grab;
  /* visibility: visible; */
  display: inline-block;
  color: #315a8a;
}
</style>
