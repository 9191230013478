<template>
    <div class="home" id="VirtualScrollerTable" style="width: fit-content">
      <div class="btn-container">
        <div style="float:left">
          <span class="reports_title">
            <div class="report-heading">{{title}}</div>
            Report for dates: {{start_date | moment('YYYY-MM-DD')}} - {{end_date | moment('YYYY-MM-DD')}} [{{tableData.length}}]
          </span>
        </div>
        <div style="float:right" v-if="title === 'Expiring Lots'">
          <el-button @click="bulk_log_inventory_reduction_dialog_visible = true" :disabled="!bulk_removal_data || !Object.keys(bulk_removal_data).length">
            <i class="fal fa-minus-circle" ></i>
            Bulk Remove
          </el-button>
        </div>
      </div>
      <el-table
        v-if="true"
        :data="emptyArray"
        style="width: 100%; overflow-x:scroll"
        :fit="true"
        class="break-word-table">
        <el-table-column
          v-for="column in columns"
          :key="column"
          :width="calculated_column_width(column)"
          :label="headers[column]"
          class="break-word">
        </el-table-column>
        <el-table-column
          v-if="title === 'Expiring Lots'"
          width="90px"
          label="Remove"
          class="break-word">
        </el-table-column>
        <el-table-column
          v-if="title === 'Expiring Lots'"
          width="90px"
          label="Bulk Remove"
          class="break-word">
        </el-table-column>
        <template slot="append">
         <DynamicScroller
          :items="tableData"
          :min-item-size="110"
          class="scroller Rtable Rtable--3cols "
          :key-field="'row_identifier'"
          :buffer="200"
          style="overflow-x: auto"
          >
            <template slot-scope="{ item, index, active }" v-if="tableData.length > 0">
              <DynamicScrollerItem
                :item="item"
                :index="index"
                :active="active"
                :data-index="index"
                :data-active="active"
                class="message"
              >
                <div class="Rtable-cell"
                  v-for="column in columns"
                  :key="column" :class="[
                    'break-word' ,
                    (css_classes[column] ? css_classes[column] : ''),
                    (column_format[column] && column_format[column].classes ? column_format[column].classes : '')]"
                  :style="{ width: calculated_column_width(column)}"
                >
                    <span v-if="field_options[column] && field_options[column].html" v-html="item[column]"></span>
                    <span v-else-if="field_options[column] && field_options[column].url">
                      <a v-if="item[column]" @click="open_entry(field_options[column].url, item, column)" target="_blank"><i class="fad fa-external-link-square"></i></a>
                      <!-- <pre>{{ {[field_options[column].url.parameter_name]: item[column]} }}</pre> -->
                    </span>
                    <span v-else>{{item[column]}} {{field_options[column]}}</span>
                </div>
                <div
                  v-if="title === 'Expiring Lots'"
                  class="Rtable-cell"
                  style="width: 90px"
                >
                  <span v-if="item.removed_from_inventory">
                    <i class="fas fa-check-circle" style="color: lightseagreen;padding-top: 10px;"></i>
                  </span>
                  <el-button v-else type="text"  @click="open_log_inventory_reduction(item)">
                    <i class="fal fa-minus-circle clickable remove" ></i>
                  </el-button>
                </div>
                <div
                  v-if="title === 'Expiring Lots'"
                  class="Rtable-cell"
                  style="width: 90px"
                >
                  <el-checkbox v-model="bulk_removal[item.identifier]" :disabled="item.removed_from_inventory" @change="select_bulk_removal(bulk_removal[item.identifier], item)">
                  </el-checkbox>
                </div>
              </DynamicScrollerItem>
            </template>
          </DynamicScroller>
        </template>
      </el-table>
      <LogInventoryPrompt @closing="closeModal"
        :dialogVisible="log_inventory_reduction_dialog_visible" :identifier="lot_to_remove.identifier"
        :quantity_unit="lot_to_remove.unit_of_measure" :quantity_remove="lot_to_remove.quantity_remaining"
        from_report>
      </LogInventoryPrompt>
      <BulkLogInventoryPrompt @closing="closeModal"
        :dialogVisible="bulk_log_inventory_reduction_dialog_visible"
        :removal_data="bulk_removal_data">
      </BulkLogInventoryPrompt>
    </div>
</template>
<script>
import { DynamicScroller } from "vue-virtual-scroller";
import LogInventoryPrompt from "@/components/ingredient/log_inventory_prompt.vue";
import BulkLogInventoryPrompt from '@/components/ingredient/bulk_log_inventory_prompt.vue'
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";
export default {
    components: {
        DynamicScroller,
        LogInventoryPrompt,
        BulkLogInventoryPrompt,
    },
    props: [
      'tableData',
      'headers',
      'columns',
      'field_options',
      'css_classes',
      'start_date',
      'end_date',
      'identifier',
      'column_format',
      'title'
    ],
    data(){
      return {
        emptyArray: null,
        log_inventory_reduction_dialog_visible: false,
        bulk_log_inventory_reduction_dialog_visible: false,
        lot_to_remove: {},
        bulk_removal: {},
        bulk_removal_data: {}
      }
    },
    methods: {
      select_bulk_removal(value, item) {
        if (value) {
          this.bulk_removal_data[item.identifier] = item.quantity_remaining
        } else {
          delete this.bulk_removal_data[item.identifier]
        }
        this.force()
      },
      force(){
        this.$forceUpdate()
      },
      format_date(date_entry) {

      },
      create_href(url_params={}, data) {
        let url_for_params = { version: 'v5' }
        url_for_params[url_params.parameter_name] = data[url_params.parameter_name]
        let url_link = url_for(url_params.name, url_for_params)
        return url_link
      },
      open_entry(url_params, data, column) {
        let route_params = {}
        route_params[url_params.parameter_name] = data[column]
        this.$router.push({ name: url_params.name, params: route_params })
        // const url_link = this.create_href(url_params, data)
        // const windowObjectReference = window.open(url_link, "Report Entry | ElectricLab");
      },
      calculated_column_width(column) {
        let column_width=''
        if (this.column_format[column] && this.column_format[column].width) {
          column_width = this.column_format[column].width.fit ? '' : this.column_format[column].width.px + 'px!important'
        } else {
          column_width = '120px'
        }
        return column_width
      },
      open_log_inventory_reduction(item) {
        this.lot_to_remove = item
        this.log_inventory_reduction_dialog_visible = true
      },
      closeModal(entry){
        console.log('1555 removed', entry)
        this.log_inventory_reduction_dialog_visible = entry.dialogVisible
        this.bulk_log_inventory_reduction_dialog_visible = entry.dialogVisible
        if (entry.removed) {
          if (this.lot_to_remove && this.lot_to_remove.identifier) {
            let row_index = _.findIndex(this.tableData, ['identifier', this.lot_to_remove.identifier])
            this.tableData[row_index].removed_from_inventory = true
          } else if (this.bulk_removal_data && Object.keys(this.bulk_removal_data).length) {
            Object.keys(this.bulk_removal_data).forEach((lot) => {
              let row_index = _.findIndex(this.tableData, ['identifier', lot])
              this.tableData[row_index].removed_from_inventory = true
            })
            this.bulk_removal_data = {}
          }

        }
        this.lot_to_remove = {}
        this.force()
      },
    }
}
</script>
<style scoped>
.dynamic-scroller-demo,
.scroller {
    width: 100%;
  height: 700px;
  overflow-y: auto;
}
.dynamic-scroller-demo {
  overflow: hidden;
}
.notice {
  padding: 24px;
  font-size: 20px;
  color: #999;
}
.message {
      border-top: 1px solid gray;
  display: -webkit-box;
  min-height: 32px;
  padding: 2px;
  box-sizing: border-box;
}
.index,
.text {
  flex: 1;
}
.text {
  max-width: 400px;
}
.index span {
  display: inline-block;
  width: 160px;
  text-align: right;
}
.Rheader {
  display: flex;
  width: 100%;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  hyphens: inherit;
  box-sizing: border-box;
}
.Rtable-cell,
.Rtable-cellfull {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  hyphens: inherit;
  box-sizing: border-box;
  flex-grow: 1;
  text-align: left;

  padding: 4px;
  list-style: none;
}

.numeric {
  text-align: right !important;
}

.Rtable-cell.thead{
  font-weight: 700;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  hyphens: inherit;
  box-sizing: border-box;
}
.Rtable-cellfull {
  box-sizing: border-box;
  flex-grow: 1;

  padding: 4px;
  overflow: hidden;
  list-style: none;
  height: 100px;
  background-color: white;
}
.big {
  height: 200px;
  background-color: rgb(124, 124, 145);
}
.Rtable-cell > h1,
.Rtable-cell > h2,
.Rtable-cell > h3,
.Rtable-cell > h4,
.Rtable-cell > h5,
.Rtable-cell > h6 {
  margin: 0;
}
/* Table column sizing
================================== */
.Rtable--2cols .Rtable-cell {
  width: 50%;
}
.Rtable--3cols .Rtable-cell {
  width: 8%;
}
.Rtable--4cols .Rtable-cell {
  width: 25%;
}
.Rtable--5cols .Rtable-cell {
  width: 20%;
}
.Rtable--6cols .Rtable-cell {
  width: 16.6%;
}
.Rtable-cellfull {
  width: 100%;
}

.btn-container{
  width: 100%;
  margin-bottom: 5vh;
  text-align: right;
  display: block;
}
.reports_title{
  font-weight:700
}
.cell{
  padding-left: 2px!important;
  padding-right: 2px!important;
  text-align: center!important;
}
.el-table__empty-block{
  display: none!important;
}

.report-heading{
  text-align: left !important;
  display: block;
  font-size: 1.2em;
  font-weight: bold;
}
@media print {
			  .print-hide {
			    display: none
			  }
			}
</style>
<style>
.vue-recycle-scroller__item-wrapper {
  overflow-x: auto;
}
.el-table::after, .el-table::before {
    background-color: transparent !important;
}
</style>
