<template>
<span>
  <el-dialog title="Upload Document" :visible.sync="dialogUploadDocument" @close="closingModal" :append-to-body="true" :close-on-press-escape="!uploading" :close-on-click-modal="!uploading">
    <el-row>
      <el-col>
        <div style="margin-top: 15px;">
          <div>Category:</div>
          <el-select v-model="form.category" placeholder="Select a category" v-if="categoriesList">
            <el-option
              v-for="(item,index) in categoriesList"
              :key="index"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <div style="margin-top: 15px;">
          <div>Name:</div>
          <el-input placeholder="Name [Optional]" v-model="form.name"></el-input>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <div style="margin-top: 15px;">
          <div>Note:</div>
          <el-input placeholder="Note [Optional]" v-model="form.note"></el-input>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <div style="margin-top: 15px;">
          <el-upload
            ref="uploadPlug"
            class="upload-demo"
            drag
            :action="upload_url()"
            :http-request="handleUpload"
            :data="formatted_document_information()"
            :on-preview="handlePreview"
            :on-remove="handleRemove"
            :on-change="handleChange"
            :on-success="handleSuccess"
            :file-list="fileList"
            with-credentials
            multiple
          >
            <i class="el-icon-upload"></i>
            <div class="el-upload__text">
              Drop file here or
              <em>click to upload</em>
            </div>
            <div class="el-upload__tip" slot="tip">Add name and notes <i>before</i> uploading file</div>
          </el-upload>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col>
        <el-button @click="dialogUploadDocument = false" :disabled="uploading">Close</el-button>
      </el-col>
    </el-row>
  </el-dialog>
  <span>
    <span style="font-size: 1.75em;" class="clickable " @click="dialogUploadDocument = true">
      <!--<i :class="icon_class || 'fas fa-cloud-upload'">
        &nbsp;
      </i>-->
      <span style="font-size: 1rem; font-family: Nunito, sans-serif;margin-left:10px;"><i class="far fa-file-alt"></i>&nbsp;{{label || 'Upload'}}</span>
    </span>
  </span>
</span>
</template>

<script>
import url_for from "@/services/pmk_url_for";

function getBody(xhr) {
  const text = xhr.responseText || xhr.response
  if (!text) {
    return text
  }

  try {
    return JSON.parse(text)
  } catch (e) {
    return text
  }
}

export default {
  noprops: {
    value: Object,
    readonly: Boolean,

    category: String,
    label: String,
    categories: Array
  },
  props: ['documents','icon_class','label'],
  data() {
    return {
      // label: 'Upload',
      identifier: null,
      toSend : null,
      uploading: false,
      finished_upload: false,
      dialogUploadDocument: false,
      categoriesList : null,
      fileList: [], //this.filter_file_list_from_documents(),
      form: {
        name: "",
        category: this.category,
        note: ""
      },
      some_document_information: {
        information: {
          //		        a: "NEXT STEPS: store a category, maybe an identifier reference and relationship type, a note",
          b:
            "Further steps: must return a document identifier back to the patent"
        }
      }
    };
  },
  watch: {
    identifier(old,newVal){
      console.log(old)


    },
    dialogUploadDocument() {
      this.uploading = false
      this.finished_upload = false
    }
  },
  mounted(){
     console.log(this.$attrs)
    if(this.$attrs.category == undefined){
      this.categoriesList = this.$attrs.categories
      if (this.$attrs.categories[0]) {
        this.form.category = this.$attrs.categories[0].value
      }

    }
  },
  methods: {
    loadMime(file, callback) {
      //List of known mimes
      var mimes = [
        {
          mime: 'image/jpeg',
          pattern: [0xff, 0xd8, 0xff],
          mask: [0xff, 0xff, 0xff],
        },
        {
          mime: 'image/png',
          pattern: [0x89, 0x50, 0x4e, 0x47],
          mask: [0xff, 0xff, 0xff, 0xff],
        },
        {
          mime: 'application/pdf',
          pattern: [0x25, 0x50, 0x44, 0x46, 0x2d],
          mask: [0xff, 0xff, 0xff, 0xff, 0xff],
        },
        {
          mime: 'image/gif',
          pattern: [0x47, 0x49, 0x46, 0x38, 0x37, 0x61],
          mask: [0xff, 0xff, 0xff, 0xff, 0xff, 0xff],
        },
        {
          mime: 'image/gif',
          pattern: [0x47, 0x49, 0x46, 0x38, 0x39, 0x61],
          mask: [0xff, 0xff, 0xff, 0xff, 0xff, 0xff],
        },
        {
          mime: 'image/bmp',
          pattern: [0x42, 0x4d],
          mask: [0xff, 0xff],
        },
        {
          mime: 'image/webp',
          pattern: [
            0x52,
            0x49,
            0x46,
            0x46,
            0x00,
            0x00,
            0x00,
            0x00,
            0x57,
            0x45,
            0x42,
            0x50,
            0x56,
            0x50,
          ],
          mask: [
            0xff,
            0xff,
            0xff,
            0xff,
            0x00,
            0x00,
            0x00,
            0x00,
            0xff,
            0xff,
            0xff,
            0xff,
            0xff,
            0xff,
          ],
        },
        {
          mime:
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          pattern: [0x50, 0x4b, 0x03, 0x04],
          mask: [0xff, 0xff, 0xff, 0xff],
        },
        {
          mime: 'application/msword',
          pattern: [0xd0, 0xcf, 0x11, 0xe0, 0xa1, 0xb1, 0x1a, 0xe1],
          mask: [0xff, 0xff, 0xff, 0xff, 0xff, 0xff, 0xff, 0xff],
        },
        {
          mime: 'image/heic',
          pattern: [
            0x00,
            0x00,
            0x00,
            0x24,
            0x66,
            0x74,
            0x79,
            0x70,
            0x68,
            0x65,
            0x69,
            0x63,
            0x00,
            0x00,
            0x00,
          ],
          mask: [
            0x00,
            0x00,
            0x00,
            0xff,
            0xff,
            0xff,
            0xff,
            0xff,
            0xff,
            0xff,
            0xff,
            0xff,
            0x00,
            0x00,
            0x00,
          ],
        },
        // you can expand this list @see https://mimesniff.spec.whatwg.org/#matching-an-image-type-pattern
      ]

      function check(bytes, mime) {
        for (var i = 0, l = mime.mask.length; i < l; ++i) {
          if ((bytes[i] & mime.mask[i]) - mime.pattern[i] !== 0) {
            return false
          }
        }
        return true
      }

      var blob = file.slice(0, 15) //read the first 15 bytes of the file

      var reader = new FileReader()
      reader.onloadend = function (e) {
        if (e.target.readyState === FileReader.DONE) {
          var bytes = new Uint8Array(e.target.result)

          for (var i = 0, l = mimes.length; i < l; ++i) {
            if (check(bytes, mimes[i])) return callback(mimes[i].mime)
          }

          return callback(false)
        }
      }
      reader.readAsArrayBuffer(blob)
    },
    async handleUpload(option) {
      this.uploading = true
      console.warn('handleUpload', Object.assign({}, option))
      option.action = this.upload_url()
      const xhr = new XMLHttpRequest()

      if (xhr.upload) {
        xhr.upload.onprogress = (e) => {
          if (e.total > 0) {
            e.percent = (e.loaded / e.total) * 100
          }
          option.onProgress(e)
        }
      }

      let mime

      return this.loadMime(option.file, (file_mime) => {
        console.log('got file mime of', file_mime)
        mime = file_mime
        const formData = new FormData()
        _.each(option.data, (val, key) => {
          formData.append(key, val)
        })

        formData.append('mime', mime || option.file.type)

        formData.append(option.filename, option.file, option.file.name)

        xhr.onerror = option.onerror
        xhr.onload = () => {
          if (xhr.status < 200 || xhr.status >= 300) {
            return option.onError(getError(option.action, option, xhr))
          }
          option.onSuccess(getBody(xhr))
          //option.onSuccess(getBody(xhr))
        }

        xhr.open('post', option.action, true)

        if (option.withCredentials && 'withCredentials' in xhr) {
          xhr.withCredentials = true
        }

        const headers = option.headers || {}

        for (const item in headers) {
          if (headers.hasOwnProperty(item) && headers[item] !== null) { // eslint-disable-line
            xhr.setRequestHeader(item, headers[item])
          }
        }
        xhr.send(formData)
        return xhr
      })
    },
    removeElements(elms) { elms.forEach(el => el.remove())},
    closingModal(){
      console.log('closing Modal')
      this.form.note = "";
      this.form.name = "";
      this.$refs.uploadPlug.clearFiles()
    },
    filter_file_list_from_documents() {
      _.filter(this.documents, { category: this.category })
    },

    document_information() {
      return {
        category: this.form.category,
        name: this.form.name,
        note: this.form.note
      };
    },
    show_upload_dialog() {},
    upload_url() {
      const url = url_for.url_for('electric_lab_document_save', {version: 'v5'})
      // "https://training.pharmetika.com/api/pharmetika/v5/electric_lab/document"; // + url_for('electric_lab_document_save')
      // console.log(152, url);
      return url;
    },
    formatted_document_information() {
      let return_data =
        this.document_information() ||
        JSON.stringify(this.document_information());
      return return_data;
    },
    handleRemove(file, fileList) {},
    handlePreview(file, fileList) {},
    handleChange(file, fileList) {
      // console.log('FILE:')
      // console.log(file)
      // console.log(this.form.name)
      if (!this.finished_upload) {
        this.uploading = true
      }
      if(this.form.name == ""){
        this.form.name = file.name
      }

    },
    handleSuccess(response, file, fileList){
      console.log('handleSuccess triggered', response, file, fileList)
      //      this.fileList = fileList.slice(-3);
      // temporary until the fox of url_for:
      // let url_tmp = "/api/pharmetika/v5/electric_lab/document/"
      // use file.response
      if (file.response && file.response.success) {
        this.removeElements( document.querySelectorAll(".el-icon-close") );
        this.removeElements( document.querySelectorAll(".el-icon-close-tip") );
        file.url = url_for.url_for('electric_lab_document_get', {identifier: file.response.data.document_identifier})
        // console.log({file});
        // console.log({fileList});
        let to_append = {}
        to_append.name = this.form.name;
        to_append.note = this.form.note;
        to_append.identifier = file.response.data.document_identifier;
        to_append.type = file.raw.type;
        let to_append_list = [];
        to_append_list.push(to_append)
        let this_document_category_list = {};
        let new_documents_list;
        Object.defineProperty(this_document_category_list, this.form.category, {value: [...to_append_list], writable: true});
        if(!this.$attrs.value || Object.keys(this.$attrs.value).length == 0){
          new_documents_list = this_document_category_list
          this.$attrs.value = new_documents_list
        }else{
          if(Object.keys(this.$attrs.value).includes(this.form.category)){
            this.$attrs.value[this.form.category].push(to_append)
          }else{
            this.$attrs.value[this.form.category] = to_append_list
          }
        }
        // new_documents_list = { ...this.$attrs.value, ...this_document_category_list}
        this.loadMime(file.raw, (file_mime) => {
          let document_to_send = {
            "identifier": to_append.identifier,
            "name": this.form.name,
            "note": this.form.note,
            "category" : this.form.category,
            "type": file_mime
          };
          // console.log({document_to_send});
          this.$emit("update_documents", document_to_send);
          this.uploading = false
          this.finished_upload = true
          return true
        })
      } else if (file.response && ( !file.response.success) ) {
        if (file.response.messages) {
          this.$store.dispatch("notification/add", response.messages[0], { root: true });
        } else {
          console.warn({file});
        }
        this.uploading = false
        this.finished_upload = true
        return false
      }
      else {
        console.warn({file});
        //this.uploading = false
        this.finished_upload = true
        return false
      }

    }
  }
};
</script>

<style scoped>
.upload-label{
  display: flex;
}
.upload-label span{
  display: flex;
  align-items: center
}
</style>
