<template>
  <div class="begin-compoundingRequest">
    <el-backtop target=".main" :right="22"><i class="el-icon-caret-top"></i></el-backtop>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
          <el-row :gutter="25">
              <el-col :span="16">
                <span>
                Formula:
                          <router-link
                            :to="{ name: 'formula-manage', params: {formula_identifier: task.task.data.formula_identifier, task_id: task.task.id} }"
                            >
                                {{task.formula.data.name}} [{{task.formula.data.identifiers.formula_id}}]
                          </router-link>
                </span>
                <span class="formula-status" v-if="formula.formula_status != 'active'">{{ formula.formula_status }}</span>

              </el-col>
              <el-col :span="8">
        <!--
        <span class="formula-status">
          <el-tag type="warning">{{ lot.status }}</el-tag>
        </span>
        -->



                <div>
                    <span v-if="formula.formula_status == 'active'">
                    <el-button
                        type="primary"
                        class="status-button"
                        style="float: right; padding: 5px"
                        v-if="formula.formula_status == 'active'"
                        @click="lot_request_dialogFormVisible=true"
                    >
                        <i class="fal fa-tasks"></i> Create Task
                    </el-button>

                    <el-dialog
                        title="Schedule Task to Create Lot"
                        :visible.sync="lot_request_dialogFormVisible"
                        width="85%"
                    >
                        <el-row :gutter="20">
                        <el-col :span="14">
                            <el-form :model="lot_request" :rules="lotReqRules" ref="lotReqForm">
                            <el-form-item label="Quantity" label-width="200" prop="quantity">
                                <el-input v-model="lot_request.quantity" autocomplete="off"
                                  type="number"
                                  inputmode="decimal"></el-input>
                                  <span style="color:tomato;font-size:.85em;"
                                    v-if="parseFloat(task.formula.data.minimum_batch_quantity) > parseFloat(lot_request.quantity)"
                                  >
                                    Quantity is less than the formula minimum batch size ({{parseFloat(task.formula.data.minimum_batch_quantity)}})
                                  </span>
                            </el-form-item>

                            <el-form-item label="Note" label-width="200">
                                <el-input v-model="lot_request.task_note" autocomplete="off"></el-input>
                            </el-form-item>
                            <el-form-item label="Priority" label-width="200">
                                <el-select v-model="lot_request.priority" placeholder="Choose Level">
                                <el-option label="Normal" value="1"></el-option>
                                <el-option label="Elevated" value="2"></el-option>
                                <el-option label="Urgent" value="3"></el-option>
                                </el-select>
                            </el-form-item>

                            <el-row>
                                <el-form-item label="Date Due" label-width="200" prop="date">
                                <el-date-picker
                                    style="width: 100%;"
                                    type="datetimerange"
                                    v-model="lot_request.date"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    format="MM/dd HH:mm"
                                    :picker-options="datetime_picker_options"
                                    range-separator="To"
                                    start-placeholder="Start date"
                                    end-placeholder="End date"
                                ></el-date-picker>
                                </el-form-item>
                                <!-- <label class="inputLabel">Date Due</label> -->

                            </el-row>
                            <el-row>
                              <el-col :span="24">
                                <el-checkbox label="Merge if possible" v-model="lot_request.merge"></el-checkbox>
                              </el-col>
                            </el-row>
                            <h5 class="title" style="font-size:16px;">Ingredient Availability</h5>
                            <ingredientAvailablity v-if="formula" :formula="formula" :quantity="parseFloat(lot_request.quantity)" />
                            </el-form>
                        </el-col>
                        <el-col :span="10">
                            <outstandingTasks :task_type="['lotCreate', 'lot_check']" :filters="{formula_identifier: formula.identifier }" :styleObject="{ fontSize: '0.7em', maxHeight: '28em'}"></outstandingTasks>
                        </el-col>
                        </el-row>
                        <span slot="footer" class="dialog-footer">
                          <el-button @click="lot_request_dialogFormVisible = false">Cancel</el-button>
                          <el-button
                              type="primary"
                              @click=" create_formula_lot_task()"
                          >Create Task</el-button>
                          <el-button
                            v-if="task.task.task_status != 'complete'"
                            type="primary"
                            @click=" create_task_and_mark_complete()"
                          >Create Task &amp; Mark Complete</el-button>
                        </span>
                    </el-dialog>
                    </span>
                    <span v-if="formula.formula_status == 'proposed'">
                    <el-button
                        type="primary"
                        class="status-button"
                        style="float: right; padding: 5px"
                        @click="lot_request_post_approval_dialogFormVisible=true"
                    >
                        <i class="fal fa-tasks"></i> Schedule Task Pending Approval
                    </el-button>

                    <el-dialog
                        title="Schedule Task to Create Lot Pending Approval"
                        :visible.sync="lot_request_post_approval_dialogFormVisible"
                        width="85%"
                    >
                      <el-row :gutter="20">
                        <el-col :span="14">
                          <el-form :model="lot_request" :rules="lotReqRules" ref="lotReqForm">
                            <el-form-item label="Quantity" label-width="200" prop="quantity">
                                <el-input v-model="lot_request.quantity" autocomplete="off"
                                  type="number"
                                  inputmode="decimal"></el-input>
                            </el-form-item>

                            <el-form-item label="Note" label-width="200">
                                <el-input v-model="lot_request.task_note" autocomplete="off"></el-input>
                            </el-form-item>
                            <el-form-item label="Priority" label-width="200">
                                <el-select v-model="lot_request.priority" placeholder="Choose Level">
                                <el-option label="Normal" value="1"></el-option>
                                <el-option label="Elevated" value="2"></el-option>
                                <el-option label="Urgent" value="3"></el-option>
                                </el-select>
                            </el-form-item>

                            <el-row>
                                <el-form-item label="Date Due" label-width="200" prop="date">
                                    <el-date-picker
                                    style="width: 100%;"
                                    type="datetimerange"
                                    v-model="lot_request.date"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    format="MM/dd HH:mm"
                                    :picker-options="datetime_picker_options"
                                    range-separator="To"
                                    start-placeholder="Start date"
                                    end-placeholder="End date"
                                    ></el-date-picker>
                                </el-form-item>
                                <!-- <label class="inputLabel">Date Due</label> -->
                            </el-row>
                          </el-form>
                        </el-col>
                        <el-col span="10">
                          <outstandingTasks :task_type="['lotCreate', 'lot_check']" :filters="{formula_identifier: formula.identifier }" :styleObject="{ fontSize: '0.7em', maxHeight: '28em'}"></outstandingTasks>
                        </el-col>
                      </el-row>
                      <span slot="footer" class="dialog-footer">
                      <el-button @click="lot_request_post_approval_dialogFormVisible = false">Cancel</el-button>
                      <el-button
                          type="primary"
                          @click=" create_formula_lot_task({ create_as_subsequent_task: true})"
                      >Create Task</el-button>
                      <el-button
                        v-if="task.task.task_status != 'complete'"
                        type="primary"
                        @click=" create_task_and_mark_complete({ create_as_subsequent_task: true})"
                      >Create Task &amp; Mark Complete</el-button>
                      </span>
                  </el-dialog>
                  </span>
                </div>
                <div class="mark-complete-button" style="clear:both; float: right; margin-top: 1em;">
                     <div v-if="task.task.task_status != 'complete'">
                        <el-button style="" type="primary" size="small" @click="complete_task(task.task.id, task.task);" plain><i class="fad fa-check-square"></i> Mark Complete</el-button>
                     </div>
                     <div v-else>
                         [completed]
                     </div>

                </div>
            </el-col>
          </el-row>
      </div>
      <div class="task-body">
            <el-row>
              <el-col :span="24">
                  <div class="info_row">
                      Stability Span: {{task.formula.data.options.stability_span}}
                  </div>
                  <div class="info_row">
                      Inventory Re-Order Point: {{task.formula.data.inventory_reorder_point || 0}}
                  </div>
                  <div class="info_row" v-if="task.task.data.quantity_requested">
                      Amount Requested: {{ task.task.data.quantity_requested || 0}}
                  </div>

                  <div class="info_row">
                      Available Inventory:
                      <span v-if="task.inventory.length">
                          {{(task.inventory[0].inventory_available || 0 )}}
                          <span style="color: #777; margin-left: 1.5em;" v-if="task.inventory[0].inventory_expired" ><i class="fad fa-exclamation-square"></i> {{task.inventory[0].inventory_expired}} [expired]</span>
                      </span>
                      <span v-else>None</span>
                  </div>
                  <div class="info_row" v-if="task.formula.data.minimum_batch_quantity">
                      Minimum Batch Size:
                      {{ parseFloat(task.formula.data.minimum_batch_quantity) }}
                  </div>
                  <div class="info_row" v-if="task.formula.data.maximum_batch_quantity">
                      Maximum Batch Size:
                      {{ parseFloat(task.formula.data.maximum_batch_quantity) }}
                  </div>
                  <div class="info_row">
                      Amount used in other formulas over past 30 days:
                      <span v-if="task.inventory.length">
                          {{task.inventory[0].inventory_used_in_past_30_days}}
                      </span>
                      <span v-else>
                          No records found
                      </span>
                  </div>
                  <div class="info_row" v-if="has_pharmacy_management_system">
                      Amount dispensed over past 30 days:
                      <span v-if="task.inventory.length">
                          {{task.inventory[0].inventory_dispensed_in_30_days}}
                      </span>
                      <span v-else>
                          No records found
                      </span>
                  </div>

                  <div class="info_row" v-if="has_pharmacy_management_system">
                      <el-collapse>
                        <el-collapse-item name="1">
                                <template slot="title">
                                    Dispense Queue: &nbsp;
                                    <span v-if="task.dispenses_unfulfilled.length">
                                        {{task.dispenses_unfulfilled.map((obj) => parseFloat(obj.quantity_dispensed)).reduce((total, dispense) => total=total + dispense, 0)}} [{{task.dispenses_unfulfilled.length}}]
                                    </span>
                                    <span v-else>
                                        No records found
                                    </span>
                                </template>
                            <div>
                                <el-table :data="task.dispenses_unfulfilled" :row-class-name="dispense_table_class">
                                  <el-table-column type="expand">
                                    <template slot-scope="props">
                                      <p>Fill note: {{ props.row.note_dispense }}</p>
                                    </template>
                                  </el-table-column>
                                  <el-table-column label="Rx Number" prop="rx_number"></el-table-column>
                                  <el-table-column label="Order ID" prop="order_id"></el-table-column>
                                  <el-table-column label="Quantity" prop="quantity_dispensed"></el-table-column>
                                  <el-table-column label="Filled" prop="date_filled"></el-table-column>
                                  <el-table-column label="Needed By" prop="date_order_needed_by">
                                    <template #default="scope">
                                      <span v-if="scope.row.date_order_needed_by">
                                        {{ scope.row.date_order_needed_by | moment('YYYY-MM-DD ha') }}
                                      </span>
                                      <span v-else-if="scope.row.date_ready_by">
                                        {{ scope.row.date_ready_by | moment('YYYY-MM-DD ha') }}
                                      </span>
                                      <span v-else>
                                        N/A
                                      </span>
                                    </template>
                                  </el-table-column>
                                </el-table>
                            </div>
                        </el-collapse-item>
                        <el-collapse-item name="2">
                                <template slot="title">
                                    Pending Orders:  {{pending_orders_quantity}} units needed for {{task.orders_unfilled.length}} orders
                                </template>
                            <div>
                                <el-table :data="task.orders_unfilled" :row-class-name="dispense_table_class">
                                  <el-table-column label="Order ID" prop="order_id"></el-table-column>
                                  <el-table-column label="Rx Number" prop="rx_number"></el-table-column>
                                  <el-table-column label="Quantity" prop="quantity_requested"></el-table-column>
                                  <el-table-column label="Needed By" prop="date_order_needed_by"></el-table-column>
                                </el-table>
                            </div>
                        </el-collapse-item>
                        <el-collapse-item name="3">
                            <template slot="title">
                                Quantity needed over next 15 days: {{task.quantity_required.quantity_required || 'none'}}
                            </template>

                            <div>Number of patients: {{task.quantity_required.number_of_patients}}</div>
                            <div>Quantity required by autoship patients: {{task.quantity_required.quantity_required_for_autoship}}</div>
                            <div>Next Date Needed By: {{task.quantity_required.date_next_needed_by}}</div>
                        </el-collapse-item>
                        <el-collapse-item title="Inventory History" name="4">
                            <div>
                                <el-table :data="task.inventory_history" :row-class-name="dispense_table_class">
                                  <el-table-column label="Lot #" prop="lot_number"></el-table-column>
                                  <el-table-column label="Quantity" prop="quantity_initial"></el-table-column>
                                  <el-table-column label="Compounded" prop="date_compounded"></el-table-column>
                                  <el-table-column label="BUD" prop="beyond_use_date"></el-table-column>
                                </el-table>
                            </div>
                        </el-collapse-item>
                        </el-collapse>
                  </div>
                  <div class="info_row" v-else>
                    <el-collapse>
                        <el-collapse-item title="Inventory History" name="4">
                            <div>
                                <el-table :data="task.inventory_history" :row-class-name="dispense_table_class">
                                  <el-table-column label="Lot #" prop="lot_number"></el-table-column>
                                  <el-table-column label="Quantity" prop="quantity_initial"></el-table-column>
                                  <el-table-column label="Compounded" prop="date_compounded"></el-table-column>
                                  <el-table-column label="BUD" prop="beyond_use_date"></el-table-column>
                                </el-table>
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                  </div>

                  <div style="margin-top: 10px;">
                    <anticipatoryCompoundReport :ndc="formula.identifiers.ndc" />
                  </div>
              </el-col>
            </el-row>
          <pre v-if="false">
   "inventory_history" : [
      {
         "beyond_use_date" : "2020-03-11T11:41:30-05:00",
         "code" : "267394",
         "date_compounded" : "2019-09-13T15:05:28.046Z",
         "date_entered_inventory" : "2019-09-13T15:05:28.046Z",
         "description" : "Testosterone Micronized 50 mg/ml (5%) Cream",
         "identifier" : "12df50ca-ec22-420c-8d2f-775831c2b503",
         "lot_number" : "30700",
         "quantity_initial" : "180"
      },


      "quantity_required" : {
      "date_next_needed_by" : "2020-04-16",
      "drug_description" : "TESTOSTERONE CREAM 50MG/ML 30ML (AUI)",
      "drug_id" : "559776D1-0BEB-43A2-A44A-01FED85DD4D2",
      "number_of_patients" : 1,
      "quantity_required" : "1",
      "quantity_required_for_autoship" : "0"
   },

   "orders_unfilled" : [
      {
         "date_order_needed_by" : "2020-04-04 00:00:00",
         "order_id" : 2,
         "order_status" : "new",
         "priority" : null,
         "quantity_requested" : null
      }
   ],

        "dispenses_unfulfilled" : [
            {
                "date_filled" : "2020-04-01",
                "date_order_needed_by" : null,
                "fill_number" : 0,
                "order_id" : null,
                "order_status" : null,
                "priority" : null,
                "quantity_dispensed" : 1,
                "rx_number" : 409393,
                "transaction_id" : 32712864
            }
        ]

              Information:
              task.unfulfilled_dispenses: [],
              task.orders_unfilled: [] (filled would be in the dispenses above)

              formula.stability_span
              inventory.current: []

              formula inventory history
              amount required in next 15 days

   "quantity_required" : {
      "date_next_needed_by" : "2020-04-16",
      "drug_description" : "TESTOSTERONE CREAM 50MG/ML 30ML (AUI)",
      "drug_id" : "559776D1-0BEB-43A2-A44A-01FED85DD4D2",
      "number_of_patients" : 1,
      "quantity_required" : "1",
      "quantity_required_for_autoship" : "0"
   },

              task.notes
          </pre>

      </div>
    </el-card>
  </div>
</template>

<script>
import global_store from "@/store/store";
const uuidv4 = require("uuid/v4");
import NProgress from "nprogress";
import Common from "@/common.js";
import EventService from "@/services/EventService.js";
const uuid_base64 = require("uuid-base64");
import url_for from "@/services/pmk_url_for";
import * as validators from '@/utils/validators.js';
import TaskService from "@/services/task.js";
import anticipatoryCompoundReport from '@/components/formula/anticipatory_compound_report.vue'
import outstandingTasks from '@/components/task/outstandingTasks'
import ingredientAvailablity from '@/components/ingredient/ingredient_availability.vue'

const datetime_picker_options = {
  default: {
    // double check the set times for each short cut
    shortcuts: [
      {
        text: "2 hours",
        onClick(picker) {
          const end = new Date();
          const start = new Date();
          end.setTime(start.getTime() + 3600 * 1000 * 2);
          picker.$emit("pick", [start, end]);
        }
      },
      {
        text: "4 hours",
        onClick(picker) {
          const end = new Date();
          const start = new Date();
          end.setTime(end.getTime() + 3600 * 1000 * 4);
          picker.$emit("pick", [start, end]);
        }
      },
      {
        text: "24 hours",
        onClick(picker) {
          const end = new Date();
          const start = new Date();
          end.setTime(end.getTime() + 3600 * 1000 * 24);
          picker.$emit("pick", [start, end]);
        }
      },
      {
        text: "3 days",
        onClick(picker) {
          const end = new Date();
          const start = new Date();
          end.setTime(end.getTime() + 3600 * 1000 * 24 * 3);
          picker.$emit("pick", [start, end]);
        }
      },
      {
        text: "Next week",
        onClick(picker) {
          const end = new Date();
          const start = new Date();
          end.setTime(end.getTime() + 3600 * 1000 * 24 * 7);
          picker.$emit("pick", [start, end]);
        }
      }
    ]
  }
};

export default {
  components: {
    anticipatoryCompoundReport,
    outstandingTasks,
    ingredientAvailablity,
  },
  props: {
    task: Object
  },
  data() {
      return this.createDefault();
  },
  computed: {
    formula: {
        get() {
          return this.task.formula.data
        }
    },
    datetime_picker_options: {
      get() {
        let picker =
          datetime_picker_options.default ||
          this.$store.getters["task/datetime_picker_options"];
        return picker;
      }
    },
    formula_types: {
      get() {
        if (!this.$store.getters["formula/get_lab_config"]) {
          this.$store.dispatch("formula/fetchLabConfig");
        }
        if (this.$store.getters["formula/get_lab_config"]) {
          return this.$store.getters["formula/get_lab_config"]["config"][
            "formula_types"
          ];
        } else return {};
      }
    },
    pending_orders_quantity: {
      get() {
        let total_quantity = 0
        let qty_reducer = (accumulator, entry) => accumulator + (parseFloat(entry.quantity_requested) || 0);
        total_quantity = this.task.orders_unfilled.reduce(qty_reducer,0)
        return total_quantity
      }
    },
    pmk_config() {
      const pmk_config = this.$store.getters['app/pmk_config']
      // console.log({pmk_config})
      return pmk_config
    },

    has_pharmacy_management_system() {
      const pmk_config = this.$store.getters['app/pmk_config']
      return !!(pmk_config.modules.pharmacy_management_system && pmk_config.modules.pharmacy_management_system.enabled)
    }
},
  methods: {
    create_task_and_mark_complete(args) {
      this.create_formula_lot_task(args)
      this.complete_task(this.task.task.id, this.task.task)
    },
      dispense_table_class({row, rowIndex}) {
        if (row.priority ) {
          return 'priority-row'
        }
      },
      createDefault() {
          return {
              "lotReqRules": {
                  "quantity":[{"trigger":"blur","validator": validators.validateNumbers}],
                "date":[
                    {"validator": validators.validateDatesArray, "trigger":"blur"}
                    ]
                },
                "lot_request_dialogFormVisible": false,
                "lot_request_post_approval_dialogFormVisible": false,
                "lot_request":{
                  quantity: this.task.task.data.quantity_requested || '',
                  task_note: this.task.task.data.task_note
                   }
          }

      },

    complete_task(parameter_task_id, task_data) {
        let self=this
        console.log(440, parameter_task_id);
        NProgress.start();
        this.$store
            .dispatch("task/complete_task", {
            task_id: parameter_task_id,
            task_data: task_data
            })
            .then(function(rsp) {
                // console.log('rsp.data', rsp.data)
                const notification = rsp.data.messages[0];
                self.$store.dispatch("notification/add", notification, {
                  root: true
                });
                self.task.task.task_status='complete'
            })
            .then(() => NProgress.done())
            .catch(() => {
            NProgress.done();
            });
        },
    create_formula_lot_task(args = {}) {
      // somewhere, display a modal to request the parameters for this task type and save to lot_request
      //      let lot_request = {}
      let self = this;
      let create_as_subsequent_task = args.create_as_subsequent_task;
      this.$refs['lotReqForm'].validate((valid) => {
        if (valid) {
          if (!create_as_subsequent_task ) {
            self.lot_request_dialogFormVisible = false;
          } else {
            self.lot_request_post_approval_dialogFormVisible = false;
          }


          console.log("this.lot_request", this.lot_request);
          console.log("store.formula", this.$store.getters["formula/get_formula"]);
          console.log("this.formula", this.formula);
          let task_sub_title = `Formula ID: ${this.task.formula.data.formula_id ||
            this.task.formula.data.identifiers.formula_id || ''} | ${
            this.formula_types[this.formula.formula_type]
              ? (this.formula_types[this.formula.formula_type].name || '')
              : ""
          }`;

          let task_details_indicators = []
          if (this.formula.formula_information) {

            if (this.formula.formula_information.is_hazardous) {

              task_details_indicators.push(
                `<span class="indicator" style="color: Tomato;">
                  <i style="font-size: 1.0rem;" class="fad fa-flask-poison"></i>&nbsp;Hazardous
                </span>`
              )
            }
            if (this.formula.options.sterile_indicator) {

              task_details_indicators.push(
                `<span class="indicator">
                  <i style="font-size: 1.0rem;" class="fad fa-exclamation-triangle"></i>&nbsp;Sterile
                </span>`
              )
            }
          }
          let title_details = `QTY: ${this.lot_request.quantity}` + ( this.lot_request.task_note ?  ` Note: ${this.lot_request.task_note.substr(0, 40)}...` : '')

          if (task_details_indicators.length > 0) {
            title_details = title_details.concat(task_details_indicators.join("\n"))
          }
          let task_data = {
            formula: { identifier: this.formula.identifier },
            lot: { quantity: this.lot_request.quantity },
            title: this.formula.name || this.formula.description,
            sub_title: task_sub_title,
            title_details: title_details,
            task_note: this.lot_request.task_note

          };

          let task_utc_dates = [
            this.$moment(this.lot_request.date[0])
              .utc()
              .format(),
            this.$moment(this.lot_request.date[1])
              .utc()
              .format()
          ];
          let new_task_object = {
            formula_identifier: this.formula.identifier,
            task_type: "lot_create",
            task_status: "new",
            merge: this.lot_request.merge,
            task_data: task_data,
            title: this.formula.name || this.formula.description,
            sub_title: task_sub_title,
            // title_details: `QTY: ${this.lot_request.quantity}` + ( this.lot_request.task_note ?  ` Note: ${this.lot_request.task_note.substr(0, 32)}...` : '') ,
            // task_note: this.lot_request.task_note,

            data_id: this.formula.id,
            order_id: this.lot_request.order_id,
            patient_id: this.lot_request.patient_id,
            prescriber_id: this.lot_request.prescriber_id,
            date_scheduled: task_utc_dates[0],
            date_due: task_utc_dates[1],
            task_priority: this.lot_request.priority
          };
          if (create_as_subsequent_task) {
            self.formula.subsequent_tasks = [
              ...(self.formula.subsequent_tasks || []),
              ...[new_task_object]
            ];
            NProgress.start();
            return TaskService.schedule_subsequent_formula_task({
              formula_identifier: self.formula.identifier,
              subsequent_tasks: [new_task_object]
            }) // patch_preserve is used so that other users creating tasks at the same time will not destroy them
              .then(() => NProgress.done())
              .then(function() {
                const notification = {
                  type: "success",
                  message:
                    "Task will be scheduled once formula changes are approved"
                };

                self.$store.dispatch("notification/add", notification, {
                  root: true
                });
                self.lot_request = {}
              })
              .catch(() => {
                NProgress.done();
              });
          } else if(this.lot_request.merge) {
            NProgress.start();
            return EventService.request_additional_inventory(new_task_object)
              .then(() => NProgress.done())
              .then(function() {
                const notification = {
                  type: "success",
                  message: "Task scheduled"
                };

                self.$store.dispatch("notification/add", notification, {
                  root: true
                });
                self.lot_request = {}
              })
              .catch(() => {
                NProgress.done();
              });

          } else {
            NProgress.start();
            return TaskService.schedule_task(new_task_object)
              .then(() => NProgress.done())
              .then(function() {
                const notification = {
                  type: "success",
                  message: "Task scheduled"
                };

                self.$store.dispatch("notification/add", notification, {
                  root: true
                });
                self.lot_request = {}
              })
              .catch(() => {
                NProgress.done();
              });
      }

        }
      })


    }

}
}
</script>

<style scoped>
    .priority-row {
        background-color: #eb85853d;
    }
</style>
