<template>
<div>
	                        <hr class="dotted" />
<BaseDocumentUpload label="TEST"></BaseDocumentUpload>
<!--
	                        <form class="func_schedule_task">
		                        <input type="text" name="title" v-model="task.title" />
		                        <input type="text" v-model="task.sub_title" id="new_task_sub_title" value="" />
								<div class="form-group row">
		                            <label class="col-sm-3 control-label">Task</label>
		                            <div class="col-sm-5">
		                              <div class="input-group">
							              <el-select
							                v-model="task.task_type"
							                filterable
							                placeholder="Task Type"
							                style="text-transform: capitalize;"
							              >
							                <el-option
							                  style="text-transform: capitalize;"
							                  v-for="(task_type, task_key) in task_types"
							                  v-if="task_type.schedule_via_ui==1"
							                  :key="task_key"
							                  :label="task_type.task_display_name"
							                  :value="task_key"
							                ></el-option>
							              </el-select>
		                                </div>
		                            </div>
									<div class="col-sm-4">
										<div class="input-group"><label for="task_priority_checkbox" class="control-label">Priority&nbsp;</label><input type="checkbox" id="task_priority_checkbox" v-model="task.task_priority" value="3" /></div>
									</div>
		                         </div>
	                             <div class="form-group row">
				              <el-row>
				                <label class="inputLabel">Date Due</label>
				                <el-date-picker
				                  style="width: 100%;"
				                  type="datetimerange"
				                  v-model="task.date_due"
				                  value-format="yyyy-MM-dd HH:mm:ss"
				                  format="MM/dd HH:mm"
				                  :picker-options="datetime_picker_options"
				                  range-separator="To"
				                  start-placeholder="Start date"
				                  end-placeholder="End date"
				                ></el-date-picker>
				              </el-row>

							    </div>
	                         
		                         
								<div class="form-group row">
									<div class="col-md-9">
										<el-input
										  type="task.task_note"
										  :rows="2"
										  placeholder="Please input"
										  v-model="textarea">
										</el-input>
										<span class="help-block"><small>This note will appear with the task</small></span>
										</div>
									<div class="col-md-3"><button class="btn btn-info btn-sm" type="submit">Add Task</button></div>
								</div>
	                        </form>

                        </div>
                	</div>
-->
</div>
</template>
<!-- component -->
<script>
const moment = require('moment')
//require('moment/locale/es')

// Vue.use(require('vue-moment'), {
//   moment
// })
export default {


  data() {
    return {
	    task_types: 
	    	{
	    	'lab-other': {
				filter: 1,
				schedule_via_ui: 0,
				task_category: "Compounding",
				task_display_name: "Lab Task - Other"
				}
	    	},
      task: {}
    }
  },
  methods: {
    format_to_correct_time_zone(date) {
      let timezone_offset = new Date().getTimezoneOffset()
      let timezone_adjusted_date = moment(date)
        .add(timezone_offset, 'minutes')
        .format('dddd, MMM Do, h:mm a')
      return timezone_adjusted_date
    },
    set_task_date_to_edit(date_to_edit) {
      this.$store.dispatch(
        'current_working_task/set_task_date_to_edit',
        date_to_edit
      )
    },
    add_task() {
	    console.log(this.task)
    }
  },
  computed: {
	datetime_picker_options: {
      get() {
        let picker =
          datetime_picker_options.default ||
          this.$store.getters["task/datetime_picker_options"];
        return picker;
      }
    }

  }
}

</script>
