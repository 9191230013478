var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('div',{staticStyle:{"padding":"1.5em"}},[_c('el-input',{staticStyle:{"width":"80%"},attrs:{"placeholder":"Filter...","clearable":""},model:{value:(_vm.filter_users),callback:function ($$v) {_vm.filter_users=$$v},expression:"filter_users"}}),_c('el-button',{staticStyle:{"float":"right","margin":"0px 20px"},attrs:{"type":"primary"},on:{"click":function($event){_vm.show_user_dialog = true}}},[_c('i',{staticClass:"fal fa-plus-circle"}),_vm._v(" New User")])],1),_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.user_list.filter(function (user) { return !_vm.filter_users || (user.username &&
            user.username
              .toLowerCase()
              .includes(_vm.filter_users.toLowerCase())) ||
          (user.name &&
            user.name.toLowerCase().includes(_vm.filter_users.toLowerCase())) ||
          (user.email &&
            user.email.toLowerCase().includes(_vm.filter_users.toLowerCase())); }
      ).filter(function (obj) { return ['lab', 'lab-pharmacist'].includes(obj.user_type); }).filter(function (obj) { return obj.enabled; })}},[_c('el-table-column',{attrs:{"prop":"username","label":"Username"}}),_c('el-table-column',{attrs:{"prop":"name","label":"Name"}}),_c('el-table-column',{attrs:{"prop":"email","label":"Email"}}),_c('el-table-column',{attrs:{"prop":"user_type","label":"User Type"}}),_c('el-table-column',{attrs:{"label":"Remove"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-popconfirm',{attrs:{"title":"Are you sure to Deactivate this user?"},on:{"confirm":function($event){return _vm.deactivate_user(scope.row)}}},[_c('el-button',{attrs:{"slot":"reference","type":"danger","plain":"","size":"small"},slot:"reference"},[_vm._v("Deactivate")])],1)]}}])})],1),_c('el-dialog',{attrs:{"title":"Create user","visible":_vm.show_user_dialog,"width":"70%"},on:{"update:visible":function($event){_vm.show_user_dialog=$event}}},[_c('createUser',{on:{"created":_vm.user_created}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }