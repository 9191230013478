<template>
  <div class="">
    <div style="margin 1.5em; padding: 1.5em;">
      <el-input
        v-model="filter_users"
        placeholder="Filter..."
        style="width: 80%"
        clearable
      />
      <el-button
        type="primary"
        style="float: right; margin: 0px 20px"
        @click="show_user_dialog = true"
        ><i class="fal fa-plus-circle" />&nbsp;New User</el-button
      >
    </div>
    <el-table
      :data="user_list.filter(user => !filter_users || (user.username &&
              user.username
                .toLowerCase()
                .includes(filter_users.toLowerCase())) ||
            (user.name &&
              user.name.toLowerCase().includes(filter_users.toLowerCase())) ||
            (user.email &&
              user.email.toLowerCase().includes(filter_users.toLowerCase()))
        ).filter(obj => ['lab', 'lab-pharmacist'].includes(obj.user_type)).filter(obj => obj.enabled)"
      style="width: 100%">
      <el-table-column prop="username" label="Username" />
      <el-table-column prop="name" label="Name" />
      <el-table-column prop="email" label="Email" />
      <el-table-column
        prop="user_type"
        label="User Type"
      />
      <el-table-column
        label="Remove">
        <template slot-scope="scope">
          <el-popconfirm
            title="Are you sure to Deactivate this user?"
            @confirm="deactivate_user(scope.row)"
          >
            <el-button slot="reference" type="danger" plain size="small">Deactivate</el-button>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      title="Create user"
      :visible.sync="show_user_dialog"
      width="70%">
      <createUser @created="user_created" />
    </el-dialog>
  </div>
</template>

<script>
import EventService from "@/services/EventService.js";
import createUser from '@/views/configuration/create_user.vue'
export default {
  components: {
    createUser
  },
  data() {
    return {
      user_list: [],
      filter_users: '',
      show_user_dialog: false
    }
  },
  mounted() {
    this.get_users()
  },
  methods: {
    deactivate_user(user) {
      EventService.pmk_fetch(
        'administration_user_create',
        'POST',
        {},
        {
          user: {
            ...user,
            user_name: user.username,
            status: 'remove'
          },
        }
      ).then((http_response) => {
        if (http_response.data.messages) {
          http_response.data.messages.forEach((message) => {
            this.$message(message)
          })
        }
        this.get_users()
      }).catch((err) => {
        console.log(err)
        service.renderMessages([
          {
            type: 'error',
            message: 'Error Deactivating user'
          }
        ])
      })
    },
    user_created() {
      this.show_user_dialog = false
      this.get_users()
    },
    get_users() {
      EventService.pmk_fetch(
        'administration_user_get_list',
        'GET',
        {},
        {}
      ).then((http_response) => {
        if (http_response.data.success) {
          this.user_list = http_response.data.data
        }
      })
    }
  }
}
</script>

<style lang="css" scoped>
</style>
