<template>
 <div>
     <el-row>
         <el-col :span="8">
             <div class="text-secondary bold">
                 Packaging:
             </div>
             <div>
                 {{details.packaging}}
             </div>
         </el-col>
         <el-col :span="8">
            <div class="text-secondary bold">
                Storage Instructions:
             </div>
             <div>
                 {{details.storage_instructions}}
             </div>
         </el-col>
         <el-col :span="8">
             <div class="text-secondary bold">
                Final Appearance:
             </div>
             <div>
                 {{details.final_appearance}}
             </div>
         </el-col>
     </el-row>
 </div>
</template>

<script>
export default {
    name:"printabledetails",
    props: ["details"]
};
</script>

<style>
.text-secondary.bold{
    font-weight: 700;
}
</style>
