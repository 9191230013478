<template>
  <div>
    <!-- 
    <pre>
    {{task}}
    </pre>
    -->
    <router-link
      :to="{ name: 'formula-manage', params: {formula_identifier: task.formula.identifier, task_id: task.task.id }  }"
    >Rework Formula {{task.formula.data.identifiers.formula_id}}</router-link>&nbsp; |
  </div>
</template>

<script>
export default {
  props: {
    task: Object
  },
  methods: {}
};
</script>

<style scoped>
</style>
