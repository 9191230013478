<template>
  <div>
    <div slot="header" class="clearfix">
      <el-row :gutter="10">
        <el-col :span="18">
          <span>
            <span style="font-size: 1.5em">Formula Search By Ingredients</span>
            &nbsp;
            <span class="text-secondary"></span>
          </span>
        </el-col>
        <el-col :span="2">
          <el-button
            v-if="true"
            type="default"
            style="  padding: 5px; margin-right: 0.5em;"
            @click="reset()"
          >Clear</el-button>
        </el-col>
        <el-col :span="2">&nbsp;</el-col>
        <el-col :span="2">&nbsp;</el-col>
      </el-row>
    </div>

    <el-row :gutter="10">
      <el-col :span="18">
        <div style="margin-bottom: 2em;">
          <div class="sub-title">Search:</div>
          <!-- <el-input width=500             v-model="form.formula_query"
          placeholder="Search..."
          @input="search_formulas"
          clearable
          ><el-input>-->
          <el-autocomplete
            id="formula_search_field"
            style="width: 40em;"
            v-model="form.formula_query"
            :fetch-suggestions="search_ingredients"
            placeholder="Search..."
            placement="bottom-end"
            :trigger-on-focus="false"
            no-value-key="name"
            @select="add_ingredient"
            size="400"
            autocapitalize="none"
            :debounce="450"
            clearable
          >
          <!--
            <template slot="prepend">
              <i class="fal fa-search"></i>
            </template>

            <template slot-scope="{ item }">
              <div class="value">
                <span v-if="item.code">[{{ item.code }}] -</span>
                <span>{{ item.name }}</span>
              </div>
            </template>
          -->
            <!-- <template slot="append">
            <i @click="select_product" class="fal fa-plus-circle"></i>
            </template>-->
          </el-autocomplete>&nbsp;
        </div>
      </el-col>
      <el-col :span="8"></el-col>
    </el-row>
    <ul>
      <li
        v-for="ingredient in ingredients"
        :key="ingredient.identifier">
        <router-link :to="{name: 'ingredient-inventory-item', params: {identifier: ingredient.identifier}}">
          {{ ingredient.description }}
        </router-link>
        <span @click="remove_item(ingredient.identifier)"><i class="fas fa-trash"></i></span>
      </li>
    </ul>
    <el-row :gutter="10">
      <el-col :span="24">
        <section class="results_area" v-if="results">
          <el-table
            ref="singleTable"
            :data="results"
            highlight-current-row
            @current-change="handleSearchResultSelection"
            style="width: 100%"
            :row-class-name="formula_status_class"
          >
            <!-- <el-table-column type="index" width="50"></el-table-column> -->
            <el-table-column class-name="clickable" property="name" label="Formula" width="520"></el-table-column>
            <el-table-column property="code" label="Code" width="120"></el-table-column>
            <el-table-column property="Object.values(inventory_data).length" label="Inventory"></el-table-column>
          </el-table>
        </section>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import NProgress from "nprogress";
import EventService from "@/services/EventService.js";
const uuid_base64 = require("uuid-base64");

export default {
  // props: {
  //   formula: {
  //     type: Object,
  //     required: false,
  //     default: this.createFreshFormObject()
  //   }
  // },
  props: {
    formulaType: {
      type: String,
      required: false
    }
  },
  watch: {
    formulaType: function() {
      this.search_formulas()
    }
  },
  data() {
    return {
      categories: this.$store.state.categories,
      form: {},
      results: [],
      ingredients: []
    };
  },
  mounted() {
    document.querySelector('#formula_search_field').focus()
  },
  methods: {
    reset() {
      this.form = {}
      this.ingredients = []
      this.results = []
    },
    add_ingredient(ingredient) {
      this.ingredients.push(ingredient)
      this.form.formula_query = ''
      this.search_formulas()
    },
    remove_item(identifier) {
      const new_ingredients = this.ingredients.filter(obj => obj.identifier !== identifier)
      this.ingredients = new_ingredients
      this.search_formulas()
      if (!this.ingredients.length) {
        this.results = []
      }
    },
    formula_status_class({row, rowIndex}) {
        if (row.formula_status == 'retired') {
          return 'retired-row';
        } else if (row.formula_status == 'proposed') {
          return 'proposed-row';
        } else if (row.formula_status != 'active') {
          return 'proposed-row';
        }
        return '';
    },
    handleSearchResultSelection(row) {
      this.$router.push({
        name: "formula-manage",
        params: { formula_identifier: row.identifier }
      });
    },
    select_product(selected_product) {
      this.form.selected_product = selected_product;
      this.product = {
        ...this.createFreshInventoryObject(),
        ...{
          product_identifier: selected_product.product_identifier,
          manufacturer_identifier: selected_product.manufacturer.identifier,
          ingredient_identifier: selected_product.identifier,
          ingredient: {
            description: selected_product.description
          },
          manufacturer: selected_product.manufacturer,

          description: selected_product.description,
          date_created: "",
          inventory: {}
        }
      };
      this.form = this.createFreshFormObject();
    },
    search_ingredients(queryString, cb) {
      let self = this;
      // here we need to query the formula list
      let arg_string = queryString;
      let query_object = {
        explicit: 0,
        method: "LIKE",
        include_formulas: 1,
        description: arg_string,
      };
      // console.log('query_object', query_object)
      NProgress.start();
      this.$store
        .dispatch("ingredient/listIngredients", query_object)
        .then(() => {
          cb(
            this.$store.getters["ingredient/ingredient_search_list"].map(item => {
              return {value: item.data.description, ...item.data}
            })
          );
        })
        .then(() => NProgress.done())
        .catch(() => {
          NProgress.done();
        });
    },
    search_formulas() {
      const ingredients_to_search = this.ingredients.map(ingredient => ingredient.identifier)
      let query_object = {
        explicit: 0,
        method: "LIKE",
        ingredient_list: ingredients_to_search
      };
      if (this.formulaType) {
        query_object['formula_type'] = this.formulaType
      }
      if (!ingredients_to_search.length) {
        return
      }
      // console.log('query_object', query_object)
      NProgress.start();
      EventService.searchFormulas(query_object)
        .then(results => {
          this.results = results.data.data;
        })
        .then(() => NProgress.done())
        .catch(() => {
          NProgress.done();
        });
    }
  }
};
</script>
<style scoped>
/* out of scope here */

.clickable {
  cursor: pointer;
  color: #409eff;
}
.clickable:hover * {
  color: #409eff;
}
</style>
<style>
.formula_search_area .el-autocomplete-suggestion.el-popper {
  display: none;
}
.autocomplete-suggestion-popper-use-table {
  display: none !important;
}

  .el-table .retired-row {
    background: #c34c4c33;
    text-decoration-color: #d65a5ae8;
    text-decoration-line: line-through;
  }

  .el-table .proposed-row {
    background: oldlace;
  }

</style>
