<template>
 <div class="formula-instructions">
        <div class="title">Instructions <span
            class="toggle-instructions clickable"
            style="color: rgb(100, 100, 100); margin-left: 2em;  text-align:right;"
          >
            <i
              class="far fa-eye"
              v-if=" ! show_instructions"
              @click="toggleInstructionVisibility(true)"
            ></i>
            <i class="far fa-eye-slash" @click="toggleInstructionVisibility(false)" v-else></i>
          </span></div>
        <div class="insctructions-container" v-if="show_instructions">
            {{localInstr}}
            <div>
                {{localFormulaTypeInstructions}}
            </div>
        </div>
      </div>
</template>

<script>
export default {
    name:"printableinstructions",
    props: ["instructions","formula_type_instructions"],
    data(){
        return{
            show_instructions: false,
            localInstr :this.instructions,
            localFormulaTypeInstructions : this.formula_type_instructions
        }
    },
    methods: {
        toggleInstructionVisibility(value) {
            this.show_instructions = value;
        },
    }
};
</script>

<style>
.insctructions-container{
    padding: 5px 5px;
    border: 1px dashed black;
    white-space: pre-wrap;
}
.title{
    font-weight: 700;
    
}
</style>
