<template>
    <el-card class="box-card" style="height: fit-content;">
    <el-row :gutter="10">
      <el-col :span="24">
          <el-button
            type="primary"
            style="float: right; padding: 5px; margin-left:  .75em;  margin-right:  .75em;"
            @click="record_entry()"
          >
            <i class="fal fa-save"></i> Save
          </el-button>&nbsp;
          <el-button
            type="default"
            style="float: right; padding: 5px; margin-left:  .75em;  margin-right:  .75em;"
            @click="reset()"
          >
            <i class="fal fa-plus"></i> New
          </el-button>&nbsp;
        <div class="sub-title">Ingredient:</div>
        <el-autocomplete
          id="ingredient_search_field"
          class="inline-input"
          v-model="form.new_ingredient_description"
          :fetch-suggestions="search_ingredients"
          placeholder="Search..."
          :trigger-on-focus="false"
          @select="select_ingredient"
          value-key="description"
          autocapitalize="none"
          clearable
          style="width: 50em;"
        >
          <template slot="prepend">
            <i class="fal fa-search"></i>
          </template>
        </el-autocomplete>
      </el-col>
    </el-row>
    </el-card>
</template>
<script>
import ManufacturerTable from "@/components/ingredient/ManufacturerTable.vue";
import ManufacturerCodeForm from "@/components/ingredient/form_add_manufacturer_code.vue";
import NProgress from "nprogress";
import EventService from "@/services/EventService.js";

import Common from "@/common.js";

const uuidv4 = require("uuid/v4");

export default {
  // props: {
  //   formula: {
  //     type: Object,
  //     required: false,
  //     default: this.createFreshFormObject()
  //   }
  // },
  components: {
    ManufacturerTable,
    ManufacturerCodeForm
  },

  data() {
    return {
      categories: this.$store.state.categories,
      form: this.createFreshFormObject(),
      ingredient: this.createFreshFormObject(),

      units: [
        "cap",
        "cc",
        "drops",
        "gm",
        "mg",
        "ml",
        "oz",
        "PCAP",
        "syringe",
        "tab",
        "tablet"
      ]

    };
  },
  computed: {
    terminology: {
      get() {
        return this.$store.getters["terminology/terminology"];
      }
    },

    manufacturers: {
      get() {
        return this.$store.getters["manufacturer/get_manufacturers"];
      }
    }
  },
  mounted() {
    document.querySelector('#ingredient_search_field').focus()
  },
  methods: {
    get_rxnorm_substance() {
      let self = this;
      console.log(self.ingredient.primary_billing_identifier);
      EventService.getRXNORM({
        ndc: self.ingredient.primary_billing_identifier
      }).then(response => {
        this.ingredient = {
          ...this.ingredient,
          ...{
            rxnorm_description: response.data.data.description,
            rxcui: response.data.data.rxcui
          }
        };
      });
      EventService.getNDCOpenFDA({
        ndc: self.ingredient.primary_billing_identifier
      }).then(response => {
        console.log(response.data.data)
        this.ingredient = {
          ...this.ingredient,
          ...{
            active_pharmeceutical_ingredient_indicator: response.data.data.pharmetika_terms.active_pharmeceutical_ingredient_indicator,
            dea_schedule: response.data.data.pharmetika_terms.dea_schedule
          }
        };

        // self.ingredient.rxnorm_description = response.data.data.description
        // self.ingredient.rxcui = response.data.data.rxcui
      });
    },
    get_manufacturers(queryString, cb) {
      let options = Object.values(this.manufacturers);
      // .map(function(manufacturer) {
      //   return { value: manufacturer.value, id: manufacturer, name: manufacturer }
      // })
      console.log(options);
      console.log(queryString);
      let results = queryString
        ? options.filter(this.createFilter(queryString))
        : options;
      cb(results);
    },
    calculate_total() {
      let total_quantity = 0;
      this.ingredient.ingredients.forEach(function(ingredient) {
        total_quantity += parseFloat(ingredient.quantity) || 0;
      });
      return total_quantity;
    },

    add_new_manufacturer() {
      if (this.form.manufacturer.name) {

        let new_manufacturer = { identifiers: [], ...this.form.manufacturer };

        //   typeof this.form.manufacturer == 'object'
        //     ? this.form.manufacturer
        //     : { name: this.form.manufacturer, id: this.form.manufacturer }
        // new_manufacturer.identifiers = []

        // hack to fix imports:
        if (typeof this.ingredient.manufacturers != "object") {
          this.ingredient = { ...{ manufacturers: {} }, ...this.ingredient };
        }
        if (this.ingredient.manufacturers[new_manufacturer.identifier]) {
          // already exists:
          // do nothing ?
        } else {
          this.ingredient.manufacturers[
            new_manufacturer.identifier
          ] = new_manufacturer;
        }

        this.form.manufacturer = {};
      }
    },

    select_manufacturer(manufacturer_identifier) {
      // hack to fix imports - similar to code above, but setting form.manufacturer forces the render of ingredient.manufacturer:
      if (typeof this.ingredient.manufacturers != "object") {
        this.ingredient = { ...{ manufacturers: {} }, ...this.ingredient };
      }
      this.form.manufacturer = this.manufacturers[manufacturer_identifier];
    },

    add_manufacturer_code(manufacturer) {
      // not good either, but is like FHIR - TODO - HOWEVER: here it creates an array AND an object, so we can leave as-is for now
      //console.log('manufacturer: adding code')
      // console.log(manufacturer)

      if (manufacturer.new_identifier) {
        manufacturer.identifiers.push(manufacturer.new_identifier);
        if (!manufacturer.product_identifiers) {
          manufacturer.product_identifiers = {};
        }

        manufacturer.product_identifiers[manufacturer.new_identifier] = {
          system: this.form.product_identifier_system,
          code: this.form.product_identifier_code
        };
        manufacturer.new_identifier = "";
        this.form.product_identifier_code = "";
      }
    },
    remove_manufacturer_identifier(manufacturer, index) {
      // not good either, but is like FHIR - TODO - see comment above
      let removed_identifier = manufacturer.identifiers.splice(index, 1);
      // remove?
      delete manufacturer.product_identifiers[removed_identifier];
    },
    set_ingredient_primary_idenfier(identifier) {
      this.ingredient.primary_identifier = identifier;
    },
    select_ingredient(selected_ingredient) {
      console.log('selected ingredient')
      this.form.ingredient = selected_ingredient;
      this.ingredient = selected_ingredient;
      this.fix_manufacturers()
      this.form.new_ingredient.description = ""
      this.$router.push({name: 'ingredient-manage-item', params: {ingredient_identifier: this.ingredient.identifier}})
      // change route?
    },
    add_new_ingredient() {
      this.form.new_ingredient.description = this.form.new_ingredient_description;
      if (this.form.ingredient.id) {
        this.ingredient = this.createFreshFormObject();
        this.ingredient = { ...this.ingredient, ...this.form.ingredient };
      } else {
        this.ingredient = this.createFreshFormObject();
        this.ingredient.description = this.form.new_ingredient_description;
      }
    },
    add_ingredient(selected_ingredient) {
      // this.form.new_ingredient = selected_ingredient
      this.ingredient.ingredients.push(selected_ingredient);
      //selected_ingredient = {}
      this.form.new_ingredient.description = "";
      return;
    },
    createFilter(queryString) {
      return result => {
        console.log(result);
        return (
          result.name.toLowerCase().indexOf(queryString.toLowerCase()) === 0
        );
      };
    },
    createFreshFormObject() {
      const user = this.$store.getters["account/profile"];

      return {
        identifier: uuidv4(),
        identifiers: { formula_id: "" },
        product_identifier_code: "",
        product_identifier_system: "NDC",

        category: "",
        ingredient: {},
        new_ingredient_description: "",
        manufacturers: {},
        author: user.name,
        manufacturer: {},
        name: "",
        description: "",
        rxcui: "",
        rxnorm_description: "",

        location: "",
        date_created: "",
        options: {
          auxillary_labels: [],
          is_lab_stock: false,
          location: "",
          stability_span: ""
        },
        instructions: "",
        tasks: [],
        ingredients: [],
        new_ingredient: {
          description: "",
          id: "",
          quantity: "",
          note: "",
          identifiers: [
            {
              code: "",
              system: ""
            }
          ]
        }
      };
    },
    search_ingredients(queryString, cb) {
      let query_object = {
        explicit: 0,
        method: "LIKE",
        code: queryString,
        description: queryString
      };
      NProgress.start();
      this.$store
        .dispatch("ingredient/listIngredients", query_object)
        .then(() => {
          cb(
            this.$store.getters["ingredient/ingredient_search_list"].map(
              item => item.data
            )
          );
        })
        .then(() => NProgress.done())
        .catch(() => {
          NProgress.done();
        });
    },
    record_entry() {
      console.log(this.ingredient);
      NProgress.start();
      this.$store
        .dispatch("ingredient/createIngredient", this.ingredient)
        .then(() => {
          this.$router.push({
            name: "ingredient-manage",
            params: { id: this.ingredient.id }
          });
          this.ingredient = this.createFreshEventObject();
        })
        .then(() => NProgress.done())
        .catch(() => {
          NProgress.done();
        });
    },
    reset() {
        let new_ingredient = this.createFreshFormObject()
      this.$router.push({ name: 'ingredient-create-item', params: {ingredient_prop: new_ingredient}})
    },
    fix_manufacturers() {
      // if a manufacturer is imported incorrectly the identifier could be missing  This is a simple hack to fix it
      // console.log('this.ingredient.manufacturers')
      // console.log(this.ingredient.manufacturers)
      try {
        Object.keys(this.ingredient.manufacturers).forEach(entry => {
          if (!this.ingredient.manufacturers[entry].identifier) {
            this.ingredient.manufacturers[entry].identifier = entry
          }
        })
      } catch(error) {
        console.log(error)
      }
      // console.log(this.ingredient.manufacturers)
    }
  }
};
</script>
