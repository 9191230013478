
<template>
    <div>
    <el-dialog
    title="Add a note"
    :visible.sync="dialogVisible"
    width="50%"
    center>
        <el-row style="margin-bottom:15px">
            <el-col>
                <span class="input-label">Type</span>
                <el-select v-model="form.note_type" placeholder="Select a note type">
                    <el-option
                    v-for="item in note_type_options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </el-col>
        </el-row>
        <el-row>
            <el-col>
                <span class="input-label">Note</span>
                <el-input
                    placeholder="Write your note here ..."
                    ref="note"
                    v-model="form.note_text"
                    type="textarea"
                    :rows="2">
                </el-input>
            </el-col>
        </el-row>
        
        
       
        <span slot="footer" class="dialog-footer">
            <el-button @click="cancelDialog()">Cancel</el-button>
            <el-button type="primary" @click="logEntry()">Confirm</el-button>
        </span>
    </el-dialog>
    </div>
</template>

<script>
import NProgress from "nprogress";
import EventService from "@/services/EventService.js";
import Common from "@/common.js";

export default {
    props: {
        identifier: {
            required: true
        },
        // log_inventory_reduction: {
        // type: Object,
        // required: true
        // },
        dialogVisible: {
            type: Boolean,
            required: true
        },
        // quantity_unit : {
        //     required: true
        // }
    },
    watch: {
        dialogVisible(oldVal,newVal){
            if(oldVal == false){
                this.$emit('closing', {
                dialogVisible: false
            })
            }
        }
    },
    computed: {
        user: {
        get() {
          return this.$store.getters["user/get_user"] || {};
        }
      },
    },
    data() {
        return {
            form: {
                note_text: null,
                note_type: 'Patient Issue',
                date_created: Date.now()
                
            },
            note_type_options: [
                {
                    label: "Patient Issue",
                    value: "Patient Issue"
                },
                {
                    label: "QRE",
                    value: "QRE"
                },
                {
                    label: "Inventory",
                    value: "Inventory"
                },
            ]
        };
    },
methods: {
    cancelDialog() {
        this.$emit('closing', {
                dialogVisible: false
                // quantity: +this.$refs.quantity.value,
                // reason: +this.$refs.reason.value
            })

    },
    logEntry() {
        let self = this
        let current_user = self.$store.getters["account/profile"]
        let query_object = {"note" : self.form, "identifier": self.identifier };
        if(current_user){
            query_object.note.author = {
                user_name : current_user.name,
                name : current_user.user_name
            }
        }

         NProgress.start();
        EventService.inventoryAddNote(query_object).then ( (response) => {
            self.$emit('input', {
                    dialogVisible: false
                })
                // reset form
                self.form= {
                    note_text: null,
                    note_type: 'Patient Issue',
                    date_created: Date.now()
                
                }
        }).then(() => {
            NProgress.done();
            const notification = {
            type: "success",
            message:
                "lot's note logged."
            };
            self.$store.dispatch("notification/add", notification, { root: true });
        })
        .catch((error) => {
            NProgress.done();
            const notification = {
            type: "error",
            message:
                "There was a problem saving your entry: " + error.message
            };
            self.$store.dispatch("notification/add", notification, { root: true });
        })
    },
    updateEntry () {
      this.$emit('input', {
        // quantity: +this.$refs.quantity.value,
        // reason: +this.$refs.reason.value

      })        
    }
}
}
</script>

<style>
.input-label {
    font-size: 1em;
    margin-right: 5px;
}
</style>
