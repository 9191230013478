<template>
 <div class="formula-tasks">
        <h4>
          <i style="margin-right: .5em" class="fal fa-tasks"></i>
          <span>Tasks</span>
        </h4>
        <ol>
    <draggable v-model="localTasks"  @start="drag=true" @end="drag=false" :disabled="! formula_re_order_enabled" handle=".handle">

          <template v-for="(task, index) in localTasks">
            <li :key="index" style="margin-top: 15px;" class="hover-only-parent">
              <span class=" old-task_handle">
                <span class="hover-only task_handle"><i class="fad fa-sort handle "></i>&nbsp;</span>
                  <el-input
                    class="sub_task-instructions"
                    placeholder="Task Instructions"
                    v-model="task.description"
                    clearable
                  ></el-input>
                  <el-input class="sub_task-points" placeholder="5" v-model="task.points"
                    type="number"
                    inputmode="decimal">
                    <template slot="prepend">Points</template>
                  </el-input>&nbsp;
                  <i
                    class="fal fa-minus-circle clickable task-remove"
                    @click="remove_formula_task(index, task)"
                  ></i>
              </span>
              <div class="formula-task-options">
                <span class="formula-task-options-label">
                  <i class="fal fa-balance-scale-right"></i> Record Weight[s]: &nbsp;
                </span>
                <el-switch v-model="task.record_weights" active-text inactive-text></el-switch>&nbsp; | &nbsp;
                <span class="formula-task-options-label">
                  <i class="fal fa-keyboard"></i> Record Other Measurements &nbsp;
                </span>
                <el-switch v-model="task.record_manual_measurement" active-text inactive-text></el-switch>&nbsp; | &nbsp;
                <span class="formula-task-options-label">
                  <i class="fad fa-stopwatch"></i> Record Time &nbsp;
                </span>
                <el-switch v-if="false && 'awaiting support'" v-model="task.record_time_measurement" active-text inactive-text></el-switch>&nbsp; | &nbsp;

                <span class="formula-task-options-label">
                  <i class="fas fa-camera"></i> Include Photo &nbsp;
                </span>
                <el-switch v-model="task.record_photo" active-text inactive-text></el-switch>&nbsp; | &nbsp;

                <span class="formula-task-options-label">
                  <i class="fas fa-exclamation"></i> Required &nbsp;
                </span>
                <el-switch v-model="task.required" active-text inactive-text></el-switch>
              </div>
            </li>
          </template>
    </draggable>
        </ol>

        <div style="margin-top: 20px;">
          <el-input placeholder="Add Task" v-model="form.new_task">
            <el-button
              @click="localTasks.push({ description: form.new_task, points: 1, weights: [], measurements: [], image: '' }); form.new_task='';"
              slot="append"
              icon="fal fa-plus-circle"
            ></el-button>
          </el-input>
        </div>
      </div>
</template>

<script>
// var decycle = require('json-decycle').decycle
// var retrocycle = require('json-decycle').retrocycle
import draggable from 'vuedraggable'

const getCircularReplacer = () => {
  const seen = new WeakSet();
  return (key, value) => {
    if (typeof value === "object" && value !== null) {
      if (seen.has(value)) {
        return;
      }
      seen.add(value);
    }
    return value;
  };
};

export default {
    name:"taskspart",
    components: {
      draggable
    },
  props: {
    "tasks": {
      type: Array,
      default: []
    },
    "form": {
      type: Object,
      default: function() {
        return {new_task: ''}
      }
    }
  },
  data(){
      return{
          localTasks: JSON.parse(JSON.stringify(this.tasks, getCircularReplacer())),
          formula_re_order_enabled: true
      }
  },
  watch: {
        localTasks: {
            handler(v){
                this.$emit('updateTasks', v)
            },
            deep: true
        }
  },
  methods: {
    remove_formula_task(task_index, task) {
      // TODO: this mutates the store and might not be reactive
      this.localTasks.splice(task_index, 1);
    },
  }
};
</script>

<style>

.sub_task-instructions {
  display: inline-block;
  min-width: 25em;
  max-width: 80%;
}
.sub_task-points {
  max-width: 8em;
  margin: 0 1em;
}

.task-remove:hover {
  color: rgb(207, 13, 13);
}


.formula-task-options {
  margin-top: 0.35em;
}
</style>
<style scoped>
.task_handle {
  font-size: 1.25em;
  padding-right: 0.5em;
  margin-right: 0.5em;
}
</style>
