<template>
  <el-row>
    <div>Date Entered: {{ format_to_correct_time_zone(date_entered) }}</div>

    <div>
      <el-row style="margin-bottom: 0px !important">
        <el-col :span="24">
          <span v-if="edit_date_scheduled == false">Date Scheduled:
            <el-button
              type="text"
              @click="set_task_date_to_edit('date_scheduled')"
            >{{ format_to_correct_time_zone(date_scheduled) }}</el-button>
          </span>
          
          <span v-if="edit_date_scheduled == true">
            <el-date-picker
              style="width: 100%;"
              type="datetimerange"
              v-model="task_date"
              value-format="yyyy-MM-dd HH:mm:ss"
              format="MM/dd HH:mm"
              :picker-options="this.picker_options"
            ></el-date-picker>
          </span>
        </el-col>
      </el-row>
    </div>

    <div>
      <el-row style="margin-bottom: 0px !important;">
        <el-col :span="24" style="margin-top: -8px;">
          <span v-if="edit_date_due == false">Date Due:
            <el-button
              type="text"
              @click="set_task_date_to_edit('date_due')"
            >{{ format_to_correct_time_zone(date_due) }}</el-button>
          </span>
          
          <span v-if="edit_date_due == true">
            <el-date-picker
              style="width: 100%;"
              type="datetimerange"
              v-model="task_date"
              value-format="yyyy-MM-dd HH:mm:ss"
              format="MM/dd HH:mm"
              :picker-options="this.picker_options"
            ></el-date-picker>
          </span>
        </el-col>
      </el-row>
    </div>
  </el-row>
</template>

<!-- component -->
<script>
const moment = require('moment')
//require('moment/locale/es')

// Vue.use(require('vue-moment'), {
//   moment
// })
export default {
  props: {
    task: Object
  },

  data() {
    return {
      picker_options: {
        shortcuts: [
          {
            text: 'Tomorrow',
            onClick(picker) {
              const start = moment(new Date())
                .add(1, 'days')
                .set('hour', 8)
                .set('minute', 0)
                .set('second', 0)._d
              const end = moment(new Date())
                .add(1, 'days')
                .set('hour', 17)
                .set('minute', 0)
                .set('second', 0)._d
              // end.setTime(end.getTime() + 3600 * 1000 * 24);

              picker.$emit('pick', [start, end])
            }
          },
          {
            text: 'Monday',
            onClick(picker) {
              let start = ''
              let end = ''

              if (moment().day() >= 1) {
                start = moment(
                  moment()
                    .day(1)
                    .set('hour', 8)
                    .set('minute', 0)
                    .set('second', 0)._d
                ).add('day', 7)
                end = moment(
                  moment()
                    .day(1)
                    .set('hour', 17)
                    .set('minute', 0)
                    .set('second', 0)._d
                ).add('day', 7)
              } else {
                start = moment()
                  .day(1)
                  .set('hour', 8)
                  .set('minute', 0)
                  .set('second', 0)._d
                end = moment()
                  .day(1)
                  .set('hour', 17)
                  .set('minute', 0)
                  .set('second', 0)._d
              }

              picker.$emit('pick', [start, end])
            }
          },
          {
            text: 'Tuesday',
            onClick(picker) {
              let start = ''
              let end = ''

              if (moment().day() >= 2) {
                start = moment(
                  moment()
                    .day(2)
                    .set('hour', 8)
                    .set('minute', 0)
                    .set('second', 0)._d
                ).add('day', 7)
                end = moment(
                  moment()
                    .day(2)
                    .set('hour', 17)
                    .set('minute', 0)
                    .set('second', 0)._d
                ).add('day', 7)
              } else {
                start = moment()
                  .day(2)
                  .set('hour', 8)
                  .set('minute', 0)
                  .set('second', 0)._d
                end = moment()
                  .day(2)
                  .set('hour', 17)
                  .set('minute', 0)
                  .set('second', 0)._d
              }

              picker.$emit('pick', [start, end])
            }
          },
          {
            text: 'Wednesday',
            onClick(picker) {
              let start = ''
              let end = ''

              if (moment().day() >= 3) {
                start = moment(
                  moment()
                    .day(3)
                    .set('hour', 8)
                    .set('minute', 0)
                    .set('second', 0)._d
                ).add('day', 7)
                end = moment(
                  moment()
                    .day(3)
                    .set('hour', 17)
                    .set('minute', 0)
                    .set('second', 0)._d
                ).add('day', 7)
              } else {
                start = moment()
                  .day(3)
                  .set('hour', 8)
                  .set('minute', 0)
                  .set('second', 0)._d
                end = moment()
                  .day(3)
                  .set('hour', 17)
                  .set('minute', 0)
                  .set('second', 0)._d
              }

              picker.$emit('pick', [start, end])
            }
          },
          {
            text: 'Thursday',
            onClick(picker) {
              let start = ''
              let end = ''

              if (moment().day() >= 4) {
                start = moment(
                  moment()
                    .day(4)
                    .set('hour', 8)
                    .set('minute', 0)
                    .set('second', 0)._d
                ).add('day', 7)
                end = moment(
                  moment()
                    .day(4)
                    .set('hour', 17)
                    .set('minute', 0)
                    .set('second', 0)._d
                ).add('day', 7)
              } else {
                start = moment()
                  .day(4)
                  .set('hour', 8)
                  .set('minute', 0)
                  .set('second', 0)._d
                end = moment()
                  .day(4)
                  .set('hour', 17)
                  .set('minute', 0)
                  .set('second', 0)._d
              }

              picker.$emit('pick', [start, end])
            }
          },
          {
            text: 'Friday',
            onClick(picker) {
              let start = ''
              let end = ''

              if (moment().day() >= 5) {
                start = moment(
                  moment()
                    .day(5)
                    .set('hour', 8)
                    .set('minute', 0)
                    .set('second', 0)._d
                ).add('day', 7)
                end = moment(
                  moment()
                    .day(5)
                    .set('hour', 17)
                    .set('minute', 0)
                    .set('second', 0)._d
                ).add('day', 7)
              } else {
                start = moment()
                  .day(5)
                  .set('hour', 8)
                  .set('minute', 0)
                  .set('second', 0)._d
                end = moment()
                  .day(5)
                  .set('hour', 17)
                  .set('minute', 0)
                  .set('second', 0)._d
              }

              picker.$emit('pick', [start, end])
            }
          }
        ]
      }
    }
  },
  methods: {
    format_to_correct_time_zone(date) {
      let timezone_offset = new Date().getTimezoneOffset()
      let timezone_adjusted_date = moment(date)
        .add(timezone_offset, 'minutes')
        .format('dddd, MMM Do, h:mm a')
      return timezone_adjusted_date
    },
    set_task_date_to_edit(date_to_edit) {
      this.$store.dispatch(
        'current_working_task/set_task_date_to_edit',
        date_to_edit
      )
    }
  },
  computed: {
    edit_date_due: {
      get() {
        return this.$store.getters['current_working_task/get_task'][
          'task_date_to_edit'
        ]['date_due']
      },
      set(value) {
        this.$store.dispatch(
          'current_working_task/set_task_date_to_edit',
          value // was: 'date_due'
        )
      }
    },
    edit_date_scheduled: {
      get() {
        return this.$store.getters['current_working_task/get_task'][
          'task_date_to_edit'
        ]['date_scheduled']
      },
      set(value) {
        this.$store.dispatch(
          'current_working_task/set_task_date_to_edit',
          value // ??? was: 'date_scheduled'
        )
      }
    },
    date_due: {
      get() {
        return this.$store.getters['current_working_task/get_task']['task'][
          'date_due'
        ]
      }
    },
    date_scheduled: {
      get() {
        return this.$store.getters['current_working_task/get_task']['task'][
          'date_scheduled'
        ]
      }
    },
    date_entered: {
      get() {
        return this.$store.getters['current_working_task/get_task']['task'][
          'date_entered'
        ]
      }
    },
    task_date: {
      get() {
        let timezone_offset = new Date().getTimezoneOffset()
        let date_scheduled = this.$store.getters[
          'current_working_task/get_task'
        ]['task']['date_scheduled']
        let date_due = this.$store.getters['current_working_task/get_task'][
          'task'
        ]['date_due']

        return [
          moment(
            moment(date_scheduled).add(timezone_offset, 'minutes')._d
          ).format('YYYY-MM-DD HH:mm:ss'),
          moment(moment(date_due).add(timezone_offset, 'minutes')._d).format(
            'YYYY-MM-DD HH:mm:ss'
          )
        ]
      },
      set(value) {
        this.$store.dispatch('current_working_task/set_task_date', value)
        this.$store.dispatch(
          'current_working_task/set_task_date_to_edit',
          'all_false'
        )
      }
    }
  }
}
</script>
