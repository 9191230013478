<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>
          <span style="font-size: 1.5em">Manage Ingredients</span>
          &nbsp;
          <span class="text-secondary"></span>
        </span>

        <el-button
          type="primary"
          style="float: right; padding: 5px; margin-left:  .75em;  margin-right:  .75em;"
          @click="record_entry()"
        >
          <i class="fal fa-save"></i> Save
        </el-button>&nbsp;
        <el-button
          type="default"
          style="float: right; padding: 5px; margin-left:  .75em;  margin-right:  .75em;"
          @click="reset()"
        >
          <i class="fal fa-plus"></i> New
        </el-button>&nbsp;
      </div>
      <el-row :gutter="20" class="ingredient-options">
        <el-col :span="9">
          <h3 class="ingredient-description">Ingredient Description</h3>
          <el-input v-model="ingredient.description" style="padding-bottom: 10px;"></el-input>
        </el-col>
        <el-col :span="1">&nbsp;</el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="6">
          <div>Code:</div>
          <span v-if="ingredient.code_static">{{ ingredient.code }}</span>
          <el-input v-else v-model="ingredient.code" id="code_input"></el-input>
        </el-col>
        <el-col :span="6">
          <div>Units:</div>
          <!-- <span v-if="ingredient.unit_of_measure">{{ ingredient.unit_of_measure }}</span> -->
          <span v-if="ingredient.readonly">
            <BaseUnitOfMeasure :unit="(ingredient.unit_of_measure)"/>
          </span>
          <el-select v-else v-model="ingredient.unit_of_measure" filterable clearable placeholder="Units">
            <el-option
              v-for="item in terminology.unit_of_measure"
              :key="item.code"
              :label="item.display || item.description"
              :value="item.code"
            >
              <span style="float: left">{{ item.display || item.description }}</span>
              <span style="float: right; color: #8492a6; font-size: 0.85em">{{ item.description }}</span>
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="6">
          <div>Primary NDC for billing purposes:</div>
          <el-input
            pattern="\d{11,12}"
            maxlength="12"
            placeholder="11/12-digit NDC: 012345121234"
            v-model="ingredient.primary_billing_identifier"
            v-on:change="get_rxnorm_substance()"
            @input="clean_ndc"
            style="    width: 15.5em;"
          >Primary</el-input>
          <span v-if="ingredient.primary_billing_identifier && !is_manufacturer_ndc" style="color: tomato;">
            Acquisition Cost and billing can not be properly calculated if there is not a manufacturer entry for the primary NDC
          </span>
        </el-col>
        <el-col :span="6">
          <div>Cost basis (per-unit):</div>
          <el-input
            pattern="\d+\.\d{0,2}"
            placeholder="per-unit cost basis, e.g. 1.50"
            v-model="ingredient.primary_billing_unit_cost"
            on:change="ingredient.primary_billing_unit_cost=parseFloat(ingredient.primary_billing_unit_cost).toFixed(2)"
            style="width: 9em;"
            type="number"
            step="0.01"
            inputmode="decimal"
          >Primary</el-input>
        </el-col>
      </el-row>
      <el-row v-if="ingredient.code" style="margin-top: .75em;" :gutter="20">
        <el-col :span="6">
          Location:
          <el-input v-model="ingredient.location"></el-input>
        </el-col>


        <el-col :span="6">
          Linked Substance:
          <div v-if="ingredient.rxcui">{{ ingredient.rxnorm_description }} [{{ingredient.rxcui}}]</div>
          <div v-if="ingredient.dea_schedule" class="light-weight smaller">
            <i class="fal fa-exclamation-square"></i> {{ingredient.dea_schedule.description}}
          </div>
        </el-col>

        <el-col :span="6">
          <div>CAS:</div>
          <el-input v-model="ingredient.cas_number"
            style="width: 9em;"
            placeholder="CAS"
          ></el-input>
        </el-col>
        <el-col :span="6">
          <div>Inventory Re-order point:</div>
          <el-input v-model.number="ingredient.inventory_reorder_point"
            style="width: 9em;"
            placeholder="100"
            type="number"
            inputmode="decimal"
          ></el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4" style="margin: 1.5em 0 0 0;">
          <el-checkbox v-model="ingredient.is_hazardous">Hazardous</el-checkbox>
        </el-col>
        <el-col :span="4" style="margin: 1.5em 0 0 0;">
          <el-checkbox v-model="ingredient.active_indicator">Active</el-checkbox>
        </el-col>
        <el-col :span="4" style="margin: 1.5em 0 0 0;">
          <el-checkbox v-model="ingredient.is_consumable">Consumable</el-checkbox>&nbsp;
          <el-tooltip class="item" content="Will not be used in Beyond Use Date calculations" placement="top">
            <el-button type="text"><i class="fad fa-question-circle"></i></el-button>
          </el-tooltip>
        </el-col>
        <el-col :span="6" style="margin: 1.5em 0 0 0;" v-if="pmk_config.modules.electriclab.activity_factor_pack_stats">
          <el-checkbox v-model="ingredient.has_activity_factor">Has Activity Factor</el-checkbox>
        </el-col>
        <el-col :span="6" style="margin: 1.5em 0 0 0;" v-if="pmk_config.modules.electriclab.activity_factor_pack_stats">
          <el-checkbox v-model="ingredient.has_pack_stat">Has Pack Stat</el-checkbox>
        </el-col>
        <el-col :span="6" style="margin: 1.5em 0 0 0;">
          <el-button type="text" @click="dialogDetailsVisible = true">Details</el-button>

          <el-dialog title="Ingredient Details" :visible.sync="dialogDetailsVisible">
            <el-form :model="ingredient.details">
              <el-form-item label="HMIS Pregnancy Rating" :label-width="formLabelWidth">
                <el-input v-model="ingredient.details.HMIS_pregnancy_rating" autocomplete="off"></el-input>
              </el-form-item>

              <el-form-item label="Solubility" :label-width="formLabelWidth">
                <el-input v-model="ingredient.details.solubility" autocomplete="off"></el-input>
              </el-form-item>

              <el-form-item label="Melting Point" :label-width="formLabelWidth">
                <el-input v-model="ingredient.details.melting_point" autocomplete="off"></el-input>
              </el-form-item>

              <el-form-item label="Water Content" :label-width="formLabelWidth">
                <el-input v-model="ingredient.details.water_content" autocomplete="off"></el-input>
              </el-form-item>

              <el-form-item label="Molecular Weight" :label-width="formLabelWidth">
                <el-input v-model="ingredient.details.molecular_weight" autocomplete="off"></el-input>
              </el-form-item>

              <el-form-item label="Density" :label-width="formLabelWidth">
                <el-input v-model="ingredient.details.density" autocomplete="off"></el-input>
              </el-form-item>

              <el-form-item label="pH Stability Range" :label-width="formLabelWidth">
                <el-input v-model="ingredient.details.ph_stability_range" autocomplete="off"></el-input>
              </el-form-item>

              <el-form-item label="Waters of Hydration" :label-width="formLabelWidth">
                <el-input v-model="ingredient.details.waters_of_hydration" autocomplete="off"></el-input>
              </el-form-item>

            </el-form>
            <span slot="footer" class="dialog-footer">
              <!-- <el-button @click="dialogDetailsVisible = false">Cancel</el-button> -->
              <el-button type="primary" @click="dialogDetailsVisible = false">Close</el-button>
            </span>
          </el-dialog>

        </el-col>

      </el-row>
      <div class="manufacturers-area" v-if="ingredient.code">
        <el-row class="ingredient-suppliers header-area">
          <el-col :span="6">
            <h4>Manufacturers</h4>
          </el-col>
          <el-col :span="18">
            <h4>Manufacturer Product Codes</h4>
          </el-col>
        </el-row>
        <el-row
          v-for="manufacturer in Object.values(ingredient.manufacturers || {})"
          :key="manufacturer.identifier"
          class="ingredient-suppliers entry"
        >
          <ManufacturerCodeForm :manufacturer="manufacturer"></ManufacturerCodeForm>
        </el-row>
        <el-row>
          <div style="margin-top: 15px;">
            <el-col :span="6">
                <el-select v-model="form.selected_manufacturer_name" filterable placeholder="Manufacturer"
                @change="select_manufacturer"
                >
                  <el-option
                    v-for="(item, index) in Object.values(this.manufacturers).sort((a, b) => a.name.localeCompare(b.name))"
                    :key="index"
                    :label="item.name"
                    :value="item.identifier"

                    >
                  </el-option>

                </el-select>
            </el-col>
            <el-col :span="6">
              <el-button icon="fal fa-plus" size="medium" type="primary"
                :disabled="!(!!form.manufacturer.identifier ? !ingredient.manufacturers[form.manufacturer.identifier] : false )"
                @click="add_new_manufacturer" plain
              >
                Add
              </el-button>
            </el-col>
          </div>
        </el-row>
      </div>
    </el-card>
  </div>
</template>
<script>
import ManufacturerTable from "@/components/ingredient/ManufacturerTable.vue";
import ManufacturerCodeForm from "@/components/ingredient/form_add_manufacturer_code.vue";
import NProgress from "nprogress";
import EventService from "@/services/EventService.js";
import { mapGetters } from 'vuex'
import Common from "@/common.js";

const uuidv4 = require("uuid/v4");

export default {
  // props: {
  //   formula: {
  //     type: Object,
  //     required: false,
  //     default: this.createFreshFormObject()
  //   }
  // },
  props: {
      ingredient_prop: {
          type: Object,
          required: true
      }
  },
  components: {
    ManufacturerTable,
    ManufacturerCodeForm
  },

  data() {
    return {
      categories: this.$store.state.categories,
      form: this.createFreshFormObject(),
      ingredient: { ...this.ingredient_prop, ...{ details: {...this.createDetailsObject(), ...this.ingredient_prop.details }}},
      dialogDetailsVisible: false,
      formLabelWidth: "35",
      is_manufacturer_ndc: false,
    };
  },
  computed: {
    terminology: {
      get() {
        return this.$store.getters["terminology/terminology"];
      }
    },
    pmk_config: {
      get() {
        return this.$store.getters["app/pmk_config"];
      }
    },
    manufacturers: {
      get() {
        return this.$store.getters["manufacturer/get_manufacturers"];
      }
    }
  },
  mounted() {
    Object.values(this.ingredient.manufacturers).forEach(manufacturer => {
      if (this.manufacturers[manufacturer.identifier]) {
        manufacturer.name = this.manufacturers[manufacturer.identifier].name
      }
    })
    this.clean_ndc()
  },
  methods: {
    get_rxnorm_substance() {
      let self = this;
      console.log(self.ingredient.primary_billing_identifier);
      EventService.getRXNORM({
        ndc: self.ingredient.primary_billing_identifier
      }).then(response => {
        this.ingredient = {
          ...this.ingredient,
          ...{
            rxnorm_description: response.data.data.description,
            rxcui: response.data.data.rxcui
          }
        };
      });
      EventService.getNDCOpenFDA({
        ndc: self.ingredient.primary_billing_identifier
      }).then(response => {
        console.log(response.data.data)
        this.ingredient = {
          ...this.ingredient,
          ...{
            fda_active_pharmaceutical_ingredient_indicator: response.data.data.pharmetika_terms.active_pharmaceutical_ingredient_indicator,
            dea_schedule: response.data.data.pharmetika_terms.dea_schedule
          }
        };

        // self.ingredient.rxnorm_description = response.data.data.description
        // self.ingredient.rxcui = response.data.data.rxcui
      });
    },
    get_manufacturers(queryString, cb) {
      let options = Object.values(this.manufacturers);
      // .map(function(manufacturer) {
      //   return { value: manufacturer.value, id: manufacturer, name: manufacturer }
      // })
      console.log(options);
      console.log(queryString);
      let results = queryString
        ? options.filter(this.createFilter(queryString))
        : options;
      cb(results);
    },
    calculate_total() {
      let total_quantity = 0;
      this.ingredient.ingredients.forEach(function(ingredient) {
        total_quantity += parseFloat(ingredient.quantity) || 0;
      });
      return total_quantity;
    },

    add_new_manufacturer() {
      if (this.form.manufacturer.name) {

        let new_manufacturer = { identifiers: [], ...this.form.manufacturer };

        //   typeof this.form.manufacturer == 'object'
        //     ? this.form.manufacturer
        //     : { name: this.form.manufacturer, id: this.form.manufacturer }
        // new_manufacturer.identifiers = []

        // hack to fix imports:
        if (typeof this.ingredient.manufacturers != "object") {
          this.ingredient = { ...{ manufacturers: {} }, ...this.ingredient };
        }
        if (this.ingredient.manufacturers[new_manufacturer.identifier]) {
          // already exists:
          // do nothing ?
        } else {
          this.ingredient.manufacturers[
            new_manufacturer.identifier
          ] = new_manufacturer;
        }

        this.form.manufacturer = {};
      }
    },

    select_manufacturer(manufacturer_identifier) {
      // hack to fix imports - similar to code above, but setting form.manufacturer forces the render of ingredient.manufacturer:
      if (typeof this.ingredient.manufacturers != "object") {
        this.ingredient = { ...{ manufacturers: {} }, ...this.ingredient };
      }
      this.form.manufacturer = this.manufacturers[manufacturer_identifier];
    },

    add_manufacturer_code(manufacturer) {
      // not good either, but is like FHIR - TODO - HOWEVER: here it creates an array AND an object, so we can leave as-is for now
      //console.log('manufacturer: adding code')
      // console.log(manufacturer)

      if (manufacturer.new_identifier) {
        manufacturer.identifiers.push(manufacturer.new_identifier);
        if (!manufacturer.product_identifiers) {
          manufacturer.product_identifiers = {};
        }

        manufacturer.product_identifiers[manufacturer.new_identifier] = {
          system: this.form.product_identifier_system,
          code: this.form.product_identifier_code
        };
        manufacturer.new_identifier = "";
        this.form.product_identifier_code = "";
      }
    },
    remove_manufacturer_identifier(manufacturer, index) {
      // not good either, but is like FHIR - TODO - see comment above
      let removed_identifier = manufacturer.identifiers.splice(index, 1);
      // remove?
      delete manufacturer.product_identifiers[removed_identifier];
    },
    set_ingredient_primary_idenfier(identifier) {
      this.ingredient.primary_identifier = identifier;
    },
    add_new_ingredient() {
      this.form.new_ingredient.description = this.form.new_ingredient_description;
      if (this.form.ingredient.id) {
        this.ingredient = this.createFreshFormObject();
        this.ingredient = { ...this.ingredient, ...this.form.ingredient };
      } else {
        this.ingredient = this.createFreshFormObject();
        this.ingredient.description = this.form.new_ingredient_description;
      }
    },
    add_ingredient(selected_ingredient) {
      // this.form.new_ingredient = selected_ingredient
      this.ingredient.ingredients.push(selected_ingredient);
      //selected_ingredient = {}
      this.form.new_ingredient.description = "";
      return;
    },
    createFilter(queryString) {
      return result => {
        console.log(result);
        return (
          result.name.toLowerCase().indexOf(queryString.toLowerCase()) === 0
        );
      };
    },
    createFreshFormObject() {
      const user = this.$store.getters["account/profile"];

      return {
        identifier: uuidv4(),
        identifiers: { formula_id: "" },
        product_identifier_code: "",
        product_identifier_system: "NDC",
        active: false,
        active_indicator: false,
        active_pharmeceutical_ingredient_indicator: false,
        category: "",
        ingredient: {},
        new_ingredient_description: "",
        manufacturers: {},
        author: user.name,
        manufacturer: {},
        name: "",
        description: "",
        rxcui: "",
        rxnorm_description: "",

        location: "",
        date_created: "",
        options: {
          auxillary_labels: [],
          is_lab_stock: false,
          location: "",
          stability_span: ""
        },
        details: this.createDetailsObject(),
        instructions: "",
        tasks: [],
        ingredients: [],
        new_ingredient: {
          description: "",
          id: "",
          quantity: "",
          note: "",
          identifiers: [
            {
              code: "",
              system: ""
            }
          ]
        }
      };
    },
    createDetailsObject() {
      return {
          HMIS_pregnancy_rating: "",
          solubility: "",
          melting_point: "",
          water_content: "",
          molecular_weight: "",
          density: "",
          ph_stability_range: "",
          waters_of_hydration: ""
        }
    },
    clean_ndc() {
      this.ingredient.primary_billing_identifier = this.ingredient.primary_billing_identifier.replace(/\D/g,'')
      this.is_manufacturer_ndc = false
      Object.values(this.ingredient.manufacturers).forEach(manufacturer => {
        Object.values(manufacturer.product_identifiers).forEach(identifier => {
          if (identifier.code == this.ingredient.primary_billing_identifier) {
            this.is_manufacturer_ndc = true
          }
        })
      })
    },
    record_entry() {
      console.log(this.ingredient);
      if (!this.ingredient.code) {
        this.$message({
          type: 'error',
          message: 'You must enter a code before saving'
        })
        document.querySelector('#code_input').focus()
        return
      }
      NProgress.start();
      this.$store
        .dispatch("ingredient/createIngredient", this.ingredient)
        .then(() => {
          this.$router.push({
            name: "ingredient-manage",
            params: { id: this.ingredient.id }
          });
          this.ingredient = this.createFreshEventObject();
        })
        .then(() => NProgress.done())
        .catch(() => {
          NProgress.done();
        });
    },
    reset() {
      this.form = this.createFreshFormObject();
      this.ingredient = this.createFreshFormObject();
    }
  }
};
</script>
<style scoped>
.location {
  margin-bottom: 0;
}
.location > .icon {
  margin-left: 10px;
}
.event-header > .title {
  margin: 0;
}
.list-group {
  margin: 0;
  padding: 0;
  list-style: none;
}
.list-group > .list-item {
  padding: 1em 0;
  border-bottom: solid 1px #e5e5e5;
}

.formula-options .el-col {
  margin-top: 4px;
}

.auxiallary_button {
  margin-right: 1.5em;
}

.formula-instructions {
  margin: 1.5em 0;
}

.button.bottom {
  margin-top: 13px;
  line-height: 12px;
  padding: 0;
  float: right;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}

.ingredient-suppliers.entry {
  margin-top: 1em;
  padding-bottom: 0.5em;
  border-bottom: 1px solid silver;
}
.ingredient-suppliers h4 {
  font-weight: bold;
}
.manufacturers-area {
  margin: 0.5em 0;
}

.ingredient-options .ingredient-description {
  font-weight: bold;
  margin: 0.75em 0;
}
.ingredient-remove:hover {
  color: rgb(207, 13, 13);
}
.clickable {
  cursor: pointer;
}
.clickable:hover {
  color: #409eff;
}

.light-weight.smaller {
  font-size: 0.8em;
  opacity: 0.75;
}
</style>
