

import EventService from "@/services/EventService.js";
import global_store from "@/store/store";
import * as user from "@/store/modules/account.js";


const uuidv4 = require("uuid/v4");
import ReconnectingWebSocket from "reconnecting-websocket";
import { Message } from 'element-ui';

export const namespaced = true

export const state = {
  notifications: [],
  messages: []
}

let nextId = 1

export const mutations = {
  PUSH(state, notification) {
    state.notifications.push({
      ...notification,
      id: nextId++
    })
  },
  DELETE(state, notificationToRemove) {
    state.notifications = state.notifications.filter(
      notification => notification.id !== notificationToRemove.id
    )
  },
// Messages:
  MESSAGE_PUSH(state, notification) {
    state.messages.push({
      ...notification,
      id: nextId++
    })
  },
  MESSAGE_DELETE(state, notificationToRemove) {
    state.messages = state.messages.filter(
      notification => notification.id !== notificationToRemove.id
    )
  }

}
export const actions = {
  add({ commit }, notification) {
    commit('PUSH', notification)

  },
  remove({ commit }, notificationToRemove) {
    commit('DELETE', notificationToRemove)
  },
  fetchMessages({ state, commit, getters, dispatch }) {
    return EventService.fetchMessages()
      .then(response => {
        console.log(52, response)
        if (response.data.data.length) {
          response.data.data.forEach(function(notification) {
            commit('MESSAGE_PUSH', notification)
          })
        }
          return response.data
        })
        .catch(error => {
          const notification = {
            type: 'error',
            message:
              'There was a problem fetching data: ' + error.message
          }
          dispatch('notification/add', notification, { root: true })
        })
  },
  message_add({ commit }, notification) {
    commit('MESSAGE_PUSH', notification)

  },
  message_remove({ commit }, notificationToRemove) {
    commit('MESSAGE_DELETE', notificationToRemove)
  },
  message_ack({ commit }, notification) {
    return EventService.message_ack({ message: notification})
      .then(rsp => {
        //commit('SET_TASKS_TOTAL', parseInt(response.headers['x-total-count']))
        commit('MESSAGE_DELETE', notification)
        return rsp;
      })
      .catch(error => {
        const notification = {
          type: "error",
          message:
            "There was a problem fetching data: " + error.message
        };

      })
  }
}

export const getters = {
  get_messages: state => {
    return state.messages
  },
  get_messages_ordered_reversed: state => {
    return state.messages.slice().reverse()
  }

}

function get_ws_url(named_resource) {
  let my_user = user.state.profile
  let user_id = my_user.user_id
  if (user_id) {
    let http_url = new URL(url_for('ws_communications_internal_user_only', { user_id: user_id }), window.location.origin)
    // new URL(url_for('ws_communications_internal'), window.location.origin).href
    http_url.protocol='wss:'
    return http_url.href;
  } else {
    return false
  }
}

const urlProvider = () => { return get_ws_url() };

//const ws_communications_internal_url =
var WS_communications_internal = new ReconnectingWebSocket(
  urlProvider
);
WS_communications_internal.addEventListener("message", ws_use_data);

window.setInterval(function() {
  WS_communications_internal.send('PING ' + Math.floor(Math.random()*10000000))
}, 56400)

function ws_use_data(e) {
  // console.log(`<%= __FILE__ %>[<%= this %>]: `, this)
  let ws_message = JSON.parse(e.data)
  // console.log(`<%= notification.js %>[<%= 122 %>]: `, ws_message.data)

  if (ws_message.data.data && ws_message.data.data.display_alert) {
    // console.log(`<%= notification.js %>[<%= 127 %>]: `, ws_message.data.data.title)
    Message( {
        message: ws_message.data.data.title,
        duration: ws_message.data.data.alert_duration || 3000,
        type: ws_message.data.data.type,
        onClick: function() {console.log({msg_128: ws_message.data})},
        iconClass: ws_message.data.data.icon
      }
    )
  }
  // add message to notifications:
  if (ws_message.data.message_status == 'new') {
    global_store.dispatch('notification/message_add', ws_message.data, { root: true })
  } else {
    global_store.dispatch('notification/message_remove', ws_message.data, { root: true })
  }



  // var reader = new FileReader();
  // reader.onload = () => {
  //   // console.table([reader.result, ws_data_buffer]);
  //   // console.log("current_equipment_config", current_equipment_config);
  //   let clear_buffer = false;
  //   if (reader.result && reader.result.match(/\n$/)) {
  //     clear_buffer = true;
  //   }
  //   ws_data_buffer = ws_data_buffer + reader.result;

  //   if (clear_buffer) {
  //     let parsed_result = JSON.parse(ws_data_buffer);
  //     ws_data_buffer = "";
  //     //current_equipment_cb(parsed_result);
  //     console.log(116, parsed_result)
  //   } else {
  //     console.log(118, reader.result)
  //   }
  // };
//  reader.readAsText(e.data);
}
