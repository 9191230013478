import axios from "axios";
import url_for from "./pmk_url_for";
import store from "@/store/store";
import { getPMKTarget } from '../../lib/settings'

// TODO: move this to common and import:
const getCircularReplacer = () => {
  const seen = new WeakSet();
  return (key, value) => {
    if (typeof value === "object" && value !== null) {
      if (seen.has(value)) {
        return;
      }
      seen.add(value);
    }
    return value;
  };
};

import LogRocket from 'logrocket'
import _ from 'lodash'
const log_rocket_url = _.memoize(() => {
  let session_url = 'error'
  LogRocket.getSessionURL(function (sessionURL) {
    session_url = sessionURL
  })
  return session_url
})

let last_updated_log_rocket_url

const get_log_rocket_url = () => {
  let url = log_rocket_url()
  if (url === 'error' || new Date() - last_updated_log_rocket_url > 30000) {
    log_rocket_url.cache = new _.memoize.Cache
    url = log_rocket_url()
    last_updated_log_rocket_url = new Date()
  }
  let timestamp = new Date().valueOf()
  url = url.replace(/\?t=.*$/g, '?t=' + timestamp)
  return url
}

// TODO BEFORE PRODUCTION: remove the OR:
const pmk_re = new RegExp("pharmetika|lab1|electriclab", "i");
const url_origin = pmk_re.test(document.referrer) ? document.referrer
  : (
    pmk_re.test(window.location.origin) ?
    window.location.origin : getPMKTarget()
  )
const base_api_url = new URL(url_origin);

const pmkClient = pmk_re.test(base_api_url.origin)
  ? axios.create({
      baseURL: base_api_url.origin,
      withCredentials: true,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
      // transformRequest: function(data) {
      //   const formData = new FormData()
      //   Object.keys(data).forEach(key => formData.append(key, data[key]))
      //   return formData
      // }
    })
  : axios.create({
      baseURL: base_api_url.origin,
      withCredentials: true,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
      // transformRequest: function(data) {
      //   const formData = new FormData()
      //   Object.keys(data).forEach(key => formData.append(key, data[key]))
      //   return formData
      // }
    });

// Add a response interceptor to catch and respond appropriately to 401 errors
pmkClient.interceptors.response.use(
  response => response,
  (err) => {
    if (err.response && err.response.status === 401) {
      // unset authorization to trigger display of the reauth dialog
      store.dispatch('reauth/unset_authorization')
    } else if (err.response && err.response.status === 403) {
      console.log('403 err', err)
      return Promise.resolve(err.response)
    } else {
      console.log('pmk_fetch err', err)
      return Promise.resolve(err.response)
    }
  }
)

const request_cancel_exclude = [
  'pmk_document_get',
  'terminologies_get_medication_attributes',
  'electric_lab_get_terminology',
  'electric_lab_get_lab_config'
]

const pmk_fetch_via_axios = function(
  service_name,
  service_method,
  service_parameters,
  args
) {
  service_parameters.version = "v5";
  const request_controller = new AbortController()
  const url = url_for.url_for(service_name, service_parameters)
  let headers = {
    'X-SESSION-ID': get_log_rocket_url(),
    'X-TIMEZONE-NAME': Intl.DateTimeFormat().resolvedOptions().timeZone,
    'X-TIMEZONE-OFFSET': new Date().getTimezoneOffset()
  }
  if (store && !request_cancel_exclude.includes(service_name)) {
    let controllers = store.getters['app/route_controllers']
    if (controllers && controllers[service_name]) {
      try {
        controllers[service_name].abort()
      } catch (e) {
        console.error('1777 error aborting', e)
      }
    }
    store.dispatch('app/setRouteController', {endpoint: service_name, controller: request_controller})
  }
  if (service_method === 'GET') {
    return pmkClient.get(url, { signal: request_controller.signal, params: args, headers: headers })
  }
  if (service_method === 'POST') {
    return pmkClient.post(url, args, { headers: headers})
  }
  if (service_method === 'PUT') {
    return pmkClient.put(url, args, { headers: headers})
  }
  if (service_method === 'DELETE') {
    return pmkClient.delete(url, { params: args, headers: headers})
  }
  if (service_method == "PATCH") {
    return pmkClient.patch(url, args, { headers: headers})
  }
};

const pmk_fetch = pmk_fetch_via_axios;

// EXP:
const pmk_fetch_via_fetch = function(
  resource,
  method = "GET",
  params = { json: true, version: "v5" },
  args = {}
) {
  if (typeof params.version === "undefined") {
    params.version = "v5";
  }
  if (typeof params.json === "undefined") {
    params.json = true;
  }
  console.log("pmk_fetch - args");
  console.log(args);

  let url = base_api_url.origin + url_for.url_for(resource, params);

  // let query_string_parameters = formdata_to_query_string(formdata);
  let json_headers = new Headers();

  let fetch_args = {
    method: method,
    credentials: "include",
    headers: json_headers
  };
  if (method.toLowerCase() === "get") {
    json_headers.append("Content-Type", "application/json");

    let query_string_parameters = "";
    let formdata = object_to_formdata(args);
    if (typeof formdata.entries == "function") {
      console.log("pmk_fetch:  using formdata");
      query_string_parameters = formdata_to_query_string(formdata);
    } else {
      console.log("pmk_fetch: using args:");
      query_string_parameters = object_to_query_string(args);
    }

    url = url + ".json?" + query_string_parameters;
  } else {
    // if a non-json API:
    if (params.json === false) {
      fetch_args.body = object_to_formdata(args);
      //		implied:		json_headers.append("Content-Type", "multipart/form-data");
    } else {
      url = url + ".json";
      json_headers.append("Content-Type", "application/json");
      //formdata=new FormData();
      //formdata.append( "json", JSON.stringify( args ) );
      fetch_args.body = JSON.stringify(args, getCircularReplacer());
    }
  }
  return fetch(url, fetch_args).then(response => response.json());
};

const isNumeric = function(val) {
  return !isNaN(parseFloat(val));
};

const object_to_formdata = (object) => {
  Object.keys(object).reduce((formData, key) => {
    formData.append(key, object[key]);
    return formData;
  }, new FormData());
}

const formdata_to_query_string = function(form_data_obj = {}) {
  if (!form_data_obj) {
    return "";
  }
  return Array.from(form_data_obj.entries())
    .map(e => encodeURIComponent(e[0]) + "=" + encodeURIComponent(e[1]))
    .join("&");
};

const object_to_query_string = function(form_data_obj = {}) {
  if (!form_data_obj) {
    return ''
  }
  return Object.keys(form_data_obj)
    .reduce(function(a, k) {
      if (form_data_obj[k] !== undefined && form_data_obj[k] !== null) {
        if (typeof form_data_obj[k] === 'object') {
          form_data_obj[k].forEach((item) => {
            a.push(k + '[]=' + encodeURIComponent(item))
          })
        } else {
          a.push(k + '=' + encodeURIComponent(form_data_obj[k]))
        }
      }
      return a
    }, [])
    .join('&')
}

// -EXP
export default {
  pmk_fetch,

  fetchAccountProfile() {
    return pmk_fetch("account_get_current_profile", "GET", {}, {});
  },
  updateAccountProfile(data) {
    return pmk_fetch(
      "account_update_current_profile",
      "PUT",
      { user_id: data.user_id },
      data
    );
  },
  fetchAccountAuthenticationBarcode() {
    return pmk_fetch("account_get_user_authentication_barcode", "GET", {}, {});
  },
  fetchAuthenticationForBarcode(data) {
    return pmk_fetch(
      "account_get_user_authentication_from_barcode",
      "GET",
      {},
      data
    );
  },

  getLabConfig() {
    return pmk_fetch("electric_lab_get_lab_config", "GET", {}, {});
  },
  updateLabConfig(lab_config) {
    //console.log(lab_config)
    return pmk_fetch("electric_lab_update_lab_config", "PUT", {}, lab_config);
  },
  updateAuxiliaryLabels(labels) {
    return pmk_fetch(
      "electric_lab_update_auxiliary_labels",
      "POST",
      {},
      { auxiliary_labels: labels }
    );
  },
  // messages-notifications
  messageAck(data) {
    return pmk_fetch(
      "communications_internal_message_ack",
      "PUT",
      { message_identifier: data.message.identifier },
      data
    );
  },

  fetchMessages(data) {
    return pmk_fetch(
      "communications_internal_get_messages_current_user",
      "GET",
      {},
      data
    );
  },
  communications_internal_message_send_to_user_group(data) {
    return pmk_fetch(
      "communications_internal_message_send_to_user_group",
      "POST",
      { "user_type": data.user_type},
      data
    )
  },
  // tasks:
  getTasks(limit, offset) {
    return pmk_fetch(
      "tasks_get_all",
      "GET",
      {},
      { limit: limit, offset: offset, include_cancelled: 1 }
    );
    // return  pmk_fetch('task_state_status_report')
    //apiClient.get('/tasks?_limit=' + perPage + '&_page=' + page)
  },
  getTask(id) {
    return pmk_fetch("task_get", "GET", { id: id }, {});
  },
  // postTask(task) {
  //   return apiClient.post('/tasks', task)
  // },

  // ingredient
  createIngredient(data) {
    return pmk_fetch("electric_lab_ingredient_create", "POST", {}, data);
  },
  listIngredients(data) {
    return pmk_fetch("electric_lab_ingredient_list", "GET", {}, data);
  },
  receive_ingredient_inventory(data) {
    return pmk_fetch(
      "electric_lab_receive_ingredient_inventory",
      "PUT",
      { identifier: data.identifier },
      data
    );
  },
  inventoryLog(data) {
    return pmk_fetch(
      "electric_lab_ingredient_update_inventory",
      "POST",
      { ingredient_id: data.ingredient_identifier },
      data
    );
  },
  inventoryLogReduction(data) {
    return pmk_fetch(
      "electric_lab_manual_inventory_reduction",
      "POST",
      { identifier: data.identifier },
      data
    );
  },
  bulkInventoryLogReduction(data) {
    return pmk_fetch(
      "electric_lab_manual_inventory_reduction_list",
      "POST",
      { version: 'v5'},
      data
    );
  },
  inventoryAddNote(data){
    console.log(123,data)
    return pmk_fetch('electric_lab_lot_note_create', 'POST',
                      {version: 'v5', "identifier": data.identifier},
                      data)

  },
  inventoryGetNotes(data){
    return pmk_fetch('electric_lab_get_lot_notes', 'GET',
    {version: 'v5', "identifier": data})
  },
  // formula
  saveFormula(data) {
    return pmk_fetch(
      "electric_lab_formula_update",
      "PUT",
      { formula_id: data.identifier },
      data
    );
  },
  getFormula(id) {
    return pmk_fetch(
      "electric_lab_formula_get_by_id",
      "GET",
      { formula_id: id },
      { update_ingredients: 1 }
    );
  },

  searchFormulas(query_object) {
    return pmk_fetch("electric_lab_formula_search", "GET", {}, query_object);
  },

  // lot:

  recordLot(query_object) {
    console.log('recordLot', query_object);
    return pmk_fetch(
      "electric_lab_formula_update_inventory",
      "PUT",
      {
        lot_number: query_object.identifier,
        formula_id: query_object.formula.identifier
      },
      query_object
    );
  },

  // inventory
  getInventory(arg) {
    let id = ''
    let api_args
    if (typeof arg === "object") {
      id = arg.identifier
      api_args = arg.options || arg
    } else {
      id = arg
    }

    console.log({getInventory: arg})
    return pmk_fetch(
      "electric_lab_get_inventory_item",
      "GET",
      { identifier: id },
      api_args
    );
  },
  searchInventory(query_object) {
    return pmk_fetch("electric_lab_inventory_search", "GET", {}, query_object);
  },
  getInventoryNewLoss(arg){
    let id = ''
    let api_args
    if (typeof arg === "object") {
      id = arg.identifier
      api_args = arg.options || arg
    } else {
      id = arg
    }
    api_args.fields = ['identifier', 'data.inventory']
    return pmk_fetch(
      "electric_lab_get_inventory_item",
      "GET",
      { identifier: id },
      api_args
    );
  },

  // manufacturer:

  getManufacturerList() {
    return pmk_fetch("electric_lab_get_manufacturer_list", "GET", {});
  },
  getManufacturer() {
    return pmk_fetch("electric_lab_get_manufacturer", "GET", {});
  },
  saveManufacturer(query_object) {
    return pmk_fetch(
      "electric_lab_update_manufacturer",
      "PUT",
      { id: query_object.identifier },
      query_object
    );
  },

  // terminologies
  getRXNORM(data) {
    console.log(142, data);
    return pmk_fetch("terminologies_lookup_rxcui", "GET", {}, data);
  },
  getNDCOpenFDA(data) {
    return pmk_fetch("terminologies_lookup_ndc_openfda", "GET", {}, data);
  },
  getNDCProperties(data) {
    return pmk_fetch("terminologies_get_ndc_properties", "GET", {}, data);
  },

  fetchMedicationTerminologies(data) {
    return pmk_fetch(
      "terminologies_get_medication_attributes",
      "GET",
      {},
      data
    );
  },


  fetchCompoundTerminologies(data) {
    return pmk_fetch(
      "electric_lab_get_terminology",
      "GET",
      {},
      data
    );
  },

  request_additional_inventory(task_data) {
    return pmk_fetch(
      'electric_lab_formula_request_additional_inventory',
      'POST',
      { version: 'v5', identifier: task_data.formula_identifier },
      task_data
    ).then((data) => {
      // render_messages(data.data.messages)
      return data
    })
  },
  update_task_quantity(task_id, task_data) {
    return pmk_fetch(
      'task_update_data',
      'PUT',
      { version: 'v5', id: task_id },
      task_data
    ).then((data) => {
      // render_messages(data.data.messages)
      return data
    })
  },

  // pdf service

  getPDF(config) {
    console.log(config);
    let lab_config = store.getters["formula/get_lab_config"];
    console.log("lab_config", lab_config);
    let pdf_service = lab_config.pmk_config.pdf_service;
    let api_params = {
      ...{ fileName: pdf_service.api_options.fileName, inlinePdf: true },
      ...{ url: config.url },
      ...{ options: pdf_service.api_options.options }
    };
    console.log(
      "pdf_service.api_options.api_url",
      pdf_service.api_options.api_url
    );
    console.log("api_params", api_params);
    axios
      .post(pdf_service.api_options.api_url, api_params, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: pdf_service.api_key
        },
        url: pdf_service.api_options.api_url,
        data: api_params
      })
      .then(function(response) {
        console.log(response);
        if (config.open_window) {
          console.log(response.data.pdf);
          window.open(
            "/app/redirect.html?redirect_url=" +
              encodeURIComponent(response.data.pdf),
            "_blank"
          );
          // use a form qq:
          //document.getElementById('label_form').action = response.data.pdf
          //document.getElementById('label_form').submit()
        }
      })
      .catch(function(error) {
        console.log(error);
      });
  },
  // reports
  getReports(data) {
    return pmk_fetch(data.api, 'GET', data.params, data.args)


  },

  // security
  log_out() {
    return base_api_url.origin + "/logout";
  }
};
