<template>
  <div>
    <!-- <pre>{{manufacturer}}</pre> -->
    <el-col :span="6">
      {{manufacturer.name}} <br>
    </el-col>

    <el-col :span="18">
      <i class="fal fa-barcode-alt"></i>
      <ul>
        <li v-for="(identifier, index) in manufacturer.identifiers" :key="index">
          {{ identifier }}
          <span
            class="manufacturer-NDC"
            v-if="manufacturer.product_identifiers && manufacturer.product_identifiers[identifier]"
          >
            [{{manufacturer.product_identifiers[identifier].system }}: {{manufacturer.product_identifiers[identifier].code}}]
          </span>
          <span class="manufacturer-SKU"
            v-if="manufacturer.product_identifiers && manufacturer.product_identifiers[identifier]">
            SKU:
            <span v-if="manufacturer.product_identifiers[identifier].edit_SKU">
              <el-input v-model="manufacturer.product_identifiers[identifier].SKU" size="small" style="display:inline-block;width: 10em;margin:0px 5px;"></el-input>
              <el-button @click="toggle_edit_SKU(manufacturer.product_identifiers[identifier])" size="small">
                Save
              </el-button>
            </span>
            <span v-else>
              <span v-if="manufacturer.product_identifiers[identifier].SKU">
                {{manufacturer.product_identifiers[identifier].SKU}}
                <el-button type="text" @click="toggle_edit_SKU(manufacturer.product_identifiers[identifier])">
                  <i class="far fa-pen"></i>
                </el-button>
              </span>
              <span v-else>
                <el-button type="text" @click="toggle_edit_SKU(manufacturer.product_identifiers[identifier])">
                  <i class="far fa-plus"></i>
                </el-button>
              </span>
            </span>
          </span>

          &nbsp;&nbsp;
          <i
            class="fal fa-minus-circle clickable ingredient-remove"
            @click.once="remove_manufacturer_identifier(manufacturer, index)"
          ></i>
        </li>
      </ul>
      <div>
        <el-form :inline="true">
          <el-form-item label="Code:">
            <el-input
              placeholder="Add Product Code"
              v-model="form.new_identifier"
              @input="read_code"
              style="width: 16em;"
            ></el-input>
          </el-form-item>

          <el-form-item label="NDC/UPC:">
            <el-input
              :placeholder="`${form.product_identifier_system}: 512345678902`"
              v-model="form.product_identifier_code"
              :pattern="form.product_identifier_system == 'NDC' ? '\d{11,12}' : ''"
              @input="format_ndc_upc"
            >
              <el-select
                v-model="form.product_identifier_system"
                slot="prepend"
                placeholder="NDC/UPC"
                style="width: 80px;"
              >
                <el-option label="NDC" value="NDC"></el-option>
                <el-option label="UPC" value="UPC"></el-option>
                <el-option label="Other" value="Other"></el-option>
              </el-select>
              <template slot="append">
                <i @click="add_manufacturer_code(manufacturer)" class="fal fa-plus-circle"></i>
              </template>
            </el-input>
          </el-form-item>

          <el-form-item label="SKU:">
            <el-input
              placeholder="Add SKU"
              v-model="form.product_SKU"
              style="width: 16em;"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
    </el-col>
    <el-col :span="18" :offset="6" style="padding-bottom: 25px;">
      <span style="float: right">
        <BaseDocumentUpload
          label="Upload Document"
          :documents.sync="documents"
          :value="documents"
          :document_information="{ category: 'SDS'}"
          :categories="document_categories"
          @update_documents="updateDocuments"
        ></BaseDocumentUpload>
      </span>
      <documentsList :documents_s="manufacturer.documents" @deleteDoc="deleteDoc" />
    </el-col>
  </div>
</template>
<script>
import Common from '@/common.js'
import documentsList from '@/components/DocumentList.vue'
export default {
  components: {
    documentsList
  },
  props: {
    manufacturer: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      form: {
        product_identifier_code: '',
        product_identifier_system: 'NDC',
        product_SKU: '',
        scan: {},
      },
      document_categories: [
        {label:'SDS', value:'SDS'},
      ],
      documents: []
    }
  },
  methods: {
    toggle_edit_SKU(manufacturer) {
      if (manufacturer.edit_SKU) {
        manufacturer.edit_SKU = !manufacturer.edit_SKU
      } else {
        manufacturer.edit_SKU = true
      }
      this.$forceUpdate()
    },
    format_ndc_upc() {
      if (this.form.product_identifier_system == 'NDC') {
        this.form.product_identifier_code = this.form.product_identifier_code.replace(/\D/, '')
      }
    },
    read_code(code) {
      // console.log(code)
      let scan = Common.parse_scan(code)
      if (!scan.structured_code.empty) {
        this.form.scan = scan
        this.form.product_identifier_code = scan.structured_code.ndc
        this.form.new_identifier = scan.structured_code.product_code
      }
    },
    updateDocuments(_document) {
      console.log('uploaded document', _document)
      if (_document) {
        let to_append = {};
        to_append.name = _document.name;
        to_append.note = _document.note;
        to_append.type = _document.type;
        to_append.identifier = _document.identifier;
        let to_append_list = [];
        to_append_list.push(to_append);
        let this_document_category_list = {};
        this_document_category_list["category"] = _document.category;
        this_document_category_list["documents"] = to_append_list;
        this.documents.push(this_document_category_list)
      }
      this.documents = this.documents.filter(category => {
        return category.documents.length
      })
      this.manufacturer.documents = this.documents
    },
    deleteDoc(item, category) {
      this.documents.forEach(doc_category => {
        if (doc_category.category === category) {
          let index = doc_category.documents.indexOf(item)
          if (index >= 0) {
            doc_category.documents.splice(index, 1)
          }
        }
      })
    },
    add_manufacturer_code(manufacturer) {
      // not good either, but is like FHIR - TODO - HOWEVER: here it creates an array AND an object, so we can leave as-is for now
      //console.log('manufacturer: adding code')
      // console.log(manufacturer)

      if (this.form.new_identifier) {
        if (manufacturer.product_identifiers && manufacturer.product_identifiers[this.form.new_identifier]) {
          this.$message({
            message: 'Product code already set',
            type: 'error'
          })
          return
        }
        manufacturer.identifiers.push(this.form.new_identifier)
        if (!manufacturer.product_identifiers) {
          manufacturer.product_identifiers = {}
        }
        manufacturer.product_identifiers[this.form.new_identifier] = {
          system: this.form.product_identifier_system,
          code: this.form.product_identifier_code,
          SKU: this.form.product_SKU
        }
        this.form.new_identifier = ''
        this.form.product_identifier_code = ''
        this.form.product_SKU = ''
      }
    },
    remove_manufacturer_identifier(manufacturer, index) {
      // not good either, but is like FHIR - TODO - see comment above
      let removed_identifier = manufacturer.identifiers.splice(index, 1)
      // remove?
      Common.deleteObjectProperty(
        manufacturer.product_identifiers,
        removed_identifier
      )
      // delete manufacturer.product_identifiers[removed_identifier]
    }
  }
}
</script>
<style media="screen">
  .manufacturer-SKU {
    margin-left: 3em;
  }
</style>
