<template>
  <div style="margin-top: 20px;">
    <h4>
      <i style="margin-right: .5em" class="fal fa-file"></i>
      <span>Documents</span>
    </h4>
    <div v-for="(doc, index) in documents" :key="index" class>
      <div v-if="doc.documents && doc.documents.length > 0">
        <div class="sub-title">Category: {{ document_category_titles[doc.category] }}</div>
        <table class="styled striped" width="100%">
          <thead>
            <tr>
              <th width="20%">Name</th>
              <th width="70%">Note</th>
              <th width="10%" v-if="!hideDelete">Remove</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in doc.documents" :key="item.identifier">
              <td style="word-break: break-all;">
                <el-button type="text" href="#" @click="show_file(item.identifier)" v-if="item.type === 'image/heic'">
                  <i class="fa fa-file"></i> {{ item.name }}
                  <HEICDialog
                    :show="showFile[item.identifier]"
                    :file="item"
                    @closeDialog="close_file(item.identifier)"
                  />
                </el-button>
                <a
                  v-else
                  :href="get_document_url(item.identifier, {file_name: item.name, mime_type: item.mime_type || item.type})"
                  target="_blank">
                  <i class="fa fa-file"></i>
                  {{ item.name }}
                </a>

              </td>
              <td>{{ item.note }}</td>
              <!-- <td>{{ doc.category }}</td> -->
              <td v-if="!hideDelete">
                <el-button
                  plain
                  size="mini"
                  type="danger"
                  icon="el-icon-delete"
                  circle
                  @click="deleteDoc(doc.documents, item, doc.category)"
                ></el-button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import Common from "@/common.js"
import _ from 'lodash'
import HEICDialog from '@/components/HEICDialog.vue'
export default {
  components: {
    HEICDialog
  },
  props: {
    documents_s: {
      type: Array,
      default: []
    },
    hideDelete: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    documents() {
      let documents = [
        {
           category: "method_of_suitability",
           documents: []
        },
        {
           category: "patient_handouts",
           documents: []
        },
        {
           category: "sterility",
           documents: []
        },
        {
           category: "certificate_of_analysis",
           documents: []
        },
        {
           category: "C of A",
           documents: []
        },
        {
           category: "Purchase Order",
           documents: []
        },
        {
           category: "activity_factor",
           documents: []
        },
        {
           category: "pack_stat",
           documents: []
        },
        {
           category: "stability",
           documents: []
        },
        {
           category: "testing",
           documents: []
        },
        {
           category: "other",
           documents: []
        },
        {
           category: "SDS",
           documents: []
        },
      ]
      this.documents_s.forEach(category => {
        let category_index = _.findIndex(documents, function (o) { return o.category === category.category })
        if (category_index >= 0 && category.documents.length) {
          documents[category_index].documents.push(...category.documents)
        }
      })
      return documents
    }
  },
  emits: ['deleteDoc'],
  data() {
    return {
      showFile: {},
      document_category_titles: {
        method_of_suitability: "Method of suitability",
        patient_handoutss: "Patient Handouts",
        sterility: "Sterility",
        certificate_of_analysis: "C of A",
        "C of A": "C of A",
        "Purchase Order": "Purchase Order",
        "activity_factor": "Activity Factor",
        "pack_stat": "Pack Stat",
        stability: "Stability",
        testing: "Testing",
        other: "Other",
        "SDS": "SDS"
      },
    }
  },
  methods: {
    show_file(identifier) {
      this.showFile[identifier] = true
      this.$forceUpdate()
    },
    close_file(identifier) {
      this.showFile[identifier] = false
      this.$forceUpdate()
    },
    get_document_url(identifier, args={}) {
      return Common.get_document_url(identifier, args);
    },
    deleteDoc(documents, item, category) {
      let index = documents.indexOf(item)
      documents.splice(index, 1)
      this.$emit('deleteDoc', item, category)
    }
  }
}
</script>

<style lang="css" scoped>
</style>
