// see how task.data.subsequent_tasks works - it allows us to create a task upon completion of the next task we create:
// Example, create a task A and B;  When A completes, B is created...

const moment = require('moment')
import EventService from '@/services/EventService.js'
import db from '@/store/indexdb.js'

let handle_task_incoming_fax = function(document_data) {
  //create tasks:
  for (let new_task_data of document_data.tasks_to_create) {
    let new_task = new_task_data.form
    console.log(new_task)
    schedule_task(new_task)
  }

  for (const [tmp_page_id, association] of Object.entries(
    document_data.associate_documents
  )) {
    EventService.pmk_fetch(
      'faxes_associate_page_to_patient',
      'PUT',
      {
        version: 'v6',
        page_id: tmp_page_id,
        patient_id: association.patient_id
      },
      {
        title: association.page_title || `Fax`,
        description: association.description,
        category: association.category,
        document_info: association.document_info
      }
    ).then(function(data) {
      render_messages(data.messages)
    })
  }
  // mark task complete
  if (document_data.task_status == 'complete') {
    complete_task(document_data.task_id)
  }
}

let task_handlers = {
  // PA statuses:
  /*
                          verifying_benefits
                          prior_authorization_initiated
                          medication_approved
                          attempting_to_contact_patient
                          prescription_transferred
                      */

  prior_authorization_specialty_therapy: task_data => {
    let task_output = { tasks_to_create: [], notifications: [] }

    if (task_data.task.data.subsequent_tasks) {
      task_data.task.data.subsequent_tasks.forEach(function(new_task) {
        task_output.tasks_to_create.push(JSON.parse(new_task))
      })
      delete task_data.task.data.subsequent_tasks // it would interfere with creation of next tasks
    }
    if (task_data.current_state.schedule_pharmacist_medication_review) {
      let new_task_data = Object.assign(
        {},
        task_data.task.data,
        task_data.current_state
      )
      delete new_task_data.schedule_patient_call
      delete new_task_data.send_prescriber_notification

      task_output.tasks_to_create.push({
        task_type: 'prior_authorization_pharmacist_medication_review',
        task_status: 'new',
        task_data: new_task_data,
        data_id: task_data.task.data_id,
        patient_id: task_data.task.patient_id,
        prescriber_id: task_data.task.prescriber_id,
        task_state: {
          confirm_insurance_information:
            task_data.current_state.has_insurance_document
        },
        date_scheduled: moment()
          .add(0, 'days')
          .toJSON(),
        date_due: moment()
          .add(1, 'days')
          .toJSON()
      })
    }

    if (task_data.current_state.send_prescriber_notification) {
      task_output.notifications.push({
        contact_id: task_data.prescriber.contact_id,
        event_type: 'specialty_prescription_status_update',
        event_data: {
          comments:
            typeof task_data.current_state.prescriber_notification_comments !=
            'undefined'
              ? task_data.current_state.prescriber_notification_comments.map(
                  comment => {
                    return {
                      // TODO: change to global store:
                      // author: pmk.user_profile.display_name,
                      comment: comment
                    }
                  }
                )
              : [],
          status: 'attempting_to_contact_patient',
          task_id: task_data.task.id,
          rx_number: task_data.prescription.rx_number,
          prescriber_id: task_data.prescriber.id
        }
      })
    }

    return task_output
  },

  prior_authorization_new_patient: task_data => {
    let task_output = { tasks_to_create: [], notifications: [] }
    console.log(`<%= __FILE__ %>[<%= __LINE__ %>]:  `)
    console.log(task_data)
    if (task_data.task.data.subsequent_tasks) {
      task_data.task.data.subsequent_tasks.forEach(function(new_task) {
        task_output.tasks_to_create.push(JSON.parse(new_task))
      })
      delete task_data.task.data.subsequent_tasks // it would interfere with creation of next tasks
    }
    if (task_data.current_state.schedule_patient_call) {
      let new_task_data = Object.assign(
        {},
        task_data.task.data,
        task_data.current_state
      )
      delete new_task_data.schedule_patient_call
      delete new_task_data.send_prescriber_notification

      task_output.tasks_to_create.push({
        task_type: 'prior_authorization_call_new_patient', // make this a configuration option?
        task_status: 'new',
        task_data: new_task_data,
        data_id: task_data.task.data_id,
        patient_id: task_data.task.patient_id,
        prescriber_id: task_data.task.prescriber_id,
        task_state: {
          confirm_insurance_information:
            task_data.current_state.has_insurance_document
        },
        date_scheduled: moment()
          .add(0, 'days')
          .toJSON(),
        date_due: moment()
          .add(1, 'days')
          .toJSON()
      })
    }

    if (task_data.current_state.send_prescriber_notification) {
      task_output.notifications.push({
        contact_id: task_data.prescriber.contact_id,
        event_type: 'specialty_prescription_status_update',
        event_data: {
          comments:
            typeof task_data.current_state.prescriber_notification_comments !=
            'undefined'
              ? task_data.current_state.prescriber_notification_comments.map(
                  comment => {
                    return {
                      // TODO, use store :
                      //    author: pmk.user_profile.display_name,
                      comment: comment
                    }
                  }
                )
              : [],
          status: 'attempting_to_contact_patient',
          task_id: task_data.task.id,
          rx_number: task_data.prescription.rx_number,
          prescriber_id: task_data.prescriber.id
        }
      })
    }
    console.log(`<%= __FILE__ %>[<%= __LINE__ %>]:  `)
    console.log(task_output)

    return task_output
  },

  prior_authorization_call_new_patient: task_data => {
    let task_output = { tasks_to_create: [], notifications: [] }
    if (task_data.task.data.subsequent_tasks) {
      task_data.task.data.subsequent_tasks.forEach(function(new_task) {
        task_output.tasks_to_create.push(JSON.parse(new_task))
      })
      delete task_data.task.data.subsequent_tasks // it would interfere with creation of next tasks
    }
    if (task_data.current_state.schedule_pharmacist_review) {
      let new_task_data = Object.assign(
        {},
        task_data.task.data,
        task_data.current_state
      )
      delete new_task_data.schedule_pharmacist_review
      delete new_task_data.send_prescriber_notification

      task_output.tasks_to_create.push({
        task_type: 'prior_authorization_pharmacist_medication_review',
        task_status: 'new',
        task_data: new_task_data,
        data_id: task_data.task.data_id,
        patient_id: task_data.task.patient_id,
        prescriber_id: task_data.task.prescriber_id,
        date_scheduled: moment()
          .add(0, 'days')
          .toJSON(),
        date_due: moment()
          .add(4, 'hours')
          .toJSON()
      })
    }

    if (task_data.current_state.send_prescriber_notification) {
      task_output.notifications.push({
        contact_id: task_data.prescriber.contact_id,
        event_type: 'specialty_prescription_status_update',
        event_data: {
          comments:
            typeof task_data.current_state.prescriber_notification_comments !=
            'undefined'
              ? task_data.current_state.prescriber_notification_comments.map(
                  comment => {
                    return {
                      //TODO, use store
                      // author: pmk.user_profile.display_name,
                      comment: comment
                    }
                  }
                )
              : [],
          status: 'verifying_benefits',
          task_id: task_data.task.id,
          rx_number: task_data.prescription.rx_number,
          prescriber_id: task_data.prescriber.id
        }
      })
    }
    return task_output
  },

  prior_authorization_pharmacist_medication_review: task_data => {
    let task_output = { tasks_to_create: [], notifications: [] }
    if (task_data.task.data.subsequent_tasks) {
      task_data.task.data.subsequent_tasks.forEach(function(new_task) {
        task_output.tasks_to_create.push(JSON.parse(new_task))
      })
      delete task_data.task.data.subsequent_tasks // it would interfere with creation of next tasks
    }
    if (task_data.current_state.initiate_prior_authorization == 'initiate') {
      let new_task_data = Object.assign(
        {},
        task_data.task.data,
        task_data.current_state
      )
      delete new_task_data.send_prescriber_notification

      new_task_data.title = `${task_data.patient.first_name} ${
        task_data.patient.last_name
      } `
      new_task_data.sub_title = `${task_data.prescription.drug_description}`
      new_task_data.title_details = ''

      task_output.tasks_to_create.push({
        task_type: 'prior_authorization_initiate_prior_authorization',
        task_status: 'new',
        task_data: new_task_data,
        data_id: task_data.task.data_id,
        patient_id: task_data.task.patient_id,
        prescriber_id: task_data.task.prescriber_id,
        date_scheduled: moment()
          .add(0, 'days')
          .toJSON(),
        date_due: moment()
          .add(4, 'hours')
          .toJSON(),
        task_priority: 2
      })
    } else if (task_data.current_state.initiate_prior_authorization == 'hold') {
      let new_task_data = Object.assign(
        {},
        task_data.task.data,
        task_data.current_state
      )
      delete new_task_data.schedule_pharmacist_review
      delete new_task_data.send_prescriber_notification

      new_task_data.title = `${task_data.prescriber.name_with_credential}`
      new_task_data.sub_title = `${task_data.patient.first_name} ${
        task_data.patient.last_name
      } `
      new_task_data.title_details = `${task_data.prescription.drug_description}`

      task_output.tasks_to_create.push({
        task_type: 'contact_prescriber_for_medication_review',
        task_status: 'new',
        task_data: new_task_data,
        data_id: task_data.task.data_id,
        patient_id: task_data.task.patient_id,
        prescriber_id: task_data.task.prescriber_id,
        date_scheduled: moment()
          .add(0, 'days')
          .toJSON(),
        date_due: moment()
          .add(4, 'hours')
          .toJSON(),
        task_priority: 3
      })
    } else if (
      task_data.current_state.initiate_prior_authorization == 'deactivate'
    ) {
      // only notes are stored as task state
      // maybe they hook in here for something later
      // perhaps emit an event in the system -
      // we could even consider the medication status as deactivated
    }
    return task_output
  },

  prior_authorization_initiate_prior_authorization: task_data => {
    let task_output = { tasks_to_create: [], notifications: [] }
    if (
      task_data.current_state.send_prior_authorization_method == 'cancelled'
    ) {
      return task_output
    }
    if (task_data.task.data.subsequent_tasks) {
      task_data.task.data.subsequent_tasks.forEach(function(new_task) {
        task_output.tasks_to_create.push(JSON.parse(new_task))
      })
      delete task_data.task.data.subsequent_tasks // it would interfere with creation of next tasks
    }
    if (
      task_data.current_state.send_prior_authorization_method == 'send_via_fax'
    ) {
      console.warn('THIS IS A PLACE HOLDER TO SEND DOCUMENTS VIA FAX')
    } else if (
      task_data.current_state.send_prior_authorization_method ==
      'send_electronically'
    ) {
      console.warn(
        'THIS IS A PLACE HOLDER TO SEND DOCUMENTS VIA PORTAL OR NCPDP [still in the works...]'
      )
    }
    let tmp_set_new_task = true
    if (tmp_set_new_task) {
      let new_task_data = Object.assign(
        {},
        task_data.task.data,
        task_data.current_state
      )
      delete new_task_data.send_prescriber_notification

      new_task_data.title = `${task_data.patient.first_name} ${
        task_data.patient.last_name
      } `
      new_task_data.sub_title = `${task_data.prescription.drug_description}`
      new_task_data.title_details = ''

      task_output.tasks_to_create.push({
        task_type: 'prior_authorization_status_followup',
        task_status: 'new',
        task_data: new_task_data,
        data_id: task_data.task.data_id,
        patient_id: task_data.task.patient_id,
        prescriber_id: task_data.task.prescriber_id,
        date_scheduled: moment()
          .add(0, 'days')
          .toJSON(),
        date_due: moment()
          .add(4, 'hours')
          .toJSON(),
        task_priority: 0
      })
    }
    if (task_data.current_state.send_prescriber_notification) {
      task_output.notifications.push({
        contact_id: task_data.prescriber.contact_id,
        event_type: 'specialty_prescription_status_update',
        event_data: {
          comments:
            typeof task_data.current_state.prescriber_notification_comments !=
            'undefined'
              ? task_data.current_state.prescriber_notification_comments.map(
                  comment => {
                    return {
                      // TODO
                      // author: pmk.user_profile.display_name,
                      comment: comment
                    }
                  }
                )
              : [],
          status: 'prior_authorization_initiated',
          task_id: task_data.task.id,
          rx_number: task_data.prescription.rx_number,
          prescriber_id: task_data.prescriber.id
        }
      })
    }
    return task_output
  },

  prior_authorization_status_followup: task_data => {
    let task_output = { tasks_to_create: [], notifications: [] }
    if (task_data.task.data.subsequent_tasks) {
      task_data.task.data.subsequent_tasks.forEach(function(new_task) {
        task_output.tasks_to_create.push(JSON.parse(new_task))
      })
      delete task_data.task.data.subsequent_tasks // it would interfere with creation of next tasks
    }

    if (task_data.current_state.pa_status == 'Approved') {
      let new_task_data = Object.assign(
        {},
        task_data.task.data,
        task_data.current_state
      )

      new_task_data.title = `${task_data.patient.first_name} ${
        task_data.patient.last_name
      } `
      new_task_data.sub_title = `${task_data.prescription.drug_description}`
      new_task_data.title_details = `Rx# ${task_data.prescription.rx_number}`
      /* could add a fill/dispense task here */
      if (task_data.current_state.schedule_fulfillment_order) {
        new_task_data.task_note = task_data.current_state.fulfillment_order_note
        task_output.tasks_to_create.push({
          task_type:
            'fullfilment_order' /* spelling error through task system */,
          task_status: 'new',
          task_data: new_task_data,
          data_id: task_data.task.data_id,
          patient_id: task_data.task.patient_id,
          prescriber_id: task_data.task.prescriber_id,
          date_scheduled:
            moment(task_data.current_state.schedule_fulfillment_order_date[0])
              .add(0, 'days')
              .toJSON() ||
            moment()
              .add(0, 'days')
              .toJSON(),
          date_due:
            moment(task_data.current_state.schedule_fulfillment_order_date[1])
              .add(0, 'days')
              .toJSON() ||
            moment()
              .add(4, 'hours')
              .toJSON(),
          task_priority: 0
        })
      }
      if (task_data.current_state.schedule_patient_call) {
        new_task_data.task_note = task_data.current_state.patient_call_note
        task_output.tasks_to_create.push({
          task_type: 'call_patient',
          task_status: 'new',
          task_data: new_task_data,
          data_id: task_data.task.data_id,
          patient_id: task_data.task.patient_id,
          prescriber_id: task_data.task.prescriber_id,
          date_scheduled:
            moment(task_data.current_state.schedule_patient_call_date[0])
              .add(0, 'days')
              .toJSON() ||
            moment()
              .add(0, 'days')
              .toJSON(),
          date_due:
            moment(task_data.current_state.schedule_patient_call_date[1])
              .add(0, 'days')
              .toJSON() ||
            moment()
              .add(4, 'hours')
              .toJSON(),
          task_priority: 2
        })
      }

      // send a notification:
      task_output.notifications.push({
        contact_id: task_data.prescriber.contact_id,
        event_type: 'specialty_prescription_status_update',
        event_data: {
          comments:
            typeof task_data.current_state.prescriber_notification_comments !=
            'undefined'
              ? task_data.current_state.prescriber_notification_comments.map(
                  comment => {
                    return {
                      // TODO
                      // author: pmk.user_profile.display_name,
                      comment: comment
                    }
                  }
                )
              : [],
          status: 'medication_approved',
          task_id: task_data.task.id,
          rx_number: task_data.prescription.rx_number,
          prescriber_id: task_data.prescriber.id
        }
      })
      // create a prior auth via API
      EventService.pmk_fetch(
        'interface_prior_authorization_add',
        'POST',
        { patient_id: task_data.task.patient_id },
        {
          prescription: task_data.prescription,
          prior_authorization: {
            prior_authorization_id: task_data.current_state.pa_number,
            third_party_id: task_data.current_state.third_party_id,
            date_begin: null,
            date_expiration: task_data.current_state.expiration_date,
            status: 'active',
            notes: task_data.current_state.pa_note,
            lab_follow_up_date: task_data.current_state.lab_follow_up_date,
            schedule_assessment_date:
              task_data.current_state.schedule_assessment_date,
            requires_assessment: task_data.current_state.requires_assessment,
            requires_labs: task_data.current_state.requires_labs
          }
        }
      )
    } else if (task_data.current_state.pa_status == 'Denied') {
      if (task_data.current_state.send_prescriber_notification_for_denial) {
        task_output.notifications.push({
          contact_id: task_data.prescriber.contact_id,
          event_type: 'specialty_prescription_status_update',
          event_data: {
            comments:
              typeof task_data.current_state.prescriber_notification_comments !=
              'undefined'
                ? task_data.current_state.prescriber_notification_comments.map(
                    comment => {
                      return {
                        // TODO
                        //  author: pmk.user_profile.display_name,
                        comment: comment
                      }
                    }
                  )
                : [],
            status: 'medication_denied',
            task_id: task_data.task.id,
            rx_number: task_data.prescription.rx_number,
            prescriber_id: task_data.prescriber.id
          }
        })
      }
      /* create a follow up task to check on the status */
      let new_task_data = {}
      new_task_data.title = `${task_data.patient.first_name} ${
        task_data.patient.last_name
      } `
      new_task_data.sub_title = `${task_data.prescription.drug_description}`
      new_task_data.title_details = `Rx# ${task_data.prescription.rx_number}`
      new_task_data.task_note = task_data.current_state.pa_denied_note
      new_task_data.rx_number = task_data.prescription.rx_number

      task_output.tasks_to_create.push({
        task_type: 'prior_authorization_status_denial_follow_up',
        task_status: 'new',
        task_data: new_task_data,
        data_id: task_data.task.data_id,
        patient_id: task_data.task.patient_id,
        prescriber_id: task_data.task.prescriber_id,
        date_scheduled: moment()
          .add(1, 'days')
          .toJSON(),
        date_due: moment()
          .add(48, 'hours')
          .toJSON(),
        task_priority: 0
      })
    } else if (
      task_data.current_state.pa_status == 'Requires Additional Information'
    ) {
      let new_task_data = task_data.task.data
      //Object.create(Object.assign({}, task_data.task.data,  task_data.current_state));

      console.log(
        'task_note: ' + task_data.current_state.additional_information_note
      )
      console.log(new_task_data)
      new_task_data.title = `${task_data.patient.first_name} ${
        task_data.patient.last_name
      } `
      new_task_data.sub_title = `${task_data.prescription.drug_description}`
      new_task_data.title_details = `Rx# ${task_data.prescription.rx_number}`
      let subsequent_tasks = []
      subsequent_tasks.push(
        JSON.stringify({
          task_type: 'prior_authorization_status_followup',
          task_status: 'new',
          task_data: new_task_data,
          data_id: task_data.task.data_id,
          patient_id: task_data.task.patient_id,
          prescriber_id: task_data.task.prescriber_id,
          date_scheduled: moment()
            .add(0, 'days')
            .toJSON(),
          date_due: moment()
            .add(4, 'hours')
            .toJSON(),
          task_priority: 0
        })
      )
      console.log(subsequent_tasks)
      /*
                                                                  let subsequent_tasks_clean = Object.create(subsequent_tasks).__proto__;
                                                                  new_task_data.subsequent_tasks=subsequent_tasks_clean;
                                                                  new_task_data.task_note=task_data.current_state.additional_information_note;
                                                                  */
      console.log(new_task_data)
      console.log(
        'task_note  - later: ' +
          task_data.current_state.additional_information_note
      )
      let task_to_create_data = task_data.task.data
      task_to_create_data.task_note =
        task_data.current_state.additional_information_note
      task_to_create_data.subsequent_tasks = subsequent_tasks
      task_output.tasks_to_create.push({
        task_type: 'prior_authorization_status_additional_information_required',
        task_status: 'new',
        task_data: task_to_create_data,
        data_id: task_data.task.data_id,
        patient_id: task_data.task.patient_id,
        prescriber_id: task_data.task.prescriber_id,
        date_scheduled: moment()
          .add(0, 'days')
          .toJSON(),
        date_due: moment()
          .add(4, 'hours')
          .toJSON(),
        task_priority: 0
      })
    }
    return task_output
  },

  // --prior_authorization_status_followup

  prior_authorization_status_additional_information_required: task_data => {
    let task_output = { tasks_to_create: [], notifications: [] }
    if (task_data.task.data.subsequent_tasks) {
      task_data.task.data.subsequent_tasks.forEach(function(new_task) {
        task_output.tasks_to_create.push(JSON.parse(new_task))
      })
      delete task_data.task.data.subsequent_tasks // it would interfere with creation of next tasks
    }

    return task_output
  },

  prior_authorization_status_denial_follow_up: task_data => {
    let task_output = { tasks_to_create: [], notifications: [] }
    if (task_data.task.data.subsequent_tasks) {
      task_data.task.data.subsequent_tasks.forEach(function(new_task) {
        task_output.tasks_to_create.push(JSON.parse(new_task))
      })
      delete task_data.task.data.subsequent_tasks // it would interfere with creation of next tasks
    }

    return task_output
  },

  patient_assessment_required: task_data => {
    let task_output = { tasks_to_create: [], notifications: [] }
    if (task_data.task.data.subsequent_tasks) {
      task_data.task.data.subsequent_tasks.forEach(function(new_task) {
        task_output.tasks_to_create.push(JSON.parse(new_task))
      })
      delete task_data.task.data.subsequent_tasks // it would interfere with creation of next tasks
    }

    return task_output
  },
  patient_originated_authorization_transfer: task_data => {
    let task_output = { tasks_to_create: [], notifications: [] }
    if (task_data.task.data.subsequent_tasks) {
      task_data.task.data.subsequent_tasks.forEach(function(new_task) {
        task_output.tasks_to_create.push(JSON.parse(new_task))
      })
      delete task_data.task.data.subsequent_tasks // it would interfere with creation of next tasks
    }

    return task_output
  },

  'SMS-IN': task_data => {
    let task_output = { tasks_to_create: [], notifications: [] }
    if (task_data.task.data.subsequent_tasks) {
      task_data.task.data.subsequent_tasks.forEach(function(new_task) {
        task_output.tasks_to_create.push(JSON.parse(new_task))
      })
      delete task_data.task.data.subsequent_tasks // it would interfere with creation of next tasks
    }

    if (task_data.current_state.response_message) {
      console.log('sending message')
      let task_patient_id = null
      let referenced_task = get_task_by_id(task_data.task.id)
      if (parseInt(task_data.patient.patient_id)) {
        task_patient_id = parseInt(task_data.patient.patient_id)
      } else if (referenced_task) {
        task_patient_id = referenced_task.patient_id
      }

      if (task_patient_id) {
        subscribe_to_patient_sms(task_patient_id)
        // re-factor, TODO:
        // $('body').trigger('send_patient_sms', {
        //   patient_id: task_patient_id,
        //   task_type: 'SMS-IN'
        // })
      }

      //				$.post( "https://psol.pharmetika.com/kazoo/sms/send", {message:  task_data.message, to_did: task_data.to_did, from_did: task_data.from_did},  	function( data ) {	render_messages(data.messages);		});

      EventService.pmk_fetch(
        'send_sms',
        'POST',
        { version: 'v5' },
        {
          message: task_data.current_state.response_message,
          to_did: task_data.sms.from_did,
          from_did: task_data.sms.to_did,
          patient_id: task_patient_id
        }
      ).then(function(data) {
        render_messages(data.messages)
      })
    }
    return task_output
  },

  default: task_data => {
    let task_output = { tasks_to_create: [], notifications: [] }
    if (task_data.task.data.subsequent_tasks) {
      task_data.task.data.subsequent_tasks.forEach(function(new_task) {
        task_output.tasks_to_create.push(JSON.parse(new_task))
      })
      delete task_data.task.data.subsequent_tasks // it would interfere with creation of next tasks
    }

    return task_output
  }
}

let update_task_status = function(task_object) {
  const param_id = task_object.id
  return EventService.pmk_fetch(
    'task_update',
    'PUT',
    { version: 'v5', id: param_id || task_object.task_id },
    task_object
  ).then(function(data) {
    render_messages(data.messages)
  })
}
let schedule_task = function(task_object) {
  /*
                                    task_type: 'call_prescriber_for_refill',
                                    task_status: 'new',
                                    task_data: pmk["_tmp"]['diplayed_task_details_data'],
                                    data_id: req_data.rxn,
                                    date_scheduled: moment().add(1, 'days'),
                                    date_due: moment().add(2, 'days')
                        */
  return EventService.pmk_fetch(
    'tasks_add',
    'PUT',
    { version: 'v5', id: (task_object.task_id || task_object.id) },
    task_object
  ).then(function(data) {
    render_messages(data.messages)
  })
}

let schedule_subsequent_formula_task = function(task_object) {
  /*
                                    task_type: 'call_prescriber_for_refill',
                                    task_status: 'new',
                                    task_data: pmk["_tmp"]['diplayed_task_details_data'],
                                    data_id: req_data.rxn,
                                    date_scheduled: moment().add(1, 'days'),
                                    date_due: moment().add(2, 'days')
                        */
  return EventService.pmk_fetch(
    'electric_lab_formula_update_with_subsequent_task',
    'PUT',
    { version: 'v5', formula_id: task_object.formula_identifier },
    task_object
  ).then(function(data) {
    render_messages(data.messages)
  })
}

let render_messages = function(notification) {
  let needs_refactor = true
  if (needs_refactor) {
    console.warn('TODO: DEFINE SOMETHING TO DISPATCH')
  } else {
    EventService.dispatch('notification/add', notification, { root: true })
  }
}

let complete_task = function(task_id) {
  return EventService.pmk_fetch(
    'task_update',
    'POST',
    { task_id: task_id, id: task_id },
    { task_status: 'complete' }
  ).then(function(data) {
    render_messages(data.messages)
    return data
  })
}

let subscribe_to_patient_sms = function(data) {
  console.warn('PLACEHOLDER FOR subscribe_to_patient_sms')
  console.log(data)
}

let get_task_by_id = function(task_id) {
  // TODO, not sure this will work
  const result = db.tasks.where('id').equals(task_id)
  return result
}
export default {
  schedule_task,
  schedule_subsequent_formula_task,
  update_task_status,
  complete_task,
  task_handlers,
  render_messages,
  // unneeded qq
  handle_task_incoming_fax
}
