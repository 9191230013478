<template>
  <div>
    <el-row style="margin-bottom: 1.5em;">
      <el-col>
        <div style="margin-bottom: 1.5em;">
          <el-input
            class="inline-input"
            style="max-width: calc(100% - 350px)"
            placeholder="Filter"
            v-model="form.filter_query"
            @input="filter_list"
            size="small">
            <template slot="prepend"><i class="fal fa-search" aria-hidden="true"></i></template>
          </el-input>
          <el-checkbox v-model="form.show_archived" style="float: right; padding: 7px 5px">
            Show Archived
          </el-checkbox>
          <el-button
            type="default"
            class="status-button"
            style="float: right; padding: 7px 5px"
            @click="add_equipment()"
          >
            <i class="fal fa-plus"></i> Add New
          </el-button>
          <el-button
            type="default"
            class="status-button"
            style="float: right; padding: 7px 5px;margin-right: 5px"
            @click="download_csv()"
          >
            <i class="far fa-list-alt"></i> Download List
          </el-button>
        </div>
      </el-col>
    </el-row>
    <div>
      <el-card
        class="box-card"
        v-for="[equipment_identifier, equipment] of Object.entries(filtered_equipment_list)
          .sort((a, b) => {
            if(a[1].archived && !b[1].archived) {
              return -1
            }
            if(!a[1].archived && b[1].archived) {
              return 1
            }
            return 0
          })
          .filter( entry =>
            (!entry[1].archived || form.show_archived)
          )"
        :key="equipment_identifier"
        style="margin-bottom: 1.5em;"
        :ref="`equipment_entry-${equipment_identifier}`"
      >
        <div slot="header" class="clearfix">
          <span>
            <span style="font-size: 1.5em">Equipment: {{ equipment.name || equipment.description}}</span>
            &nbsp;
            <span class="text-secondary">
              <el-button
                type="text"
                @click="toggle_collapse(equipment_identifier)">
                <span v-show="equipment.collapsed"><i class="far fa-chevron-double-down"></i></span>
                <span v-show="!equipment.collapsed"><i class="far fa-chevron-double-up"></i></span>
              </el-button>
            </span>
          </span>
          <el-button
            v-if="!equipment.archived"
            plain
            type="danger"
            class="status-button"
            style="float: right; padding: 5px; margin: 1em;"
            @click="removeEquipment(equipment)"
          >
            <i class="fas fa-minus-circle"></i> Remove
          </el-button>
          <span v-else>
            <el-button
              plain
              type="text"
              style="float: right; padding: 5px; margin: 1em;color:#1f2d3d"
            >
              <i class="fas fa-archive"></i> Archived
            </el-button>&nbsp;
            <el-button
              style="float: right; padding: 5px; margin: 1em;color:#1f2d3d"
              @click="reactivateEquipment(equipment)"
            >
              Reactivate
            </el-button>&nbsp;
          </span>
          <el-button
            v-if="!equipment.archived"
            plain
            type="info"
            class="status-button"
            style="float: right; padding: 5px; margin: 1em;"
            @click="openLabel(equipment)"
          >
            <i class="fas fa-tag"></i> Label
          </el-button>&nbsp;
          <el-button
            type="primary"
            class="status-button"
            style="float: right; padding: 5px; margin: 1em;"
            @click="saveEquipment(equipment)"
          >
            <i class="fal fa-save"></i> Save
          </el-button>
        </div>
        <!--
        <pre>{{ equipment_identifier }}:
          {{ equipment }}
        </pre>
        -->
        <div v-if="equipment_list && !equipment.collapsed">
          <el-row :gutter="10" class="property-row">
            <el-col :span="4">
              <span>
                <span style="font-size: 1.5em">Description:</span>
                &nbsp;
                <span class="text-secondary"></span>
              </span>
            </el-col>
            <el-col :span="8">
              <el-input v-model="equipment.name"></el-input>
            </el-col>
            <el-col :span="2"></el-col>
            <el-col :span="2"></el-col>
          </el-row>

          <el-row :gutter="10" class="property-row">
            <el-col :span="4">
              <span>
                <span style="font-size: 1.5em">Location:</span>
                &nbsp;
                <span class="text-secondary"></span>
              </span>
            </el-col>
            <el-col :span="8">
              <el-input v-model="equipment.location"></el-input>
            </el-col>
            <el-col :span="2"></el-col>
            <el-col :span="2"></el-col>
          </el-row>

          <el-row :gutter="10" class="property-row">
            <el-col :span="4">
              <span>
                <span style="font-size: 1.5em">Equipment Type:</span>
                &nbsp;
                <span class="text-secondary"></span>
              </span>
            </el-col>
            <el-col :span="8">
              <el-select v-model="equipment.equipment_type" filterable placeholder="Select">
                <el-option
                  v-for="item in equipment_types"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-col>
            <el-col :span="2"></el-col>
            <el-col :span="2"></el-col>
          </el-row>

          <el-row
            :gutter="10"
            class="property-row"
            v-if="equipment.equipment_type && equipment.equipment_type.toLowerCase()=='balance'"
          >
            <el-col :span="4">
              <span>
                <span style="font-size: 1.5em">Output Format:</span>
                &nbsp;
                <span class="text-secondary"></span>
              </span>
            </el-col>
            <el-col :span="6">
              <el-select v-model="equipment.parser" filterable placeholder="Select">
                <el-option
                  v-for="item in equipment_parsers"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-col>
            <el-col :span="2"></el-col>
            <el-col :span="2"></el-col>
          </el-row>
        <!-- equipment_type options -->
          <el-row
            :gutter="20"
            class="property-row"
            v-if="equipment.equipment_type && equipment.equipment_type.toLowerCase()=='balance'"
          >
            <el-col :span="4">
              <span>
                <span style="font-size: 1.5em">Variance [%]:</span>
                &nbsp;
                <span class="text-secondary"></span>
              </span>
            </el-col>
            <el-col :span="16">
              <div>
                    <el-slider
                      v-model="equipment.balance_variance_percentage"
                      :step="parseFloat(0.5)" :min="parseFloat(0.0)" :max="parseFloat(5.0)"
                      show-input>
                    </el-slider>
              </div>
            </el-col>
            <el-col :span="2"></el-col>
            <el-col :span="2"></el-col>
          </el-row>
          <el-row
            :gutter="20"
            class="property-row"
            v-if="equipment.equipment_type && equipment.equipment_type.toLowerCase()=='balance'"
          >
            <el-col :span="4">
              <span>
                <span style="font-size: 1.25em">Minimum Weighable Quantity <span style="font-size: 0.8em; color: gray;">(in grams)</span>:</span>
                &nbsp;
                <span class="text-secondary"></span>
              </span>
            </el-col>
            <el-col :span="4">
              <div>
                <el-input
                  v-model="equipment.minimum_weighable_quantity"
                  placeholder="0.02 (grams)"
                  >
                </el-input>

              </div>
            </el-col>
            <el-col :span="2"></el-col>
            <el-col :span="2"></el-col>
          </el-row>
          <el-row
            :gutter="20"
            class="property-row"
            v-if="equipment.equipment_type && equipment.equipment_type.toLowerCase()=='balance'"
          >
            <el-col :span="4">
              <span>
                <span style="font-size: 1.5em">Calibration Date:</span>
                &nbsp;
                <span class="text-secondary"></span>
              </span>
            </el-col>
            <el-col :span="16">
              <div>
                <el-date-picker
                  v-model="equipment.date_calibration"
                  type="date"
                  placeholder="Date Calibrated"
                  format="yyyy-MM-dd">
                </el-date-picker>
              </div>
            </el-col>
            <el-col :span="2"></el-col>
            <el-col :span="2"></el-col>
          </el-row>
          <el-row
            :gutter="20"
            class="property-row"
            v-if="equipment.equipment_type && ['balance', 'printer'].includes(equipment.equipment_type.toLowerCase())"
          >
            <el-col :span="4">
              <span>
                <span style="font-size: 1.5em">Transmitter ID:</span>
                &nbsp;
                <span class="text-secondary"></span>
              </span>
            </el-col>
            <el-col :span="16">
              <div>
                <el-input
                  v-model="equipment.transmitter_device_identifier"
                  placeholder="Scan Transmitter Device"
                  @change="equipment.transmitter_device_identifier = equipment.transmitter_device_identifier.trim()"
                  >
                </el-input>
              </div>
            </el-col>
            <el-col :span="2"></el-col>
            <el-col :span="2"></el-col>
          </el-row>

          <el-row
            :gutter="20"
            class="property-row"
            v-if="equipment.equipment_type && 'printer' === equipment.equipment_type.toLowerCase()"
          >
            <el-col :span="4">
              <span>
                <span style="font-size: 1.5em">Printer Type:</span>
                &nbsp;
                <span class="text-secondary"></span>
              </span>
            </el-col>
            <el-col :span="16">
              <div>
                <el-select v-model="equipment.printer_type" placeholder="Select Type">
                  <el-option
                    v-for="item in printer_types"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="2"></el-col>
            <el-col :span="2"></el-col>
          </el-row>

          <el-row
            :gutter="20"
            class="property-row"
            v-if="equipment.equipment_type && 'printer' === equipment.equipment_type.toLowerCase()"
          >
            <el-col :span="4">
              <span>
                <span style="font-size: 1.5em">Label Size:</span>
                &nbsp;
                <span class="text-secondary"></span>
              </span>
            </el-col>
            <el-col :span="16">
              <div>
                <el-select v-model="equipment.label_size" placeholder="Select Type">
                  <el-option
                    v-for="item in label_sizes.filter(obj => !obj.brother)"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="2"></el-col>
            <el-col :span="2"></el-col>
          </el-row>

          <el-row
            :gutter="20"
            class="property-row"
            v-if="equipment.equipment_type && 'printer' === equipment.equipment_type.toLowerCase() && equipment.printer_type === 'brother'"
          >
            <el-col :span="4">
              <span>
                <span style="font-size: 1.5em">Paper Size:</span>
                &nbsp;
                <span class="text-secondary"></span>
              </span>
            </el-col>
            <el-col :span="16">
              <div>
                <el-select v-model="equipment.brother_paper_size" placeholder="Select Type">
                  <el-option
                    v-for="item in label_sizes.filter(obj => obj.brother)"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="2"></el-col>
            <el-col :span="2"></el-col>
          </el-row>

          <el-row
            :gutter="20"
            class="property-row"
            v-if="equipment.equipment_type && 'printer' === equipment.equipment_type.toLowerCase() && equipment.printer_type !== 'brother'"
          >
            <el-col :span="4">
              <span>
                <span style="font-size: 1.5em">Printer DPI:</span>
                &nbsp;
                <span class="text-secondary"></span>
              </span>
            </el-col>
            <el-col :span="16">
              <div>
                <el-select v-model="equipment.printer_dpi" placeholder="Select DPI">
                  <el-option
                    label="203 DPI"
                    value="203">
                  </el-option>
                  <el-option
                    label="300 DPI"
                    value="300">
                  </el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="2"></el-col>
            <el-col :span="2"></el-col>
          </el-row>

          <el-row
            :gutter="20"
            class="property-row"
            v-if="equipment.equipment_type && 'printer' === equipment.equipment_type.toLowerCase() && equipment.printer_type === 'brother'"
          >
            <el-col :span="4">
              <span>
                <span style="font-size: 1.5em">Red/Black Paper?</span>
                &nbsp;
                <span class="text-secondary"></span>
              </span>
            </el-col>
            <el-col :span="16">
              <div>
                <el-checkbox v-model="equipment.brother_red"></el-checkbox>
              </div>
            </el-col>
            <el-col :span="2"></el-col>
            <el-col :span="2"></el-col>
          </el-row>

          <el-row
            :gutter="20"
            class="property-row"
            v-if="equipment.equipment_type && 'printer' === equipment.equipment_type.toLowerCase() && equipment.printer_type === 'brother'"
          >
            <el-col :span="4">
              <span>
                <span style="font-size: 1.5em">
                  Fast Printing
                  <el-tooltip class="item" effect="dark" content="This will print labels in 'low quality', which will increase the printer speed" placement="top">
                    <el-button type="text"><i class="fal fa-info-circle"></i></el-button>
                  </el-tooltip>
                </span>
                &nbsp;
                <span class="text-secondary"></span>
              </span>
            </el-col>
            <el-col :span="16">
              <div>
                <el-checkbox v-model="equipment.brother_lq"></el-checkbox>
              </div>
            </el-col>
            <el-col :span="2"></el-col>
            <el-col :span="2"></el-col>
          </el-row>

          <el-row
            :gutter="20"
            class="property-row"
            v-else-if="equipment.equipment_type && equipment.equipment_type.toLowerCase()=='device'"
          >
            <el-col :span="4">
              <span>
                <span style="font-size: 1.5em">iCloud/FaceTime Account:</span>
                &nbsp;
                <span class="text-secondary"></span>
              </span>
            </el-col>
            <el-col :span="16">
              <div>
                    <el-input
                      v-model="equipment.icloud_account"
                      >
                      <i slot="suffix" class="el-input__icon fab fa-apple"></i>
                    </el-input>
              </div>
            </el-col>
            <el-col :span="2"></el-col>
            <el-col :span="2"></el-col>
          </el-row>

          <el-row
            :gutter="20"
            class="property-row"
            v-if="equipment.equipment_type && equipment.equipment_type.toLowerCase()=='station'"
          >
            <el-col :span="4">
              <span>
                <span style="font-size: 1.5em">Station Name:</span>
                &nbsp;
                <span class="text-secondary"></span>
              </span>
            </el-col>
            <el-col :span="12">
              <div>
                    <el-input
                      v-model="equipment.station_name"
                      >
                    </el-input>
              </div>
            </el-col>
          </el-row>
          <el-row
            :gutter="20"
            class="property-row"
            v-if="equipment.equipment_type && equipment.equipment_type.toLowerCase()=='station'"
          >
            <el-col :span="4">
              <span>
                <span style="font-size: 1.5em">Station Type:</span>
                &nbsp;
                <span class="text-secondary"></span>
              </span>
            </el-col>
            <el-col :span="6">
              <div>
                <el-select v-model="equipment.station_type" placeholder="Station Type">
                    <el-option
                      v-for="item in station_type_options"
                      :key="item.value"
                      :label="item.description"
                      :value="item.value">
                    </el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="4"><el-checkbox label="Hazardous" v-model="equipment.station_is_hazardous_environment"></el-checkbox></el-col>
            <el-col :span="4"><el-checkbox label="Sterile" v-model="equipment.station_is_sterile_environment"></el-checkbox></el-col>
          </el-row>

          <el-row
            :gutter="20"
            class="property-row"
            v-if="equipment.equipment_type && equipment.equipment_type.toLowerCase()=='freezer'"
          >
            <el-col :span="4">
              <span>
                <span style="font-size: 1.5em">Safe Temperature Range:</span>
                &nbsp;
                <span class="text-secondary"></span>
              </span>
            </el-col>
            <el-col :span="8">
              <div>
                    <el-input
                      v-model="equipment.temperature_range_low"
                      placeholder="Low in degrees C, e.g. -17"
                      >
                    </el-input>
              </div>
            </el-col>
            <el-col :span="8">
              <div>
                    <el-input
                      v-model="equipment.temperature_range_high"
                      placeholder="High in degrees C, e.g. -4"
                      >
                    </el-input>
              </div>

            </el-col>
            <el-col :span="2"></el-col>
          </el-row>

<!-- /end equipment_type options -->

          <el-row :gutter="10" class="property-row" v-if="!!(equipment.equipment_type &&  equipment_types_by_key[equipment.equipment_type] && equipment_types_by_key[equipment.equipment_type].requires_network_address)">
            <el-col :span="4">
              <span>
                <span style="font-size: 1.5em">Host/IP Address:</span>
                &nbsp;
                <span class="text-secondary"></span>
              </span>
            </el-col>
            <el-col :span="4">
              <el-input v-model="equipment.ip_addrss"></el-input>
            </el-col>
            <el-col :span="2"></el-col>
            <el-col :span="2"></el-col>
          </el-row>

          <el-row :gutter="10" class="property-row"  v-if="!!( equipment.equipment_type  &&  equipment_types_by_key[equipment.equipment_type] && equipment_types_by_key[equipment.equipment_type].requires_network_port)">
            <el-col :span="4">
              <span>
                <span style="font-size: 1.5em">Port:</span>
                &nbsp;
                <span class="text-secondary"></span>
              </span>
            </el-col>
            <el-col :span="4">
              <el-input v-model="equipment.port"></el-input>
            </el-col>
            <el-col :span="2"></el-col>
            <el-col :span="2"></el-col>
          </el-row>

          <el-row :gutter="10" class="property-row" v-if="!!(equipment.equipment_type  &&  equipment_types_by_key[equipment.equipment_type] && equipment_types_by_key[equipment.equipment_type].requires_authentication)">
            <el-col :span="4">
              <span>
                <span style="font-size: 1.5em">Username:</span>
                &nbsp;
                <span class="text-secondary"></span>
              </span>
            </el-col>
            <el-col :span="4">
              <el-input v-model="equipment.username"></el-input>
            </el-col>
            <el-col :span="2"></el-col>
            <el-col :span="2"></el-col>
          </el-row>

          <el-row :gutter="10" class="property-row" v-if="!!( equipment.equipment_type  &&  equipment_types_by_key[equipment.equipment_type] && equipment_types_by_key[equipment.equipment_type].requires_authentication)">
            <el-col :span="4">
              <span>
                <span style="font-size: 1.5em">Password:</span>
                &nbsp;
                <span class="text-secondary"></span>
              </span>
            </el-col>
            <el-col :span="4">
              <el-input v-model="equipment.password" show-password></el-input>
            </el-col>
            <el-col :span="2"></el-col>
            <el-col :span="2"></el-col>
          </el-row>
        </div>
      </el-card>
    </div>
  </div>
</template>
<script>
import Common from "@/common.js";
import url_for from '@/services/pmk_url_for.js'
import _ from 'lodash'
const arrayToObject = (array, keyField) =>
   array.reduce((obj, item) => {
     obj[item[keyField]] = item
     return obj
   }, {})

const equipment_types = [
        {
          value: "balance",
          label: "Balance",
          requires_network_port: true
        },

        {
          value: "printer",
          label: "Printer"
        },

        {
          value: "device",
          label: "Device/iPad"
        },

        {
          value: "station",
          label: "Station"
        },

        {
          value: "Freezer",
          label: "Freezer"
        },
        {
          value: "Refrigerator",
          label: "Refrigerator"
        },
        {
          value: "Hot Plate",
          label: "Hot Plate"
        },
        {
          value: "Hand Mixer",
          label: "Hand Mixer"
        },
        {
          value: "Blender",
          label: "Blender"
        },
        {
          value: "Capsule Machine",
          label: "Capsule Machine"
        },
        {
          value: "Bowl Mixer",
          label: "Bowl Mixer"
        },
        {
          value: "Speed Mixer",
          label: "Speed Mixer"
        },
        {
          value: "Unguator",
          label: "Unguator"
        },
        {
          value: "Powder Mixer",
          label: "Powder Mixer"
        },
        {
          value: "PH Meter",
          label: "PH Meter"
        },
        {
          value: "Homogenizer",
          label: "Homogenizer"
        },
        {
          value: "Other",
          label: "Other"
        }
      ]
const equipment_types_by_key  = arrayToObject(equipment_types,  'value')
export default {
  // props: {
  //   equipments: {
  //     type: Object,
  //     required: true
  //     // default: function() {
  //     //   let tmp_equipments = {
  //     //     Medisca: {
  //     //       account: 'account',
  //     //       phone: '1234567890',
  //     //       name: 'Medisca'
  //     //     }
  //     //   }
  //     //   return tmp_equipments
  //     // }
  //   }
  // },
  data() {
    return {
      //      equipment: {},
      form: {
        value: 0,
        filter_query: '',
        show_archived: false
      },
      equipment_parsers: [
        {
          value: "auto_json",
          label: "Auto"
        },
        /*
        {
          value: "ohaus_adventurer",
          label: "Ohaus"
        },
        {
          value: "mettler",
          label: "Mettler"
        },
        {
          value: "accuris",
          label: "Accuris"
        },
        {
          value: "cole_parmer",
          label: "Cole-Parmer"
        },
        {
          value: "and",
          label: "AND"
        },
        {
          value: "other",
          label: "Other"
        }
        */
      ],
      equipment_types,
      equipment_types_by_key,
      station_type_options_orig: [
        {
          "description": 'Capsule Station',
          "value": "capsule_station"
        },
        {
          "description": 'Creams Station',
          "value": "cream_station"
        },
        {
          "description": 'Suspension Station',
          "value": "suspension_station"
        },
        {
          "description": 'Bulk Station',
          "value": "bulk_station"
        },
        {
          "description": 'Bases Station',
          "value": "bases_station"
        },
        {
          "description": 'Other',
          "value": "other_station"
        },
      ],
      filtered_equipment_list: [],
      printer_types: [
        {
          value: 'zpl',
          label: 'Zebra/ZPL'
        },
        {
          value: 'brother',
          label: 'Brother'
        },
        {
          value: 'other',
          label: 'Other'
        },
      ],
      label_sizes: [
        {
          value: '38x90',
          label: '38mm x 90mm',
          brother: false,
        },
        {
          value: '4_000x2_000',
          label: '4in x 2in',
          brother: false,
        },
        {
          value: '2_000x2_725',
          label: '2in x 2.725in',
          brother: false,
        },
        {
          value: '12',
          label: '12',
          brother: true,
        },
        {
          value: '29',
          label: '29',
          brother: true,
        },
        {
          value: '38',
          label: '38',
          brother: true,
        },
        {
          value: '50',
          label: '50',
          brother: true,
        },
        {
          value: '54',
          label: '54',
          brother: true,
        },
        {
          value: '62',
          label: '62',
          brother: true,
        },
        {
          value: '102',
          label: '102',
          brother: true,
        },
        {
          value: '17x54',
          label: '17x54',
          brother: true,
        },
        {
          value: '17x87',
          label: '17x87',
          brother: true,
        },
        {
          value: '23x23',
          label: '23x23',
          brother: true,
        },
        {
          value: '29x42',
          label: '29x42',
          brother: true,
        },
        {
          value: '29x90',
          label: '29x90',
          brother: true,
        },
        {
          value: '39x90',
          label: '39x90',
          brother: true,
        },
        {
          value: '39x48',
          label: '39x48',
          brother: true,
        },
        {
          value: '52x29',
          label: '52x29',
          brother: true,
        },
        {
          value: '62x29',
          label: '62x29',
          brother: true,
        },
        {
          value: '62x100',
          label: '62x100',
          brother: true,
        },
        {
          value: '102x51',
          label: '102x51',
          brother: true,
        },
        {
          value: '102x152',
          label: '102x152',
          brother: true,
        },
        {
          value: 'd12',
          label: 'd12',
          brother: true,
        },
        {
          value: 'd24',
          label: 'd24',
          brother: true,
        },
        {
          value: 'd58',
          label: 'd58',
          brother: true,
        },
      ]
    };
  },
  computed: {
    equipment_list: {
      get() {
        return this.$store.getters["equipment/get_equipment_list"];
      },
      set() {
        return this.$store.dispatch("equipment/set_equipment");
      }
    },
    station_type_options() {
      const station_types = this.$store.getters['formula/get_station_types']
      return Object.values(station_types)
    }
  },
  methods: {
    toggle_collapse(identifier) {
      console.log('should toggle coll for ', this.filtered_equipment_list[identifier])
      this.filtered_equipment_list[identifier].collapsed = !this.filtered_equipment_list[identifier].collapsed
      this.$forceUpdate()
    },
    filter_list() {
      if (this.form.filter_query) {
        this.filtered_equipment_list = Object.fromEntries(Object.entries(this.equipment_list).filter(entry =>
          entry[1].name.toLowerCase().includes(this.form.filter_query.toLowerCase()) ||
          entry[1].port.toString().includes(this.form.filter_query) ||
          entry[1].transmitter_device_identifier.includes(this.form.filter_query.toLowerCase())
        ))
      } else {
        this.filtered_equipment_list = this.equipment_list
      }
      console.log('this.filtered_equipment_list', this.filtered_equipment_list)
    },
    add_equipment() {
      let self = this
      this.$store.dispatch("equipment/addEquipment").then( new_equipment_object => {
        this.filter_list()
        window.setTimeout(function () {
          let scroller = document.querySelector('#scroll-top')
          scroller.scrollTo(0, scroller.scrollHeight)
        }, 25)
        // replaced - this no longer works with the filtered equipment list
        /*
        let new_el = self.$refs["equipment_entry-" + new_equipment_object.identifier]
        let scrollOptions = {
          container: '#scroll-top'
        }
        self.$scrollTo(new_el[0].$el,500, scrollOptions)
        */
      })

      // equipment_entry-${equipment_identifier}
      // document.querySelector("#scroll-top").firstElementChild.scrollIntoView();
    },
    removeEquipment(equipment) {
      equipment.archived = true;
      this.$store.dispatch("equipment/saveEquipment", equipment).then(data => {
        const notification = {
          type: "Info",
          message: "Equipment has been archived"
        };
        this.$store.dispatch("notification/add", notification, { root: true });
      });
    },
    reactivateEquipment(equipment) {
      equipment.archived = false;
      this.$store.dispatch("equipment/saveEquipment", equipment).then(data => {
        const notification = {
          type: "Info",
          message: "Equipment has been reactived"
        };
        this.$store.dispatch("notification/add", notification, { root: true });
      });
    },

    saveEquipment(equipment) {
      let self = this
      let equipment_settings_to_save = {};
      Object.entries(equipment).forEach(function(item) {
        equipment_settings_to_save[item[0]] = item[1];
      });

      let thing = this.$store.dispatch(
        "equipment/saveEquipment",
        equipment_settings_to_save
      ).then( data => {
        const notification = {
          type: 'success',
          message: 'Equipment updated!'
        }
        self.$store.dispatch('notification/add', notification, { root: true })
      });
      //NOTE: equipment.config is created on getEquipment from the settings
      //   .then(data => {
      //     console.log(data)
      //   })
    },
    openLabel(equipment) {
       const lab_config = this.$store.getters['formula/get_lab_config']
      const label_config = lab_config.config.label
      let label_url = Common.generate_equipment_label_url(equipment, label_config);
      window.open(label_url, "_blank");
    },
    download_csv() {
      let list_url = url_for.url_for(
        "electric_lab_get_equipment_list",
        {'version': 'v5'}
      )
      list_url += '.csv'
      window.open(list_url, "_blank");
    }
  },
  mounted() {
    Object.entries(this.equipment_list).forEach( entry => {
      if (!entry[1].transmitter_device_identifier) {
        entry[1].transmitter_device_identifier = ''
      }
      entry[1].collapsed = true
    })
    this.filter_list()
    const pmk_config = this.$store.getters['app/pmk_config']
    console.log('pmk_config', pmk_config)
    if (pmk_config.realm === 'wickliffe') {
      this.equipment_parsers.push({
        value: "nodecimal",
        label: "Non-decimal OHAUS"
      })
    }
  }
};
</script>

<style scoped>
.property-row {
  margin-bottom: 1em;
}
</style>
