
<template>
  <div>
      <div v-for="(task, task_index) in subsequentTasks">
          <template>
              <div class="task-card" :class="parseInt(task.task_priority) == 3 ? 'priority-urgent' : parseInt(task.task_priority) == 2 ? 'priority-elevated' : 'priority-normal' ">
                  <div>Quantity: {{task.task_data.lot.quantity}}</div>
                  <div>Scheduled: {{task.date_scheduled | moment('YYYY-MM-DD ha') }} - {{task.date_due | moment('YYYY-MM-DD ha') }}</div>
                  <div v-if="task.task_data.task_note">{{ task.task_data.task_note }}</div>
              </div>
          </template>
          <!-- <pre>{{task}}</pre> -->
    </div>
  </div>
</template>

<script>
import url_for from "@/services/pmk_url_for";

export default {
  props: {
    subsequentTasks: Array
  },
  data() {
    return {
      tasks_mutable: [ ...this.subsequentTasks || [] ],
      form: {
        name: "",
        category: this.category,
        note: ""
      }
    }
  },
  watch: {
    identifier(old,newVal){
    }
  },
  methods: {
    removeElements(elms) { elms.forEach(el => el.remove())},
    closingModal(){
      console.log('closing Modal')
      this.form.note = "";
      this.form.name = "";
    }
  }
}
</script>

<style scoped>
.upload-label{
  display: flex;
}
.upload-label span{
  display: flex;
  align-items: center
}
.task-card {
    position: relative;
    border-radius: 4px;
    padding: 0.65em;
    margin: 5px 0;
    /* height: 114px; */
    box-sizing: border-box;

}
.priority-normal {
    background-color: rgba(144, 147, 153, 0.35);
}
.priority-elevated {
    background-color:rgba(230, 162, 60, 0.35);
}

.priority-urgent {
    background-color:rgba(245, 108, 108, 0.35);
}
</style>