<template>
  <div>
    <router-link
      :to="{ name: 'formula-manage', params: {formula_identifier: task.task.data.formula_identifier, task_id: task.task.id} }"
    >{{ task.task.task_status === 'complete' ? 'View' : 'Verify'}} Formula {{task.formula.data.identifiers.formula_id}}</router-link>
  </div>
</template>

<script>
export default {
  props: {
    task: Object
  },
  methods: {}
}
</script>

<style scoped>
</style>
