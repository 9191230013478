import Dexie from 'dexie'

const db = new Dexie('db')
db.version(4).stores({
  tasks:
    'id,  [task_type+task_due_status],  task_sort_field,   task_type,    task_status,    data_id,    patient_id,    prescriber_id,    order_id,  currently_due,  past_due,  task_due_status, title_string'
})
db.version(45).stores({
  tasks:
    'id,  [task_type+task_due_status],  task_sort_field,   task_type,    task_status,    data_id,    patient_id,    prescriber_id,    order_id,  currently_due,  past_due,  task_due_status, title_string, formula_identifier, lot_identifier'
})
.upgrade(tx => {
  return tx.tasks.toCollection().modify(task => {
      task.formula_identifier = ''
  });
});
db.version(46).stores({
  tasks:
    'id,  [task_type+task_due_status],  task_sort_field,   task_type,    task_status,    data_id,    patient_id,    prescriber_id,    order_id,  currently_due,  past_due,  task_due_status, title_string, formula_identifier, lot_identifier, task_priority'
})
.upgrade(tx => {
  return tx.tasks.toCollection().modify(task => {
      task.formula_identifier = ''
      task.task_priority = 1
  });
});
db.tasks.clear()

//beforeUnload clear db
window.onbeforeunload = function() {
  //Put IndexedDB saving here, but will only finish if they decide to stay via dialog choice.
  db.tasks.clear()
  return true
}
export default db
