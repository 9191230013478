<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <el-row :gutter="10">
          <el-col :span="18">
            <span>
              <span style="font-size: 1.5em">Inventory Review</span>
              &nbsp;
              <span class="text-secondary"></span>
            </span>
          </el-col>
          <el-col :span="2">
          </el-col>
          <el-col :span="2">
          </el-col>
        </el-row>
      </div>
        <!-- <pre>{{ task }} </pre> -->
      <section class="selected_item_area" v-if="task.lot">
          <inventoryRecord :inventory_entry="task.lot" />
      </section>
    </el-card>
  </div>
</template>
<script>
import NProgress from "nprogress";
import Common from "@/common.js";
import url_for from "@/services/pmk_url_for";
const uuidv4 = require("uuid/v4");

import inventory_record from "@/components/ingredient/inventory_record";

export default {
  props: {
    task: {
      type: Object,
      required: true
    }
  },
  components: { inventoryRecord: inventory_record},
  data() {
    return {
      documents_s: [],
      document_categories: [
        {label:'C of A',value:'C of A'},
        {label:'Purchase Order',value:'Purchase Order'},
      ],
      document_category_titles: {
        "C of A": "C of A",
        "Purchase Order": "Purchase Order"
      },
      categories: this.$store.state.categories,
      form: this.createFreshFormObject(),
      product: this.createFreshInventoryObject()
    };
  },
  mounted(){
    this.documents_s = [];
  },
  methods: {

  emit_event: function () {
    this.$emit('click-save-inventory', 'PASS_WHATEVER_YOU_WANT');
  },
    reset() {
      this.form = this.createFreshFormObject();
      this.product = this.createFreshInventoryObject();
    },
    can_print_labels() {
      return this.product.saved;
    },
    get_manufacturers(queryString, cb) {
      let options = Object.values(this.manufacturers);
      // .map(function(manufacturer) {
      //   return { value: manufacturer.value, id: manufacturer, name: manufacturer }
      // })
      let results = queryString
        ? options.filter(this.createFilter(queryString))
        : options;
      cb(results);
    },
    //    read_product_code() {},

    createFreshFormObject() {
      return {
        identifier: uuidv4(),
        selected_product: {},
        identifiers: { formula_id: "" },
        category: "",
        ingredient: {},
        new_ingredient_description: "",
        manufacturers: [],
        manufacturer: {},
        name: "",
        description: "",
        location: "",
        date_created: "",
        documents: {},
        options: {
          auxillary_labels: [],
          is_lab_stock: false,
          location: "",
          stability_span: ""
        },
        instructions: "",
        tasks: [],
        scanned_code: {},
        ingredients: [],
        new_ingredient: {
          description: "",
          id: "",
          quantity: "",
          note: "",
          identifiers: [
            {
              code: "",
              system: ""
            }
          ]
        }
      };
    },
    createFreshInventoryObject() {
      const user = this.$store.getters["account/profile"];

      return {
        identifier: uuidv4(),
        product_identifier: "",
        manufacturer_identifier: "",
        ingredient_identifier: "",
        selected_product: {},
        ingredient: {
          description: ""
        },
        documents: {},
        author: user.name,
        manufacturer: {},
        name: "",
        description: "",
        date_created: "",
        instructions: "",
        saved: false,
        lot_type: 'ingredient',
        date_entered_inventory: new Date().toJSON(),
        capsule_weight_average: ""
      };
    }
  }
};
</script>
<style scoped>
.location {
  margin-bottom: 0;
}
.location > .icon {
  margin-left: 10px;
}
.event-header > .title {
  margin: 0;
}
.list-group {
  margin: 0;
  padding: 0;
  list-style: none;
}
.list-group > .list-item {
  padding: 1em 0;
  border-bottom: solid 1px #e5e5e5;
}

.formula-options .el-col {
  margin-top: 4px;
}

.auxiallary_button {
  margin-right: 1.5em;
}

.formula-instructions {
  margin: 1.5em 0;
}

.button.bottom {
  margin-top: 13px;
  line-height: 12px;
  padding: 0;
  float: right;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}

.ingredient-suppliers.entry {
  margin-top: 1em;
  padding-bottom: 0.5em;
  border-bottom: 1px solid silver;
}
.ingredient-suppliers h4 {
  font-weight: bold;
}
.manufacturers-area {
  margin: 0.5em 0;
}

.ingredient-options .ingredient-description {
  font-weight: bold;
  margin: 0.75em 0;
}

.selected_item_area .el-row {
  margin-bottom: 1.5em;
}
.clickable {
  cursor: pointer;
}
</style>
