import EventService from "@/services/EventService.js";
import store from "@/store/store";
import { timeout } from "q";
const uuidv4 = require("uuid/v4");
import TaskService from "@/services/task.js";
import { Notification } from 'element-ui';

export const namespaced = true;

export const state = {
  formula: {},
  formula_tasks: {},
  lab_config: "",
  dosage_forms_with_flavors: ['C69068', 'C68996', 'C29012', 'C29269', 'C42893', 'C42894', 'C42912', 'C42941', 'C42953', 'C42955', 'C42996', 'C64880', 'C43001', 'C43002'],
  dosage_forms_with_pack_stat: ['C25158', 'C42961', 'C42972', 'C42973', 'C42975', 'C64907', 'C64908', 'C91171'],
  pack_stat_dosage_forms: {
    capsules: ["000", "00", "0", "1", "2", "3", "4", "5"],
    scoops: ["1mL", "2.5mL", "5mL", "15mL", "29.6mL", "60mL"]
  },
  documents_categories: [
    {label:'Method of Suitability',value:'method_of_suitability'},
    {label:'Sterility',value:'sterility_information'},
    {label:"Patient Handouts", value:"patient_handouts"},
    {label:"Stability", value:"stability"},
    {label:"Testing", value:"testing"},
    {label:"Other", value:"other"},
  ],
  inventoryHistory:[],
  station_types: {
      "bulk_station": {
        "description": "Bulk Station",
        "value": "bulk_station"
      },
      "bases_station": {
        "description": "Bases Station",
        "value": "bases_station"
      },
      "capsule_station": {
        "description": "Capsule Station",
        "value": "capsule_station"
      },
      "cream_station": {
        "description": "Creams Station",
        "value": "cream_station"
      },
      "gel_station": {
        "description": "Gel Station",
        "value": "gel_station"
      },
      "ointment_station": {
        "description": "Ointment Station",
        "value": "ointment_station"
      },
      "oral_liquid_station": {
        "description": "Oral Liquid Station",
        "value": "oral_liquid_station"
      },
      "powder_station": {
        "description": "Powder Station",
        "value": "powder_station"
      },
      "suspension_station": {
        "description": "Suspension Station",
        "value": "suspension_station"
      },
      "suppository_station": {
        "description": "Suppository Station",
        "value": "suppository_station"
      },
      "tablet_station": {
        "description": "Tablet Station",
        "value": "tablet_station"
      },
      "troche_station": {
        "description": "Troche Station",
        "value": "troche_station"
      },
      "other_station": {
        "description": "Other",
        "value": "other_station"
      }
  }

};

export const mutations = {
  SET_WORKING_FORMULA(state, data) {
    state.formula = data;
  },
  SET_WORKING_FORMULA_TASKS(state, data) {
    state.formula_tasks = data;
  },
  SET_LAB_CONFIG(state, data) {
    console.log('committing', data)
    state.lab_config = data;
  },

  SET_EVENTS_TOTAL(state, eventsTotal) {
    state.eventsTotal = eventsTotal;
  },
  SET_EVENT(state, event) {
    state.event = event;
  },
  SET_INVENTORY_HISTORY(state, inventoryHistory) {
    state.inventoryHistory = inventoryHistory;
  },
};

export const actions = {
  getInventoryHistory({ commit, dispatch, state }, ingredient_identifier){
    return EventService.searchInventory({
      "explicit": "1",
      "fields":["lot_number","identifier"],
      "ingredient_identifier": ingredient_identifier,
      "method": "=",
      "search_all_inventory": 1
    }).then(response => {
      commit("SET_INVENTORY_HISTORY", response.data.data)
    })
  },
  copyFormula({ commit, dispatch, state }, data){
    const copy = JSON.parse(JSON.stringify(state.formula))
    copy.original_formula_identifier_copied_from = copy.identifier
    copy.identifier = data;
    copy.description += "[copy]";
    copy.name += "[copy]";
    copy.code = null;
    copy.identifiers.formula_id = null;
    copy.identifiers.ndc = null;
    copy.change_log = []

    // get any new properties the old formula did not have:
    let new_formula = formula_functions.createFreshFormulaObject({
      getters,
      state
    })

    new_formula = {...new_formula, ...copy}
    new_formula.change_log = [];

    commit('SET_WORKING_FORMULA', new_formula)
    return new_formula;
  },
  saveFormula({ commit, dispatch }, data) {
    return EventService.saveFormula(data)
      .then((response) => {
        console.log('saveFormula.data', response.data.data)
        if (response.data.success) {
          commit("SET_WORKING_FORMULA", response.data.data);
          const notification = {
            type: "success",
            message: "Your formula has been saved!"
          };
          dispatch("notification/add", notification, { root: true });
          return response
        } else if (response.data && response.data.messages[0]) {
          const notification = response.data.messages[0]
          dispatch("notification/add", notification, { root: true });
          return response
        } else {
          const notification = {
            type: "error",
            message: "There was a problem saving your formula!"
          };
          dispatch("notification/add", notification, { root: true });
          throw error;
          }
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "There was a problem saving your formula: " + error.message
        };
        dispatch("notification/add", notification, { root: true });
        throw error;
      });
  },
  fetchFormula({ state, commit, getters, dispatch }, id) {
    if (id == "create") {
      let new_formula = formula_functions.createFreshFormulaObject({
        getters,
        state
      });
      return commit("SET_WORKING_FORMULA", new_formula);
    }
    return EventService.getFormula(id)
      .then(response => {
        //add patches:
        if (!response.data.data.data.details) {
          response.data.data.data.details = {};
        }
        response.data.data.data.options.capsule_weight = parseFloat(response.data.data.data.options.capsule_weight)
        response.data.data.data = {  ...formula_functions.createFreshFormulaObject({
              getters,
              state
            }), ...response.data.data.data}
                  console.log({data79: response.data.data})
        commit("SET_WORKING_FORMULA", response.data.data.data);
        return response.data.data;
      })
      .catch(error => {
        const notification = {
          type: "error",
          message: "There was a problem fetching formula: " + error.message
        };
        dispatch("notification/add", notification, { root: true });
      });
  },
  fetchLabConfig({ state, commit, getters, dispatch }, id) {
    return EventService.getLabConfig(id)
      .then(response => {
        let lab_config = response.data.data
        console.log('lab_config', lab_config)


        if ((! lab_config.config.formula_types) ? 1 : 0) {


          let new_formula_type = formula_functions.createFreshFormulaTypeObject()

          lab_config.config.formula_types = {}
          lab_config.config.formula_types[new_formula_type.identifier] = new_formula_type

        }
        //console.log(87, {...response.data, ...{ data: lab_config} })

        commit("SET_LAB_CONFIG", lab_config);
        // return lab_config
        return {...response.data, ...{ data: lab_config} };
      })
      .catch(error => {
        const notification = {
          type: "error",
          message:
            "There was a problem fetching formula types: " + error.message
        };
        dispatch("notification/add", notification, { root: true });
      });
  },
  updateLabConfig({ state, commit, getters, dispatch }, config) {
    console.log('current_config', config)
    let lab_config = {
      formula_types: config.config.formula_types
      // -- add more here -- //
    }
    console.log('current_config', lab_config)

    return EventService.updateLabConfig(lab_config)
      .then(http_response => {
      if (http_response.data.messages) {
          Notification(http_response.data.messages[0])
          //TaskService.render_messages(data.data.messages)
        //dispatch("notification/add", http_response.data.messages[0], { root: true });
      }
      return http_response.data;
      })
      .catch(error => {
        const notification = {
          type: "error",
          message:
            "There was a problem fetching formula types: " + error.message
        };
        dispatch("notification/add", notification, { root: true });
      });
  },
  updateAuxiliaryLabels({ state, commit, getters, dispatch }, config) {

    return EventService.updateAuxiliaryLabels(config)
      .then(response => {
        if (response.messages) {
          dispatch("notification/add", response.messages[0], { root: true });
        }
        dispatch("formula/fetchLabConfig")
        return response.data;
      })
      .catch(error => {
        const notification = {
          type: "error",
          message:
            "There was a problem saving labels: " + error.message
        };
        dispatch("notification/add", notification, { root: true });
      });
  },

  addFormulaType({ state, commit, getters, dispatch }, id) {
    let new_formula_type = createFreshFormulaTypeObject();
    // NEXT STEPS:
    //   insert a new formula type properly into lab_config
    //   also ensure we can properly save the formula type back,
    //     maybe another API is needed to avoid needed the entire lab object each time.

    let lab_config = {
      ...store.lab_config,
      ...{
        ...state.lab_config.config.formula_types,
        ...new_formula_type
      }
    };
    commit("SET_LAB_CONFIG", lab_config);
  },
  setLabConfig({ state, commit, getters, dispatch }, lab_config) {
    commit("SET_LAB_CONFIG", lab_config);
  }
};

export const getters = {
  get_formula: state => {
    return state.formula;
  },
  get_formula_types: state => {
    return state.lab_config.config.formula_types;
  },
  get_lab_config: state => {
    return state.lab_config;
  },
  get_document_categories: state => {
    return state.documents_categories;
  },
  get_inventory_history: state => {
    return state.inventoryHistory
  },
  get_station_types: state => {
    return state.station_types
  },
  get_pack_stat_dosage_forms: state => {
    return state.dosage_forms_with_pack_stat
  },
  get_pack_stat_dosage_types: state => {
    return state.pack_stat_dosage_forms
  },
  get_flavor_dosage_forms: state => {
    return state.dosage_forms_with_flavors
  }
};

export const formula_functions = {
  createFreshFormulaTypeObject() {
    //const user = store.state.user.user;
    // see also in manage_types.vue
    return {
     identifier: uuidv4(),
      instructions: "",
      name: 'New Formula Type',
      tasks: [] // this should be in the same format as formula tasks
    };
  },
  createFreshFormulaObject() {
    const user = store.getters["account/profile"];
    return {
      identifier: uuidv4(),
      identifiers: { formula_id: "" },
      category: "",
      author: user.name,
      verified_by: "",
      date_created: new Date().toJSON(),
      date_verified: "",
      formula_status: "draft",
      formula_type: "",
      formula_quantity: 1,
      unit_of_measure: "",
      name: "",
      label_description: "",
      description: "New Formula",
      location: "",
      verification_tasks: {},
      details: {
        chemical_compatibility: "",
        packaging: "",
        storage_instructions: "",
        stability_supporting_documentation: "",
        final_appearance: ""
      },
      options: {
        auxiliary_labels: [],
        is_lab_stock: false,
        stability_span: "",
        flavor: ""
      },
      documents: {},
      change_log: [],
      instructions: "",
      tasks: [],
      ingredients: [],
      new_ingredient: {
        description: "",
        id: "",
        quantity: "",
        note: "",
        identifiers: [
          {
            code: "",
            system: ""
          }
        ]
      }
    };
  }
};
