<template>
  <div class="" v-if="ingredient_availability">
    <div v-for="ingredient in formula.ingredients" :key="ingredient.identifier">
      <b>{{ingredient.name}}</b>
      <span v-if="quantity">
        - {{amount_ingredient_required(ingredient)}} Required&nbsp;
        <span v-if="enough_ingredient(ingredient)">
          <i class="fas fa-check" style="color:green"></i>
        </span>
        <span v-else>
          <i class="fas fa-times" style="color:tomato"></i>
        </span>
      </span>
      <ul v-if="ingredient_availability[ingredient.identifier]">
        <li v-for="lot in Object.values(ingredient_availability[ingredient.identifier])
          .sort((a,b) => new Date(b.beyond_use_date) - new Date(a.beyond_use_date))
          .filter((obj) => new Date(obj.beyond_use_date) > new Date()).slice(0, 5)" :key="lot.lot_identifier"
        >
          Lot #{{lot.lot_number}} | {{parseFloat(lot.quantity_remaining).toFixed(decimal_places)}} remaining | BUD: {{lot.beyond_use_date}}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import EventService from "@/services/EventService.js";
import _ from 'lodash'
export default {
  props: {
    formula: Object,
    quantity: Number,
  },
  data() {
    return {
      ingredient_availability: null
    }
  },
  computed: {
    decimal_places() {
      return _.get(this.$store.getters['formula/get_lab_config'].config, 'lot.decimal_places', 3)
    },
  },
  mounted() {
    this.get_ingredient_availability()
  },
  methods: {
    get_ingredient_availability() {
      let ingredient_identifiers = this.formula.ingredients.map(obj => obj.identifier)
      EventService.pmk_fetch('electric_lab_ingredient_inventory_availability', 'GET', {}, {ingredient_identifiers: ingredient_identifiers}).then((response) => {
        this.ingredient_availability = response.data.data
      })
    },
    amount_ingredient_required(ingredient) {
      let quantity_multiplier = this.quantity / this.formula.formula_quantity
      return( ingredient.quantity * quantity_multiplier).toFixed(this.decimal_places)
    },
    enough_ingredient(ingredient) {
      let amount_required = this.amount_ingredient_required(ingredient)
      if (this.ingredient_availability[ingredient.identifier]) {
        let valid_lots = Object.values(this.ingredient_availability[ingredient.identifier])
          .filter((obj) => new Date(obj.beyond_use_date) > new Date())
        let quantity_available = valid_lots.reduce((a,b) => a + (parseFloat(b.quantity_remaining) || 0), 0)
        return quantity_available > amount_required
      }
      return 0
    }
  }
}
</script>

<style lang="css" scoped>
</style>
