<template>
  <div>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <el-row :gutter="10">
          <el-col :span="18">
            <span>
              <span style="font-size: 1.5em">Inventory</span>
              &nbsp;
              <span class="text-secondary"></span>
            </span>
          </el-col>
          <el-col :span="4">
          </el-col>
        </el-row>
      </div>

      <el-row :gutter="10">
        <el-col :span="12">
          <div class="sub-title">
            <i class="fal fa-barcode-scan"></i> Enter Product Code:
          </div>
          <el-autocomplete
            id='ingredient_search_field'
            class="inline-input"
            style="width: 50em;"
            v-model="form.product_code"
            :fetch-suggestions="search_product_identifiers"
            placeholder="Search..."
            :trigger-on-focus="false"
            @select="select_product"
            autocapitalize="none"
            clearable
            tabindex="0"
          >
            <template slot="prepend">
              <i class="fal fa-search"></i>
            </template>

            <template slot-scope="{ item }">
              <div class="value">{{ item.manufacturer_name }} - {{item.description}}</div>
            </template>
            <!-- <template slot="append">
              <i @click="select_product" class="fal fa-plus-circle"></i>
            </template>-->
          </el-autocomplete>&nbsp;
        </el-col>
        <el-col :span="8">
          <router-link :to="{ name: 'ingredient-manage' }">Add/Manage Ingredients</router-link>
        </el-col>
      </el-row>
      <section class="selected_item_area" v-if="product.product_identifier" style="margin-block-start: 10px;">
          <inventoryRecord :inventory_entry="product" />
      </section>
    </el-card>
  </div>
</template>
<script>
import NProgress from "nprogress";
import Common from "@/common.js";
import url_for from "@/services/pmk_url_for";
const uuidv4 = require("uuid/v4");

import inventory_record from "@/components/ingredient/inventory_record";

export default {
  // props: {
  //   formula: {
  //     type: Object,
  //     required: false,
  //     default: this.createFreshFormObject()
  //   }
  // },
  components: { inventoryRecord: inventory_record},
  data() {
    return {
      documents_s: [],
      document_categories: [
        {label:'C of A',value:'C of A'},
        {label:'Purchase Order',value:'Purchase Order'},
      ],
      document_category_titles: {
        "C of A": "C of A",
        "Purchase Order": "Purchase Order"
      },
      categories: this.$store.state.categories,
      form: this.createFreshFormObject(),
      product: this.createFreshInventoryObject()
    };
  },
  mounted(){
    this.documents_s = [];
    document.querySelector('#ingredient_search_field').focus()
  },
  methods: {

  emit_event: function () {
    this.$emit('click-save-inventory', 'PASS_WHATEVER_YOU_WANT');
  },
    reset() {
      this.form = this.createFreshFormObject();
      this.product = this.createFreshInventoryObject();
    },
    can_print_labels() {
      return this.product.saved;
    },
    get_manufacturers(queryString, cb) {
      let options = Object.values(this.manufacturers);
      // .map(function(manufacturer) {
      //   return { value: manufacturer.value, id: manufacturer, name: manufacturer }
      // })
      let results = queryString
        ? options.filter(this.createFilter(queryString))
        : options;
      cb(results);
    },
    //    read_product_code() {},
    select_product(selected_product) {
      // console.log(selected_product)
      let scan = { structured_code: {} };
      if (this.form.scan) {
        scan = this.form.scan.structured_code;
      }

      this.form.selected_product = selected_product;
      let product_identifiers =
        selected_product.manufacturers[selected_product.manufacturer.identifier]
          .product_identifiers[selected_product.product_identifier];

      this.product = {
        ...this.createFreshInventoryObject(),
        ...{
          product_identifier: selected_product.product_identifier,
          // product_identifiers: selected_product.product_identifiers,
          product_identifiers: product_identifiers,
          manufacturer_identifier: selected_product.manufacturer.identifier,
          ingredient_identifier: selected_product.identifier,
          ingredient: {
            description: selected_product.description,
            location: selected_product.location,
            unit_of_measure: selected_product.unit_of_measure
          },
          manufacturer: selected_product.manufacturer,
          has_activity_factor: selected_product.has_activity_factor,
          description: selected_product.description,
          date_created: new Date().toJSON(),
          inventory: {
            beyond_use_date: scan.beyond_use_date,
            lot_number: scan.lot_number,
            quantity: scan.quantity,
            container_id: scan.container_id
          },
          lot_number: scan.lot_number        }
      };
      this.form = this.createFreshFormObject();
      // focus the lot#: TODO
      try {
        this.$nextTick(() => this.$refs.inputLotNumber.focus());
      } catch (e) {
        console.error(e)
      }
    },
    createFreshFormObject() {
      return {
        identifier: uuidv4(),
        selected_product: {},
        identifiers: { formula_id: "" },
        category: "",
        ingredient: {},
        new_ingredient_description: "",
        manufacturers: [],
        manufacturer: {},
        name: "",
        description: "",
        location: "",
        date_created: "",
        documents: {},
        options: {
          auxillary_labels: [],
          is_lab_stock: false,
          location: "",
          stability_span: ""
        },
        instructions: "",
        tasks: [],
        scanned_code: {},
        ingredients: [],
        new_ingredient: {
          description: "",
          id: "",
          quantity: "",
          note: "",
          identifiers: [
            {
              code: "",
              system: ""
            }
          ]
        }
      };
    },
    createFreshInventoryObject() {
      const user = this.$store.getters["account/profile"];

      return {
        identifier: uuidv4(),
        product_identifier: "",
        manufacturer_identifier: "",
        ingredient_identifier: "",
        selected_product: {},
        ingredient: {
          description: ""
        },
        documents: {},
        author: user.name,
        manufacturer: {},
        name: "",
        description: "",
        date_created: "",
        instructions: "",
        saved: false,
        lot_type: 'ingredient',
        date_entered_inventory: new Date().toJSON(),
        capsule_weight_average: ""
      };
    },

    search_product_identifiers(queryString, cb) {
      // first parse the barcode:
      let product_identifier = queryString;
      let scan = Common.parse_scan(product_identifier);
      // console.log(scan)
      if (!scan.structured_code.empty) {
        this.form.scan = scan;
      }

      // here we need to query the ingredient list API for manufacturer identifiers
      let query_object = {
        explicit: 0,
        method: "LIKE",
        product_identifier:
          scan.structured_code.product_code ||
          scan.structured_code.ndc ||
          product_identifier,
        description: queryString
      };
      NProgress.start();
      this.$store
        .dispatch("ingredient/listIngredients", query_object)
        .then(() => {
          cb(
            this.$store.getters["ingredient/ingredient_search_list"].map(
              item => item.data
            )
          );
        })
        .then(() => NProgress.done())
        .catch(() => {
          NProgress.done();
        });
    },

    print_lot_labels() {
      if (this.product.identifier) {
        let label_url = Common.get_lot_label_url(
          this.product,
          this.product.ingredient,
          this.$store.getters['formula/get_lab_config']
        );
        window.open(label_url, "_blank");
      }
    },

    record_entry() {
      // see how createEvent in event.js works:
      // an action gets dispatched, which performs a POST, inside of which a commit is made
      NProgress.start();
      // attach documents
      this.product.documents = this.documents_s;
      this.$store
        .dispatch("ingredient/inventoryLog", this.product)
        .then(() => {
          // this.product = this.createFreshEventObject()
          // this.form = this.createFreshEventObject()
          this.product.saved = true;
        })
        .then(() => NProgress.done())
        .catch(() => {
          NProgress.done();
        });
    }
  }
};
</script>
<style scoped>
.location {
  margin-bottom: 0;
}
.location > .icon {
  margin-left: 10px;
}
.event-header > .title {
  margin: 0;
}
.list-group {
  margin: 0;
  padding: 0;
  list-style: none;
}
.list-group > .list-item {
  padding: 1em 0;
  border-bottom: solid 1px #e5e5e5;
}

.formula-options .el-col {
  margin-top: 4px;
}

.auxiallary_button {
  margin-right: 1.5em;
}

.formula-instructions {
  margin: 1.5em 0;
}

.button.bottom {
  margin-top: 13px;
  line-height: 12px;
  padding: 0;
  float: right;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}

.ingredient-suppliers.entry {
  margin-top: 1em;
  padding-bottom: 0.5em;
  border-bottom: 1px solid silver;
}
.ingredient-suppliers h4 {
  font-weight: bold;
}
.manufacturers-area {
  margin: 0.5em 0;
}

.ingredient-options .ingredient-description {
  font-weight: bold;
  margin: 0.75em 0;
}

.selected_item_area .el-row {
  margin-bottom: 1.5em;
}
.clickable {
  cursor: pointer;
}
</style>
