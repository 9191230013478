
<template>
  <div>
    <el-card class="box-card">
      <div ref="worksheet_area_inner">
        <div slot="header" class="clearfix">
          <span id="header_flex" style=" display: flex; justify-content: space-between;">
            <span class="formula_name">
              <span style="font-size: 1.5em">{{ formula.name }}</span>
              &nbsp;
              <span class="text-secondary">
                [
                <router-link
                  :to="{ name: 'formula-manage', params: { formula_identifier: formula.identifier } }"
                >{{formula.identifiers.formula_id}}</router-link>]
              </span>
              <div
                class="text-secondary"
              >Date compounded: {{lot.date_compounded | moment('YYYY-MM-DD HH:mm')}}</div>
            </span>
            <span class="text-secondary" style="font-size: 1.5em; float:right;display: flex;">
              <div><img id="lot_barcode_png" alt="Barcode" /></div>
              <div class="p-3">
                Lot #: {{lot.lot_number}}
                <div style="font-size: 0.8rem;">{{lot.identifier}}</div>
              </div>

            </span>
          </span>
        </div>

        <el-row :gutter="10" class="formula-options">
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
            <div>
              Formula Type:
              <span style="text-transform: capitalize;">{{ formula_type.name }}</span>
              <span v-if="formula.options.is_lab_stock">[Lab Stock]</span>
            </div>
            <div>Estimated Time: {{formula.task_time_required}}</div>
            <div>Location: {{formula.location || 'Not Set'}}</div>
            <div>Formula stability span: {{formula.options.stability_span}} days</div>

            <div
              v-if="formula.options.capsule_weight"
            >
              Capsule Weight: {{formula.options.capsule_weight}}
            </div>

            <div v-if="!(lot.preparation_environment && lot.preparation_environment.environment_not_set && lot.preparation_environment_type === '01')">
              Environment:
              <span v-if="lot.compounding_environment_type">{{lot.compounding_environment_type}}</span>
            </div>
            <div>
              <span class="indicators">
                <span class="indicator" style="color: Tomato;" v-if="formula.formula_information && formula.formula_information.is_hazardous">
                  <i style="font-size: 1.0rem;" class="fad fa-flask-poison"></i>&nbsp;Hazardous
                </span>
                <span v-if="formula.formula_information && formula.formula_information.dea_schedule">
                  <i style="font-size: 1.0rem;" class="fad fa-exclamation-triangle"></i>&nbsp;{{ formula.formula_information.dea_schedule.description }}
                </span>
              </span>
            </div>
            <div>Quantity: {{lot.quantity}}</div>

            <div>Beyond Use: {{lot.inventory.beyond_use_date | moment('YYYY-MM-DD')}}</div>

            <div v-if="view_mode() == 'readonly'">
              <span v-if="!lot.session_data">Task Performed By: {{lot.author}}</span>
              <span v-else>Task Performed By:
                <span v-for="(user, index) in users_worked_on_lot" :key="index"><span v-if="index >= 1">,</span> <span :style="user.user_type === 'lab-pharmacist' ? {'text-decoration': 'underline'} : {}">{{ user.name }}</span></span>
              </span>
            </div>
            <div
              v-if="view_mode() == 'readonly' && lot.verified_by"
            >Verified By: {{lot.verified_by.name}}</div>
            <div>Station: {{lot.station_primary}}</div>
            <div>Preparation Environment: {{preparationEnvText}}</div>
            <div>
              Route of Administration: {{route_of_administration_text}}
            </div>
          </el-col>
          <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12" v-if="lot.activity_factor_difference || lot.pack_stat_fill">
            <div v-if="(lot.activity_factor_difference && Object.values(lot.activity_factor_difference).length) || (lot.pack_stat_fill && Object.values(lot.pack_stat_fill).length) || lot.activity_factor_calculation">
              <el-card class="box-card">
                <div slot="header" class="clearfix">
                  <span style="font-size: 1.5em;margin: 0">Allowed Lots</span>
                </div>
                <el-collapse v-model="lot.lots_allowed_collapse">
                  <el-collapse-item
                    v-for="ingredient in lot.lots_allowed"
                    :key="ingredient.identifier"
                    :title="`${ingredient.data.description || ingredient.description} - Lot# ${ingredient.data.lot_number}`"
                    :name="ingredient.data.lot_number">
                      <b>Original amount:</b>
                      {{parseFloat(formula.ingredients.filter(ing => ing.identifier === ingredient.ingredient_identifier)[0].quantity * quantity_muliplier).toFixed(decimal_places)}}
                      <BaseUnitOfMeasure
                        :unit="(ingredient.formula_unit_of_measure || ingredient.unit_of_measure)"
                      /><br>
                      <span v-if="ingredient.data && ingredient.data.activity_factor">
                        <b>Activity Factor:</b> {{ ingredient.data.activity_factor }}%<br>
                      </span>
                      <span v-if="ingredient.data && ingredient.data.pack_stat && lot.formula.pack_stat_required">
                        <b>Pack Stats:</b>
                        <div
                          v-for="pack_stat in Object.entries(ingredient.data.pack_stat).filter(entry => entry[1] > 0)"
                          :key="pack_stat[0]">
                          {{pack_stat[0]}}: {{pack_stat[1]}} gm
                        </div>
                      </span>
                  </el-collapse-item>
                  <el-collapse-item
                    title="Calculations"
                    name="calculations"
                  >
                  <div v-if="!lot.activity_factor_calculation">
                    <div
                      v-for="ingredient in uniqueby(Object.values(lot.lots_allowed).filter(ing => ing.ingredient.has_pack_stat || ing.ingredient.has_activity_factor || ing.ingredient.identifier === lot.formula.activity_factor_modifying_ingredient), 'ingredient_identifier')"
                      :key="ingredient.identifier">
                        <packstatCalculations :ingredient="ingredient" :formula="formula" :lot="lot" :quantity_muliplier="quantity_muliplier" />
                    </div>
                  </div>
                  <div v-else v-html="lot.activity_factor_calculation">
                  </div>
                  </el-collapse-item>
                </el-collapse>
              </el-card>
            </div>
          </el-col>
        </el-row>

        <el-row :gutter="10" class="formula-options" v-if="lot.date_removed && lot.status === 'cancelled'">
          <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="8">
            <div><b>Lot cancelled</b></div>
            <div>
              Reason: {{lot.reason_removed_details}}
            </div>
            <div>
              Date Cancelled: {{lot.date_removed | moment('YYYY-MM-DD h:mm:ss a') }}
            </div>
            <div v-if="lot.cancelled_by">
              Cancelled By: {{ lot.cancelled_by.name }}
            </div>
          </el-col>
        </el-row>

        <el-row>
          <div class="formula-ingredients">
            <div style="margin-top: 15px;">
              <el-col :span="11">
                <div class="sub-title section-header">Ingredients</div>
              </el-col>
            </div>
          </div>
        </el-row>
        <el-row v-for="(ingredient, index) in formula.ingredients" :key="index">
          <div style="margin-top: 15px;">
            <el-col :span="11">
              <el-row>
                <el-col :span="17">
                  <span
                    :class="calculate_ingredient_in_range(ingredient) ? 'ingredient-quantity-required-met' : '' "
                  >
                    <i
                      class="fas fa-check-circle mr-5"
                      v-if="calculate_ingredient_in_range(ingredient)"
                    >&nbsp;</i>
                    <span
                      :class="ingredient.identifier == current_ingredient.ingredient_identifier ? 'current-ingredient-lot' : ''"
                    >{{ ingredient.ingredient_type == 'formula' ? ingredient.name : ingredient.description || ingredient.name }}</span>
                  </span>
                  &nbsp;
                  <span class="ingredient-code">{{ ingredient.code }}</span>
                </el-col>
                <el-col :span="1">&nbsp;</el-col>
                <el-col
                  :span=" (ingredient.quantity_sufficient_indicator|| ingredient.requires_measurement_check) ? 23 : 6"
                >
                  <!-- REQUIRES MEASUREMENT CHECK: -->
                  <div style="display: inline-block;" v-if="0==1">
                    Quantity:&nbsp;{{form.manual_measurement}}
                    <BaseUnitOfMeasure
                      :unit="(ingredient.formula_unit_of_measure || ingredient.unit_of_measure)"
                    />
                  </div>
                </el-col>
                <!-- <el-col :span="1">
                <span style="padding-right: 55px;">&nbsp;</span>
                </el-col>-->
              </el-row>
              <el-row v-if="1==0">
                <el-col :span="24">
                  <div v-if="! ingredient.requires_measurement_check">
                    <div
                      class="quantity_current"
                    >{{ parseFloat(calculate_ingredient_current_quantity(ingredient)).toFixed(decimal_places) }}</div>
                    <div
                      class="quantity_needed"
                      :class="ingredient.quantity_sufficient_indicator ? 'quantity_sufficient' : '' "
                    >{{ parseFloat(parseFloat(calculate_quantity_required(ingredient)) - parseFloat(calculate_ingredient_current_quantity(ingredient))).toFixed(decimal_places) }}</div>
                  </div>
                  <div v-else>
                    <div>
                      <div
                        class="quantity_current"
                      >{{ parseFloat(calculate_ingredient_current_quantity(ingredient)).toFixed(decimal_places) }}</div>
                      <div
                        class="quantity_needed"
                      >{{ parseFloat(parseFloat(calculate_quantity_required(ingredient)) - parseFloat(calculate_ingredient_current_quantity(ingredient))).toFixed(decimal_places) }}</div>
                    </div>
                  </div>
                </el-col>
                <el-col :span="4">
                  <span style="padding-right: 55px;">&nbsp;</span>
                </el-col>
              </el-row>
            </el-col>
            <el-col :span="1">&nbsp;</el-col>
            <el-col :span="6">
              <span class="ingredient-measurement-line">
                <span v-if="! ingredient.requires_measurement_check">
                  <span
                    class="ingredient-quantity-used"
                  >{{ parseFloat(calculate_ingredient_used_quantity({identifier: ingredient.identifier })).toFixed(decimal_places) }}</span>
                  <span>&nbsp;/&nbsp;</span>
                </span>
                <span class="ingredient-quantity-required">
                  <span
                    class="ingredient-quantity-sufficient-message"
                    v-if="ingredient.quantity_sufficient_indicator"
                  >QS to</span>
                  {{ calculate_quantity_required(ingredient) }}
                </span>
                <span></span>
                <span class="unit-of-measure-area">
                  <BaseUnitOfMeasure
                    :unit="(ingredient.formula_unit_of_measure || ingredient.unit_of_measure)"
                  />
                </span>
              </span>
            </el-col>
            <el-col :span="6" v-if="ingredient.requires_measurement_check" style="display: flex; flex-direction: row">
              <span v-show="ingredient.measurement_checked" style="margin-right: 5px">
                <i class="fal fa-check-square"></i>
              </span>
              <span v-show="!ingredient.measurement_checked" style="margin-right: 5px">
                <i class="fal fa-square"></i>
              </span>

              <!--
            <div v-if="view_mode() == 'pending'">
	            <el-button @click="log_measurement_reviewer(ingredient, (! ingredient.measurement_checked) )" type="info" size="mini" round plain>{{ ! ingredient.measurement_checked ? 'Check' : 'Reset' }}</el-button>
            </div>
              -->
              <div
                v-if="ingredient.measurement_checked && view_mode() == 'readonly' && typeof ingredient.measurement_checked_by == 'object'"
              >
                <div>Checked By: {{ingredient.measurement_checked_by.name}} [{{new Date(ingredient.date_measurement_checked) | moment('YYYY-MM-DD h:mm:ss a') }}]</div>
              </div>
            </el-col>
          </div>
          <div style="clear:both;">
            <el-col :span="12">
              <div v-if="lot.ingredients_used[ingredient.identifier]">
                <b class="lot-header">Lots:</b>
                <div
                  v-for="ingredient_lot of Object.values(lot.ingredients_used[ingredient.identifier].lots).sort((a, b) => new Date(a.date_scanned) - new Date(b.date_scanned))"
                  :key="ingredient_lot.identifier"
                  class="lot-line"
                >
                  <!-- <pre>{{ ingredient_lot }}</pre> -->
                  <!-- {{ parseFloat(ingredient_lot.quantity || 0 ).toFixed(decimal_places) }} -->
                  <div>
                    <span class="lot-info">
                      <router-link  :to="{ name: 'ingredient-inventory-item', params: { identifier: ingredient_lot.identifier }}" target="_blank" style="color:#303133" >
                        {{ ingredient_lot.lot_number }}
                      </router-link>
                       <span class="manufacturer_name" v-if="ingredient_lot.lot_type==='ingredient' && ingredient_lot.data.manufacturer && ingredient_lot.data.manufacturer.name">[{{ingredient_lot.data.manufacturer.name}}]</span>
                      <span
                        class="lot-beyond-use-date"
                        :class="view_mode()"
                      >
                        Beyond Use:
                        <span
                          class="date no-break"
                        >{{moment_utc(ingredient_lot.data.inventory.beyond_use_date)}}</span>
                      </span>
                      <div class="lot-capsule-weight-average" v-if="ingredient_lot.data.inventory.capsule_weight_average">Empty Capsule Weight: {{ingredient_lot.data.inventory.capsule_weight_average}}</div>
                    </span>
                    <span class="ingredient-lot-quantity-area">

                      <span
                        class="ingredient-lot-quantity"
                      >Quantity Used: {{parseFloat(ingredient_lot.quantity|| 0).toFixed(decimal_places)}}</span>
                    </span>
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :span="12" v-if="lot.ingredients_used[ingredient.identifier]">
              <!--
            <pre>
              {{lot.ingredients_used[ingredient.identifier].log_measurements}}
            </pre>
              -->
              <div class="measurements-log-area">
                <div>
                  <b class="log-header">Measurements Log</b>
                </div>
                <div
                  v-for="item in lot.ingredients_used[ingredient.identifier].log_measurements"
                  :key="item.identifier"
                >
                  <span class="logged-weight" :class="item.archived ? 'strike' : ''">{{item.weight}}</span>
                  [ {{item.user || item.name}} - {{new Date(item.date_logged) | moment('YYYY-MM-DD h:mm:ss a') }}]
                </div>
              </div>
            </el-col>
          </div>
        </el-row>
        <el-row>
          <div style="margin-top: 15px;">
            <el-col :span="16">&nbsp;</el-col>
            <el-col :span="4"></el-col>
            Total Weight: {{calculate_total()}}
          </div>
        </el-row>
        <el-row>
          <div style="margin-top: 15px;">
            <el-col :span="24">
              <b class="section-header">Equipment Used:</b>
              <el-table :data="equipment_list" style="width: 100%">
                <el-table-column prop="description" label="Equipment Name" ></el-table-column>
                <el-table-column label="User" width="300">
                  <template slot-scope="scope">
                    {{ scope.row.user }}<br>
                    <i class="el-icon-time"></i>
                    <span
                      style="margin-left: 10px"
                    >{{ scope.row.date_checked_out | moment('YYYY-MM-DD h:mm a') }}</span>
                  </template>
                </el-table-column>
                <!--
                <el-table-column prop="date_checked_out" label="Timestamp" width="240">
                  <template slot-scope="scope">
                    <i class="el-icon-time"></i>
                    <span
                      style="margin-left: 10px"
                    >{{ scope.row.date_checked_out | moment('YYYY-MM-DD h:mm a') }}</span>
                  </template>
                </el-table-column>
              -->
              </el-table>
            </el-col>
          </div>
        </el-row>

        <div class="formula-instructions-area">
          <div class="section-header">
            Instructions
            <span
              class="toggle-instructions clickable"
              style="color: rgb(100, 100, 100); margin-left: 2em;  text-align:right;"
            >
              <i
                class="far fa-eye"
                v-if=" ! show_instructions"
                @click="toggleInstructionVisibility(true)"
              ></i>
              <i class="far fa-eye-slash" @click="toggleInstructionVisibility(false)" v-else></i>
            </span>
          </div>
          <div class="formula-instructions" v-if="show_instructions || printView">{{formula.instructions}}</div>
          <div
            class="formula-type formula-instructions"
            v-if="show_instructions || printView"
          >{{formula_type.instructions}}</div>
        </div>

        <div class="formula-tasks">
          <h4>
            <i style="margin-right: .5em" class="fal fa-tasks"></i>
            <span>Tasks</span>
          </h4>
          <el-form>
            <el-form-item
              label
              v-for="(task, index) in task_mutatable.lot.formula.tasks"
              :key="index"
            >
              <span v-if="'printable_checkboxes' == false">
                <el-checkbox
                  :label="task.description"
                  :name="`task_${index}`"
                  @change="set_subtask_status(task, index)"
                  v-model="task.complete"
                  :checked="task.complete"
                  :readonly="view_mode() == 'readonly'"
                ></el-checkbox>
              </span>
              <span v-else>
                <i :class=" task.complete ? 'fal fa-check-square' : 'fal fa-square'"></i>
                {{task.description}}
              </span>
              <span v-if="view_mode() == 'readonly'">
                [
                <el-tooltip placement="top">
                  <div
                    slot="content"
                  >{{new Date(task.date_completed) | moment('YYYY-MM-DD h:mm:ss a') }}</div>
                  <span class="clickable" v-if="task.user">{{task.user.name}}</span>
                  <span v-else>
                    <i class="fal fa-exclamation-triangle"></i>
                    <span v-if="! task.complete">Incomplete</span>
                    <span v-else>n/a</span>
                  </span>
                </el-tooltip>]
              </span>

              <div v-if="task.record_weights">

                <div class="list-wrap">
                  <div class="styled-list">
                    <div v-for="(weight, weight_index) in task.weights" :key="weight_index">
                      {{weight}}
                      <i
                        class="fal fa-minus-circle clickable item-remove"
                        @click="task.weights.splice(weight_index, 1)"
                        v-if="view_mode() == 'pending'"
                      ></i>
                    </div>
                  </div>
                </div>

                <!-- remove dead code from copypasta some day -->
                <span v-if="view_mode() == 'pending'">
                  <el-input
                    v-model="subtasks[index].new_weight"
                    style="max-width: 5em; "
                    size="mini"
                  ></el-input>&nbsp;
                  <el-button type="primary" @click="set_subtask_weights(task, index )" size="mini">
                    <i class="fal fa-plus"></i> &nbsp; Add
                  </el-button>
                  <el-button
                    type="primary"
                    @click="set_subtask_weights(task, index, balance.weight )"
                    data-old="@click='task.weights.push(balance.weight)''"
                    size="mini"
                  >
                    <i class="fal fa-balance-scale-right"></i>
                    &nbsp; Record [{{balance.weight}}]
                  </el-button>
                </span>
              </div>

              <div v-if="task.record_manual_measurement">
                <ul>
                  <li v-for="(measurement, measurement_index) in task.measurements" :key="measurement_index">
                    {{measurement.display_description || measurement.value}}
                    <i
                        class="fal fa-minus-circle clickable item-remove"
                        @click="remove_task_measurement(index, measurement_index)"
                        v-if="view_mode() == 'pending'"
                    ></i>
                    <!-- ^^ remove dead code from copypasta some day ^^ -->
                  </li>
                </ul>
                <!-- remove dead code from copypasta some day -->
                <span v-if="view_mode() == 'pending'">
                  <el-input v-model="subtasks[index].new_measurement.value" style="max-width: 25em; " size="mini"></el-input>&nbsp;
                  <el-button type="primary" @click="set_subtask_measurement(task, index )" size="mini">
                    <i class="fal fa-plus"></i> &nbsp; Add
                  </el-button>
                </span>
              </div>

            <div v-if="task.record_time_measurement">
              <i class="fad fa-stopwatch"></i>
              <span v-if="task.time_measurements && task.time_measurements[0]">
                <span class="time_measurement_begin">{{ task.time_measurements[0] | moment('YYYY-MM-DD hh:mm a') }}</span> -
                <span class="time_measurement_end" v-if="task.time_measurements[1]">{{ task.time_measurements[1] | moment('YYYY-MM-DD  hh:mm a') }}</span>
                <span class="time_measurement_quantity" v-if="task.time_measurements[1]"> | {{ format_time_quantity(task.time_measurements) }} </span>
              </span>
            </div>

              <div v-if="task.record_photo">
                <el-row>
                  <el-col :span="24">
                    <div style="margin-top: 20px;">
                      <h4>
                        <i style="margin-right: .5em" class="fal fa-file"></i>
                        <span>Documents</span>
                      </h4>
                      <!-- <pre>task.documents:{{task.documents}}</pre> -->
                      <div v-for="(doc, index) in task.documents" :key="index">
                        <div v-if="doc.documents && doc.documents.length > 0">
                          <div class="sub-title">Category: {{ task_document_categories[doc.category] }}</div>
                          <table class="styled striped" width="100%">
                            <thead>
                            <tr>
                              <th width="20%">Name</th>
                              <th width="70%">Note</th>
                              <th width="">Preview</th>
                              <!-- <th width="10%">Remove</th> -->
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="item in doc.documents" :key="item.identifier">
                              <td style="word-break: break-all;">
                                <el-link :href="get_document_url(item.identifier, {file_name: item.name, mime_type: item.mime_type || item.type})" target="_blank">
                                  <i class="fa fa-file"></i>
                                  {{ item.name }}
                                </el-link>
                              </td>
                              <td>{{ item.note }}</td>
                              <td>
                                <a :href="get_document_url(item.identifier, {file_name: item.name, mime_type: item.mime_type || item.type})" target="_blank">
                                  <el-image
                                    v-if="is_picture(item.mime_type || item.type)"
                                    style="width: 200px; height: 200px"
                                    :src="get_document_url(item.identifier, {file_name: item.name, mime_type: item.mime_type || item.type, width: 200})"
                                    fit="scale-down"
                                  >
                                    <div slot="placeholder" class="image-slot">
                                      Loading<span class="dot">...</span>
                                    </div>
                                  </el-image>
                                </a>
                              </td>
                              <!-- <td>{{ doc.category }}</td> -->
                              <!-- <td>
                                <el-button
                                  plain
                                  size="mini"
                                  type="danger"
                                  icon="el-icon-delete"
                                  circle
                                  @click="deleteDoc(doc.documents, item)"
                                ></el-button>
                              </td> -->
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </el-form-item>
          </el-form>
          <!-- formula-type tasks -->
        </div>

        <div
          style="border-top: .5px solid #aaa; border-bottom: .5px solid #aaa; padding: 15px 15px; margin: 10px 0px;"
          v-if="formula.details.final_appearance || formula.details.packaging || formula.details.storage_instructions"
        >
          <div v-if="formula.details.final_appearance">
            <b class="section-header">Final Appearance: {{formula.details.final_appearance}}</b>
          </div>
          <div v-if="formula.details.packaging">
            <b class="section-header">Packaging: {{formula.details.packaging}}</b>
          </div>
          <div v-if="formula.details.storage_instructions">
            <b class="section-header">Storage Instructions: {{formula.details.storage_instructions}}</b>
          </div>
        </div>


        <!-- details formula part  -->
        <el-row>
          <el-col>
              <detailspart :details="formula.details" :toprint="true"></detailspart>
          </el-col>
        </el-row>
        <el-row>
          <div style="margin-bottom: 15px;"></div>
        </el-row>
        <el-row :gutter="30">
          <el-col :span="8" :sm="12">
            <span class="field_title yield">Final Yield:</span>
            <span class="field_value yield">
              {{lot.yield}}
              <BaseUnitOfMeasure :unit="formula.unit_of_measure"/>
            </span>
            &nbsp; &nbsp;
          </el-col>
          <el-col :span="4" :sm="12">
            <b>Containers:</b> {{lot.yield_container_count}}
          </el-col>
          <el-col :span="12" :sm="24">
            <b>Note:</b>
            <div>{{ lot.note }}</div>
          </el-col>
        </el-row>

        <el-row v-if="notesTableData.length">
          <el-col class="mt-20" :xs="24" :sm="24" :md="24" :lg="24" :xl="24" >
            <h4>
              <i style="margin-right: .5em" class="fal fa-sticky-note"></i>
              <span>Notes</span>
            </h4>
            <div v-for="note in notesTableData" :key="note">
              <el-divider />
              <div class="">
                <b>{{ note.date_created | moment('YYYY-MM-DD') }}</b> |
                <b>{{ note.author.name || note.author.user_name || note.author.display_name}}</b>
              </div>
              {{note.note_text}}
            </div>
          </el-col>
        </el-row>


        <el-row>
          <div style="margin-top: 15px;"></div>
        </el-row>
      </div>
    </el-card>

    <el-dialog title="Rework Notes" :visible.sync="dialogReworkVisible">
      <el-form :model="form">
        <el-form-item label="Reason">
          <el-input type="textarea" v-model="form.rework_reason" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogReworkVisible = false">Cancel</el-button>
        <el-button
          type="primary"
          @click="save_lot({status: 'rework', workflow: { task_note: form.rework_reason || 'Needs changes', task_type: 'lot_rework', rework_reason: form.rework_reason } }); dialogReworkVisible = false"
        >Confirm</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
const uuidv4 = require("uuid/v4");
import NProgress from "nprogress";
import Common from "@/common.js";
import moment from 'moment'
import elabmath from '@/utils/calculate_quantity_required.js'
import EventService from "@/services/EventService.js";
const uuid_base64 = require("uuid-base64");
import lot_store from "@/store/modules/lot.js";
import detailspart from '../formula/parts/details'
import global_store from "@/store/store";
import packstatCalculations from '@/components/task/task_template/packstat_calculations.vue'
import _ from 'lodash'

const render_lot_barcode = (identifier) => {
  document.getElementById(
      'lot_barcode_png'
  ).src = `https://bwipjs-api.metafloor.com/?bcid=datamatrix&text=${identifier}`
}

const scanned_code_listener = function(e) {
  console.log("caught code:", e);
  NProgress.start();
  EventService.getInventory(e.detail.code)
    .then((result) => {
      if (result.success && result.data.ingredient_identifier) {
        //let lot = this.$store.getters['lot/getLot']
        let filter_result = this.formula.ingredients.filter(
          ingredient =>
            ingredient.identifier.toLowerCase() ==
            result.data.ingredient_identifier.toLowerCase()
        );
        if (filter_result.length) {
          console.log(
            `416:  ${result.data.ingredient_identifier.toLowerCase()}`
          );
          this.$store.dispatch("lot/ingredient_check_in_and_weigh", {
            ingredient_identifier: result.data.ingredient_identifier.toLowerCase(),
            lot: result.data.data
          });
        } else {
          const notification = {
            type: "warning",
            message: "Incorrect ingredient scanned!"
          };
          this.$store.dispatch("notification/add", notification, {
            root: true
          });
        }
      }
    })
    .then(() => NProgress.done())
    .catch(() => {
      NProgress.done();
    });
};

const createFreshLotObject = function(lot_task) {
  console.log("lot_task", lot_task);
  let current_user =
      global_store.getters["user/get_user"] || global_store.getters["get_user"];

  let formula_type = { tasks: [] };

  let formula_type_identifier = lot_task.formula.data.formula_type;
  if (!global_store.getters["formula/get_lab_config"]) {
    global_store.dispatch("formula/fetchLabConfig");
  }
  if (global_store.getters["formula/get_lab_config"]) {
    console.log("getting lab config ");
    formula_type = {
      ...{ tasks: [], instructions: "" },
      ...global_store.getters["formula/get_lab_config"]["config"][
          "formula_types"
          ][formula_type_identifier]
    };
  }

  console.log("formula_type", formula_type);

  let formula_tasks = [...lot_task.formula.data.tasks, ...formula_type.tasks];
  let formula = {
    ...lot_task.formula.data,
    ...{ formula_level_tasks: true, tasks: formula_tasks }
  };

  return {
    identifier: uuidv4(),
    author: current_user.name,
    verified_by: "",
    date_verified: "",
    status: "pending",
    location: "",
    task_id_create_lot: lot_task.task.id,
    lot_id: lot_task.task.id,
    lot_number: lot_task.task.id,
    date_created: new Date(),
    date_compounded: new Date(),
    options: {},
    note: "",
    formula: formula,
    quantity:
        typeof lot_task.task.data.lot == "object"
            ? lot_task.task.data.lot.quantity
            : undefined,
    ingredients_used: {
      // example:
      // estradiol_powder (actually the identifier for it, 'ab4513c-2801ef'):
      //     { lots: {
      //         '135edf-01abc': {quantity: 5.5, identifier: ..., beyond_use_date: '2019-06-01'},
      //         '4635df-23c1d': {quantity: 5.5, identifier: }
      //         ...
    },
    saved: false,
    product_identifier: lot_task.formula.data.code,
    lot_type: "formula",
    ingredient_identifier: lot_task.formula.data.identifier
  };
};

export default {
  props: {
    task: Object,
    printView: Boolean,
  },
  emits: ['readyPrint'],
  data() {
    return {
      budChangeVisible: false,
      BudToOverride: null,
      task_mutatable: this.task,
      has_activity_factor_ingredient: false,
      has_pack_stat_ingredient: false,
      activity_factor_difference: {},
      pack_stat_fill: {},
      pack_stat_unit_name: '',
      activity_factor_modifying_ingredient: {},
      pack_stat_excipient_ingredient: {},
      options: {},
      show_instructions: true,
      subtasks: [],
      formula_tasks_all: [],
      categories: this.$store.state.categories,
      form: {
        ingredient_scan_mode: false,
        scan_code: "",
        manual_measurement: ""
      },
      quantity_muliplier: 0,
      dialogReworkVisible: false,
      task_document_categories: [
        {
          label:'Photo',
          value:'photo'
        }
      ],
      //formula: this.createFreshFormulaObject()
    };
  },
  components: {
    detailspart,
    packstatCalculations
  },
  watch: {
    printView() {
      if (this.printView) {
        const self = this
        window.setTimeout(function() {
          self.$emit('readyPrint')
        }, 50)
      }
    }
  },
  computed: {
    decimal_places() {
      return _.get(this.$store.getters['formula/get_lab_config'].config, 'lot.decimal_places', 3)
    },
    users_worked_on_lot() {
      let users_worked_on_lot = []
      if (this.lot.session_data) {
        this.lot.session_data.forEach((session, index) => {
          if (_.indexOf(users_worked_on_lot, session.user) === -1) {
            users_worked_on_lot.push(session.user)
          }
        })
      }
      return _.uniqBy(users_worked_on_lot, 'name')
      //return users_worked_on_lot
    },
    preparation_environment_type() {
      let environment_types = this.$store.getters["terminology/compound"][
        "preparation_environment_type"
      ];
      if (!environment_types) {
        this.$store.dispatch("terminology/fetchCompoundTerminologies");
        environment_types = this.$store.getters["terminology/compound"][
          "preparation_environment_type"
        ];
      }
      return environment_types;
    },
    preparationEnvText: {
      get(){

        let preparationEnvTypeObj = this.lot.preparation_environment;
        let result ="";
        if(preparationEnvTypeObj){
          result += (parseInt(preparationEnvTypeObj.hazardous, 10) === 0) ? "Non-hazardous" : "Hazardous";
          result += (parseInt(preparationEnvTypeObj.sterile, 10) === 0) ? " / Non-sterile" : " / Sterile";
          if (preparationEnvTypeObj.environment_not_set && this.lot.preparation_environment_type === '01') {
            result = this.preparation_environment_type && this.preparation_environment_type[this.lot.preparation_environment_type].description
          }
        }
        return result

      }
    },
    routes_of_administration() {
      let routes_of_administration = this.$store.getters["terminology/terminology"][
        "route_of_administration"
      ];
      if (!routes_of_administration) {
        this.$store.dispatch("terminology/fetchMedicationTerminologies");
        routes_of_administration = this.$store.getters["terminology/terminology"][
          "route_of_administration"
        ];
      }
      let routes_of_administration_mapped = {}
      routes_of_administration.forEach((route) => {
        routes_of_administration_mapped[route.cid] = route.display_text
      })
      return routes_of_administration_mapped;
    },
    route_of_administration_text() {
      return this.routes_of_administration[this.lot.formula.route_of_administration]
    },
    // formula: {
    //   get() {
    //     return this.$store.getters['formula/get_formula']
    //   }
    // },
    equipment_list: {
      get() {
        return this.$store.getters["equipment/get_equipment_list_checked_out"];
      }
    },
    formula: {
      get() {
        //return { options: {} }
        return this.task_mutatable.formula.data || { options: {} };
      }
    },
    notesTableData(){
      let data = [];
      let result = data.concat(this.lot.lot_notes);
      return result;
    },
    formula_type: {
      get() {
        if (this.lot && this.lot.formula_type_data) {
          console.log('using local formula type data')
          return this.lot.formula_type_data
        }
        if (!this.$store.getters["formula/get_lab_config"]) {
          this.$store.dispatch("formula/fetchLabConfig");
        }
        console.log(this.$store.getters["formula/get_lab_config"]);
        if (this.$store.getters["formula/get_lab_config"]) {
          console.log("getting types");
          return (
            this.$store.getters["formula/get_lab_config"]["config"][
              "formula_types"
            ][this.formula.formula_type] || { id: this.formula.formula_type }
          );
        } else return {};
      }
    },
    related_task: {
      get() {
        return this.$store.getters["task/queryTasks"];
      }
    },
    balance: {
      get() {
        return this.$store.getters["equipment/get_balance_state"];
      }
    },
    current_ingredient: {
      get() {
        return this.$store.getters["lot/getCurrentIngredientLot"];
      }
    },
    lot: {
      get() {
        // should be this but lot.js needs work:
        // return this.$store.getters['lot/queryTasks']
        if (
          !this.task_mutatable.lot ||
          (Object.entries(this.task_mutatable.lot).length === 0 &&
            this.task_mutatable.lot.constructor === Object)
        ) {
          console.log("getting lot task:", this.task_mutatable);
          let task_lot = createFreshLotObject(this.task_mutatable);
          console.log("new task lot:", task_lot);
          if (!task_lot.formula.formula_level_tasks) {
            let lot_formula = {
              ...task_lot.formula,
              ...{
                formula_level_tasks: true,
                tasks: [...task_lot.formula.tasks, ...this.formula_type.tasks]
              }
            };
            task_lot = { ...task_lot, ...{ formula: lot_formula } };
          }

          this.task_mutatable = {
            ...this.task_mutatable,
            ...{ lot: task_lot }
          };
          this.$store.dispatch("lot/setFormulaLot", task_lot);
        }

        let returned_lot = {
          ...{ formula: { tasks: [] } },
          ...(typeof this.$store.getters["lot/getLot"] == "object"
            ? this.$store.getters["lot/getLot"]
            : {})
        };
        console.log("returned_lot:", returned_lot);

        if (!returned_lot.formula.formula_level_tasks) {
          let lot_formula = {
            ...returned_lot.formula,
            ...{
              formula_level_tasks: true,
              tasks: [...returned_lot.formula.tasks, ...this.formula_type.tasks]
            }
          };
          returned_lot = { ...returned_lot, ...{ formula: lot_formula } };

          //returned_lot = {...returned_lot, ...{formula: {formula_level_tasks: true, tasks: [...returned_lot.formula.tasks, ...this.formula_type.tasks]}} } ;
        } else {
          this.task_mutatable = {
            ...this.task_mutatable,
            ...{ lot: returned_lot }
          };
        }

        return returned_lot;
      },
      set(new_data) {
        let new_object = { ...this.task_mutatable.lot, ...new_data };
        this.$store.dispatch("lot/setFormulaLot", new_object).then(data => {
          console.log("setFormulaLot");
        });
      }
    },
    lot_tasks: {
      get() {
        let sub_tasks = this.$store.getters["lot/getLot"].formula.data.tasks;
      }
    }
  },
  methods: {
    moment_utc(date) {
      if (date) {
        return moment.utc(date).local().format('YYYY-MM-DD')
      }
      return ''
    },
    uniqueby(arr, prop) {
      return _.uniqBy(arr, prop)
    },
    space_remaining(pack_stat_fill) {
      let space = 100
      if (pack_stat_fill) {
        Object.values(pack_stat_fill).forEach(item => {
          space -= item
        })
      }

      return space
    },
    is_picture(mime_type) {
      const picture_types = [
          'image/gif',
          'image/jpeg',
          'image/png'
      ]
      return picture_types.includes(mime_type)
    },
    get_document_url(identifier, args={}) {
      return Common.get_document_url(identifier, args);
    },
    ChangeBud() {
      this.budChangeVisible = false;
      this.calculated_beyond_use_date(true);
    },
    go_to_task(task_id) {
      console.log("goto", task_id);
      if (task_id) {
        this.$router.push({ name: "task-view", params: { id: task_id } });
      }
      console.log("goto-end", task_id);
    },
    view_mode() {
      let mode =
        this.lot.status == "pending" || this.lot.status == "rework"
          ? "pending"
          : "readonly";
      return mode;
    },
    view_mode_permissions() {
      const current_user = this.$store.getters["account/profile"];
      console.log("this.lot.author", this.lot.author);
      console.log(
        "this.task_mutatable.lot.author",
        this.task_mutatable.lot.author
      );
      console.log("current_user", current_user);
      if (this.lot.author == current_user.name) {
        return false;
      } else if (current_user.user) {
      } else {
        return true;
      }
    },
    switch_ingredient_mode() {
      console.log(365, this.form.ingredient_scan_mode);
      if (this.form.ingredient_scan_mode) {
        this.$nextTick(() => this.$refs.form_scan_code.focus());
        // document.addEventListener('scanned-code', e => {
        //   console.log('scanned-code', e)
        // })
      }
    },
    format_time_quantity(times) {
      const begin_time = new moment(times[0])
      const end_time = new moment(times[1])
      const duration = moment.duration(begin_time.diff(end_time))
      return duration.humanize()
    },
    utilize_scan_code() {
      // this is for ingredients (or formulas as ingredients)
      if (this.form.scan_code.length > 21) {
        let decompressed_scan = uuid_base64.decode(this.form.scan_code);

        this.$store
          .dispatch("lot/getIngredientInventory", decompressed_scan)
          .then(data => {
            if (data == undefined) {
              data = this.$store.getters["lot/getCurrentIngredientLot"];
              // console.log("416 data from getter", data);
            }
            // console.log("lot:", this.lot);
            // console.log("this.lot.ingredients_used", this.lot.ingredients_used);
            //see if ingredient is valid in formula:
            if (
              !this.lot.formula.ingredients.some((tmp_ingredient) => {
                // console.log('tmp_ingredient.identifier: ', tmp_ingredient.identifier)
                return (
                  tmp_ingredient.identifier == data.data.ingredient_identifier
                );
              })
            ) {
              // console.log('invalid ingredient: ', data)
              // console.log('data.data.ingredient_identifier: ', data.data.ingredient_identifier)
              // console.log({ingredients: this.lot.formula.ingredients})

              this.form.scan_code = "";
              this.$refs.form_scan_code.focus();
              let error_notification = {
                type: "error",
                icon: "fal fa-hand-paper",
                message:
                  "The scanned ingredient does not belong to this formula"
              };

              this.$store.dispatch("notification/add", error_notification, {
                root: true
              });
            }
            // IMPORTANT NOTE: nearly identical code exists in store/modules/lot.js.  Not sure it should be here too...
            if (this.lot.ingredients_used[data.ingredient_identifier]) {
              if (
                this.lot.ingredients_used[data.ingredient_identifier].lots[
                  data.identifier
                ]
              ) {
                console.log(`just set active ingredient`);
              } else {
                this.lot.ingredients_used[data.ingredient_identifier].lots[
                  data.identifier
                ] = data;
              }
            } else {
              let lots = {};
              if (data.identifier) {
                // hack to avoid undefined
                lots[data.identifier] = data;
                // TODO ~ could use Object.defineProperty
                let new_data = {};
                new_data[data.ingredient_identifier] = { lots: lots };
                // [TODO?] should probably be:
                this.lot.ingredients_used = {
                  ...this.lot.ingredients_used,
                  ...new_data
                };
                // was:
                // this.lot.ingredients_used[data.ingredient_identifier] = {
                //   lots: lots
                // };
              }
            }
            console.log(this.lot.ingredients_used);
            this.form.scan_code = "";
            this.$refs.form_scan_code.focus();
          });
      }
    },
    open_label() {
      let label_url = Common.get_lot_label_url(this.lot, this.lot["formula"], this.$store.getters['formula/get_lab_config']);
      console.log(label_url);
      return label_url;
      // //let label_url = "";
      // let compressed_scan = uuid_base64.encode(this.lot.identifier);
      // let p_demo = {
      //   lot_b64: compressed_scan,
      //   product: this.formula.identifiers.NDC || "21400221223", // NDC
      //   product_description: this.formula.name,
      //   verified_by: this.lot.approved_by || this.lot.verified_by || "n/a",
      //   beyond_use_date: this.$moment(this.calculated_beyond_use_date()).format(
      //     "YYYY-MM-DD"
      //   ),
      //   date_compounded: this.$moment(this.lot.date_compounded).format(
      //     "YYYY-MM-DD"
      //   ),
      //   location: this.formula.location,
      //   lot_id: this.lot.lot_id
      // };
      // label_url =
      //   `/print_lot_label.html?` +
      //   Object.keys(p_demo)
      //     .map((k) => {
      //       return encodeURIComponent(k) + "=" + encodeURIComponent(p_demo[k]);
      //     })
      //     .join("&");

      // //this.$store.getters['lot/generate_lot_label_url']
      // window.open(label_url, "_blank");
    },
    calculate_quantity_required(ingredient) {
      const args = {
        ingredient,
        quantity_muliplier: this.quantity_muliplier,
        activity_factor_difference: this.activity_factor_difference,
        pack_stat_fill: this.pack_stat_fill,
        has_activity_factor_ingredient: this.has_activity_factor_ingredient,
        lot: this.lot,
        task_mutatable: this.task_mutatable,
        formula: this.formula,
        has_pack_stat_ingredient: this.has_pack_stat_ingredient
      }

      const results = elabmath.calculate_quantity_required(args)

      this.quantity_muliplier = results.quantity_muliplier
      this.activity_factor_difference = results.activity_factor_difference
      this.pack_stat_fill = results.pack_stat_fill
      this.lot.pack_stat_fill = results.pack_stat_fill
      this.lot.formula.activity_factor_modifying_ingredient = results.lot.formula.activity_factor_modifying_ingredient
      if (results.messages.length) {
        console.log('messages', results.messages)
      }
      return results.quantity
    },
    calculate_total() {
      let total_quantity = 0;
      const total_by_unit = {}
      const ingredient_units = {}
      this.lot.formula.ingredients.forEach((ingredient_entry) => {
        ingredient_units[ingredient_entry.identifier] = ingredient_entry.formula_unit_of_measure
      })
      Object.entries(this.lot.ingredients_used).forEach(([key, ingredient]) => {
        //      this.lot.ingredients_used.forEach((ingredient) => {
        let amount = this.calculate_ingredient_used_quantity({
          identifier: key
        });
        //this.calculate_ingredient_used_quantity(ingredient)



        if (ingredient_units[key]) {
          // avoid null + number = NaN:
          if (total_by_unit[ingredient_units[key]]) {
            total_by_unit[ingredient_units[key]] += amount
          } else {
            total_by_unit[ingredient_units[key]] = amount
          }
        }
      });
      // grams only: C48155
      // console.log({total_by_unit})
      // TODO: [EL-366] display total by units on worksheet instead of just weight
      // return total_by_unit
      const total = parseFloat(total_by_unit['C48155']).toFixed(this.decimal_places)
      return total === 'NaN' ? 'N/A' : total
    },
    calculated_beyond_use_date() {
      let return_value;
      if (this.lot.beyond_use_date_override) {
          return_value = new Date(this.lot.beyond_use_date_override)
      } else {
        let max_days = this.formula.options.stability_span || 180;
        let today = new Date();

        // function addDays(date, days) {
        //   let result = new Date(date);
        //   result.setDate(result.getDate() + parseInt(days))
        //   return result;
        // }

        let nearest_date = new Date().setDate(
        ( this.lot.date_compounded ? new Date(this.lot.date_compounded).getDate() : today.getDate()) + parseInt(max_days)        );
        // for (let ingredient of this.lot.ingredients_used) {
        // console.log(568, this.lot.ingredients_used);
        for (const [ingredient_identifier, ingredient] of Object.entries(
          this.lot.ingredients_used
        )) {
          // console.log(571, ingredient.lots);
          if (ingredient.lots) {
            for (const [lot_identifier, ingredient_lot] of Object.entries(
              ingredient.lots
            )) {
              // console.log(575, lot_identifier, ingredient_lot);
              // re-enable for debug:
              // console.log("ingredient_lot.data.inventory.beyond_use_date, nearest_date");
              // console.log(ingredient_lot.data.inventory.beyond_use_date, nearest_date)

              if (nearest_date > new Date(ingredient_lot.data.inventory.beyond_use_date)) {
                nearest_date = new Date(ingredient_lot.data.inventory.beyond_use_date);
              }
            }
          }
        }
        return_value = new Date(nearest_date);
     }
      if (this.BudToOverride != null && this.BudToOverride != "") {
        if (this.BudToOverride != return_value) {
          return_value = this.BudToOverride;
          this.lot.beyond_use_date_override = this.BudToOverride
        }
      }
      return return_value;
    },
    calculate_ingredient_used_quantity(ingredient_data) {
      let total_quantity = 0;
      if (this.lot.ingredients_used[ingredient_data.identifier]) {
        // for (let ingredient_lot of this.lot.ingredients_used[
        //   ingredient_data.identifier
        // ].lots) {

        for (const [lot_identifier, ingredient_lot] of Object.entries(
          this.lot.ingredients_used[ingredient_data.identifier].lots
        )) {
          total_quantity += parseFloat(ingredient_lot.quantity) || 0;
        }
      }
      return total_quantity;
    },
    calculate_ingredient_in_range(ingredient) {
      if (ingredient.requires_measurement_check) {
        ingredient.is_in_range = ingredient.measurement_checked;
        return ingredient.is_in_range;
      } else if (ingredient.quantity_sufficient_indicator) {
        let quantity_current_qs = this.calculate_ingredient_used_quantity({
          identifier: ingredient.identifier
        });

        ingredient.is_in_range = quantity_current_qs > 0;
        return ingredient.is_in_range;
      } else {
        const inRange = (value, min, max) => {
          return (value - min) * (value - max) <= 0;
        }
        let quantity_current = this.calculate_ingredient_used_quantity({
          identifier: ingredient.identifier
        });
        let quantity_current_with_weighing = this.calculate_ingredient_current_quantity(
          ingredient
        );
        let quantity_required = this.calculate_quantity_required(
          ingredient
        );
        let range_allowance = parseFloat(
            "undefined" !=
              typeof this.lot.ingredients_used[ingredient.identifier] &&
              this.lot.ingredients_used[ingredient.identifier]
                .balance_variance_percentage
              ? this.lot.ingredients_used[ingredient.identifier]
                  .balance_variance_percentage
              : 0
          ) / 100;

        let is_in_range = inRange(
          ingredient.identifier == this.current_ingredient.ingredient_identifier
            ? quantity_current_with_weighing
            : quantity_current,
          quantity_required - quantity_required * range_allowance,
          quantity_required + quantity_required * range_allowance
        );
        ingredient.is_in_range = is_in_range;
        ingredient.quantity_needed =
          parseFloat(quantity_required) - parseFloat(quantity_current);
        return is_in_range;
      }
    },
    calculate_ingredient_percent_complete(ingredient) {
      if (parseFloat(ingredient.quantity) > 0) {
        let required_qty = parseFloat(
          this.calculate_quantity_required(ingredient)
        );

        let sum_qty = this.calculate_ingredient_current_quantity(ingredient);

        let percentage_qty = 100 * (sum_qty / required_qty);

        return parseInt(percentage_qty);
      } else {
        return 0;
      }
    },
    calculate_ingredient_current_quantity(ingredient) {
      let current_qty = parseFloat(
        this.calculate_ingredient_used_quantity({
          identifier: ingredient.identifier
        }) || 0
      );

      let balance_qty =
        ingredient.identifier == this.current_ingredient.ingredient_identifier
          ? parseFloat(this.balance.weight || 0)
          : 0;
      let sum_qty = parseFloat(current_qty) + parseFloat(balance_qty);

      return sum_qty;
    },
    record_ingredient_weight(measurement = {}) {
      console.log("record_ingredient_weight", measurement);
      this.$store.dispatch("lot/record_ingredient_weight", measurement);
      if (this.form.manual_measurement) {
        this.form.manual_measurement = "";
      }
    },
    remove_log_measurement(measurement) {
      console.log("remove_log_measurement", measurement);
      if (measurement.log.archived == true) {
        return;
      }
      // console.log("this.lot.ingredients_used", this.lot.ingredients_used);

      let new_current_quantity =
        this.lot.ingredients_used[measurement.ingredient_identifier].lots[
          measurement.log.lot_identifier
        ].quantity - measurement.log.weight;

      // mark archived
      measurement.log.archived = true;
      let new_data = {
        ingredient_identifier: measurement.ingredient_identifier,
        lot_identifier: measurement.log.lot_identifier,
        quantity: new_current_quantity
      };
      // console.log("remove_log: dispatching", `${new Date().getUTCSeconds()} : ${new Date().getUTCMilliseconds()}`);

      this.$store.dispatch("lot/set_ingredient_weight", new_data);
    },
    log_measurement_reviewer(ingredient, checked = true) {
      console.log("log_measurement_reviewer", {
        ingredient: ingredient,
        arg: checked,
        lot: this.task_mutatable.lot
      });
      if (checked) {
        this.$prompt("Please scan checking pharmacist barcode", "Scan", {
          confirmButtonText: "Check",
          cancelButtonText: "Cancel",
          inputErrorMessage: "Invalid code"
        })
          .then(({ value }) => {
            EventService.fetchAuthenticationForBarcode({ key: value }).then(
              (result) => {
                if (result.data.success) {
                  // if logging in manually: const user = this.$store.getters["account/profile"]
                  const user = result.data.data.user;
                  ingredient.measurement_checked = true;
                  Vue.set(ingredient, "measurement_checked", true);
                  ingredient.measurement_checked_by = user;
                  ingredient.date_measurement_checked = new Date().toJSON();
                  this.$message({
                    type: "success",
                    message: "Measurement check recorded"
                  });
                } else {
                  Vue.set(ingredient, "measurement_checked", false);
                  ingredient.measurement_checked = false;
                  ingredient.measurement_checked_by = null;
                  ingredient.date_measurement_checked = null;
                  this.$message({
                    type: "error",
                    message: "Invalid Code"
                  });
                }
              }
            );
          })
          .catch(() => {
            Vue.set(ingredient, "measurement_checked", false);
            ingredient.measurement_checked = false;
            ingredient.measurement_checked_by = null;
            ingredient.date_measurement_checked = null;

            this.$message({
              type: "info",
              message: "Check canceled"
            });
          });
      } else {
        Vue.set(ingredient, "measurement_checked", false);
        ingredient.measurement_checked = false;
        ingredient.measurement_checked_by = null;
        ingredient.date_measurement_checked = null;
      }
    },
    set_subtask_status(lot_task, lot_task_index) {
      let completed = lot_task.complete;
      let task = {};
      console.log("set_subtask_status", lot_task, completed);
      if (completed) {
        const user = this.$store.getters["account/profile"];

        task.date_completed = new Date().toJSON();
        task.user = user;
        task.status = "complete";
        task.complete = true;
      } else {
        task.date_completed = null;
        task.user = null;
        task.status = "new";
        task.complete = false;
      }
      lot_task = { ...lot_task, ...task };
      let new_lot_data = this.lot;
      new_lot_data.formula.tasks[lot_task_index] = lot_task;
      this.lot = new_lot_data;
    },
    set_subtask_weights(lot_task, lot_task_index, weight_to_record) {
      let task = { ...lot_task };
      if (
        typeof weight_to_record === "undefined" ||
        weight_to_record === null
      ) {
        weight_to_record = this.subtasks[lot_task_index].new_weight;
      }
      // if (! this.subtasks[lot_task_index] ) { this.subtasks[lot_task_index] = {} }
      task.weights.push(weight_to_record);
      this.subtasks[lot_task_index].new_weight = ""; // clear
      lot_task = { ...lot_task, ...task };
      let new_lot_data = { ...this.lot };
      new_lot_data.formula.tasks[lot_task_index] = lot_task;
      this.lot = new_lot_data;
    },

    select_balance(balance) {
      console.log(balance);
    },

    add_ingredient(selected_ingredient) {
      // this.form.new_ingredient = selected_ingredient
      this.formula.ingredients.push(selected_ingredient);
      //selected_ingredient = {}
      this.form.new_ingredient.description = "";
      return;
    },
    remove_ingredient_lot(ingredient_lot) {
      console.log("remove ing", ingredient_lot);
      console.log("this.lot", this.lot);
      let new_ingredients_used = this.lot.ingredients_used;
      delete new_ingredients_used[ingredient_lot.ingredient_identifier].lots[
        ingredient_lot.identifier
      ];
      // edit object directly because we must delete from it
      let new_data = {
        ...this.lot,
        ...{ ingredients_used: new_ingredients_used }
      };
      // but first edit locally because we are not properly using vuex yet:
      this.lot = new_data;
      this.$store.dispatch("lot/reset_current_ingredient_lot");
      this.$store.dispatch("lot/setFormulaLot", new_data).then(data => {
        console.log("setFormulaLot");
      });

      // this.formula.ingredients.splice(ingredient_index, 1);
    },

    save_lot(args) {
      //      this.lot = args;
      console.log("store list", this.equipment_list);
      console.log("lot list", this.lot.equipment_list);
      let equipment_list_to_save = [...(this.equipment_list || [])];
      let lot_data = {
        ...this.lot,
        ...args,
        ...{
          beyond_use_date: this.$moment(
            this.calculated_beyond_use_date()
          ).format("YYYY-MM-DD"),
          // this would reset the formula each time when it should be _static_:
          //          formula: this.formula,
          current_task_id: this.task_mutatable.task.id,
          //          lot_id: this.task.task.id,
          //          ingredient_identifier: this.formula.identifier,
          //          product_identifier: this.formula.identifiers.formula_id,
          //          lot_type: "formula",
          inventory: {
            quantity: this.lot.yield,
            containers: this.lot.yield_container_count,
            beyond_use_date: this.$moment(
              this.calculated_beyond_use_date()
            ).format()
          }
        },
        ...{
          product_identifier: this.lot.formula.code,
          lot_type: "formula",
          ingredient_identifier: this.lot.formula.identifier,
          equipment_list: equipment_list_to_save
        }
      };
      // if not already approved:
      if (lot_data.status == "approved" && this.lot.status != "approved") {
        lot_data.verified_by = this.$store.getters["account/profile"];
        // let database do this: lot_data.date_verified=this.$moment().format()
      } else if (
        lot_data.status == "cancelled" &&
        this.lot.status != "approved"
      ) {
        lot_data.cancelled_by = this.$store.getters["account/profile"];
        // let database do this: lot_data.date_verified=this.$moment().format()
      }

      console.log("save_lot - 944", lot_data);
      this.record_entry(lot_data);
    },
    record_entry(lot_data) {
      // see how createEvent in event.js works:
      // an action gets dispatched, which performs a POST, inside of which a commit is made
      console.log(lot_data);
      NProgress.start();
      this.$store
        .dispatch("lot/saveLot", lot_data)
        .then(() => {
          // this.product = this.createFreshEventObject()
          // this.form = this.createFreshEventObject()
          lot_data.saved = true;
          //this.lot = lot_data;
          console.log("current", this.lot);
          this.$store.dispatch("lot/setFormulaLot", lot_data);
          console.log("after", this.lot);
          // this.$store.dispatch("lot/setFormulaLot", new_object)
        })
        .then(() => NProgress.done())
        .catch(() => {
          NProgress.done();
        });
    },
    reset_balance() {
      this.$store.dispatch("equipment/reset_balance_state");
    },
    complete_task(task_id, task_data) {
      NProgress.start();
      this.$store
        .dispatch("task/complete_task", {
          task_id: task_id,
          task_data: task_data
        })
        .then(() => NProgress.done())
        .catch(() => {
          NProgress.done();
        });
    },
    toggleInstructionVisibility(value) {
      console.log("toggleInstructionVisibility", this.show_instructions);
      console.log("VALUE", value);

      this.show_instructions = value;
      console.log("POST - toggleInstructionVisibility", this.show_instructions);
    },
    toggleReworkVisibility() {
      return;
    }
  },
  created() {
    // this.lot = this.task.lot;
    // set lot:
    if (
      !this.task_mutatable.lot ||
      (Object.entries(this.task_mutatable.lot).length === 0 &&
        this.task_mutatable.lot.constructor === Object)
    ) {
      // console.log('this.task.formula.tasks:', this.task.formula.tasks)
      // console.log('this.formula_type.tasks', this.formula_type.tasks)
      //       console.log('seeting task_lotC:', { ...{formula: {tasks: [...this.task.formula.tasks, ...this.formula_type.tasks]}} })

      // let task_lot = {...createFreshLotObject(this.task), ...{formula: {tasks: [...this.task.lot.formula.tasks, ...this.formula_type.tasks]}} } ;
      // console.log('task_lotC:', task_lot)
      //      task_lot.formula.tasks=[...task_lot.formula.tasks, ...this.formula_type.tasks]
      //    task_lot = {...task_lot, }
      //      task_lot.formula.tasks.push(this.formula_type.tasks)
      this.$store.dispatch(
        "lot/setFormulaLot",
        createFreshLotObject(this.task_mutatable)
      );
    } else {
      this.$store.dispatch("lot/setFormulaLot", this.task_mutatable.lot);
    }
  },
  mounted() {
    // set multiplier:
    render_lot_barcode(this.lot.lot_identifier)
    const self = this
    console.log("lot QTY:", this.lot.quantity);
    console.log("formula makes QTY:", this.formula.formula_quantity);
    this.quantity_muliplier =
      this.lot.quantity / (this.formula.formula_quantity || 1);

    this.formula.ingredients.forEach(ingredient => {
      if (ingredient.has_activity_factor) {
        self.has_activity_factor_ingredient = true
      }
    })
    if (this.formula.pack_stat_required) {
      self.has_pack_stat_ingredient = true
    }
    if (!this.lot.lots_allowed) {
      this.lot.lots_allowed = {}
    }
    if (this.formula.dosage_form === 'C25158') {
      this.pack_stat_unit_name = 'Caps'
    } else if (['C42961', 'C42972', 'C42973', 'C42975', 'C64907', 'C64908', 'C91171'].includes(this.formula.dosage_form)) {
      this.pack_stat_unit_name = 'Scoops'
    }


    // this is now done upon initial loading
    //let all_tasks = [...this.task.lot.formula.tasks, ...this.formula_type.tasks]
    if (
      this.task_mutatable.lot &&
      this.task_mutatable.lot.formula &&
      !this.task_mutatable.lot.formula.formula_level_tasks
    ) {
      let task_lot = {
        ...this.task_mutatable.lot,
        ...{
          formula: {
            formula_level_tasks: true,
            tasks: [
              ...this.task_mutatable.lot.formula.tasks,
              ...this.formula_type.tasks
            ]
          }
        }
      };
      let lot_formula = {
        ...this.task_mutatable.lot.formula,
        ...{
          formula_level_tasks: true,
          tasks: [...task_lot.formula.tasks, ...this.formula_type.tasks]
        }
      };
      task_lot = { ...task_lot, ...{ formula: lot_formula } };

      this.$store.dispatch("lot/setFormulaLot", task_lot);
    }
    let all_tasks = [...this.task_mutatable.lot.formula.tasks];
    all_tasks.forEach((element) => {
      this.formula_tasks_all.push(element);
      this.subtasks.push({
        new_weight: "",
        new_measurement: {},
        new_image: {}
      });
    });
    if (this.task_id) {
      // listen for scanned-code event
      document.addEventListener("scanned-code", scanned_code_listener);
      // EventService.getInventory(decompressed_scan)
      this.$store.dispatch("task/setQueryFilters", { task_id: this.task_id });
    }
    // making the default value for the picker that changes the bud
    this.BudToOverride = this.calculated_beyond_use_date();
  },
  beforeDestroy() {
    // remove listener for barcode-scan event
    this.$store.dispatch("lot/reset_lot");
    document.removeEventListener("scanned-code", scanned_code_listener, false);
    this.$store.dispatch("equipment/clear_checked_out_equipment");
    this.$store.dispatch("equipment/reset_balance_state");

    // EventService.getInventory(decompressed_scan)
  }
};
</script>

<style scoped>
.location {
  margin-bottom: 0;
}
.location > .icon {
  margin-left: 10px;
}
.indicators {
  font-size: 0.8em;
}

.indicator {
  margin-right: 1em;
}

.lot-info a:hover {
    color: #409EFF !important;
}

.event-header > .title {
  margin: 0;
}
.list-group {
  margin: 0;
  padding: 0;
  list-style: none;
}
.list-group > .list-item {
  padding: 1em 0;
  border-bottom: solid 1px #e5e5e5;
}

.formula-options .el-col {
  margin-top: 4px;
}

.auxiallary_button {
  margin-right: 1.5em;
}

.formula-instructions {
  margin: 1.5em 0;
  white-space: pre-wrap;
}

.button.bottom {
  margin-top: 13px;
  line-height: 12px;
  padding: 0;
  float: right;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}

.status-button {
  margin-right: 1.25em;
}

.formula-status {
  font-size: 0.8em;
  color: #8a8a8a;
  margin: 0 0.5em;
}
.sub_task-instructions {
  display: inline-block;
  min-width: 25em;
  max-width: 80%;
}
.sub_task-points {
  max-width: 8em;
  margin: 0 1em;
}
.ingredient-remove:hover {
  color: rgb(207, 13, 13);
}
.clickable {
  cursor: pointer;
}
.clickable:hover {
  color: #409eff !important;
}
.ingredient-lot-quantity {
  margin-left: 0em;
}
.helper-instructions {
  font-size: 0.8em;
  color: #8a8a8a;
  hyphens: auto;
  overflow-wrap: break-word;
  word-wrap: break-word;
}
.balance-area {
  font-size: 1.8em;
}
.current-ingredient {
  /* text-decoration: underline; */
  font-weight: bold;
  font-size: 1.1em;
}
.current-ingredient-lot {
  color: #39b982;
  font-weight: bold;
}
.quantity_current {
  float: left;
}
.quantity_needed {
  /* float: right;
  margin-right: 4em; */
  text-align: right;
  /* 50px to match el-progress-bar css */
  padding-right: 50px;
}

.ingredient-quantity-required-met {
  color: #39b982;
}
.formula-task-options-label {
  vertical-align: bottom;
}
.item-remove:hover {
  color: rgb(207, 13, 13);
}
/* same as sub-title */
.section-header {
  font-weight: bold;
}
.record-balance-weight-for-ingredient-quantity {
  /* float: right; */
  margin-right: 2em;
  text-align: right;
}

.lot-beyond-use-date {
  /* margin-left: 2em; */
  color: #747474;
  /* text-align: end; */
  text-decoration: none !important;
  font-size: x-small;
  display: block;
}

.lot-capsule-weight-average {
  /* margin-left: 2em; */
  color: #747474;
  /* text-align: end; */
  text-decoration: none !important;
  font-size: x-small;
  display: block;
}


.lot-line {
  padding-top: 0.15em;
  padding-bottom: 0.15em;
  border-top: 1px solid silver;
}
.ingredient-lot-quantity-area {
  margin-left: 0em;
  text-align: end;
}
.ingredient-measurement-line {
  font-weight: bold;
}
.lot-header {
  font-size: 0.85em;
}
.log-header {
  font-size: 0.85em;
}
.measurements-log-area {
  text-align: right;
  color: #747474;
  padding-right: 2em;
}
.strike {
  text-decoration: line-through;
  text-decoration-color: red !important;
}
.remove:hover {
  color: red;
}
.no-break {
  word-break: keep-all;
  word-wrap: break-word;
}
/* .balance .el-progress-bar {
  padding-right: 0px;
} */
.yield { font-size: 1.05em; font-size: 14px; font-weight: bold;}
</style>
<style>
.balance .el-progress-bar {
  /* padding-right: 0px; */
}
.quantity_sufficient.balance .el-progress-bar .el-progress-bar__outer {
  background-color: #0e8dc599;
}
.quantity_sufficient.quantity_needed {
  color: gray;
}
.quantity_sufficient.quantity_needed:before {
  content: "[QS] ";
}
.mt-5 {
  margin-top: 5px;
}

.list-wrap {
  padding:40px;
  border-radius:5px;
  width:400px;
}

div.styled-list {
  counter-reset: list-number;
}
div.styled-list div:before {
  counter-increment: list-number;
  content: counter(list-number);

  margin-right: 0.75em;
  margin-bottom: 0.5em;
  width: 1.5em;
  height: 1.5em;
  display: inline-flex;
  align-items:center;
  justify-content: center;
  font-size:12px;
  font-weight: bold;
  background-color:#0e8ec5;
  border-radius:50%;
  color:#fff;
}
div.el-collapse-item__header {
  height: 48px !important;
  line-height: initial !important;
}
img#lot_barcode_png {
    max-width: 45px;
    margin: 5px;
}
</style>
