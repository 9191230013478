import EquipmentService from "@/services/equipment.js";
import global_store from "@/store/store";
const uuidv4 = require("uuid/v4");

export const namespaced = true;

export const state = {
  equipment: {},
  equipment_list: {},
  equipment_list_checked_out: [],
  balance: {
    description: "",
    unit: "mg",
    weight: 0.0,
    tares: [],
    tare_index: 0,
    message: ""
  },
  current_device: "",
  current_station: "",
  current_printer: {},
  current_transmitter_device_identifier: ""
};

export const mutations = {
  CHECKOUT_EQUIPMENT(state, data) {
    let equipment_record = {
      description: data.description,
      user: global_store.getters["account/profile"].username,
      date_checked_out: new Date().toJSON(),
      identifier: data.identifier,
      equipment_type: data.equipment_type,
      // these should get captured in the event they change later on and must be recorded at the lot-level:
      station_is_hazardous_environment: data.station_is_hazardous_environment,
      station_is_sterile_environment: data.station_is_sterile_environment
    };
    state.equipment_list_checked_out.push(equipment_record);
  },
  CLEAR_CHECKOUT_EQUIPMENT(state) {
    state.equipment_list_checked_out = []
  },
  SET_CHECKOUT_EQUIPMENT(state, data) {
    state.equipment_list_checked_out = data
  },
  SET_WORKING_EQUIPMENT(state, data) {
    state.equipment = data;
  },
  SET_BALANCE_STATE(state, data) {
    state.balance = data;

    if (typeof data.transmitter_device_identifier !== 'undefined') {
      state.current_transmitter_device_identifier = data.transmitter_device_identifier
    }
  },
  SET_BALANCE_STATE_WEIGHT(state, data) {
    // simple and used frequently, this is inteded to be a performance optimization
    console.log("SET_BALANCE_STATE_WEIGHT", data);
    if (state.balance.identifier && typeof data == "object") { // TODO: use _.isObject here, maybe along with an !_.isEmpty as secondary test
      let is_match_transmitter_device_identifier = (data.device_id && state.current_transmitter_device_identifier) && (state.current_transmitter_device_identifier.toLowerCase() === data.device_id.toLowerCase())
      if (is_match_transmitter_device_identifier || !data.device_id || !state.current_transmitter_device_identifier) {
        state.balance.weight = data.weight;
        state.balance.last_updated = data.timestamp_string
        state.balance.allow_recording = data.allow_recording
        if (data.unit && state.balance.unit !== data.unit) {
          state.balance.unit = data.unit;
        }

        if (data.message) {
          state.balance = { ...state.balance, ...{ message: data.message } }
        } else {
          if (state.balance.message) {
            // skip updating the object if we do not need to
            state.balance.message = ""
            state.balance = { ...state.balance, ...{ message: '' } }
          }
        }
      } else {
        console.warn('mis-matched transmitter_device_identifier:',
          { device_id: data.device_id, current_transmitter_device_identifier: state.current_transmitter_device_identifier }
        )
      }
    } else {
      console.warn('invalid balance data',
        data
      )
    }
  },
  RESET_BALANCE_STATE(state, data) {
    state.balance = {
      description: "",
      unit: "mg",
      weight: 0.0,
      tares: [],
      tare_index: 0,
      message: ""
    }
  },
  SET_EQUIPMENT_LIST(state, data) {
    state.equipment_list = data;
  },
  ADD_EQUIPMENT(state, data) {
    let new_object = {};
    new_object[data.identifier] = data;
    state.equipment_list = { ...state.equipment_list, ...new_object };
  },
  SET_CURRENT_DEVICE(state, data) {
    state.current_device = data;
    localStorage.setItem('current_device',data)
  },
  SET_CURRENT_STATION(state, data) {
    state.current_station = data;
    localStorage.setItem('current_station',data)
  },
  SET_CURRENT_PRINTER(state, data) {
    state.current_printer = data;
    localStorage.setItem('checked_out_printer', JSON.stringify(data))
  }

};

export const actions = {

  set_equipment_list_checked_out({ commit, dispatch, state }, list) {
    commit("SET_CHECKOUT_EQUIPMENT", list)
  },
  set_current_device({commit}, data) {
    commit("SET_CURRENT_DEVICE", data)
  },
  set_current_station({commit}, data) {
    commit("SET_CURRENT_STATION", data)
  },
  set_current_printer({commit}, data) {
    commit("SET_CURRENT_PRINTER", data)
  },
  fetchEquipmentList({ commit, dispatch, state }) {
    return EquipmentService.getEquipmentList()
      .then(list => {
        //commit('SET_TASKS_TOTAL', parseInt(response.headers['x-total-count']))
        commit("SET_EQUIPMENT_LIST", list);
        return list;
      })
      .catch(error => {
        const notification = {
          type: "error",
          message:
            "There was a problem fetching equipment list: " + error.message
        };

        dispatch("notification/add", notification, { root: true });
      });
  },
  set_balace_state({ commit }, data) {
    commit("SET_BALANCE_STATE", data);
  },
  set_balace_state_weight({ commit }, data) {
    commit("SET_BALANCE_STATE_WEIGHT", data);
  },
  set_balace_state_tare({ commit, state }) {
    //does this need a getter?  TODO?
    let tares = state.balance.tares;
    let new_tare_index = state.balance.tares.length;
    tares.push(state.balance.weight);
    commit("SET_BALANCE_STATE",
    {
        ...{
            tare_index: new_tare_index,
            tares
        },
        ...state.balance
    });
  },
  reset_balance_state({ commit }, data) {
    commit("SET_BALANCE_STATE", {
      ...{
        unit: "g",
        weight: 0.0,
        tares: [],
        tare_index: 0
      },
      ...data
    });
  },
  checkout_equipment({ commit }, data) {
    if(data.equipment_type) {
      commit("SET_WORKING_EQUIPMENT", data);
      commit("CHECKOUT_EQUIPMENT", data);
      if (data.equipment_type == "balance") {
        actions.reset_balance_state(
          { commit },
          {
            ...{
                description: data.description,
                transmitter_device_identifier: data.transmitter_device_identifier,
                message: '<span style="color: #4dabf7;">Connecting to transmitter... <i class="fas fa-spinner fa-spin" style="color: #4dabf7;"></i></span>'
            },
            ...data
          }
        );
      }
    } else {
      commit("SET_WORKING_EQUIPMENT", data);
      // could check type to see if we should record this or not.
      // maybe a device should get skipped ??
      console.log("disconnecting equipment:", data)
      commit("CHECKOUT_EQUIPMENT", {...{description: 'Disconnect' }, ...data})
      // Explanation: to disconnect a balance we need to "reset" the balance state:
      actions.reset_balance_state(
        { commit },
        {
          transmitter_device_identifier: ''
        }
      );
    }

  },
  clear_checked_out_equipment({ commit }) {
    commit("CLEAR_CHECKOUT_EQUIPMENT");
  },
  addEquipment({ commit }) {
    let new_equipment_object = common_functions.createFreshEquipmentObject()
    commit("ADD_EQUIPMENT", new_equipment_object);
    return new_equipment_object
  },

  saveEquipment({ commit, dispatch }, data) {
    return EquipmentService.saveEquipment(data)
      .then(response => {
      })
      .catch(error => {
        const notification = {
          type: "error",
          message:
            "There was a problem updating equipment list: " + error.message
        };

        dispatch("notification/add", notification, { root: true });
      });
  },
  disconnectEquipmentWs({ commit, dispatch }, data) {
    // unsubscribe the ws
    EquipmentService.unsubscribeWs()
    // reset current ingredient:
    global_store.dispatch("lot/reset_current_ingredient_lot");

    // reseting equipment object in store
    actions.checkout_equipment({commit}, {})
    //reset balance state
    commit("RESET_BALANCE_STATE")
  }
};

export const getters = {
  get_equipment: state => {
    return state.equipment;
  },
  get_equipment_list: state => {
    return state.equipment_list;
  },
  get_current_device: state => {
    let localStorageItem = localStorage.getItem('current_device')
    if(localStorageItem != null){
      if(state.current_device.length == 0 && localStorageItem.length > 0 ){
        // console.log('get_current_device:this',this)
        // store.dispatch('set_current_device', localStorageItem)
        global_store.dispatch("equipment/set_current_device", localStorageItem );

//        commit("SET_CURRENT_DEVICE", localStorageItem)
        return localStorageItem
      }
    }
    return state.current_device;
  },
  get_current_station: state => {
    let localStorageItem = localStorage.getItem('current_station')
    if(localStorageItem != null){
      if(state.current_station.length == 0 && localStorageItem.length > 0 ){
        // actions.set_current_station(localStorageItem)

        // store.dispatch('set_current_station', localStorageItem)
        //        commit("SET_CURRENT_STATION", localStorageItem)
        global_store.dispatch("equipment/set_current_station", localStorageItem );
        return localStorageItem
      }
    }
    return state.current_station;
  },
  get_current_printer: state => {
    return state.current_printer;
  },
  get_equipment_list_checked_out: state => {
    return state.equipment_list_checked_out;
  },
  get_balance_weight: state => {
    return state.balance.weight;
  },
  get_balance_weight_tared: state => {
    if (typeof state.balance.tares == "object" && state.balance.tares.length) {
      let tares_weight = state.balance.tares.reduce((sum, x) => sum + x);
      return state.balance.weight - tares_weight;
    } else {
      return 0.0;
    }
  },

  get_balance_state: state => {
    return state.balance;
  }
};

export const common_functions = {
  createFreshEquipmentObject() {
    let port_to_use = 32000;
    const user = global_store.getters["account/profile"]
    // was global_store.state.user.user;
    if (Object.keys(state.equipment_list).length) {
      port_to_use =
        1 +
        Math.max(
          ...Object.values(state.equipment_list).map(function(item) {
            // if (item.setting) { return parseInt(item.setting.port) || 0 }
            return parseInt(item.port || 0);
          })
        );
    }
    // TODO: connect this to the deeper config that is in services
    return {
      // identifier: uuidv4(),
      // author: user.name,
      // location: "",
      // equipment_type: "",
      // ip_address: "",
      // username: "",
      // password: "",

      // name: "",

      // date_created: new Date().toJSON()

      // "equipment_type": "",
      // "description": "New Equipment",
      // "has_data": false,
      // "config": {
      //   "data_parser": "",
      //   "wss_url": ""
      // },
      // "setting": {
      author: user.name,
      date_created: new Date().toJSON(),
      equipment_type: "balance",
      identifier: uuidv4(),
      ip_address: "",
      location: "",
      name: "New Equipment",
      parser: "",
      balance_variance_percentage: 0.0,
      balance_variance_quantity: 0.0,
      password: "",
      port: port_to_use,
      username: "",
      label_size: '38x90',
      printer_type: 'zpl',
      printer_dpi: 300
      // }
    };
  }
};
