
<template>
<div>
    <el-dialog
    title="Assign Quantities"
    :visible.sync="dialogVisible"
    :before-close="cancelDialog"
    width="30%"
    center>
        <div style="max-height: 25em; overflow-y: scroll; overflow-x: hidden;">
                <el-row :gutter="20">
                    <el-col :span="6">
                        Quantity
                    </el-col>
                    <el-col :span="12">
                        Note
                    </el-col>
                    <el-col :span="6">
                        Remove
                    </el-col>
                </el-row>

            <div v-for="(item, index) in form.label_list" v-bind:key="index">
                <el-row :gutter="20" style="margin-top: 0.75em; border-top: 1px solid silver; padding: 0.5em;">
                    <el-col :span="6">
                        {{ index + 1}} &nbsp;
                        <el-input-number v-model="item.quantity" ref="quantity" placeholder="2.25" clearable :controls="false" style="text-align: right; max-width: 5em;"
                          type="number"
                          inputmode="decimal"></el-input-number>
                    </el-col>
                    <el-col :span="12">
                        <el-input
                            placeholder="Note"
                            ref="reason"
                            v-model="item.note"
                            clearable>
                        </el-input>
                    </el-col>
                    <el-col :span="6">
                        <div style="text-align: center;">
                            <a href="#" @click="remove_label(index)"><i class="fad fa-minus-circle" style="color:#c53f27; margin: 0.65em;"/></a>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" plain @click="add_label()">Add</el-button>
            <el-button @click="cancelDialog()">Cancel</el-button>
            <el-button type="primary" @click="saveLabels()">Confirm</el-button>
        </span>
    </el-dialog>
</div>
</template>

<script>
import NProgress from "nprogress";
import EventService from "@/services/EventService.js";
import Common from "@/common.js";

const uuidv4 = require("uuid/v4");

export default {
    props: {
        labels: {
            required: true,
            type: Array,
            default: () => []
        },
        dialogVisible: {
            type: Boolean,
            required: true
        },
        quantity_unit : {
            required: false
        }
    },
    // watch: {
    //     dialogVisible(oldVal,newVal){
    //         if(oldVal == false){
    //             this.$emit('closing', {
    //             dialogVisible: false
    //         })
    //         }
    //     }
    // },
    data() {
        return {
            form: {
                label_list: this.labels
            }
        };
    },
methods: {
    create_fresh_label() {
        return {
            quantity: 0.0,
            note: '',
            identifier: uuidv4()
        }
    },
    add_label() {
        const new_label = this.create_fresh_label()
        this.form.label_list.push(new_label)
    },
    remove_label(idx) {
        this.form.label_list.splice(idx, 1)
    },
    cancelDialog() {
        console.log('cancelled??')
        this.$emit('close', {
                dialogVisible: false
                // quantity: +this.$refs.quantity.value,
                // reason: +this.$refs.reason.value
            })

    },
    saveLabels() {
        console.log(this.form.label_list)
        this.$emit('input', this.form.label_list)
    }
}

}
</script>

<style>
.input-label {
    font-size: 1em;
}
</style>
