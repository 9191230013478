<template>
  <div>
    <el-card class="box-card formula_search_area">
      <span style="font-size: 1.25em;">Search By:</span>

      <span style="float: right;">
        <el-checkbox v-model="search_removed_inventory">Search Removed</el-checkbox>
        <el-radio-group v-model="search_ingredient_type" size="small" style="margin-left: 15px">
          <el-radio-button label="ingredient">Ingredient</el-radio-button>
          <el-radio-button label="formula">Formula</el-radio-button>
          <el-radio-button label="both">Both</el-radio-button>
        </el-radio-group>
        <el-date-picker
          style="max-width: 18em;margin-left: 15px"
          v-model="dateRange"
          type="daterange"
          range-separator="To"
          start-placeholder="Start date"
          end-placeholder="End date"
          :picker-options="pickerOptions">
        </el-date-picker>
      </span>

      <el-tabs v-model="active_tab">
        <el-tab-pane label="Ingredient" name="ingredient">
          <ingredientSearch :search_removed="search_removed_inventory" :search_ingredient_type="search_ingredient_type" :date_range="dateRange" />
        </el-tab-pane>
        <el-tab-pane label="Purchase Order" name="purchase_order">
          <purchaseOrderSearch :search_removed="search_removed_inventory" :search_ingredient_type="search_ingredient_type" :date_range="dateRange" />
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
import purchaseOrderSearch from './purchase_order_search.vue'
import ingredientSearch from './ingredient_search.vue'

export default {
  components: {
    purchaseOrderSearch,
    ingredientSearch,
  },
  watch: {
    dateRange() {
      if(!this.dateRange) {
        this.dateRange = []
      }
    }
  },
  data() {
    return {
      active_tab: 'ingredient',
      search_removed_inventory: false,
      search_ingredient_type: 'both',
      dateRange: [],
      pickerOptions: {
          shortcuts: [
              {
                  text: 'Last week',
                  onClick(picker) {
                      const end = new Date();
                      const start = new Date();
                      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                      picker.$emit('pick', [start, end]);
                  }
              },
              {
                  text: 'Last month',
                  onClick(picker) {
                      const end = new Date();
                      const start = new Date();
                      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                      picker.$emit('pick', [start, end]);
                  }
              },
              {
                  text: 'Last 3 months',
                  onClick(picker) {
                  const end = new Date();
                  const start = new Date();
                  start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                  picker.$emit('pick', [start, end]);
                  }
              }
          ]
      },
    }
  },
  methods: {
    set_formula_type(type) {
      this.formula_type = type
    }
  }
}
</script>

<style>
.max_width_is_screen {
    max-width: -webkit-fill-available;
  }
</style>
