<template>
  <div class="toolbar flex align-center justify-space-between">
    <div class="box-left box grow flex">
      <button
        @click="toggleSidebar"
        v-if="menuBurger !== 'right'"
        class="toggle-sidebar card-base card-shadow--small"
      >
        <i class="mdi mdi-menu"></i>
      </button>

      <search class="hidden-xs-only"></search>
    </div>
    <div class="box-right flex align-center pl-10">
        <span v-if="device">
          <el-tooltip class="item" effect="dark" :content="device" placement="bottom-end">
            <i  class="fad fa-tablet" style="--fa-primary-color: #13ce66; --fa-secondary-color: dimgray; --fa-secondary-opacity: 0.6;"></i>
          </el-tooltip>

        </span>
        &nbsp;
      <el-dropdown trigger="click" @command="executeTool">

        <span class="el-dropdown-link">
          <i class="far fa-sliders-v"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="zoom-increase">
            <i class="fal fa-search-plus"></i> Zoom +
          </el-dropdown-item>
          <el-dropdown-item command="zoom-decrease">
            <i class="fal fa-search-minus"></i> Zoom -
          </el-dropdown-item>
          <el-dropdown-item command="zoom-reset">
            <i class="fal fa-search"></i> Zoom Reset
          </el-dropdown-item>
          <!-- <el-dropdown-item command="/multi-language">
            <i class="mdi mdi-translate mr-15"></i>Read the docs
          </el-dropdown-item>-->
        </el-dropdown-menu>
      </el-dropdown>
      <button class="fullscreen-button" @click="toggleFullscreen">
        <i class="mdi mdi-fullscreen" v-if="!fullscreen"></i>
        <i class="mdi mdi-fullscreen-exit" v-if="fullscreen"></i>
      </button>
      <el-popover ref="popover" placement="bottom" :width="popoverWidth" trigger="click">
        <notification-box></notification-box>
      </el-popover>
      <el-badge :is-dot="messages.length > 0 " class="notification-icon-badge">
        <el-button v-popover:popover icon="mdi mdi-bell" class="notification-icon"></el-button>
      </el-badge>

      <el-tooltip class="item" effect="dark" placement="bottom" v-if="is_connected_printer">
        <div slot="content">Connected to printer: {{is_connected_printer.description}}</div>
        <el-button type="text" class="notification-icon"><i class="fas fa-print"></i></el-button>
      </el-tooltip>

      <span class="username">{{user.name}}</span>
      <el-dropdown trigger="click" @command="onCommand">
        <span class="el-dropdown-link">
          <img src="../assets/images/avatar-3.jpg" class="avatar" alt="avatar" />
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="/profile">
            <i class="mdi mdi-account mr-10"></i> Profile
          </el-dropdown-item>
          <!-- <el-dropdown-item command="/calendar"><i class="mdi mdi-calendar mr-10"></i> Calendar</el-dropdown-item> -->
          <el-dropdown-item>
              <a href="/app/knowledge_base" target="_blank" style="color:#606266">
                  <i class="mdi mdi-book-multiple mr-10"></i>
              Knowledge Base
              </a>
          </el-dropdown-item>

          <el-dropdown-item command="/logout" divided>
            <i class="mdi mdi-logout mr-10"></i> Logout
          </el-dropdown-item>
          <el-dropdown-item no-command="/log_support_case" divided>
            <LogSupportCase></LogSupportCase>
          </el-dropdown-item>
          <el-dropdown-item v-if="is_connected_balance_id" no-command="/reboot_device" @click="power_cycle_device">
              <i class="fad fa-power-off" style="--fa-primary-color: tomato; --fa-secondary-color: #004085;"/>
              Power Cycle Current Adapter
          </el-dropdown-item>
          <el-dropdown-item v-if="is_connected_printer" no-command="/disconnect_printer">
            <div @click="disconnect_printer">
              <i class="fas fa-print"></i>
              Disconnect Printer
            </div>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>

      <button
        @click="toggleSidebar"
        v-if="menuBurger === 'right'"
        class="toggle-sidebar toggle-sidebar__right card-base card-shadow--small"
      >
        <i class="mdi mdi-menu"></i>
      </button>
    </div>
  </div>
</template>

<script>
import NotificationBox from "@/components/NotificationBox";
import Search from "@/components/Search";
import LogSupportCase from "@/components/log_support_case"
import { mapState } from "vuex";
import EventService from "@/services/EventService.js"

export default {
  name: "Toolbar",
  props: ["menuBurger"],
  data() {
    return {
      popoverWidth: 300,
      fullscreen: false,
      lang: "us"
    };
  },
  computed: {
    ...mapState("notification", ["messages"]),
    ...{
      user: {
        get() {
          return this.$store.getters["user/get_user"] || {};
        }
      },
        device: {
          get() {
                if (this.$store.getters["equipment/get_current_device"]) {
                    return this.$store.getters["equipment/get_current_device"].name
                } else return '';
            }
        }
    },
    is_connected_balance_id: function() {
      if(this.$store.getters['equipment/get_balance_state']) {
        return this.$store.getters['equipment/get_balance_state'].transmitter_device_identifier
      } else return false
    },
    is_connected_printer: function() {
      let checked_out_printer = this.$store.getters["equipment/get_current_printer"]
      if (checked_out_printer && checked_out_printer.identifier) {
        let printer_config = this.$store.getters["equipment/get_equipment_list"][checked_out_printer.identifier]
        return printer_config
      } else return false
    }
},
  methods: {
    onCommandLang(lang) {
      console.log('lang', lang)
      if (lang.charAt(0) === "/") this.onCommand(lang);
      else this.lang = lang;
    },
    executeTool(arg) {
      console.log({ arg });
      let zoom_level = parseFloat(
        document.querySelector("#scroll-top").style.zoom || 1
      );
      if (arg == "zoom-increase") {
        console.log(`increasing...`);
        document.querySelector("#scroll-top").style.zoom = zoom_level + 0.2;
        console.log(
          `increasing...${document.querySelector("#scroll-top").style.zoom ||
            1}`
        );
      }
      if (arg == "zoom-decrease") {
        document.querySelector("#scroll-top").style.zoom = zoom_level - 0.2;
      }
      if (arg == "zoom-reset") {
        document.querySelector("#scroll-top").style.zoom = 1.0;
      }
    },
    onCommand(path) {
      this.$router.push(path);
    },
    toggleSidebar() {
      this.$emit("toggle-sidebar");
    },
    resizePopoverWidth() {
      if (window.innerWidth <= 768) {
        this.popoverWidth = 250;
      } else {
        this.popoverWidth = 300;
      }
    },
    toggleFullscreen() {
      this.$fullscreen.toggle(document.querySelector("body"), {
        wrap: false,
        callback: () => {
          this.fullscreen = this.$fullscreen.getState();
        }
      });
    },
    power_cycle_device() {
      if (this.is_connected_balance_id()) {
        EventService.pmk_fetch('electric_lab_device_power_cycle_by_device_id', 'POST', { device_id: this.is_connected_balance_id() }).then((http_response) => {
          if (http_response.data.messages) {
            this.$renderMessages(http_response.data.messages)
          }
        })
      }
    },
    disconnect_printer() {
      this.$store.dispatch('equipment/set_current_printer', {})
      window.localStorage.removeItem('checked_out_printer')
    }
  },
  components: {
    NotificationBox,
    Search,
    LogSupportCase
  },
  mounted() {
    this.fullscreen = this.$fullscreen.getState();
    this.resizePopoverWidth();
    window.addEventListener("resize", this.resizePopoverWidth);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resizePopoverWidth);
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/_variables";
@import "../assets/scss/_mixins";

.toolbar {
  width: 100%;
  height: 100%;
  padding: 0 20px;
  box-sizing: border-box;

  .username {
    margin-left: 20px;
    font-weight: bold;
    @include text-bordered-shadow();

    a {
      color: $text-color;
      text-decoration: none;

      &:hover {
        color: $text-color-accent;
      }
    }
  }

  .avatar {
    border-radius: 50%;
    width: 35px;
    height: 35px;
    border: 1px solid #fff;
    margin-left: 20px;
    box-sizing: border-box;
    display: block;
    cursor: pointer;
    box-shadow: 0 2px 5px 0 rgba(49, 49, 93, 0.1),
      0 1px 2px 0 rgba(0, 0, 0, 0.08);
    transition: box-shadow 0.5s;

    &:hover {
      box-shadow: 0px 3px 10px 0 rgba(49, 49, 93, 0.08),
        0px 2px 7px 0 rgba(0, 0, 0, 0.08);
    }
  }

  .notification-icon {
    font-size: 20px;
    outline: none;
    padding: 0;
    background: transparent;
    border: none;
    margin-left: 20px;
    //color: #aab7c5;
    color: transparentize($text-color, 0.7);
    @include text-bordered-shadow();

    &:hover {
      color: $text-color-accent;
    }
  }

  .toggle-sidebar {
    border: 1px solid transparent;
    height: 32px;
    min-height: 32px;
    line-height: 32px;
    width: 32px;
    min-width: 32px;
    max-width: 32px;
    box-sizing: border-box;
    text-align: center;
    margin: 0;
    outline: none;
    margin-right: 5px;
    font-size: 24px;
    padding: 0;
    cursor: pointer;
    display: inline-block;
    color: $text-color;
    background: white;
    display: none;
    opacity: 0;
    transition: all 0.5s;

    &__right {
      margin-right: 0px;
      margin-left: 5px;
    }

    &:hover,
    &:focus,
    &:active {
      color: $text-color-accent;
      border-color: $text-color-accent;
    }
  }

  .fullscreen-button {
    font-size: 24px;
    cursor: pointer;
    outline: none;
    padding: 0;
    background: transparent;
    border: none;
    margin-left: 20px;
    //color: #aab7c5;
    color: transparentize($text-color, 0.7);
    @include text-bordered-shadow();

    &:hover {
      color: $text-color-accent;
    }
  }

  .el-dropdown {
    .flag-icon {
      box-shadow: 0 2px 5px 0 rgba(49, 49, 93, 0.1),
        0 1px 2px 0 rgba(0, 0, 0, 0.08);
      cursor: pointer;
      border: 1px solid lighten($background-color, 20%);
      background-color: lighten($background-color, 20%);
    }
  }
}

@media (max-width: 650px) {
  .toolbar {
    .username {
      display: none;
    }
  }
}

@media (max-width: 768px) {
  .toolbar {
    padding: 0 10px;

    .toggle-sidebar {
      display: block;
      opacity: 1;
    }

    .fullscreen-button {
      display: none;
    }
  }
}
</style>
