<template>
  <div class="formula-ingredients">
    <el-row>
      <div style="margin-top: 15px;">
        <el-col :span="12">
          <div class="sub-title">Ingredients</div>
        </el-col>
      </div>
    </el-row>
    <el-row class="ingredient-header">
      <div style="margin-top: 15px;">
        <el-col :span="5">
          <div>Description</div>
        </el-col>
        <el-col :span="1">&nbsp;</el-col>

        <el-col :span="5">
          Require Measurement Check
          <!-- <el-tooltip
            class="helper-instructions"
            effect="dark"
            content="Check this for items that can not be read by equipment or need a pharmacist review"
          >
            <i class="fal fa-question-circle"></i>
          </el-tooltip> -->
          <div>
            Quantity Sufficient
            <!-- <el-tooltip
              class="helper-instructions"
              effect="dark"
              content="Check this for items that do not have a specific quantity, e.g. add enough water to be 60mL"
            >
              <i class="fal fa-question-circle"></i>
            </el-tooltip> -->
          </div>
        </el-col>
        <el-col :span="8">Quantity</el-col>
      </div>
    </el-row>
    <el-row v-for="ingredient in localIngr" :key="ingredient.identifier">
      <div style="margin-top: 15px;">
        <el-col :span="5">
          <div>
            {{ ingredient.description }}
          </div>
          <div>Code: {{ ingredient.code }}</div>
        </el-col>
        <el-col :span="1">&nbsp;</el-col>

        <el-col :span="5">
          <div>
            <span><i :class=" ingredient.requires_measurement_check ? 'fal fa-check-square' : 'fal fa-square'"></i> Check</span>
          </div>
          <div>
            <span><i :class="ingredient.quantity_sufficient_indicator ? 'fal fa-check-square' : 'fal fa-square'"></i> QS</span>
          </div>
        </el-col>
        <el-col :span="13">
            <span>{{ingredient.quantity}}</span>
          &nbsp;
          <span v-if="locked" class="unit-of-measure">
            <BaseUnitOfMeasure
              :unit="(ingredient.formula_unit_of_measure || ingredient.unit_of_measure)"
            />
          </span>
          <span v-else>
              <BaseUnitOfMeasure
              :unit="ingredient.formula_unit_of_measure"
            />
          </span>
        </el-col>
      </div>
    </el-row>
    <el-row style="border-top: 1px solid silver; margin-top: 1em;">
      <div style="margin-top: 15px;">
        <el-col :span="11">&nbsp;</el-col>
        <el-col :span="10">
          <div class="total-weight">Total Weight: {{parseFloat(calculate_total()).toFixed(decimal_places)}}</div>
        </el-col>
      </div>
    </el-row>
    <el-row>
      <div style="margin-top: 15px;">
        <el-col :span="11">&nbsp;</el-col>
        <el-col :span="5">
          <div class="formuala-quantity">
            <el-tooltip
              class="item"
              effect="dark"
              content="Number of units produced"
              placement="top-start"
            >
              <b>Quantity: &nbsp;</b>
            </el-tooltip>
            <span> {{local_formula_quantity}}</span>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="formuala-quantity">
            <!-- nbsp here to force it down, TODO: use CSS -->
            <!-- <div>&nbsp;</div> -->
            <div>Units <span><BaseUnitOfMeasure
              :unit="local_unit_of_measure"
            /></span></div>

          </div>
        </el-col>
      </div>
    </el-row>
  </div>
</template>

<script>
import _ from 'lodash'
import NProgress from "nprogress";
export default {
  name: "printableingredients",
  props: [
    "ingredients",
    "form",
    "terminology",
    "locked",
    "formula_quantity",
    "unit_of_measure"
  ],
  data() {
    return {
      localIngr: JSON.parse(JSON.stringify(this.ingredients)),
      local_formula_quantity: this.formula_quantity,
      local_unit_of_measure: this.unit_of_measure,
      form: {
        new_ingredient: {
          description: ''
        }
      }
    };
  },
  mounted() {},
  computed: {
    decimal_places() {
      return _.get(this.$store.getters['formula/get_lab_config'].config, 'lot.decimal_places', 3)
    }
  },
  watch: {
    localIngr: {
      handler(v) {
        this.$emit("updateIngredients", v);
      },
      deep: true
    },
    local_formula_quantity(v) {
      this.$emit("updateFormulaQty", v);
    },
    local_unit_of_measure(v) {
      this.$emit("updateFormulaUnit", v);
    }
  },
  methods: {
    calculate_total() {
      let total_quantity = 0;
      this.localIngr.forEach(function(ingredient) {
        let unit_of_measure = (
          ingredient.formula_unit_of_measure ||
          ingredient.unit_of_measure ||
          ""
        ).toUpperCase();
        if (["C28252", "C28253", "C48155"].includes(unit_of_measure)) {
          total_quantity += parseFloat(ingredient.quantity) || 0;
        }
      });
      return total_quantity;
    }
  }
};
</script>

<style>
</style>
