<template>
  <el-table :data="manufacturers" style="width: 100%">
    <el-table-column type="expand">
      <template slot-scope="props">
        <ul>
          <li v-for="code in props.row.codes" :key="code">{{ code }}</li>
        </ul>
      </template>
    </el-table-column>
    <el-table-column label="Manufacturer" prop="name"></el-table-column>
    <el-table-column label="Name" prop="name"></el-table-column>
  </el-table>
</template>

<script>
export default {
  props: {
    manufacturers: {
      type: [Array]
    }
    // name: {
    //       type: [String]
    //     },
    //     codes: {
    //       type: [Array],
    //       default: []
    //     }
  }
}
</script>
