<!-- NOTE: come back to this when v-model with compnents is understood better , it should get used in formula/manage.vue -->
<template>
  <span>
    <span class="dosage-form" v-if="readonly">{{ display() || 'n/a' }}</span>
    <span v-else>
      <!-- this is inactive because it is not working properly -->
      <el-select
        v-model="content"
        @change="handleInput"
        filterable
        placeholder="Form"
        style="text-transform: capitalize;"
      >
        <el-option
          style="text-transform: capitalize;"
          v-for="item in form"
          :key="item.code"
          :label="item.display || item.description"
          :value="item.code"
        ></el-option>
      </el-select>
    </span>
  </span>
</template>

<script>
export default {
  props: {
    value: String,
    readonly: Boolean
  },

  //   model: {
  //     prop: "value",
  //     event: "input"
  //   },

  data() {
    return {
      content: this.value
    };
  },
  methods: {
    handleInput(e) {
      console.log("d", e);
      this.$emit("change", e);
    },
    display() {
      let unit_list = this.form;
      let map = new Map(
        unit_list.map(i => [i.code, i.display || i.description])
      );
      return map.get(this.value) || this.value;
    }
  },
  computed: {
    form: {
      get() {
        return this.$store.getters["terminology/terminology"]["dosage_form"];
      }
    }
  }
};
</script>




<style scoped>
</style>