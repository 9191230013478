<template>
  <div>
    <el-row :gutter="10">
      <el-col :span="12" style="margin-bottom: 2em;">
        <div class="sub-title">Search:</div>
        <el-autocomplete
          id="ingredient_search_field"
          class="inline-input"
          v-model="form.purchase_order"
          :fetch-suggestions="search_formulas"
          placeholder="Search..."
          :trigger-on-focus="false"
          no-value-key="name"
          popper-class="autocomplete-suggestion-popper-use-table"
          clearable
          autocapitalize="none"
          style="width: 100%;"
        >
          <template slot="prepend">
            <i class="fal fa-search"></i>
          </template>
        </el-autocomplete>&nbsp;
      </el-col>
      <el-col :span="4" >
        <el-button v-show="results.length" type="text" @click="download_results">
          <i class="fad fa-file-download"></i> Download Results
        </el-button>
      </el-col>
    </el-row>
    <el-row :gutter="10">
      <el-col :span="24">
        <section class="results_area" v-if="results">
          <el-table
            ref="singleTable"
            :data="results"
            highlight-current-row
            @current-change="handleSearchResultSelection"
            style="width: 100%"
            class="break-word"
          >
            <!-- <el-table-column type="index" width="50"></el-table-column> -->
            <el-table-column
              class-name="clickable"
              property="description"
              label="Product"
              width="250"
              sortable
            ></el-table-column>
            <el-table-column property="code" label="Code"></el-table-column>
            <el-table-column property="lot_number" label="Lot #" ></el-table-column>
            <el-table-column
              property="beyond_use_date"
              :formatter="format_yyyy_mm_dd"
              label="Beyond Use"
              min-width="100"
              class="break-word"
              sortable
            ></el-table-column>
             <el-table-column
                label="Entered Inventory"
                width="180"
                property="date_entered_inventory"
                sortable>
                <template slot-scope="scope">
                  <span v-if="scope.row.date_compounded">{{format_yyyy_mm_dd_as_method(scope.row.date_compounded) }}</span>
                  <span v-else>{{format_yyyy_mm_dd_as_method(scope.row.date_entered_inventory) }}</span>
                </template>
              </el-table-column>
            <el-table-column property="quantity" label="QTY" ></el-table-column>
          </el-table>
        </section>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import NProgress from "nprogress";
import EventService from "@/services/EventService.js";
const uuid_base64 = require("uuid-base64");
import _ from 'lodash'

const moment = require("moment");

export default {
  // props: {
  //   formula: {
  //     type: Object,
  //     required: false,
  //     default: this.createFreshFormObject()
  //   }
  // },
  props: {
    search_removed: Boolean,
    search_ingredient_type: String,
    date_range: Array,
  },
  data() {
    return {
      categories: this.$store.state.categories,
      form: {
        search_all_inventory: 0,
        search_removed_inventory: this.search_removed,
        formula_query: ''
      },
      results: []
    };
  },
  computed: {
    decimal_places() {
      return _.get(this.$store.getters['formula/get_lab_config'].config, 'lot.decimal_places', 3)
    }
  },
  watch: {
    search_removed(val) {
      this.form.search_removed_inventory = val
      if (this.form.formula_query) {
        this.search_formulas(this.form.formula_query, ()=> {})
      }
    },
    search_ingredient_type() {
      if (this.form.formula_query) {
        this.search_formulas(this.form.formula_query, ()=> {})
      }
    },
    date_range() {
      if (this.form.formula_query) {
        this.search_formulas(this.form.formula_query, ()=> {})
      }
    }
  },
  mounted() {
    document.querySelector('#ingredient_search_field').focus()
  },
  methods: {
    reset() {
      this.form = {};
    },
    handleSearchResultSelection(row) {
      this.$router.push({
        name: "ingredient-inventory-item",
        params: { identifier: row.identifier }
      });
    },
    download_results() {
      const omit = (originalObject = {}, keysToOmit = []) => {
          const clonedObject = _.cloneDeep(originalObject);
          for (const path of keysToOmit) {
            for (const record of clonedObject) {
              _.unset(record, path)
            }
          }
          return clonedObject;
      }
      let csvContent = "data:text/csv;charset=utf-8,";
      let data = _.cloneDeep(this.results)
      data.forEach(item => {
        item['quantity_remaining'] = item.current_inventory && item.current_inventory.quantity_remaining
        item['quantity_used'] = item.current_inventory && item.current_inventory.quantity_used
        item['containers'] = item.data && item.data.inventory.containers
        item['quantity_initial'] = item.data && item.data.inventory.quantity
      })
      data = omit(data, ['data', 'current_inventory', 'realm'])
      const rows = []
      let column_list = [
        'id',
        'identifier',
        'ingredient_identifier',
        'lot_number',
        'code',
        'beyond_use_date',
        'date_entered_inventory',
        'description'
      ]
      data.forEach(item => {
        const row = []
        column_list.forEach(col => row.push(item[col]))
        rows.push(row)
      })
      column_list[1] = 'lot_identifier'
      csvContent += column_list.join(',') + '\n'
      csvContent += rows.map(item => item.join(',')).join('\n')
      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "inventory_results.csv");
      document.body.appendChild(link); // Required for FF
      link.click();
    },
    search_formulas(queryString, cb) {
      let self = this;
      // here we need to query the formula list
      let arg_string = queryString;
      console.log("arg_string", arg_string);
      let default_fields = ['date_created',
                            'date_updated',
                            'id',
                            'identifier',
                            'ingredient_identifier',
                            'lot_number',
                            'lot_type',
                            'product_identifier',
                            'realm',
                            'data.inventory'
                            ]
      let query_object = {
        purchase_order: this.form.purchase_order,
        search_removed_inventory: this.form.search_removed_inventory ? 1 : 0,
        date_start: this.date_range[0] || '',
        date_end: this.date_range[1] || ''
      };
      if (this.search_ingredient_type !== 'both') {
        query_object.lot_type = this.search_ingredient_type
      }
      // query_object.fields = default_fields
      console.log("query_object", [queryString, query_object]);
      NProgress.start();
      EventService.pmk_fetch('electric_lab_inventory_search_by_purchase_order',
        'GET', {}, {...query_object})
        .then(results => {
          self.results = results.data.data;
          cb(self.results)
        })
        .then(() => NProgress.done())
        .catch(() => {
          NProgress.done();
        });
    },

    format_yyyy_mm_dd(row, col, val, indx) {
      return moment(val).format("YYYY-MM-DD");
    },
    format_yyyy_mm_dd_as_method(val) {
      return moment(val).format("YYYY-MM-DD");
    }
  }
};
</script>
<style scoped>
.clickable {
  cursor: pointer;
}
</style>
