const state = {
  authorized: true,
}

const getters = {
  authorized(state) {
    console.log(9999, { state })
    return state.authorized
  }
}

const mutations = {
  set_authorized(state) {
    state.authorized = true
  },
  unset_authorized(state) {
    state.authorized = false
  }
}

const actions = {
  unset_authorized({ commit }) {
    commit('unset_authorized')
  },
  set_authorized({ commit }) {
    commit('set_authorized')
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
