export const validateDatesArray = (rule, value, callback) => {
    if(!value || value.length < 2 ){
      return callback(new Error('Please input a Due Date'))
    }else{
      return callback();
    }
  };
  export const validateNumbers = (rule, value, callback) => {
    let pattern = /^(\d+\.\d+)$|^(\d+)$/g
    if(pattern.test(value) ){
       return callback();
    }else{
      return callback(new Error('Please input a valid quantity'))
    }
  };