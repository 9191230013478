<template>
  <span class="">
    <el-dialog
    title="Take photo"
    :visible.sync="display_photo_dialog"
    top="10%"
    width="90%">
      <div v-if="display_photo_dialog">
        <div class="camera" v-show="!has_photo" style="text-align: center">
          <div class="video-container" @click="takepicture">
            <video
              id="video"
              class="image-display"
              playsinline
              muted
              autoplay
              :style="{ transform: 'scale(' + zoom + ')' }"
            >
              Video stream not available.
            </video>
          </div>
          <div class="">
            <el-button @click="takepicture" round >Take photo</el-button>
          </div>
          <el-slider v-model="zoom" :min="1" :max="3" :step="0.1" style="max-width: 80%;margin: auto;"></el-slider>
        </div>

        <canvas id="canvas" v-show="false">
        </canvas>
        <div class="output" v-show="has_photo" style="text-align: center" >
          <img id="photo" alt="The screen capture will appear in this box." class="image-display">
          <div class="">
            <el-button @click="reset_photo" round >Reset</el-button><br><br>
            <b>Name:&nbsp;</b> <el-input v-model="form.name" style="width: 80%"></el-input><br><br>
            <b>Note:&nbsp;</b> <el-input v-model="form.note" style="width: 80%"></el-input>
          </div>
        </div>




        <span slot="footer" class="dialog-footer">
          <div style="margin-bottom: 15px;">
            <el-select v-model="selected_camera" @change="get_camera" v-if="has_camera && camera_list && camera_list.length" :disabled="has_photo" style="margin-right: 10px; margin-bottom: 5px;">
              <el-option
                v-for="camera in camera_list"
                :key="camera.deviceId"
                :label="camera.label"
                :value="camera.deviceId">
              </el-option>
            </el-select>
            <el-button @click="display_photo_dialog = false">Cancel</el-button>
            <el-button type="primary" :disabled="!photo_data" @click="handle_upload">Save</el-button>
          </div>
        </span>
      </div>
    </el-dialog>
    <span v-if="camera_list.length">
      <span style="font-size: 1.75em;" class="clickable " @click="display_photo_dialog = true">
        <span style="font-size: 1rem">&nbsp;|&nbsp;<i class="fad fa-camera"></i>&nbsp;Take Photo</span>
      </span>
    </span>
  </span>

</template>

<script>
import url_for from "@/services/pmk_url_for"
const uuidv4 = require("uuid/v4");
export default {
  data() {
    return {
      display_photo_dialog: false,
      width: 1280,
      height: 0,
      zoom: 1,
      streaming: false,
      video: null,
      canvas: null,
      photo: null,
      has_photo: false,
      photo_data: null,
      form: {
        name: '',
        note: '',
      },
      xhr_response: {},
      loading: null,
      has_camera: false,
      camera_list: [],
      selected_camera: ''
    }
  },
  mounted() {
    this.detect_cameras()
  },
  watch: {
    display_photo_dialog(val) {
      if (val) {
        window.setTimeout(this.startup, 50)
      } else {
        this.video.pause()
        this.video.srcObject.getTracks().forEach(function(track) {
          track.stop()
        })
        this.video = null
      }
    },
    xhr_response: {
      deep: true,
      handler(val) {
        this.loading.close()
        let document_to_send = {
          "identifier": val.data.document_identifier,
          "name": this.form.name,
          "note": this.form.note,
          "category" : 'photo',
          "type": 'image/png'
        }
        this.$emit("update_documents", document_to_send)
        this.display_photo_dialog = false
        this.video.srcObject.getTracks().forEach(function(track) {
          track.stop()
        })
      }
    },
    selected_camera(val) {
      localStorage.setItem('selected_camera', val)
    }
  },
  computed: {
    pmk_config: {
      get() {
        return this.$store.getters["app/pmk_config"];
      }
    },
  },
  methods: {
    reset_photo() {
      this.has_photo = false
      this.photo_data = null
    },
    detect_cameras() {
      navigator.mediaDevices.enumerateDevices().then(devices => {
        this.camera_list = devices.filter(obj => obj.kind === 'videoinput')
        let selected_cam = localStorage.getItem('selected_camera')
        if (selected_cam) {
          this.selected_camera = selected_cam
        } else {
          this.selected_camera = this.camera_list[0].deviceId
        }
        if (this.camera_list.length > 1) {
          this.has_camera = true
        }
      })
    },
    handle_upload() {
      let return_data = this.savephoto()
    },
    startup() {
      this.video = document.getElementById('video')
      this.canvas = document.getElementById('canvas')
      this.photo = document.getElementById('photo')
      this.form = {
        name: '',
        note: '',
      }
      this.zoom = 1
      this.get_camera()
    },
    get_camera() {
      const self = this
      navigator.mediaDevices.getUserMedia({
        video: {
          deviceId: this.selected_camera,
          width: { ideal: 1920 },
          height: { ideal: 1080 },
        },
        audio: false
      })
      .then((stream) => {
          this.video.srcObject = stream
          this.video.play()
      })
      .catch(function(err) {
          console.log("An error occurred: " + err);
          let url = 'https://' +
            self.pmk_config.realm +
            '.electriclab.app/app/knowledge_base#/knowledge_base/en_US/156084-ion/allowing-camera-access'
          self.$message({
            type: 'error',
            dangerouslyUseHTMLString: true,
            message:
              'Error connecting to camera, please see <a target="_blank" href="' +
              url +
              '">' +
              url +
              '</a>',          });
      });
      this.video.addEventListener('canplay', (ev) => {
        if (!this.streaming) {
          this.width = _.min([this.video.videoWidth, 3000])
          this.height = this.video.videoHeight / (this.video.videoWidth/this.width);

          this.video.setAttribute('width', this.width);
          //this.video.setAttribute('height', this.height);
          this.canvas.setAttribute('width', this.width);
          //this.canvas.setAttribute('height', this.height);
          this.streaming = true;
          this.detect_cameras()
        }
      }, false);
      this.clearphoto()
    },
    takepicture() {
      var context = this.canvas.getContext('2d')
      if (this.width && this.height) {
        this.canvas.width = this.width
        this.canvas.height = this.height
        context.scale(this.zoom, this.zoom)
        let x = (this.width - this.width * this.zoom) / (2 * this.zoom)
        let y = (this.height - this.height * this.zoom) / (2 * this.zoom)
        context.drawImage(this.video, x, y, this.width, this.height)

        var data = this.canvas.toDataURL('image/png')
        this.photo.setAttribute('src', data)
        this.photo_data = data
        this.has_photo = true
      } else {
        this.clearphoto()
      }
    },
    upload_url() {
      const url = url_for.url_for('electric_lab_document_save', {
        version: 'v5'
      })
      return url
    },
    clearphoto() {
      var context = this.canvas.getContext('2d')
      context.fillStyle = "#AAA"
      context.fillRect(0, 0, this.canvas.width, this.canvas.height)

      var data = this.canvas.toDataURL('image/png')
      this.photo.setAttribute('src', data)
      this.photo_data = null
      this.has_photo = false
    },
    savephoto() {
      let name = this.form.name || 'photo'
      var fileFromBlob = new File([this.photo_data], name + '.png')

      let option = {}
      option.action = this.upload_url()
      const xhr = new XMLHttpRequest()

      if (xhr.upload) {
        xhr.upload.onprogress = (e) => {
          if (e.total > 0) {
            e.percent = (e.loaded / e.total) * 100
          }
        }
      }

      const formData = new FormData()
      formData.append('category', 'photo')
      formData.append('name', name)
      formData.append('note', this.form.note)
      formData.append('mime', 'image/png')

      xhr.onerror = option.onerror
      xhr.onload = () => {
        if (xhr.status < 200 || xhr.status >= 300) {
          return xhr
        }
        this.xhr_response = JSON.parse(xhr.response)
        console.log('onload finalized', xhr)
      }

      xhr.open('post', option.action, true)

      if (option.withCredentials && 'withCredentials' in xhr) {
        xhr.withCredentials = true
      }

      const headers = option.headers || {}

      for (const item in headers) {
        if (headers.hasOwnProperty(item) && headers[item] !== null) { // eslint-disable-line
          xhr.setRequestHeader(item, headers[item])
        }
      }

      fetch(this.photo_data).then(res => {
        return res.blob()
      }).then(data => {
        console.log('data', data)
        formData.append('file', data)
        xhr.send(formData)
        this.loading = this.$loading({
          lock: true,
          text: 'Saving',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        return xhr
      })
    }
  }
}
</script>

<style scoped>
.image-display {
  max-height: 59vh;
  max-width: 80vw;
}
@media only screen and (max-width: 800px) {
  .image-display {
    max-height: 100%;
    max-width: 80vw;
  }
}
.video-container {
  overflow: hidden;
}
</style>
