<template>
  <div>
    <OrderButtons></OrderButtons>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>{{ formula.name }} [{{formula.identifiers.formula_id}}]</span>

        <el-button style="float: right; padding: 3px 0" type="text">Edit</el-button>
      </div>
      <div class="formula-options">
        <el-row v-for="label in formula.options.auxillary_labels" :key="label.id">
          <el-button :type="label.type" round>{{label.value}}</el-button>
        </el-row>
      </div>

      <div class="formula-tasks">
        <ol>
          <!-- 
          <template v-for="(task, index) in formula.tasks">
            <li>
              <el-input placeholder="Task Instructions" v-model="task.description" clearable></el-input>
            </li>
          </template>
          -->
        </ol>
      </div>

      <div v-for="o in 4" :key="o" class="text item">{{'List item ' + o }}</div>
    </el-card>
  </div>
</template>
<script>
export default {
  // props: {
  //   formula: {
  //     type: Object,
  //     required: false,
  //     default: this.createFreshFormObject()
  //   }
  // },
  data() {
    return {
      categories: this.$store.state.categories,
      form: this.createFreshFormObject(),
      formula: this.createFreshFormObject()
    };
  },
  methods: {
    createFreshFormObject() {
      const user = this.$store.getters["account/profile"];
      const id = Math.floor(Math.random() * 10000000);

      return {
        id: id,
        identifiers: { formula_id: "abc22" },
        category: "HRT",
        author: user.name,
        name: "LDN 0.5mg capsule",
        description: "",
        date_created: () => {
          let d = new Date();
          return d.toJSON();
        },
        options: {
          auxillary_labels: [
            { id: "auz1", type: "warning", value: "drink with coffee" }
          ],
          is_lab_stock: false,
          location: "G1",
          stability_span: "182"
        },
        instructions: `MUST BE RICH TEXT`,
        tasks: [
          {
            complete: false,
            completed_by: "some_username",
            date_completed: "2019-01-22T01:11:22Z",
            task_description: "Do something..."
          }
        ]
      };

      // return {
      //   id: id,
      //   identifiers: { formula_id: '' },
      //   category: '',
      //   author: user.name,
      //   name: '',
      //   description: '',
      //   location: '',
      //   date_created: '',
      //   options: {
      //     auxillary_labels: [],
      //     is_lab_stock: false,
      //     location: '',
      //     stability_span: ''
      //   },
      //   instructions: '',
      //   tasks: []
      // }
    }
  }
};
</script>
<style scoped>
.location {
  margin-bottom: 0;
}
.location > .icon {
  margin-left: 10px;
}
.event-header > .title {
  margin: 0;
}
.list-group {
  margin: 0;
  padding: 0;
  list-style: none;
}
.list-group > .list-item {
  padding: 1em 0;
  border-bottom: solid 1px #e5e5e5;
}
</style>
