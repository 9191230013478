<template>
 <div class="formula-tasks">
        <h4>
          <i style="margin-right: .5em" class="fal fa-tasks"></i>
          <span>Tasks</span>
        </h4>
        <ul>
          <li v-for="(task,index) in localTasks" :key="index">
              {{task.description}}
          </li>
          <li
            v-for="(typeTask,index) in localFormulaTypeTasks" :key="index">
              {{typeTask.description}}
          </li>
        </ul>
        
      </div>
</template>

<script>
// var decycle = require('json-decycle').decycle
// var retrocycle = require('json-decycle').retrocycle

const getCircularReplacer = () => {
  const seen = new WeakSet();
  return (key, value) => {
    if (typeof value === "object" && value !== null) {
      if (seen.has(value)) {
        return;
      }
      seen.add(value);
    }
    return value;
  };
};

export default {
    name:"printabletasks",
  props: ["tasks", "form", "formula_type_tasks"],
  data(){
      return{
          localTasks: JSON.parse(JSON.stringify(this.tasks, getCircularReplacer())),
          localFormulaTypeTasks: this.formula_type_tasks
      }
  },
  watch: {
        localTasks: {
            handler(v){
                this.$emit('updateTasks', v)
            },
            deep: true
        }
  },
  methods: {
    remove_formula_task(task_index, task) {
      // TODO: this mutates the store and might not be reactive
      this.localTasks.splice(task_index, 1);
    },
  }
};
</script>

<style>
.el-table th, .el-table td {
    padding: 3px 0!important;
}
</style>
