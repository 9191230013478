<template>
  <div>
    <el-row style="margin-bottom: 1.5em;">
      <el-col style="margin-bottom: 1em;">
        <div style="margin-bottom: 1.5em;">
          <el-button
            type="default"
            class="status-button"
            style="float: right; padding: 5px"
            @click="add_entry()"
          >
            <i class="fal fa-plus"></i> Add New
          </el-button>
        </div>
      </el-col>
      <el-col>
        <el-input
          v-model="form.filter_query"
          placeholder="Filter..."
          size="small"/>
      </el-col>
    </el-row>
    <div v-for="(item, item_identifier) in Object.keys(formula_types_mutable)
            .filter(key => ! formula_types_mutable[key].archived)
            .filter(key => formula_types_mutable[key].name.toUpperCase().includes(form.filter_query.toUpperCase()))
            .reduce((obj, key) => {
              obj[key] = formula_types_mutable[key];
              return obj;
            }, {})"
        :key="item_identifier">
      <manage-type :item="item" :item_identifier="item_identifier" v-on:update_entry="update_entry" v-on:copyEntry="copyEntry"></manage-type>
    </div>

    <!-- <pre>  THIS NEEDS a way to update lab config/ formula types
  -- which now includes stuff that is NOT lab config
  -- formula and pmk_config.  real config (formula_types only at present) should move to just "config":
  { pmk_config: {...},
    config: {...},
    formula: { aux labels, ...},
    other_external: {...}
    }
</pre> -->
  </div>
</template>
<script>
import Common from "@/common.js";
import tasks from '@/views/formula/parts/tasks';
import ManageType from './manage_type.vue';
const uuidv4 = require("uuid/v4");
// import taskspart from "./parts/tasks.vue"
export default {
  components: { ManageType },
  props: {
    formula_types: {
      type: Object,
      required: true,
      //   let tmp_items = {
      //     Medisca: {
      //       account: 'account',
      //       phone: '1234567890',
      //       name: 'Medisca'
      //     }
      //   }
      //   return tmp_items
      // }
    }
  },
  data() {
    return {
      //      item: {},when
      form: {
        new_task: {},
        filter_query: ''
      },
      formula_types_mutable: this.formula_types,
    };
  },
  computed: {
    station_type_options() {
      const station_types = this.$store.getters['formula/get_station_types']
      return Object.values(station_types)
    }
  },
  methods: {
    add_entry() {
      this.form.filter_query = ''
      let new_type = this.createFreshFormulaTypeObject()
      let new_type_object = {}
      new_type_object[new_type.identifier] = new_type
      this.formula_types_mutable = Object.fromEntries(Object.entries(this.formula_types_mutable).reverse())
      this.formula_types_mutable =
      { ...this.formula_types_mutable, ...new_type_object }
      this.formula_types_mutable = Object.fromEntries(Object.entries(this.formula_types_mutable).reverse())
    },
    copyEntry(item) {
      this.form.filter_query = ''
      let new_type = JSON.parse(JSON.stringify(item))
      new_type.name = new_type.name + ' [copy]'
      new_type.identifier = uuidv4()
      let new_type_object = {}
      new_type_object[new_type.identifier] = new_type
      this.formula_types_mutable = Object.fromEntries(Object.entries(this.formula_types_mutable).reverse())
      this.formula_types_mutable =
      { ...this.formula_types_mutable, ...new_type_object }
      this.formula_types_mutable = Object.fromEntries(Object.entries(this.formula_types_mutable).reverse())
    },
    update_entry(entry) {
        this.formula_types_mutable = { ...this.formula_types_mutable, entry }
    },
  createFreshFormulaTypeObject() {
    //const user = store.state.user.user;
    // see also in manage_types.vue
    return {
     identifier: uuidv4(),
      instructions: "",
      // setting with prefix _space_ makes it sort to the top:
      name: ' --New Formula Type--',
      tasks: [] // this should be in the same format as formula tasks
    };
  },


add_item() { // not currently used
      this.$store.dispatch("formula/addFormulaType");
    }

}
};
</script>

<style scoped>
.property-row {
  margin-bottom: 1em;
}
.sub_task-instructions {
  display: inline-block;
  min-width: 25em;
  max-width: 80%;
}
.sub_task-points {
  max-width: 8em;
  margin: 0 1em;
}

.form-label {
  margin-right: 1.25em;
}
.formula-task-options-label {
  vertical-align: middle;
}
.pull-right {
  margin-left: 1em;
  font-size: 0.5em
}
</style>
