<template>
	<div class="profile-barcode">
        Use this barcode at the login screen.
        <el-row :gutter="12">
            <el-col :span="8">
            Password: <el-input v-model="form.password"  show-password placeholder="Password"></el-input>
            </el-col>
            <el-col :span="1">
                &nbsp;
            </el-col>
            <el-col :span="15">
              <el-button @click="get_login_barcode_label" type="primary" icon="el-icon-printer" plain>Print Barcode</el-button>
                <div style="padding: 1.25em">
                    <el-alert
                        title="Security Alert"
                        type="warning"
                        description="Keep this barcode secure.  If lost, it is important to reset your password and obtain a new barcode."
                        show-icon>
                    </el-alert>
                </div>
            </el-col>
            <el-col :span="1">
                &nbsp;
            </el-col>
            <el-col :span="1">
            </el-col>
        </el-row>
	</div>
</template>

<script>
import Common from "@/common.js";
const uuidv4 = require("uuid/v4");
import EventService from "@/services/EventService.js";

export default {

	props: ['profile'],
	data() {
		return {
            form: { password: "" }
        }
    },
    methods: {
        get_login_barcode_label() {
	        let self = this
	          EventService.fetchAccountAuthenticationBarcode()
			    .then(function(result) {
				    console.log(result)
				    console.log({
			            	name: self.profile.name, 
			            	username: self.profile.username, 
			            	password: self.form.password,
			            	code: result.data.code
			            	})
			      if (result.data.success && result.data.data.code) {
		            return Common.get_login_barcode_label({
			            	name: self.profile.name, 
			            	username: self.profile.username, 
			            	password: self.form.password,
			            	code: result.data.data.code
			            	})
			        }
			    })
	    }
    }
}
</script>