<template>
<div>
    <el-row>
        <el-col :span="12">
            <a :href="excelParams.url"  :download="excelParams.filename">
              <i class="fad fa-file-spreadsheet"></i> Download the spreadsheet
            </a>
        </el-col>
        <el-col :span="12" style="text-align:right">
          <!--
            <el-tooltip class="item" effect="dark" content="Change the report parameters" placement="top-start">
                    <el-button type="info" icon="el-icon-setting" circle size="mini" @click="openModal()    ">
                    </el-button>
            </el-tooltip>
          -->
        </el-col>
    </el-row>
<VirtualScrollerTable v-if="!destroyComp && reportsData.length != 0"
 :tableData="reportsDataComputed()"
 :headers="reportsData.format.headers"
 :columns="reportsData.format.columns"
 :field_options="reportsData.format.args.field_options"
 :css_classes="reportsData.format.args.css_classes"
 :start_date="reportsData.format.start_date"
 :end_date="reportsData.format.end_date"
 :identifier="reportsData.format.row_identifer"
 :column_format="reportsData.format.column_format"
 :title="reportsData.format.args._slot_title_html"
/>

<el-dialog title="Parameters" :visible="destroyComp && needsParams"  @close="closeModal()" >
    <div v-if="reportCategroy.require">
    <el-row v-if="reportCategroy.require.dates">Dates
        <el-col>
            <el-date-picker
                v-model="args.dates"
                type="daterange"
                align="right"
                format="yyyy-MM-dd"
                unlink-panels
                range-separator="To"
                start-placeholder="Start date"
                end-placeholder="End date"
                :picker-options="pickerOptions">
            </el-date-picker>
        </el-col>
    </el-row>
    <el-row>
        <div style="margin-top: 15px;">
        </div>
    </el-row>
    <el-row v-if="reportCategroy.require.identifier && reportCategroy.require.identifier.lot">Lot identifier
        <el-col>
            <el-input v-model="args.lot_identifier"/>
        </el-col>
    </el-row>
    <el-row>
        <div style="margin-top: 15px;">
        </div>
    </el-row>
    <el-row v-if="reportCategroy.require.lot_type">Lot Type
        <el-col>
            <el-radio v-model="args.lot_type" label="formula">Formula</el-radio>
            <el-radio v-model="args.lot_type" label="ingredient">Ingredient</el-radio>
            <el-radio v-model="args.lot_type" label="">Both</el-radio>
        </el-col>
    </el-row>
    <el-row>
        <div style="margin-top: 15px;">
        </div>
    </el-row>
    <el-row v-if="reportCategroy.options.group_by">Group By:
        <el-col>
          <el-select v-model="args.group_by" multiple clearable placeholder="Group By" style="width: 100%">
            <el-option
              v-for="item in reportCategroy.options.group_by"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-col>

    </el-row>
    <el-row v-if="reportCategroy.require.search_removed_inventory">
        <el-col>
          <el-checkbox v-model="args.search_removed_inventory" label="Search removed inventory"></el-checkbox>
        </el-col>
    </el-row>

    <el-row v-if="reportCategroy.require.include_out_of_inventory">
        <el-col>
          <el-checkbox v-model="args.include_out_of_inventory" label="Include out of inventory items"></el-checkbox>
        </el-col>
    </el-row>

    <el-row>
        <div style="margin-top: 15px;">
        </div>
    </el-row>
    <el-row >
        <el-col style="text-align:right">
            <el-button type="primary" @click="loadData()">Load data</el-button>
        </el-col>
    </el-row>
    </div>

</el-dialog>
</div>

</template>

<script>
import url_for from "@/services/pmk_url_for";
import { mapGetters } from 'vuex'
const uuidv4 = require("uuid/v4");
import VirtualScrollerTable from '@/components/VirtualScrollerTable.vue'
import store from "@/store/store";
    let categories = {
        "lots_entered_by_date":{
            category: "lots_entered_by_date",
            api : "electric_lab_report_lots_entered_by_date",
            params: {version: 'v5'},
            args: {fields: 'data.yield', date_start: '2019-08-07', date_end: '2019-08-28', search_removed_inventory: false},
            require: {dates:{fields: ["date_start", "date_end"]}, search_removed_inventory: true},
            options: {}
        },
        "expiring_product_lots": {
            category: "expiring_product_lots",
            api : "electric_lab_report_expiring_product_lots",
            params: {version: 'v5'},
            args: { date_start: '2019-08-07', date_end: '2019-08-28', include_out_of_inventory: false},
            options: {},
            require: {dates:{fields: ["date_start", "date_end"]}, lot_type: 'type', include_out_of_inventory: true}
        },
        "lots_using_lot_as_ingredient": {
            category: "lots_using_lot_as_ingredient",
            api : "electric_lab_report_lots_using_lot_as_ingredient",
            //params:  {version: 'v5', lot_identifier: routeTo.params.identifier},
            params:  {version: 'v5'},
            args: {},
            options: {},
            require: {identifier:{lot: ["lot_identifier"]}}
        },
        "report_lots_production_by_date": {
            category: "report_lots_production_by_date",
            api : "electric_lab_report_lots_production_by_date",
            //params:  {version: 'v5', lot_identifier: routeTo.params.identifier},
            params:  {version: 'v5'},
            args: {},
            options: {},
            require: {dates:{fields: ["date_start", "date_end"]}}
        },
        "report_ingredient_usage_by_date": {
            category: "report_ingredient_usage_by_date",
            api : "electric_lab_report_ingredient_usage_by_date",
            //params:  {version: 'v5', lot_identifier: routeTo.params.identifier},
            params:  {version: 'v5'},
            args: {},
            options: {
                group_by: ['ingredient_identifier', 'reason_type']
            },
            require: {dates:{fields: ["date_start", "date_end"]}}
        },
        "lots_containing_controlled_substances": {
            category: "lots_containing_controlled_substances",
            api : "electric_lab_report_lots_containing_controlled_substances",
            //params:  {version: 'v5', lot_identifier: routeTo.params.identifier},
            params:  {version: 'v5'},
            args: {},
            options: {},
            require: null
        },
        "inventory_ingredient_current": {
            category: "inventory",
            api : "electric_lab_report_inventory_ingredient_current",
            //params:  {version: 'v5', lot_identifier: routeTo.params.identifier},
            params:  {version: 'v5'},
            args: {},
            options: {},
            require: null
        },
        "inventory_formula_current": {
            category: "inventory",
            api : "electric_lab_report_inventory_formula_current",
            //params:  {version: 'v5', lot_identifier: routeTo.params.identifier},
            params:  {version: 'v5'},
            args: {},
            options: {},
            require: null
        },
        "inventory_failed_lots": {
            category: "inventory",
            api : "electric_lab_report_inventory_failed_lots",
            //params:  {version: 'v5', lot_identifier: routeTo.params.identifier},
            params:  {version: 'v5'},
            args: {},
            options: {},
            require: {dates:{fields: ["date_start", "date_end"]}}
        },
        "inventory_lot_usage": {
            category: "inventory",
            api : "electric_lab_report_inventory_lot_usage",
            params:  {version: 'v5'},
            args: {},
            options: {},
            require: {dates:{fields: ["date_start", "date_end"]}}
        },
        "controlled_substance_ingredient_inventory": {
            category: "inventory",
            api : "electric_lab_report_ingredient_lots_containing_controlled_substances",
            params:  {version: 'v5'},
            args: {},
            options: {},
            require: null
        },
        "electric_lab_report_lots_by_date_with_productivity_metrics": {
            category: "inventory",
            api : "electric_lab_report_lots_by_date_with_productivity_metrics",
            params:  {version: 'v5'},
            args: {},
            options: {},
            require: {dates:{fields: ["date_start", "date_end"]}}
        },
        "task_report": {
            category: "task",
            api : "electric_lab_tasks_report",
            params:  {version: 'v5'},
            args: {},
            options: {},
            require: null
        },

}
export default {
    name:"reports",
    components: {VirtualScrollerTable},
    beforeRouteEnter(routeTo, routeFrom, next) {
        store.dispatch("reports/setReportCategory", categories[routeTo.params.category] ).then(d => {
            // in case the route needs no params
            if(categories[routeTo.params.category].require == null){
                store.dispatch('reports/setExcelUrl', categories[routeTo.params.category]).then(s => {})
                store.dispatch("reports/fetchReports", categories[routeTo.params.category]).then(s => {})

            }
            next();
        });
    },
    beforeRouteUpdate(routeTo, routeFrom, next) {
        store.dispatch("reports/setReportCategory", categories[routeTo.params.category] ).then(d => {
            // in case the route needs no params
            if(categories[routeTo.params.category].require == null){
                store.dispatch('reports/setExcelUrl', categories[routeTo.params.category]).then(s => {})
                store.dispatch("reports/fetchReports", categories[routeTo.params.category]).then(s => {})
            }
            next();
        });
    },
    computed: {
        ...mapGetters({
            reportsData :"reports/getReports",
            excelParams : "reports/getExcelParams",
            reportCategroy: "reports/getCategory"
        }),
        destroyComp: {
            get(){
                return store.getters["reports/getDestroyComp"]
            },
            set(newVal){
                store.dispatch('reports/setDestroyComponent',newVal)
            }
        },
        needsParams: {
            get(){
                return store.getters["reports/getNeedsParams"]
            },
            set(newVal){
                store.dispatch('reports/setNeedsParams',newVal)
            }
        }
    },
    data(){
        return {
            showReport: true,
            queryExcel : null,
            report_name: null,
            args:{dates: null, lot_identifier:null},
            pickerOptions: {
                shortcuts: [
                    {
                        text: 'Last week',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    },
                    {
                        text: 'Last month',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    },
                    {
                        text: 'Last 3 months',
                        onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                        }
                    }
                ]
            },
        }
    },
    mounted(){
    },

    methods: {
      reportsDataComputed() {
        if (this.reportCategroy.api === 'electric_lab_report_inventory_lot_usage' && this.reportsData.data) {
          let mapped = this.reportsData.data.map(obj => {
            let unit_of_measure = store.getters['terminology/unit_of_measure'].filter(uom => uom.code === obj.quantity_unit)[0]
            let unit_of_measure_display = ''
            if (unit_of_measure && unit_of_measure.display) {
              unit_of_measure_display = unit_of_measure.display
            }
            return {
              ...obj,
              quantity_unit: unit_of_measure_display,
              row_identifier: uuidv4()
            }
          })
          return mapped
        } else {
          return this.reportsData.data.map(obj => {
            return {
              ...obj,
              row_identifier: uuidv4()
            }
          })
        }
      },
        closeModal(){
            let self = this;
            store.dispatch('reports/setDestroyComponent').then(d => {
            })
        },
        openModal(){
            let self = this;
            store.dispatch('reports/setDestroyComponent', true).then(d => {
            })
        },
        loadData(){
            let self = this;
            let category = JSON.parse(JSON.stringify(this.reportCategroy));
            let paramsInUrl = '?';
            if (category.require && category.require.dates){
                let firstFieldName = category.require.dates.fields[0];
                let secondFieldName = category.require.dates.fields[1];
                console.log('console.log(self.args.dates[0]);',self.args.dates[0]);
                console.log('self.args.dates[1]',self.args.dates[1]);
                let start_datetime = self.args.dates[0]
                let end_datetime = self.args.dates[1]
                category.args[firstFieldName] = new Date(
                    start_datetime.getFullYear()
                    ,start_datetime.getMonth()
                    ,start_datetime.getDate()
                    ,0,0,1
                ).toISOString(); // self.args.dates[0].toISOString();
                category.args[secondFieldName] = new Date(
                    end_datetime.getFullYear()
                    ,end_datetime.getMonth()
                    ,end_datetime.getDate()
                    ,23,59,59
                    // was:
                    // self.args.dates[1].getTime()+1000*60*60*24 -  1
                ).toISOString();
                console.log('category.args', category.args);

            }
            if(category.require && category.require.identifier && category.require.identifier.lot){
                category.params.lot_identifier = encodeURIComponent(self.args.lot_identifier)
            }
            if(category.require && category.require.lot_type) {
              category.args.lot_type = self.args.lot_type
            }
            if(category.require && category.require.search_removed_inventory) {
              category.args.search_removed_inventory = self.args.search_removed_inventory ? 1 : 0
            }
            if (this.args) {
              Object.keys(this.args).forEach(function (item) {
                  if (item !== 'dates') {
                    category.args[item] = self.args[item];
                    console.log(item); // key
                  }
              });
            }
            // ^ This code does what the block below was meant to do (I believe)
            /* Seems to only impact report_ingredient_usage_by_date, and makes "group by" always selected
            if (category.options) {
                console.warn('HAS OPTIONS')
                Object.keys(category.options).forEach(function (item) {
                    category.args[item]=category.options[item];
                    console.log(item); // key
                    console.log(category.options[item]); // value
                });
            }
            */
            // set repport's spreadsheet url
            store.dispatch('reports/setExcelUrl', category).then(s => {})
            console.log({category})
            store.dispatch("reports/fetchReports", category).then(s => {self.showReport = false})
        }
    }
};
</script>

<style>
.el-date-editor--daterange{
    width: 100%!important;
}
.el-table__empty-block{
  display: none!important;
}
.el-table th>.cell{
  word-break: keep-all!important;
}
.el-table th div {
    padding-left: 4px!important;
    padding-right: 4px!important;
}
#VirtualScrollerTable .el-table__header-wrapper {
   overflow-x: scroll;
}
</style>
