import EventService from "@/services/EventService.js";
export const namespaced = true;

export const state = {
    pmk_config: {},
    route_controllers: {},
};

export const mutations = {
  SET_PMK_CONFIG(state, value) {
    state.pmk_config = value
  },
  SET_ROUTE_CONTROLLER(state, args) {
    state.route_controllers[args.endpoint] = args.controller
  }
}



export const actions = {
  setPMKConfig({ commit, dispatch }) {
    return EventService.pmk_fetch('pmk_config', 'GET', { version: 'v5' }, {}).then(response_data => {
      commit('SET_PMK_CONFIG', response_data.data.data)
      return response_data.data
    }).catch(e => {
      const notification = {
        message: 'An error occurred ' + e,
        type: 'warning'
      }
      dispatch('notification/add', notification, { root: true })
    })
  },
  setRouteController({ commit }, args) {
    commit('SET_ROUTE_CONTROLLER', args)
  },
}

export const getters = {
  pmk_config: state => {
    return state.pmk_config
  },
  route_controllers: state => {
    return state.route_controllers
  }
}
