import ReconnectingWebSocket from "reconnecting-websocket";
import EventService from "@/services/EventService.js";
import store from "@/store/store";
import moment from 'moment'
import axios from "axios";
import { Message } from 'element-ui';
const uuid_base64 = require("uuid-base64");

//import EquipmentStore from '@/store/equipment.js'
let current_equipment_url = ''
let current_equipment_url_secondary = ''
let current_adapter_port = '0'
let current_transmitter_device_identifier = ''

let wss_urls = [];
let wss_url_index = 0;

// round robin url provider
const urlProvider = () => wss_urls[wss_url_index++ % wss_urls.length]
// const urlProvider = () => current_equipment_url
let equipment_socket_connection = {};

const associate_device = function() {
  console.log(13, this)
  store.dispatch("equipment/set_current_device", this);
}


const associate_station = function() {
  store.dispatch("equipment/set_current_station", this);
  checkout_equipment(this);
}

const connect_to_balance = function() {
  checkout_equipment(this);
  if (this.equipment_type === "balance" && this.config.wss_url) {
    try {current_adapter_port = this.config.adapter_id}
    catch { console.log('could not set current_adapter_port', {config: this.config}) }
    try {current_transmitter_device_identifier = this.transmitter_device_identifier}
    catch { console.log('could not set current_transmitter_device_identifier', {transmitter_device_identifier: this.transmitter_device_identifier}) }

  if ((!this.config.transmitter_device_identifier && this.config.adpater_id < 35000) || !this.config.adapter_id) {
    console.log('Missing device id or port - aborting connect to balance')
    return
  }

  ws_subscribe(this, record_to_balance);
  }
}
;
// capture system barcodes:
let key_down_code = "";
// TODO - shoudl go into a config
const barcode_prefix = "^^-";
const barcode_suffix = "_$$";
// ^^-BALANCE_02_$$
let barcode_value = `ABC123`;
let prefix_captured = false;
let suffix_captured = true;
// document.addEventListener could be replaced with:
// https://github.com/shentao/vue-global-events
document.addEventListener(
  "keydown",
  function(e) {
    if (e.key == "Shift") {
      return;
    }
    switch (e.key) {
      case "Down": // IE/Edge specific value
      case "ArrowDown":
        // Do something for "down arrow" key press.
        break;
      case "Up": // IE/Edge specific value
      case "ArrowUp":
        // Do something for "up arrow" key press.
        break;
      case "Left": // IE/Edge specific value
      case "ArrowLeft":
        // Do something for "left arrow" key press.
        break;
      case "Right": // IE/Edge specific value
      case "ArrowRight":
        // Do something for "right arrow" key press.
        break;
      case "Enter":
        // Do something for "enter" or "return" key press.
        break;
      case "Esc": // IE/Edge specific value
      case "Escape":
        // Do something for "esc" key press.
        break;
      default: {
        let tmp_barcode = capture_keydown(e);
        if (tmp_barcode) {
          utilize_barcode(tmp_barcode);
          console.log(tmp_barcode);
          return tmp_barcode;
        }
        // else if () { // put somethign here that checks a vuex property and records a barcode?? }
        else {
          return;
        }
      }
    }
  },
  //true
  false
);

function capture_keydown(e) {
  //console.log(e)
  // console.warn(
  //   e.key,
  //   'TODO: has barcode prefix/suffix be updated?',
  //   barcode_prefix,
  //   barcode_suffix
  // )
  key_down_code = (key_down_code + e.key).substr(-3);
  if (suffix_captured && key_down_code == barcode_prefix) {
    prefix_captured = true;
    key_down_code = "";
    barcode_value = "";
  } else if (prefix_captured) {
    barcode_value = barcode_value + e.key;
  }
  if (key_down_code == barcode_suffix) {
    suffix_captured = true;
    prefix_captured = false;
    barcode_value = barcode_value.slice(0, -1 * barcode_suffix.length);
    key_down_code = "";
    return barcode_value;
  }
  if (barcode_value.length > 40) {
    suffix_captured = true;
    prefix_captured = false;
    key_down_code = "";
    barcode_value = "";
  }
  // console.log(barcode_value)
}

let checkout_equipment = function(equipment_config) {
  console.log("CHECKED_OUT: " + equipment_config.description, {equipment_config});

  if (equipment_config.transmitter_status == "offline") {
    console.log("EQUIPMENT OFFLINE: " + equipment_config.description);
    const notification = {
      type: 'warning',
      message: 'Warning: ' + equipment_config.description + ' is reported as offline'
    }
    store.dispatch('notification/add', notification, { root: true })
  }

  store.dispatch("equipment/checkout_equipment", equipment_config);
};

var equipment_data_buffer = "";
var current_equipment_config = {};
var current_equipment_cb = function() {};
function ws_use_data(e) {
  var reader = new FileReader();
  reader.onload = () => {
    // console.table([reader.result, equipment_data_buffer]);
    // console.log("current_equipment_config", current_equipment_config);
    let clear_buffer = false;
    if (reader.result && reader.result.match(/\n$/)) {
      clear_buffer = true;
    }
    equipment_data_buffer = equipment_data_buffer + reader.result;

    if (clear_buffer) {
      let current_adapter_from_equipment;
      try {
        current_adapter_from_equipment = current_equipment_config.config.adapter_id
      } catch {
        console.log('problem with current_equipment_config.config.adapter_id', { equipment_data_parsers, current_equipment_config })
      }

      let parsed_result = equipment_data_parsers[
        current_equipment_config.config.data_parser
      ].parser(equipment_data_buffer, current_adapter_from_equipment, current_transmitter_device_identifier);
      equipment_data_buffer = "";
      if (parsed_result) {
        current_equipment_cb(parsed_result)
      }
    }
  };
  // in some cases we get the data as text which then needs to be converted back to a Blob fo this
  let tmp_blob = typeof e.data == "object" ? e.data : (new Blob([e.data + "\n"], { type: 'text/plain' }));
  reader.readAsText(tmp_blob);
}

let ws_subscribe = function(equipment_config, cb) {
  // authenticate
  EventService.pmk_fetch(
    "api_login",
    "POST",
    { version: "v5" }
    //    { userID: 'rp-cs', password: 'abc123a' }
  ).then(function(data) {
    console.log({equipment_socket_connection})
    if (
      equipment_socket_connection &&
      equipment_socket_connection.readyState &&
      equipment_socket_connection.readyState ==  1
    ) {
      console.log('closing connection', {equipment_socket_connection})
      equipment_socket_connection.removeEventListener("message", ws_use_data);
      equipment_socket_connection.maxReconnectInterval=0;
      equipment_socket_connection.maxReconnectAttempts=0;
      equipment_socket_connection.close();
      // more needs to be done here

      console.log('closed', {urlProvider: urlProvider(), equipment_socket_connection: equipment_socket_connection})
    }

    // set urlProvider to be current equipment:
    current_equipment_url = equipment_config.config.wss_url
    current_equipment_url_secondary = equipment_config.config.wss_url_secondary
    wss_urls = []

    if( current_equipment_url ) { wss_urls.push(current_equipment_url) }
    if( current_equipment_url_secondary ) { wss_urls.push(current_equipment_url_secondary) }


    equipment_socket_connection = new ReconnectingWebSocket(
      urlProvider, null, {debug: false, reconnectInterval: 3000}
    );
    // set current equipment:
    current_equipment_config = equipment_config;
    current_equipment_cb = cb;
    equipment_socket_connection.addEventListener("message", ws_use_data);
  });

  return equipment_socket_connection;
};

// TODO: make this work with the lab config.  loop over config, if type is balance set equipment with on

let equipment_types = {
  balance: {
    configuration: function(config) {
      let device_wss_url = ((config.transmitter_device_identifier && config.port > 38000) ?
        `wss://ws2.electriclab.app/device/id/${config.transmitter_device_identifier}?port_number=${config.port}`
        : config.port > 35000 ?
          `wss://ws.electriclab.app/device/${config.port}`
          : `wss://ws2.electriclab.app:${Math.abs(config.port - (config.port < 35000 ? 10000 : 0))}`)
      let secondary_url = ''
      let device_wss_url_secondary = ''
      try {
        secondary_url = new URL(`wss://ws.electriclab.app/device/id/${config.transmitter_device_identifier}?port_number=${config.port}`)
        if (config.port < 38000) {
          secondary_url = new URL(`wss://ws2.electriclab.app/device/id/${config.transmitter_device_identifier}?port_number=${config.port}`)
        }
        secondary_url.host = 'ws.electriclab.app'
        device_wss_url_secondary = secondary_url.toString()
      } catch (e) {
        console.log('invalid config: ', e)
      }

      return {
        equipment_type: "balance",

        description: config.name || "Balance (default)",
        onScan: connect_to_balance,
        has_data: true,
        config: {
          adapter_id: config.port,
          data_parser: config.parser || "ohaus_adventurer",
          wss_url: device_wss_url,
          wss_url_secondary: device_wss_url_secondary,
          transmitter_device_identifier: config.transmitter_device_identifier
        }
      };
    }
  },

  printer: {
    configuration: function(config) {
      let device_url = `https://ws2.electriclab.app/device/id/${config.transmitter_device_identifier}`
      let secondary_url = ''
      let device_url_secondary = ''
      try {
        secondary_url = new URL(device_url)
        secondary_url.host = 'ws.electriclab.app'
        device_url_secondary = secondary_url.toString()
      } catch (e) {
        console.log('invlaid config: ', e)
      }
      return {
        equipment_type: "printer",
        description: config.name || "Printer (default)",
        config: {
          device_url: device_url,
          device_url_secondary: device_url_secondary,
          transmitter_device_identifier: config.transmitter_device_identifier
        },
        onScan: function() {
          store.dispatch("equipment/set_current_printer", this)
          return;
        },
      };
    }
  },

  device: {
    configuration: function(config) {
      return {
        equipment_type: "device",

        description: config.name || "Device (default)",
        onScan: associate_device,
        has_data: false,
        config: {

        }
      };
    }
  },

  station: {
    configuration: function(config) {
      return {
        equipment_type: "station",

        description: config.name || "station (default)",
        onScan: associate_station,
        has_data: false,
        config: {

        }
      };
    }
  },

  other: {
    configuration: function(config) {
      return {
        equipment_type: config.equipment_type || "other",

        description: config.name || config.equipment_type,
        onScan: function() {
          checkout_equipment(this);
          return;
        },
        has_data: false,
        config: {}
      };
    }
  }
};

let equipment_data_parsers = {
  auto_json: {
    parser: function(data, adapter_id, current_transmitter_device_identifier) {
      let json_result = null
      let is_match_transmitter_device_identifier = false
      try {
        json_result = JSON.parse(data)
        is_match_transmitter_device_identifier = (json_result.device_id && current_transmitter_device_identifier) && (current_transmitter_device_identifier.toLowerCase().trim() === json_result.device_id.toLowerCase().trim())
      } catch(e) {
        console.log('Error in equipment parser', e )
        console.warn(`Invalid data received from equipment: ${data}`);
      }


      if (
        is_match_transmitter_device_identifier ||
        (parseInt(adapter_id) === parseInt(current_adapter_port) && current_adapter_port)
      )
      {
        // console.log("parser[282]: ", data);
        try {
          if (json_result) {
            let is_match_transmitter_device_identifier = (json_result.device_id && current_transmitter_device_identifier) && (current_transmitter_device_identifier.toLowerCase() === json_result.device_id.toLowerCase())
            if (is_match_transmitter_device_identifier || (!json_result.device_id || !current_transmitter_device_identifier)) {

              if (_.isNumber(json_result.weight)) {
                console.log('balance result:', JSON.parse(JSON.stringify(json_result)))
                json_result.balance_id = adapter_id

                let lab_config = store.getters['formula/get_lab_config'].config

                if (_.isNil(_.get(lab_config, 'lot.require_current_balance_time', null)) ? true : lab_config.lot.require_current_balance_time) {
                  // check timestamps:
                  const five_seconds_ago = moment.utc().subtract(10, 'seconds').valueOf()
                  if (json_result.timestamp >= five_seconds_ago) {
                    return json_result
                  } else {
                    console.log('Time mis-match, device timezone: ', moment().utcOffset())
                    console.log('Time mis-match, device time: ', moment.utc().format('YYYY-MM-DD h:mm:ss A'))
                    console.log('Time mis-match, transmitter time: ', moment.unix(json_result.timestamp).format('YYYY-MM-DD h:mm:ss A'))
                    const tmp_human_timestamp = moment(json_result.timestamp).format('YYYY-MM-DD hh:mm:ss a')
                    json_result.message = [`Last entry from adapter older than 10 seconds: ${tmp_human_timestamp}`, json_result.message].join(';\n')
                    json_result.allow_recording = false
                    return json_result
                  }
                } else {
                  return json_result
                }

              } else {
                console.warn('non-number auto_json balance result', json_result)
                const balance_result =
                {
                  balance_id: adapter_id,
                  device_id: json_result.device_id,
                  weight: 0,
                  unit: 'g',
                  allow_recording: false,
                }
                if (json_result.messages) {
                  const message_for_display = json_result.messages.map(msg => {
                    return msg.message
                  })
                  balance_result.message = message_for_display.join("\n")
                }
                // console.warn('balance_result', balance_result)
                return balance_result
              }
              // TODO: show any messages
            } else {
              // mis-match device_id
              console.warn('Passed on message from wrong balance.', {
                current_adapter_port,
                line: 329,
                file: 'services/equipment.js',
                received_adapter_id: adapter_id,
                current_transmitter_device_identifier,
                transmitter_device_identifier:  json_result.device_id,
                transmitter_version: json_result.version
              })
              // skip this
              return
            }
          }
        }
        catch(e) {
          console.log('Error in equipment parser', e )
          console.warn(`Invalid data received from equipment: ${data}`);
        }
      } else {
        console.warn('Passed on message from wrong balance.', { current_adapter_port: current_adapter_port || null, received_adapter_id: adapter_id })
      }
    }
  },
  nodecimal: {
    parser: function(data, adapter_id, current_transmitter_device_identifier) {
      let json_result = null
      let is_match_transmitter_device_identifier = false
      try {
        json_result = JSON.parse(data)
        is_match_transmitter_device_identifier = (json_result.device_id && current_transmitter_device_identifier) && (current_transmitter_device_identifier.toLowerCase().trim() === json_result.device_id.toLowerCase().trim())
      } catch(e) {
        console.log('Error in equipment parser', e )
        console.warn(`Invalid data received from equipment: ${data}`);
      }
      if (
        is_match_transmitter_device_identifier ||
        (parseInt(adapter_id) === parseInt(current_adapter_port) && current_adapter_port)
      )
      {
        // console.log("parser[282]: ", data);
        try {
          if (json_result) {
            let is_match_transmitter_device_identifier = (json_result.device_id && current_transmitter_device_identifier) && (current_transmitter_device_identifier.toLowerCase() === json_result.device_id.toLowerCase())
            if (is_match_transmitter_device_identifier || (!json_result.device_id || !current_transmitter_device_identifier)) {


              if (json_result.message.includes('Could not read')) {
                console.log('balance result:', JSON.parse(JSON.stringify(json_result)))
                let re = /Could not read: '(-?\d+)\sg/i
                let found = json_result.message.match(re)
                if (!isNaN(parseInt(found[1]))) {
                  json_result.balance_id = adapter_id

                  let lab_config = store.getters['formula/get_lab_config'].config

                  if (_.isNil(_.get(lab_config, 'lot.require_current_balance_time', null)) ? true : lab_config.lot.require_current_balance_time) {
                    // check timestamps:
                    const five_seconds_ago = moment.utc().subtract(10, 'seconds').valueOf()
                    if (json_result.timestamp >= five_seconds_ago) {
                      let result = {
                        weight: parseInt(found[1]) || 0,
                        unit: json_result.unit,
                        allow_recording: true
                      }
                      console.log('result: ', result)
                      return result
                    } else {
                      console.log('Time mis-match, device timezone: ', moment().utcOffset())
                      console.log('Time mis-match, device time: ', moment.utc().format('YYYY-MM-DD h:mm:ss A'))
                      console.log('Time mis-match, transmitter time: ', moment.unix(json_result.timestamp).format('YYYY-MM-DD h:mm:ss A'))
                      const tmp_human_timestamp = moment(json_result.timestamp).format('YYYY-MM-DD hh:mm:ss a')
                      json_result.message = [`Last entry from adapter older than 10 seconds: ${tmp_human_timestamp}`, json_result.message].join(';\n')
                      json_result.allow_recording = false
                      return json_result
                    }
                  } else {
                    let result = {
                      weight: parseInt(found[1]) || 0,
                      unit: json_result.unit,
                      allow_recording: true
                    }
                    console.log('result: ', result)
                    return result
                  }

                } else {
                  console.warn('non-number result found', found)
                  const balance_result =
                  {
                    balance_id: adapter_id,
                    device_id: json_result.device_id,
                    weight: 0,
                    unit: 'g',
                    allow_recording: false,
                    message: json_result.message
                  }
                  return balance_result
                }
              }


            } else {
              // mis-match device_id
              console.warn('Passed on message from wrong balance.', {
                current_adapter_port,
                line: 329,
                file: 'services/equipment.js',
                received_adapter_id: adapter_id,
                current_transmitter_device_identifier,
                transmitter_device_identifier:  json_result.device_id,
                transmitter_version: json_result.version
              })
              // skip this
              return
            }
          }
        }
        catch(e) {
          console.log('Error in equipment parser', e )
          console.warn(`Invalid data received from equipment: ${data}`);
        }
      } else {
        console.warn('Passed on message from wrong balance.', { current_adapter_port: current_adapter_port || null, received_adapter_id: adapter_id })
      }
    }
  },
  cole_parmer: {
    parser: function(data) {
      let time_stamp = new Date().toJSON();
       console.log(`parser: [${time_stamp}]`, data);
      let string_data = data.toString(16).trim();
      let re = /^\s*(\-)?\D*(\d+\.?\d*)\s+(\S+)\s*(NET|N)?\s*$/i;
      let found = string_data.match(re);
       console.log("parser-found: ", found);
      if (found) {
        if (found[1] == "-") {
          return {
            weight: -1 * parseFloat(found[2]),
            unit: found[3],
            allow_recording: false
          };
        }
        let result = {
          weight: parseFloat(found[2]) || 0,
          unit: found[3],
          allow_recording: true
        };
        console.log('result: ', result)
        return result;
      }
      // console.warn(`invalid data received from equipment: ${data} - ${found}`);
    }
  },
  ohaus_adventurer: {
    parser: function(data) {
       console.log("parser: ", '[' + data + ']');
       let string_data = data.toString(16).trim();

      let re = /^\s*(\-)?\D*(\d+\.?\d*)\s+(\S+)\s*(NET|N)?\s*$/i;
      let found = string_data.match(re);
       console.log("parser-found: ", found);
      if (found) {
        if (found[1] == "-") {
          return {
            weight: -1 * parseFloat(found[2]),
            unit: found[3],
            allow_recording: false
          };
        }
        let result = {
          weight: parseFloat(found[2]) || 0,
          unit: found[3],
          allow_recording: true
        };
         console.log(result)
        return result;
      }
       console.warn(`ohaus_adventurer: invalid data received from equipment: ${data} - ${found}`);
    }
  },
  and: {
    parser: function(data) {
      // console.log("parser: ", data);
      let re = /(?:US|ST)\s*[,:]?([\+\-])?\s*(\d+\.\d+)\s*(mg|g|mcg)\s*$/i;
      let found = data.match(re);
      // console.log("parser-found: ", found);
      if (found[1] == "-") {
        return {
          weight: -1 * parseFloat(found[2]),
          unit: found[3],
          allow_recording: false
        };
      }

      if (found) {
        let result = {
          weight: parseFloat(found[2]) || 0,
          unit: found[3],
          allow_recording: true
        };
        // console.log(result)
        return result;
      }
      console.warn(`and: invalid data received from equipment: ${data} - ${found}`);
    }
  },
  // duplicate to support case "AND" - to be removed:
  AND: {
    parser: function(data) {
      // console.log("parser: ", data);
      let re = /(?:US|ST)\s*[,:]?([\+\-])?\s*(\d+\.\d+)\s*(mg|g|mcg)\s*$/i;
      let found = data.match(re);
      // console.log("parser-found: ", found);
      if (found[1] == "-") {
        return {
          weight: -1 * parseFloat(found[2]),
          unit: found[3],
          allow_recording: false
        };
      }

      if (found) {
        let result = {
          weight: parseFloat(found[2]) || 0,
          unit: found[3],
          allow_recording: true
        };
        // console.log(result)
        return result;
      }
      console.warn(`AND: invalid data received from equipment: ${data} - ${found}`);
    }
  },
  TOMS: {
    parser: function(data) {
      // console.log("parser: ", data);
      let re = /(?:US|ST)\s*[,:]?([\+\-])?\s*(\d+\.\d+)\s*(mg|g|mcg)\s*$/i;
      let found = data.match(re);
      // console.log("parser-found: ", found);
      if (found[1] == "-") {
        return {
          weight: -1 * parseFloat(found[2]),
          unit: found[3],
          allow_recording: false
        };
      }

      if (found) {
        let result = {
          weight: parseFloat(found[2]) || 0,
          unit: found[3],
          allow_recording: true
        };
        // console.log(result)
        return result;
      }
      console.warn(`TOMS: invalid data received from equipment: ${data} - ${found}`);
    }
  }
};
// quick parser test:
//
// var data = `10.25`
// var re = /\D*(\d+\.?\d*)\s*(\D*)\s*$/i
// var found = data.match(re)
// console.log(parseFloat(found[1]))

export const utilize_barcode = function(barcode) {
  console.log("barcode: ", barcode);
  // if it is known equipment, use it
  let equipment_list = store.getters["equipment/get_equipment_list"];
  if (equipment_list[barcode]) {
    console.log('equipment_list[barcode]', equipment_list[barcode])
    equipment_list[barcode].onScan();
  } else {
    // search inventory:
    // barcode = `Jn13LyBdIUT6TOxDxqCoxk`
    let decompressed_scan = uuid_base64.decode(barcode);
    // emit an event for a component to consume:
    let scanned_code_event = new CustomEvent("scanned-code", {
      detail: { code: decompressed_scan }
    });
    document.dispatchEvent(scanned_code_event);

    console.log(decompressed_scan);
  }
};

function record_to_balance(data) {
  store.dispatch("equipment/set_balace_state_weight", data);
}


// data services:

// equipment:
export default {
  barcode_prefix,
  barcode_suffix,

  getEquipmentList() {
    return EventService.pmk_fetch(
      "electric_lab_get_equipment_list",
      "GET",
      {'version': 'v5'}
    ).then(response => {
      let list = {};
      Object.entries(response.data.data).forEach(function([key, element]) {
        try {
          let equipment_config = {};
          if (equipment_types[element.data.equipment_type.toLowerCase()]) {

            equipment_config = equipment_types[
              element.data.equipment_type.toLowerCase()
            ].configuration(element.data);
            //          equipment_config.setting = element;
            equipment_config = { ...element.data, ...equipment_config };
          } else {
            equipment_config = equipment_types.other.configuration(element.data);
            //          equipment_config.setting = element;
            equipment_config = { ...element.data, ...equipment_config };
          }
          // set the status:
          equipment_config.transmitter_status =  element.transmitter_status
          list[key] = equipment_config;
        } catch(e) {
          console.log('e', e)
        }
      });
      console.log({list})
      return list;
    });
  },
  getEquipment() {
    // unimplemented
    return EventService.pmk_fetch("electric_lab_get_equipment", "GET", {});
  },
  saveEquipment(query_object) {
    return EventService.pmk_fetch(
      "electric_lab_update_equipment",
      "PUT",
      { identifier: query_object.identifier },
      query_object
    );
  },
  unsubscribeWs(){
    // console.log('unsubscribeWs - current state:', urlProvider())
    current_equipment_url = 'ws://localhost:65534' // should be dead port
    if(equipment_socket_connection.readyState ==  1 ) {
      equipment_socket_connection.removeEventListener("message", ws_use_data);
      equipment_socket_connection.maxReconnectInterval=0;
      equipment_socket_connection.maxReconnectAttempts=0;
      equipment_socket_connection.close();
    }

  },
  send_to_equipment_printer(args) {
    let url_label = ''
    if (args.url) {
      url_label = args.url
    }
    let checked_out_printer = store.getters["equipment/get_equipment_list_checked_out"].filter(obj => obj.equipment_type === 'printer')
    if (checked_out_printer[0]) {
      let printer_config = store.getters["equipment/get_equipment_list"][checked_out_printer[0].identifier]
      console.log('printer config:', printer_config)
      let request_url = printer_config.config.device_url_secondary + '/print?label_url=' + encodeURIComponent(url_label)
      return axios.post(request_url, {}, {
        withCredentials: true,
        auth: {
          username: 'electriclab',
          password: 'printer_auth_4767'
        },
      }).then(e => {
        console.log('returned', e)
        if (e.data.success) {
          return true
        } else {
          request_url = printer_config.config.device_url + '/print?label_url=' + encodeURIComponent(url_label)
          return axios.post(request_url, {}, {
            withCredentials: true,
            auth: {
              username: 'electriclab',
              password: 'printer_auth_4767'
            },
          }).then(e => {
            console.log('returned', e)
            if (e.data.success) {
              return true
            }
          }).catch(e => {
            console.log('caught', e)
            Message({
              message: 'Could not reach printer',
              type: 'error'
            })
            return false
          })
        }
      }).catch(e => {
        console.log('caught', e)
        request_url = printer_config.config.device_url + '/print?label_url=' + encodeURIComponent(url_label)
        return axios.post(request_url, {}, {
          withCredentials: true,
          auth: {
            username: 'electriclab',
            password: 'printer_auth_4767'
          },
        }).then(e => {
          console.log('returned', e)
          if (e.data.success) {
            return true
          }
        }).catch(e => {
          console.log('caught', e)
          Message({
            message: 'Could not reach printer',
            type: 'error'
          })
          return false
        })
      })
    }
    return new Promise((resolve, reject) => {
      resolve(false);
    });
  }
};
