<template>
  <el-card
    class="box-card"
    style="margin-bottom: 1.5em;"
  >
    <div slot="header" class="clearfix">
      <span>
        <span style="font-size: 1.5em">Manufacturer: {{manufacturer.name}}</span>
        &nbsp;
        <span class="text-secondary"></span>
      </span>
      &nbsp;
      <el-button
        type="text"
        @click="collapse = !collapse">
        <span v-show="collapse"><i class="far fa-chevron-double-down"></i></span>
        <span v-show="!collapse"><i class="far fa-chevron-double-up"></i></span>
      </el-button>

      <el-button
        type="default"
        class="status-button"
        style="float: right; padding: 5px"
        @click="saveManufacturer(manufacturer)"
      >
        <i class="fal fa-save"></i> Save
      </el-button>
    </div>
    <div v-if="!collapse">
      <form autocomplete="off">
        <el-row :gutter="10" class="property-row">
          <el-col :span="4">
            <span>
              <span style="font-size: 1.5em">Name:</span>
              &nbsp;
              <span class="text-secondary"></span>
            </span>
          </el-col>
          <el-col :span="16">
            <el-input v-model="manufacturer.name"></el-input>
          </el-col>
          <el-col :span="2"></el-col>
          <el-col :span="2"></el-col>
        </el-row>

        <el-row :gutter="10" class="property-row">
          <el-col :span="4">
            <span>
              <span style="font-size: 1.5em">Phone:</span>
              &nbsp;
              <span class="text-secondary"></span>
            </span>
          </el-col>
          <el-col :span="16">
            <el-input v-model="manufacturer.phone"></el-input>
          </el-col>
          <el-col :span="2"></el-col>
          <el-col :span="2"></el-col>
        </el-row>

        <el-row :gutter="10" class="property-row">
          <el-col :span="4">
            <span>
              <span style="font-size: 1.5em">Fax:</span>
              &nbsp;
              <span class="text-secondary"></span>
            </span>
          </el-col>
          <el-col :span="16">
            <el-input v-model="manufacturer.fax"></el-input>
          </el-col>
          <el-col :span="2"></el-col>
          <el-col :span="2"></el-col>
        </el-row>

        <el-row :gutter="10" class="property-row">
          <el-col :span="4">
            <span>
              <span style="font-size: 1.5em">Email:</span>
              &nbsp;
              <span class="text-secondary"></span>
            </span>
          </el-col>
          <el-col :span="16">
            <el-input v-model="manufacturer.email"></el-input>
          </el-col>
          <el-col :span="2"></el-col>
          <el-col :span="2"></el-col>
        </el-row>

        <el-row :gutter="10" class="property-row">
          <el-col :span="4">
            <span>
              <span style="font-size: 1.5em">URL:</span>
              &nbsp;
              <span class="text-secondary"></span>
            </span>
          </el-col>
          <el-col :span="16">
            <el-input v-model="manufacturer.url"></el-input>
          </el-col>
          <el-col :span="2"></el-col>
          <el-col :span="2"></el-col>
        </el-row>

        <el-row :gutter="10" class="property-row">
          <el-col :span="4">
            <span>
              <span style="font-size: 1.5em">Username:</span>
              &nbsp;
              <span class="text-secondary"></span>
            </span>
          </el-col>
          <el-col :span="16">
            <el-input v-model="manufacturer.username" :autocomplete="false"></el-input>
          </el-col>
          <el-col :span="2"></el-col>
          <el-col :span="2"></el-col>
        </el-row>

        <el-row :gutter="10" class="property-row">
          <el-col :span="4">
            <span>
              <span style="font-size: 1.5em">Password:</span>
              &nbsp;
              <span class="text-secondary"></span>
            </span>
          </el-col>
          <el-col :span="16">
            <el-input v-model="manufacturer.password" show-password :autocomplete="false"></el-input>
          </el-col>
          <el-col :span="2"></el-col>
          <el-col :span="2"></el-col>
        </el-row>

        <el-row :gutter="10" class="property-row">
          <el-col :span="4">
            <span>
              <span style="font-size: 1.5em">Account #:</span>
              &nbsp;
              <span class="text-secondary"></span>
            </span>
          </el-col>
          <el-col :span="16">
            <el-input v-model="manufacturer.account"></el-input>
          </el-col>
          <el-col :span="2"></el-col>
          <el-col :span="2"></el-col>
        </el-row>
      </form>
    </div>
  </el-card>
</template>

<script>
export default {
  props: ["manufacturer"],
  data() {
    return {
      collapse: true
    }
  },
  mounted() {
    if (!this.manufacturer.name) {
      this.collapse = false
    }
  },
  methods: {
    saveManufacturer(manufacturer) {
      this.$store
        .dispatch('manufacturer/saveManufacturer', manufacturer)
        .then(data => {
          console.log(data)
        })
    }
  }
}
</script>

<style lang="css" scoped>
.property-row {
    margin: 10px 0px;
}
</style>
