import EventService from '@/services/EventService.js'

export const namespaced = true

export const state = {
  ingredient: {},
  ingredients: [],
  manufacturers: [],
  manufacturer: {},
  ingredient_search_list: [],
  inventory_recent: []
}

export const mutations = {
  ADD_INGREDIENT(state, data) {
    state.ingredients.push(data)
  },
  ADD_MANUFACTURER(state, data) {
    state.manufacturers.push(data)
  },
  SET_INGREDIENT(state, data) {
    state.ingredient = data
  },
  SET_INGREDIENT_SEARCH_LIST(state, data) {
    state.ingredient_search_list = data
  },

  ADD_INVENTORY_RECENT(state, data) {
    state.inventory_recent.push(data)
  },

  SET_TASKS_TOTAL(state, tasksTotal) {
    state.tasksTotal = tasksTotal
  },
  SET_EVENT(state, event) {
    state.task = event
  }
}

export const actions = {
  createIngredient({ commit, dispatch }, data) {
    return EventService.createIngredient(data)
      .then((http_response) => {
        if (http_response.data.success) {
          commit('ADD_INGREDIENT', data)
          commit('SET_INGREDIENT', data)
          const notification = {
            type: 'success',
            message: 'Ingredient has been saved!'
          }
          dispatch('notification/add', notification, { root: true })
        } else {
          http_response.data.messages.forEach(message => {
            dispatch('notification/add', message, { root: true })
          })
          throw 'error'
        }
      })
      .catch(error => {
        const notification = {
          type: 'error',
          message:
            'There was a problem creating your ingredient: ' + error.message
        }
        dispatch('notification/add', notification, { root: true })
        throw error
      })
  },
  listIngredients({ commit, dispatch }, data) {
    return EventService.listIngredients(data)
      .then(results => {
        // console.log('61results')
        console.log(results)
        commit('SET_INGREDIENT_SEARCH_LIST', results.data.data)
      })
      .catch(error => {
        const notification = {
          type: 'error',
          message:
            'There was a problem searching for your ingredient: ' +
            error.message
        }
        dispatch('notification/add', notification, { root: true })
        throw error
      })
  },
  inventoryLog({ commit, dispatch }, data) {
    return EventService.inventoryLog(data)
      .then(() => {
        commit('ADD_INVENTORY_RECENT', data)
        const notification = {
          type: 'success',
          message: 'Inventory has been saved!'
        }
        dispatch('notification/add', notification, { root: true })
      })
      .catch(error => {
        const notification = {
          type: 'error',
          message:
            'There was a problem logging your inventory: ' + error.message
        }
        dispatch('notification/add', notification, { root: true })
        throw error
      })
  },
  receive_ingredient_inventory({ commit, dispatch }, data) {
    return EventService.receive_ingredient_inventory(data)
      .then((response) => {
        console.log('receive_ingredient_inventory', response)
        let messages = response.data.messages || []
        if( response.data.success ) {
          commit('ADD_INVENTORY_RECENT', data)
          if (messages.length == 0) {
            messages.push({
              type: 'success',
              message: 'Inventory has been saved!'
            })
          }
        }

        messages.forEach(notification => {
          dispatch('notification/add', notification, { root: true })
        })
        return response.data
      })
      .catch(error => {
        const notification = {
          type: 'error',
          message:
            'There was a problem logging your inventory: ' + error.message
        }
        dispatch('notification/add', notification, { root: true })
        throw error
      })
  },

  fetchTasks({ commit, dispatch, state }, { page }) {
    return EventService.getTasks(state.perPage, page)
      .then(response => {
        //commit('SET_TASKS_TOTAL', parseInt(response.headers['x-total-count']))
        commit('SET_TASKS_TOTAL', response.data.length || 98)
        commit('SET_TASKS', response.data)
      })
      .catch(error => {
        const notification = {
          type: 'error',
          message: 'There was a problem fetching tasks: ' + error.message
        }
        commit('SET_TASKS_TOTAL', 98)

        dispatch('notification/add', notification, { root: true })
      })
  },
  fetchTask({ commit, getters, dispatch, state }, id) {
    if (id == state.task.id) {
      return state.task
    }

    var event = getters.getEventById(id)

    if (event) {
      commit('SET_EVENT', event)
      return event
    } else {
      return EventService.getTask(id)
        .then(response => {
          commit('SET_EVENT', response.data)
          return response.data
        })
        .catch(error => {
          const notification = {
            type: 'error',
            message: 'There was a problem fetching event: ' + error.message
          }
          dispatch('notification/add', notification, { root: true })
        })
    }
  },
  fetchIngredient({ commit, getters, dispatch, state }, ingredient_identifier) {
      return EventService.pmk_fetch('electric_lab_ingredient_get_by_id', 'GET', { ingredient_id: ingredient_identifier})
  }
}
export const getters = {
  getEventById: state => id => {
    return state.tasks.find(event => event.id === id)
  },
  ingredient_search_list: state => {
    return state.ingredient_search_list
  }
}
