<template>
  <span class="pmk_task_template_style">
    <el-row>
      <el-col :span="24">
        <el-card class="box-card">
          <el-row>
            <el-col :span="16">
              <span v-if="task.task_status === 'complete'" style="font-size: 24px;">
                <i class="fas fa-check-circle" style="color:#39b982"></i>
                Task Complete <br>
              </span>
              {{ task_data.task && task_data.task.task_display_name }}
            </el-col>
            <el-col :offset="5" :span="3">
              <span class="task-title-task_id" style="
                text-align: right;
                float: right;
                font-size: 0.65em;">
                  Task ID: {{ task_data.task.id }}
                  <div v-if="config_allow_technicians_to_scrap_lot">
                    <el-popover
                      placement="bottom"
                      width="200"
                      :disabled="task_complete"
                      trigger="click">
                      <div class="">
                        <p style="word-break: break-word;padding: 5px;">Are you sure you want to cancel this?</p>
                        <el-button style="float:right" size="mini" type="primary" @click="cancelTask">Yes</el-button>
                      </div>
                      <el-button slot="reference" type="text" class="danger" size="small" style="color: #F56C6C">Cancel</el-button>
                    </el-popover>
                </div>
                <div v-if="task_data.lot && task_data.lot.lot_number">
                  Lot #: {{task_data.lot.lot_number}}
                </div>
                <div v-if="$store.getters['lot/getLot'] && !$store.getters['lot/getLot'].saved">
                  <span class="clickable" v-if="!editing_due_date" @click="editing_due_date = true">
                    Due: {{moment_utc(task_data.task.date_due).format('YYYY-MM-DD hh:mm a')}}
                  </span>
                  <span v-else style="display: flex; flex-direction: row;">
                    <el-date-picker
                      v-model="task_data.task.date_due"
                      type="datetime"
                      size="mini"
                      placeholder="Select new due date">
                    </el-date-picker>
                    <el-button type="text" style="margin: -5px 0px 0px 5px;" @click="update_task_due_date">
                      <i class="fas fa-save"></i>
                    </el-button>
                  </span>
                </div>
              </span>
            </el-col>
          </el-row>

          <el-row>
            <el-col :md="12" :xs="24" :lg="12" :sm="12">
              <span>Note: </span>

              <div v-html="task.data.task_note"></div>
            </el-col>
            <el-col :md="12" :xs="24" :lg="12" :sm="12">
              <el-form :inline="true" >
                <el-form-item label="Priority" class="custom-el-form">
                  <el-select v-model="task.task_priority" placeholder="Choose Level" @change="upadateTaskPriority()" :disabled="task_complete">
                    <el-option v-for="pr in priorities" :label="pr.label" :value="pr.value" :key="pr.value" />
                  </el-select>
                </el-form-item>
              </el-form>
            </el-col>
          </el-row>
          <!-- <pre>[{{(task)}}]</pre> -->

          <el-row>
            <el-col>
              <div v-if="task.task_status === 'complete' && task.task_type !== 'lot_create'">
                <div v-if="task.task_state && task.task_state.lot_identifier">
                  <el-button
                    type="default"
                    class="status-button"
                    style="padding: 10px; margin: 0.5em;"
                    @click="open_item_in_inventory(task.task_state.lot_identifier)"
                  >
                    View Item in Inventory
                  </el-button>
                </div>
              </div>
              <component v-bind:is="currentTaskView(task)" :task="task_data" @complete="task_complete = true"></component>
            </el-col>
          </el-row>

          <el-row>
            <hr />
            <el-col />
          </el-row>

          <!-- date information below the task -->
          <!-- <commonDate :task="task" task_template="current_working_task"></commonDate> -->
        </el-card>
      </el-col>
    </el-row>

    <el-row />

    <span slot="footer" class="dialog-footer">
      <!-- <pmk-tasks-task-buttons :task="task" task_template="current_working_task"></pmk-tasks-task-buttons> -->
    </span>
  </span>
</template>

<script>
import commonDate from "./commonDate";
import commonButtons from "./commonButtons";
import TaskService from '@/services/task.js'
import _ from 'lodash'
import inventoryReview from '@/views/ingredient/inventory_review.vue'
import moment from 'moment'

const pmk_task_type_templates = {
  incoming_fax: "pmk-fax-form",
  prior_authorization_specialty_therapy: "pmk-tasks-prior-authorization-specialty-therapy",
  prior_authorization_new_patient: "pmk-tasks-prior-authorization-new-patient",
  prior_authorization_initiate_prior_authorization: "pmk-tasks-prior-authorization-initiate-prior-authorization",
  prior_authorization_pharmacist_medication_review: "pmk-tasks-prior-authorization-pharmacist-medication-review",
  prior_authorization_call_new_patient: "pmk-tasks-prior-authorization-call-new-patient",
  prior_authorization_status_followup: "pmk-tasks-prior-authorization-status-followup",
  prior_authorization_status_additional_information_required: "pmk-tasks-prior-authorization-status-additional-information-required",
  contact_prescriber_for_medication_review: "pmk-tasks-contact-prescriber-for-medication-review",
  labs_required: "pmk-tasks-labs-required",
  patient_assessment_required: "pmk-tasks-patient-assessment",
  prior_authorization_status_denial_follow_up: "pmk-tasks-prior-authorization-denied-follow-up",
  patient_originated_authorization_transfer: "pmk-tasks-patientoriginated-authorization-transfer",
  "SMS-IN": "pmk-tasks-SMS-IN",
  medication_request_new: "pmk-tasks-medication-request-order",
  Message: "pmk-tasks-provider-message",
  Patient_Info: "pmk-tasks-provider-portal-patient-demographics-update",
  pharmacist_patient_encounter: "pmk-tasks-pharmacist-patient-encounter",
  // formula_rework: 'pmk-tasks-formula-rework',
  formula_rework: "taskFormulaRework",
  formula_verify: "taskFormulaVerify",
  formula_verify_second: "taskFormulaVerify",
  lot_create: "taskLotCreate",
  // TODO: separate these, but use common components:
  lot_check: "taskLotCreate",
  lot_rework: "taskLotCreate",
  compounding_request: "compoundingRequest",
  inventory_review: "inventoryReview",
  perform_sop: 'sop',

  voicemail_patient: "taskFormulaRework"
};

export default {
  components: {
    commonDate,
    commonButtons,
    inventoryReview
  },
  mounted() {
    try {
      if (window._mfq) {
        window._mfq.push(["config", "path", this.$route.fullPath]);
      }
      if (mouseflow) {
        mouseflow.start()
      }
    } catch(e) {
      console.warn(e)
    }
  },
  beforeRouteLeave (to, from, next) {
    try {
      if (window._mfq) {
        window._mfq.push(["stop"]);
      }
    } catch(e) {
      console.warn(e)
    }
    if (this.task && this.task.task_status === 'pending') {
      this.$confirm('Are you sure you want to leave this page?', {
          confirmButtonText: 'Yes',
          cancelButtonText: 'Cancel',
          type: 'warning',
        }).then(()  => {
          next()
        }).catch(() => {
          next(false)
        })
    } else {
      next()
    }
  },

  data() {
    return {
      counter: 0,
      priorities : [
        {
          label:'Normal', value: 1
        },
        {
          label:'Elevated', value: 2
        },
        {
          label:'Urgent', value: 3
        }
      ],
      editing_due_date: false,
      new_due_date: '',
      task_complete: false,
    };
  },
  computed: {
    task: {
      get() {
        return this.$store.getters["task/get_task"]["task"];
      }
    },
    formula: {
      get() {
        return this.$store.getters["task/get_task"]["formula"];
      }
    },
    task_data: {
      get() {
        return this.$store.getters["task/get_task"];
      }
    },
    lab_config: {
      get() {
        if (!this.$store.getters["formula/get_lab_config"]) {
          this.$store.dispatch("formula/fetchLabConfig");
        }
        if (this.$store.getters["formula/get_lab_config"]) {
          return this.$store.getters["formula/get_lab_config"]["config"]
        } else return {};
      }
    },
    config_allow_technicians_to_scrap_lot: {
      get() {
        return _.get(this.lab_config, 'lot.allow_technicians_to_scrap_lot', true)
      }
    }
  },
  methods: {
    moment_utc(time) {
      return moment.utc(time).local()
    },
    currentTaskView(current_task) {
      return pmk_task_type_templates[current_task.task_type];
    },
    upadateTaskPriority(){
      TaskService.update_task_status(this.task);
    },
    cancelTask() {
      this.task.task_status = 'cancelled'
      TaskService.update_task_status(this.task)
        .then(() => this.$notify({ title: 'Task Cancelled', message: 'Task has been cancelled' }))
    },
    update_task_due_date() {
      TaskService.update_task_status(this.task);
      this.editing_due_date = false
    },
    open_item_in_inventory(identifier){
      this.$router.push({ name: 'ingredient-inventory-item', params: { identifier }})
    },
  }
};
</script>

<style scoped>
.custom-el-form{
  float: right!important;
  margin-bottom: 4px!important;
  margin-right: 0!important;
}
</style>
