<template>
<div>
<el-form label-position="top" label-width="100px" :model="form" v-if="!toprint">
        <el-form-item label="Stability Supporting Documentation">
        <el-input
            type="textarea"
            autosize
            v-model="localDetails.stability_supporting_documentation"
        ></el-input>
        </el-form-item>

        <el-form-item label="Chemical Compatibility">
        <el-input type="textarea" autosize v-model="localDetails.chemical_compatibility"></el-input>
        </el-form-item>

        <el-form-item label="Packaging">
        <el-input type="textarea" autosize v-model="localDetails.packaging"></el-input>
        </el-form-item>

        <el-form-item label="Storage Instructions">
        <el-input type="textarea" autosize v-model="localDetails.storage_instructions"></el-input>
        </el-form-item>

        <el-form-item label="Final Appearance">
        <el-input type="textarea" autosize v-model="localDetails.final_appearance"></el-input>
        </el-form-item>

        <el-form-item label="SOP/Reference Documentation">
        <el-input
            type="textarea"
            autosize
            v-model="localDetails.reference_documentation"
        ></el-input>
        </el-form-item>
    </el-form>
    <div v-if="toprint">
        <printabledetails :details="localDetails"></printabledetails>
    </div>
</div>
 
</template>

<script>
import printabledetails from "../printable/printable_details"
export default {
    name:"detailspart",
    props: {
        "details":Object,
        'form':Object,
        "toprint": {
            type: Boolean,
            default: false
        },
    },
    components: {
        printabledetails
    },
    data(){
        return {
            localDetails : JSON.parse(JSON.stringify(this.details))
        }
    },
    watch: {
        localDetails: {
            handler(v){
                this.$emit('updateDetails', v)
             },
          deep: true
        }
    },
    methods: {

    }
};
</script>

<style>
</style>
