<template>
<el-row>
    <div style="margin-top: 15px;">
        <!-- EL-13: Make this a scrollable table -->
        <b>Inventory history</b>
        <hr>
        <div class="bottom clearfix">
        <el-table class="break-word"
          :data="inventoryHistory"
          :height="inventoryHistory && inventoryHistory.length > 0 ? 250 : 0"
        >
            <el-table-column label="Date Compounded" class="break-word">
                <template slot-scope="scope">
                {{scope.row.date_entered_inventory   | moment('YYYY-MM-DD')}}
                </template>
            </el-table-column>
            <el-table-column prop="quantity_initial" label="Quantity" class="break-word">
              <template slot-scope="scope">
                {{scope.row.quantity_initial === 'null' ? '[Scrapped]' : scope.row.quantity_initial || '[Scrapped]'}}
              </template>
            </el-table-column>
            <el-table-column prop="current_inventory.quantity_remaining" label="QTY remaining" class="break-word">
            </el-table-column>
            <el-table-column
                prop="lot_number"
                label="Lot#" class="break-word">
                <template slot-scope="scope">
                  <span>
                    <router-link  :to="{ name: 'ingredient-inventory-item', params: { identifier: scope.row.identifier }}"  >{{ scope.row.lot_number }}</router-link>
                    <span v-if="scope.row.inventory_type === 'removed'">
                      &nbsp;<i class="fad fa-trash-alt" style="font-size: 1em;"></i>
                    </span>
                  </span>
                </template>
              </el-table-column>
        </el-table>
        </div>
    </div>
</el-row>
</template>

<script>
export default {
    name:"inventoryhistorypart",
    props: ["identifier"],
    computed: {
      inventoryHistory : {
        get(){
          let inventory_history = this.$store.getters['formula/get_inventory_history']
          inventory_history.sort((a,b) => {
            return new Date(b.date_entered_inventory) - new Date(a.date_entered_inventory)
          })
          return inventory_history
        }
      }
    },
    watch:{
        identifier(oldVal,newVAl){
           this.getHistory()
        }
    },
    methods: {
        getHistory(){
            this.$store.dispatch('formula/getInventoryHistory', this.identifier);
        }
    },
    mounted(){
        this.getHistory()
    }

};
</script>

<style>
</style>
