<template>
  <div>
<el-card class="box-card">
  <div slot="header" class="clearfix">
    <span>Manage Auxiliary Labels</span>
    <el-button size="mini" type="primary" style="float: right;" @click="save_labels()">Save</el-button>

  </div>
  <div >
    <BaseAuxiliaryLabels :value="auxiliary_labels_mutable"></BaseAuxiliaryLabels>
    <!-- <pre>{{auxiliary_labels_mutable}}</pre> -->
  </div>
</el-card>



  </div>
</template>
<script>
import Common from "@/common.js";
export default {
   props: {
    auxiliary_labels: {
      type: Object,
      required: true
  //     // default: function() {
  //     //   let tmp_equipments = {
  //     //     Medisca: {
  //     //       account: 'account',
  //     //       phone: '1234567890',
  //     //       name: 'Medisca'
  //     //     }
  //     //   }
  //     //   return tmp_equipments
  //     // }
    }
  },
  data() {
    return {
        auxiliary_labels_mutable: { ...this.auxiliary_labels} ,
        form: {}
    //   auxiliary_labels_mutable: auxiliary_labels || {}
    }
  },
  computed: {
    equipment_list: {
      get() {
        return this.$store.getters["equipment/get_equipment_list"];
      },
      set() {
        return this.$store.dispatch("equipment/set_equipment");
      }
    }
  },
  methods: {
    save_labels() {
      let thing = this.$store.dispatch(
        "formula/updateAuxiliaryLabels",
        this.auxiliary_labels_mutable
      )
    }
  }
};
</script>

<style scoped>
.property-row {
  margin-bottom: 1em;
}
</style>
