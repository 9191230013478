<template>
	<div class="notification-box">
		<div class="notify" v-for="n in get_messages_ordered_reversed" :key="n.id">
			<div class="flex">

				<div class="n-title box grow">
					<strong><i :class="n.data.icon"></i>
						<span class="n-title-text" lang="en">&nbsp;{{n.data.title}}</span>
					</strong>

				</div>

				<div class="n-date">{{ format_utc_to_local(n.date_created)  }} <i class="mdi mdi-clock"></i></div>
			</div>

			<div class="alert-task-link" v-if="n.task_id">
				  <router-link class="task-link" :to="{ name: 'task-view', params: { id: n.task_id } }" target="_blank">
					  <i class="fad fa-external-link-square-alt"></i>  Go To Task
				  </router-link>
			</div>

			<div class="alert-station-name" v-if="n.data.station">
				<span><i class="fal fa-location"></i> {{ n.data.station }}</span>
			</div>

			<div class="alert-video-call-link" v-if="n.data.video_response_link">
				<a :href="n.data.video_response_link" target="_facetime"><i class="fad fa-webcam"></i> Video Call</a>
			</div>

			<div class="alert-video-call-link" v-else-if="n.data.icloud_account">
				<a :href="`facetime:${n.data.icloud_account}`" target="_facetime"><i class="fad fa-webcam"></i> Video Call</a>
			</div>

			<div class="n-desc">
				{{n.data.message}}
			</div>

		</div>
		 <div class="see-all">{{messages.length}} messages</div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
const moment = require("vue-moment");

export default {
	name: 'NotificationBox',
	computed: {
		...mapState('notification', ['messages']),
		get_messages_ordered_reversed() {
			return this.$store.getters["notification/get_messages_ordered_reversed"]
		}
	},
	methods: {
		format_utc_to_local(data) {
			let new_date = this.$moment(this.$moment.utc(data))
			return new_date.fromNow()
		}
	},
		// computed: {
		// 	notifications: {
		// 		get() {
		// 			return this.$store.getters["notifications/messages"]
		// 		}
		// 	}

		// },
	data() {
		return {

			// [
			// 	{
			// 		id: 1,
			// 		title: 'New order',
			// 		icon: 'mdi mdi-cart-outline',
			// 		desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam in diam sit amet felis ultricies ultricies vitae et tortor. Proin dapibus justo felis, ut imperdiet lacus accumsan quis.',
			// 		date: 'Just now!'
			// 	},
			// 	{
			// 		id: 2,
			// 		title: 'New Schedule Realease',
			// 		icon: 'mdi mdi-calendar',
			// 		desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam in diam sit amet felis ultricies ultricies vitae et tortor. Proin dapibus justo felis, ut imperdiet lacus accumsan quis.',
			// 		date: '2 Min Ago'
			// 	},
			// 	{
			// 		id: 3,
			// 		title: 'New message from David',
			// 		icon: 'mdi mdi-email-outline',
			// 		desc: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam in diam sit amet felis ultricies ultricies vitae et tortor. Proin dapibus justo felis, ut imperdiet lacus accumsan quis.',
			// 		date: '30 Min Ago'
			// 	}
			// ]
		}
	}
}
</script>
<style>
.alert-video-call-link {
	margin-bottom: 0.5em;

}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '../assets/scss/_variables';

.notification-box {
	color: $text-color;

	.notify {
		margin-bottom: 20px;
		max-height: 8em;
		// min-height: 25em;
		overflow: hidden;
		position: relative;

		&::after {
			content: '';
			display: block;
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			width: 100%;
			height: 20px;
			// box-shadow: 0px -20px 20px -10px $background-color inset;
			box-shadow: 0px -12px 12px -6px $background-color inset;
		}

		.n-title {
			text-align: left;
			padding-right: 10px;
			-webkit-hyphens: auto;
			-moz-hyphens: auto;
			-ms-hyphens: auto;
			hyphens: auto;
		}
		.n-desc {
			color: transparentize($text-color, 0.15);
			font-size: 1.05em;
			word-break: inherit;
			-webkit-hyphens: auto;
			-moz-hyphens: auto;
			-ms-hyphens: auto;
			hyphens: auto;
		}

		&:hover {
			color: $text-color-accent;

			.n-desc {
				color: transparentize($text-color-accent, 0);
			}
		}
	}

	.see-all {
		&:hover {
			color: $text-color-accent;
		}
	}
}
</style>
