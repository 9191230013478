import EventService from "@/services/EventService.js";
import global_store from "@/store/store";
const uuid_base64 = require("uuid-base64");
const uuidv4 = require("uuid/v4");

export const namespaced = true;

export const state = {
  terminology: {},
  compound_class: {
            "01": { "code": "01", "description": "Anti-infective", "description-long": "Anti-infective—a medicinal product intended to treat pathogens such as bacteria, viruses, fungi or parasites." },
            "02": { "code": "02", "description": "Ionotropic", "description-long": "Ionotropic—a medicinal product intended to correct irregular heart rhythms." },
            "03": { "code": "03", "description": "Chemotherapy", "description-long": "Chemotherapy—a medicinal product intended to treat cancer." },
            "04": { "code": "04", "description": "Pain Management", "description-long": "Pain Management—a regimen of therapy intended to ameliorate mild to severe discomfort." },
            "05": { "code": "05", "description": "TPN/PPN", "description-long": "TPN/PPN (Hepatic, Renal, Pediatric) Total Parenteral Nutrition/ Peripheral Parenteral Nutrition—products intended to provide nourishment by central or peripheral veins for patients with compromised digestive tracts." },
            "06": { "code": "06", "description": "Hydration", "description-long": "Hydration—a product intended to restore body fluids." },
            "07": { "code": "07", "description": "Ophthalmic", "description-long": "Ophthalmic—a product intended to be applied to or instill in the surface of the eye." },
            "99": { "code": "99", "description": "Other", "description-long": "Other—not defined by other available codes." },
  },
  compound: {}

};

export const mutations = {
  SET_MEDICATION_ATTRIBUTES(state, data) {
    state.terminology = data;
  },
  SET_COMPOUND_ATTRIBUTES(state, data) {
    state.compound = data;
  }

}



export const actions = {
  fetchMedicationTerminologies({ state, commit, getters, dispatch }, id) {
    return EventService.fetchMedicationTerminologies(id)
      .then(response => {
        commit('SET_MEDICATION_ATTRIBUTES', response.data.data)
        return response.data
      })
      .catch(error => {
        const notification = {
          type: 'error',
          message:
            'There was a problem fetching data: ' + error.message
        }
        dispatch('notification/add', notification, { root: true })
      })
  },

  fetchCompoundTerminologies({ state, commit, getters, dispatch }, id) {
    return EventService.fetchCompoundTerminologies(id)
      .then(response => {
        commit('SET_COMPOUND_ATTRIBUTES', response.data.data)
        return response.data
      })
      .catch(error => {
        const notification = {
          type: 'error',
          message:
            'There was a problem fetching data: ' + error.message
        }
        dispatch('notification/add', notification, { root: true })
      })
  }

}
export const getters = {
  unit_of_measure: state => {
    return state.terminology.unit_of_measure
  },
  compound_class: state => {
    return state.compound_class
  },
  compound_class_as_array: state => {
    return Object.values(state.compound_class)
  },

  terminology: state => {
    return state.terminology
  },
  compound: state => {
    return state.compound
  },
  compound_level_of_complexity: state => {
    return state.compound.compound_level_of_complexity
  },
  compound_level_of_complexity_as_array: state => {
    return Object.values(state.compound.compound_level_of_complexity)
  },
}
