<template>
  <div class="begin-taskLotCreate">
    <el-backtop target=".main" :right="22"><i class="el-icon-caret-top"></i></el-backtop>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>
          <span v-if="task.task.task_status === 'cancelled'" style="color: tomato;font-size: 1.5em">
            Task cancelled -
          </span>
          <span style="font-size: 1.5em">{{ formula.name }}</span>

        </span>

        <div style="display: block;">
          <el-tooltip
            v-if="required_tasks_incomplete"
            style="float: right; padding: 5px 0px;margin-left: 0px;margin-right: 15px;"
            class="item"
            effect="dark"
            content="Required tasks are incomplete"
            placement="top"
          >
            <el-button type="text"><i class="fas fa-exclamation-circle" style="color: tomato; font-size: 18px;"></i></el-button>
          </el-tooltip>
          <el-button
            type="primary"
            class="status-button"
            style="float: right; padding: 5px"
            @click="save_lot({status: 'review'})"
            :disabled="task.task.task_status === 'cancelled' || required_tasks_incomplete"
          >
            Mark Complete
          </el-button>
        </div>
      </div>
      <el-row :gutter="10" style="display: flex">
        <el-col :xs="24" :sm="12" :md="14" :lg="10" :xl="6" v-if="view_mode() == 'pending'">
          <div class="balance-area">
            <div class="el-badge item">
              <i class="far fa-balance-scale-right"></i>&nbsp;
              <sup v-if="balance.description" class="el-badge__content el-badge__content--primary" style="" @click="disconnectEquipment()">x</sup>
            </div>

            <span v-if="balance.description">
              {{balance.description}}:
              <span
                style="font-size: 1em; margin: 2em; vertical-align: text-bottom;"
              >
                <div style="margin-top: 5px;" v-show="balance.message">
                  <!-- <transition name="el-fade-in-linear"> -->
                    <div v-show="balance.description && !balance.allow_manual_measurement" class="equipment-message-box">
                      <i class="fad fa-exclamation-circle" style="--fa-secondary-color: tomato;"></i>&nbsp;
                      <span v-html="balance.message"></span></div>
                  <!-- </transition> -->
                </div>
                <span v-if="balance.allow_manual_measurement">
                  <div>
                    <span style="font-size: 14px;">Manual Entry: &nbsp; </span>
                    <span><el-input style="max-width: 8em;" v-model="balance.weight" placeholder="Record Manual Measurement" clearable autocomplete="off" @input="format_manual_weight"
                      type="number"
                      inputmode="decimal"></el-input>
                    </span>
                  </div>
                </span>
                <span v-else>
                  <el-tag
                    v-if="!balance_disallow_recording || parseFloat(balance.weight)"
                    no-color="#8a8a8a"
                    style="transform: scale(1.75);"
                  >{{parseFloat(balance.weight)}} {{balance.unit}}</el-tag>
                  <el-tag
                    v-else
                    no-color="#8a8a8a"
                    style="transform: scale(1.75);"
                  >Waiting for Data...</el-tag>
                </span>
              </span>
            </span>
            <span v-else class="helper-instructions">
              <i class="fal fa-barcode-scan"></i>&nbsp; Scan to select equipment
            </span>
          </div>
        </el-col>
      </el-row>

      <el-row>
        <div style="margin-top: 15px;">
          <el-col :span="24" :xs="24">
            <b class="section-header">Equipment Used:</b>
            <el-table :data="equipment_list" style="width: 100%" height="250">
              <el-table-column prop="description" label="Equipment" min-width="180"></el-table-column>
              <el-table-column prop="user" label="User" min-width="180"></el-table-column>
              <el-table-column prop="date_checked_out" label="Time" min-width="200">
                <template slot-scope="props">
                  {{props.row.date_checked_out | moment('YYYY-MM-DD h:mm:ss a') }}
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </div>
      </el-row>

      <div class="formula-instructions-area">
        <div class="section-header">
          Instructions
          <span
            class="toggle-instructions clickable"
            style="color: rgb(100, 100, 100); margin-left: 2em;  text-align:right;"
          >
            <i
              class="far fa-eye"
              v-if=" ! show_instructions"
              @click="toggleInstructionVisibility(true)"
            ></i>
            <i class="far fa-eye-slash" @click="toggleInstructionVisibility(false)" v-else></i>
          </span>
        </div>
        <div class="formula-instructions" v-if="show_instructions">{{formula.instructions}}</div>
        <div
          class="formula-type formula-instructions"
          v-if="show_instructions"
        >{{formula_type.instructions}}</div>
      </div>

      <div class="formula-tasks">
        <h4>
          <i style="margin-right: .5em" class="fal fa-tasks"></i>
          <span>Tasks</span>
        </h4>
        <el-form>
          <el-form-item
            label
            v-for="(task, index) in task_mutatable.lot.formula.tasks"
            :key="index"
          >
            <el-checkbox
              :label="task.description"
              :name="`task_${index}`"
              @change="set_subtask_status(task, index)"
              v-model="task.complete"
              :checked="task.complete"
              :readonly="view_mode() == 'readonly'"
            ></el-checkbox>
            <span v-if="task.required">
              &nbsp;
              <el-tooltip
                class="item"
                effect="dark"
                content="This task is required"
                placement="top"
              >
                <el-button type="text">
                  <span v-if="task.complete">
                    <i class="fas fa-check-circle" style="color:forestgreen"></i>
                  </span>
                  <span v-else>
                    <i class="fas fa-exclamation" style="color: tomato"></i>
                  </span>
                </el-button>
              </el-tooltip>
            </span>
            <span v-if="view_mode() == 'readonly' || task.complete ">
              [
              <el-tooltip placement="top">
                <div
                  slot="content"
                >{{new Date(task.date_completed) | moment('YYYY-MM-DD h:mm:ss a') }}</div>
                <span class="clickable" v-if="task.user">{{task.user.name}}</span>
                <span v-else>
                  <i class="fal fa-exclamation-triangle"></i>
                  <span v-if="! task.complete">Incomplete</span>
                  <span v-else>n/a</span>
                </span>
              </el-tooltip>]
            </span>



            <div v-if="task.record_weights">
              <div class="list-wrap">

                <div class="styled-list">
                  <div v-for="(weight, weight_index) in task.weights" :key="weight_index">
                    {{weight}}
                    <i
                      class="fal fa-minus-circle clickable item-remove"
                      @click="remove_task_weight(index, weight_index)"
                      v-if="view_mode() == 'pending'"
                    ></i>
                  </div>
                </div>
              </div>

              <span v-if="view_mode() == 'pending' && subtasks[index]">

                <span v-if="!config_disallow_manual_measurement_tasks">
                  <el-input v-model="subtasks[index].new_weight" style="max-width: 5em; " size="mini" type="number" inputmode="decimal"></el-input>&nbsp;
                  <el-button type="primary" @click="set_subtask_weights(task, index )" size="mini" style="margin-right: 5px">
                    <i class="fal fa-plus"></i> &nbsp; Add
                  </el-button>
                </span>
                <el-button
                  type="primary"
                  @click="set_subtask_weights(task, index, balance.weight )"
                  data-old="@click='task.weights.push(balance.weight)''"
                  size="mini"
                  :disabled="!balance.description || balance_disallow_recording"
                >
                  <i class="fal fa-balance-scale-right"></i>
                  &nbsp; Record [{{balance.weight}}]
                </el-button>
              </span>
            </div>

            <div v-if="task.record_manual_measurement">
             <ul>
                <li v-for="(measurement, measurement_index) in task.measurements" :key="measurement_index">
                  {{measurement.display_description || measurement.value}}
                  <i
                    class="fal fa-minus-circle clickable item-remove"
                    @click="remove_task_measurement(index, measurement_index)"
                    v-if="view_mode() == 'pending'"
                  ></i>
                </li>
              </ul>
              <span v-if="view_mode() == 'pending'">
                <el-input v-model="subtasks[index].new_measurement.value" style="max-width: 25em; " size="mini"></el-input>
                &nbsp;
                <el-button type="primary" @click="set_subtask_measurement(task, index )" size="mini">
                  <i class="fal fa-plus"></i> &nbsp; Add
                </el-button>
              </span>
            </div>

            <div v-if="task.record_time_measurement">
              <i class="fad fa-stopwatch"></i>
              <span v-if="task.time_measurements && task.time_measurements[0]">
                <span class="time_measurement_begin">{{ task.time_measurements[0] | moment('YYYY-MM-DD hh:mm:ss a') }}</span> -
                <span class="time_measurement_end" v-if="task.time_measurements[1]">{{ task.time_measurements[1] | moment('YYYY-MM-DD  hh:mm:ss a') }}</span>
                <span class="time_measurement_quantity" v-if="task.time_measurements[1]"> | {{ format_time_quantity(task.time_measurements) }} </span>
              </span>
              <span v-if="view_mode() == 'pending'">
                <el-button type="primary" @click="set_subtask_time_measurement(task, index, 0 )" size="mini">
                   &nbsp; Begin
                </el-button>
                &nbsp;
                <el-button type="primary" @click="set_subtask_time_measurement(task, index, 1 )" size="mini">
                   &nbsp; End
                </el-button>

              </span>
            </div>

            <div v-if="task.record_photo">
              <el-row>
                <el-col :span="24">
                  <div style="margin-top: 20px;">
                    <h4>
                      <i style="margin-right: .5em" class="fal fa-file"></i>
                      <span>Documents</span>
                    </h4>
                    <!-- <pre>task.documents:{{task.documents}}</pre> -->
                    <div v-for="(doc, index) in task.documents" :key="index">
                      <div v-if="doc.documents && doc.documents.length > 0">
                        <div class="sub-title">Category: {{ task_document_categories[doc.category] }}</div>
                        <table class="styled striped" width="100%">
                          <thead>
                          <tr>
                            <th width="20%">Name</th>
                            <th width="70%">Note</th>
                            <th width="">Preview</th>
                            <!-- <th width="10%">Remove</th> -->
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="item in doc.documents" :key="item.identifier">
                            <td style="word-break: break-all;">
                              <el-link :href="get_document_url(item.identifier, {file_name: item.name, mime_type: item.mime_type || item.type})" target="_blank">
                                <i class="fa fa-file"></i>
                                {{ item.name }}
                              </el-link>
                            </td>
                            <td>{{ item.note }}</td>
                            <td>
                              <a :href="get_document_url(item.identifier, {file_name: item.name, mime_type: item.mime_type || item.type})" target="_blank">
                                <div v-if="item.type === 'image/heic'" style="width: 200px; height: 200px;">
                                  <RenderHEICImage :file="item" />
                                </div>
                                <el-image
                                    v-if="is_picture(item.mime_type || item.type)"
                                    style="width: 200px; height: 200px"
                                    :src="get_document_url(item.identifier, {file_name: item.name, mime_type: item.mime_type || item.type, width: 200})"
                                    fit="scale-down"
                                >
                                  <div slot="placeholder" class="image-slot">
                                    Loading<span class="dot">...</span>
                                  </div>
                                </el-image>
                              </a>
                            </td>
                            <!-- <td>{{ doc.category }}</td> -->
                            <!-- <td>
                              <el-button
                                plain
                                size="mini"
                                type="danger"
                                icon="el-icon-delete"
                                circle
                                @click="deleteDoc(doc.documents, item)"
                              ></el-button>
                            </td> -->
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <BaseDocumentUpload
                      :documents.sync="task.documents"
                      icon_class="fal fa-camera"
                      :value="task.documents"
                      :categories="task_document_categories"
                      label="Upload"
                      @update_documents="updateTaskDocuments(task, ...arguments)"
                    ></BaseDocumentUpload>
                    <takePhoto @update_documents="updateTaskDocuments(task, ...arguments)" />
                  </div>
                </el-col>
              </el-row>
            </div>
          </el-form-item>
        </el-form>
        <!-- formula-type tasks -->
      </div>

      <el-row>
        <div style="margin-top: 15px;"></div>
      </el-row>
    </el-card>
  </div>

</template>

<script>
const uuidv4 = require("uuid/v4");
import NProgress from "nprogress";
import Common from "@/common.js";
import moment from 'moment';
import EventService from "@/services/EventService.js";
import EquipmentService from '@/services/equipment.js'
const uuid_base64 = require("uuid-base64");
import lot_store from "@/store/modules/lot.js";
import url_for from "@/services/pmk_url_for";
import LogRocket from 'logrocket';
import takePhoto from '@/components/TakePhoto.vue'
import RenderHEICImage from '@/components/RenderHEICImage.vue'
import _ from 'lodash'

const scanned_code_listener = function(e) {
  // console.log("caught code:", e);
  NProgress.start();
  EventService.getInventory(e.detail.code)
    .then(function(result) {
      if (result.success && result.data.ingredient_identifier) {
        //let lot = self.$store.getters['lot/getLot']
        let filter_result = self.formula.ingredients.filter(
          ingredient =>
            ingredient.identifier.toLowerCase() ==
            result.data.ingredient_identifier.toLowerCase()
        );
        if (filter_result.length) {
          // console.log(
          //   `416:  ${result.data.ingredient_identifier.toLowerCase()}`
          // );
          self.$store.dispatch("lot/ingredient_check_in_and_weigh", {
            ingredient_identifier: result.data.ingredient_identifier.toLowerCase(),
            lot: result.data.data
          });
        } else {
          const notification = {
            type: "warning",
            message: "Incorrect ingredient scanned!"
          };
          self.$store.dispatch("notification/add", notification, {
            root: true
          });
        }
      }
    })
    .then(() => NProgress.done())
    .catch(() => {
      NProgress.done();
    });
};


export default {
  props: {
    task: Object
  },
  components: {
    takePhoto,
    RenderHEICImage
  },
  data() {
    return {
      balance_disallow_recording: false,
      task_mutatable: this.task,
      options: {},
      show_instructions: false,
      subtasks: [],
      formula_tasks_all: [],
      categories: this.$store.state.categories,
      task_document_categories: [
        {label:'Photo',value:'photo'}
      ],
      date_started: new Date().toJSON(),
      selected_device: {},
      current_sessions: []
    };
  },
  watch: {
  },
  computed: {
    decimal_places() {
      return _.get(this.$store.getters['formula/get_lab_config'].config, 'lot.decimal_places', 3)
    },
    required_tasks_incomplete() {
      let incomplete_required_tasks = this.task_mutatable.lot.formula.tasks.filter(task => {
        if (task.required && !task.complete) {
          return true
        }
        return false
      })
      return !!incomplete_required_tasks.length
    },
    lab_config: {
      get() {
        if (!this.$store.getters["formula/get_lab_config"]) {
          this.$store.dispatch("formula/fetchLabConfig");
        }
        if (this.$store.getters["formula/get_lab_config"]) {
          return this.$store.getters["formula/get_lab_config"]["config"]
        } else return {};
      }
    },
    config_disallow_manual_measurement_tasks: {
      get() {
        return _.get(this.lab_config, 'lot.disallow_manual_measurement_tasks', false)
      }
    },
    related_task: {
      get() {
        return this.$store.getters["task/queryTasks"];
      }
    },
    balance: {
      get() {
        return this.$store.getters["equipment/get_balance_state"];
      },
      set(new_data) {
        let self = this;
        console.log('set balance', new_data)
        let new_object = { ...new_data };
        self.$store.dispatch("equipment/set_balace_state_weight", new_object).then(data => {
          console.log("balance.weight", self.balance.weight);
        });
      }

    },
    lot_tasks: {
      get() {
        let sub_tasks = this.$store.getters["lot/getLot"].formula.data.tasks;
      }
    },
  },
  methods: {
    uniqueby(arr, prop) {
      return _.uniqBy(arr, prop)
    },
    handle_visibility_change() {
      if (document.visibilityState === "hidden") {
        console.log('1555 user left page')
        try {
          let session = {
            sessionid: '',
            pageviewid: '',
            date_session_ended: new Date().toJSON(),
            date_session_started: this.date_started,
            user: this.$store.getters["account/profile"],
            logrocket: ''
          }
          let session_url = ''
          LogRocket.getSessionURL(function (sessionURL) {
            session_url = sessionURL
          })
          session.logrocket = session_url
          // Stop and start mouseflow, log the session and pageview ids
          try {
            if (mouseflow) {
              // console.log('mf session test', mouseflow)
              window._mfq.push(["stop"]);
              let pageview_id = ''
              try {
                pageview_id = mouseflow.getPageviewId()
              } catch {
                pageview_id = ''
              }

              session.sessionid = mouseflow.getSessionId()
              session.pageviewid = pageview_id
              mouseflow.start()
            }
          } catch (e) {
            console.error(e)
          }
          this.current_sessions.push(session)
        } catch(e) {console.error(e);}
      } else  {
        console.log('1555 user re-entered page')
        this.date_started = new Date().toJSON()
        // Start a new session
      }
    },
    format_manual_weight() {
      this.balance.weight = this.balance.weight.replace(/[^0-9.-]/g, '')
    },
    check_balance_disallow_recording() {
      if (this.balance && !_.isNil(this.balance.allow_recording)) {
        this.balance_disallow_recording = !this.balance.allow_recording
      } else if (this.balance.allow_manual_measurement) {
        this.balance_disallow_recording = false
      }
      window.setTimeout(() => { this.check_balance_disallow_recording() }, 1500)
    },
    moment(time) {
      return moment(time)
    },
    is_picture(mime_type) {
      const picture_types = [
        'image/gif',
        'image/jpeg',
        'image/png'
      ]
      return picture_types.includes(mime_type)
    },
    go_to_task(task_id) {
      // console.log("goto", task_id);
      if (task_id) {
        this.$router.push({ name: "task-view", params: { id: task_id } });
      }
      // console.log("goto-end", task_id);
    },
    format_float(val) {
      if (parseFloat(val)) {
        this.lot.yield = parseFloat(val)
        if (val.slice(-1) === '.') {
          this.lot.yield += '.'
        }
      } else {
        this.lot.yield = ''
      }
    },
    isChrome() {
      var isChromium = window.chrome;
      var winNav = window.navigator;
      var vendorName = winNav.vendor;
      var isOpera = typeof window.opr !== "undefined";
      var isIEedge = winNav.userAgent.indexOf("Edge") > -1;
      var isIOSChrome = winNav.userAgent.match("CriOS");

      if (isIOSChrome) {
         return true
      } else if(
        isChromium !== null &&
        typeof isChromium !== "undefined" &&
        vendorName === "Google Inc." &&
        isOpera === false &&
        isIEedge === false
      ) {
         return true
      } else {
         return false
      }
    },
    set_subtask_status(lot_task, lot_task_index) {
      let completed = lot_task.complete;
      let task = { version: this.task_mutatable.lot.version };
      // console.log("set_subtask_status", lot_task, completed);

      if (completed) {
        const user = this.$store.getters["account/profile"];

        task.date_completed = new Date().toJSON();
        task.user = user;
        task.status = "complete";
        task.complete = true;
      } else {
        task.date_completed = null;
        task.user = null;
        task.status = "new";
        task.complete = false;
      }
      lot_task = { ...lot_task, ...task };
      let new_lot_data = this.lot;
      new_lot_data.formula.tasks[lot_task_index] = lot_task;
      this.lot = new_lot_data;
    },
    set_subtask_weights(lot_task, lot_task_index, weight_to_record) {
      let task = { ...lot_task };
      task.version = this.task_mutatable.lot.version
      if (
        typeof weight_to_record === "undefined" ||
        weight_to_record === null
      ) {
        weight_to_record = this.subtasks[lot_task_index].new_weight;
      }
      // if (! this.subtasks[lot_task_index] ) { this.subtasks[lot_task_index] = {} }
      task.weights.push(weight_to_record);
      this.subtasks[lot_task_index].new_weight = ""; // clear
      lot_task = { ...lot_task, ...task };
      let new_lot_data = { ...this.lot };
      new_lot_data.formula.tasks[lot_task_index] = lot_task;
      this.lot = new_lot_data;
    },
    set_subtask_measurement(lot_task, lot_task_index, measurement_to_record) {
      let task = { ...lot_task };
      task.version = this.task_mutatable.lot.version
      if (
        typeof measurement_to_record === "undefined" ||
        measurement_to_record === null
      ) {
        measurement_to_record = this.subtasks[lot_task_index].new_measurement;
      }
      // if (! this.subtasks[lot_task_index] ) { this.subtasks[lot_task_index] = {} }
      task.measurements.push(measurement_to_record);
      this.subtasks[lot_task_index].new_measurement = {type: "", value: "" }; // clear
      lot_task = { ...lot_task, ...task };
      let new_lot_data = { ...this.lot };
      new_lot_data.formula.tasks[lot_task_index] = lot_task;
      this.lot = new_lot_data;
    },

    set_subtask_time_measurement(lot_task, lot_task_index, time_measurement_index) {
      let task = { ...lot_task };
      task.version = this.task_mutatable.lot.version
      if (
        typeof time_measurement_index === "undefined" ||
        time_measurement_index === null
      ) {
        time_measurement_index = 0
      }
      const measurement_to_record = new Date().toJSON()
      if (! task.time_measurements) {
        task.time_measurements = []
      }
      task.time_measurements[time_measurement_index] = measurement_to_record;

      lot_task = { ...lot_task, ...task };
      let new_lot_data = { ...this.lot };
      new_lot_data.formula.tasks[lot_task_index] = lot_task;
      this.lot = new_lot_data;
    },
    format_time_quantity(times) {
      const begin_time = new moment(times[0])
      const end_time = new moment(times[1])
      const duration = moment.duration(begin_time.diff(end_time))
      return duration.humanize()
    },
    remove_task_weight(task_index,   weight_index) {
      this.task_mutatable.lot.formula.tasks[task_index].weights.splice(weight_index, 1)
      this.$set(this.task_mutatable, 'tasks', this.task_mutatable.lot.formula.tasks)
      this.task_mutatable = {...this.task_mutatable}
    },

    remove_task_measurement(task_index, measurement_index) {
      this.task_mutatable.lot.formula.tasks[task_index].measurements.splice(measurement_index, 1)
      this.task_mutatable = {...this.task_mutatable}
    },

    select_balance(balance) {
      console.log(balance);
    },

    add_ingredient(selected_ingredient) {
      // this.form.new_ingredient = selected_ingredient
      this.formula.ingredients.push(selected_ingredient);
      //selected_ingredient = {}
      this.form.new_ingredient.description = "";
      return;
    },
    remove_ingredient_lot(ingredient_lot) {
      let self = this;
      console.log("remove ing", ingredient_lot);
      console.log("this.lot", this.lot);
      let new_ingredients_used = this.lot.ingredients_used;
      delete new_ingredients_used[ingredient_lot.ingredient_identifier].lots[
        ingredient_lot.identifier
      ];
      // edit object directly because we must delete from it
      let new_data = {
        ...this.lot,
        ...{ ingredients_used: new_ingredients_used }
      };
      // but first edit locally because we are not properly using vuex yet:
      this.lot = new_data;
      self.$store.dispatch("lot/reset_current_ingredient_lot");
      self.$store.dispatch("lot/setFormulaLot", new_data).then(data => {
        // console.log("setFormulaLot");
      });

      // this.formula.ingredients.splice(ingredient_index, 1);
    },

    reset_balance() {
      this.$store.dispatch("equipment/reset_balance_state");
    },
    complete_task(task_id, task_data) {
      NProgress.start();
      this.$store
        .dispatch("task/complete_task", {
          task_id: task_id,
          task_data: task_data
        })
        .then(() => NProgress.done())
        .catch(() => {
          NProgress.done();
        });
    },
    toggleInstructionVisibility(value) {
      // console.log("toggleInstructionVisibility", this.show_instructions);
      // console.log("VALUE", value);

      this.show_instructions = value;
      // console.log("POST - toggleInstructionVisibility", this.show_instructions);
    },
    toggleReworkVisibility() {
      return;
    },
    updateTaskDocuments( task, _document) {
      // console.log('_document', _document)
      // console.log('task', task)
      // copied from formula/manage.vue:
      let to_append = {};
      to_append.name = _document.name;
      to_append.note = _document.note;
      to_append.type = _document.type;
      to_append.identifier = _document.identifier;
      let to_append_list = [];
      to_append_list.push(to_append);
      let this_document_category_list = {};
      let new_documents_list;
      this_document_category_list["category"] = _document.category;
      this_document_category_list["documents"] = to_append_list;
      // catch if task does not have a documents array yet:
      if (_.isNil(task.documents) || typeof task.documents != 'object') {
        this.$set(task, 'documents', [])
        //task.documents=[]
      }
      if ((task.documents && task.documents.length == 0)) {
        new_documents_list = this_document_category_list;
        task.documents.push(this_document_category_list);
      } else {
        let sear = this.arrayContainsCategory(task.documents, _document.category);
        if (sear.contains) {
          task.documents[sear.index]["documents"].push(to_append);
        } else {
          let to_add_in_list= {};
          to_add_in_list["category"] = _document.category;
          to_add_in_list["documents"] = to_append_list;
          task.documents.push(to_add_in_list);
        }
      }
      this.$forceUpdate()
    },
    arrayContainsCategory(tabb, categ) {
      let res = false;
      let i = 0;
      let pos = i;
      tabb.forEach(function(params) {
        if (params.category == categ) {
          res = true;
          pos = i;
        }
        i++;
      });
      return { contains: res, index: pos };
    },
    get_document_url(identifier, args={}) {
      return Common.get_document_url(identifier, args);
    },
  },
  created() {
    let self = this;
    const pmk_config = this.$store.getters['app/pmk_config']


    // this.lot = this.task.lot;
    // set lot:
    console.log('CREATED')
    const formula_type_tasks_mutable = JSON.parse(JSON.stringify(this.formula_type.tasks || []))

    let all_tasks = [...this.task_mutatable.lot.formula.tasks];
    all_tasks.forEach(function(element) {
      self.formula_tasks_all.push(element);
      self.subtasks.push({
        new_weight: "",
        // TODO: support an object that can store a timestamp and other information:
        new_measurement: { value: "" , type: ""},
        // new_measurement: "",
        new_image: {}
      });
    });

},
  mounted() {
    let self = this;

    this.check_balance_disallow_recording()
    document.removeEventListener("visibilitychange", this.handle_visibility_change, false)
    document.addEventListener("visibilitychange", this.handle_visibility_change, false)

    if (this.task_id) {
      // listen for scanned-code event
      document.addEventListener("scanned-code", scanned_code_listener);
      // EventService.getInventory(decompressed_scan)
      this.$store.dispatch("task/setQueryFilters", { task_id: this.task_id });
    }

  },
  beforeDestroy() {
    document.removeEventListener("scanned-code", scanned_code_listener, false);
    this.$store.dispatch("equipment/clear_checked_out_equipment");
    this.$store.dispatch("equipment/reset_balance_state");
    this.$store.dispatch("equipment/disconnectEquipmentWs");

  }
};
</script>


<style id="task-checkbox-fix">
.formula-tasks label.el-checkbox {
    white-space: break-spaces;
}
.el-icon-warning:before {
    content: "\e60a" !important;
}
</style>
<style scoped>
.location {
  margin-bottom: 0;
}
.location > .icon {
  margin-left: 10px;
}
.indicators {
  font-size: 0.8em;
}
.indicator {
  margin-right: 1em;
}
.event-header > .title {
  margin: 0;
}
.list-group {
  margin: 0;
  padding: 0;
  list-style: none;
}
.list-group > .list-item {
  padding: 1em 0;
  border-bottom: solid 1px #e5e5e5;
}
.button.bottom {
  margin-top: 13px;
  line-height: 12px;
  padding: 0;
  float: right;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}
.status-button {
  margin-right: 1.25em;
}
.sub_task-instructions {
  display: inline-block;
  min-width: 25em;
  max-width: 80%;
}
.sub_task-points {
  max-width: 8em;
  margin: 0 1em;
}
.ingredient-remove:hover {
  color: rgb(207, 13, 13);
}
.clickable {
  cursor: pointer;
}
.clickable:hover {
  color: #409eff !important;
}
.helper-instructions {
  font-size: 0.8em;
  color: #8a8a8a;
  hyphens: auto;
  overflow-wrap: break-word;
  word-wrap: break-word;
}
.balance-area {
  font-size: 1.8em;
}
.formula-task-options-label {
  vertical-align: bottom;
}
.item-remove:hover {
  color: rgb(207, 13, 13);
}
/* same as sub-title */
.section-header {
  font-weight: bold;
}
.record-balance-weight-for-ingredient-quantity {
  /* float: right; */
  margin-right: 2em;
  text-align: right;
}
.log-header {
  font-size: 0.85em;
}
.measurements-log-area {
  text-align: right;
  color: #747474;
  padding-right: 2em;
}
.strike {
  text-decoration: line-through;
  text-decoration-color: red !important;
}
.remove:hover {
  color: red;
}
.no-break {
  word-break: keep-all;
  word-wrap: break-word;
}
.item .el-badge__content.is-fixed{
  cursor: pointer;
  right: 20px;
  border-radius: 15px;
  font-size: 11px;
  height: 15px;
  line-height: 14px;
  padding: 0px 4px;
  color: #f4f4f5;
  background: #cccecf;
  border-color: #cbcdcf;
}
.list-wrap {
  padding:40px;
  border-radius:5px;
  width:400px;
}
div.styled-list {
  counter-reset: list-number;
}
div.styled-list div:before {
  counter-increment: list-number;
  content: counter(list-number);

  margin-right: 0.75em;
  margin-bottom: 0.5em;
  width: 1.5em;
  height: 1.5em;
  display: inline-flex;
  align-items:center;
  justify-content: center;
  font-size:12px;
  font-weight: bold;
  background-color:#0e8ec5;
  border-radius:50%;
  color:#fff;
}
.equipment-message-box {
  font-size: 0.65em;
  color: rgb(148, 42, 42);
  white-space: pre-line;
}
</style>
