
<template>
    <div>
    <el-dialog
    title="Remove Inventory"
    :visible.sync="dialogVisible"
    width="30%"
    center>
        <el-row style="margin-top: 0.35em" :gutter="20">
            <el-col>
                <div class="input-label">Reason</div>
                <el-input
                    placeholder="Reason for loss"
                    ref="reason"
                    v-model="form.log_inventory_reduction.reason"

                    clearable>
                </el-input>
            </el-col>
        </el-row>

        <el-row style="margin-top: 0.35em" :gutter="20">
            <el-col :span="12">
                <span class="input-label">Type</span>
                <div style="margin-top: 0.35em">
                    <el-select v-model="form.log_inventory_reduction.reason_type" filterable placeholder="Removal Type">
                        <el-option
                        v-for="item in form.log_inventory_reduction.options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <!-- <pre>{{ form.log_inventory_reduction }}</pre> -->
            </el-col>
            <el-col :span="12">
                <div style="margin-top: 0.35em; line-height: 1.5em; padding: 1.5em 0;">
                  <span v-if="form.log_inventory_reduction.remove_from_inventory" class="warning-text">Removes entire lot from available inventory.  Use this to ensure a lot can not be used or dispensed.</span>
                </div>
            </el-col>
        </el-row>

        <el-row>
          <el-col style="text-align: center">
            Removing {{Object.keys(removal_data).length}} items from inventory
          </el-col>
        </el-row>
        <span slot="footer" class="dialog-footer">
            <el-button @click="cancelDialog()">Cancel</el-button>
            <el-button type="primary" @click="logEntry()">Confirm</el-button>
        </span>
    </el-dialog>
    </div>
</template>

<script>
import NProgress from "nprogress";
import EventService from "@/services/EventService.js";
import Common from "@/common.js";

export default {
    props: {
        removal_data: {
          type: Object,
          default: () => {}
        },
        // log_inventory_reduction: {
        // type: Object,
        // required: true
        // },
        dialogVisible: {
            type: Boolean,
            required: true
        },
    },
    watch: {
        dialogVisible(oldVal,newVal){
            if(oldVal == false){
                this.$emit('closing', {
                dialogVisible: false
            })
          } else {
            this.form.log_inventory_reduction.remove_from_inventory = true
            this.form.log_inventory_reduction.reason_type = "expired"
          }
        }
    },
    data() {
        return {
            form: {
                log_inventory_reduction: {
                    quantity: '',
                    reason: '',
                    reason_type: '',
                    options: [
                        {
                            label: 'Spill/Transfer Loss/etc',
                            value: "loss-manual"
                        },
                        {
                            label: "Expired",
                            value: "expired"
                        },
                        {
                            label: "Removed from inventory - Other",
                            value: "remove_from_inventory"
                        },

                        {
                            label: "Recalled",
                            value: "recalled"
                        }
                    ],
                    remove_from_inventory: false
                }
            }
        };
    },
methods: {
    cancelDialog(removed) {
        this.$emit('closing', {
                dialogVisible: false,
                removed,
                // quantity: +this.$refs.quantity.value,
                // reason: +this.$refs.reason.value
            })

    },
    logEntry() {
        let self = this
        let details = {
          username: this.$store.getters['account/get_user_name'],
          recorded_by: this.$store.getters['account/get_user'].name
        }
        let query_object = {
            lots: this.removal_data,
            reason: this.form.log_inventory_reduction.reason,
            reason_type: this.form.log_inventory_reduction.reason_type || "loss-manual",
            reason_details: details,
            remove_from_inventory: true
        }
        console.log({query_object})
        NProgress.start();
        EventService.bulkInventoryLogReduction(query_object).then ( (response) => {
          if (!response.data.success) {
            throw response.data.errors
          }
          self.$emit('input', {
            dialogVisible: false,
            // quantity: +this.$refs.quantity.value,
            // reason: +this.$refs.reason.value
          })
          self.form= {
            log_inventory_reduction: {
              quantity: '',
              reason: '',
              reason_type: "loss-manual"
            }
          }
        }).then(() => {
            NProgress.done();
            self.cancelDialog(query_object.remove_from_inventory)
            const notification = {
            type: "success",
            message:
                "Inventory reduction logged."
            };
            self.$store.dispatch("notification/add", notification, { root: true });
        })
        .catch((error) => {
            NProgress.done();
            const notification = {
            type: "error",
            message:
                "There was a problem saving your entry: " + error.message
            };
            self.$store.dispatch("notification/add", notification, { root: true });
        })
    },
    updateEntry () {
      this.$emit('input', {
        // quantity: +this.$refs.quantity.value,
        // reason: +this.$refs.reason.value

      })
    }
  }
}
</script>

<style>
.input-label {
    font-size: 1em;
}
.warning-text {
  display: block;
  font-size: .75em;
  color: tomato;
}
</style>
