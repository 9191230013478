<template>
  <el-dialog
    :visible.sync="show"
    :title="`Title: ${file.name}`"
    width="60%"
    :show-close="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    top="7vh"
    append-to-body
  >
    Category: {{file.category}} - {{file.sub_category}}<br>
    Description: {{file.description}}
    <div style="height: 70vh">
      <RenderHEICImage :file="file" />
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="closeDialog">Close</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import RenderHEICImage from '@/components/RenderHEICImage.vue'
export default {
  components: {
    RenderHEICImage,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    file: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['closeDialog'],
  methods: {
    closeDialog() {
        this.$emit('closeDialog')
    }
  }
}
</script>

<style lang="css" scoped></style>
